export enum UsedWebsocketChannel {
  MEMBER = 'member',
  COMPANY_MEMBER = 'chm',
  CASE = 'case',
  MASTER_AGENT = 'masterAgent',
  CLIENT = 'customer',
  COMPANY = 'company',
}

export enum UsedWebsocketEvent {
  SUBSCRIBE_FAIL = 'subscribeFail',

  /**
   * 版本状态变更
   */
  VERSION_UPDATE_STATUS = 'versionUpdateStatus',

  /**
   * 版本更新通知
   */
  VERSION_UPDATE_NOTIFY = 'versionUpdatedInfo',

  /**
   * 系统版本变更
   */
  SYSTEM_VERSION_CHANGE = 'currentVersionChange',

  /**
   * 系统退出
   * channel MEMBER
   *
   */
  CLOSE = 'close',

  /**
   * 上级渠道负责人刷新
   * channel MASTER_AGENT
   */
  MASTER_AGENT_CHANGE_CHARGE = 'masterAgentChangeCharge',

  /**
   * 公司邀请
   * channel  MEMBER
   */
  COMPANY_INVITE = 'companyInvite',

  /**
   * 可用副申请人列表刷新
   * channel COMPANY_MEMBER
   */
  DEPUTY_REFRESH = 'deputyRefresh',

  /**
   * 副申请人填写表单
   * channel CASE
   */
  DEPUTY_CREATION_REFRESH = 'deputyCreationRefresh',

  /**
   * 代创建客户刷新
   * channel COMPANY
   */
  CLIENT_CAN_CREATED_CHANGE = 'canCreatedChange',

  /**
   * 消息刷新
   * channel MEMBER /COMPANY_MEMBER
   */
  MESSAGE_REFRESH = 'newMessage',

  /**
   * 待阅读消息数量
   * channel MEMBER / COMPANY_MEMBER
   */
  WAIT_READ = 'waitRead',

  /**
   * 远程协助状态刷新
   * channel COMPANY
   */
  REMOVE_ASSIST_STATUS_CHANGE = 'remoteAssistStatusChange',

  /**
   * 案件评论区刷新
   * channel CASE
   */
  CASE_COMMENT_REFRESH = 'commentRefresh',

  /**
   * 待办事项更新了
   * channel COMPANY CLIENT CASE
   */
  TODO_TASK_UPDATED = 'todoTaskUpdated',

  /**
   * 待办事项创建了
   * channel COMPANY CLIENT CASE
   */
  TODO_TASK_CREATED = 'todoTaskCreated',

  /**
   * GTE AI 写作
   * channel  CASE
   */
  GTE = 'gteGenerateNewChar',

  /**
   * 文件柜签名并打包下载
   * channel baleFileCabinet:{caseId}:{randomKey}
   */
  BALE_FILE_CABINET_RESULT = 'baleFileCabinetResult',

  /**
   * 更新日志阅读状态变更
   * channel MEMBER
   */
  RELEASE_LOG_VISA_STATUS_CHANGE = 'versionUpdateLogViewStatusChange',

  /**
   * 打包下载学校申请选择的文件
   * channel  packCaseSchoolFile:{caseId}:{randChar}
   */
  PACKAGE_OFFER_FILES = 'packCaseSchoolFormDone',
}
