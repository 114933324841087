import { Options } from '@/types';

export const getTestTypeOptions = (): Options => [
  {
    label: 'IELTS',
    value: 'IELTS',
  },
  {
    label: 'PTE',
    value: 'PTE',
  },
  {
    label: 'TOEFL',
    value: 'TOEFL',
  },
  {
    label: 'CAE',
    value: 'CAE',
  },
  {
    label: 'OET',
    value: 'OET',
  },
  {
    label: 'Duolingo',
    value: 'Duolingo',
  },
];

export const englishTestTypeIsDuolingo = (type: string) => type === 'Duolingo';
