import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ProTable, { ProTableProps } from '@ant-design/pro-table';
import { Button, Space } from 'antd';
import classNames from 'classnames';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useMeasure } from '@/hooks';
import styles from './index.less';
import { useScrollbar } from './useScrollbar';

const MotionButton = motion(Button);

const EnhancedProTable = <T, U = any, ValueType = 'text'>({
  headerTitle,
  className,
  style,
  tableClassName,
  tableStyle,
  onLoad,
  collapsibleIconRender,
  collapsible,
  defaultCollapsed = 'onDataLoad',
  columns,
  scrollbar = true,
  ...props
}: ProTableProps<T, U, ValueType> & {
  collapsible?: boolean;
  defaultCollapsed?: boolean | 'onDataLoad';
  collapsibleIconRender?: (collapsed: boolean) => React.ReactNode;
  tableClassName?: string;
  tableStyle?: React.CSSProperties;
  scrollbar?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(
    collapsible ? !!defaultCollapsed : false,
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [tableContainer, setTableContainer] = useState<HTMLDivElement | null>(
    null,
  );
  const [tableContent, setTableContent] = useState<HTMLDivElement | null>(null);

  const {
    data: { width },
  } = useMeasure({
    ref: containerRef,
  });

  const {
    data: { width: tableScrollWidth },
    setRef: setTableRef,
  } = useMeasure<HTMLTableElement>();

  const {
    data: { width: tableContentWidth },
    setRef: setTableContentRef,
  } = useMeasure();

  const leftScrollbarHandlers = useScrollbar(tableContent, -1);
  const rightScrollbarHandlers = useScrollbar(tableContent, 1);

  const scrollX = useMotionValue(0);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const currentTableContainer = container.querySelector(
        '.ant-table-container',
      ) as HTMLDivElement | null;

      setTableContainer(currentTableContainer);
      setTableContent(
        currentTableContainer?.querySelector(
          '.ant-table-content',
        ) as HTMLDivElement | null,
      );
    } else {
      setTableContainer(null);
      setTableContent(null);
    }
  }, [scrollbar]);

  useEffect(() => {
    if (!tableContent) {
      return undefined;
    }

    const table = tableContent.querySelector('table')!;

    setTableRef(table);
    setTableContentRef(tableContent);

    const scrollHandler = () => {
      scrollX.set(tableContent.scrollLeft);
    };

    tableContent.addEventListener('scroll', scrollHandler);

    return () => {
      setTableRef(null);
      setTableContentRef(null);
      tableContent.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollX, setTableContentRef, setTableRef, tableContent]);

  const nowrapTableWidth = 1920;

  const renderTitle = () => {
    if (!collapsible) {
      return headerTitle;
    }

    return (
      <Space
        align="center"
        role="button"
        tabIndex={0}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        style={{ cursor: 'pointer' }}
      >
        {collapsibleIconRender ? (
          collapsibleIconRender(collapsed)
        ) : (
          <RightOutlined className={styles.icon} />
        )}

        {headerTitle}
      </Space>
    );
  };

  const internalColumns = collapsible
    ? columns?.map((item) => {
        if (item.ellipsis === true && !item.render && item.key) {
          return {
            ...item,
            key: `${item.key}-collapsed-${Number(collapsed)}`,
          };
        }

        return item;
      })
    : columns;

  const scrollbarWidth = 30;
  const scrollMaxDistance = tableScrollWidth - tableContentWidth;

  const scrollbarLeftBtnOpacity = useTransform(
    scrollX,
    [0, scrollbarWidth, scrollbarWidth + 1],
    [0, 1, 1],
  );
  const scrollbarRightBtnOpacity = useTransform(
    scrollX,
    [0, scrollMaxDistance - scrollbarWidth, scrollMaxDistance],
    [1, 1, 0],
  );

  return (
    <div
      style={style}
      ref={containerRef}
      className={classNames(
        styles.tableContainer,
        collapsed && styles.collapsed,
        width < nowrapTableWidth && styles.nowrap,
        className,
      )}
    >
      <ProTable
        className={tableClassName}
        style={tableStyle}
        onLoad={(data) => {
          onLoad?.(data);

          if (defaultCollapsed === 'onDataLoad' && collapsed) {
            setCollapsed(data.length === 0);
          }
        }}
        headerTitle={renderTitle()}
        columns={internalColumns}
        {...props}
      />
      {scrollbar &&
        tableContainer &&
        scrollMaxDistance > scrollbarWidth * 2 &&
        createPortal(
          <>
            <MotionButton
              className={classNames(styles.scrollbarButton, styles.left)}
              icon={<LeftOutlined />}
              style={{
                // @ts-ignore
                opacity: scrollbarLeftBtnOpacity,
              }}
              {...leftScrollbarHandlers}
            />
            <MotionButton
              className={classNames(styles.scrollbarButton, styles.right)}
              icon={<RightOutlined />}
              style={{
                // @ts-ignore
                opacity: scrollbarRightBtnOpacity,
              }}
              {...rightScrollbarHandlers}
            />
          </>,
          tableContainer,
        )}
    </div>
  );
};

export default EnhancedProTable;
