import { CheckOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popover } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { TodoPriority, useTodoPriorityEnum } from '@/data';
import { useFormatMessage } from '@/hooks';
import styles from './TodoDetailBadge.less';

export const TodoDetailBadge: React.FC<{
  title?: string | null;
  subTitle?: string;
  icon?: React.ReactElement;
  className?: string;
}> = ({ title, subTitle, icon, className, ...props }) => {
  return (
    <div className={classNames(styles.badge, className)} {...props}>
      <div className={styles.icon}>{icon}</div>
      <div>
        <h3 className={classNames('line-2-clamp', styles.title)}>
          {title || '-'}
        </h3>
        {!!subTitle && <h4 className={styles.subTitle}>{subTitle}</h4>}
      </div>
    </div>
  );
};

export const TodoDetailBadgePopup = ({
  children,
  content,
  dropdown,
  density,
  overlayWidth = 250,
}: {
  children: React.ReactElement;
  content: (params: { onConfirm: () => void }) => React.ReactElement;
  overlayWidth?: number;
  dropdown?: boolean;
  density?: boolean;
}) => {
  const [visible, setVisible] = useState(false);

  const handleConfirm = () => {
    setVisible(false);
  };

  if (dropdown) {
    return (
      <Dropdown
        visible={visible}
        onVisibleChange={setVisible}
        overlay={content({ onConfirm: handleConfirm })}
        trigger={['click']}
        overlayStyle={{
          width: overlayWidth,
        }}
      >
        {children}
      </Dropdown>
    );
  }

  return (
    <Popover
      content={content({ onConfirm: handleConfirm })}
      trigger={['click']}
      visible={visible}
      onVisibleChange={setVisible}
      overlayInnerStyle={{
        width: overlayWidth,
      }}
      placement="bottom"
      overlayClassName={density && styles.popoverDensity}
    >
      {children}
    </Popover>
  );
};

const TodoPriorityIcon: React.FC<{
  priority?: TodoPriority;
  iconSize?: number;
  style?: React.CSSProperties;
}> = ({ priority, style, iconSize = 20 }) => {
  const colors = {
    [TodoPriority.NORMAL]: '#6889FF',
    [TodoPriority.URGENCY]: '#FF4D4F',
  };

  return (
    <div
      className={styles.priority}
      style={{
        ...style,
        background: priority && colors[priority],
      }}
    >
      <ThunderboltOutlined style={{ fontSize: iconSize, color: '#fff' }} />
    </div>
  );
};

export const TodoPrioritySelect: React.FC<{
  value?: TodoPriority;
  onChange?: (value: string) => void;
  iconSize?: number;
}> = ({ value, onChange, iconSize }) => {
  const priorityEnum = useTodoPriorityEnum();
  const { formatMessage } = useFormatMessage();

  return (
    <TodoDetailBadgePopup
      overlayWidth={250}
      content={({ onConfirm }) => (
        <Menu
          items={Array.from(priorityEnum.entries()).map(([key, item]) => ({
            key,
            icon: (
              <TodoPriorityIcon
                style={{ width: 20, height: 20, marginRight: 8 }}
                priority={key as TodoPriority}
              />
            ),
            label: item.text,
            itemIcon: key === value && <CheckOutlined />,
            onClick: () => {
              onChange?.(key as TodoPriority);
              onConfirm();
            },
          }))}
          style={{
            width: 200,
            borderRight: 0,
          }}
          selectedKeys={value && [value]}
        />
      )}
      dropdown
    >
      <TodoDetailBadge
        icon={<TodoPriorityIcon iconSize={iconSize} priority={value} />}
        title={value && priorityEnum.get(value)?.text}
        subTitle={formatMessage('优先级')}
      />
    </TodoDetailBadgePopup>
  );
};
