import { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { AbsoluteDate } from '../MessageTask';
import styles from './TodoDeadlineDate.less';

const TodoDeadlineDate: React.FC<{
  onChange?: (value: Moment) => void;
  value?: Moment;
}> = ({ value, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [container, setContainer] = useState<HTMLDivElement>();
  useEffect(() => {
    if (ref.current) {
      setContainer(ref.current);
    }
  }, [value]);

  return (
    <div className={styles.container}>
      <div ref={ref} />
      {container && (
        <AbsoluteDate
          precision="day"
          onChange={(val) => {
            if (!val) {
              return;
            }
            onChange?.(val);
          }}
          value={value}
          allowClear={false}
          getPopupContainer={() => container}
          dropdownAlign={{
            offset: [-16, -48],
          }}
          renderToFooter
          open
        />
      )}
    </div>
  );
};

export default TodoDeadlineDate;
