export const countryCode = {
  au: '61',
  cn: '86',
  nz: '64',
  hk: '852',
};

export const phoneValidatorMap: Record<string, (value: string) => boolean> = {
  au(value) {
    return /^0?4\d{8}$/.test(value);
  },
  cn(value) {
    return /^1[3-9]\d{9}$/.test(value);
  },
  nz(value) {
    return /^0?2\d{7,9}$/.test(value);
  },
  hk(value) {
    return /^\d{8}$/.test(value);
  },
};

export const countryMap = Object.entries(countryCode).reduce(
  (ret, [key, value]) => ({
    ...ret,
    [value]: key,
  }),
  {},
);

export function phoneValidator(code: string, value: string): boolean {
  const country = countryMap[code];

  if (!country) {
    const alpha = value.match(/[a-z]/g);
    return /^[a-z\d]{6,}$/.test(value) && (!alpha || alpha.length < 3);
  }

  return phoneValidatorMap[country](value);
}
