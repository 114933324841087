import { ProFormSelect } from '@ant-design/pro-form';
import { useRequest } from 'ahooks';
import React, { useImperativeHandle } from 'react';
import { ResponseSchema, services } from '@/api';
import { useCommonRules } from '@/hooks';
import type { StaffOrClientSelectRef } from './type';

const StaffSelect = React.forwardRef<
  StaffOrClientSelectRef,
  React.ComponentProps<typeof ProFormSelect> & {
    selectedValues?: number[];
  }
>(({ fieldProps, selectedValues, ...props }, ref) => {
  const { selectRules } = useCommonRules();
  const { data: staffResp, loading } = useRequest<
    ResponseSchema<
      {
        familyName: string;
        givenName: string;
        id: number;
        nickname: string;
      }[]
    >
  >(services.messageTask.getStaffList, {
    cacheKey: 'TASK_FORM_STAFF_SELECT',
  });

  const map =
    selectedValues && Object.fromEntries(selectedValues.map((v) => [v, true]));
  const staffOptions = staffResp?.data
    ?.filter((item) => (map ? !map[item.id] : true))
    .map((item) => ({
      value: item.id,
      label: item.nickname,
    }));

  useImperativeHandle(ref, () => ({
    getNames: (values) =>
      values.map((item) => ({
        value: item,
        name: staffOptions?.find((v) => v.value === item)?.label,
      })),
  }));

  return (
    <ProFormSelect
      rules={selectRules}
      options={staffOptions}
      fieldProps={{
        loading,
        ...fieldProps,
      }}
      showSearch
      {...props}
    />
  );
});

export default StaffSelect;
