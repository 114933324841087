import { Button, ButtonProps } from 'antd';
import React from 'react';

const LinkOptionButton = (
  { style, ...props }: Omit<ButtonProps, 'type'>,
  ref: React.Ref<HTMLElement>,
) => {
  return (
    <Button
      {...props}
      type="link"
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        ...style,
      }}
      ref={ref}
    />
  );
};

export default React.forwardRef(LinkOptionButton);
