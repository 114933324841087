import ProForm, {
  ProFormCheckbox,
  ProFormDependency,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-form';
import { useRequest } from 'ahooks';
import { Alert } from 'antd';
import { isNil } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useModel } from 'umi';
import { ResponseSchema, services } from '@/api';
import { FormText } from '@/components/FormText';
import ProModalForm from '@/components/ProModalForm';
import { getTextareaSizeProps } from '@/config';
import {
  MessageTaskEventType,
  MessageTaskGroup,
  MessageTaskGroupedData,
  MessageTaskScope,
  messageTaskEventIncludeCaseInfo,
  messageTaskEventIsBelongToCase,
  messageTaskEventIsBelongToClient,
  messageTaskEventTypeIsHook,
  useMessageTaskEventTypeOptions,
} from '@/data';
import {
  useCommonRules,
  useFormatMessage,
  useModalFormRequestActions,
} from '@/hooks';
import { ModalFormPublicProps } from '@/types';
import CaseSelect from './CaseSelect';
import ClientSelect from './ClientSelect';
import StaffOrClientSelect from './StaffOrClientSelect';
import StaffSelect from './StaffSelect';
import TriggerDateForm from './TriggerDateForm';
import {
  MessageTaskPriorityType,
  useGroupEnum,
  useMessageTaskTriggerDescriptors,
  usePersonnelNameEnum,
} from './data';
import type { MessageTaskFormValues } from './type';
import { convertToTaskFormValues, formatTaskFormValues } from './util';

const FormItem = ProForm.Item;

export type MessageTaskFormProps = ModalFormPublicProps & {
  /**
   * 当前的案件
   */
  currentCase?: { name: string; id: number };
  /**
   * 当前的客户
   */
  currentClient?: { name: string; id: number };
  scope?: MessageTaskScope;
  onlyNormal?: boolean;
  onSubmit?: (...args: any) => Promise<any>;
  tooltip?: boolean;
  initialValues?: MessageTaskFormInitialValues;
};

export type MessageTaskFormInitialValues = Partial<
  Pick<
    MessageTaskFormValues,
    'title' | 'content' | 'priority' | 'triggerEvent'
  > & {
    staffList: (string | number)[];
  }
>;

const MessageTaskForm: React.FC<MessageTaskFormProps> = ({
  onOk,
  currentCase,
  currentClient,
  visible,
  onCancel,
  id,
  onlyNormal,
  onSubmit,
  initialValues,
  tooltip = true,
  scope = 'all',
}) => {
  const [form] = ProForm.useForm();
  const { formatMessage } = useFormatMessage();
  const { caseGroup, clientGroup, companyGroup } = usePersonnelNameEnum();
  const eventTypeOptions = useMessageTaskEventTypeOptions();
  const groupEnum = useGroupEnum();
  const { information } =
    useModel('@@initialState')?.initialState?.currentUser ?? {};

  const { selectRules, textLengthRule, inputRules } = useCommonRules();
  const {
    data: templateResp,
    loading: fetchingTemplate,
    run: fetchTemplateList,
  } = useRequest<ResponseSchema<AnyObject[]>>(
    services.messageTask.getTemplateList,
    {
      manual: true,
    },
  );
  const { loading, run: fetch, data: resp, reset } = useRequest<
    ResponseSchema<MessageTaskFormValues>
  >(services.messageTask.getTaskSummary, {
    manual: true,
  });

  const data = resp?.data;

  const getGroupingOptions = useCallback(
    (triggerEvent: MessageTaskEventType) => {
      const groupedData = MessageTaskGroupedData[scope];
      const groupingValueList = Object.keys(groupedData).filter((key) =>
        groupedData[key].includes(triggerEvent),
      );
      return groupingValueList.map((value) => ({
        value,
        label: groupEnum[value],
      }));
    },
    [groupEnum, scope],
  );

  const getCurrentGrouping = useCallback(
    (
      triggerEvent: MessageTaskEventType,
      grouping: MessageTaskGroup | undefined,
    ) => {
      const groupingOptions = getGroupingOptions(triggerEvent);
      /**
       * 这里用已经存在的 grouping
       * 1. 创建的时候 刚好用 groupingOptions 第一个
       * 2. 编辑的时候 用以前的
       */
      const currentGrouping = grouping || groupingOptions[0]?.value;

      return currentGrouping;
    },
    [getGroupingOptions],
  );

  const { handleSubmit } = useModalFormRequestActions({
    visible,
    form,
    id,
    submitActions: {
      create: onSubmit || services.messageTask.createTask,
      update: onSubmit || services.messageTask.updateTask,
    },
    formatSubmitParams: (params) =>
      formatTaskFormValues(params, id ? data : undefined, scope),
    onOk,
  });

  const { dateDescriptors } = useMessageTaskTriggerDescriptors();
  const isEditMode = !!id;

  useEffect(() => {
    if (id && visible) {
      fetch({ id });
    }
  }, [id, visible, fetch]);

  useEffect(() => {
    form.resetFields();
  }, [scope, form]);

  useEffect(() => {
    if (visible && initialValues) {
      form.setFieldsValue(initialValues);

      if (initialValues.triggerEvent) {
        fetchTemplateList({ type: initialValues.triggerEvent });
      }
    }
  }, [initialValues, form, visible, fetchTemplateList, scope]);

  useEffect(() => {
    if (data && visible && id) {
      fetchTemplateList({ type: data.triggerEvent });
      form.setFieldsValue(
        convertToTaskFormValues({
          ...data,
          currentGrouping: getCurrentGrouping(data.triggerEvent, data.grouping),
        }),
      );
    }
  }, [data, id, visible, form, getCurrentGrouping, fetchTemplateList]);

  const priorityOptions = [
    {
      label: formatMessage('重要'),
      value: MessageTaskPriorityType.IMPORTANT,
    },
    {
      label: formatMessage('一般'),
      value: MessageTaskPriorityType.NORMAL,
    },
    {
      label: formatMessage('不重要'),
      value: MessageTaskPriorityType.UNIMPORTANT,
    },
  ];

  const templateOptions = templateResp?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const eventOptions = eventTypeOptions.filter(({ value: type }) => {
    if (onlyNormal) {
      return type === MessageTaskEventType.NORMAL;
    }

    if (scope === 'all') {
      return true;
    }

    return scope === 'client'
      ? messageTaskEventIsBelongToClient(type)
      : messageTaskEventIsBelongToCase(type);
  });

  const renderBindCase = (clientId?: number) => (
    <CaseSelect
      label={formatMessage('关联案件')}
      name="correlationCaseId"
      key="bindCase"
      clientId={clientId}
      selectProps={{
        disabled: isEditMode,
        // @ts-ignore
        onSelect: (_, { label }) => {
          form.setFieldsValue({
            correlationName: label,
          });
        },
      }}
    />
  );

  const renderCurrentCase = () => (
    <FormItem
      label={formatMessage('关联案件')}
      name="correlationCaseId"
      rules={inputRules}
      initialValue={currentCase!.id}
    >
      <FormText
        getText={() => (
          <span className="text-primary">{currentCase!.name}</span>
        )}
      />
    </FormItem>
  );
  const renderBindClient = () => (
    <ClientSelect
      label={formatMessage('关联客户')}
      name="correlationClientId"
      key="bindClient"
      selectProps={{
        disabled: isEditMode,
        // @ts-ignore
        onSelect: (_, { label }) => {
          form.setFieldsValue({
            correlationName: label,
          });
        },
      }}
    />
  );

  const languageTriggerConditionOptions = [
    {
      value: 'zh-CN',
      label: '简体中文',
    },
    {
      label: 'English',
      value: 'en-US',
    },
  ];

  return (
    <ProModalForm
      title={id ? formatMessage('编辑任务') : formatMessage('创建任务')}
      onFinish={handleSubmit}
      onCancel={onCancel}
      visible={visible}
      form={form}
      loading={loading}
      onValuesChange={(changed) => {
        if ('triggerEvent' in changed && changed.triggerEvent) {
          fetchTemplateList({ type: changed.triggerEvent });
          form.resetFields(['templateId', 'grouping']);
        }

        if ('correlationId' in changed && !changed.correlationId) {
          form.resetFields(['correlationName']);
        }
      }}
      modalProps={{
        afterClose() {
          form.resetFields();
          reset();
        },
      }}
    >
      {tooltip && (
        <Alert
          style={{ marginBottom: 24 }}
          message={
            <div>
              <div>
                {formatMessage(
                  '签证过期日期、护照过期日期以 Australia/Melbourne 时区为基准',
                )}
              </div>
              {formatMessage('客户生日以 {timezone} 时区为基准', {
                timezone: information?.companyTimezone,
              })}
            </div>
          }
          showIcon
        />
      )}
      <ProFormSelect
        name="triggerEvent"
        label={formatMessage('任务事件')}
        rules={selectRules}
        options={eventOptions}
        fieldProps={{
          disabled: isEditMode,
        }}
      />
      <ProFormCheckbox.Group
        name="notifyMode"
        initialValue={['email']}
        rules={selectRules}
        label={formatMessage('通知方式')}
        options={[
          { value: 'email', label: formatMessage('邮件') },
          { value: 'sms', label: formatMessage('短信') },
        ]}
        help={formatMessage(
          '一般情况下，默认是邮件通知方式，除非特别申明要用短信通知',
        )}
      />
      <ProFormRadio.Group
        options={priorityOptions}
        rules={selectRules}
        name="priority"
        label={formatMessage('优先级')}
      />
      <ProFormDependency name={['triggerEvent']}>
        {({ triggerEvent }) => {
          if (!triggerEvent) {
            return null;
          }

          if (triggerEvent === MessageTaskEventType.NORMAL) {
            return (
              <>
                <ProFormText
                  name="title"
                  rules={[...inputRules, textLengthRule(2, 200)]}
                  fieldProps={{
                    minLength: 2,
                    maxLength: 200,
                  }}
                  initialValue={currentCase?.name}
                  label={formatMessage('消息标题')}
                />
                <ProFormTextArea
                  rules={[...inputRules, textLengthRule(2, 250)]}
                  name="content"
                  label={formatMessage('消息内容')}
                  fieldProps={getTextareaSizeProps({ min: 2, max: 250 })}
                />
              </>
            );
          }

          return (
            <ProFormSelect
              rules={selectRules}
              name="templateId"
              disabled={!triggerEvent}
              options={templateOptions}
              fieldProps={{
                loading: fetchingTemplate,
              }}
              label={formatMessage('消息模板')}
              showSearch
            />
          );
        }}
      </ProFormDependency>
      <ProFormDependency name={['triggerEvent']}>
        {({ triggerEvent }) => {
          if (!triggerEvent) {
            return null;
          }
          const groupingOptions = getGroupingOptions(triggerEvent);

          return (
            groupingOptions.length > 1 && (
              <ProFormRadio.Group
                options={groupingOptions}
                rules={selectRules}
                name="grouping"
                label={formatMessage('分组')}
                fieldProps={{
                  disabled: isEditMode,
                }}
              />
            )
          );
        }}
      </ProFormDependency>
      <ProFormDependency name={['grouping', 'triggerEvent']}>
        {({ triggerEvent, grouping }) => {
          if (!triggerEvent) {
            return null;
          }

          const currentGrouping = getCurrentGrouping(triggerEvent, grouping);
          const needBindClient = currentGrouping === MessageTaskGroup.CLIENT;
          const needBindCase = currentGrouping === MessageTaskGroup.CASE;

          if (scope === 'case') {
            return currentCase
              ? renderCurrentCase()
              : needBindCase && renderBindCase();
          }

          if (scope === 'client') {
            const list: React.ReactNode[] = [];

            if (currentClient) {
              list.push(
                <FormItem
                  label={formatMessage('关联客户')}
                  {...(needBindClient
                    ? {
                        name: 'correlationClientId',
                        initialValue: currentClient.id,
                        rules: inputRules,
                      }
                    : null)}
                >
                  <FormText
                    getText={() => (
                      <span className="text-primary">{currentClient.name}</span>
                    )}
                  />
                </FormItem>,
              );
            }

            if (needBindCase) {
              list.push(renderBindCase(currentClient?.id));
            }

            return list;
          }

          return needBindCase
            ? renderBindCase()
            : needBindClient && renderBindClient();
        }}
      </ProFormDependency>
      <ProFormDependency
        name={['grouping', 'triggerEvent', 'correlationCaseId']}
      >
        {({ grouping, triggerEvent, correlationCaseId }) => {
          if (isNil(triggerEvent)) {
            return null;
          }
          const groupingOptions = getGroupingOptions(triggerEvent);
          const currentGrouping = getCurrentGrouping(triggerEvent, grouping);

          const includedCase = // 现在可以在案件里创建定时任务
            messageTaskEventIncludeCaseInfo(triggerEvent) ||
            (correlationCaseId && triggerEvent === MessageTaskEventType.NORMAL);

          // normal 的时候，指定参与专员
          if (triggerEvent === MessageTaskEventType.NORMAL) {
            return (
              <StaffSelect
                name="staffList"
                label={formatMessage('参与专员')}
                fieldProps={{
                  mode: 'multiple',
                }}
              />
            );
          }

          /**
           * 可以直接绑定专员的任务
           * 1. 客户创建
           * 2. 全局任务且 scope 是
           */
          if (
            currentGrouping === MessageTaskGroup.OVERALL ||
            triggerEvent === MessageTaskEventType.ON_CLIENT_CREATED
          ) {
            return (
              <ProFormSelect
                name="personnelName"
                rules={selectRules}
                label={formatMessage('参与人员')}
                valueEnum={{
                  ...clientGroup,
                  ...(includedCase ? caseGroup : null),
                  ...companyGroup,
                }}
                fieldProps={{
                  mode: 'multiple',
                }}
              />
            );
          }

          /**
           * 1. 可以进行分组
           * 2. 没有分组数据直接选择
           */
          if (groupingOptions.length > 0 ? currentGrouping : true) {
            return (
              <FormItem
                name="recipient"
                label={formatMessage('参与人员')}
                rules={selectRules}
              >
                <StaffOrClientSelect includeCase={includedCase} />
              </FormItem>
            );
          }

          return null;
        }}
      </ProFormDependency>

      <ProFormDependency name={['triggerEvent']}>
        {({ triggerEvent }) => {
          if (!triggerEvent) {
            return null;
          }

          return messageTaskEventTypeIsHook(triggerEvent) ? null : (
            <ProForm.Item
              name="triggerDate"
              label={formatMessage('设置时间')}
              rules={selectRules}
            >
              <TriggerDateForm
                onlyAbsoluteDate={triggerEvent === MessageTaskEventType.NORMAL}
                description={dateDescriptors[triggerEvent]}
              />
            </ProForm.Item>
          );
        }}
      </ProFormDependency>

      <ProFormSelect
        options={languageTriggerConditionOptions}
        name="triggerLanguage"
        label={formatMessage('触发语言')}
        extra={formatMessage(
          '当客户的偏爱语言是选中的语言时，才会执行该消息任务',
        )}
      />
    </ProModalForm>
  );
};

export default MessageTaskForm;
