import Icon from '@ant-design/icons';
import React from 'react';

const ReplySVG: React.FC = (props) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M425.984 384q208 29.994667 320.981333 159.018667t148.992 310.997333q-153.984-217.984-470.016-217.984l0 173.994667-297.984-297.984 297.984-297.984 0 169.984z" />
  </svg>
);

export const EmailReplyIcon: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => <Icon component={ReplySVG} {...props} />;

const ForwardSVG: React.FC = (props) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M512 341.333333l0-170.666667 341.333333 341.333333-341.333333 341.333333 0-170.666667-341.333333 0 0-341.333333z" />
  </svg>
);

export const EmailForwardIcon: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => <Icon component={ForwardSVG} {...props} />;
