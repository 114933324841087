import moment from 'moment';
import type { Moment } from 'moment';
import { memo, useEffect, useMemo, useState } from 'react';
import { useInterval } from '@/hooks';
import { formatDate } from '@/utils';

const calculate = (mom: Moment) =>
  mom.diff(moment(), 'day') < -6
    ? formatDate(mom.valueOf(), true)
    : mom.fromNow();

const DynamicRelativeTime: React.FC<{ time: string }> = ({ time }) => {
  const memoedTime = useMemo(() => moment(time), [time]);
  const [relativeTime, set] = useState(() => calculate(memoedTime));

  const { start } = useInterval(() => set(calculate(memoedTime)), {
    loop: true,
    interval: 990,
  });

  useEffect(() => {
    start();
  }, [start]);

  return <>{relativeTime}</>;
};

export default memo(DynamicRelativeTime);
