import { BasicLayoutProps, MenuDataItem } from '@ant-design/pro-layout';
import { notification } from 'antd';
import { isString, templateSettings } from 'lodash';
import QueryString from 'qs';
import React from 'react';
import { getLocale, history } from 'umi';
import logo from '@/assets/logo.png';
import Footer from '@/components/Footer';
import RightContent from '@/components/RightContent';
import { ResponseSchema, services } from './api';
import {
  getLoginUrl,
  isNonLoginStatusPage,
  redirectToUpdatePage,
  resolveUpdateCompletedDirect,
  setToken,
  shouldRedirectUpdatePage,
} from './auth';
import {
  ADMIN_LOGIN,
  BACKEND_PATHNAMES,
  COMPANY_PATHNAMES,
  SYSTEM_UPDATE_PROMPT_PATHNAME,
} from './constant';
import { AccountType, RoleType } from './data';
import { ENV } from './env';
import {
  AppContainer,
  MenuHeaderRender,
  getInitialState as _getInitialState,
} from './layouts/components';
import { initSentry } from './sentry';
import { GlobalState } from './types/API';

templateSettings.interpolate = /{{([\s\S]+?)}}/g;

notification.config({
  placement: 'bottomLeft',
  duration: 2.5,
});

const initAssistant = () => {
  if (!window.location.hostname.includes(ENV.assistDomain)) {
    return;
  }

  const { t } = QueryString.parse(window.location.search.replace('?', ''));

  if (isString(t) && t) {
    setToken(t);
  }
};

initAssistant();

export const getInitialState = _getInitialState;

const sortMenus = (showAll: boolean, menus: MenuDataItem[]): MenuDataItem[] => {
  const target = menus.filter((item) => (showAll ? true : !!item.shortcut));

  const getChildrenLength = (item: MenuDataItem) => {
    if (!item.routes || item.hideChildrenInMenu || item.hideInMenu) {
      return 0;
    }

    return item.routes.filter((menu: any) => !menu.hideInMenu).length;
  };
  const getOrder = (item: MenuDataItem) => {
    const childrenLength = getChildrenLength(item);
    const internalOrder = 'order' in item ? item.order : 0;

    return childrenLength - internalOrder;
  };

  target.sort((a, b) => {
    const aOrder = getOrder(a);
    const bOrder = getOrder(b);

    return aOrder - bOrder;
  });

  return target;
};

export const layout = (
  {
    initialState,
  }: {
    initialState?: Partial<GlobalState>;
  } = { initialState: {} },
): BasicLayoutProps => {
  const currentUser = initialState?.currentUser;

  return {
    logo,
    waterMarkProps:
      initialState?.accountType === AccountType.ASSISTANT
        ? {
            content: currentUser?.information.previousCompanyName ?? undefined,
          }
        : undefined,
    rightContentRender: (props) => <RightContent {...props} />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { pathname } = history.location;

      const isBackend = initialState?.role === 'backend';
      const loggedIn = initialState?.currentUser?.information;

      const isAtHome = pathname === '/';

      if (initialState?.latestRelease?.status === 'updating') {
        if (isBackend || pathname === ADMIN_LOGIN) {
          if (pathname === SYSTEM_UPDATE_PROMPT_PATHNAME || isAtHome) {
            history.replace(BACKEND_PATHNAMES[0]);
          }
        } else if (shouldRedirectUpdatePage(pathname)) {
          redirectToUpdatePage();
        }
        return;
      }

      if (isAtHome && loggedIn) {
        history.replace(isBackend ? BACKEND_PATHNAMES[0] : '/dashboard');

        return;
      }

      if (
        pathname === SYSTEM_UPDATE_PROMPT_PATHNAME &&
        initialState?.latestRelease?.status === 'completed'
      ) {
        resolveUpdateCompletedDirect(isBackend);
        return;
      }

      if (loggedIn) {
        if (pathname.includes('/account')) {
          return;
        }

        if (
          initialState?.currentUser?.information &&
          !COMPANY_PATHNAMES.includes(pathname) &&
          initialState.role === RoleType.GUEST
        ) {
          history.replace('/welcome');
        }

        return;
      }

      if (!isNonLoginStatusPage(pathname)) {
        history.replace(getLoginUrl(true));
      }
    },
    menuHeaderRender: (_logo, title) => (
      <MenuHeaderRender logo={_logo} title={title} />
    ),
    menuDataRender: (menus) => sortMenus(!!initialState?.showAllMenu, menus),
    ...initialState?.settings,
  };
};

initSentry();

export const rootContainer = (
  container: React.ReactElement,
  props: AnyObject,
) => {
  return React.createElement(AppContainer, props as any, container);
};

export const render = async (oldRender: () => void) => {
  if (APP_ENV === 'production') {
    const { data } = await services.checkTime<
      ResponseSchema<{ success: boolean }>
    >({ time: Date.now() });

    if (data.success === false) {
      const locale = getLocale() || 'zh-CN';

      const tips = {
        'zh-CN':
          '温馨提示\n系统检测到设备当前时间不正确，请设置正确的时间后再次打开本系统。',
        'en-US':
          'Warm reminder\nThe system detects that the current time of the device is incorrect. Please set the correct time and open the system again.',
      };
      // eslint-disable-next-line no-alert
      alert(tips[locale]);

      return;
    }
  }

  oldRender();
};
