import { useUnmountedRef, useUpdateEffect } from 'ahooks';
import { notification } from 'antd';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useInternalWebsocket, { ReadyState } from 'react-use-websocket';
import type { Options } from 'react-use-websocket';
import type { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import { useModel } from 'umi';
import { ENV } from '@/env';
import { useFormatMessage } from '@/hooks';
import { UsedWebsocketEvent } from './constants';

export type WebsocketContextState = WebSocketHook<
  | ({ event: UsedWebsocketEvent | ({} & string) } & {
      payload?: any;
      channel?: string;
      msg?: string;
    })
  | null
> & {
  subscribe: (
    params: { event: UsedWebsocketEvent; token?: boolean },
    callback: {
      onSuccess: (...args: any) => void;
      onError?: (msg?: string) => void;
    },
    channel?: string,
  ) => () => void;
};

const GLOBAL_CHANNEL = 'global-channel';

const WebsocketContext = createContext<WebsocketContextState | null>(null);

export const useWebsocket = () => {
  const hook = useContext(WebsocketContext);

  return hook;
};

const NOTIFICATION_KEY = 'websocket-status-notification';

export const UseWebsocketContainer: React.FC<{
  options?: Options;
}> = ({ options, children }) => {
  const unmountedRef = useUnmountedRef();
  const { formatMessage } = useFormatMessage();
  const { initialState } = useModel('@@initialState');

  const [registerQueue] = useState(
    () =>
      new Map<
        string,
        {
          onErrorQueue: Set<(msg?: string) => void>;
          onSuccessMap: Map<UsedWebsocketEvent, Set<(...args: any) => void>>;
        }
      >(),
  );

  const result = useInternalWebsocket(ENV.websocketApiBaseUrl, {
    share: true,
    reconnectInterval: 5000,
    reconnectAttempts: 10,
    shouldReconnect: () =>
      unmountedRef.current === false && APP_ENV !== 'development',
    retryOnError: APP_ENV !== 'development',
    onError() {
      notification.error({
        key: NOTIFICATION_KEY,
        message: formatMessage(
          'Websocket 连接失败，会影响到你正常使用系统，请通知管理员或者刷新浏览器',
        ),
        duration: 30,
        placement: 'top',
      });
    },

    ...options,
  }) as WebsocketContextState;
  const { readyState, sendJsonMessage, lastJsonMessage } = result;
  const token = initialState?.token;

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { channel, event, payload, msg } = lastJsonMessage;

    const internalChannel =
      event === UsedWebsocketEvent.SUBSCRIBE_FAIL
        ? (payload?.channel as string)
        : channel || GLOBAL_CHANNEL;

    if (!internalChannel) {
      return;
    }

    const listeners = registerQueue
      .get(internalChannel)
      ?.onSuccessMap.get((event as unknown) as UsedWebsocketEvent);

    const errorCallbacks = registerQueue.get(internalChannel)?.onErrorQueue;

    if (event === UsedWebsocketEvent.SUBSCRIBE_FAIL) {
      if (errorCallbacks) {
        errorCallbacks.forEach((item) => {
          item(msg);
        });
      }
    } else {
      try {
        listeners?.forEach((item) => {
          item(payload);
        });
        // eslint-disable-next-line no-empty
      } catch (err: any) {}
    }
  }, [lastJsonMessage, registerQueue]);

  const subscribe = useCallback(
    (
      params: { event: UsedWebsocketEvent; token?: boolean },
      callback: {
        onSuccess: (...args: any) => void;
        onError?: (msg?: string) => void;
      },
      channel?: string,
    ) => {
      const key = channel || GLOBAL_CHANNEL;
      const target = registerQueue.get(key);
      const payload: AnyObject = {};
      const { event, token: tokenFlag } = params;

      if (channel) {
        payload.channel = channel;
      }

      if (tokenFlag) {
        payload.token = token;
      }

      if (!target) {
        registerQueue.set(key, {
          onErrorQueue: new Set(),
          onSuccessMap: new Map(),
        });

        if (channel) {
          sendJsonMessage({
            event: 'subscribe',
            payload,
          });
        }
      }

      if (!registerQueue.get(key)?.onSuccessMap.has(event)) {
        registerQueue.get(key)?.onSuccessMap.set(event, new Set());
      }

      if (!registerQueue.get(key)?.onErrorQueue && callback.onError) {
        registerQueue.get(key)!.onErrorQueue = new Set();
      }

      registerQueue.get(key)!.onSuccessMap.get(event)!.add(callback.onSuccess);

      if (callback.onError) {
        registerQueue.get(key)!.onErrorQueue!.add(callback.onError);
      }

      return () => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const target = registerQueue.get(key);
        const { onErrorQueue, onSuccessMap } = target || {};

        if (!target) {
          return;
        }

        onSuccessMap?.get(event)?.delete(callback.onSuccess);

        if (callback.onError) {
          onErrorQueue?.delete(callback.onError);
        }

        if (onSuccessMap?.get(event)?.size === 0) {
          onSuccessMap.delete(event);
        }

        if (onSuccessMap!.size === 0 && channel) {
          sendJsonMessage({
            event: 'subscribeLeave',
            payload,
          });
        }
      };
    },
    [registerQueue, sendJsonMessage, token],
  );

  useEffect(() => {
    return () => {
      if (registerQueue.size > 0) {
        registerQueue.clear();
        sendJsonMessage({ event: 'subscribeClean"' });
      }
    };
  }, [token, sendJsonMessage, registerQueue]);

  useUpdateEffect(() => {
    if (readyState === ReadyState.CONNECTING) {
      notification.warn({
        key: NOTIFICATION_KEY,
        message: formatMessage('Websocket 重新连接中'),
        duration: 30,
        placement: 'top',
      });
    }

    if (readyState === ReadyState.OPEN) {
      return () => {
        notification.close(NOTIFICATION_KEY);
      };
    }

    return undefined;
  }, [readyState]);

  const ctxValue = useMemo(() => ({ ...result, subscribe }), [
    result,
    subscribe,
  ]);

  return (
    <WebsocketContext.Provider value={ctxValue}>
      {children}
    </WebsocketContext.Provider>
  );
};
