import { useLocalStorageState } from 'ahooks';
import moment from 'moment';
import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react';
import {
  UsedWebsocketEvent,
  useWebsocketSubscribe,
} from '@/components/Websocket';
import { getId } from '@/utils';

export type VersionUpdateMessage = {
  versionNumber: string;
  startTime: string;
  endTime: string;
  id: string;
  currentTime: string;
};

const VersionUpdateNotifyContext = createContext<{
  messages: VersionUpdateMessage[];
  clear?: () => void;
}>({
  messages: [],
});

export const useVersionUpdateNotifyContext = () =>
  useContext(VersionUpdateNotifyContext);

const StorageKey = 'VERSION_UPDATE_NOTIFY_LIST';

export const VersionUpdateNotifyProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [messages, setMessages] = useLocalStorageState<VersionUpdateMessage[]>(
    StorageKey,
    [],
  );

  useWebsocketSubscribe<VersionUpdateMessage>(
    UsedWebsocketEvent.VERSION_UPDATE_NOTIFY,
    (message) => {
      setMessages((messages ?? []).concat({ ...message, id: getId() }));
    },
  );

  useLayoutEffect(() => {
    const next = messages.filter((item) => moment().isBefore(item.endTime));

    if (next.length !== messages.length) {
      setMessages(next);
    }
  }, [messages, setMessages]);

  const val = useMemo(
    () => ({
      messages,
      clear: () => {
        setMessages([]);
      },
    }),
    [messages, setMessages],
  );

  return (
    <VersionUpdateNotifyContext.Provider value={val}>
      {children}
    </VersionUpdateNotifyContext.Provider>
  );
};
