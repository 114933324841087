import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

const FunctionGuideContext = createContext<{
  register?: (key: string, fn: () => void) => void;
  complete?: (key: string) => void;
}>({});

export const useFunctionGuideContext = () => useContext(FunctionGuideContext);

const FunctionGuideProvider: React.FC = ({ children }) => {
  const queue = useRef<{ key: string; fn: () => void }[]>([]);

  const open = useRef(false);

  const next = useCallback(() => {
    if (queue.current.length > 0) {
      const latest = queue.current.shift()!;

      latest.fn();
      open.current = true;
    } else {
      open.current = false;
    }
  }, []);

  const complete = useCallback(
    (key?: string) => {
      const index = queue.current.findIndex((item) => item.key === key);

      if (index > -1) {
        queue.current.splice(index, 1);
      }

      next();
    },
    [next],
  );

  const register = useCallback((key: string, fn: () => void) => {
    if (open.current) {
      queue.current.push({ key, fn });
    } else {
      fn();

      open.current = true;
    }

    return () => {
      queue.current = queue.current.filter((item) => item.fn !== fn);
    };
  }, []);

  const ctxValue = useMemo(() => ({ register, complete }), [
    register,
    complete,
  ]);

  return (
    <FunctionGuideContext.Provider value={ctxValue}>
      {children}
    </FunctionGuideContext.Provider>
  );
};

export default FunctionGuideProvider;
