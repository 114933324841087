import { FormatMessage, Options } from '@/types';

export const getRelationshipToYouOptions = (formatMessage: FormatMessage) => [
  {
    label: formatMessage({
      id: 'apply-form:relationship-to-you.option.parent',
      defaultMessage: '父母',
    }),
    value: 'parents',
  },
  {
    label: formatMessage({
      id: 'apply-form:relationship-to-you.option.relative',
      defaultMessage: '亲戚',
    }),
    value: 'relatives',
  },
  {
    label: formatMessage({
      id: 'apply-form:relationship-to-you.option.partner',
      defaultMessage: '伴侣',
    }),
    value: 'partner',
  },
  {
    label: formatMessage({
      id: 'apply-form:relationship-to-you.option.frirend',
      defaultMessage: '朋友',
    }),
    value: 'friends',
  },
  {
    label: formatMessage({
      id: 'apply-form:relationship-to-you.option.other',
      defaultMessage: '其他',
    }),
    value: 'other',
  },
];

export const getHealthStatusOptions = (
  formatMessage: FormatMessage,
): Options => [
  {
    label: formatMessage({
      id: 'apply-form:health-status.option.hearing-deaf',
      defaultMessage: '听力/聋哑',
    }),
    value: 'hearing/deaf',
  },
  {
    label: formatMessage({
      id: 'apply-form:health-status.option.intellectual',
      defaultMessage: '智力缺陷',
    }),
    value: 'intellectual',
  },
  {
    label: formatMessage({
      id: 'apply-form:health-status.option.mobility',
      defaultMessage: '行动障碍',
    }),
    value: 'mobility',
  },
  {
    label: formatMessage({
      id: 'apply-form:health-status.option.learning',
      defaultMessage: '学习障碍',
    }),
    value: 'learning',
  },
  {
    label: formatMessage({
      id: 'apply-form:health-status.option.visual',
      defaultMessage: '视力缺陷',
    }),
    value: 'visual',
  },
  {
    label: formatMessage({
      id: 'apply-form:health-status.option.mental-illness',
      defaultMessage: '精神疾病',
    }),
    value: 'mental illness',
  },
  {
    label: formatMessage({
      id: 'apply-form:health-status.option.medical-condition',
      defaultMessage: '健康状况',
    }),
    value: 'medical condition',
  },
];

export const getApplicationLocationOptions = (formatMessage: FormatMessage) => [
  { label: formatMessage({ defaultMessage: '澳洲境内' }), value: 'onshore' },
  { label: formatMessage({ defaultMessage: '澳洲境外' }), value: 'offshore' },
];

export const getApplicationLocationName = (
  value: string,
  formatMessage: FormatMessage,
) =>
  getApplicationLocationOptions(formatMessage).find(
    (item) => item.value === value,
  )?.label;
