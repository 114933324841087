import IAccessable from './IAccessable';
import { useExtraPermissions } from './hooks';

export enum ExtraPermissionEnum {
  FileAssistant = 'archiveFileOpen',
  FileSign = 'fileSignOpen',
  Bill = 'billOpen',
  Point = 'pointOpen',
  VevoQuery = 'isVisaVevoQuery',
}

const createExtraPermission = (extraPermission: ExtraPermissionEnum) => {
  const ExtraPermissionAccessable: React.FC<{
    permission?: ExtraPermissionEnum;
  }> = ({ permission, children }) => {
    const accessable = useExtraPermissions(permission || extraPermission);

    return <IAccessable authority={accessable}>{children}</IAccessable>;
  };

  return ExtraPermissionAccessable;
};

export default createExtraPermission;
