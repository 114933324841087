import { ArrowRightOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Badge } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'umi';
import { services } from '@/api';
import { useFormatMessage } from '@/hooks';
import { formatDate } from '@/utils';
import styles from './ReleaseLogItem.less';

export type ReleaseLogItemData = {
  actualEndTime: string;
  id: number;
  isRead: 0 | 1;
  publisherAvatar: null | string;
  publisherNickname: string | null;
  summary: string | null;
  title: string;
  versionNumber: string;
  onRead?: () => void;
};

const ReleaseLogItem: React.FC<ReleaseLogItemData & { className?: string }> = ({
  id,
  isRead,
  versionNumber,
  title,
  actualEndTime,
  summary,
  onRead,
  className,
}) => {
  const { formatMessage } = useFormatMessage();
  const [ref, inView] = useInView();
  const { run } = useRequest(services.systemUpdate.front.markReleaseLogAsRead, {
    manual: true,
    onSuccess: onRead,
  });

  useEffect(() => {
    if (inView && !isRead) {
      run({ id });
    }
  }, [inView, id, run, isRead]);

  const content = (
    <h3>
      <span>{versionNumber}:</span> {title}
    </h3>
  );

  return (
    <div ref={ref} key={id} className={classNames(styles.item, className)}>
      {isRead ? (
        content
      ) : (
        <Badge.Ribbon text={formatMessage('未读')} color="red">
          {content}
        </Badge.Ribbon>
      )}

      <p className={styles.date}>
        {formatDate(actualEndTime, false, 'DD/MM/YYYY HH:mm')}
      </p>
      <div className={styles.summary}>
        <p>{formatMessage('主要更新内容')}:</p>
        {summary
          ?.split('\n')
          .filter(Boolean)
          .map((text) => (
            <p key={text}>{text}</p>
          ))}
      </div>
      <Link className={styles.more} to={`/releases/${id}`}>
        {formatMessage('查看更多')} <ArrowRightOutlined />
      </Link>
    </div>
  );
};

export default ReleaseLogItem;
