import type { HeaderViewProps } from '@ant-design/pro-layout/lib/Header';
import { Space, Tag } from 'antd';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { clearBodyLocks } from 'tua-body-scroll-lock';
import { SelectLang, useLocation, useModel } from 'umi';
import { AccountType, RoleType } from '@/data';
import { MessageNotifyDrawer } from '../Message';
import Avatar from './AvatarDropdown';
import HelpCenterDropdown from './HelpCenterDropdown';
import MessageDropdown from './MessageDropdown';
import SwitchCompany from './SwitchCompany';
import styles from './index.less';

const data = [
  {
    lang: 'en-US',
    label: 'English',
    icon: ' ',
    title: 'Language',
  },
  {
    lang: 'zh-CN',
    label: '简体中文',
    icon: ' ',
    title: '语言',
  },
];

const clearAllBodyLocks = () => {
  const { body } = document;

  clearBodyLocks();

  if (
    body.classList.contains('ant-scrolling-effect') ||
    body.style.overflow === 'hidden'
  ) {
    body.classList.remove('ant-scrolling-effect');
    body.style.overflow = '';
    body.style.width = '';
  }
};

// const langs = ['zh-CN', 'en-US'];

const GlobalHeaderRight: React.FC<HeaderViewProps> = ({ isMobile }) => {
  const { initialState } = useModel('@@initialState');
  const location = useLocation();

  useEffect(clearAllBodyLocks, [location]);

  if (!initialState || !initialState.settings) {
    return null;
  }

  const { navTheme, layout } = initialState.settings;

  const { token, role } = initialState;
  const roles = [
    RoleType.AGENT,
    RoleType.CHANNEL,
    RoleType.SCHOOL,
    RoleType.GUEST,
  ];
  const showEnv = APP_ENV === 'development' || APP_ENV === 'test';
  const env = APP_ENV === 'development' ? 'DEV' : 'TEST';

  return (
    <>
      <Space
        align="center"
        className={classNames(styles.right, {
          [styles.dark]:
            (navTheme === 'dark' && layout === 'top' && !isMobile) ||
            layout === 'mix',
        })}
        size={0}
      >
        {((role && roles.includes(role)) ||
          initialState.accountType === AccountType.ASSISTANT) && (
          <SwitchCompany />
        )}
        {role !== RoleType.BACKEND && <HelpCenterDropdown />}
        {token && <MessageDropdown />}
        {showEnv && !isMobile && (
          <Tag style={{ marginLeft: 8, marginRight: 0 }} color="error">
            {env}
          </Tag>
        )}

        <Avatar />
        <SelectLang reload={false} postLocalesData={() => data} />
      </Space>

      <MessageNotifyDrawer />
    </>
  );
};
export default GlobalHeaderRight;
