import type { TextAreaProps } from 'antd/lib/input';
import type { InputNumberProps } from 'antd/lib/input-number';
import { formatNumber } from './utils';

export interface PublicModalLayoutConfig {
  width: number;
  widerWidth: number;
  style: React.CSSProperties;
  centered?: boolean;
  bodyStyle: {
    maxHeight: number | string;
    willChange: string;
    overflow: React.CSSProperties['overflow'];
    overflowX: React.CSSProperties['overflowX'];
  };
}

export const modalLayout: PublicModalLayoutConfig = {
  width: 478,
  widerWidth: 746,
  style: {},
  bodyStyle: {
    maxHeight: 'calc(80vh - 60px)',
    overflow: 'auto',
    overflowX: 'auto',
    willChange: 'height',
  },
};

export const getPopupContainerProps = {
  getPopupContainer: (node: HTMLElement) =>
    node?.parentElement ?? document.body,
};

export const getTextareaSizeProps = (
  limit: {
    min: number;
    max: number;
  } = { min: 2, max: 250 },
): TextAreaProps => ({
  autoSize: {
    minRows: limit?.max ? Math.max(4, Math.ceil(limit.max / 125)) : 4,
    maxRows: limit?.max ? Math.max(7, Math.ceil(limit.max / 80)) : 7,
  },
  minLength: limit?.min,
  maxLength: limit?.max,
  showCount: true,
});

export const DATE_FORMAT = 'DD/MM/YYYY';

export const MONTH_FORMAT = 'MM/YYYY';

export const datePickerProps = {
  style: {
    width: '100%',
  },
  format: DATE_FORMAT,
};

export const monthPickerProps = {
  style: {
    width: '100%',
  },
  format: MONTH_FORMAT,
  picker: 'month',
} as const;

export const getInputNumberProps = (
  precision = 2,
): Pick<InputNumberProps, 'precision' | 'parser' | 'formatter'> => ({
  precision,
  parser: (value) => Number(value?.replace(/,|\s/g, '') ?? 0),
  formatter: (val, info) => {
    if (!val) {
      return '';
    }

    if (!info?.userTyping) {
      return formatNumber(val, precision > 0, precision);
    }

    const [int, decimal = ''] = String(val).split('.');

    return [formatNumber(int), decimal.slice(0, precision)]
      .filter(Boolean)
      .join('.');
  },
});
