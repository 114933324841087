import { usePersistFn } from 'ahooks';
import { Method } from 'axios';
import Eventemitter from 'eventemitter3';
import { useEffect } from 'react';
import type { RequestPlugin } from 'zyouh-request';
import type { ResponseSchema } from './services';

type IRequestListenerData<D = any> = {
  url: string;
  method: Method;
  data: ResponseSchema<D>;
};

const eventemitter = new Eventemitter<string, IRequestListenerData>();
const EVENT_NAME = 'REQUEST_LISTENER';

export const requestListenerPlugin: RequestPlugin = {
  name: 'requestListener',
  managers: {
    response: [
      (resp) => {
        const { config, data } = resp;

        eventemitter.emit(EVENT_NAME, {
          url: config.url!,
          method: config.method,
          data,
        });

        return resp;
      },
    ],
  },
};

export const useSubscribeRequest = (
  onCallback: (data: IRequestListenerData) => void,
  onBefore?: (data: IRequestListenerData) => void | boolean,
) => {
  const fn = usePersistFn(onCallback);
  const persistedOnBefore = usePersistFn(onBefore || (() => {}));
  const handler = usePersistFn((data: IRequestListenerData) => {
    const flag = persistedOnBefore(data);

    if (flag === true || flag === undefined) {
      fn(data);
    }
  });

  useEffect(() => {
    eventemitter.on(EVENT_NAME, handler);

    return () => {
      eventemitter.off(EVENT_NAME, handler);
    };
  }, [handler]);
};
