import { FormatMessage, Options } from '@/types';

export const getProofOfWorkOptions = (
  formatMessage: FormatMessage,
): Options => [
  {
    value: 'employment',
    label: formatMessage({
      id: 'app.proof-of-work.employment',
    }),
  },
  {
    value: 'resignation',
    label: formatMessage({
      id: 'app.proof-of-work.resignation',
    }),
  },
  {
    value: 'payslip',
    label: formatMessage({
      id: 'app.proof-of-work.payslip',
    }),
  },
  {
    value: 'taxRecord',
    label: formatMessage({
      id: 'app.proof-of-work.tax-record',
    }),
  },
  {
    value: 'other',
    label: formatMessage({
      id: 'app.proof-of-work.other',
    }),
  },
];

export const getProofOfWorkName = (
  value: string,
  formatMessage: FormatMessage,
) =>
  getProofOfWorkOptions(formatMessage).find((item) => item.value === value)
    ?.label;
