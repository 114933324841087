import { usePersistFn } from 'ahooks';
import { useModel } from 'umi';
import { useDeepMemorizedEffect } from '@/hooks';
import { useWebsocket } from './WebSocket';
import { UsedWebsocketChannel, UsedWebsocketEvent } from './constants';

function useWebsocketSubscribe<D extends any = any>(
  eventName: UsedWebsocketEvent,
  callback: (data: D) => void,
  options?:
    | {
        scope?: string | number;
        channel?: Exclude<
          UsedWebsocketChannel,
          | UsedWebsocketChannel.COMPANY
          | UsedWebsocketChannel.MEMBER
          | UsedWebsocketChannel.COMPANY_MEMBER
        >;
        token?: false;
        onChannelFormat?: (channel: string) => string;
        deps?: React.DependencyList;
        onError?: (msg?: string) => void;
      }
    | {
        channel:
          | UsedWebsocketChannel.COMPANY
          | UsedWebsocketChannel.MEMBER
          | UsedWebsocketChannel.COMPANY_MEMBER;
        onChannelFormat?: (channel: string) => string;
        deps?: React.DependencyList;
        onError?: (msg?: string) => void;
      },
  ready = true,
) {
  const { subscribe } = useWebsocket() ?? {};
  const listener = usePersistFn(callback);
  const { initialState } = useModel('@@initialState');

  const { companyId, memberId, companyMemberId } = initialState ?? {};
  const {
    // @ts-ignore
    scope,
    channel,
    // @ts-ignore
    token = true,
    onChannelFormat = (x: string) => x,
    deps,
    onError,
  } = options ?? {};

  const channelFormat = usePersistFn(onChannelFormat);

  useDeepMemorizedEffect(() => {
    if (!subscribe || !ready) {
      return undefined;
    }

    const memberChannels = [
      [UsedWebsocketChannel.COMPANY_MEMBER, companyMemberId],
      [UsedWebsocketChannel.MEMBER, memberId],
      [UsedWebsocketChannel.COMPANY, companyId],
    ] as const;

    if (memberChannels.some((item) => item[0] === channel && !item[1])) {
      return undefined;
    }

    const targetChannelConfig = memberChannels.find(
      (item) => item[0] === channel,
    );

    const internalChannel = channel
      ? [channel, targetChannelConfig ? targetChannelConfig[1] : scope]
          .filter(Boolean)
          .join(':')
      : undefined;

    return subscribe(
      {
        event: eventName,
        token,
      },
      {
        onSuccess: listener,
        onError,
      },
      internalChannel ? channelFormat(internalChannel) : undefined,
    );
  }, [
    subscribe,
    listener,
    eventName,
    scope,
    channel,
    token,
    ready,
    channelFormat,
    onError,
    companyId,
    memberId,
    companyMemberId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...(deps ?? []),
  ]);
}

export default useWebsocketSubscribe;
