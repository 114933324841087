import type { TagProps } from 'antd';
import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum FileAssistantType {
  CoE = 'CoE',
  OSHC = 'oshc',
  Visa = 'visa',
  Passport = 'passport',
  Offer = 'offer',
  Other = 'other',
}

export const useFileAssistantEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () =>
      ({
        [FileAssistantType.CoE]: {
          text: formatMessage('澳洲学习经历'),
          color: 'blue',
        },
        [FileAssistantType.OSHC]: {
          text: formatMessage('学生医疗保险'),
          color: 'cyan',
        },
        [FileAssistantType.Visa]: {
          text: formatMessage('签证'),
          color: 'gold',
        },
        [FileAssistantType.Passport]: {
          text: formatMessage('护照'),
          color: 'orange',
        },
        [FileAssistantType.Offer]: {
          text: formatMessage('澳洲 Offer'),
          color: 'purple',
        },
        [FileAssistantType.Other]: {
          text: formatMessage('其它'),
          color: 'pink',
        },
      } as Record<
        FileAssistantType,
        {
          text: string;
          color?: TagProps['color'];
        }
      >),
    [formatMessage],
  );
};
