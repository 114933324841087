import { logout } from '@/auth';
import {
  UsedWebsocketChannel,
  UsedWebsocketEvent,
  useWebsocketSubscribe,
} from '../../components/Websocket';
import { useAppGlobalStateContext } from './AppGlobalState';

const InnerLayout: React.FC = () => {
  const { update } = useAppGlobalStateContext();

  // 版本更新
  useWebsocketSubscribe(UsedWebsocketEvent.VERSION_UPDATE_STATUS, update);

  useWebsocketSubscribe(
    UsedWebsocketEvent.CLOSE,
    () => {
      if (APP_ENV === 'production') {
        logout();
      }
    },
    {
      channel: UsedWebsocketChannel.MEMBER,
    },
  );

  return null;
};

export default InnerLayout;
