import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Menu } from 'antd';
import React from 'react';
import { ResponseSchema, services } from '@/api';
import { getAssistanceUrl } from '@/auth';
import { LinkOptionButton } from '@/common';
import styles from './index.less';

const AssistantSwitchCompanyItem: React.FC<{
  id: string;
  name: string;
  active?: boolean;
}> = ({ id, name, active }) => {
  const { mutate, isLoading } = useMutation<
    ResponseSchema<{
      token: string;
      svcNumber: string;
    }>,
    any,
    { id: string }
  >(
    [services.assist.getAssistanceToken.key],
    services.assist.getAssistanceToken,
    {
      onSuccess({ data: { svcNumber, token } }) {
        const url = getAssistanceUrl(svcNumber, token);
        window.location.href = url;
      },
    },
  );

  return (
    <Menu.Item
      onClick={() => {
        mutate({ id });
      }}
      style={{ textAlign: 'left' }}
    >
      <div className={styles.companyItem}>
        {isLoading && <LoadingOutlined style={{ marginRight: 4 }} spin />}
        <h4 className={styles.companyName}>{name}</h4>
        {active && (
          <LinkOptionButton className={styles.companyAction}>
            <CheckOutlined />
          </LinkOptionButton>
        )}
      </div>
    </Menu.Item>
  );
};

export default AssistantSwitchCompanyItem;
