import type { EmailInfo } from './EmailEditor';

const formatEmailHTML = (html?: string) => {
  if (!html) {
    return '';
  }

  const div = document.createElement('div');
  div.innerHTML = html.replace(/<meta[^>]*>(?:[\s\S]*<\/meta>)?/g, '');

  Array.from(div.querySelectorAll('strong a')).forEach((anchor) => {
    const parent = anchor.parentElement;

    anchor.innerHTML = `<strong>${anchor.innerHTML}</strong>`;

    if (parent?.parentElement) {
      if (parent.nextElementSibling) {
        parent.parentElement.insertBefore(parent.nextElementSibling, anchor);
      } else {
        parent.parentElement.appendChild(anchor);
      }
    }
  });

  const result = div.innerHTML;

  return result.replace(/div/g, 'p');
};

export const getEmailSubject = (type: string, subject?: string) => {
  return `${type === 'forward' ? 'Fwd' : 'Re'}: ${subject || ''}`;
};

export const getEmailContent = (
  type: string,
  content: string,
  { from, toFull, subject, name }: EmailInfo,
) => {
  let result = '';

  if (type === 'forward') {
    result = `<p></p><p>---------------- Forwarded message ----------------</p><p>From: ${name} ${new Date().toString()}</p><p>To: ${toFull
      .map((item) => `&lt;${item.email}&gt;`)
      .join(', ')}</p>${
      subject ? `<p>Subject: ${getEmailSubject('forward', subject)}</p>` : ''
    }${formatEmailHTML(content)}`;
  } else {
    result = `<p>On ${new Date().toString()}, ${
      name ? `<b>${name}</b>&nbsp;&nbsp;` : ''
    } &lt;${from}&gt; wrote:</p><p></p><p>---------------- Original message ----------------</p>${formatEmailHTML(
      content,
    )}`;
  }

  return result;
};
