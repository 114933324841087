import { useEffect, useState } from 'react';

const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handler = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handler);

    handler();

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return { screenWidth: width };
};

export default useScreenWidth;
