import classNames from 'classnames';
import React from 'react';
import styles from './index.less';

export interface SetInnerHTMLProps
  extends React.HTMLAttributes<HTMLDivElement> {
  html?: string;
  component?: keyof JSX.IntrinsicElements;
  style?: React.CSSProperties;
  className?: string;
}

const SetInnerHTML = React.forwardRef<HTMLElement, SetInnerHTMLProps>(
  ({ html, component = 'div', style, className, ...rest }, ref) => {
    return React.createElement(component!, {
      style,
      className: classNames(styles.container, className),
      dangerouslySetInnerHTML: { __html: html },
      ...rest,
      ref,
    });
  },
);

export default SetInnerHTML;
