import {
  CompressOutlined,
  MinusOutlined,
  PlusOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Result, Spin } from 'antd';
import clsx from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DocumentProps, Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { useMeasure } from '@/hooks';
import styles from './index.less';

export interface PdfViewProps {
  url: string;
  className?: string;
  maxScale?: number;
  minScale?: number;
  scaleDelta?: number;
  style?: React.CSSProperties;
  classNames?: Partial<Record<'root' | 'wrapper' | 'document', string>>;
  rotation?: number;
  onRotationChange?: (rotation: number) => void;
}

const PdfView = React.forwardRef<HTMLDivElement, PdfViewProps>(
  (
    {
      url,
      className,
      maxScale = 2,
      minScale = 0.6,
      scaleDelta = 0.25,
      style,
      classNames,
      ...props
    },
    ref,
  ) => {
    const [pages, setPages] = useState(0);
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useControllableValue<number>(props, {
      valuePropName: 'rotation',
      trigger: 'onRotationChange',
      defaultValue: 0,
    });

    const {
      setRef,
      data: { width: w },
    } = useMeasure({
      format(target) {
        if (!target) {
          return { width: 0 };
        }

        return {
          width: (target.parentNode as HTMLDivElement).clientWidth - 8,
        };
      },
    });

    const minW = Math.max(1, w);
    const width = Math.min(1200, minW);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const handleDocumentLoadSuccess: NonNullable<
      DocumentProps['onLoadSuccess']
    > = useCallback((document) => {
      setPages(document.numPages);
    }, []);

    const handleRotate = () => {
      setRotation((rotation + 90) % 360);
    };

    const handleZoomIn = useCallback(() => {
      setScale((prev) => Math.max(minScale!, prev - scaleDelta!));
    }, [scaleDelta, minScale]);

    const handleZoomOut = useCallback(() => {
      setScale((prev) => Math.min(maxScale!, prev + scaleDelta!));
    }, [scaleDelta, maxScale]);

    const handleZoomReset = useCallback(() => {
      setScale((prev) =>
        prev === 1 ? Math.max(minScale!, Math.min(maxScale!, minW / width)) : 1,
      );
    }, [minScale, maxScale, minW, width]);

    const pageList = useMemo(
      () =>
        Array.from({ length: pages }, (_, i) => ({
          page: i + 1,
          key: `${url}_${i + 1}`,
        })),
      [pages, url],
    );

    return (
      <div
        ref={ref}
        className={clsx(styles.pdfContainer, className, classNames?.root)}
        style={style}
      >
        <div
          className={clsx(styles.pdfWrapper, classNames?.wrapper)}
          ref={wrapperRef}
        >
          <div
            ref={setRef}
            className={clsx(styles.pdfDocument, classNames?.document)}
          >
            <div
              style={{
                width: width * scale,
                margin: '0 auto',
              }}
            >
              <Document
                loading={
                  <Spin
                    style={{
                      width: width || 1200,
                    }}
                    className={styles.pdfStatus}
                    size="large"
                  />
                }
                options={{
                  cMapUrl: '/cmaps/',
                  cMapPacked: true,
                }}
                error={
                  <Result
                    style={{
                      width: width || 1200,
                    }}
                    title="Loading Error"
                    className={styles.pdfStatus}
                    subTitle="Please try again later"
                    status="error"
                  />
                }
                onLoadSuccess={handleDocumentLoadSuccess}
                file={url}
              >
                {pageList.map((item, index) => (
                  <div
                    className={styles.pdfPage}
                    style={{
                      width: width * scale,
                    }}
                    key={item.key}
                  >
                    <Page
                      rotate={rotation}
                      renderTextLayer={false}
                      scale={scale}
                      width={width}
                      pageNumber={item.page}
                      renderAnnotationLayer={false}
                    />
                  </div>
                ))}
              </Document>
            </div>
          </div>
        </div>

        <div className={styles.action}>
          <div className={styles.actionItem}>
            <CompressOutlined onClick={handleZoomReset} />
          </div>
          <div className={styles.actionItem}>
            <RedoOutlined onClick={handleRotate} />
          </div>
          <div className={styles.actionItem}>
            <PlusOutlined onClick={handleZoomOut} />
          </div>
          <div className={styles.actionItem}>
            <MinusOutlined onClick={handleZoomIn} />
          </div>
        </div>
      </div>
    );
  },
);

export default PdfView;
