export default {
  'form:text.length':
    'Please enter at least {min} characters up to {max} characters',
  'form:input.placeholder': 'Please enter',
  'form:input.required-tips': 'Required',
  'form:select.placeholder': 'Please choose',
  'form:select.required-tips': 'Required',
  'form:file.placeholder': 'Please upload',
  'form:file.required-tips': 'Required',
  'form:country-code': 'Area',
  'form:add.placeholder': 'Please add in',
  'form:add.required-tips': 'Required',

  'form:phone.wrong-format': 'Please enter a valid phone number',
  'form:email.wrong-format': 'Please enter your vaild email',

  'form:sex.option.man': 'Male',
  'form:sex.option.woman': 'Female',
  'form:sex.option.x': 'X',

  'form:sure-btn-text': 'Sure',
  'form:add-btn-text': 'Add to',
  'form:submit-btn-text': 'Submit',
  'form:cancel-btn-text': 'Cancel',
  'form:delete-btn-text': 'Delete',
  'form:edit-btn-text': 'Edit',
  'form:preview-btn-text': 'Preview',
  'form:action-text': 'Action',

  'form:yesno.option.yes': 'Yes',
  'form:yesno.option.no': 'No',

  'form:view-detail-text': 'View Detail',
  'form:keyword-text': 'Keyword',
};
