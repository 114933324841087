import { useDrag, usePinch } from '@use-gesture/react';
import { useUnmountedRef } from 'ahooks';
import {
  MotionValue,
  animate,
  motion,
  useMotionTemplate,
  useMotionValue,
} from 'framer-motion';
import { clamp } from 'lodash';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { useMeasure } from '@/hooks';
import styles from './index.less';

interface PanPinchParamsProps {
  maxScale?: number;
  minScale?: number;
  scaleDelta?: number;
  url: string;
  children: (
    elem: React.ReactElement,
    params: {
      zoomIn: () => void;
      zoomOut: () => void;
      reset: (doAninmate?: boolean) => void;
      rotate: (angle?: number) => void;
    },
  ) => React.ReactNode;
}

const scaleToFromOrigin = (
  scaleOrigin: [number, number],
  scaleDelta: number,
  xy: number[],
) => [
  scaleOrigin[0] + (xy[0] - scaleOrigin[0]) * scaleDelta,
  scaleOrigin[1] + (xy[1] - scaleOrigin[1]) * scaleDelta,
];
const PanPinch: React.FC<PanPinchParamsProps> = ({
  url,
  children,
  maxScale = 2.5,
  minScale = 0.5,
  scaleDelta = 0.5,
}) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const scale = useMotionValue(1);
  const roation = useMotionValue(0);
  const animationCtrls = useRef<ReturnType<typeof animate>[]>([]);
  // 记录上一次的缩放中心，用于手动缩放
  const lastScaleOrigin = useRef<[number, number]>([0, 0]);

  const containerRef = useRef<HTMLDivElement>(null);
  const unmountedRef = useUnmountedRef();
  const {
    data: { width: containerWidth, height: containerHeight },
  } = useMeasure({
    ref: containerRef,
  });
  const {
    setRef: bindImg,
    data: { left: width, height },
  } = useMeasure();

  const reset = useCallback(
    (doAnimate = true) => {
      if (
        containerHeight &&
        containerWidth &&
        width &&
        height &&
        !unmountedRef.current
      ) {
        animationCtrls.current = [
          animate(x, 0, doAnimate),
          animate(y, 0, doAnimate),
          animate(scale, 1, doAnimate),
          animate(roation, 0, doAnimate),
        ];
      }
    },
    [
      containerHeight,
      containerWidth,
      width,
      height,
      x,
      y,
      scale,
      roation,
      unmountedRef,
    ],
  );

  const setValue = useCallback(
    (value: MotionValue, target: number, doAnimate: boolean = false) => {
      if (doAnimate) {
        animationCtrls.current.push(
          animate(value, target, {
            type: 'spring',
            damping: 50,
            stiffness: 600,
          }),
        );
      } else {
        value.set(target);
      }
    },
    [],
  );

  const getBase = useCallback(() => {
    const rect = containerRef.current!.getBoundingClientRect();

    return [rect.left + containerWidth / 2, rect.top + containerHeight / 2];
  }, [containerHeight, containerWidth]);

  const stopAnimations = useCallback(() => {
    if (animationCtrls.current.length) {
      animationCtrls.current.forEach((item) => item.stop());
      animationCtrls.current = [];
    }
  }, []);

  const scaleTo = useCallback(
    (delta: number, origin: [number, number] = lastScaleOrigin.current) => {
      stopAnimations();

      const cs = scale.get();
      const target = clamp(cs + delta, minScale, maxScale);

      animationCtrls.current.push(
        animate(cs, target, {
          onUpdate(current) {
            const [cx, cy] = scaleToFromOrigin(origin, current / scale.get(), [
              x.get(),
              y.get(),
            ]);
            setValue(scale, current, false);
            setValue(x, cx, false);
            setValue(y, cy, false);
          },
          type: 'spring',
          damping: 30,
          velocity: 0.5,
          stiffness: 300,
        }),
      );
    },
    [minScale, maxScale, x, y, scale, stopAnimations, setValue],
  );

  const zoomIn = useCallback(() => {
    scaleTo(scaleDelta);
  }, [scaleDelta, scaleTo]);

  const zoomOut = useCallback(() => {
    scaleTo(-scaleDelta);
  }, [scaleDelta, scaleTo]);

  const rotate = useCallback(
    (angle = 90) => {
      if (roation.isAnimating()) {
        return;
      }

      lastScaleOrigin.current = [0, 0];

      animate(roation, roation.get() + angle, {
        onComplete() {
          setValue(roation, roation.get() % 360, false);
        },
      });
    },
    [roation, setValue],
  );

  const handleDoubleClick = useCallback(
    (evt: React.MouseEvent<HTMLImageElement>) => {
      stopAnimations();

      const { clientX, clientY } = evt;
      const base = getBase();
      const middleScale = (minScale + maxScale) / 2;
      const cs = scale.get();
      const scaleOrigin: [number, number] = [
        clientX - base[0],
        clientY - base[1],
      ];
      const nextScale = cs > middleScale ? 1 : maxScale;

      scaleTo(nextScale - cs, scaleOrigin);
    },
    [scale, minScale, maxScale, stopAnimations, getBase, scaleTo],
  );

  const getDragBounds = () => {
    const nextScale = clamp(scale.get(), minScale, maxScale);

    const l = -(nextScale * width - containerWidth) / 2;
    const r = (nextScale * width - containerWidth) / 2;
    const t = -(nextScale * height - containerHeight) / 2;
    const b = (nextScale * height - containerHeight) / 2;

    return {
      left: Math.min(0, l - containerWidth),
      right: Math.max(0, r + containerWidth),
      top: Math.min(0, t - containerHeight),
      bottom: Math.max(0, b + containerHeight),
      scale: nextScale,
    };
  };

  useDrag(
    // eslint-disable-next-line consistent-return
    ({ offset: [cx, cy], dragging, first, pinching, cancel }) => {
      if (pinching) {
        return cancel();
      }

      if (first) {
        lastScaleOrigin.current = [0, 0];
      }

      stopAnimations();
      setValue(x, cx, !dragging);
      setValue(y, cy, !dragging);
    },
    {
      target: containerRef,
      from: () => [x.get(), y.get()],
      rubberband: true,
      bounds: getDragBounds,
    },
  );

  usePinch(
    // eslint-disable-next-line consistent-return
    ({ pinching, axis, origin, offset, memo }) => {
      if (axis === 'scale') {
        memo ??= getBase();
        stopAnimations();

        const cs = offset[0];

        const pinchScaleDelta = cs / scale.get();
        const cx = x.get();
        const cy = y.get();
        const scaleOrigin: [number, number] = [
          origin[0] - memo[0],
          origin[1] - memo[1],
        ];
        lastScaleOrigin.current = scaleOrigin;

        const [nx, ny] = scaleToFromOrigin(scaleOrigin, pinchScaleDelta, [
          cx,
          cy,
        ]);
        setValue(x, nx, !pinching);
        setValue(y, ny, !pinching);
        setValue(scale, cs, !pinching);

        return memo;
      }
    },
    {
      target: containerRef,
      scaleBounds: {
        min: minScale,
        max: maxScale,
      },
      rubberband: true,
    },
  );

  const transform = useMotionTemplate`translate3d(${x}px, ${y}px, 0) rotate(${roation}deg)  scale(${scale})`;

  useLayoutEffect(() => {
    reset(false);

    return () => {
      stopAnimations();
    };
  }, [reset, stopAnimations]);

  const elem = (
    <div className={styles.wrapper}>
      <div
        ref={containerRef}
        className={styles.container}
        onDoubleClick={handleDoubleClick}
      >
        <motion.img
          style={{
            transform,
          }}
          src={url}
          ref={bindImg}
        />
      </div>
    </div>
  );

  return (
    <>
      {children(elem, {
        zoomIn,
        zoomOut,
        reset,
        rotate,
      })}
    </>
  );
};

export default PanPinch;
