import { FormatMessage, Options } from '@/types';

export const getEmploymentStatusOptions = (
  formatMessage: FormatMessage,
): Options => [
  {
    label: formatMessage({
      id: 'apply-form:employment-status.option.on-job',
      defaultMessage: '在职',
    }),
    value: 'on-job',
  },
  {
    label: formatMessage({
      id: 'apply-form:employment-status.option.resign',
      defaultMessage: '离职',
    }),
    value: 'resign',
  },
  {
    label: formatMessage({
      id: 'apply-form:employment-status.option.retired',
      defaultMessage: '退休',
    }),
    value: 'retired',
  },
  {
    label: formatMessage({
      id: 'apply-form:employment-status.option.suspended',
      defaultMessage: '停职',
    }),
    value: 'suspended',
  },
];

export const getEmploymentStatusName = (
  value: string,
  formatMessage: FormatMessage,
) => {
  const options = getEmploymentStatusOptions(formatMessage);

  return options.find((item) => item.value === value)?.label;
};
