import { Typography } from 'antd';
import { isString } from 'lodash';
import React, { memo, useState } from 'react';
import { useUnactivate } from 'react-activation';
import { getId } from '@/utils';
import ShortLine from '../ShortLine';

const { Text } = Typography;

/**
 * table cell 文本缺省处理
 */
const TableCell: React.FC<{
  text: string | null | undefined;
  style?: React.CSSProperties;
}> = ({ text, style }) => {
  const [id] = useState(() => `table-cell-tooltip-${getId()}`);

  const handleShow = () => {
    const target = document.getElementById(id);

    if (!target) {
      return;
    }

    const tooltip = target.closest('.ant-tooltip') as HTMLDivElement;

    if (tooltip) {
      tooltip.style.display = '';
    }
  };

  useUnactivate(() => {
    const target = document.getElementById(id);

    if (!target) {
      return;
    }

    const tooltip = target.closest('.ant-tooltip') as HTMLDivElement;

    if (tooltip) {
      tooltip.style.display = 'none';
    }
  });

  if (text === undefined || text === null || text === '') {
    return <ShortLine />;
  }

  return (
    <Text
      ellipsis={{
        tooltip: isString(text) ? <span id={id}>{text}</span> : undefined,
      }}
      style={{
        color: 'inherit',
        ...style,
      }}
    >
      <span onMouseEnter={handleShow} onTouchStart={handleShow}>
        {text}
      </span>
    </Text>
  );
};

export default memo(TableCell);
