import type { UploadFile } from 'antd/lib/upload/interface';
import * as FileSaver from 'file-saver';
import { Fetcher, SUCCESS_CODE, request } from '@/api';
import { formatNumber, safetyGetJSON } from './other';

export type FileType = 'image' | 'pdf' | 'other';

export const getFileType = (url: string): FileType => {
  if (/\.(?:png|jpe?g)(?:\?\S*)?$/.test(url)) {
    return 'image';
  }

  if (/\.pdf(?:\?\S*)?$/.test(url)) {
    return 'pdf';
  }

  return 'other';
};

export const getFileAccepts = (
  acceptImage: boolean,
  acceptDocs: boolean,
  accept?: string,
) => {
  const docs = ['.doc', '.docs', '.docx'];

  if (accept) {
    return acceptDocs ? `${accept}, ${docs.join(',')}` : accept;
  }

  const accepts = ['.pdf'];

  if (acceptImage) {
    accepts.push('.png', '.jpg', '.jpeg', '.webp', '.bmp');
  }

  if (acceptDocs) {
    accepts.push(...docs);
  }

  return accepts.join(', ');
};

export const formatFileSize = (size: number) => {
  const kb = size / 1024;

  if (kb > 1024) {
    return `${formatNumber(kb / 1024, true)} MB`;
  }

  return `${formatNumber(kb, true)} KB`;
};

export const prefixUploadFile = (
  fileUrl: string,
  name?: string,
  thumbUrl?: string,
  size = 0,
): UploadFile => {
  const random = `UPLOADER_UUID${Math.random().toString(36)}`;
  const type = getFileType(fileUrl);

  return {
    uid: fileUrl.includes('UPLOADER_UUID') ? fileUrl : fileUrl + random,
    url: fileUrl,
    status: 'done',
    name: name || fileUrl.split('/').pop()?.split('?')[0],
    size,
    type: type ?? 'image',
    percent: 100,
    thumbUrl,
    response: {
      code: SUCCESS_CODE,
      data: {
        fileUrl,
      },
    },
  } as UploadFile;
};

export const normalizeUploadValue = (
  value:
    | string
    | string[]
    | UploadFile[]
    | {
        url: string;
        name?: string;
        fileName?: string;
        filename?: string;
        size?: number;
        thumbUrl?: string;
      }[]
    | undefined,
): UploadFile[] | undefined => {
  if (!value) {
    return undefined;
  }

  if (typeof value === 'string') {
    return [prefixUploadFile(value)];
  }

  if (
    Array.isArray(value) &&
    value[0] &&
    (typeof value[0] === 'string' || !('percent' in value[0]))
  ) {
    if (typeof value[0] === 'string') {
      return (value as string[]).map((item: string) =>
        prefixUploadFile(item, item),
      );
    }

    return (value as AnyObject[]).map((item) =>
      prefixUploadFile(
        item.url,
        item.name || item.fileName || item.filename || '',
        item.thumbUrl,
        item.size,
      ),
    );
  }

  return (value || undefined) as UploadFile[];
};

export const formatFilename = (filename: string) =>
  decodeURIComponent(decodeURIComponent(filename));

export const downloadFile = (fileUrl: string, name?: string): void => {
  if (!fileUrl) {
    return;
  }

  const link = document.createElement('a');

  if (name) {
    link.download = formatFilename(name);
  }
  link.target = '_blank';
  link.href = fileUrl;

  document.body.appendChild(link);

  link.click();

  link.parentNode!.removeChild(link);
};

export const downloadFile2 = async (fetcher: Fetcher, params: AnyObject) => {
  const { headers, data } = await fetcher<any>(
    {
      ...params,
      responseType: 'arraybuffer',
      download: 0,
    },
    {
      extractResponseData: false,
    },
  );

  if ((data as ArrayBuffer).byteLength < 400) {
    // eslint-disable-next-line compat/compat
    const msg = new TextDecoder().decode(data);

    const resp = safetyGetJSON(msg, { code: 10001, msg: 'Download failed' });

    if (resp.code && resp.msg) {
      throw resp;
    }
  }

  const blob = new Blob([data], { type: headers['content-type'] });

  FileSaver.saveAs(blob, formatFilename(headers.filename || headers.fileName));
};

export const saveFileByFileUrl = async (fileUrl: string, filename?: string) => {
  const { headers = {}, data } = await request({
    url: fileUrl,
    responseType: 'arraybuffer',
  });

  const fileType = getFileType(fileUrl);
  const blob = new Blob([data], {
    type:
      headers?.['content-type'] ||
      (fileType === 'pdf' ? 'application/pdf' : ''),
  });

  FileSaver.saveAs(
    blob,
    formatFilename(filename || headers.filename || headers.fileName),
  );
};
