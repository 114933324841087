import { UserOutlined } from '@ant-design/icons';
import { Avatar, Space } from 'antd';
import React from 'react';
import { useFormatMessage } from '@/hooks';
import styles from '../index.less';

const EmailSummary: React.FC<{
  emailInfo: AnyObject;
  actions?: React.ReactNode[];
}> = ({ emailInfo, actions }) => {
  const { formatMessage } = useFormatMessage();
  const type = emailInfo?.type;
  const to =
    type === 'normalSend'
      ? emailInfo?.toEmailJson
      : emailInfo?.toFull?.map?.((item: AnyObject) => item.email);

  return (
    <div className={styles.summary}>
      <div className={styles.header}>
        <div>
          <Space align="start">
            <Avatar icon={<UserOutlined />} />
            <div>
              <h3
                style={{
                  marginBottom: 4,
                  whiteSpace: 'normal',
                }}
              >
                {emailInfo.fromName !== emailInfo.fromEmail &&
                  emailInfo.fromEmail && (
                    <b style={{ marginRight: 4, fontWeight: 500 }}>
                      {emailInfo.fromName}
                    </b>
                  )}
                <span className={styles.grey}>{emailInfo?.fromEmail}</span>
              </h3>
              <Space align="start">
                <span>{formatMessage('发送至')}</span>
                <div className={styles.grey}>{to?.join?.(' , ')}</div>
              </Space>
            </div>
          </Space>
        </div>

        {!!actions && <Space className={styles.actions}>{actions}</Space>}
      </div>
    </div>
  );
};

export default EmailSummary;
