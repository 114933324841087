import { useControllableValue } from 'ahooks';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { omit } from 'lodash';
import React from 'react';
import { getPopupContainerProps } from '@/config';
import { useMeasure } from '@/hooks';

const emailRegexp = /^[\w-]+?(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-z]{2,6}$/i;

const EmailAddressInput: React.FC<SelectProps<string[]>> = (props) => {
  const {
    setRef,
    data: { width },
  } = useMeasure();
  const [value, setValue] = useControllableValue<string[] | undefined>(props);

  const handleChange = (nextValue: string[] | undefined) => {
    setValue(nextValue?.filter((item) => item && emailRegexp.test(item)));
  };

  return (
    <div ref={setRef}>
      <Select<string[]>
        value={value}
        onChange={handleChange}
        mode="tags"
        showArrow={false}
        tokenSeparators={[',', ';']}
        dropdownStyle={{
          width,
        }}
        {...getPopupContainerProps}
        {...omit(props, ['value', 'onChange', 'mode'])}
      />
    </div>
  );
};

export default EmailAddressInput;
