import { IRoute } from 'umi';
import { EXTRA_PERMISSIONS } from './extraPermissions';

const OuterClientLayout = {
  wrappers: ['@/layouts/wrappers/OuterClient'],
};

// const WechatShareLayout = {
//   wrappers: ['@/layouts/wrappers/WechatShare'],
// };

const BaseRoutes: IRoute[] = [
  {
    path: '/user',
    layout: false,
    routes: [
      {
        name: 'login',
        path: '/user/login',
        component: './User/Login',
      },
      {
        name: 'admin-login',
        path: '/user/admin-login',
        component: './User/AdminLogin',
      },
      {
        name: 'register',
        path: '/user/register',
        component: './User/Register',
      },
      {
        name: 'forgot',
        path: '/user/forgot',
        component: './User/ForgotPassword',
      },
      {
        name: 'invite',
        path: '/user/invite',
        component: './User/Invite',
      },
    ].map((item) => ({
      ...item,
      ...OuterClientLayout,
      hideInMenu: true,
    })),
  },
  {
    layout: false,
    name: 'system-update-prompt',
    path: '/system-update-prompt',
    component: './SystemUpdate/Prompt',
    hideInMenu: true,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    icon: 'DashboardOutlined',
    component: './EmptyAccess',
    access: 'normalRouteAccessFilter',
    useEmptyAccess: true,
    order: 99,
    shortcut: true,
    routes: [
      {
        exact: true,
        access: 'normalRouteAccessFilter',
        component: './Dashboard/Workplace',
        name: 'workplace',
        path: '/dashboard/workplace',
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/account/settings',
    component: './Account/Settings/Sidebar',
    name: 'account.settings',
    hideInMenu: true,
    extraPermission: EXTRA_PERMISSIONS.ACCOUNT_OPEN,
    routes: [
      {
        path: '/account/settings',
        redirect: '/account/settings/base',
      },
      {
        name: 'base',
        path: '/account/settings/base',
        component: './Account/Settings/BaseView',
      },
      {
        name: 'webauthn',
        path: '/account/settings/webauthn',
        component: './Account/Settings/WebAuthn',
      },
      {
        name: 'password',
        path: '/account/settings/password',
        component: './Account/Settings/Password',
      },
      {
        name: 'payment-info',
        path: '/account/settings/payment-info',
        component: './Account/Settings/PaymentInfo',
        extraPermission: EXTRA_PERMISSIONS.POINT_OPEN,
      },
      {
        name: 'association',
        path: '/account/settings/association',
        component: './Account/Settings/Association',
      },
    ],
  },
  {
    path: '/account/log',
    component: './Account/Log/List',
    name: 'account.log',
    access: 'normalRouteAccessFilter',
    hideInMenu: true,
  },
  {
    path: '/account/file-sign',
    component: './FileSign/Personal',
    name: 'account.file-sign',
    hideInMenu: true,
    access: 'normalRouteAccessFilter',
    extraPermission: EXTRA_PERMISSIONS.FILE_SIGN_OPEN,
  },

  {
    path: '/releases',
    component: './Admin/ReleaseLog/List',
    name: 'releases',
    access: 'normalRouteAccessFilter',
    hideInMenu: true,
  },
  {
    path: '/releases/:id',
    component: './Admin/ReleaseLog/Detail',
    name: 'releases.detail',
    access: 'normalRouteAccessFilter',
    hideInMenu: true,
  },
];

const AdminRoutes: IRoute[] = [
  {
    name: 'system-tips',
    path: '/system-tips',
    access: 'normalRouteAccessFilter',
    icon: 'ToolOutlined',
    component: './Admin/Tips',
  },
  {
    name: 'system-accounts',
    path: '/system-accounts',
    access: 'normalRouteAccessFilter',
    icon: 'ToolOutlined',
    component: './Admin/Accounts',
  },
  {
    name: 'system-companys',
    path: '/system-companys',
    access: 'normalRouteAccessFilter',
    icon: 'ToolOutlined',
    component: './Admin/Companys',
  },

  {
    name: 'system-update',
    path: '/system-update',
    icon: 'AlertOutlined',
    access: 'normalRouteAccessFilter',
    component: './EmptyAccess',
    useEmptyAccess: true,
    routes: [
      {
        path: '/system-update/list',
        access: 'normalRouteAccessFilter',
        component: './SystemUpdate/List',
        name: 'list',
        hideInMenu: true,
      },
      {
        name: 'detail',
        path: '/system-update/detail',
        hideInMenu: true,
        component: './SystemUpdate/Detail',
        access: 'normalRouteAccessFilter',
      },
    ],
  },

  {
    name: 'system',
    path: '/system',
    icon: 'SettingOutlined',
    component: './EmptyAccess',
    access: 'normalRouteAccessFilter',
    useEmptyAccess: true,
    routes: [
      {
        path: '/system/router-rule',
        name: 'router-rule',
        component: './System/RouterRuleList',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/system/api-rule',
        name: 'api-rule',
        component: './System/APIRuleList',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/system/role-group',
        name: 'role-group',
        component: './System/RoleGroupList',
        access: 'normalRouteAccessFilter',
      },
    ],
  },
];

const AgentRoutes: IRoute[] = [
  {
    path: '/agent-case',
    name: 'agent-case',
    icon: 'AppstoreOutlined',
    component: './EmptyAccess',
    access: 'normalRouteAccessFilter',
    useEmptyAccess: true,
    order: 92,
    shortcut: true,
    routes: [
      {
        path: '/agent-case/list',
        access: 'normalRouteAccessFilter',
        name: 'list',
        component: './AgentCase/List',
        hideInMenu: true,
      },
      {
        path: '/agent-case/detail/:id',
        access: 'normalRouteAccessFilter',
        name: 'detail',
        component: './AgentCase/Detail',
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/agent-client',
    name: 'agent-client',
    icon: 'TeamOutlined',
    component: './EmptyAccess',
    access: 'normalRouteAccessFilter',
    useEmptyAccess: true,
    order: 90,
    shortcut: true,
    routes: [
      {
        path: '/agent-client/list',
        component: './AgentClient/List',
        name: 'list',
        access: 'normalRouteAccessFilter',
        hideInMenu: true,
      },
      {
        path: '/agent-client/detail/:id',
        access: 'normalRouteAccessFilter',
        name: 'detail',
        component: './AgentClient/Detail',
        hideInMenu: true,
      },
      {
        path: '/agent-client/detail/:id/email/:emailId',
        access: 'normalRouteAccessFilter',
        name: 'detail-email',
        parentKeys: ['/agent-client/detail/:id'],
        component: './AgentClient/EmailDetail',
        hideInMenu: true,
      },
    ],
  },

  {
    path: '/point',
    name: 'point',
    component: './MyPoint',
    access: 'normalRouteAccessFilter',
    order: 89,
    shortcut: true,
    extraPermission: EXTRA_PERMISSIONS.POINT_OPEN,
    icon: 'DollarCircleOutlined',
  },
];

const CompanyRoutes: IRoute[] = [
  {
    name: 'financial',
    access: 'normalRouteAccessFilter',
    icon: 'PoundCircleOutlined',
    order: 91,
    useEmptyAccess: true,
    component: './EmptyAccess',
    path: '/financial',
    routes: [
      // 中介账单
      {
        access: 'normalRouteAccessFilter',
        path: '/financial/company-bill',
        name: 'company-bill',
        icon: 'AccountBookOutlined',
        component: './CompanyBill/List',
        extraPermission: EXTRA_PERMISSIONS.BILL_OPEN,
      },
      // 中介付款审核
      {
        access: 'normalRouteAccessFilter',
        path: '/financial/company-coe-payment',
        name: 'company-coe-payment',
        icon: 'PayCircleOutlined',
        component: './CompanyCoePayment/List',
      },
      // oneulink 账单
      {
        access: 'normalRouteAccessFilter',
        path: '/financial/channel-bill',
        name: 'channel-bill',
        icon: 'AccountBookOutlined',
        component: './ChannelBill/List',
      },

      // oneulink coe 付款审核
      {
        access: 'normalRouteAccessFilter',
        path: '/financial/channel-coe-payment',
        name: 'channel-coe-payment',
        icon: 'PayCircleOutlined',
        component: './ChannelCoePayment/List',
      },
      // 中介的 oneulink 积分
      {
        path: '/financial/c2c-point-agent/coe',
        name: 'c2c-point-agent.coe',
        access: 'normalRouteAccessFilter',
        icon: 'DollarCircleOutlined',
        extraPermission: EXTRA_PERMISSIONS.POINT_OPEN,
        component: './C2CCoE/Agent',
        // routes: [
        //   {
        //     path: '/financial/c2c-point-agent/list',
        //     access: 'normalRouteAccessFilter',
        //     component: './C2CPoint/Agent',
        //     name: 'list',
        //     hideInMenu: true,
        //   },
        //   {
        //     path: '/financial/c2c-point-agent/coe',
        //     access: 'normalRouteAccessFilter',
        //     component: './C2CCoE/Agent',
        //     name: 'coe',
        //   },
        // ],
      },

      // 公司积分
      {
        path: '/financial/company-point',
        name: 'company-point',
        icon: 'WalletOutlined',
        access: 'normalRouteAccessFilter',
        extraPermission: EXTRA_PERMISSIONS.POINT_OPEN,
        component: './EmptyAccess',
        routes: [
          {
            path: '/financial/company-point/list',
            component: './CompanyPoint/List',
            name: 'list',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/financial/company-point/extra',
            name: 'extra',
            component: './CompanyPoint/Extra',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/financial/company-point/withdraw',
            name: 'withdraw',
            component: './CompanyPoint/Withdraw',
            access: 'normalRouteAccessFilter',
          },
        ],
      },

      {
        path: '/financial/c2c-point-channel',
        name: 'c2c-point-channel',
        icon: 'WalletOutlined',
        access: 'normalRouteAccessFilter',
        extraPermission: EXTRA_PERMISSIONS.POINT_OPEN,
        component: './EmptyAccess',
        routes: [
          {
            path: '/financial/c2c-point-channel/list',
            component: './C2CPoint/Channel/List',
            name: 'list',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/financial/c2c-point-channel/extra',
            name: 'extra',
            component: './C2CPoint/Channel/Extra',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/financial/c2c-point-channel/withdraw',
            name: 'withdraw',
            component: './C2CPoint/Channel/Withdraw',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/financial/c2c-point-channel/coe',
            name: 'coe',
            access: 'normalRouteAccessFilter',
            component: './C2CCoE/Channel',
          },
        ],
      },
    ],
  },
  {
    path: '/company-admin',
    name: 'company-admin',
    icon: 'SettingOutlined',
    access: 'normalRouteAccessFilter',
    component: './EmptyAccess',
    useEmptyAccess: true,
    routes: [
      {
        path: '/company-admin/detail',
        component: './CompanyAdmin/Detail',
        name: 'detail',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/company-admin/bill',
        access: 'normalRouteAccessFilter',
        component: './CompanyAdmin/BillSettings',
        name: 'bill-settings',
        extraPermission: EXTRA_PERMISSIONS.BILL_OPEN,
      },
      {
        path: '/company-admin/settings',
        access: 'normalRouteAccessFilter',
        component: './CompanyAdmin/Settings',
        name: 'settings',
      },
      {
        path: '/company-admin/case-point-settings',
        access: 'normalRouteAccessFilter',
        component: './CompanyAdmin/CasePointSettings',
        name: 'case-point-settings',
        extraPermission: EXTRA_PERMISSIONS.POINT_OPEN,
      },
      {
        access: 'normalRouteAccessFilter',
        path: '/company-admin/material-recycle',
        name: 'material-recycle',
        icon: 'DeleteOutlined',
        component: './CompanyAdmin/MaterialRecycle',
      },
      {
        path: '/company-admin/message-task',
        name: 'message-task',
        icon: 'ScheduleOutlined',
        component: './EmptyAccess',
        access: 'normalRouteAccessFilter',
        extraPermission: EXTRA_PERMISSIONS.ONLY_ONEU_EDUCATION,
        useEmptyAccess: true,
        routes: [
          {
            path: '/company-admin/message-task/template',
            component: './CompanyAdmin/MessageTask/Template',
            name: 'template',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/company-admin/message-task/log',
            component: './CompanyAdmin/MessageTask/Log',
            name: 'log',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/company-admin/message-task/list',
            component: './CompanyAdmin/MessageTask/List',
            name: 'list',
            access: 'normalRouteAccessFilter',
          },
        ],
      },
    ],
  },
  {
    path: '/company-staff',
    name: 'company-staff',
    icon: 'ApartmentOutlined',
    access: 'normalRouteAccessFilter',
    component: './EmptyAccess',
    useEmptyAccess: true,
    routes: [
      {
        path: '/company-staff/list',
        component: './CompanyStaff/List',
        name: 'list',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/company-staff/invite-list',
        component: './CompanyStaff/InviteList',
        access: 'normalRouteAccessFilter',
        name: 'invite-list',
      },
      {
        path: '/company-staff/job-list',
        component: './CompanyStaff/JobList',
        name: 'job-list',
        access: 'normalRouteAccessFilter',
      },
    ],
  },

  {
    path: '/custom-case',
    name: 'custom-case',
    icon: 'CodeOutlined',
    access: 'normalRouteAccessFilter',
    component: './EmptyAccess',
    routes: [
      {
        path: '/custom-case/custom-detail',
        access: 'normalRouteAccessFilter',
        component: './CustomCase/CustomDetail',
        name: 'custom-detail',
        parentKey: ['/custom-case/custom-list'],
        hideInMenu: true,
      },
      {
        path: '/custom-case/builtin-detail',
        access: 'normalRouteAccessFilter',
        component: './CustomCase/BuiltinDetail',
        name: 'builtin-detail',
        parentKey: ['/custom-case/builtin-list'],
        hideInMenu: true,
      },
      {
        path: '/custom-case/builtin-list',
        access: 'normalRouteAccessFilter',
        component: './CustomCase/BuiltinList',
        name: 'builtin-list',
      },
      {
        path: '/custom-case/custom-list',
        access: 'normalRouteAccessFilter',
        component: './CustomCase/CustomList',
        name: 'custom-list',
      },
      {
        path: '/custom-case/steps-config',
        component: './CustomCase/StepsConfig',
        name: 'steps-config',
        access: 'normalRouteAccessFilter',
      },
      {
        access: 'normalRouteAccessFilter',
        path: '/custom-case/custom-category',
        component: './CustomCase/CategoryList',
        name: 'custom-category',
      },
      {
        path: '/custom-case/custom-form',
        component: './CustomCase/CustomFormList',
        name: 'custom-form',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/custom-case/steps-category',
        component: './CustomCase/StepsCategory',
        name: 'steps-category',
        access: 'normalRouteAccessFilter',
      },
    ],
  },

  {
    path: '/company-logs',
    name: 'company-logs',
    icon: 'SafetyOutlined',
    access: 'normalRouteAccessFilter',
    component: './EmptyAccess',
    useEmptyAccess: true,
    routes: [
      {
        path: '/company-logs/operation-list',
        access: 'normalRouteAccessFilter',
        component: './CompanyLogs/OperationList',
        name: 'operation-list',
      },
      {
        access: 'normalRouteAccessFilter',
        path: '/company-logs/log',
        name: 'log',
        icon: 'DatabaseOutlined',
        routes: [
          {
            path: '/company-logs/log/staff',
            component: './CompanyLogs/Log/Staff',
            name: 'staff',
            access: 'normalRouteAccessFilter',
            hideInMenu: true,
          },
        ],
      },
    ],
  },
];

const ChannelRoutes: IRoute[] = [
  {
    path: '/channel-case',
    name: 'channel-case',
    icon: 'AppstoreOutlined',
    component: './EmptyAccess',
    access: 'normalRouteAccessFilter',
    useEmptyAccess: true,
    order: 92,
    shortcut: true,
    routes: [
      {
        path: '/channel-case/list',
        access: 'normalRouteAccessFilter',
        name: 'list',
        component: './ChannelCase/List',
        hideInMenu: true,
      },
      {
        path: '/channel-case/detail/:id',
        access: 'normalRouteAccessFilter',
        name: 'detail',
        component: './ChannelCase/Detail',
        hideInMenu: true,
      },
    ],
  },

  {
    path: '/channel-client',
    name: 'channel-client',
    icon: 'TeamOutlined',
    component: './EmptyAccess',
    access: 'normalRouteAccessFilter',
    useEmptyAccess: true,
    order: 90,
    shortcut: true,
    routes: [
      {
        path: '/channel-client/list',
        component: './ChannelClient/List',
        name: 'list',
        hideInMenu: true,
      },
      {
        path: '/channel-client/detail/:id',
        component: './ChannelClient/Detail',
        name: 'detail',
        hideInMenu: true,
      },
    ],
  },

  {
    access: 'normalRouteAccessFilter',
    path: '/my-master-agent',
    name: 'my-master-agent',
    icon: 'TeamOutlined',
    extraPermission: EXTRA_PERMISSIONS.MY_MASTER_AGENT,
    component: './MasterAgent/MyAgent',
  },
  {
    path: '/oneulink-assistance',
    name: 'oneulink-assistance',
    icon: 'ContactsOutlined',
    access: 'normalRouteAccessFilter',
    component: './EmptyAccess',
    useEmptyAccess: true,
    routes: [
      {
        path: '/oneulink-assistance/list',
        component: './OneULinkAssistance/List',
        name: 'list',
        access: 'normalRouteAccessFilter',
        hideInMenu: true,
      },
    ],
  },
  {
    access: 'normalRouteAccessFilter',
    path: '/case-tag',
    name: 'case-tag',
    icon: 'TagOutlined',
    component: './CaseTag/List',
    hideInMenu: true,
  },

  {
    path: '/file-remark',
    access: 'normalRouteAccessFilter',
    name: 'file-remark',
    icon: 'TagOutlined',
    component: './EmptyAccess',
    useEmptyAccess: true,
    routes: [
      {
        path: '/file-remark/coe',
        name: 'coe-attachment',
        component: './FileRemark/CoE',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/file-remark/offer',
        name: 'offer',
        component: './FileRemark/Offer',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/file-remark/cate',
        name: 'cate',
        component: './FileRemark/Category',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/file-remark/student-visa',
        name: 'student-visa',
        component: './FileRemark/StudentVisa',
        access: 'normalRouteAccessFilter',
      },
    ],
  },

  {
    path: '/master-agent',
    icon: 'ClusterOutlined',
    access: 'normalRouteAccessFilter',
    name: 'master-agent',
    component: './EmptyAccess',
    useEmptyAccess: true,
    routes: [
      {
        path: '/master-agent/list',
        name: 'list',
        access: 'normalRouteAccessFilter',
        component: './MasterAgent/List',
        hideInMenu: true,
      },
      {
        path: '/master-agent/:id',
        name: 'detail',
        hideInMenu: true,
        component: './MasterAgent/Detail',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/master-agent/:id/:schoolId',
        name: 'detail.school',
        hideInMenu: true,
        component: './MasterAgent/SchoolDetail',
        access: 'normalRouteAccessFilter',
        parentKeys: ['/master-agent/:id'],
      },
    ],
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: './EmptyAccess',
    access: 'normalRouteAccessFilter',
    icon: 'AppstoreOutlined',
    useEmptyAccess: true,
    order: -90,
    routes: [
      {
        path: '/analysis/asqa',
        name: 'asqa',
        component: './Analysis/ASQA',
      },
      {
        path: '/analysis/school',
        name: 'school',
        component: './Analysis/School',
      },
      {
        path: '/analysis/course',
        name: 'course',
        component: './Analysis/Course',
      },
      {
        path: '/analysis/trend',
        name: 'trend',
        component: './Analysis/Trend',
      },
      {
        path: '/analysis/agent',
        name: 'agent',
        routes: [
          {
            path: '/analysis/agent/sumary',
            name: 'summary',
            component: './Analysis/Agent/Summary',
          },
          {
            path: '/analysis/agent/compare',
            name: 'compare',
            component: './Analysis/Agent/Compare',
          },
          {
            path: '/analysis/agent/detail',
            name: 'detail',
            component: './Analysis/Agent/Detail',
          },
        ],
      },
    ],
  },

  {
    path: '/data-config',
    name: 'data-config',
    icon: 'SettingOutlined',
    access: 'normalRouteAccessFilter',
    routes: [
      {
        name: 'review-payment-info',
        path: '/data-config/review-payment-info',
        component: './OneULinkAdmin/ReviewPaymentInfo',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/data-config/course-checklist',
        component: './DataConfig/CourseChecklist',
        name: 'course-checklist',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/data-config/skill-assess',
        component: './DataConfig/SkillAssess',
        name: 'skill-assess',
      },
      {
        path: '/data-config/occupation',
        name: 'occupation',
        component: './DataConfig/Occupation',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/data-config/rpl-discount',
        component: './DataConfig/RplDiscount',
        name: 'rpl-discount',
        access: 'normalRouteAccessFilter',
      },
      {
        name: 'assist-promotion',
        path: '/data-config/assist-promotion',
        access: 'normalRouteAccessFilter',
        component: './DataConfig/AIPromotion',
      },
      {
        name: 'publicity-access',
        path: '/data-config/publicity-access',
        component: './OneULinkAdmin/Publicity',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/data-config/file-category',
        name: 'file-category',
        component: './FileCabinetsConfig/Category',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/data-config/auto-submit',
        component: './OneULinkAdmin/AutoSubmit',
        name: 'auto-submit',
        access: 'normalRouteAccessFilter',
      },

      {
        path: '/data-config/email-blacklist',
        name: 'email-blacklist',
        component: './OneULinkAdmin/EmailBlacklist',
        access: 'normalRouteAccessFilter',
      },
    ],
  },
];

const ClientUserRoutes: IRoute[] = [
  {
    path: '/client-user/create',
    name: 'client-user.create',
    component: './ClientUser/Create',
  },
  {
    path: '/client-user/visitor-visa',
    name: 'client-user.visitor-visa',
    component: './ClientUser/VisitorVisa',
  },
  {
    path: '/client-user/student-visa',
    name: 'client-user.student-visa',
    component: './ClientUser/StudentVisa',
  },
  {
    path: '/client-user/bridging-visa-b',
    name: 'client-user.bridging-visa-b',
    component: './ClientUser/BridgingVisaB',
  },
  {
    path: '/client-user/subsequent-student-visa',
    name: 'client-user.subsequent-student-visa',
    component: './ClientUser/SubsequentStudentVisa',
  },
  {
    path: '/client-user/guardian-student-visa',
    name: 'client-user.guardian-student-visa',
    component: './ClientUser/GuardianStudentVisa',
  },
  {
    path: '/client-user/485-visa',
    name: 'client-user.485-visa',
    component: './ClientUser/PSWVisa',
  },
  {
    path: '/client-user/gte',
    name: 'client-user.gte',
    component: './ClientUser/GTE',
  },
  {
    path: '/client-user/956-form',
    name: 'client-user.956-form',
    component: './ClientUser/956Form',
  },

  {
    path: '/client-user/contact-info-update/:number',
    name: 'client-user.gte',
    component: './ClientUser/ContactInfoUpdate',
  },
  {
    path: '/client-user/view-offer/:id',
    name: 'client-user.view-offer',
    component: './ClientUser/ViewOffer',
  },
  {
    path: '/client-user/offer/:id',
    name: 'client-user.offer',
    component: './ClientUser/Offer',
  },
  {
    path: '/client-user/contract/:number',
    name: 'client-user.contract',
    component: './ClientUser/Contract',
  },
  {
    path: '/client-user/contract-view/:number',
    name: 'client-user.contract-view',
    component: './ClientUser/ContractView',
  },
  {
    path: '/client-user/sa/:id/:applicationId',
    name: 'client-user.school-apply',
    component: './ClientUser/SchoolApply',
  },

  {
    path: '/client-user/sa/:id',
    name: 'client-user.school-apply-nav',
    component: './ClientUser/SchoolApplyNav',
  },

  {
    path: '/client-user/aa/:id',
    name: 'client-user.agent-apply',
    component: './ClientUser/AgentApply',
  },
  {
    path: '/client-user/prp',
    name: 'client-user.prp',
    component: './ClientUser/PrPlanner',
  },
].map((item) => ({
  ...item,
  hideInMenu: true,
  layout: false,
}));

const PublicRoutes: IRoute[] = [
  {
    path: '/offer-form/:id',
    name: 'offer-form',
    access: 'normalRouteAccessFilter',
    component: './OfferForm',
    hideInMenu: true,
  },
  {
    path: '/visa-like-me',
    name: 'visa-like-me',
    icon: 'SearchOutlined',
    component: './VisaLikeMe/List',
    access: 'normalRouteAccessFilter',
  },
  {
    path: '/school',
    name: 'school',
    icon: 'ReadOutlined',
    component: './EmptyAccess',
    useEmptyAccess: true,
    access: 'normalRouteAccessFilter',
    order: 85,
    shortcut: true,
    routes: [
      {
        path: '/school/list',
        component: './School/SchoolList',
        name: 'list',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/school/course-alternative-titles',
        name: 'course-alternative-titles',
        access: 'normalRouteAccessFilter',
        component: './School/CourseAlternativeTitles',
      },
      {
        path: '/school/course-tutoring-company',
        name: 'course-tutoring-company',
        access: 'normalRouteAccessFilter',
        component: './School/CourseTutoringCompany',
      },
      {
        path: '/school/rpl-course-list',
        name: 'rpl-course-list',
        access: 'normalRouteAccessFilter',
        component: './School/RplCourseList',
      },
      {
        path: '/school/detail/:code',
        access: 'normalRouteAccessFilter',
        name: 'detail',
        component: './School/Detail',
        hideInMenu: true,
      },
    ],
  },

  {
    path: '/publicity',
    name: 'publicity',
    icon: 'ToolOutlined',
    component: './EmptyAccess',
    useEmptyAccess: true,
    access: 'normalRouteAccessFilter',
    routes: [
      {
        path: '/publicity/course-checklist',
        component: './AgentPublicity/CourseChecklist',
        name: 'course-checklist',
        access: 'normalRouteAccessFilter',
        extraPermission: EXTRA_PERMISSIONS.PUBLICITY_OPEN,
      },
      {
        path: '/publicity/visa-fees',
        component: './VisaFees/List',
        name: 'visa-fees-list',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/publicity/visa-fees/:id',
        component: './VisaFees/Detail',
        hideInMenu: true,
        name: 'visa-fees-detail',
        parentKeys: ['/publicity/visa-fees'],
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/publicity/offer',
        component: './School/Offer',
        name: 'offer',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/publicity/part-study',
        component: './AgentPublicity/PartStudy',
        name: 'part-study',
      },
      {
        path: '/publicity/prp',
        component: './AgentPublicity/PrPlanners',
        name: 'prp',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/publicity/prp/detail',
        component: './AgentPublicity/PrPlannerDetail',
        name: 'prp-detail',
        parentKeys: ['publicity.prp'],
        layout: false,
        hideInMenu: true,
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/publicity/pr-planner',
        name: 'pr-planner',
        icon: 'StockOutlined',
        access: 'normalRouteAccessFilter',
        routes: [
          {
            path: '/publicity/pr-planner/diff',
            component: './PrPlanner/DiffList',
            name: 'diff',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/publicity/pr-planner/channel',
            component: './PrPlanner/ChannelList',
            name: 'channel',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/publicity/pr-planner/course',
            component: './PrPlanner/Course',
            name: 'course',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/publicity/pr-planner/channel/:id',
            component: './PrPlanner/ChannelDetail',
            name: 'channel-detail',
            hideInMenu: true,
            access: 'normalRouteAccessFilter',
          },

          {
            path: '/publicity/pr-planner/assess-condition',
            component: './PrPlanner/AssessConditionList',
            name: 'assess-condition',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/publicity/pr-planner/cert-condition',
            component: './PrPlanner/CertConditionList',
            name: 'cert-condition',
            access: 'normalRouteAccessFilter',
          },
          {
            path: '/publicity/pr-planner/version',
            component: './PrPlanner/Version',
            name: 'version',
            access: 'normalRouteAccessFilter',
          },
        ],
      },
    ],
  },
];

const StudentAppRoutes: IRoute[] = [
  {
    path: '/students',
    name: 'student',
    access: 'normalRouteAccessFilter',
    component: './EmptyAccess',
    icon: 'TeamOutlined',
    routes: [
      {
        path: '/students/accounts',
        name: 'accounts',
        component: './Student/Accounts',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/students/point-details',
        name: 'point-details',
        component: './Student/PointDetails',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/students/case-point-bonus',
        name: 'case-point-bonus',
        component: './Student/CasePointBonus',
        access: 'normalRouteAccessFilter',
      },
      {
        path: '/students/use-points-record',
        name: 'use-points-record',
        component: './Student/UsePointsRecord',
        access: 'normalRouteAccessFilter',
      },
    ],
  },
];

export default [
  ...BaseRoutes,
  {
    path: '/welcome',
    name: 'welcome',
    component: './Welcome/Index',
    menuRender: false,
    hideInMenu: true,
  },
  {
    path: '/create-company',
    name: 'create-company',
    component: './Welcome/CreateCompany',
    menuRender: false,
    hideInMenu: true,
  },
  ...AdminRoutes,
  ...ChannelRoutes,
  ...AgentRoutes,
  ...CompanyRoutes,
  ...ClientUserRoutes,
  ...PublicRoutes,
  ...StudentAppRoutes,
];
