import { noop } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

const ModalManagerContext = createContext<{
  open: (callback: () => void, id: string, name?: string) => () => void;
  close: (id: string) => void;
}>({
  open: () => noop,
  close: noop,
});

const DEFAULT_NAME = 'DEFAULT';

export const useModalManagerContext = () => useContext(ModalManagerContext);

export const ModalManagerProvider: React.FC = ({ children }) => {
  const queue = useRef(
    new Map<string, { id: string; callback: () => void }[]>(),
  );
  const state = useRef<{
    name: string | null;
    opening: boolean;
    id: null | string;
  }>({
    name: null,
    opening: false,
    id: null,
  });

  const next = useCallback((useCurrentName = true) => {
    const queueMap = queue.current;
    let { name } = state.current;

    if (!name || !queueMap.get(name) || !useCurrentName) {
      name = Array.from(queueMap.keys()).shift() ?? null;
    }

    // 队列跑完
    if (!name) {
      state.current = {
        opening: false,
        name: null,
        id: null,
      };
      return;
    }

    // 空队列，继续下一个
    if (queueMap.get(name)!.length === 0) {
      queueMap.delete(name);
      next(true);

      return;
    }

    const list = Array.from(queueMap.get(name)!.values());
    const item = list.shift()!;

    item.callback();

    state.current.id = item.id;
    state.current.opening = true;

    if (list.length === 0) {
      queueMap.delete(name);
    } else {
      queueMap.set(name, list);
    }
  }, []);

  const open = useCallback(
    (callback: () => void, id: string, name = DEFAULT_NAME) => {
      if (!state.current.opening) {
        callback();
        state.current = {
          opening: true,
          name,
          id,
        };

        return noop;
      }

      const map = queue.current;
      const list = map.get(name) || [];

      map.set(name, list);
      map.get(name)!.push({ id, callback });

      return () => {
        const nextList = map.get(name)?.filter((item) => item.id !== id) ?? [];

        if (nextList.length === 0) {
          map.delete(name);
        } else {
          map.set(name, nextList);
        }

        if (state.current.opening && state.current.id === id) {
          next();
        }
      };
    },
    [next],
  );

  const close = useCallback(
    (id: string) => {
      const name = Array.from(queue.current.entries()).find((item) =>
        item[1].find((v) => v.id === id),
      )?.[0];

      if (name) {
        const list =
          queue.current.get(name)?.filter((item) => item.id !== id) ?? [];
        if (list.length === 0) {
          queue.current.delete(name);
        } else {
          queue.current.set(name, list);
        }
      }
      next();
    },
    [next],
  );

  const value = useMemo(() => ({ open, close }), [open, close]);

  return (
    <ModalManagerContext.Provider value={value}>
      {children}
    </ModalManagerContext.Provider>
  );
};
