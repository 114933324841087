import { useRequest } from 'ahooks';
import { isPlainObject } from 'lodash';
import { createContext, useContext } from 'react';
import { services } from '@/api';

const TodoDetailEditItemContext = createContext<{
  id?: number;
  onSuccess?: () => void;
}>({});

const TodoDetailEditItem = <T extends any>({
  field,
  children,
  transform,
}: {
  field: string;
  transform?: (value?: T) => AnyObject;
  children: (childProps: {
    onChange: (value: T) => void;
    loading: boolean;
  }) => React.ReactNode;
}) => {
  const { onSuccess, id } = useContext(TodoDetailEditItemContext);
  const { run: submit, loading } = useRequest(services.todo.updateTodo, {
    manual: true,
    onSuccess,
  });

  const handleChange = (value: T) => {
    if (!id) {
      return;
    }

    const targetValue = transform ? transform(value) : value;
    const params = { id };

    if (isPlainObject(targetValue)) {
      Object.assign(params, targetValue);
    } else {
      params[field] = targetValue;
    }

    submit(params);
  };

  return <>{children({ loading, onChange: handleChange })}</>;
};

export const TodoDetailEditItemProvider: React.FC<{
  id: number;
  onSuccess?: () => void;
}> = ({ children, id, onSuccess }) => (
  <TodoDetailEditItemContext.Provider value={{ id, onSuccess }}>
    {children}
  </TodoDetailEditItemContext.Provider>
);

export default TodoDetailEditItem;
