import { get } from 'lodash';
import React from 'react';
import FileViewer from '@/PageComponents/FileViewer';
import { MONTH_FORMAT } from '@/config';
import DynamicRelativeTime from '../DynamicRelativeTime';
import FormatDate from '../FormatDate';
import SetInnerHTML from '../SetInnerHTML';
import ShortLine from '../ShortLine';
import TableCell from '../TableCell';

type TableFieldValueType =
  | 'email'
  | 'phone'
  | 'url'
  | 'text'
  | 'date'
  | 'dateTime'
  | 'month'
  | 'html'
  | 'file'
  | 'relativeTime';

const sanitizeUrl = (url: string) =>
  url.startsWith('http') ? url : `http://${url}`;

const TableField: React.FC<{
  record: AnyObject;
  field: string;
  countryCodeKey?: string;
  type?: TableFieldValueType;
}> = ({ record, type = 'text', field, countryCodeKey = 'countryCode' }) => {
  const value = get(record, field);
  if (value === '' || value === undefined || value === null) {
    return <ShortLine />;
  }

  switch (type) {
    case 'email':
      return (
        <a className="text-clip" href={`mailto:${value}`} title={value}>
          {value}
        </a>
      );
    case 'url':
      return (
        <a rel="noreferrer" href={sanitizeUrl(value)} target="_blank">
          {value}
        </a>
      );
    case 'phone':
      // eslint-disable-next-line no-case-declarations
      const countryCode = record[countryCodeKey];

      return (
        <a href={`tel:+${countryCode} ${value}`}>
          <span style={{ marginRight: 4 }}>+{countryCode}</span>
          <span>{value}</span>
        </a>
      );
    case 'date':
    case 'month':
    case 'dateTime':
      return (
        <FormatDate
          format={type === 'month' ? MONTH_FORMAT : undefined}
          minute={type === 'dateTime'}
          time={value}
        />
      );

    case 'html':
      return (
        <SetInnerHTML
          html={value}
          style={{
            whiteSpace: 'pre-line',
            minWidth: '10em',
          }}
        />
      );
    case 'file':
      return <FileViewer id={value} />;
    case 'relativeTime':
      return <DynamicRelativeTime time={value} />;
    default:
      return <TableCell text={value} />;
  }
};

export default TableField;
