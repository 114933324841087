export const getCourseLevelOptions = () =>
  [
    'Masters Degree (Coursework)',
    'Masters Degree (Research)',
    'Masters Degree (Extended)',
    'Bachelor Degree',
    'Bachelor Honours Degree',
    'Diploma',
    'Advanced Diploma',
    'Certificate I',
    'Certificate II',
    'Certificate III',
    'Certificate IV',
    'Graduate Diploma',
    'Graduate Certificate',
    'Associate Degree',
  ].map((value) => ({
    value,
    label: value,
  }));
