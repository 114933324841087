import ProForm, { ProFormText } from '@ant-design/pro-form';
import { useRequest } from 'ahooks';
import { Alert, Button, Tabs, notification } from 'antd';
import { useState } from 'react';
import { ResponseSchema, services } from '@/api';
import ProModalForm from '@/components/ProModalForm';
import { useCommonRules, useFormatMessage } from '@/hooks';

const FileLimitPrompt: React.FC<{
  onOk?: () => void;
  visible?: boolean;
  onCancel?: () => void;
}> = ({ onCancel, onOk, visible }) => {
  const { formatMessage } = useFormatMessage();
  const { data: smsResp } = useRequest<
    ResponseSchema<{
      countryCode: string;
      phone: string;
      email?: string;
    }>
  >(services.file.limiter.getSmsInfo);
  const [form] = ProForm.useForm();
  const [activeKey, setActiveKey] = useState<'phone' | 'email'>('phone');
  const { run: sendCode, loading: sending, data: sendResp } = useRequest(
    services.file.limiter.sendSmsCode,
    {
      manual: true,
      onSuccess() {
        notification.success({
          message:
            activeKey === 'email'
              ? formatMessage('发送邮箱验证码成功')
              : formatMessage('发送短信验证码成功'),
        });
      },
    },
  );
  const { inputRules } = useCommonRules();
  const { run: relieve } = useRequest(services.file.limiter.relieve, {
    manual: true,
  });

  const smsInfo = smsResp?.data;

  const messageConfig = {
    email: {
      label: formatMessage('输入邮箱验证码'),
      action: formatMessage('获取邮箱验证码'),
      tips: formatMessage(
        '文件下载次数已超过限制，需要输入邮箱验证码验证方可继续下载文件',
      ),
    },
    phone: {
      label: formatMessage('输入短信验证码'),
      action: formatMessage('获取短信验证码'),
      tips: formatMessage(
        '文件下载次数已超过限制，需要输入手机验证码验证方可继续下载文件',
      ),
    },
  };

  return (
    <ProModalForm<{ smsCode: string }>
      onFinish={async (values) => {
        await relieve({
          ...values,
          captchaType: activeKey,
        });

        onOk?.();

        return true;
      }}
      onCancel={onCancel}
      visible={visible}
      modalProps={{
        transitionName: '',
        maskTransitionName: '',
        zIndex: 1006,
        afterClose() {
          setActiveKey('phone');
        },
      }}
      submitter={{
        searchConfig: {
          submitText: formatMessage('验证'),
        },
      }}
      form={form}
      title={formatMessage('文件下载验证')}
    >
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key as typeof activeKey);
          form.resetFields(['smsCode']);
        }}
      >
        <Tabs.TabPane tab={formatMessage('手机验证')} key="phone" />
        <Tabs.TabPane tab={formatMessage('邮箱验证')} key="email" />
      </Tabs>
      <Alert
        type="warning"
        message={messageConfig[activeKey].tips}
        style={{ marginBottom: 12 }}
        showIcon
      />
      <div style={{ display: 'flex', flex: 1, gap: 24 }}>
        <div>
          <ProFormText
            name="smsCode"
            fieldProps={{
              minLength: 4,
            }}
            label={messageConfig[activeKey].label}
            rules={inputRules}
          />
        </div>
        <Button
          style={{ marginTop: 30 }}
          loading={sending}
          onClick={() =>
            sendCode({
              captchaType: activeKey,
            })
          }
          type="dashed"
        >
          {messageConfig[activeKey].action}
        </Button>
      </div>
      {sendResp && (
        <Alert
          type="success"
          message={
            activeKey === 'email'
              ? formatMessage('请检查邮箱为 {email} 的邮箱验证码', {
                  email: smsInfo?.email,
                })
              : formatMessage('请检查手机号为 {phone} 的手机短信验证码', {
                  phone: `+${smsInfo?.countryCode} ${smsInfo?.phone}`,
                })
          }
          showIcon
        />
      )}
    </ProModalForm>
  );
};

export default FileLimitPrompt;
