import { useRequest } from 'ahooks';
import { Space } from 'antd';
import classnames from 'classnames';
import { template } from 'lodash';
import React, { memo } from 'react';
import { history, useIntl } from 'umi';
import { useTodoGlobalViewerContext } from '@/PageComponents/Todo';
import { services } from '@/api';
import { CustomAvatar, LinkOptionButton } from '@/common';
import { useTodoStatusEnum } from '@/data';
import { useFormatMessage } from '@/hooks';
import { formatDate } from '@/utils';
import DynamicRelativeTime from '../DynamicRelativeTime';
import SetInnerHTML from '../SetInnerHTML';
import InviteOperator from './InviteOperator';
import styles from './index.less';
import type { IMessageItem } from './type';

const MessageItem = (
  {
    data,
    hoverable = true,
    avatar = true,
    extra,
    compact,
    refresh,
    onView,
  }: {
    data: IMessageItem;
    hoverable?: boolean;
    avatar?: boolean;
    extra?: React.ReactNode[];
    style?: React.CSSProperties;
    compact?: boolean;
    // 通知上层刷新
    refresh?: () => void;
    onView?: () => void;
  },
  ref: React.Ref<HTMLDivElement>,
) => {
  const { locale } = useIntl();
  const { formatMessage } = useFormatMessage();
  const titleKey = locale === 'zh-CN' ? 'cnTitle' : 'enTitle';
  const contentKey = locale === 'zh-CN' ? 'cnContent' : 'enContent';

  const { run: mark } = useRequest(services.message.markAsRead, {
    manual: true,
    onSuccess() {
      refresh?.();
    },
  });

  const actions: React.ReactNode[] = [];
  const todoStatusEnum = useTodoStatusEnum();
  const { onView: onViewTodo } = useTodoGlobalViewerContext();
  const isCase =
    data.type === 'case' && (data.extra?.caseId || data.extra?.channelCaseId);

  const handleMark = () => {
    if (!data.hasRead) {
      mark({
        ids: [data.id],
      });
    }

    if (isCase) {
      setTimeout(() => {
        if (data.extra!.caseId) {
          history.push(`/agent-case/detail/${data.extra?.caseId}`);
        } else {
          history.push(`/channel-case/detail/${data.extra?.channelCaseId}`);
        }
        onView?.();
      }, 80);
    } else if (data.type === 'client' && data.extra?.customerId) {
      setTimeout(() => {
        history.push(`/agent-client/detail/${data.extra?.customerId}`);
        onView?.();
      }, 80);
    }
  };

  if (isCase) {
    actions.push(
      <LinkOptionButton key="view" onClick={handleMark}>
        {formatMessage('查看详情')}
      </LinkOptionButton>,
    );
  }

  let content = data[contentKey];

  if (data.extra?.type === 'updating') {
    content = template(content)({
      estimatedEndTime: formatDate(data.extra.estimatedEndTime, true),
      estimatedStartTime: formatDate(data.extra.estimatedStartTime, true),
    });
  }

  if (data.extra?.type === 'todoTaskUpdate') {
    content = template(content)({
      state: todoStatusEnum.get(data.extra.state)?.text,
    });

    actions.push(
      <LinkOptionButton
        key="view"
        onClick={() => onViewTodo?.(data.extra!.todoTaskId)}
      >
        {formatMessage('查看详情')}
      </LinkOptionButton>,
    );
  }

  if (data.extra?.type === 'todoTaskCreate') {
    actions.push(
      <LinkOptionButton
        key="view"
        onClick={() => onViewTodo?.(data.extra!.todoTaskId)}
      >
        {formatMessage('查看详情')}
      </LinkOptionButton>,
    );
  }

  if (
    data.type === 'notice' &&
    data.extra?.type === 'invite' &&
    !data.extra?.result
  ) {
    actions.push(
      <InviteOperator
        key="invite"
        id={data.id}
        markAsRead={() => mark({ id: data.id })}
      />,
    );
  }

  const actionsElem = <Space>{actions}</Space>;

  return (
    <div
      ref={ref}
      className={classnames(styles.messageItem, {
        [styles.hoverable]: hoverable,
        [styles.compact]: compact,
        [styles.isReaded]: data.hasRead === 1,
      })}
    >
      {avatar && (
        <div className={styles.avatar}>
          {data.type === 'notice' ? (
            <div className={styles.systemIcon} />
          ) : (
            <CustomAvatar alt={data[titleKey].charAt(0).toUpperCase()} />
          )}
        </div>
      )}
      <div className={styles.main}>
        <div className={styles.header}>
          <h4 className={styles.title}>{data[titleKey]}</h4>
          {extra && <Space align="center">{extra}</Space>}
        </div>
        <SetInnerHTML html={content} className={styles.content} />
        <Space direction="vertical">
          <Space className={styles.metas}>
            <span>
              <DynamicRelativeTime time={data.createdAt} />
            </span>
          </Space>
          {actions.length > 0 && actionsElem}
        </Space>
      </div>
    </div>
  );
};

export default memo(React.forwardRef(MessageItem));
