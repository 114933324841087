import { FormatMessage, Options } from '@/types';

export const getCoeAttachmentTypeOptions = (
  formatMessage: FormatMessage,
): Options => [
  {
    label: formatMessage({
      id: 'app.coe-attachment.transcript',
    }),
    value: 'transcript',
  },
  {
    value: 'proof',
    label: formatMessage({
      id: 'app.coe-attachment.proof',
    }),
  },
  {
    label: formatMessage({
      id: 'app.coe-attachment.certificate',
    }),
    value: 'certificate',
  },
  {
    value: 'release',
    label: formatMessage({
      id: 'app.coe-attachment.release',
    }),
  },
  {
    label: formatMessage({
      id: 'app.coe-attachment.completion-letter',
    }),
    value: 'completionLetter',
  },
  {
    label: formatMessage({
      id: 'app.coe-attachment.transcript-certificate',
    }),
    value: 'transcriptAndCertificate',
  },
  {
    value: 'other',
    label: formatMessage({ id: 'app.coe-attachment.other' }),
  },
];

export const getCoeAttachmentTypeName = (
  value: string,
  formatMessage: FormatMessage,
) => {
  const options = getCoeAttachmentTypeOptions(formatMessage);

  return options.find((item) => item.value === value)?.label;
};
