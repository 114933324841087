import { useIntl } from 'umi';

const useCommonOptions = () => {
  const { formatMessage } = useIntl();

  const yesnoOptions = [
    {
      label: formatMessage({ id: 'form:yesno.option.yes' }),
      value: 1,
    },
    {
      label: formatMessage({ id: 'form:yesno.option.no' }),
      value: 0,
    },
  ];

  const yesnoOptionsAsStrings = [
    {
      label: formatMessage({ id: 'form:yesno.option.yes' }),
      value: '1',
    },
    {
      label: formatMessage({ id: 'form:yesno.option.no' }),
      value: '0',
    },
  ];

  const sexOptions = [
    {
      label: formatMessage({ id: 'form:sex.option.man' }),
      value: 'man',
    },
    {
      label: formatMessage({ id: 'form:sex.option.woman' }),
      value: 'woman',
    },
    {
      label: formatMessage({ id: 'form:sex.option.x' }),
      value: 'x',
    },
  ];

  const normalSexOptions = [
    {
      label: formatMessage({ id: 'form:sex.option.man' }),
      value: 'Male',
    },
    {
      label: formatMessage({ id: 'form:sex.option.woman' }),
      value: 'Female',
    },
    {
      label: formatMessage({ id: 'form:sex.option.x' }),
      value: 'X',
    },
  ];

  return {
    yesnoOptions,
    sexOptions,
    normalSexOptions,
    yesnoOptionsAsStrings,
  };
};

export default useCommonOptions;
