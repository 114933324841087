import { useMemo } from 'react';
import { useIsEn } from '@/hooks';

const FamilyOptions = [
  { cnName: '子女', value: 'Child', enName: 'Child' },
  {
    cnName: '配偶/事实伴侣',
    value: 'Spouse/De Facto Partner',
    enName: 'Spouse/De Facto Partner',
  },
  { cnName: '继子/继女', value: 'Step Child', enName: 'Step Child' },
  { cnName: '父母', value: 'Parents', enName: 'Parents' },
  {
    cnName: '姐弟/兄妹/兄弟/姐妹',
    value: 'Brother/Sister',
    enName: 'Brother/Sister',
  },
];

export const useFamilyRelationshipEnum = (onlySpouseAndChildren?: boolean) => {
  const isEn = useIsEn();

  return useMemo(
    () =>
      new Map(
        FamilyOptions.filter((item) =>
          onlySpouseAndChildren
            ? !['Brother/Sister', 'Parents'].includes(item.value)
            : true,
        ).map((item) => [item.value, item[isEn ? 'enName' : 'cnName']]),
      ),
    [isEn, onlySpouseAndChildren],
  );
};
