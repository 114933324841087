import { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Empty, Input, Menu, Radio, TableProps } from 'antd';
import type {
  ColumnFilterItem,
  FilterDropdownProps,
} from 'antd/lib/table/interface';
import { isFunction, isString } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { safetyGetJSON } from '@/utils';
import useFormatMessage from './useFormatMessage';

type IFilterDropdownProps<T extends ColumnFilterItem> = {
  cacheKey: string;
  defaultValue?: React.Key[];
  placeholder?: string;
  filters?: T[];
  multiple?: boolean;
  filterSearch?: boolean | ((input: string, option: T) => boolean);
} & Omit<FilterDropdownProps, 'filters'>;

const FilterDropdown = <T extends ColumnFilterItem>({
  cacheKey,
  placeholder,
  filters,
  multiple,
  filterSearch,
  confirm,
  clearFilters,
  selectedKeys,
  setSelectedKeys,
}: IFilterDropdownProps<T>) => {
  const [keyword, setKeyword] = useState('');
  const { formatMessage } = useFormatMessage();

  useEffect(() => {
    localStorage.setItem(cacheKey, JSON.stringify(selectedKeys));
  }, [selectedKeys, cacheKey]);

  const handleConfirm = () => {
    confirm({ closeDropdown: true });
  };

  const handleReset = () => {
    clearFilters?.();
  };

  const lowerCasedKeyword = keyword.toLowerCase().trim();
  const list = useMemo(
    () =>
      !lowerCasedKeyword || !filterSearch
        ? filters
        : filters?.filter((item) => {
            if (isFunction(filterSearch)) {
              return filterSearch(lowerCasedKeyword, item);
            }

            return [String(item.value), isString(item.text) && item.text]
              .filter(Boolean)
              .some((key) => String(key).includes(lowerCasedKeyword));
          }),
    [lowerCasedKeyword, filterSearch, filters],
  );

  const menuStyles: React.CSSProperties = {
    overflowX: 'hidden',
    maxHeight: 264,
    overflowY: 'auto',
    padding: '8px 0',
    borderRight: 0,
  };

  const renderMenus = () => (
    <Menu
      style={menuStyles}
      selectedKeys={selectedKeys?.map((item) => String(item))}
      items={list?.map((item) => ({
        label: multiple ? (
          <Checkbox style={{ display: 'flex' }} value={item.value}>
            {item.text}
          </Checkbox>
        ) : (
          <Radio style={{ display: 'flex' }} value={item.value}>
            {item.text}
          </Radio>
        ),
        style: { margin: 0 },
        key: String(item.value),
      }))}
    />
  );

  return (
    <div>
      {filterSearch && (
        <div className="ant-table-filter-dropdown-search">
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={(evt) => setKeyword(evt.target.value)}
            placeholder={placeholder || formatMessage('在筛选项中搜索')}
            className="ant-table-filter-dropdown-search-input"
          />
        </div>
      )}
      {list && list.length ? (
        <>
          {multiple ? (
            <Checkbox.Group
              value={selectedKeys}
              style={{ display: 'block' }}
              onChange={(val) => setSelectedKeys(val as React.Key[])}
            >
              {renderMenus()}
            </Checkbox.Group>
          ) : (
            <Radio.Group
              value={selectedKeys?.[0]}
              style={{ display: 'block' }}
              onChange={(evt) => {
                setSelectedKeys([evt.target.value] as React.Key[]);
              }}
            >
              {renderMenus()}
            </Radio.Group>
          )}
        </>
      ) : (
        <div
          style={{
            padding: '24px 0',
          }}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
      <div className="ant-table-filter-dropdown-btns">
        <Button
          disabled={selectedKeys.length === 0}
          size="small"
          onClick={handleReset}
          type="link"
        >
          {formatMessage('重置')}
        </Button>
        <Button type="primary" size="small" onClick={handleConfirm}>
          {formatMessage('确定')}
        </Button>
      </div>
    </div>
  );
};

const useCachedTableFilters = (tableKey: string) => {
  const getColumnFilterProps = <T extends ColumnFilterItem>(
    dataIndex: string,
    {
      filters,
      ...params
    }: Omit<
      IFilterDropdownProps<T>,
      'cacheKey' | Exclude<keyof FilterDropdownProps, 'filters'>
    >,
  ) => {
    const cacheKey = `${tableKey}_${dataIndex}`;

    return {
      filterDropdown: ({ filters: subFilters, ...props }) => (
        <FilterDropdown<T>
          {...props}
          {...params}
          filters={subFilters as T[]}
          cacheKey={cacheKey}
        />
      ),
      filters,
      defaultFilteredValue: safetyGetJSON(
        localStorage.getItem(cacheKey),
        params.defaultValue,
      ),
    } as Pick<
      NonNullable<TableProps<any>['columns']>[number],
      'filterDropdown' | 'filters' | 'defaultFilteredValue'
    >;
  };

  return getColumnFilterProps;
};

export default useCachedTableFilters;
