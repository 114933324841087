import { CloseOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Progress, Tooltip } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useState } from 'react';
import Uploader from '@/components/Uploader';
import { useFormatMessage } from '@/hooks';
import { fileValidator } from '@/utils/validator';
import styles from '../index.less';

const FileCard: React.FC<{
  file: UploadFile;
  onDelete: (id: string) => void;
}> = ({ file, onDelete }) => {
  return (
    <div className={styles.fileCard}>
      <a
        target="_blank"
        href={file.url}
        rel="noreferrer"
        className={styles.fileName}
      >
        {file.name || file.fileName || file.url || file.thumbUrl}
      </a>
      <div className={styles.fileExtra}>
        {!!file.percent && file.status === 'uploading' && (
          <div
            style={{
              width: 100,
            }}
          >
            <Progress percent={Number(Number(file.percent).toFixed(2))} />
          </div>
        )}
        <Button
          onClick={() => onDelete(file.uid)}
          className={styles.del}
          type="text"
          style={{
            marginLeft: 8,
          }}
          size="small"
          icon={<CloseOutlined />}
        />
      </div>
    </div>
  );
};

const useUploadAttachments = () => {
  const { formatMessage } = useFormatMessage();
  const [fileList, setFileList] = useState<UploadFile<any>[] | undefined>();

  const handleDelete = (id: string) => {
    const index = fileList!.findIndex((item) => item.uid === id);

    if (index > -1) {
      fileList!.splice(index, 1);
      setFileList([...fileList!]);
    }
  };

  const renderFileList = () => {
    return (
      <div>
        {fileList?.map((item) => (
          <FileCard onDelete={handleDelete} key={item.uid} file={item} />
        ))}
      </div>
    );
  };

  const validator = (rule: any) => fileValidator(rule, fileList);
  const sizeValidator = () => {
    if (!Array.isArray(fileList)) {
      return Promise.resolve();
    }

    const totalSize = fileList.reduce((sum, item) => sum + (item.size ?? 0), 0);
    const max = 14.8 * 1024 * 1024;

    if (totalSize > max) {
      return Promise.reject(formatMessage('附件大小不得超过 15 MB'));
    }

    return Promise.resolve();
  };

  const trigger = (
    <Uploader
      listType="text"
      enableDrag={false}
      showUploadList={false}
      value={fileList}
      onChange={setFileList}
      fileNum={9}
      acceptDocx
      multiple
    >
      {() => (
        <Tooltip title={formatMessage('上传附件')}>
          <Button
            style={{
              padding: 0,
              width: 28,
              height: 28,
            }}
            type="text"
            icon={<FileAddOutlined />}
          />
        </Tooltip>
      )}
    </Uploader>
  );

  return [
    fileList,
    {
      trigger,
      renderAttachments: renderFileList,
      setAttachments: setFileList,
      attachmentsValidators: [
        {
          validator,
        },
        {
          validator: sizeValidator,
        },
      ],
    },
  ] as const;
};

export default useUploadAttachments;
