export default {
  'navBar.lang': '语言',
  'app.right-content.user-settings': '个人设置',
  'app.right-content.logout': '退出',
  'app.right-content-.assistant-back-oneulink': '返回 OneuLink',
  'app.all': '全部',
  'app.confirm.title': '温馨提示',
  'app.confirm.content': '确认删除吗？',
  'app.coe-attachment.transcript': '成绩单',
  'app.coe-attachment.proof': '在读证明',
  'app.coe-attachment.certificate': '毕业证',
  'app.coe-attachment.release': '释放信',
  'app.coe-attachment.completion-letter': '完成信',
  'app.coe-attachment.other': '其它',
  'app.coe-attachment.transcript-certificate': '成绩单和毕业证',
  'app.proof-of-work.employment': '在职证明',
  'app.proof-of-work.resignation': '离职证明',
  'app.proof-of-work.payslip': '工资证明',
  'app.proof-of-work.tax-record': '纳税记录',
  'app.proof-of-work.other': '其它',

  'app.proof-of-abroad.transcript': '成绩单',
  'app.proof-of-abroad.graduation': '毕业证',
  'app.proof-of-abroad.degree': '学位证',
  'app.proof-of-abroad.completion-letter': '完成证明',
  'app.proof-of-abroad.enrollment-letter': '在读证明',
  'app.proof-of-abroad.other': '其它',

  'app.modal.success-tips': '操作成功！',
  'app.file.other-tip': '当前文件类型当前不支持预览\n您可以单击下载以查看文件',
  'app.file.download': '下载',
  'app.file.view': '查看文件',
};
