import { useMutation } from '@tanstack/react-query';
import { isFunction, noop } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useModel } from 'umi';
import { ResponseSchema, services } from '@/api';
import { getToken } from '@/auth';
import { RoleType } from '@/data';
import { API, GlobalState, ReleaseLogInfo } from '@/types/API';
import defaultSettings from '../../../config/defaultSettings';

export type AppGlobalStateContextState = {
  update: (onSuccess?: (data: Partial<GlobalState>) => void) => void;
  updating?: boolean;
};

const AppGlobalStateContext = createContext<AppGlobalStateContextState>({
  update: noop,
});

const fetchAllAuthority = async (): Promise<{
  routerAuthorityList: string[];
  apiAuthorityList: string[];
  currentUser: API.CurrentUser;
  extraPermissions: GlobalState['extraPermissions'];
}> => {
  const { data } = await services.account.getUserInfo<
    ResponseSchema<Omit<API.CurrentUser, 'companys'>>
  >();
  const companys =
    data.information.ascription !== RoleType.BACKEND
      ? await (await services.account.getCompanys()).data
      : [];

  return {
    routerAuthorityList: data.router,
    apiAuthorityList: data.permissions,
    extraPermissions: data.extraPermissions,
    currentUser: {
      ...data,
      companys,
    },
  };
};

export async function getInitialState(): Promise<Partial<GlobalState>> {
  const token = getToken();

  const showAllMenu = localStorage.getItem('SHOW_ALL_MENU')
    ? JSON.parse(localStorage.getItem('SHOW_ALL_MENU')!)
    : false;

  const initialState: Partial<GlobalState> = {
    settings: defaultSettings,
    apiAuthorityList: [],
    routerAuthorityList: [],
    showAllMenu,
    token,
  };

  const [
    { data: releaseLogData },
    {
      data: { versionNumber },
    },
    authorities,
    { data: functionGuideReadData },
  ] = await Promise.all([
    services.release.getReleaseStatus<ResponseSchema<ReleaseLogInfo | null>>(),
    services.release.getCurrentVersion<
      ResponseSchema<{ versionNumber: string | null }>
    >(),
    token ? fetchAllAuthority() : Promise.resolve(undefined),
    token
      ? services.functionGuide.getIsRead<ResponseSchema<{ viewed: string[] }>>()
      : Promise.resolve({ data: undefined }),
  ]);

  return {
    ...initialState,
    ...authorities,
    latestRelease: releaseLogData,
    systemVersion: versionNumber,
    localeSystemVersion: APP_VERSION,
    role: authorities?.currentUser.information.ascription,
    accountType: authorities?.currentUser.information.accountType,
    memberId: authorities?.currentUser.information.memberId,
    companyMemberId: authorities?.currentUser.information.chmId,
    companyId:
      authorities?.currentUser.information.previousCompany ?? undefined,
    functionGuideStatusStore: functionGuideReadData
      ? new Map(functionGuideReadData.viewed.map((key) => [key, true]))
      : undefined,
  };
}

export const AppGlobalStateProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const fetchQueue = useRef<Set<(data: Partial<GlobalState>) => void>>(
    new Set(),
  );
  const updatePromise = useRef<null | Promise<Partial<GlobalState>>>(null);
  const { setInitialState } = useModel('@@initialState');
  const { isLoading, mutateAsync } = useMutation(getInitialState, {
    mutationKey: ['updateInitialState'],
    onSuccess(data) {
      const queue = Array.from(fetchQueue.current);

      fetchQueue.current.clear();
      updatePromise.current = null;

      setInitialState(data);
      queue.forEach((item) => item(data));
    },
  });

  const update = useCallback(
    async (onSuccess?: (data: Partial<GlobalState>) => void) => {
      if (isFunction(onSuccess)) {
        fetchQueue.current.add(onSuccess);
      }

      if (!updatePromise.current) {
        updatePromise.current = mutateAsync();
      }

      const result = await updatePromise.current;

      return result;
    },
    [mutateAsync],
  );

  const ctxValue: AppGlobalStateContextState = useMemo(
    () => ({
      update,
      updating: isLoading,
    }),
    [update, isLoading],
  );

  return (
    <AppGlobalStateContext.Provider value={ctxValue}>
      {children}
    </AppGlobalStateContext.Provider>
  );
};

export const useAppGlobalStateContext = () => useContext(AppGlobalStateContext);
