import { useMemo } from 'react';
import useFormatMessage from '@/hooks/useFormatMessage';

export enum CaseCloseType {
  FAILED = 'failed',
  CLIENT_SELF = 'clientSelf',
  CLIENT_LOST = 'clientLost',
  VISA_REFUSAL = 'visa-refusal',
  OTHER = 'other',
  ERROR_AT_CREATE_CASE = 'error-at-create-case',
}
export const useCaseCloseTypeEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum = {
      [CaseCloseType.FAILED]: {
        text: /*  @message.scope case-close-status @message.en-US Case failed */ formatMessage(
          '案件失败',
        ),
        status: 'error',
      },
      [CaseCloseType.CLIENT_SELF]: {
        text: /*  @message.scope case-close-status @message.en-US Change of mind */ formatMessage(
          '客户改变主意',
        ),
        status: 'error',
      },

      [CaseCloseType.CLIENT_LOST]: {
        text: /*  @message.scope case-close-status @message.en-US Lost the client */ formatMessage(
          '客户丢失',
        ),
        status: 'error',
      },
      [CaseCloseType.ERROR_AT_CREATE_CASE]: {
        text: /*  @message.scope case-close-status @message.en-US Error creating case */ formatMessage(
          '创建案子出错',
        ),
        status: 'error',
      },
      [CaseCloseType.VISA_REFUSAL]: {
        text: /* @message.en-US Visa Refusal @message.scope case-close-status */ formatMessage(
          '签证拒签',
        ),
        status: 'error',
      },
      [CaseCloseType.OTHER]: {
        text: /*  @message.scope case-close-status @message.en-US Other reasons */ formatMessage(
          '其它原因',
        ),
        status: 'error',
      },
    } as const;

    return new Map(
      Object.entries(statusEnum).map((item) => [item[0], item[1]]),
    );
  }, [formatMessage]);
};
