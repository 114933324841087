import { Space, Tag } from 'antd';
import React, { memo } from 'react';
import ShortLine from '@/components/ShortLine';
import {
  MessageTaskRecipientType,
  usePersonnelNameEnum,
  useRecipientTypeEnum,
} from './data';

const Recipient: React.FC<{
  name: string;
  type: MessageTaskRecipientType;
  personalName?: string;
}> = ({ name, type, personalName }) => {
  const typeEnum = useRecipientTypeEnum();
  const { caseGroup, clientGroup } = usePersonnelNameEnum();
  const common = {
    ...caseGroup,
    ...clientGroup,
  };

  // 不是代称一定要有名称
  if (type !== MessageTaskRecipientType.PRONOUN && !name) {
    return <ShortLine />;
  }

  const target = typeEnum[type];
  // personalName 代称谓的值
  const label =
    type === MessageTaskRecipientType.PRONOUN && personalName
      ? common[personalName]
      : target?.text;
  // 方便取值， name 可能是代称谓的值
  const renderName = name in common ? common[name] : name;

  return (
    <Space size={4}>
      <Tag color={target.color}>{label ?? target.text}</Tag>
      <span>{renderName}</span>
    </Space>
  );
};

export default memo(Recipient);
