import { CloseOutlined } from '@ant-design/icons';
import { useKeyPress } from 'ahooks';
import { Button } from 'antd';
import classNames from 'classnames';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { omit } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { lock, unlock } from 'tua-body-scroll-lock';
import { useScreenWidth } from '@/hooks';
import styles from './index.less';

const Popup: React.FC<{
  open?: boolean;
  onClose?: () => void;
  afterClose?: () => void;
  className?: string;
  immediate?: boolean;
  zIndex?: number;
  bodyClassName?: string;
  maskClosable?: boolean;
  title?: React.ReactNode;
  closeable?: boolean;
  closeIcon?: React.ReactNode;
  animations?: Pick<
    HTMLMotionProps<'div'>,
    'initial' | 'animate' | 'exit' | 'transition'
  >;
  bodyStyle?: React.CSSProperties;
  width?: number;
  extra?: React.ReactNode;
  center?: boolean;
}> = ({
  zIndex,
  open,
  onClose,
  afterClose,
  className,
  immediate,
  bodyClassName,
  animations,
  children,
  title,
  closeIcon,
  closeable,
  bodyStyle,
  width,
  extra,
  center = true,
  maskClosable = true,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { screenWidth } = useScreenWidth();

  useKeyPress('esc', () => {
    onClose?.();
  });

  useEffect(() => {
    const containerElem = ref.current;
    if (open) {
      lock(containerElem);
    }

    return () => {
      unlock(containerElem);
    };
  }, [open]);

  const defaultAnimations: HTMLMotionProps<'div'> = {
    initial: {
      y: '-100%',
    },
    animate: {
      y: '-0%',
    },
    exit: {
      y: '-100%',
    },
  };

  const transition = !immediate
    ? {
        type: 'spring',
        stiffness: 600,
        damping: 50,
      }
    : { duration: 0 };

  const hasContent = title || closeIcon || closeable;
  const bodyWidth = width ? Math.min(screenWidth - 30, width) : undefined;

  return createPortal(
    <AnimatePresence
      onExitComplete={() => {
        afterClose?.();
      }}
      exitBeforeEnter
    >
      {open && (
        <motion.div
          key="mask"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={transition}
          className={classNames(
            styles.mask,
            maskClosable && styles.maskClosable,
          )}
          style={{ zIndex }}
          {...(maskClosable && {
            role: 'button',
            tabIndex: 0,
          })}
          onClick={() => {
            if (maskClosable) {
              onClose?.();
            }
          }}
        />
      )}

      {open && (
        <motion.div
          key="content"
          className={classNames(
            styles.container,
            className,
            center && styles.center,
          )}
          transition={{
            ...transition,
            ...(!immediate && animations?.transition),
          }}
          style={{ zIndex }}
          ref={ref}
          {...{ ...defaultAnimations, ...omit(animations, 'transition') }}
        >
          <div
            className={classNames(
              styles.wrapper,
              hasContent && styles.hasContent,
            )}
          >
            {hasContent && (
              <div className={styles.header}>
                {title && <h3 className={styles.title}>{title}</h3>}
                {extra}
              </div>
            )}
            {closeIcon ||
              (closeable && (
                <span
                  onClick={onClose}
                  className={styles.close}
                  role="button"
                  tabIndex={0}
                >
                  {closeIcon || <Button type="text" icon={<CloseOutlined />} />}
                </span>
              ))}
            <div
              className={classNames(styles.body, bodyClassName)}
              style={{
                width: bodyWidth,
                ...bodyStyle,
              }}
            >
              {children}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body,
  );
};

export default Popup;
