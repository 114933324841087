import { BadgeProps } from 'antd';
import { useMemo } from 'react';
import useFormatMessage from '@/hooks/useFormatMessage';

export enum CaseStatus {
  PROCESSING = 1,
  SUCCESS = 2,
  ERROR = 3,
  PENDING = 4,
}

export enum CaseCreateStatus {
  /**
   * 等待创建
   */
  WAIT = 'wait',

  /**
   * 创建进行中
   */
  PROCESSING = 'in-progress',

  /**
   * 创建完成
   */
  COMPLETED = 'completed',

  /**
   * 创建失败
   */
  FAIL = 'fail',
}

export enum CasePendingStatusEnum {
  PENDING = 'pending',
  NOT_PENDING = 'not-pending',
}

export const useCaseStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum: Record<
      CaseStatus,
      {
        text: string;
        status: BadgeProps['status'];
      }
    > = {
      1: { text: formatMessage('进行中'), status: 'processing' },
      2: { text: formatMessage('案件成功'), status: 'success' },
      3: { text: formatMessage('案件关闭'), status: 'error' },
      4: { text: formatMessage('案件已挂起'), status: 'error' },
    } as const;
    return new Map(
      Object.entries(statusEnum).map((item) => [Number(item[0]), item[1]]),
    );
  }, [formatMessage]);
};

export const useCaseCreateStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum: Record<
      CaseCreateStatus,
      {
        text: string;
        status?: string;
      }
    > = {
      [CaseCreateStatus.PROCESSING]: {
        text: formatMessage('案件创建中'),
        status: 'processing',
      },
      [CaseCreateStatus.COMPLETED]: {
        text: formatMessage('案件创建成功'),
        status: 'success',
      },
      [CaseCreateStatus.FAIL]: {
        text: formatMessage('案件创建失败'),
        status: 'error',
      },
      [CaseCreateStatus.WAIT]: { text: formatMessage('案件等待创建') },
    } as const;
    return new Map(
      Object.entries(statusEnum).map((item) => [item[0], item[1]]),
    );
  }, [formatMessage]);
};
