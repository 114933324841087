export default {
  'navBar.lang': 'Lanuages',
  'app.right-content.user-settings': 'Settings',
  'app.right-content.logout': 'Logout',
  'app.right-content-.assistant-back-oneulink': 'Back to OneuLink',
  'app.all': 'All',
  'app.confirm.title': 'Tips',
  'app.confirm.content': 'Confirm delete?',

  'app.coe-attachment.transcript': 'Transcript',
  'app.coe-attachment.proof': 'Enrollment Certificate',
  'app.coe-attachment.certificate': 'Graduation Certificate',
  'app.coe-attachment.release': 'Release Letter',
  'app.coe-attachment.other': 'Others',
  'app.coe-attachment.completion-letter': 'Completion Letter',
  'app.coe-attachment.transcript-certificate':
    'Transcript - Graduation Certificate',

  'app.proof-of-work.employment': 'Employment certificate',
  'app.proof-of-work.resignation': 'Resignation certificate',
  'app.proof-of-work.payslip': 'Payslip',
  'app.proof-of-work.tax-record': 'Tax record',
  'app.proof-of-work.other': 'Others',

  'app.proof-of-abroad.graduation': 'Graduation certificate',
  'app.proof-of-abroad.transcript': 'Transcript',
  'app.proof-of-abroad.degree': 'Degree certificate',
  'app.proof-of-abroad.completion-letter': 'The letter of completion',
  'app.proof-of-abroad.enrollment-letter': 'Enrollment letter',
  'app.proof-of-abroad.other': 'Others',

  'app.modal.success-tips': 'Successful operation!',
  'app.file.other-tip':
    'The current file type does not currently support preview\nYou can click to download to view the file',
  'app.file.download': 'Download',
  'app.file.view': 'View File',
};
