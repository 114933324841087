import { Button, ButtonProps } from 'antd';
import React from 'react';
import { Fetcher, services } from '@/api';
import { useDownload } from '@/hooks';
import { useFileLimit } from '../FileLimit';

const Downloader: React.FC<{
  fetcher?: Fetcher;
  onDownload?: () => any;
  text?: string;
  renderTrigger?: (params: {
    downloading: boolean;
    download: (params?: AnyObject) => Promise<any>;
  }) => React.ReactElement;
  trigger?: React.ReactElement<{
    onClick: () => any;
  }>;
  buttonProps?: ButtonProps;
}> = ({
  onDownload,
  text,
  trigger,
  renderTrigger,
  buttonProps,
  fetcher = services.file.downloadFile,
}) => {
  const { download: handler } = useDownload(fetcher);
  const { loading: downloading, run: download, fileLimitHolder } = useFileLimit(
    handler,
  );

  let content: React.ReactNode;

  if (renderTrigger) {
    content = renderTrigger({
      downloading,
      download: (params?: AnyObject) =>
        download({
          ...onDownload?.(),
          ...params,
        }),
    });
  } else {
    const internalTrigger = trigger || (
      <Button {...buttonProps} loading={downloading}>
        {text}
      </Button>
    );

    content = React.cloneElement(internalTrigger, {
      onClick(...args: any) {
        download({ ...onDownload?.() });
        internalTrigger.props.onClick?.(...args);
      },
      loading: downloading,
      children: text || internalTrigger.props.children,
    });
  }

  return (
    <>
      {content}
      {fileLimitHolder}
    </>
  );
};

export default Downloader;
