import { useMemo } from 'react';
import { IVariableGroupData } from '@/components/TiptapEditor';
import { useFormatMessage } from '@/hooks';

export enum MessageTaskTemplateType {
  COMMON = 'common',
  VISA = 'visa-expired',
  PASSPORT = 'passport-expired',
  CLIENT_BIRTHDAY = 'customer-birthday',
  ON_CASE_CREATED = 'case-created',
  ON_CASE_COMPLETED = 'case-success',
  ON_CLIENT_CREATED = 'customer-created',
  ON_COE_COURSE_START = 'coe-course-start',
  ON_COE_COURSE_END = 'coe-course-end',

  ON_VISA_WILL_DUE = 'visa-will-due',
  ON_VISA_EXPIRATION_URGENT = 'visa-expiration-urgent',
}

export enum MessageTaskEventType {
  VISA = 'visa-expired',
  PASSPORT = 'passport-expired',
  CLIENT_BIRTHDAY = 'customer-birthday',
  ON_CASE_CREATED = 'case-created',
  ON_CASE_COMPLETED = 'case-success',
  ON_CLIENT_CREATED = 'customer-created',
  ON_COE_COURSE_START = 'coe-course-start',
  ON_COE_COURSE_END = 'coe-course-end',
  NORMAL = 'normal',

  ON_VISA_WILL_DUE = 'visa-will-due',
  ON_VISA_EXPIRATION_URGENT = 'visa-expiration-urgent',
}

export enum MessageTaskGroup {
  OVERALL = 'overall',
  CLIENT = 'customer',
  CASE = 'customer-case',
  TODO = 'todo-task',
}

export enum MessageTaskStatus {
  // 运行中
  RUNNING = 1,
  // 暂停
  SUSPEND = 2,
  // 关闭
  CLOSED = 3,
}

export type MessageTaskScope = 'client' | 'case' | 'all' | 'todo';

export const useMessageTemplateVariable = () => {
  const { formatMessage } = useFormatMessage();

  const variables = useMemo(() => {
    const client: IVariableGroupData[] = [
      {
        title: formatMessage('客户信息'),
        value: 'client',
        options: [
          {
            label: formatMessage('客户称谓+姓氏'),
            value: 'prefix+familyName',
          },
          {
            label: formatMessage('客户称谓+名字+姓氏'),
            value: 'prefix+givenName+familyName',
          },
          {
            label: formatMessage('客户名字'),
            value: 'givenName',
          },
          {
            label: formatMessage('客户昵称'),
            value: 'preferName',
          },
          {
            label: formatMessage('客户昵称+姓氏'),
            value: 'preferName+familyName',
          },
          {
            label: formatMessage('客户生日'),
            value: 'birthday',
          },
          {
            label: formatMessage('推荐人姓名'),
            value: 'referrerName',
          },
          {
            label: formatMessage('推荐人邮箱'),
            value: 'referrerEmail',
          },
          {
            label: formatMessage('推荐人联系二维码'),
            value: 'referrerQrcode',
            image: true,
            imageClassName: 'variable-referrer-qrcode',
          },

          {
            label: formatMessage('最近案件处理专员姓氏'),
            value: 'lastHandleStaffFamilyName',
          },
          {
            label: formatMessage('最近案件处理专员名字'),
            value: 'lastHandleStaffGivenName',
          },
          {
            label: formatMessage('最近案件处理专员姓氏+名字'),
            value: 'lastHandleStaffFamilyName+lastHandleStaffGivenName',
          },
          {
            label: formatMessage('最近案件处理专员昵称'),
            value: 'lastHandleStaffNickname',
          },
          {
            label: formatMessage('客户详情链接'),
            value: 'detailUrl',
            link: true,
          },
        ],
      },
    ];

    const visa: IVariableGroupData[] = [
      {
        title: formatMessage('签证信息'),
        value: 'visa',
        options: [
          {
            label: formatMessage('签证到期日期'),
            value: 'expirationDate',
          },
          {
            label: formatMessage('签证类型'),
            value: 'type',
          },
          {
            label: formatMessage('签证距离过期剩余天数'),
            value: 'expirationDays',
          },
        ],
      },
    ];

    const passport: IVariableGroupData[] = [
      {
        title: formatMessage('护照信息'),
        value: 'passport',
        options: [
          {
            label: formatMessage('护照到期日期'),
            value: 'expirationDate',
          },
        ],
      },
    ];

    const caseInfo: IVariableGroupData[] = [
      {
        title: formatMessage('案件信息'),
        value: 'case',
        options: [
          {
            label: formatMessage('案件名称'),
            value: 'name',
          },
          {
            label: formatMessage('案件推荐人'),
            value: 'refereeName',
          },

          {
            label: formatMessage('案件创建人'),
            value: 'createdMemberName',
          },
          {
            label: formatMessage('案件详情链接'),
            value: 'detailUrl',
            link: true,
          },
        ],
      },
    ];

    const companyInfo: IVariableGroupData[] = [
      {
        title: formatMessage('公司信息'),
        value: 'company',
        options: [
          {
            label: formatMessage('公司联系电话'),
            value: 'tel',
          },
          {
            label: formatMessage('公司名称'),
            value: 'tradingName',
          },
          {
            label: formatMessage('公司创始人'),
            value: 'founder',
          },
        ],
      },
    ];

    const courseInfo: IVariableGroupData[] = [
      {
        title: formatMessage('课程信息'),
        value: 'course',
        options: [
          {
            label: formatMessage('学校英文名字'),
            value: 'schoolEnName',
          },
          {
            label: formatMessage('课程英文名字'),
            value: 'courseEnName',
          },
          {
            label: formatMessage('课程开始日期'),
            value: 'courseStartDate',
          },
          {
            label: formatMessage('课程结束日期'),
            value: 'courseEndDate',
          },
          {
            label: formatMessage('距离课程开始天数'),
            value: 'numberOfDaysUntilCourseStart',
          },
          {
            label: formatMessage('距离课程结束天数'),
            value: 'numberOfDaysUntilEndStart',
          },
        ],
      },
    ];

    const configs: Record<MessageTaskTemplateType, IVariableGroupData[][]> = {
      [MessageTaskTemplateType.COMMON]: [companyInfo],
      [MessageTaskTemplateType.CLIENT_BIRTHDAY]: [client, companyInfo],
      [MessageTaskTemplateType.VISA]: [client, visa, companyInfo],
      [MessageTaskTemplateType.PASSPORT]: [client, passport, companyInfo],
      [MessageTaskTemplateType.ON_CASE_CREATED]: [
        client,
        caseInfo,
        companyInfo,
      ],
      [MessageTaskTemplateType.ON_CASE_COMPLETED]: [
        client,
        caseInfo,
        companyInfo,
      ],
      [MessageTaskTemplateType.ON_CLIENT_CREATED]: [client, companyInfo],
      [MessageTaskTemplateType.ON_COE_COURSE_START]: [
        client,
        companyInfo,
        courseInfo,
      ],
      [MessageTaskTemplateType.ON_COE_COURSE_END]: [
        client,
        companyInfo,
        courseInfo,
      ],
      [MessageTaskTemplateType.ON_VISA_WILL_DUE]: [
        client,
        visa,
        caseInfo,
        companyInfo,
      ],
      [MessageTaskTemplateType.ON_VISA_EXPIRATION_URGENT]: [
        client,
        visa,
        caseInfo,
        companyInfo,
      ],
    };

    return Object.fromEntries(
      Object.entries(configs).map((item) => [item[0], item[1].flat()]),
    );
  }, [formatMessage]);

  const variableOptions = useMemo(
    () => [
      {
        label: formatMessage('公共模板'),
        value: MessageTaskTemplateType.COMMON,
      },
      {
        label: formatMessage('签证到期'),
        value: MessageTaskTemplateType.VISA,
      },
      {
        label: formatMessage('护照到期'),
        value: MessageTaskTemplateType.PASSPORT,
      },
      {
        label: formatMessage('客户生日'),
        value: MessageTaskTemplateType.CLIENT_BIRTHDAY,
      },
      {
        label: formatMessage('案件创建成功'),
        value: MessageTaskTemplateType.ON_CASE_CREATED,
      },
      {
        label: formatMessage('案件完成'),
        value: MessageTaskTemplateType.ON_CASE_COMPLETED,
      },
      {
        label: formatMessage('创建客户成功'),
        value: MessageTaskTemplateType.ON_CLIENT_CREATED,
      },
      {
        label: formatMessage('课程开始'),
        value: MessageTaskTemplateType.ON_COE_COURSE_START,
      },
      {
        label: formatMessage('课程结束'),
        value: MessageTaskTemplateType.ON_COE_COURSE_END,
      },
      {
        label: formatMessage('签证续签临期提醒'),
        value: MessageTaskTemplateType.ON_VISA_WILL_DUE,
      },
      {
        label: formatMessage('签证续签紧急提醒'),
        value: MessageTaskTemplateType.ON_VISA_EXPIRATION_URGENT,
      },
    ],
    [formatMessage],
  );

  return { variables, variableOptions };
};

export const useMessageTaskEventTypeOptions = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    return [
      {
        label: formatMessage('定时任务'),
        value: MessageTaskEventType.NORMAL,
      },
      {
        label: formatMessage('签证到期'),
        value: MessageTaskEventType.VISA,
      },
      {
        label: formatMessage('护照到期'),
        value: MessageTaskEventType.PASSPORT,
      },
      {
        label: formatMessage('客户生日'),
        value: MessageTaskEventType.CLIENT_BIRTHDAY,
      },
      {
        label: formatMessage('案件创建成功'),
        value: MessageTaskEventType.ON_CASE_CREATED,
      },
      {
        label: formatMessage('案件完成'),
        value: MessageTaskEventType.ON_CASE_COMPLETED,
      },
      {
        label: formatMessage('创建客户成功'),
        value: MessageTaskEventType.ON_CLIENT_CREATED,
      },
      {
        label: formatMessage('课程开始'),
        value: MessageTaskEventType.ON_COE_COURSE_START,
      },
      {
        label: formatMessage('课程结束'),
        value: MessageTaskEventType.ON_COE_COURSE_END,
      },
      {
        label: formatMessage('签证续签临期提醒'),
        value: MessageTaskEventType.ON_VISA_WILL_DUE,
      },
      {
        label: formatMessage('签证续签紧急提醒'),
        value: MessageTaskEventType.ON_VISA_EXPIRATION_URGENT,
      },
    ];
  }, [formatMessage]);
};

export const messageTaskEventTypeIsHook = (type: MessageTaskEventType) => {
  return [
    MessageTaskEventType.ON_CASE_COMPLETED,
    MessageTaskEventType.ON_CASE_CREATED,
    MessageTaskEventType.ON_CLIENT_CREATED,
    MessageTaskEventType.ON_VISA_EXPIRATION_URGENT,
    MessageTaskEventType.ON_VISA_WILL_DUE,
  ].includes(type);
};

export const MessageTaskGroupedData: Record<
  MessageTaskScope,
  Record<MessageTaskGroup, MessageTaskEventType[]>
> = {
  /**
   * 对于每个 scope
   *
   * all 可以指定全局
   * client 可以指定具体客户
   * case 可以指定具体案件
   *
   * 用 event 反向查找有哪些任务分组
   */
  all: {
    [MessageTaskGroup.OVERALL]: [
      MessageTaskEventType.PASSPORT,
      MessageTaskEventType.VISA,
      MessageTaskEventType.CLIENT_BIRTHDAY,
      MessageTaskEventType.ON_CASE_CREATED,
      MessageTaskEventType.ON_CASE_COMPLETED,
      MessageTaskEventType.ON_CLIENT_CREATED,
      MessageTaskEventType.ON_COE_COURSE_END,
      MessageTaskEventType.ON_COE_COURSE_START,
      MessageTaskEventType.ON_VISA_WILL_DUE,
      MessageTaskEventType.ON_VISA_EXPIRATION_URGENT,
    ],
    [MessageTaskGroup.CLIENT]: [
      MessageTaskEventType.PASSPORT,
      MessageTaskEventType.VISA,
      MessageTaskEventType.CLIENT_BIRTHDAY,
      MessageTaskEventType.ON_CASE_CREATED,
      MessageTaskEventType.ON_COE_COURSE_END,
      MessageTaskEventType.ON_COE_COURSE_START,
    ],
    [MessageTaskGroup.CASE]: [
      MessageTaskEventType.ON_CASE_COMPLETED,
      MessageTaskEventType.ON_VISA_WILL_DUE,
      MessageTaskEventType.ON_VISA_EXPIRATION_URGENT,
    ],
    [MessageTaskGroup.TODO]: [],
  },
  client: {
    [MessageTaskGroup.OVERALL]: [],
    [MessageTaskGroup.CLIENT]: [
      MessageTaskEventType.PASSPORT,
      MessageTaskEventType.VISA,
      MessageTaskEventType.CLIENT_BIRTHDAY,
      MessageTaskEventType.ON_CASE_CREATED,
      MessageTaskEventType.ON_COE_COURSE_END,
      MessageTaskEventType.ON_COE_COURSE_START,
      // 定时任务的优先级最低
      MessageTaskEventType.NORMAL,
    ],
    [MessageTaskGroup.CASE]: [],
    [MessageTaskGroup.TODO]: [],
  },
  case: {
    [MessageTaskGroup.OVERALL]: [],
    [MessageTaskGroup.CLIENT]: [],
    [MessageTaskGroup.CASE]: [
      // 定时任务的优先级最低
      MessageTaskEventType.NORMAL,
    ],
    [MessageTaskGroup.TODO]: [],
  },
  todo: {
    [MessageTaskGroup.OVERALL]: [],
    [MessageTaskGroup.CLIENT]: [],
    [MessageTaskGroup.CASE]: [],
    [MessageTaskGroup.TODO]: [MessageTaskEventType.NORMAL],
  },
};

export const messageTaskEventOnlyBelongToClient = (
  type: MessageTaskEventType,
) =>
  [
    MessageTaskEventType.PASSPORT,
    MessageTaskEventType.VISA,
    MessageTaskEventType.CLIENT_BIRTHDAY,
    MessageTaskEventType.ON_COE_COURSE_END,
    MessageTaskEventType.ON_COE_COURSE_START,
  ].includes(type);

/**
 * 哪些任务可以在 scope = 'client' 下显示
 */
export const messageTaskEventIsBelongToClient = (
  type: MessageTaskEventType,
) => {
  return [
    MessageTaskEventType.PASSPORT,
    MessageTaskEventType.VISA,
    MessageTaskEventType.CLIENT_BIRTHDAY,
    MessageTaskEventType.ON_CASE_CREATED,
    MessageTaskEventType.NORMAL,
    MessageTaskEventType.ON_COE_COURSE_END,
    MessageTaskEventType.ON_COE_COURSE_START,
  ].includes(type);
};

/**
 * 哪些任务可以在 scope = 'case' 下显示
 */
export const messageTaskEventIsBelongToCase = (type: MessageTaskEventType) => {
  return [
    MessageTaskEventType.ON_CASE_COMPLETED,
    MessageTaskEventType.ON_VISA_EXPIRATION_URGENT,
    MessageTaskEventType.ON_VISA_WILL_DUE,
    MessageTaskEventType.NORMAL,
  ].includes(type);
};

/**
 * 哪些任务类型可以包括案件信息
 */
export const messageTaskEventIncludeCaseInfo = (type: MessageTaskEventType) =>
  [
    MessageTaskEventType.ON_CASE_CREATED,
    MessageTaskEventType.ON_CASE_COMPLETED,
    MessageTaskEventType.ON_VISA_EXPIRATION_URGENT,
    MessageTaskEventType.ON_VISA_WILL_DUE,
  ].includes(type);
