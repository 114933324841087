import { LinkOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import { Button, Divider, Skeleton, Space, Tooltip } from 'antd';
import React from 'react';
import { EmailForwardIcon, EmailReplyIcon } from '@/PageComponents/EmailEditor';
import FileViewer from '@/PageComponents/FileViewer';
import DynamicRelativeTime from '@/components/DynamicRelativeTime';
import { useFormatMessage } from '@/hooks';
import styles from '../index.less';
import EmailHTMLBody from './EmailHTMLBody';
import EmailSummary from './EmailSummary';

const PageEmailDetail: React.FC<{
  onForward?: () => void;
  onReply?: () => void;
  loading: boolean;
  data?: AnyObject;
}> = ({ onForward, onReply, loading, data }) => {
  const { formatMessage } = useFormatMessage();
  const options = [
    {
      key: 'forward',
      label: formatMessage('转发'),
      icon: EmailForwardIcon,
    },
    {
      key: 'reply',
      label: formatMessage('回复'),
      icon: EmailReplyIcon,
    },
  ];

  const title = data?.subject;

  const renderActions = (label?: boolean) =>
    options.map((item) => {
      const btn = (
        <Button
          key={item.key}
          onClick={() => {
            if (item.key === 'forward') {
              onForward?.();
            } else {
              onReply?.();
            }
          }}
          type={label ? 'default' : 'text'}
          icon={React.createElement(item.icon, {
            style: {
              color: '#666',
              fontSize: 20,
              transform: 'translateY(0.1em)',
            },
          })}
        >
          {label && item.label}
        </Button>
      );

      return label ? (
        btn
      ) : (
        <Tooltip key={item.key} title={item.label}>
          {btn}
        </Tooltip>
      );
    });

  return (
    <PageContainer
      title={title}
      loading={loading}
      header={{
        children: data ? (
          <div>
            <EmailSummary
              emailInfo={data}
              actions={[
                data?.attachments?.length > 0 && <LinkOutlined key="link" />,
                data?.utcDate && (
                  <DynamicRelativeTime key="time" time={data.utcDate} />
                ),
                renderActions(),
              ]}
            />

            <div className={styles.body}>
              <div>
                <EmailHTMLBody html={data?.htmlBody} />
              </div>

              {data?.attachments?.length > 0 && (
                <>
                  <Divider orientation="left" />
                  <div className={styles.attachments}>
                    {data!.attachments.map((item: AnyObject) => (
                      <div
                        style={{
                          marginBottom: 4,
                        }}
                        key={item.fileId}
                      >
                        <FileViewer id={item.fileId}>
                          {item.filename}
                        </FileViewer>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>

            <Space
              style={{
                marginBottom: 24,
              }}
            >
              {renderActions(true)}
            </Space>
          </div>
        ) : (
          <div>
            <Skeleton avatar />
            <Skeleton
              paragraph={{
                rows: 3,
              }}
            />
            <Skeleton
              paragraph={{
                rows: 5,
              }}
            />
            <Space>
              <Skeleton.Button />
              <Skeleton.Button />
            </Space>
          </div>
        ),
      }}
    />
  );
};

export default PageEmailDetail;
