import React, { useEffect, useRef, useState } from 'react';
import { getId } from '@/utils';
import { useModalManagerContext } from './ModalManagerProvider';

const ModalManager = (
  {
    children,
    queue,
    visible,
    visibleProperty = 'visible',
    ...props
  }: {
    children: React.ReactElement;
    visibleProperty?: string;
    queue?: string;
    visible: boolean;
  },
  ref: React.Ref<unknown>,
) => {
  const [internalVisible, setInterVisible] = useState(visible);
  const { open, close } = useModalManagerContext()!;
  const id = useRef(getId());

  useEffect(() => {
    if (!visible) {
      setInterVisible(false);
      close(id.current);
      return undefined;
    }

    return open(
      () => {
        setInterVisible(true);
      },
      id.current,
      queue,
    );
  }, [visible, queue, open, close]);

  if (!React.isValidElement(children)) {
    return <>{children}</>;
  }

  return (
    <>
      {React.cloneElement(children, {
        [visibleProperty]: internalVisible,
        // @ts-ignore
        ref,
        ...props,
      })}
    </>
  );
};

export default React.forwardRef(ModalManager);
