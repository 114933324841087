import { useMutation } from '@tanstack/react-query';
import { usePersistFn } from 'ahooks';
import { noop } from 'lodash';
import { useCallback } from 'react';
import { ResponseSchema, services } from '../../api';
import { setToken } from '../../auth';
import { useAppGlobalStateContext } from './AppGlobalState';

const useSwitchCompany = (onOk = noop) => {
  const { update, updating } = useAppGlobalStateContext();
  const callback = usePersistFn(onOk);
  const { mutate, isLoading } = useMutation<
    ResponseSchema<{ token: string }>,
    any,
    { id: number }
  >(services.account.switchCompany, {
    onSuccess({ data }) {
      setToken(data.token);
      update(callback);
    },
    mutationKey: ['switchCompany'],
  });

  const switching = updating || isLoading;

  const switchCompany = useCallback(
    (id: number) => {
      mutate({ id });
    },
    [mutate],
  );

  return {
    switching,
    switchCompany,
  };
};

export default useSwitchCompany;
