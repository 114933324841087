import { Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useEffect } from 'react';
import useFormItemDefaultLayout from './useFormItemDefaultLayout';

const usePublicForm = (
  props?: Omit<FormProps<any>, 'onFinish'> & {
    onFinish?: (values: AnyObject) => Promise<any>;
  },
) => {
  const [form] = Form.useForm();
  const { formItemLayout } = useFormItemDefaultLayout();
  const { initialValues } = props ?? {};

  useEffect(() => {
    form.setFieldsValue({ initialValues });
  }, [form, initialValues]);

  const formProps: Omit<FormProps<any>, 'onFinish'> & {
    onFinish?: (values: AnyObject) => Promise<any>;
  } = {
    form,
    layout: 'vertical',
    ...(props?.layout === 'horizontal' ? formItemLayout : undefined),
    scrollToFirstError: {
      block: 'center',
      behavior: 'smooth',
    },
    ...props,
  };

  return [form, formProps] as const;
};

export default usePublicForm;
