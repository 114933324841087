import { ModalForm, ModalFormProps } from '@ant-design/pro-form';
import { FormInstance, Spin } from 'antd';
import { debounce, omit } from 'lodash';
import { useEffect, useRef } from 'react';
import { modalLayout } from '@/config';
import { getId } from '@/utils';

export interface ProModalFormProps<T = AnyObject>
  extends Omit<ModalFormProps<T>, 'form' | 'onFinish'> {
  onOk?: () => void;
  wider?: boolean;
  loading?: boolean;
  onCancel?: () => void;
  visible?: boolean;
  form?: FormInstance;
  onFinish: ModalFormProps<T>['onFinish'];
}

const overflowCheck = () => {
  const { body } = document;
  const className = 'ant-scrolling-effect';

  if (body.classList.contains(className)) {
    body.classList.remove(className);
    body.style.width = '';
    body.style.overflow = '';
  }
};

function ProModalForm<T = AnyObject>({
  onOk,
  onCancel,
  wider,
  children,
  form,
  width,
  visible,
  trigger,
  onVisibleChange,
  loading = false,
  ...props
}: ProModalFormProps<T>) {
  const keyId = useRef(`modal-wrapper-${getId()}`);
  const internalWidth =
    width ?? (wider ? modalLayout.widerWidth : modalLayout.width);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleVisibleChange = (visible: boolean) => {
    onVisibleChange?.(visible);

    if (!visible) {
      onCancel?.();
    }
  };

  useEffect(() => {
    if (!visible) {
      const fn = debounce(overflowCheck, 16 * 40);
      return fn.cancel;
    }

    return undefined;
  }, [visible]);

  return (
    <ModalForm<T>
      preserve={false}
      {...props}
      form={form}
      width={internalWidth}
      modalProps={{
        ...omit(modalLayout, ['width']),
        ...props.modalProps,
        maskClosable: false,
        afterClose: () => {
          if (form) {
            form.resetFields();
          }
          props?.modalProps?.afterClose?.();
        },
      }}
      {...(trigger
        ? { trigger, visible }
        : {
            visible,
          })}
      onVisibleChange={handleVisibleChange}
    >
      <div id={keyId.current} className="public-modal-root">
        <div className="fake-ant-form">
          <Spin spinning={loading}>{children}</Spin>
        </div>
      </div>
    </ModalForm>
  );
}

export default ProModalForm;
