import { DEGREE } from '@/data';
import { FormatMessage, Options } from '@/types';

export const getEnrolmentStatusOptions = (
  formatMessage: FormatMessage,
): Options => [
  {
    label: formatMessage({
      id: 'apply-form:entrolment-status.option.discontinued',
      defaultMessage: '辍学',
    }),
    value: 'discontinued',
  },
  {
    label: formatMessage({
      id: 'apply-form:entrolment-status.option.intermission',
      defaultMessage: '休学',
    }),
    value: 'intermission',
  },
  {
    label: formatMessage({
      id: 'apply-form:entrolment-status.option.current',
      defaultMessage: '在读',
    }),
    value: 'current',
  },
  {
    label: formatMessage({
      id: 'apply-form:entrolment-status.option.graduated',
      defaultMessage: '已毕业',
    }),
    value: 'graduated',
  },
];

export const getEnrolmentStatusName = (
  value: string,
  formatMessage: FormatMessage,
) => {
  const options = getEnrolmentStatusOptions(formatMessage);

  return options.find((item) => item.value === value)?.label;
};

export const getDegreeOptions = (lang: string): Options =>
  DEGREE.map((item) => ({
    label: item[lang === 'zh-CN' ? 'name' : 'enName'],
    value: item.value,
  }));

export const getDegreeName = (value: string, lang: string) =>
  DEGREE.find((item) => item.value === value)?.[
    lang === 'zh-CN' ? 'name' : 'enName'
  ];
