/* eslint-disable no-redeclare */
import { ResizeObserver } from '@juggle/resize-observer';
import type { ResizeObserverEntry } from '@juggle/resize-observer';
import { usePersistFn, useUnmountedRef } from 'ahooks';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type MeasureRect = {
  left: number;
  top: number;
  width: number;
  height: number;
};

type MeasureTarget = Element | SVGElement;

const getDefaultRect = (): MeasureRect => ({
  left: 0,
  top: 0,
  width: 0,
  height: 0,
});

const getRect = (target: MeasureTarget) => {
  const rect = target.getBoundingClientRect();

  return {
    left: rect.left,
    top: rect.top,
    width: rect.width,
    height: rect.height,
  };
};

type UseMeasureOptions<T, D = unknown> = {
  ref?: React.RefObject<T>;
  format?: (target: T | undefined) => D;
};

type UseMeasureResult<T, D, M = (target: T) => void> = [T, D, M] & {
  setRef: React.RefCallback<T>;
  data: D;
  measure: M;
};

function useMeasure<T extends MeasureTarget = Element>(): UseMeasureResult<
  T,
  MeasureRect
>;
function useMeasure<
  T extends MeasureTarget = Element,
  D = MeasureRect
>(options: {
  ref?: React.RefObject<T>;
  format?: (target: T | undefined) => D;
}): UseMeasureResult<T, D>;
function useMeasure<T extends MeasureTarget>(options?: UseMeasureOptions<T>) {
  const observer = useRef<ResizeObserver | null>(null);
  const [data, setData] = useState<unknown>(() =>
    options?.format ? options.format(undefined) : getDefaultRect(),
  );
  const unmountedRef = useUnmountedRef();
  const [elem, setElem] = useState<T | null>(null);

  const ctrledRef = options?.ref;
  const format = options?.format;

  const measure = usePersistFn((target: T) => {
    if (!unmountedRef.current) {
      setData(isFunction(format) ? format(target) : getRect(target));
    }
  });

  const resizeCallback = useCallback(
    ([entry]: ResizeObserverEntry[]) => {
      measure(entry.target as T);
    },
    [measure],
  );

  const setRef = setElem;

  useEffect(() => {
    if (elem) {
      if (!observer.current) {
        observer.current = new ResizeObserver(resizeCallback);
      }

      const ob = observer.current;
      ob.observe(elem);

      return () => {
        ob.unobserve(elem);
      };
    }

    return undefined;
  }, [elem, resizeCallback]);

  useEffect(() => {
    if (ctrledRef) {
      setRef(ctrledRef.current);
    }
  }, [ctrledRef, setRef]);

  const result = [data, setRef, measure] as UseMeasureResult<T, unknown>;

  result.setRef = setRef;
  result.data = data;
  result.measure = measure;

  return result;
}

export default useMeasure;
