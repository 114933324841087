export default {
  '4235': '点击',
  '84543': '请尽量填写签证结束日期',
  '262591':
    '申请人可能需要到澳大利亚政府办公室进行面试。离申请人目前所在地最近的办事处是哪一个？',
  '68b': '天',
  '5pE': '今',
  '3R0U': '正文',
  '2OHB': '中国',
  '51N1': '设置',
  '4hgn': '短信',
  '3nCR': '封面',
  '3J7s': '来源',
  '2Vl2': '佣金',
  '3y2M': '折扣',
  '3Br4': '操作',
  '3IbN': '新闻',
  '3okw': '广告',
  '3tMA': '忽略',
  '59gR': '转移',
  '2UiX': '会计',
  '3n1T': '工号',
  '3GaG': '月份',
  '4UHm': '要求',
  '3Czc': '推荐',
  '1Iag6': '左对齐',
  '1xnsp': '右对齐',
  '2LRDj': '预结算',
  '1QwK7': '收款人',
  '1OrBX': '推广码',
  '1NqF1': '折扣码',
  '1JWY2': '已过期',
  '1Ij2E': '已忽略',
  '1yX4r': '发送中',
  '1OaT2': '批签率',
  '1SSYN': '案件数',
  '2KUtX': '预提现',
  Ir1gP: '上传图片',
  QglK8: '居中对齐',
  VPAFo: '文本预览',
  QmVoM: '审核失败',
  Qgk4j: '客户名字',
  QgYSO: '客户昵称',
  JygcY: '代理公司',
  Swpil: '已预结算',
  XB9Sb: '未预结算',
  JnAuJ: '付款备注',
  VIUkU: '收款账号',
  PFNiy: '失败原因',
  Qnbii: '审核成功',
  ViLrY: '推荐课程',
  QitAN: '客户邮箱',
  KDKM7: '分享编号',
  WAdt5: '最新备注',
  TUanq: '打开编号',
  TOiaI: '所属渠道',
  UBEEX: '推广专员',
  LRUzw: '发布专员',
  VGLtu: '数据来源',
  U8mpa: '折扣说明',
  PSVt3: '学历等级',
  WLk2C: '材料清单',
  V4WFy: '提现信息',
  WyUQH: '更新成功',
  LSOs2: '发布新闻',
  T19gp: '忽略时间',
  LSC5J: '发布成功',
  WJ2j8: '新闻标题',
  WJ4rz: '新闻概览',
  WIBzO: '新闻封面',
  WKyQD: '新闻附件',
  WJ5WR: '新闻正文',
  MaF2R: '取消发送',
  S1ikw: '当前天数',
  JUH4V: '使用方法',
  RbzeE: '工作时间',
  RbWI0: '工作状态',
  IYpwd: '主时间线',
  Iw8UX: '个人经历',
  VgyNl: '推荐专员',
  PTpwG: '学历要求',
  RcLaK: '工作要求',
  X3I92: '普通要求',
  TYwF7: '打折说明',
  QnfWy: '审核拒绝',
  UE9Xu: '推广链接',
  ITGgj: '中文别名',
  '115WFm': '添加变量',
  '118gow': '添加间距',
  '1puKYy': '预览内容',
  '129DJh': '澳大利亚',
  '1kynoL': '通知方式',
  '1nvPH0': '银行名称',
  '117PoK': '清单介绍',
  '1aa1rQ': '系统生成',
  '1hrCWV': '评估机构',
  '1fwCTx': '行业类型',
  '1hpEgv': '证书利润',
  '10z5pt': '欠费提醒',
  '1kadYA': '邮件类型',
  '1axcDl': '绑定步骤',
  '1ax0IL': '绑定时间',
  '16p46O': '目标学校',
  '12B4jR': '点击查看',
  '1axT4P': '给客户开',
  '1aiCVd': '给中介开',
  '1k8NNx': '邮件原文',
  fUcT5: '签证官信息',
  '1ihSHP': '账号密码',
  '1ikHef': '课程时间',
  '1il5HA': '课程状态',
  '1b7KFm': '职位名称',
  '1iAX4q': '语言要求',
  '117MwR': '添加要求',
  '166tYx': '申请步骤',
  '1ijYUc': '课程列表',
  '1hstFS': '评估职业',
  '1c3ca9': '翻译结果',
  '1i8ovH': '说点什么',
  '115OQa': '添加别名',
  '12KTkC': '添加分割线',
  '7VrhD': '客户详情链接',
  '5Tead': '编辑评估职业',
  '134pqa': '添加折扣码',
  '2tqL4w': '中文主名称',
  '1iY9BK': '缴费单通知',
  '9CHQO': '查看新闻详情',
  '2TnXe': '点击上传材料',
  Zc4SVA: '等待发送中',
  ZyqBrO: '案件进行中',
  IUK0w: '平均审理时长',
  ZpSeO: '编辑职业评估',
  Ztw7Yv: '请添加课程',
  ZauXgH: '请选择学校',
  ZLpjuG: '已付款已到期',
  Z1PUROj: '公司创始人',
  ZomprV: '客户其它文件',
  '1TKRqp': '跳过自动发送',
  Z2fbCaC: '下载申请表',
  '23q1rg': '返回案件详情',
  '2qgpn2': '添加课程清单',
  ZfYDwN: '新建永居规划',
  Z28qiu1: '预提现账单',
  '1ro3l9': '开始结束时间',
  '2o9Ecf': 'CoE 状态',
  Z1QeB0x: '佣金未开启',
  '2j3QNO': '添加职业评估',
  '16x9yH': '代理申请链接',
  '1dTr21': '审核付款信息',
  ZGYcdx: '个人付款信息',
  ZfbHDF: '证书原版价格',
  Z1T0bij: '预绑定步骤',
  ZJj77m: '协助中介公司',
  UiQu2: '{op} 操作',
  '2rqLAr': '职业评估名称',
  '2pn8uT': '添加评估机构',
  '1LR5IS': '审核课程清单',
  '2pnYdQ': '添加评估职业',
  Fb2xA: '相关的评估职业',
  '15MAFc': '发送邮件成功',
  Z1tjDII: '申请预提现',
  Z1SFoJ6: '生成推广码',
  Z1Hum9h: '设置条件渲染',
  Z1I3O4n: '设置文本颜色',
  Z28Lmub: '未付款已超期',
  ZDhzEy: '客户称谓+姓氏',
  Z2hKNq0: '案件详情链接',
  '1AmYYx': '推荐人可得佣金',
  '1YvbeL': '推荐人结算方式',
  Z1tchX2: '公司付款信息',
  '1aKiSL': '付款银行所在地',
  Z15hNn6: '公司第二名称',
  Z1gD2Cm: '课程中文名称',
  DmBJ8: '学校上级渠道邮件',
  Z1EVKib: '转移目标渠道',
  Z1gD4IL: '课程中文名字',
  Z1Yefem: '打包课程详情',
  Z1cN1xH: '快速创建案件',
  Z2vy2Ho: '官网注册链接',
  '1ua8dS': '公司名称/雇主',
  '1xaOSG': '个人经历时间线',
  ZJiwhX: '下载预提现账单',
  Z1rAc4D: '暂无提现账单',
  Z1npcIn: '公司备用名称',
  '1RJqDe': '案件完成度统计',
  '5hPdy': '设置公司付款信息',
  '1X32pT': 'PY 课程要求',
  Z1dCgmO: '数据来源网址',
  Z1lSsIw: '输入评估机构',
  DFGsF: '代理申请信息模板',
  ZfTreF: '发送链接到邮箱',
  ZAJQ4F: '获取邮箱验证码',
  '2eOSX2': '获取短信验证码',
  Z1mzsWx: '设置课程别名',
  '1P7kbv': '灰色文本 - 1',
  Z1eXTo0: '客户昵称+姓氏',
  ZSOh8r: '签证续签紧急提醒',
  ZWdvx8: 'CoE 积分状态',
  '1oEVM1': '签证到期临期提醒',
  '1OoQNW': '签证到期紧急提醒',
  Z1FBefl: '请设置付款信息',
  ZCgIJq: '请选择对应的校区',
  '22BMpO': '暂未设置付款信息',
  '1Horje': '数据有一定的延迟',
  ZsAk1g: '输入课程名字搜索',
  '2mTMVg': '请输入姓名的拼音',
  Z2s3KH0: '隐藏的 CoE',
  PM4La: '关联自定义表单文件',
  wIHKr: '修改 RPL 材料',
  '2r1hw4': '已选中的材料清单',
  wK1od: '修改 RPL 课程',
  ZvopvS: '使用哪个收款信息',
  '3AUIk': '发送申请邮件到学校',
  Z1gDymg: 'RPL课程打折',
  sp6UX: '天以内（今天以后）',
  sp3bM: '天以内（今天以前）',
  ZQlVIl: '额外要求英文描述',
  ZnpJSj: '推广链接复制成功',
  Z1jycan: '签证续签临期提醒',
  '1hP2To': '跳过自动发送给学校',
  ZOKPg9: '下载申请表 PDF',
  m6n6F: '选择或者输入银行名称',
  '1HWLxB': '是否注册了 GST',
  ZuBPsi: '显示专员的联系方式',
  ZFJPRr: '全部 RPL 课程',
  '2fOJ4y': '显示个人经历时间线',
  Z1xGMNb: '返回签证表单填写',
  ZkwbTl: '转移邮件到其它渠道',
  '1tBJYN': '上传 RPL 材料',
  ZH3KHV: '创建 RPL 课程',
  zjiTm: '预计可得案件积分奖励',
  '1vLpvQ': '最近{num}个月',
  AKv7j: '编辑 RPL 折扣码',
  EyTch: '指定的 RPL 课程',
  Z1bVz4a: '不能添加重复课程',
  Z1kpQdw: '额外要求中文描述',
  Z1XBq9U: '信息表单模板文件',
  '2oFOxc': '客户称谓+名字+姓氏',
  ZmQTIp: '最近案件处理专员姓氏',
  ZmR8bG: '最近案件处理专员名字',
  ZmQsmb: '最近案件处理专员昵称',
  '1TOqjT': '修改 CoE 推荐人',
  jvxz5: '转移 CoE 付款记录',
  Z1GsOgy: '确认进行预结算吗？',
  Z1dgWtl: '请重新设置付款信息',
  '2uz5G2': '请选择对应的课程校区',
  Z2alp8g: '银行 BSB 编号',
  ido3Y: '输入 5 位字母、数字',
  ZcRmY7: '输入上级渠道名字搜索',
  Z1krlJE: '添加 RPL 课程',
  Z164wPn: '隐藏个人经历时间线',
  ZI3rUN: '添加 RPL 折扣码',
  '1UdmDd': '生效的 RPL 课程',
  Z2edhSh: '将在官网对客户显示',
  Z2jktx0: '确认隐藏该记录吗？',
  Z138GHL: '签证距离过期剩余天数',
  '6wG': '开',
  Z235hS8: '客户最近案件处理专员',
  ZDCHMV: '生成 AI 助手推广码',
  mYwIQ: '确定显示该 CoE 吗？',
  Z4RWWW: '是否关联自定义表单文件',
  '5M9': '否',
  Z2dzAf8: '请点击您要选择的材料',
  ZcOO0n: '上传修改好的学校申请表',
  '191Iw4': '跳过发送申请邮件到学校',
  ZJuxB3: '开启了学生案件积分奖励',
  '2plFJ8': '可以选择不同的时间范围',
  '5AO': '关',
  '5EW': '副',
  Z1mmGYh: '替换学校申请表 PDF',
  '71P': '是',
  ZxUUnM: '搜索或者输入课程英文名字',
  ZU0EbL: '搜索或者输入课程学历等级',
  ZNm20i: '升学转学案件开学时间统计',
  Z1XDXQj: '确认申请积分预提现吗？',
  Z1GYmjl: '上传代理申请信息表模板',
  ZKvArN: '最近案件处理专员姓氏+名字',
  Z237ajq: '确定隐藏该 CoE 吗？',
  EGu8A: '与护照一致，请使用英文输入法',
  Z11YORX: '搜索或者输入课程中文名字',
  Zxpymi: '查看指定时间内案件完成情况',
  '6uo': '年',
  '75p': '有',
  '3TKQ': '没有',
  Z1F2USv: '推荐课程 {index}',
  Z1E1TD7: '选择转移目标 CoE 付款',
  '3tpD': '年龄',
  '5b5L': '运行',
  '5Ne': '周',
  '3DXh': '新增',
  KLp8C: '可以查看等待开学和已开学的案件',
  '30zW': '全部',
  ZXx1HY: '是否在官网显示自己的联系信息',
  '1RnGmK': '将选中的积分一次性申请预提现',
  if4: 'CoE',
  '4sYD': '签证',
  '57CK': '跳过',
  '3mM1': '已付',
  Z13X5ff: '确认将该专员设置为推荐人吗？',
  '5cdL': '通过',
  '3xev': '打开',
  '5jBO': '链接',
  '32xc': '名字',
  '2SBq': '保存',
  '4AUb': '编辑',
  '4rv6': '类型',
  '3KL6': '查看',
  '51KT': '账单',
  '3poD': '开启',
  '5pju': '隐藏',
  '34DB': '名称',
  '3tw6': '开通',
  '2XNX': '加入',
  '3Hoi': '显示',
  '54fv': '费用',
  bCPg: 'Unit',
  '35EI': '发送',
  '3qYA': '已读',
  '3GGX': '更多',
  '54hb': '路径',
  '1k2BAP': '确认将选中的积分发起预提现吗？',
  '3JlQ': '查找',
  '3Nmb': '标题',
  '32UG': '同意',
  '4Ip1': '草稿',
  '3jbr': '客戶',
  '5kTG': '附件',
  '5c4G': '邮箱',
  '3jA9': '审核',
  '3IPc': '案件',
  '58YX': '邮件',
  '1bLdYv': '使用',
  '3Y4m': '清空',
  vv0Ao: '姓名',
  '4ffc': '登录',
  '3Lm3': '标签',
  '2Tl3': '主题',
  '38o0': '回复',
  '4pGI': '称谓',
  Ec8dg: '确定该附件吗？忽略以后将不再通知。',
  '3wIT': '总额',
  '5fzh': '重试',
  zsIlk: '还未上传有效的护照，是否继续上传？',
  '9Cs': '读',
  '5Br': '写',
  Mqgjz: '周',
  '5eIe': '重置',
  Z19kFqJ: '我，',
  xyMXr: '高等教育',
  '3KDV': '未读',
  '2cde85': '案件',
  '3iYD': '完成',
  '1qqAv': '下一步',
  '3krV': '展开',
  '3AtL': '拒绝',
  '2W1Y': '其它',
  '3JmG': '普通',
  '4twB': '紧急',
  '3FSu': '撤销',
  '47fB': '状态',
  '3rI6': '性别',
  '585I': '退回',
  '3zht': '提交',
  '2VWX': '全局',
  '3INh': '校区',
  '4gov': '癌症',
  '3D7d': '搜索',
  '3TDA': '活动',
  '33kA': '取消',
  '51WV': '课程',
  '2XDn': '创建',
  '2NRO': '上传',
  bA5s: 'VEVO',
  '2XAG': '全球',
  '3Qvf': '正常',
  Z1BCxSw: '移民局90%案件审核需要的天数：',
  '5pXl': '韩文',
  '9Cl': '说',
  '1qozP': '下一个',
  Z210l3a: '查看指定时间内开学的升学转学案件',
  '3QIW': '每周',
  '32ut': '删除',
  '30md': '协助',
  Z1bOMh: '输入课程名字、Code 搜索并选择',
  ZONq9e: '类型',
  '5cNB': '邀请',
  ZW3e9l: '年',
  '3hyp': '奖金',
  '2askYi': '登录',
  MLysb: '发送失败',
  '4Gfl': '英文',
  Z1zMDuK: '双语',
  '3emm': '备注',
  '5tKg': '预览',
  '3FeO': '无效',
  '1R07K': '暂停中',
  '2RiX': '一般',
  '501F': '详情',
  '2VIt': '余额',
  '3F9l': '暂停',
  '3DGE': '收据',
  '5ok4': '院系',
  '3j5M': '学校',
  '3g0t': '姓氏',
  '2aablp': '如果父母信息缺失的话，可能会导致拒签',
  '38OU': '国家',
  '38W7': '地址',
  '3pWU': '当前',
  '3XRE': '流转',
  '2Wau': '内容',
  '2Vqf': '公司',
  '30IM': '克隆',
  '1uA9k5': '已拒绝',
  '1IgIa': '已归档',
  '3ETw': '日志',
  '46OM': '版本',
  '4j98': '确定',
  NP4x2: '垃圾邮件',
  '1PAwt': '收件箱',
  '5cFC': '选项',
  '1Jm16': '已绑定',
  '4wL2': '绑定',
  Z20N2LS: '可以拖拽课程卡片对打包课程进行排序',
  '3Dsb': '放弃',
  '3zD5': '提及',
  '2PHC': '中文',
  '35tJ': '可见',
  '5Mf': '听',
  '3S17': '每月',
  '3DBj': '支持',
  '3BB8': '押金',
  '1sC096': '职业教育',
  ZHjoM5: '客户',
  ZRg28F: '系统',
  '3GA4': '收起',
  '1Jho3': '已签署',
  '1Iv8y': '已撤销',
  '3zaT': '护照',
  '1HPgF': '已关闭',
  NBWfs: '子女关系',
  '3jbs': '客户',
  '4zns': '继续',
  '1I1Or': '已复制',
  '52Ug': '语言',
  '5c0U': '送达',
  '30E7': '分组',
  '4lGA': '积分',
  '1JeoV': '已签名',
  ZjJWFl: '使用中',
  '4z51': '职位',
  '2uK0Md': '写',
  '4ziY': '职务',
  ZVoIQt: '性质',
  ZaYcJd: '生效',
  '2Sjr': '下载',
  '1wGnL': '发件人',
  '1wJtt': '发件箱',
  '4ofW': '税率',
  Z1bb5FC: '点击按钮，可以快速创建其他对应的案件',
  '3j1y': '学期',
  '3HmH': '昵称',
  '54Hm': '身份',
  Z1xwyiX: '邮箱',
  '4okR': '等于',
  '2d8C0': '积分数',
  '3kYc': '密码',
  XDLff: '公司',
  ZAEWNP: '数量',
  '4cwN': '生成',
  ZgPgaA: '积分',
  '2gssDk': '绑定',
  '1JXXv': '已逾期',
  '1PGiu': '推荐人',
  '6aX': '姓',
  '5LJ': '名',
  '1JfJF': '已移除',
  '1Ir8v': '已挂起',
  '311B': '关闭',
  '3HHK': '有效',
  '33l2': '员工',
  '3Do6': '新值',
  '3DQ3': '旧值',
  '5fpv': '重要',
  Z1RHND4: '付款信息审核中，如有需要请联系管理人员',
  Z1u8xAP: '读',
  LFW6t: '原因选择',
  '3s1J': '怀孕',
  '1voq5': '创建人',
  Z2weWbH: '公司名称 / Trading Name',
  '1wAv4': '关键字',
  '1HMSr': '已创建',
  Iv1z3: '中介公司',
  '1G2LX': '审核人',
  '3o8l': '应付',
  TNAyp: '所属客户',
  '3CWU': '文件',
  '1vdmH': '创始人',
  '4orm': '离职',
  '2DACc': '邀请人',
  '3rt6': '归档',
  '4p9R': '签名',
  ZfeRUt: '过期',
  '16v773': '地址',
  ZxpDVq: '国家',
  '1TlvL': '未绑定',
  '1z1CI': '发送至',
  '1jIXoO': '公司主名称 / Trading Name',
  '1xAatf': '在此可以切换公司，查看协助公相关统计信息',
  Z1xrJgU: '该文件已经绑定到步骤了，是否继续绑定？',
  '3Lz0': '权限',
  '2SN6': '不限',
  '23AT8o': '手机号',
  '3iJl': '小于',
  '3czn': '复制',
  PRYgI: '学号前缀',
  '1IpIP': '已拒绝',
  '3BOW': '提现',
  '1I8eH': '已完成',
  '5ydN': '验证',
  '1P7aq': '操作人',
  MLNlG: '发送成功',
  '1OCk4': '接收人',
  '1rHo4': '优先级',
  Z1uB1VE: '说',
  '1laC4y': '重新打开',
  '4yYY': '职业',
  TNdNc: '所属公司',
  '3BFK': '换绑',
  '1I8Pd': '已审核',
  Z2rS0zz: '输入课程或 VET Code 关键字搜索',
  '1hGu7h': '解除绑定',
  '23ql91': '职位',
  '56DH': '转发',
  Z28uRvW: '电子邮件',
  Z15XAjp: '网站',
  '1Sm7E': '新邮件',
  '1qok5': '上一个',
  Z2jv2kp: '去更新',
  '4yYi': '结算',
  QoQGy: '审核通过',
  '28yTJ': '生效中',
  '2tbde': '被取消',
  '1JmPv': '已结束',
  '1j6tqf': '父母关系',
  '3elI': '姓名',
  '1aJXwr': '结束日期',
  Z2oL9xX: '职位',
  '2RadX': '黑名单',
  '1PxqL': '收件人',
  '9LnA': '1 天前',
  '2SCL': '代称',
  J1VVw: '任务日志',
  ZFwSnO: '相关课程',
  Z1QtPJY: '听',
  L9XIO: '到期时间',
  '1SRMP': '案件名',
  '2a0BN': '申请人',
  '1vHh8x': '问题',
  '3bHj': '在职',
  ZCchHS: '邀请成功',
  '2sUMm': '表单项',
  '1SgdP': '未归档',
  '1PSIc': '文件名',
  VKx1k: '新建步骤',
  '1wKfj': '去协助',
  '2CIiX': '运行中',
  '1wIwt': '去创建',
  '3b4G': '国籍',
  ZoIoE7: '澳大利亚商业编号',
  '1JdGq': '已离职',
  XnQzt: '查看全部',
  '1qFQn': '不可见',
  '1qqzo': '下一次',
  '1hrmgX': '已接收',
  '3qDE': '归属',
  '3lDs': '学费',
  '1xyki': '变更为',
  JUC9s: '配偶的住址',
  '4CyO': '翻译',
  Z1wafpY: '图书馆',
  WQDFr: '推荐人',
  XoxGu: '查看更多',
  '1JoQt': '待付款',
  '1SewU': '未开始',
  '2CL9g': '进行中',
  '1XFkVU': '夫妻关系',
  Z1jxgok: '离异关系',
  '1jwcFs': '同居关系',
  WStcE: '案件名称',
  '3dGU': '声明',
  WTQ49: '案件类型',
  '1aK1SC': '结束时间',
  RY1Ny: '开始时间',
  '1MmzI': '总费用',
  RXXrn: '开始日期',
  '537A': '订阅',
  '9Bf7': '1 周前',
  '2Oji': '专员',
  '1VV3m': '模板：',
  Z1KrW1I: '其它疾病？',
  OAuWa: '复制链接',
  Z2d53vU: '签名',
  '29pws': '百分比',
  L17WK: '加入时间',
  Z5g7Tm: '邀请已发送',
  WUgd0: '案件表单',
  '117qTw': '添加职位',
  VojHk: '文件类型',
  Vn5F9: '文件大小',
  '3SRK': '注册',
  J2Ix6: '任务类型',
  '2xD68': '请添加',
  Jhjkp: '下级中介',
  '2evhg': '离职中',
  '2SKY': '介绍',
  '2WLO': '全校',
  '1k9ro1': '邮件日志',
  '117kO9': '添加签证',
  '116Dby': '添加标签',
  WSq1g: '案件列表',
  XozOI: '查看案件',
  TUWbc: '打开链接',
  JF2MJ: '保存数据',
  '12vB9S': '澳洲境外',
  '3cxf': '大于',
  '1qgK2H': '已完成',
  Zo1RqE: '账单创建人',
  '3xIX': '手机',
  VDrI1: '新增案件',
  Z22o9jO: '手机号登录',
  '1I3qL': '已失效',
  '2KkRV': '预发布',
  WSCUp: '案件备注',
  LzT96: '关闭案件',
  '4cMS': '生日',
  '3AwY': '挂科',
  '1ihGLa': '设置奖金',
  '3GCD': '暂无',
  QgkfQ: '家庭电话',
  '3UwV': '母语',
  Rcscx: '工作背景',
  '4Q3L': '行业',
  '9LuW': '1 天后',
  '1bAvci': '编辑任务',
  J16XG: '任务事件',
  MNqw9: '发送邮件',
  '221W9i': '如果需要修改付款信息请在下方重新填写你的付款信息',
  OcBqk: '填写学费',
  '1nzbYI': '教育背景',
  Zs5qa0: '签证类型',
  Z1D4TGP: '职业',
  '1k6yWY': '教育背景',
  '11Kdw1': '测试类型',
  UIxB8: '接收专员',
  S7Sqm: '待办事项',
  '1RMo7': '未创建',
  KExzS: '分享链接',
  '4wO3': '编号',
  '1TnJ2': '未结算',
  WTcUe: '案件标题',
  WTkeS: '案件步骤',
  XhWXn: '标注文件',
  '1wk5R': '协助者',
  '1Q0KHn': '获取验证码',
  LaBYr: '入职时间',
  XLaAT: '查询时间',
  '19xMLC': '签证列表',
  '1fFXSP': '表单类型',
  VnDyh: '文件标签',
  '3iqb': '学年',
  '24Fngz': '获取验证码',
  ZS3w0g: '关联',
  QgWqD: '客户日志',
  '19zCXm': '签证表单',
  '1bU7CU': '职称配置',
  '1CR9Gm': '可以分别查看案件推荐专员和案件处理专员的完成统计',
  Trohj: '快速工具',
  '1t15W6': '该邮件已存在系统，请再次确认是否需要归档到案件下',
  '1jNGb2': '递交案件',
  MaHNI: '员工列表',
  KEIwM: '分享页面',
  VGNV2: '数据权限',
  heJKy: '请输入邮箱',
  Wz4VI: '更新日志',
  '2Rwv': '付款',
  S0HAj: '开学日期',
  '1kwvbr': '配偶的名',
  Zo2yEp: '打印名称',
  Z73XXi: '多媒体',
  RnPsy: '帮助中心',
  '1KYsW': '待签署',
  JGWFz: '中途转学',
  '1G2JE': '审核中',
  WSBLx: '案件奖励',
  JVnkP: '使用积分',
  '1ilw3q': '课程结束',
  Qj9hh: '定时任务',
  Z2dGhf7: '中文',
  PSVkq: '学历类型',
  QeSNN: '学校名称',
  KvCqe: '入学时间',
  QeQHo: '学校名字',
  '3hHa': '学号',
  '3Uiu': '涨价',
  Wz8Ns: '更新时间',
  KPWpH: '公民身份',
  '30uw': '分类',
  IrtPC: '上传文件',
  '160Xbj': '登录时间',
  '16xafW': '短信状态',
  '9Bmt': '1 周后',
  Qnof3: '审核时间',
  '2eRo1': '签名中',
  '132K1T': '工作背景',
  '117SMr': '添加课程',
  '2oa7eV': 'CoE 编码',
  U2oLF: '截止时间',
  '38Fe': '地区',
  '2yNEs': '请输入',
  WTvNE: '案件状态',
  IVTVC: '上次更新',
  QgmaI: '客户名称',
  Qh3Sa: '客户标签',
  '1MyOa': '总金额',
  '3hO8': '实付',
  '1bcqdu': '继续创建',
  '51Ny': '账号',
  OlJ9Y: '基本步骤',
  '10W6J4': '步骤名称',
  '3Mtn': '标识',
  '1uh7m': '公司名',
  '1FvgzA': '注册',
  KeaB5: '停止时间',
  IrzDX: '上传时间',
  Z1xQlpF: '传真号',
  '4PSGI': '申请中介',
  '1rmW3': '中文名',
  '2nyzp': '英文名',
  VmG53: '文件上传',
  '2uD4bn': 'GTE 表单',
  '1YA8Yy': '956 表单',
  QgSpH: '学校费用',
  ZJhB4: '默认抄送人',
  '10OD9P': '款项名称',
  '1O86S': '手续费',
  TUBIe: '打开设备',
  '116uMq': '添加文件',
  '1PU5k': '文件柜',
  '1k9xVY': '重新关联',
  '1k8MVH': '邮件列表',
  WAHVU: '最新案件',
  '19yv9B': '签证案件',
  JUpvV: '使用年份',
  OELfM: '基础等级',
  '1hiyc8': '角色等级',
  KYXXa: '创建链接',
  KxY5r: '其它案件',
  '1bAypl': '编辑主题',
  '1kwvzF': '配偶的姓',
  '1YE6BA': '965 表格',
  '1IQOiQ': '合作商',
  Z1EY6IN: '您是一个',
  rPSyN: '研讨室',
  M7ofB: '加载更多',
  Z1Mle5Y: '通知',
  '1qqkK': '上一步',
  '2o8Ov3': 'CoE 奖金',
  '2052Oj': '案件失败',
  '1uRNr': '冷客户',
  WSpzE: '案件关闭',
  TRkp8: '折叠菜单',
  LzT5a: '关闭时间',
  '27UO12': '生日',
  '1Skk9': '有效期',
  V6Gwy: '挂起案件',
  '1ik36A': '课程名称',
  Jo2xs: '付款日期',
  '1iW1l3': '费用详情',
  '1k5jOz': '过期日期',
  uyT78: '城镇/城市',
  Vn19l: '文件地址',
  '3v80E': '28 天前',
  '1sPM9': '不重要',
  LcucG: '关联案件',
  LyFRf: '参与人员',
  '116AAL': '添加时间',
  '1kabUy': '选择类型',
  '1LE1N': '总积分',
  '17EJz8': '积分使用',
  W8j3L: '撤销审核',
  '2yPPV': '请选择',
  '1ilvh2': '课程编号',
  '1wmci': '全选否',
  '1ijQWD': '课程代码',
  '1J7Hjw': '学历',
  '1LqGh': '心脏病',
  '1kyvaU': '邮政编码',
  '9DGWS': '加载中...',
  Z1Q5k09: '排队日期',
  '1hW1b3': '说明原因',
  '117SD4': '添加账号',
  '3cNj': '头像',
  '3THW': '派单',
  NBVoV: '固定金额',
  ITMSg: '中文名称',
  '1Se9n': '未开启',
  ITKLQ: '中文名字',
  VQJqM: '是否通过',
  LSP2u: '发布时间',
  ZyOa9w: '邮箱登录',
  '1T1v3': '未生效',
  '1k9nnn': '选择文件',
  '19ySz1': '签证状态',
  Z2i25U8: 'PDF 文件',
  S2xbR: '当前签证',
  XLTOh: '查询签证',
  MM1ir: '发送时间',
  hfJWD: '公司名称',
  '16pFGR': '目标步骤',
  Lvug2: '克隆步骤',
  QsVGw: '学生名字',
  '1t3w1l': '默认费用',
  WMrU3: '材料费用',
  VOC7m: '是否含税',
  '1k5nbK': '过期时间',
  MaHL9: '取消回复',
  TSLvu: '打开地址',
  TGhqt: '打包文件',
  QUt9d: '学费类型',
  Zwmyis: '添加 OSHC',
  Z1K0dWl: '签证结束日期',
  KnN1v: '公司名字',
  WxNih: '更改信息',
  '1sH1l': '不转发',
  '1Kr75': '总均分',
  '16p4cu': '目标客户',
  WUozI: '案件详情',
  Koujv: '公司案件',
  XnMpL: '查看公司',
  '1T7SR': '未确定',
  '116aUi': '添加学校',
  '1k9tfE': '选择案件',
  QgiYk: '客户列表',
  '1bB2VY': '编辑年份',
  '7YCJb': '模板 ID',
  '1reXW': '不支持',
  '12wCw1': '学年',
  '1im2Hu': '课程费用',
  Ir0qz: '一些课程',
  JUxSe: '使用手册',
  '1jNCJU': '递交日期',
  Z2ttbaO: '请签名',
  '2iJpqa': '个体经营',
  '10aRbm': '电视和视频',
  '1DV0Qo': '添加员工',
  '1k98i9': '选择年份',
  '2al68G': '签证拒签',
  '1kaTzd': '邮件退回',
  '1k9dt5': '邮件已读',
  '1M5xL': '待验证',
  '1xdrx': '发布中',
  '1ikofa': '课程开始',
  JXx5f: '电子邮件',
  '1inWz7': '详细说明',
  KXo7B: '创建时间',
  '1fGwpp': '表单详情',
  SIFYG: '恢复案件',
  '19ymz2': '签证拒签',
  Z1FF8nc: '学生资料清单',
  '1dFebi': '英语测试',
  '2oa4KG': 'CoE 编号',
  '1ax0MH': '绑定案件',
  '19Tbj0': '精确学费',
  Li0DX: '出生日期',
  LX14M: '删除文件',
  KWXwN: '创建客户',
  KXobx: '创建案件',
  SqbwV: '常规邮件',
  XoMe4: '查看消息',
  '1BOFAm': '自定义时间',
  KWvtl: '创建任务',
  '115AcS': '消息任务',
  '1K7yW': '待执行',
  UKRhN: '接收邮件',
  '1ii8Y1': '设置时间',
  '116b0X': '添加客户',
  QnLIo: '审核状态',
  '1k9Dup': '邮件正文',
  '1ikcOl': '课程备注',
  '1wnqY': '全选是',
  '16K5sC': '学历',
  '2o7MpR': '测试名称',
  ZzPVTc: '测试日期',
  Z3c6hF: '犯罪类型',
  '14uS24': '犯罪类型',
  '11Jrq0': '测试日期',
  '1jsXLr': '资金类型',
  '19xMdX': '签证历史',
  TJtTK: '所在行业',
  '1bCE5X': '编辑详情',
  wuKdU: '{day} 天',
  KQGhR: '剩余时间',
  jGwog: '进一步停留',
  U1yJN: '手机号码',
  '1b8pOi': '组织机构',
  WUwRC: '案件进度',
  Qabbm: '导入客户',
  '25yzL': '版本号',
  QoEus: '审核详情',
  UYyix: '操作日志',
  WL1Cs: '材料文件',
  WSp3z: '案件分类',
  LX6S8: '删除时间',
  '1l7TPV': '邀请员工',
  '1cXHnr': '英文名称',
  '1BPNt3': '自定义表单',
  '115Rk2': '添加分类',
  '1cXFh2': '英文名字',
  '2lxrM': '联系人',
  '3l6v': '官网',
  '170EF0': '确定加入',
  '1w2HF': '出生地',
  PSm1G: '公司名称 (雇主)',
  '1wzRk': '写邮件',
  XKrJc: '查询历史',
  UoecG: '护照列表',
  CNaSQ: '公司印章',
  '19kiBF': '签约时间',
  XnRYz: '查看合同',
  Yl9hu: '模板名称',
  '1k9F6i': '选择步骤',
  QgvRP: '客户姓名',
  VDDyE: '新增步骤',
  '1fGJ4m': '表单选项',
  Lf0IM: '内置表单',
  '1gGBJj': '补课费用',
  '2xYBk': '账单号',
  '1igSlG': '账单列表',
  UR1ub: '抄送人员',
  XotWr: '查看文件',
  '1ikfPz': '课程学位',
  '1iVyME': '费用缺失',
  TPuHC: '房屋面积',
  TNdpm: '房屋地址',
  '116r0p': '添加护照',
  '2y4iP': '请确认',
  pBJHz: 'Tips 标题',
  LXQ6v: '删除签证',
  '11JuMb': '测试时间',
  '1b6Rns': '结算方式',
  LqWwJ: '分类名称',
  Ymw8Y: '模板类型',
  PipcM: '进行中案件',
  Xpuwn: '查看自己',
  WSEg2: '案件大类',
  K0pHD: '绑定到案件',
  WSg8f: '案件交接',
  '115Uax': '添加员工',
  '1jKXWb': '辅导费用',
  Z29J8ny: '请输入手机号',
  '115Kx6': '消息列表',
  '11IQFD': '测试发送',
  LoHGi: '协助动作',
  '16lkvH': '直接打开',
  M1k8c: '合并成功',
  WTjh0: '案件派单',
  MaISH: '员工名字',
  '1laObT': '重新查找',
  RcPxr: '工作证明',
  ZXeiLM: '加载更多中',
  KWALA: '创建公司',
  '1bsD88': '审核不通过',
  '2o8pt9': 'CoE 付款',
  Z1EiSOH: '登录失败',
  WSUny: '案件成功',
  ZzkVsz: '案件已挂起',
  ZzxYdj: '案件创建中',
  '1Bfzkd': '已付完学费',
  WSoUg: '案件协助',
  ZzxYb0: '案件创建人',
  Zv7Nj: '尊敬的用户您好，',
  MbRJD: '升级系统',
  WBoHL: '有效日期',
  ZRCb05: '护照过期日期',
  '12Cveo': '点击选项',
  '1FOMJ': '学杂费',
  JoxDE: '付款理由',
  JplBz: '付款记录',
  KlzqH: '其他费用',
  QmSP9: '审核备注',
  '2o8SA6': 'CoE 学费',
  '18yOnN': '签发日期',
  LhuVT: '出生地点',
  Zcg4cP: '请详细说明',
  Rc63N: '工作电话',
  MbVAH: '员工登录',
  '1bYrhS': '联系邮箱',
  '1ii6sB': '账号手机',
  '1k8FOx': '邮件主题',
  '1kaLva': '邮件详情',
  '3v881': '28 天后',
  ZfmfsS: '一般情况下，默认是邮件通知方式，除非特别申明要用短信通知',
  '1oHF9u': '额外积分',
  LpsM0: '协助案件',
  '1kVoNM': '邮箱地址',
  '1kbgeK': '邮件预览',
  THFqu: '打包课程',
  '1ikHgg': '课程校区',
  X3OoB: '普通课程',
  '17NeRE': '行业类型',
  '1b98wQ': '职位类型',
  '1ntVtE': '雇主名称',
  RjCQD: '就读学校',
  ZScNIK: '主要语言',
  Zglgz2: '地区/国家',
  '1hQK3m': '证明类型',
  POkLf: '学习领域',
  Z1OPSar: '学生签证',
  ABEdW: '郊区/城镇',
  MuXzP: '商务电话',
  S2brP: '当前状态',
  ZnbMgh: '新建待办事项',
  WymcG: '更新内容',
  '12CHRg': '点击隐藏',
  '12B0W4': '点击显示',
  '162k9j': '相关案件',
  JotnY: '付款状态',
  VQ7cj: '是否结算',
  '1W5Q99': '链接已失效',
  UYCah: '操作时间',
  OlAzV: '在职状态',
  '2Xoa': '分工',
  Sc9UC: '归属客户',
  '1hpMOS': '设为有效',
  U4wEH: '打标状态',
  '1ug6x': '佣金比',
  LTdvP: '发布状态',
  MMdKI: '发送消息',
  Z1D0ggb: '手机登录',
  '1NXjk': '护照号',
  '19yphk': '签证文件',
  '1jFFdd': '转学日期',
  '1jFIzo': '转学时间',
  Xpg9o: '查看签名',
  UJs46: '接收时间',
  ZrUdnl: '联系人姓名',
  Z1bDMrV: '电话号码',
  '1bBzK8': '编辑步骤',
  '116tgz': '添加操作',
  V0kT7: '操作类型',
  '167Cyd': '申请费用',
  '15jfyd': '生成时间',
  Kvz43: '入学日期',
  '4WMl': '解约',
  '1bW4mp': '联系中介',
  KYG7f: '创建账单',
  JoCVv: '付款目标',
  QhdKE: '客户消息',
  '2uB91c': 'GTE 写作',
  VpMzx: '搜索成员',
  VnFyp: '文件标题',
  '1jzdN5': '输入内容',
  Z22Le2p: '签证开始日期',
  '1qC8w': '中介端',
  Z1eGbCB: '编辑 Tips',
  Qh467: '客户权限',
  '2jmOHj': '本次付款中，{name} 预计可得佣金为 {value}',
  ZVa2c3: '定位中...',
  LQoN6: '功能指南',
  '1WFhy': '每两周',
  QgdY1: '学校简介',
  '1ilpX5': '课程类型',
  '2AIYu': '转学分',
  '1aymIE': '绑定路由',
  UpEoc: '护照等级',
  '11JAts': '测试模板',
  '1jNBOI': '递交方式',
  '1PJ8I': '推荐码',
  Tj6ij: '尊敬的客户',
  '1DILor': '已关闭案件',
  VqOff: '搜索结果',
  WAiib: '最新客户',
  '12SBBs': '添加大分类',
  YVaNc: '每周学费',
  rBa7n: '客户丢失',
  Z1uKHMC: '其它原因',
  '1kaXup': '邮件送达',
  RXiov: '开始协助',
  c7w3G: '恋爱关系',
  KlPPW: '公共模板',
  WSHxG: '案件完成',
  '1kmN1x': '邮寄地址',
  QdX5F: '完成时间',
  UpJyw: '护照编号',
  RllIM: '就读课程',
  '2o9aTy': 'CoE 文件',
  KzjuK: '其它费用',
  Jo0P2: '付款收据',
  PRXzN: '奖金金额',
  KnjgB: '佣金比率',
  KWGlo: '创建合同',
  '1MCIR': '所在地',
  IAYuz: '所在地身份状态',
  '1EREi': '州/省',
  KxOq9: '其它护照',
  '19q0Vj': '语言',
  WwahO: '旅行历史',
  '16w0aP': '短信内容',
  KxSda: '其它文件',
  '116xr4': '消息标题',
  Loy61: '协助中介',
  '17GOYO': '积分详情',
  '17ELGC': '积分余额',
  V75rD: '提现账单',
  '1k9zPF': '邮件标题',
  '1ikk6r': '课程学费',
  WxFzn: '旅行经历',
  '1g46nn': '血液疾病',
  '19V5QP': '精神疾病',
  '2kjKSu': '通常是你想要给客户展示特定的公司名称，例如贵公司的中文名称',
  '1bWMWa': '联系手机',
  Kplsw: '公司网站',
  KqOPL: '供资详情',
  pCVn4: 'Tips 详情',
  Kl9Oz: '偏爱语言',
  '12B08P': '点击收起',
  UXVJ3: '操作员工',
  '17ER3l': '积分功能',
  VO8zA: '文案专员',
  '1fECeA': '表单变量',
  KnYkN: '佣金结算',
  UrlNX: '提交类型',
  '1hpKlW': '设为无效',
  '18z8fe': '签名时间',
  P8ElP: '处理区域',
  '15mJmA': '生效时间',
  thkh8: '预计剩余时间',
  JqLEF: '你已加入',
  Tk4a2: '忘记密码',
  '1u1OC3': '邮箱验证码登录',
  '1iVYPY': '费用评估',
  Olrr4: '基本文件',
  ZCVwMa: '发送到邮箱',
  XKWE9: '查询成功',
  XLYr5: '查询结果',
  V5ngS: '提现审核',
  LQ3Aj: '合同编号',
  '1lbkpR': '重新申请',
  '1abwio': '系统默认',
  KmqNI: '佣金列表',
  '1ikuRd': '课程打包',
  WUr1u: '案件讨论',
  '1a9K9A': '系统消息',
  Z1srSB9: '学生医疗保险费用',
  QdX9B: '完成案件',
  JB9r2: '下载简历',
  Vp1c4: '文件选择',
  '1ikA47': '课程押金',
  ZOTDwN: '权利所有人',
  UqirU: '持有牌照',
  '1ihxgB': '账号关联',
  pASvS: 'Tips 内容',
  TSpRt: '报告文件',
  VPX3H: '文档编号',
  N2ICF: '各项成绩',
  LPD1R: '合同状态',
  '19mK94': '签署链接',
  SgpFv: '学习与工作',
  Qgjwa: '客户合同',
  LyK3F: '副申请人',
  '1KeFf': '总佣金',
  Jo1Cg: '付款方式',
  ZOMoRM: 'Offer 列表',
  '19jRVc': '签约学校',
  '2bF80': '看自己',
  '15o2yg': '生效课程',
  Jpma3: '付款详情',
  M17u9: '合并客户',
  UYO4Q: '操作步骤',
  '1R99gF': '配偶的签证状态',
  Tx9xz: '成功案件',
  '1NfyLT': '已挂起案件',
  gp2JX: '签证申请人',
  '1ij8nq': '账单金额',
  Z1MSuSr: '个人签名',
  '19iUaw': '联系电话',
  XpQ1c: '查看详情',
  Utff7: '插入变量',
  '11J4FR': '活跃客户',
  VX3Ea: '无效客户',
  LSpfR: '发布完成',
  Uo6Ji: '护照信息',
  WSixR: '案件信息',
  '1bWTH5': '联系方式',
  JimXA: '上级渠道',
  PN1G2: '学习状态',
  '1qGhaj': '验证身份',
  Z1WWJHh: '学校 Code',
  Z1dvXaC: '课程 Code',
  '1KDXF': '总学费',
  KnQQB: '佣金类型',
  QoGeG: '审核账单',
  Zav6hN: '请选择国籍',
  UqcL9: '护照详情',
  '1NeCm': '手机号',
  WJDY7: '机构名称',
  Z1wLicl: '登录浏览器',
  Z1gMQdN: '短信接收人',
  UFhYf: '执行失败',
  '115Gfn': '消息内容',
  '1ikHck': '课程时长',
  '12wSfq': '澳洲签证',
  '139dPL': '添加新课程',
  oRMxf: '打包课程',
  S0Y5U: '当前位置',
  Whhmh: '当前位置',
  Z23yhNM: '申请ID',
  '1bCG2e': '编辑课程',
  Z1RiFb1: '原因',
  '166eHM': '申请日期',
  WbbDa: '教育类型',
  Zf4H4S: '国家/地区',
  '14u6V3': '犯罪日期',
  '1Iise': '州或省',
  '2k1cTB': '公司 LOGO',
  '1bWoaj': '联系地址',
  '137v4i': '添加新客户',
  McImY: '取消选择',
  '18y5oX': '签发公司',
  Zkk22O: '付款凭证上传日期',
  JpurW: '付款进度',
  '1l8Aha': '邀请时间',
  '1b9DVo': '职位角色',
  '1xgBT': '原文件',
  '1neTOL': '附件类型',
  '19kzDb': '签约渠道',
  '12RJrm': '点我协助',
  '1d2ncn': '自行提交',
  '1O8Iz': '批签号',
  '118cJc': '添加附件',
  f5Mr: '重置所有 CoE',
  '116gh3': '添加已有',
  '1gyAMq': 'VEVO 查询',
  KRjdc: '剩余积分',
  '1oIIHT': '额外问题',
  oD7os: '材料清单',
  Yn4Ey: '模板详情',
  '1387yF': '添加新步骤',
  VFVlQ: '数据信息',
  '1jbheX': '编辑表单项',
  Z1cyCKP: '新增表单项',
  '1jMLu3': '递交中介',
  KngMA: '佣金比例',
  '1kRQh1': '重发邮件',
  '10W69K': '步骤可用',
  '10Wl56': '步骤完成',
  '1jzb53': '辅助工具',
  LXUSI: '医疗保险',
  TN6g3: '所属专业',
  ZrCYTH: '使用该 CoE',
  Iw1xF: '个人简介',
  OmPha: '基本设置',
  pAVPU: 'Tips 分类',
  '1k8JTY': '选择分类',
  '1omcg5': '隐藏客户',
  VnKJM: '文件汇总',
  '1ilRUu': '课程规划',
  Vz3X5: '收据文件',
  QhHbz: '客户签字',
  Yl1Nn: '模板内容',
  '1jOd9g': '退出协助',
  Mjuri: '因系统升级需要，为了不影响你的积分提现，请尽快更新你的付款信息',
  UrzHc: '提交范围',
  VeqLn: '收件邮箱',
  '1k8MKu': '选择员工',
  '2Owgw': '验证码',
  '1U4sU': '联系中介公司',
  '15jfTp': '生成来源',
  '2a2b6': '看全部',
  '1im5RF': '课程辅导',
  Zt8wfh: '上传失败！',
  S3aUY: '常用签证',
  KYG9T: '创建账号',
  '1hrNv5': '证件编号',
  '1b6VEP': '结算时间',
  TO1d9: '所属案件',
  '1oTawC': '添加推荐人',
  '1ob19a': '隐私邮箱',
  Kpkyk: '公司职位',
  QiKYw: '没有更多了',
  '1k8FPH': '选择公司',
  '1JPU7': '待审核',
  '2o8thF': 'CoE 佣金',
  '1wyUKn': '客户改变主意',
  V9up7: '绑定成功',
  ZHk9kN: '澳洲 Offer',
  '1jQ75e': '兄弟姐妹关系',
  '19xFje': '签证信息',
  '19zdOv': '签证类型',
  KnP7U: '公司名称',
  Z1CvLE: '查看全部菜单',
  Z148FqQ: '护照签发日期',
  '1ke9xJ': '转移渠道',
  '1k8yIK': '选择专员',
  '10UG88': '海外地址',
  '1ilq6H': '课程等级',
  '2Idrg': '查看奖金列表',
  '1JgDvy': '当前位置',
  '1hrIc8': '证件类型',
  QelRM: '就业状况',
  '1b9G3q': '职位详情',
  '1l8aGm': '邀请客户',
  UoPHo: '护照文件',
  WSdRl: '案件专员',
  ZlDJMD: '课程结束日',
  '116yxq': '消息模板',
  '1gweGx': '触发语言',
  '17Fxlk': '积分明细',
  '166i4X': '申请时间',
  '166U0i': '申请积分',
  '18yFoX': '签名失败',
  '1kb7ob': '邮件附件',
  '1kaLno': '选择课程',
  '1im7rO': '课程选择',
  ZmbTfq: '课程总时长',
  OkHsX: '基本信息',
  ORABt: '生成 Offer',
  '15j2Bs': '生成成功',
  '1lizDK': '健康保险',
  Wbgkb: '教育经历',
  Z2fzr4A: '所在地身份状态',
  UClwm: '技能评估',
  '10RXoi': '语言能力',
  '1Lsixm': '参考号码',
  '1rnYXX': '开始结束日期',
  Z1TdGnX: '犯罪日期',
  PLIjw: '学习专业',
  W9K2C: '教育历史',
  '1jtwlo': '资金证明',
  '1RdC2V': '真正的学生',
  WHEdO: '未来就业',
  '2zXIm': '负责人',
  '1kvQj1': '逗留目的',
  Z13Yclf: '必须是公司英文名称，如果贵公司名字是中文名称，可以转换成拼音',
  KopEx: '公司时区',
  VOvQo: '是否创建',
  '2izifr': '基本信息',
  '1kd5bd': '转移公司',
  '1igSXF': '账单功能',
  '1jPrjV': '追加步骤',
  '1hpsBs': '设为市场',
  LP9Jb: '合同文件',
  Lqinj: '协助经理',
  '160Key': '登录成功',
  Z1tT5wp: '申请的课程',
  '163VdN': '相关附件',
  VoU7u: '文件语言',
  Z2bY9dR: '打包该课程',
  Z1LpwU8: '医疗保险文件',
  UvOwo: '我要查询',
  '1k8yyF': '选择中介',
  JzLip: '下载合同',
  '19kswi': '签署合同',
  X1PLH: '普通员工',
  '19jnYC': '签约中介',
  '1iiToF': '账单货币',
  WjDnb: '收费款项',
  VYSsx: '无效选择',
  TGai4: '打包成功',
  JAngh: '下载文件',
  KHlfz: '保险公司',
  '1t9q9Y': '联系二维码',
  LWWhL: '删除护照',
  '166i8T': '申请案件',
  KoFPs: '佣金金额',
  WiHvH: '服务范围',
  WgSuB: '服务优势',
  '1k93AU': '选择客户',
  S0KWu: '开学时间',
  VnCy4: '文件标注',
  Z6uWfT: '标记为有效',
  Z6uYIP: '标记为无效',
  '1BOFEi': '自定义案件',
  '1bAXa5': '编辑学校',
  '180lfB': '能处理的区域',
  J8lP5: '交接员工',
  QgQ88: '学校课程',
  '12vAD8': '澳洲境内',
  UrWPV: '接口请求',
  '1lNw4z': '重要提示',
  '2uw9QR': '您的客户信息已经超过 5 个月未更新，请确认全部信息还是有效。',
  LJ0Wd: '原始语言',
  '19z1Te': '签证申请',
  JS3Do: '修改时间',
  '1ihGm1': '账单模板',
  '12UWqt': '添加子分类',
  Spvnr: '归档文件',
  '1lknHd': '成绩单',
  Z2i1Tpv: 'PDF 模板',
  Z15pV1W: '暂未收到消息',
  LoFn3: '协助公司',
  '1f5j7A': '取消压缩文件',
  Z25evwz: '登录成功',
  '1shSP': '付款中',
  QgbvV: '客户信息',
  ZzdGiA: '案件推荐人',
  Z1lzMKq: '护照信息',
  Zq1w5t: '旅行目的',
  '10YcF0': '步骤通知',
  Sbn3n: '延期入学',
  ZM18gy: '澳洲签证文件',
  '162eh2': '相关文件',
  Q2q1W: '实发金额',
  JpCCk: '付款金额',
  R9mAC: '合同创建成功',
  '1BcfmU': '出生国家/地区',
  Z29cehy: '结婚日期',
  K1ESu: '健康检查',
  Uw5YB: '提名学生',
  QeLNi: '就业经历',
  bQ3RI: '设置自定义时间',
  '18gzDX': '客户创建人',
  '115G8N': '添加专员',
  '17FrN6': '积分提现',
  '18z4HM': '签名无效',
  ZyseQA: '提交之后，需要等 OneULink 排单之后才会发送到学校邮箱中',
  kUw1c: '紧急联系人',
  RcmEv: '工作经历',
  ZOLdNc: 'Offer 生成',
  '1zcidT': '总学费',
  JETr7: '保存成功',
  WU6DK: '校区邮编',
  Z1nPIL1: '总分',
  Kjuu7: '其他关系',
  Z2o6Fom: '快要到期时',
  Z26Wod7: '修改了状态',
  S2d0V: '当前环境',
  '1iAoRz': '财政支持',
  '1npPXA': '资金支持类型',
  '1hg9ng': '退休开始时间',
  '3Dur': '旅伴',
  Kopnb: '佣金详情',
  '165MF4': '申请备注',
  ZOKqjk: 'Offer 详情',
  Vohm6: '文件签名',
  '1xwiF': '发新版',
  '1WheGW': '添加基本步骤',
  '1l8XKv': '邀请状态',
  '15nFYE': '生效范围',
  LoMhP: '协助员工',
  Lqj1O: '协助职位',
  L0U10: '加入成功',
  '1l9PY6': '邀请记录',
  SwdKB: '平行课程',
  '14XUb2': '工作状态',
  UlAtS: '成绩通知',
  '17GoWr': '积分结算',
  '9c9mF': '在线申请表',
  KkLTT: '具体操作',
  '257OQh': '添加新的选项',
  Z1WOVgM: '签证案件表单',
  Kmj85: '佣金上限',
  ZKbmr: '默认接单人',
  '1gFhe9': '补课时长',
  ZCb0VM: '发送给客户',
  '1omsrE': '非法操作',
  '18zi9I': '简历生成',
  fNfaL: '签证和护照',
  ItbMo: '上传附件',
  SIERf: '恢复有效',
  LYI1y: '删除附件',
  Qg4K1: '学校申请',
  Z1OVb8D: '接单案件数',
  KnKPw: '公司协助',
  ZrxPJD: '格式不正确',
  '165nrU': '申请中介',
  '18p9vM': '离职记录',
  '18ohic': '离职状态',
  '1krTVt': '远程协助',
  '1jJDr1': '辅导时长',
  Z1TWEee: '未接收',
  '1a8OJJ': '系统内部',
  '1ijTN1': '课程佣金',
  WngBr: '暂未配置',
  JiCns: '上级目录',
  ZOMNC0: '课程结束时间',
  Z2dxKAa: '保存为图片',
  g4aQJ: '签证批签号',
  UFTWQ: '拒签案件',
  V5Hno: '提现收据',
  '1WIu36': '选择员工职位',
  LzgNe: '升学转学',
  '21O2Qj': '当前所在地',
  '1URGhq': '学位证',
  Z1kdFYX: '学号初始数字',
  VuJRK: '放弃压缩',
  LzaF7: '关闭协助',
  MIx1e: '发起协助',
  Qhuk0: '客户生日',
  KnEt8: '公司信息',
  '1ijRrN': '课程信息',
  '19xKGD': '签证到期',
  Uoc7H: '护照到期',
  QhktQ: '居住地址',
  K172A: '健康声明',
  P8t7I: '处理专员',
  VgFUi: '推荐公司',
  '1kdRAa': '转移案件',
  L9eiP: '关系状况',
  Pqqkp: '婚姻状态',
  Z1GSK7G: '澳大利亚签证',
  '197iEr': '作为申请人：',
  '1jN3oU': '退休详情',
  '12vhoR': '澳洲住址',
  '18ARwn': '客户推荐人',
  ZmdHOL: '课程开始日',
  Lc4xW: '关联客户',
  '1sHbq': '二维码',
  '17Gq9Q': '积分统计',
  '3hFj': '学历',
  '12vycT': '澳洲地址',
  Jng8c: '付款信息',
  '165DGV': '教育机构名称',
  '11ITMM': '测试均分',
  ZxaOxg: '创建了待办事项',
  Z26Vvr3: '修改了详情',
  '2915JQ': '添加消息任务',
  '1zokGq': '显示客户标签',
  IUn0E: '中文描述',
  Jnnti: '付款凭证',
  '10YfJD': '步骤配置',
  '1hpJOj': '设为文案',
  '18z2qS': '签名文件',
  '1qGFkn': '手机验证码',
  '1ilue5': '课程级别',
  '1hPVvb': '证明文件',
  PjON0: '触发自由步骤',
  ZBE7UV: '自定义表单项',
  Kn3jq: '佣金文件',
  '1jIQ7U': '辅导公司',
  '1bW4wu': '联系专员',
  XcQiy: '步骤不可用',
  cPbSb: '查看步骤日志',
  '1bcgrV': '继续上传',
  Zmchlj: '课程总学费',
  MatY8: '取消使用',
  '2vj8OK': '出生国家地区',
  '10Vebh': '海外教育',
  VnjFk: '文件归档',
  '2lAIIg': '添加英语测试',
  '1hQ1Rq': '证明材料',
  QggP2: '客户关系',
  '1YbLn0': '添加客户关系',
  '13SHGt': '父母签字',
  '18Gt68': '签字地点',
  '1kdc60': '转移员工',
  '1PK9Sw':
    '因系统升级需要，为了不影响贵公司的积分提现，请尽快更新公司付款信息',
  '18ndnC': '离职员工',
  '18nSNQ': '离职时间',
  Z2qEcq: '邮箱或者手机号',
  Ue5hf: '我知道了',
  '1kXO5t': '邮箱验证',
  '1kvaCP': '配偶信息',
  ZdE4Az: '编辑案件标签',
  '2jlFjy': '姓名与职位',
  Z1mxmIG: '设置课程费用',
  '18nFR6': '离职成功',
  Mc5MR: '员工离职',
  ZYKW59: '标记为全部已读',
  kBY4c: '使用该压缩文件',
  V5Eav: '提现拒绝',
  S3EPT: '异常客户',
  '11VpLO': '潜在客户',
  Z2cmJzv: '学生医疗保险',
  Z1oKbeg: '推荐人姓名',
  xTVvd: '邮件发送中',
  '1TufAD': '添加副申请人',
  Z1W80yb: '申请人信息',
  Z1vPp7z: '关闭原因',
  UR06j: '抄送专员',
  Zm4AYP: '课程有效期',
  n3eSl: '尚未绑定案件',
  UYpdw: '操作成功',
  LXG8e: '可得佣金',
  '166dLk': '申请摘要',
  '1BfmqV': '家庭成员',
  KnTqq: '公司地址',
  '2S6s': '保号',
  V4RRn: '换绑邮箱',
  ZOLMn5: 'Offer 文件',
  UFwRK: '执行成功',
  '1Tubpd': '添加参与人员',
  '1b6VIL': '结算案件',
  IuvIF: '个人信息',
  '2nLWN': '客户自己邮箱',
  Z9NFmD: '课程学费',
  ZLtuTh: '学校入学日期',
  T36E4: '总时长',
  '1MQRP2': '总材料费',
  '2y70X': '请签名',
  Iw1SI: '个人签名',
  Z19vyMJ: '工作经历',
  ZzHIgl: '申请摘要',
  '2j7DUf': '十年旅行历史',
  WblRd: '教育背景',
  ZdeJSb: '开始结束日期',
  Qt7Qf: '学生声明',
  Z25drzd: '结束时间',
  OFTIB: '进入澳大利亚',
  '2sbBME': '健康保险',
  '1b6oeL': '职业分类',
  '2kfNB8': '隐藏客户标签',
  '1o8I4i': '隐私信息',
  ZaPG4T: '预提现被拒绝',
  '1cYhuP': '英文描述',
  '13QDBa': '版本说明',
  '28Rvwb': '添加步骤分支',
  Rn06n: '市场专员',
  '139bFJ': '添加自定义案件',
  z28Oz: '处理的国籍',
  IxXQq: '专员宣传',
  KnEfi: '公司介绍',
  Z123e0P: '邮箱验证码',
  Z12wHuV: '医疗保险号',
  '1ky26l': '通知客户',
  '13Ox61': '版本信息',
  '13PhRc': '版本描述',
  '9KK1r': '查看案件交接',
  '1kyPau': '通知状态',
  syW0L: '提交 956 表单',
  Z12FRTt: '删除的文件',
  '1XynVI': '房屋 {index}',
  '1Yb699': '添加家庭成员',
  Wyq7X: '更改成功',
  '165uIW': '申请公司',
  Z25FsQ9: '额外追加步骤',
  MfRgw: '同步成功',
  '18YAeJ': '客户端域名',
  '10W2zY': '步骤分类',
  ZF2nly: '最近更新时间',
  LOpL5: '合同信息',
  Z1Q6HYw: '接入成功！',
  '1442U1': '在筛选项中搜索',
  Qh1eS: '学校邮箱',
  '166vHD': '申请流程',
  MbwM5: '取消流程',
  '1SfjTC': '添加其他客户',
  '1igKSi': '账单信息',
  Z1z3C6K: '设置画笔颜色',
  '1jQgTG': '返回首页',
  '22ut7A': '在职证明',
  XtrI6: '毕业证',
  '2e386b': '添加申请费用',
  '1bcvuz': '继续压缩',
  '166b8E': '申请提现',
  uodQL: '创建案子出错',
  '9sSW4': '查看更新详情',
  '1Sk7lK': '副申请人案件',
  '2bqcdm': '关闭案件提示',
  '2zxDG': '身份证',
  Z1QCFvw: '签发国家/地区',
  Z1WtU9F: '以前的护照',
  '1ab0YJ': '系统邮箱',
  Sr4ud: '归档邮箱',
  ZkrY7X: '登录 IP 地址',
  Z1wutKX: '{day} 天之前',
  Z10pJmR: '邮件 {index}',
  '1Xy3Sa': '家庭成员',
  '1b7Pun': '组织地址',
  '2dGT5b': '澳洲价值观：',
  Z26WH6x: '修改了标题',
  '2kMqJg': '修改了截止时间',
  '163qc': '在澳联系人',
  '1Gom2p': '联系人详细资料',
  S1eYo: '座机号码',
  '2aRkba': '新增 Tips',
  ZjWe93: 'Alipay 海外',
  '1T9ns0': '添加内置案件',
  '2rKVQT': '添加追加步骤',
  ZxH5Hq: '祝你开心每一天',
  SoRU3: '归档分类',
  '2eE6Cc': '上一次分类',
  '39j54': '查看学校详情',
  oBRu: '已经有账号？登录',
  '1bAy47': '编辑信息',
  Z21VKxe: '关联 Case',
  '19jo9H': '签约专员',
  '2sUp7R': '父母请签名',
  '13ajuu': '添加自定义表单',
  VOYxx: '是否必填',
  Z1vG1Wl: 'Offer 生成权限',
  '1hqism': '证书文件',
  '15kueI': '生成证书',
  ZBnj1c: '提交签证表单',
  '2f07TY': '邮件状态变更',
  Z5gtoo: '步骤状态变更',
  Z12o2bh: '文件步骤绑定',
  jehp4: '转学分文件',
  Z17x7Uj: '课程开学日期',
  '12w6g3': '澳洲教育',
  '1c6L5k': '自动转发',
  Zphiys: '步骤中文标题',
  '1c5eI6': '自动提交',
  Z25Sx5M: '修改角色组',
  Z17yNH4: '课程开始时间',
  QaFGH: '小于等于',
  Z1RcGfp: '主申请人信息',
  Z16pMIK: '是否递交？',
  Z1Mbn5Y: '签证签发日期',
  '1DeFnB': '计算机资源',
  Uqq8n: '提交成功',
  Z2rVeNd: '已结算到公司',
  Z1oIdvi: '推荐人邮箱',
  Zk1oGi: '学校英文名字',
  ZMzaeA: '课程英文名字',
  Z11MA0H: '申请人是否:',
  Z2bRpey: '目的的国家',
  '1ixIkB': '评论邮箱',
  '1j4bVI': '身份证号',
  '1SO28': '曾用名',
  '1hql1l': '证件号码',
  KI86m: '保险机构',
  '1ijJNk': '账号邮箱',
  ZwVmdk: 'OSHC 文件',
  '1Ir51': '已提现',
  TEOem: '手动提现',
  V5IaC: '提现方式',
  Z24APYh: '学生所处地点',
  fHjD: '客户系统邮箱',
  '1UY0VJ': '英语考试记录',
  Z8jUqr: '申请额外积分',
  OyH9Q: '复制成功',
  '1cem78': '职责描述',
  Z1gR7L4: '限制令起始日期',
  '1lM21R': '金融机构',
  '25pONj': '添加了消息任务',
  '1pwRq4': '使用归档文件',
  KpWy0: '公司邮箱',
  '1Vwymq': '查看 CoE 详情',
  gu8EM: '输入客户名',
  Z1vuwYa: '确定删除吗？',
  Z1jNNHa: '新增自定义案件',
  S14GK: '当前分类',
  M29QO: '是否去协助？',
  Z1x0dM5: '登录成功！',
  '1p3fbJ': '是否需要学分',
  '29Wdsg': '意向开学时间',
  MDz1X: '发表成功',
  '1K08t': '律师费',
  '1bVNrG': 'GTE 预填写表单',
  XBRcA: '提交 GTE 表单',
  '19z1Ur': '签证电调',
  '22IAh5': '选择工作经历',
  Z2kHh3S: '绑定到步骤文件',
  ZtxvYw: '请添加文件',
  Z1oA8vm: '添加权利所有人',
  b3Nxj: '点击展开更多',
  '1czf3t': '打开签名链接',
  JTvVO: '修改邮箱',
  '2qdbSY': '添加账单模板',
  ZJdzXe: '未归档文件',
  LriaB: '协助验证',
  ZOLj5o: 'Offer 状态',
  Z1xb8fb: '创建角色组',
  ViaxX: '改名原因',
  ZauxAe: '请选择时间',
  OdqpR: '相关申请人信息',
  Z1tOiQX: '申请类型？',
  Z2jEzc6: '如果是，请解释：',
  Z1POATq: '无效的表达式',
  ZgPz5b: '编辑打包课程',
  '2gwvHx': '案件创建失败',
  '1oHz2I': '额外申请',
  '2fqEa5': '系统更新提示',
  '15MlLG': '发送邮件失败',
  '15kHQD': '生成邮箱',
  '2fSPP6': '下一笔付款日期',
  MkyM2: '受益专员',
  hPifN: '证件编号类型',
  ZzCW0z: '案件下一步',
  Z1hT4BM: '护照所属国家',
  Og2MP: '国内联系电话',
  '8hm76': '查看文件详情',
  Zxenlx: '{week} 周之前',
  Z1wutDB: '{day} 天之后',
  yfPC5: '{hour} 小时之前',
  sdJ5C: '预期发送时间',
  '22DaXs': '添加打包课程',
  sVgpu: '预计入学时间',
  '1oGdrI': '额外信息',
  Z1dCWxo: '课程材料费',
  Z1flO5T: '下载 Offer 文件',
  Z1GqVP3: '页面已失效',
  Z1LnW28: '提名的职业',
  Z2aVv8K: '英语测试分数',
  '1YEq0V': '自行添加课程',
  Z1sYB5M: '暂无打包课程',
  '1KF4Ro': '英语测试详情',
  '1mAn2I': '测试参考号',
  '1591TV': '其它家庭成员',
  '2cBF9Q': '移动电话/手机',
  EcS6z: '参考号码类型',
  Z9NF5s: '计划抵达日期',
  '2slJKj': '组织机构地址',
  '1ttWAU': '联系手机号',
  Z1CNGjR: '佣金操作专员',
  Z1y31n: '查看历史版本',
  '1RdRvy': '添加上级渠道',
  UrTNP: '提交说明',
  Z2fKyht: '其它登录方式',
  Z11AsB6: '注册成功',
  '1oIfag': '额外要求',
  '18wSKh': '学校警告信',
  '2wYKJk': '表单中文名字',
  ZaAGAH: '编辑步骤成功',
  Z1f2grz: '课程入学日期',
  '1Xody5': '中介 Offer 生成权限',
  Zl5pBc: '默认材料费用',
  '12tb5O': '其它地区费用',
  '1hqR2f': '证书生成',
  '2rGrcz': '添加辅导课程',
  '1W5XFf': '选择副申请人',
  '139n3s': '添加自定义款项',
  ZQviSc: '填写 956 表单',
  Oc9LG: '填写信息',
  '21j8Wy': '选择学习历史',
  Z1AQOaF: '初始化错误',
  VmRGK: '文件分类',
  '1YcyD5': '添加客户权限',
  Z1IxbyX: '删除英语测试',
  '25co2z': '添加服务优势',
  ZYGOt1: '可提现积分',
  '2etnjs': '案件中文名称',
  '1FDNuz': 'OneULink 提交',
  Qlmz7: '客服中心',
  ZNFJl: '再次添加新客户',
  U43Di: '手机验证',
  Z2pgfku: '创建案件标签',
  EbXeG: '添加一组数据',
  OfrGj: '国内联系方式',
  OI54l: '护照扫描件',
  '1hnCBe': '主要更新内容',
  '8m0jL': '选择在读 CoE',
  '2bq5OF': '关闭案件成功',
  '1fxVyP': '合同签署链接',
  '1ta33k': '联系人详情',
  V3ewE: '换绑手机',
  ZTh0Jh: '社交账号1',
  ZTh0Jg: '社交账号2',
  fKOVT: '签证到期日',
  '248BM': '客户联系邮箱',
  NphiT: '国内地址',
  UFcrd: '拒签原因',
  SRnoi: '开课日期',
  '1immM3': '课程院系',
  Zauxyd: '请选择校区',
  '1yvWbX': '测试参考编号',
  ZweOuI: '创建于 {time}',
  Z1qBSOR: '积分使用申请审核',
  TN6wa: '所属中介',
  Z1uT20u: '亲爱的 {name} ，你好！',
  ujt1K: '预计发布时间',
  '13AzAf': '邀请已失效',
  '2us9cB': '还没有账号？',
  Rop5K: 'OneULink提交',
  '22s4V0': '意向入学时间',
  '38buv': '申请表单 PDF',
  XNqtS: '邮箱验证码',
  ZBEhsy: '发送验证码',
  Z248szq: '表单英文名字',
  Z159cS5: '发布新版本',
  '2eu722': '案件中文标题',
  Zq9UGx: '编辑佣金规则',
  '1gI4RA': '要求描述',
  '1KOL5L': '添加为副申请人',
  Z1bVFaJ: '澳洲体检费用',
  FhuJc: '首次签证申请费用',
  Z1lbucq: '文件柜查看',
  Z24a0KQ: '签证审理时间',
  '22It02': '添加房屋信息',
  Z1f666S: '护照有效日期',
  Z1dguI: '客户签字时间',
  '1icmo2': '购买保单',
  '25ed3F': '添加服务范围',
  '3LAUI': '步骤英文标题',
  LAjVV: '半工半读',
  '180vLS': '能处理的国籍',
  Z2fp8GL: '案件转移失败',
  ZnbcrW: '绑定邮件到案件',
  Z1kvM2m: 'CoE 付款审核',
  '2eH9Qf': '案件交接失败',
  '22TRcW': '上传成功！',
  Oz26b: '大于等于',
  '1TxaoJ': '配偶的联系方式',
  '26YNE8': '当前积分余额',
  dVMh: '上传中，请稍后',
  MnpiF: '周期提现',
  Z1mj7hX: '我的主要办公室有',
  Z1yLLRX: '设置申请费用',
  ZcKeX2: '默认申请费用',
  '1lmR6C': 'PDF 页脚模板',
  ZM6df6: '申请使用积分',
  ZOMQYb: '课程结束日期',
  y5YGK: '邮件已送达',
  ZUyQQC: '电子通讯',
  '10R4yR': '主申请人案件',
  '17v2bX': '学校佣金比',
  Z1nkGx0: '改名的原因',
  '1vhQiT': '儿童监护权细节',
  Z1Pve1X: '失业/无业详情',
  '2hwGNq': '发送时间已过期',
  Z2gJbWH: '不能重复添加',
  yfPJr: '{hour} 小时之后',
  '1T2Ho0': '当前打包课程',
  hCOkl: '输入验证码',
  ZeOIGT: '签证批签号类型',
  '22QhbH': '选择就读校区',
  ZSLQJu: '角色声明2',
  ZSLQJv: '角色声明1',
  Z1hCNgD: '政府奖学金',
  Z1P5xJe: '公司联系人',
  Z2tkiNn: '删除了消息任务',
  Z1qypDw: '更新于 {time}',
  Z1sT1Gu: '保险公司名称',
  TNhRt: '所属分类',
  Z1ku6AP: 'CoE 付款详情',
  Z984WW: '海外银行转账',
  ZfbmNU: '编辑文件分类',
  Z2pYtnT: '创建标签：{tag}',
  '207Rct': '添加工作经历',
  '2dTFtH': '显示取消的 CoE',
  '2lVsbE': '职位岗位推荐',
  '19kWlK': '签署成功',
  Z1fMWhl: '短信验证码',
  '1TYiJU': '邮件中文标题',
  '2o3df6': '邮件英文标题',
  '1PDOEJ': 'Preferred Name',
  ZngQap: '第一次打开',
  '2wpBi3': 'AAT上诉账单费用',
  Z1OCLw8: '签证申请表单',
  ZAM9ak: '{name} 课程费用',
  Z1V5t1o: '文件绑定到步骤',
  ZwIcCX: 'GTE 辅助写作',
  Z1mDC5l: '新添加的文件',
  '1mBBtQ': '密码不相同',
  ZWLGl4: '合并目标客户',
  Z2d39KP: '操作日志详情',
  KYcAO: '分拥员工',
  '29245o': '添加消息模板',
  '1sEW9P': '点击下一步按钮',
  ZDix9y: '可接受案件类型',
  '2uGyT4': '生成联系邮箱',
  '1k93vf': '选择学校',
  ZyrzE4: '案件转移中',
  Z2nL1IH: '绑定到案件成功',
  '2eHoJK': '案件交接成功',
  '2hfnta': '添加签约学校',
  '19ksrW': '修改辅导课程',
  Z1FobWJ: '身份证信息',
  '1upQN8': '签证批签号类型',
  '13SEXz': 'COE 编号重复',
  Z1N97B3: '批签号重复',
  ZGoQBh: '积分总余额',
  '2dmotw': '账户密码登录',
  jrQJ1: '设置课程入学日期',
  OeV9x: '国内联系地址',
  Z1Pavhw: '护照签发地',
  ZLBKGI: '学校佣金比率',
  Z1FYGYz: '学生课程详情',
  FP2FO: '修改客户备注',
  '12wEQc': '澳洲电话',
  LyEtn: '参与专员',
  '1c5hgz': '自动提现',
  '2cJiEH':
    '跳过后，相关申请邮件不会发送给学校，需要手动将申请表 PDF 上传到相应的步骤中',
  ZQnHYX: '紧急联系人姓名',
  UFTSU: '拒签时间',
  Z19jUM8: '课程完成日期',
  '1ikg8i': '课程学历',
  Z16P2PX: '英语语言测试详情',
  '1j5w2G': '教育结构名称',
  '165sxY': '教育机构代码',
  vk0m3: '奖学金/奖项名称',
  '1GmcKq': '联系人详细信息',
  '2YiU': '关系',
  '1bXuNB': '联系电话',
  Zsmcvh: '拟定停留时间',
  QfABb: '家庭成员',
  Z1I4qG9: '审核于 {time}',
  Z12vCpw: '额外积分申请审核',
  Z2qhYFq: '获取临时密码',
  '1G1u3W': '登录到 OneULink',
  ZqSLOq: '学校申请案件',
  '2sq2nt': '创建佣金规则',
  '1ijXWv': '课程分类',
  '3loRO': '编辑 Offer 模板',
  Z1QVtld: '账单收据对象',
  Z1IG7gP: '重新通知专员',
  ZqRE0F: '学校申请表单',
  Lc9O2: '关联已有',
  '18z9xd': '客户护照号',
  Z1lcr5H: '文件柜上传',
  ZKY9Cq: 'GTE 生成失败',
  '2mgKpo': '与客户关系',
  Z21mEaS: '谁的工作证明',
  '1jzBBM': '输入密码',
  Z25135K: '删除工作经历',
  '1nVvwe': '使用公司 LOGO',
  Z2aricK: '上传公司 LOGO',
  pdaGi: '是否开启佣金结算',
  '28Rx7W': '添加步骤分类',
  Z1xNjfc: '创建案件数',
  Z11PmB9: '确定并刷新',
  '1Dh61G': '渠道商名称',
  '1DgWI7': '渠道商佣金',
  Z23otzY: '创建辅导课程',
  '1W4BNn': '输入邮箱或者手机号',
  Z13HNpz: '出生地 (省/市/国家地区)',
  Z187QBJ: '办公室的位置：',
  Zn4XX6: '最大课程时长',
  '1Sr4B1': '添加入学日期',
  ZOMPDV: '预提现审核失败',
  yaVSl: '邮件投递中',
  '2gwKB3': '案件创建成功',
  Z29Ah79: '签证到期日期',
  '2tFdUU': '海外旅行计划',
  Z2lKYtC: '关系开始日期',
  '1wWBTt': '其它身份证件',
  gfOWa: '居住联系电话',
  '28EKtB': '自动提交预览',
  '2tX1v9': '复制预览文件链接',
  '26rdmj': '健康保险公司名称',
  '14vqxD': '犯罪详情',
  ygAqQ: '修改了优先级',
  KsrcL: '入境日期',
  RJ8tg: '在澳大利亚学习',
  '1QN1sr': '客户姓名或者邮件',
  '1SXamy': '自行提交案件',
  Zy1ATb: '澳洲银行转账',
  Z12evtu: '课程标注详情',
  Zb4paa: '请输入密码',
  Z1byohS: '记录创建成功',
  Z1kWGCW: '{s} 秒后重发',
  '1TXrye': '邮件中文内容',
  '2o2m3p': '邮件英文内容',
  ZNwzO: '默认总学费',
  '1sF4xS': '添加课程入学日期',
  '1NKitK': '提交到 OneULink',
  ZKXTIU: 'GTE 生成成功',
  Z1lafVQ: '文件柜详情',
  '1bnCVW': '添加澳洲 Offer',
  ZqaA2x: '编辑公司信息',
  RkXws: 'OneULink 端',
  '1EEyfB': '员工结算方式',
  Z1qAvJC: '公司可得奖金',
  '1k7P59': '还未派单',
  OlG2n: '增加职称',
  '176rKP': '完成案件数',
  Z1fEylt: '公司案件统计',
  ZzBzkT: '案件交接中',
  '2k0Jg4': '添加自动提交',
  Z1GvuLM: '页面失效！',
  ZShSuK: '{day} 个自然日后',
  Z1rNIuj: '保险单号重复',
  Z1yrB2f: '上传客户材料',
  '1xjDBK': '什么是隐私邮箱',
  '2eGHex': '案件交接专员',
  Z1qxzs1: '护照到期日期',
  Z17yR4f: '课程开始日期',
  Zs2oPF: '接口调用成功',
  Z1UB2sA: '设置上级渠道',
  '1yiBdK': '学校所在国家/地区',
  ZLC0Ua: '学校佣金收据',
  t3Oz6: '其它身份证明文件',
  Z2uqlev: '签名初始化中',
  ZJB2Jh: '澳洲联系电话',
  ZQmGKh: '紧急联系人电话',
  Z2wjdVu: '表单信息预览',
  '2dqyX9': '技能评估日期',
  zvE2p: '英语水平',
  Z1LArhN: '入学确认书',
  Z2hihNf: '犯罪详情',
  '2u6pUj': '提前 {day} 天',
  CCsdR: '定时消息通知',
  cKlWf: '特殊类别的条目',
  Z1xi1ad: '进一步停留的时长',
  '1VtV8q': '上一次就业岗位',
  VvaAb: '旅伴信息',
  Z1krMA9: '账单默认设置',
  XJ3yp: '是否有转学分',
  Z1jk1lY: '公司联系二维码',
  gcLwx: '欢迎使用 OneULink',
  '1STCzn': '入学时间/结束时间',
  Z1lVUN: '代理注册号码',
  Z2mcRkC: '创建步骤成功',
  '17R5X8': '确认密码',
  Z2mDQ0i: '案件英文名称',
  '1DisSb': '渠道商类型',
  Z2foSNg: '案件转移成功',
  '1RcNRn': '添加下级中介',
  PTadE: '学历范围',
  '1vFhNp': '创建客户表单链接',
  Z1yEOES: '继续合并客户',
  '1TwA4B': '太平洋-澳大利亚卡',
  Z1PPbkm: '护照号重复',
  '1lbWyj': 'PDF 页眉模板',
  Z1wYgC: '查看历史通知',
  ZQnNoz: '紧急联系人地址',
  '2nHPTw': '固定金额 {amount}',
  Z2x3Czv: '预定结婚日期',
  UlaTY: '修改客户推荐人',
  ZciT7A: '客户有效无效',
  ZnF51K: '确定全部提现吗？',
  '23Sogn': '{year} 入学时间',
  ZyfeIE: '是否有打包课程',
  Zz1tJ8: '签证历史与护照',
  Z18Di26: '课程学费总计',
  t7vNG: '教育经历详细信息',
  Zs4uPp: '当前或以往的签证',
  f5Obw: '评估机构名称',
  '2oKd6z': '选择该组打包课程',
  Z2lC3Vf: '语言学习经历',
  ZB8k5O: '公司所在国家/地区',
  '1PzhAm': '当前就业详情',
  '17PXvN': '修改账号信息',
  Z1PBK40: '护照所在地',
  '13MA8x': '合同有效日期',
  Z1LoImW: '医疗保险类型',
  '2orlh2': '重置 CoE 打包课程',
  Z2m3QHa: '创建消息模板',
  Z15PbUq: '文件显示数量',
  TtsPF: '中文显示信息',
  Z1Co46t: '输入步骤分类',
  Z2mD7hI: '案件英文标题',
  '1bbzQ2': '续签费用',
  '2rgJ3B': '生成简历成功',
  Z15RCyV: 'GTE 写作描述',
  Z7UeRe: '保存为 word 文件',
  '21N96B': '选择客户文件',
  HX89S: '家庭成员 {index}',
  SfRzK: '是否开启宣传',
  '1MLjat': 'Tips 所属公司',
  ZQo5cB: '紧急联系人住址',
  '2naxep': '邮件自动转发',
  Z1O7XQV: '账单模板配置',
  Z2uEJP8: '待重新标注学费',
  sA50: '所属辅导公司',
  '2fscwq': '系统更新通知',
  ZQJip9: '选择其它案件类型',
  ZJBCPN: '澳洲联系方式',
  ZHGaQU: '申请增加积分',
  ZbDmGE: '预提现审核中',
  Z2qXHkl: '案件等待创建',
  Z2g6QbI: '澳洲入学 CoE',
  Z1QwEiK: '百分比 {percent}',
  Z1PSzY8: '护照到期日',
  '7L6QL': '已使用额外积分',
  Z1Uw9iY: '设置付款信息',
  Z2qLTvz: '健康保险详细信息',
  '1Yvsj0': '常住地区/国家',
  '1SnIw5': '参考/收据编号',
  '1pDOFQ': '居住地区/国家',
  zoQL9: '修改了负责人',
  Z1Ps76g: '公司注册号',
  '1bWFbw': '联系座机',
  Z15MCcT: '澳洲学习历史',
  Z1ev3xp: '出资者个人信息',
  '5VBbr': 'OneULink 提交案件',
  Z2qMxxP: '创建文件分类',
  Z1Flepe: '上传付款凭证',
  '1vkkgI': '下载签名文件',
  '21Oypu': '选择客户资料',
  '2wDJHe': '添加额外要求',
  Z1BBuFe: '编辑案件抄送人员',
  Z2lC6Fl: '签名打包下载',
  ZzqQ19: '权利所有人 {index}',
  Z1Eve2r: '请输入中文姓名',
  '1gwIdK': '员工可得奖金',
  '3Lrkx': '步骤英文描述',
  ZBkSo1: '本月已完成案件',
  '2cPnE2': '请选择周期类型',
  '1kX7CY': '支持分期付款',
  Z1OGT7B: '公司宣传二维码',
  '11DKvr': '修改自动提交',
  Z1hwwwT: '域名已经存在',
  '7bXfk': '点击发起提现',
  Nqnwl: '发生了一点错误',
  Z1K65pH: '添加宣传活动',
  ZiVGI3: '默认每周学费',
  Z23SeB0: '切换公司成功',
  Z1g2tDE: '文件压缩失败',
  '2tO0FJ': '已结算到公司员工',
  Z29poW: '协助验证成功',
  Z2wqdQf: '身份证签发地区/国家',
  FOHjh: '修改客户信息',
  '2wYNly': '输入案件关键字搜索',
  ZsiSsl: '课程每周学费',
  Z1O8fgN: '教育背景 / 工作背景',
  Z1eVotv: '打算居住的州',
  '1SpL5o': '选择课程就读校区',
  '1c6DUU': '联邦资助的学生',
  Z8r9u2: '赞助机构名称',
  Z8pLCx: '赞助机构类型',
  arnN9: '所在地区/国家',
  kMwIz: '澳大利亚居民身份',
  ZSyxye: '{count}秒后重新获取',
  '2spYRa': '创建公司成功',
  Z2a0X0g: '学生基本信息',
  Zfpj8I: '个人联系信息',
  ZJC9nz: '澳洲联系地址',
  ZI7yuE: '外国护照（当前）',
  OfkVo: '国内联系手机',
  '10sKcO': '请配置案件步骤',
  '1kVoCP': '是否通知客户',
  JYhwx: '上传移民局文件',
  ZLqQd0: '工作证明信息',
  tGnIO: '专员签字时间',
  Zphs9D: '步骤中文描述',
  Zwsrai: '本月已提现积分',
  Z1FrGCS: '输入案件分类',
  '1TYN7B': '添加路由权限规则',
  Z2mqYSY: '请选择账号类型',
  ZrVG7C: '您经营多久了？',
  ZypoUh: '如果是，请附上文件',
  '29ppOT': '个人信息类',
  Z1g2eK9: '文件压缩成功',
  '19vhDX': '推荐人联系二维码',
  ZC8m5t: '搜索国内地址',
  '1w2OSE': '护照所属国家/地区',
  ZzuEJr: '海外教育背景',
  '1R3kMV': '补充付款收据',
  Z1qAQX0: '公司可得佣金',
  Z1EFIML: '雇主/企业详情',
  qugqC: '澳大利亚旅行历史',
  Z55SML:
    '当你需要修改学校申请表单 PDF 时，可以选择跳过自动发送，然后将修改好的申请表上传到步骤中',
  '24vucO': '护照所在国家/地区',
  jLVhA: '澳大利亚学习要求',
  '1mO9BR': '技能延期资格',
  Z1QdJ6j: '这是当前地址吗？',
  Z1F6yyO: '雇主/业务细节',
  Z1pERX0: '针对你未来的职业规划',
  Z2mJxlb: '职位英文名称',
  '2enFXz': '职位中文名称',
  Z1FIwwp: '已发送，请注意查收',
  Z1BjhXY: '中文姓名或常用名',
  Z1qAgjo: '表单项英文 Label',
  Z12VnsA: '课程有效时长',
  '2XVhK': '证书生成成功',
  '1EbAic': '下载证书文件',
  WkiTx: '暂无数据',
  eVLgr: '保险开始结束日期',
  Z2aqvxR: '生成签证电调文件',
  '26T6jk': '选择文件分类',
  gx0bj: '例如 2024-05-01，有效期是到北京时间  2024-05-01 23:59:59 有效',
  '25h0Bl': '{file} 标注成功',
  Z1sXoCE: '学校基础护照等级',
  lgl0B: '输入邮箱验证码',
  Z1x7H21: '请输入客户邮箱',
  '1BD1SQ': '澳洲签证批签号',
  '218Ulf': '英联邦资助的学生',
  Z5ssjS: '或拖放文件至此处',
  Z1rNgIN: '归属的案件类型',
  N9F0c: '持有的执照和许可证：',
  Z2cUUys: '是否使用隐私邮箱',
  ZQo3lR: '紧急联系人信息',
  Z1XrTUn: '重发派单邮件',
  RVR3N: '输入专员名字搜索',
  LRmtR: '设置学校佣金比率',
  '1vt6ih': '教育机构的关闭',
  Scws1: '护照持有人国籍',
  '2vXBad': '学生监护人声明',
  Z1TPCqh: '澳洲 Offer 文件',
  '2eRvl': '签发地',
  Z203xRb: '澳洲签证批签号',
  hXqtp: '职业名称 (ANZSCO)',
  Z2pri21: '刑罚的全部细节',
  ZzCacl: '与申请人的关系',
  '2wDP9O': '请输入正确的网址',
  ZFC2AR: '输入公司名字搜索',
  ZJY3St: '申请表单不见了～～',
  Z1L5cT0: '删除职位成功',
  Z2mPttv: '自动提交 PDF',
  Z1yLAFq: '当前表单已过期',
  Z298KFQ: '是否有打包课程？',
  '179ICR': '英文显示信息',
  b7b8U: '步骤部分完成',
  Z2eI1sl: '服务器连接中',
  Zol093: '客户其它资料',
  Z2e3R7P: '户口本信息',
  Gyr1c: '编辑客户基本信息',
  Z26Bnsf: '修改手机号',
  ZNSkDv: '公司额外信息',
  Z2pshop: '学校数据年份管理',
  ZRHkKe: '国家的护照等级',
  Z217RFP: '手机验证码',
  ZwV4iS: '请输入手机号',
  Z18LOeG: '学历相关信息',
  '1ILNdC': '等待公司预提现',
  Z15L6Ul: '澳洲学习经历',
  '1Ptejs': '工作签证医疗保险',
  '20U0Bt': '输入地址关键字搜索',
  ZWaFXx: '等待邮件确认中',
  '16iylh': '护照持有人国籍',
  '25Az9G': '确定恢复案件吗？',
  ZRVTDG: '当前附件大小总计',
  Z19kPYy: '不能选择重复课程',
  Z1A5X0o: '是否需要转学分？',
  ZYobFF: '澳洲教育背景',
  Z2gWK3h: '健康保险类型',
  Z1KU2tw: '获得认可的大学学习',
  '12j8G4': '职业编码 (ANZSCO)',
  '2sbcB': '请详细说明原因',
  Z1tUIhq: '教育机构的关闭',
  I46to: '适用的国家',
  ZaqjYv: '其他组织奖学金',
  ZMvEY1: '计划的最后离开日期',
  Z1sRDP0: '保险公司类型',
  '1BhkkI': '他们是做什么工作的？',
  ZJBJAI: '澳洲联系手机',
  '2gmK7V': '表单项中文 Label',
  eyvpC: '预计可得 {point} 积分',
  Z2fKxer: '房产证信息',
  Z1iQLpk: '公司所在国家',
  '182nCs': '能处理的语言',
  Z17DalE: '预积分申请审核',
  ZnpWHp: '默认提交说明',
  Z2qVwGQ: '案件等待转移',
  YF34y: '输入课程名称关键字',
  '1V2sMp': '添加接口权限规则',
  ZcU5XX: '申请过桥签证',
  Z1iJivB: '文件下载验证',
  Z1dIOBx: '{day} 个工作日后',
  Zb2IkN: '请输入账号',
  Z1a3shf: '预估可得佣金',
  '1mfz0A': '证件持有人国籍',
  '2d6yVI': '是否需要创建 OSHC',
  Z28LXWk: '是否需要创建签证',
  Z2unGEm: '添加不同地区费用',
  Z1lwJCb: '文件压缩中',
  Z13hDYS: 'Websocket 重新连接中',
  '27Lmim': '旅行许可申请',
  uTKxU: '已重新发送派单邮件',
  ZGExdm: '课程起止时间',
  MaUkQ: '修改有效无效',
  D2e4p: '在案件完成时发送',
  Z21RwVC: '在案件创建时发送',
  '20XHRx': '在签证到期日 {desc}',
  ZcopXH: '上传相应的文件',
  ZQlKh0: '紧急联系人邮箱',
  '2nGlJ2': '课程材料费用总计',
  Z1Cfm0B: '确认全选否吗？',
  '1CieHy': '机构所在地区/国家',
  Z2cvl4S: '在澳大利亚停留的时间',
  '29z5Yk': '待创建客户人数',
  '1q0Tfz': '等待预提现申请',
  Z1EQoDc: '请输入不同选项',
  Z2bbo6e:
    '18岁以下申请人的陪读签证是单独的590父母陪读签证，相应的表格信息不一样，请手动创建相应的案件',
  '1l1oAH': '是否是申请过签证',
  '2rwdyC': '是否是第二次续签',
  Z2m0OlG: '拖拽要上传的文件',
  Rg0Y1: '提交资料到学校',
  '18A4GI': '关联已有签证',
  '1IdiFp': '是否包含 GTE 文件',
  '65SCK': '{name} 申请 {school}',
  Aghfb:
    '点击下方复制按钮，将链接在常用浏览器里打开（如：手机微信、Google 浏览器），链接有效期2个小时',
  Z1JruCl: '设置提交说明',
  BikYa: '请输入关键字搜索',
  iTV4z: '是否有签证批签号',
  Z23IheU: '是否需要创建 CoE',
  Z11B3hC: '公司联系电话',
  Z2vSBSX: '创建客户成功',
  Z1m3jPd: '下载自动表单 PDF',
  Z1fktxp: '其他医疗保险机构名称',
  Z1bWRfP: '不能添加重复时间',
  UFjMS: '拒签地区',
  Z1BUNAV: '确认全选是吗？',
  Z2sICX2: '其他学习证明',
  Z21GvB5: '其他证明材料',
  ZF9uCg: '关系',
  Z2hnjA3: '请重新选择截止时间',
  '1TD9uX': '出资者与申请人的关系',
  ZRuTPr: '您课程总时长是多久？',
  Z1HW4Bh: '您的校址在哪里？',
  Z1J9Wv2: '您未来职业规划是什么样的？',
  '1XHA2H': '发布新版案件',
  J4K5f: '创建课程辅导公司',
  Z1fQBrP: '请输入签名地点',
  ZP7f6B: '客户简历生成失败',
  '1esgbL': '删除学生医疗保险',
  ZhFMnY: '可接受转发专员',
  '1gwn0n': '员工可得佣金',
  eEl70: '同步全局消息任务',
  ZwYgyD: '点击切换协助公司',
  Z2kAuyX: '修改课程辅导公司',
  paCIU: '材料查重失败',
  Z1iUsIX: '赞助商或赞助商申请人',
  K48Cc: '查看最新创建的客户',
  Z1TGTT6: '申请人声明：',
  CAPk: '执行 {name} 成功',
  '22K9Dl': '身份证（如果有）',
  ZtSq9h: '中介处理专员',
  Z1MLd0W: '是否有相关 CoE 文件',
  gORVm: 'CoE 文件识别中',
  '1dK7NR': '与主申请人关系',
  Z1c7Lu6: '健康保险 / 教育背景',
  Z2p9Tai: '测试所在国家',
  Z1FG69l: '针对你的背景',
  ZGmzuM: '创建自定义案件成功',
  WWPhw: '是否有拒签史',
  LII4B: '编辑个人联系信息',
  ZG26Hj: '您的信息已更新',
  Z2aG9fm: '离职案件交接情况',
  Zy7uy3: '确认完成案件吗？',
  ZvTLEI: '请输入验证码',
  '2tYlFI': '编辑自定义案件分类',
  '16GeD9': '可以快速创建案件',
  Z2ru347: '修改路由权限规则',
  Z7ME0Q: '社交账号 {index}',
  Z2mzFAh: '确认结算给员工吗',
  ZixzaL: '距离课程开始天数',
  '1PjpL7': '在客户创建时发送',
  P05il: '签发地/签发机构',
  '17bsmu': '艾滋病毒感染，包括艾滋病',
  '2nBmAH': '是否从来没有出过境？',
  Z1LIHY: '请详细说明需要在澳洲进一步停留的原因',
  '28cFg1': '添加海外教育背景',
  '1cXvUX': '学校自动申请表单',
  '28wrXR': '输入文件标题（可选）',
  ZWJuGn: '请输入验证码！',
  Z27eohc: '通过 OneULink 提交',
  Z1tjaWH: '点击退出当前协助',
  ZSAGHM: '高风险国家护照等级',
  Z2qrnoj: '修改接口权限规则',
  ZzUQ5W: '主申请人出生日期',
  Z28MRL4: '是否需要创建护照',
  K00D9: '附上注册证明',
  Z14W83h: '员工人数及职位：',
  Mat4N: '文件再次压缩中',
  ZYJxs8: '客户表单尚未提交',
  Z1Aj6zT: '暂无可用资料',
  Z1huiNV: '文件初始化中',
  ZxhvrF: '是否有拒签史？',
  V8ojt: '医疗保险覆盖期间',
  ZTBQDS: '澳大利亚资格详细信息',
  ZljiSv: '澳大利亚联邦警察局核查',
  Z1bTibN: '紧急联系人所在地',
  '1WEbsQ': '是否要购买 OSHC',
  '2c69bI': '提交学校申请表单',
  Z1Lqanw: '医疗保险分类',
  Z1RQBrh: '删除澳洲入学 CoE',
  dfGyO: '编辑公司额外信息',
  '16FNYp': '可以快速创建客户',
  '5T7YS': '修改上级渠道信息',
  '2hDQXb': '全部案件转移成功',
  yV8gF: '有两种方式可以处理',
  '1P7C9M': '提议者或提议者申请人',
  '1Ij9jM': '添加澳洲教育背景',
  '12HS9q': '未开启佣金结算',
  wN1TP: '在课程开始日 {desc}',
  '1AQFdA': '申请人通常从事的职业',
  '28PDIA': '资格证书的课程等级',
  Z1DQBtz: '罪行的日期和性质',
  '2je2kz': '肾脏疾病，包括透析',
  '22QeFv': '尺寸限制为 200x200',
  '1wYatV': '输入案件名关键字搜索',
  ZP4EIg: '针对你即将学习的课程',
  '27zI9z': '表单行可拖拽排序',
  Z1MsJJf: '使用紧急联系人信息',
  Z2ozrV8: 'blackboard登录信息',
  TiVRR: '触发提交到 OneULink',
  Z2wyDnk: '回复内容不能为空!',
  '15MaKX': '查看 GTE 表单详情文件',
  fgKST: '签名打包下载文件',
  ZyFzyt: '生物指纹登录绑定',
  Z13phtD: '删除澳洲学校 Offer',
  Xu7Rg: '修改案件积分配置',
  '6nG40': '表单项可拖拽排序',
  '21wg4o': '升学转学案件',
  Z1Rxd3M: '请上传正确的二维码',
  Z1mS6SF: '提名人或提名人申请人',
  '11HfH1': '具体事项 – 提供详细信息',
  Z2woCVM: '是否需要创建 Offer',
  Z27cv6t: '确定挂起当前案件吗？',
  kPr4T: '学生护照（必须上传）',
  Z1stjSJ: '学生医疗保险文件',
  Zkw77R: '生成客户系统邮箱',
  Gbfv3: '在客户生日 {desc}',
  qYuNP: '测试地区/国家',
  '1kbuSw': '已选 {total} 项',
  ZP4arK: '您的课程开学时间是什么时候？',
  ZibjNJ: '代写或其他软件广告',
  Z1iEpgK: '个人信息收集表',
  yfLhj: '设置提交自由步骤触发步骤',
  Z1my8vq: '{name} 课程不同地区费用',
  ZVoQlL: '查看 956 表单详情文件',
  Z99S2l: '通知该操作给客户',
  '20M1N9': '常住国家/地区',
  ZpXaHT:
    '当前签证的护照号是 {current}, 和已上传的有效护照证件号 {previous} 不一致，是否继续上传',
  Z4yXA6: '获取验证码成功！',
  Z1RWVQp: '新增自定义案件分类',
  '1JX1A1': '部分案件转移失败',
  Z1U4vNK: '当前转案件移成功进度',
  '4oMmM': '支持单次或批量上传',
  'apply-form:relationship-to-you.option.other': '其他',
  'apply-form:employment-status.option.resign': '离职',
  Z1Sl2KC: '输入短信验证码',
  '1KKwCG': '选择文件名的语言',
  '265Bs4': '序列号(印在卡的正面)',
  '1AHoIC': '客户材料一键上传',
  '14jmE3': '生物指纹绑定失败',
  '2e6xLN': '暂未设置抄送人员',
  '1gLk48': '副申请人信息',
  ZXec7a: '查看客户私密信息',
  Z1mBAgT: '暂无消息内容',
  '1SXOh7': '来自个人的经济支持',
  '2vh3aR': '选择特殊类别的条目',
  '1Svb0P': '您申请就读什么课程？',
  Z11mlOV: '注册 OneULink 账号',
  '240P4y': '当前表单项已经存在',
  Z1NnGNw: '输入案件名字关键字搜索',
  '16bnCt': '以下信息不正确',
  '1NOmue': '移民局文件导入',
  '1NNRAr': '移民局文件上传',
  ZrzuEr: '选择要包含的材料文件',
  Z2dQMM6: '删除海外教育背景',
  ZqEySG: '编辑公司信息成功',
  '1FLygL': '申请额外积分被拒绝',
  ZRi0T: '预计返回澳大利亚的日期',
  Z2eHgkI: '补充学校佣金收据',
  Z23EpXz: '输入客户名称关键字搜索',
  KaaF3: '当前邮件附件大小总计',
  Z1ttWR9: '紧急联系人所在地区/国家',
  Zn4p3Y: '是否申请过澳洲签证？',
  '1263e': '获取临时密码成功！',
  Z1gboB0: '确定让该合同无效吗',
  '1vNz7s': '输入客户名字、邮箱',
  'apply-form:entrolment-status.option.current': '在读',
  'apply-form:relationship-to-you.option.parent': '父母',
  'apply-form:employment-status.option.retired': '退休',
  '1FLDFu': '申请额外积分被撤销',
  '1tKWlV': '绑定到客户的其它文件',
  ZBg3Co: '生成客户归档邮箱',
  Z20a339: '签证提交收据号（TRN）',
  '9fekR': '访问澳大利亚的理由',
  MGiqu: '申请有排队日期吗？',
  '2jixy8': '自动申请提现',
  Z1hICep: '老师、同学发过来的邮件',
  eyQmx: '输入邮件主题关键字搜索',
  Z2or7Kk: '完整填写表格中的所有内容',
  xNBfP: '移民局表单快速填写',
  ZIpN6Y: 'Offer AI 识别中',
  '1QPd4i': '确定隐藏该 CoE 付款吗？',
  Z17MlEE: '参与该公司协助',
  ZepG6N: '关键字不得少于2个字',
  'apply-form:relationship-to-you.option.frirend': '朋友',
  Z2an7bW: '添加新客户成为副申请人',
  ZBsx1b: '是否有学生签证',
  Z2cTvCP: '其他护照或旅行证件',
  Z1oXVSK: '内置案件配置成功',
  sv0hI: '请输入正确的课程 Code',
  '2lTzwT': '学校佣金是否结算',
  ZhFvQU: '家庭单位的陪同成员',
  ZKigcy: '澳大利亚签证批签号',
  Z1Vyaww: '在护照到期日 {desc}',
  Kp0Xu: '申请学校客户创建记录',
  ZgOhOB: '请先设置账单模板',
  Z1mUFnR: '查看学校申请详情',
  '2jbExk': '请输入正确的签发地',
  '3CGsw': '长时间未更新案件',
  'apply-form:relationship-to-you.option.partner': '伴侣',
  'apply-form:employment-status.option.on-job': '在职',
  '1QsyCa': '发送短信验证码成功',
  XWfMQ: '是否来过澳洲',
  Z2bdA08: '输入姓名、邮箱搜索',
  Z1TS7cb: '请尽可能签在画板中心',
  ZLz5H: '距离课程结束天数',
  Z2lzFA0: '在课程结束日 {desc}',
  '2hPCxD': '学习工作背景',
  '3qKwD': '紧急联系人与学生的关系',
  UbMCe: '过去10年居住地址',
  hsvlP: 'COVID-19相关旅行限制',
  '2ftBks': '输入 Tips 标题关键字搜索',
  Z1Qjge4: '关联到需要触发的表单',
  rLuUw: '紧急联系人和学生的关系',
  Z13d8uE: '更新个人信息成功',
  '1cs06A': '确定打包勾选的课程？',
  ZaoiHx: '案件步骤步骤重复，请检查',
  '25CKrx': '查看签证申请详情文件',
  Z1Dz4j3: '设置该课程没有押金',
  '250GMj': '转移后创始人职位',
  ZepFQ3: '关键字不得少于3个字',
  'apply-form:health-status.option.visual': '视力缺陷',
  Z1cB5ly: '太平洋-澳大利亚卡序列号',
  Z1ED1n9: '请选择，输入关键字搜索',
  '1wYGAm': '过去10年居住地址',
  Iv10I: '提供单项和总分。',
  '16t5cH': '您在澳大利亚有亲属吗？',
  LqmPF: '具体情况请联系专员',
  Z1pcMAO: '最近成绩单/毕业证',
  '2wiwBB': '下载学校自动表单 PDF',
  '1XuIkz': '是否包含原始的 GTE 内容',
  'apply-form:relationship-to-you.option.relative': '亲戚',
  'apply-form:employment-status.option.suspended': '停职',
  Z9U9if: '发送成功，注意查收',
  ZjQ5dF: '请求部长干预的人',
  Z2s3gWF: '不能选择重复国家/地区',
  Z1Y6fe8: 'OneULink 发起积分提现',
  '1Ussur': 'OneULink 接受协助请求',
  '1Dhd8L': '乙型或丙型肝炎和/或肝脏疾病',
  Ms3qy: '参考号码',
  Z11vTNJ: '抱歉！您的合同已失效~',
  '22yc3V': '允许文件最大上传数量',
  SmUbV: '课程及佣金说明文件',
  Z2mfF7M: '输入客户名字、邮箱搜索',
  ZzUa0c: '确认进行到下一步吗？',
  Zvsl82: '签证文件 AI 识别中',
  'apply-form:entrolment-status.option.graduated': '已毕业',
  Z23Tbot: '发送邮箱验证码成功',
  '1XkFMS': '接受移民援助的人',
  '1rz2nB': '预计离开澳大利亚的日期',
  ZOtTT5: '在 {date}|{timezone}|触发',
  Z1SibH8: '申请学校额外要求',
  Z2uphqb: '在澳大利亚打算居住的州',
  ZbY6F2: '澳大利亚联邦警察局核查详情',
  '166JNY': '申请理由',
  Z1InXo8: '已发送，请查收邮箱',
  vfHkh: '点击查看折叠的步骤详情',
  '2v3T4W': '学生开学\n毕业管理',
  Z1ImOnf: '课程存在重复，请检查',
  'apply-form:entrolment-status.option.discontinued': '辍学',
  Z1obQMX: '与紧急联系人的关系',
  ZbqekM: '该申请人是否是为无国籍人士？',
  '1S5yaY': '如果是，请附上说明信。',
  Z1Pk31z: '提供提名学生的详细信息',
  '1cuPit': '非副申请人的家庭成员',
  ZrAQGQ: '在案件 [{name}] 完成时发送',
  kAWid: '请输入正确的手机号码',
  '23SDGi': '家庭单位的非随行成员',
  Z1EqSP9: '请输入正确的 {type} 编码',
  Z2lsGiF: '学生中心的通知邮件',
  Z2w6Dql: '设置提交到 OneULink 步骤',
  ZliAuy: '拖拽要上传的附件文件',
  '1LwnoX': '选择澳洲境外的国家/地区',
  X3tJX: '输入英文字母、数字或者中文',
  ZXfSJp: '是否自动创建 {job} 职位',
  Z1BTksR: '该家庭成员是否超过5岁？',
  '13rkMo': '澳大利亚签证批签号（如果知道）',
  w4exN: '手机号码和座机号码必填一个',
  Z1AoSTK: '确定取消绑定 CoE 吗？',
  Z21wJPm: '案件步骤至少配置 2 步',
  '1WBza3': 'OSHC 文件 AI 识别中',
  Z1qfocv: '还未绑定快捷登录，您可以',
  'apply-form:health-status.option.intellectual': '智力缺陷',
  Z2l1of4: '系统识别文件分类',
  '1GO0yC': '家庭单位的非随行成员与主申请人的关系',
  '2bfwNO': '邮箱验证码获取成功',
  '123Deq': '在 [{name}] 分类下添加文件',
  '1DOPV0': '护照文件 AI 识别中',
  '2sjsAO': '附件大小不得超过 15 MB',
  '1ww092': '确定前往该公司协助吗？',
  'apply-form:health-status.option.hearing-deaf': '听力/聋哑',
  '264gHJ': '是否有独立的联系方式',
  ZH8POg: '有无身份证',
  Z2gPJzv: '曾经患过或目前患过肺结核?',
  Z1RBB6R: '家庭单位中的其他家庭成员',
  Ozdvb: '系统正在更新中，给你造成的不便敬请谅解',
  Zb9jI5: '缴费单通知，欠费提醒',
  '2UiD7': '将要向 {email} 发送验证码',
  ZkPvy7: '查看有最新跟进信息的案件',
  '1kHfLw': '（组织名称）的法定代表人',
  '2k0H56': '以下是本次系统更新内容：',
  '1skpRl': '该家庭成员是否是为无国籍人士？',
  ZIQHn7: '是哪个教育背景的附件',
  tflL7: '澳元价值（仅限整数）',
  ZBFlP3: '澳大利亚的亲戚、朋友或联系人',
  Z2eatOf: '签证申请人目前的海外工作',
  Z17m3XC: '您的课程总学费是多少？',
  '1FRVTj': '在此之前，需要你登录或者注册',
  '2bQfIQ': '澳大利亚签证复印件(如果您持有)',
  '1XR8t2': '编辑学校申请额外要求',
  'apply-form:health-status.option.learning': '学习障碍',
  Z2380V5: '学校佣金比率尚未设置，请先设置',
  Z2pDoiz: '至少提供一个以下的号码',
  '1Fqfm7': '内政部请求ID',
  WKqc1: '请确定文件的分类',
  Z24mgVv: '请添加分支触发追加的步骤',
  '1ieOJv': '请输入正确的医疗保险号',
  '1Q7Pf3': '修改学校数据生效年份成功',
  'apply-form:health-status.option.mental-illness': '精神疾病',
  aILtP: '为什么申请过桥签证',
  '1Keio2': '可以点击上传或者拖拽文件到此处',
  ZQ1kVx: '您是否曾经以其他名称进行过交易？',
  '2hdBlu': '我已阅读并同意上述免责书',
  '7qeka': '详细说明申请人所在位置',
  UXrWV: '提名学生与监护人的关系',
  Z2r0JBF: '确定与该中介解约吗？',
  '2oJXLE': 'GTE 内容是否翻译成中文',
  Zg3TC4: '为客户生成课程规划报告',
  E3apU: '添加学校申请额外要求',
  Zeot1X: '要求里的文件上传数量',
  '1EjKx2': '复制此链接发送到微信',
  ZpIQU1: '账单编号、付款方式配置',
  H0d9D: '信息输入错误，请重新尝试',
  '1VmP26': '该家庭成员是否有有效护照？',
  ZNv6CB: '确认要提交此次签证表格吗？',
  Zp9px1: '为什么您选择澳大利亚的这所城市？',
  '1VOfwV': '文件柜暂无上传文件',
  '246O8g': '确认通过这份付款凭证吗',
  Z268NVj: '确认撤销额外申请吗？',
  Z2f9kmr: '详细说明申请人所在位置',
  '1A4rbR': '文件分类 AI 识别中',
  '2n3BoA': '协助但未提交到 OneULink案件',
  Z2rnUuF: '是否有其它有效护照',
  Z1958nF: '确认不通过这份付款凭证吗',
  ZlDaVe: '设置文件签名时的个人签名',
  ZE70uu: '取消压缩文件将放弃上传文件',
  Z15Irf3: '点击这里可以向该案件添加副申请人',
  '1UEPGs': '审核选中的预积分提现申请',
  ZpCUEO: '您申请的大学或学院叫什么？',
  '22O0DI': '该操作默认不会通知客户',
  ZiGxLg: '输入学校名字、CRICOS Code 搜索',
  Z1n7urc: '提供最近一次英语语言测试的详细情况',
  Z2Afbz: '您打算赴澳后住在哪里？',
  '1iTsW6': '输入课程关键字搜索或者自行输入',
  '2tv2vE': '该客户的推荐人还是原来的',
  Zqn4G8: '员工是否接受过有关 ESOS 立法的培训？',
  pEQT1: '该家庭成员是否有身份证',
  Z1iSKNX: '确定将该专员设为文案专员？',
  h4WX4: '分支触发步骤请关联表单',
  fPluN: '填写创建时相关信息',
  'apply-form:health-status.option.medical-condition': '健康状况',
  Z184x7J: '创建客户表单链接已经生成',
  Z24J8tv: '同时，该客户的推荐人将由',
  '1DgXQS': '该申请人是否为其他国家的公民？',
  pqYq3: '申请人是否持有太平洋-澳大利亚卡',
  'app.right-content-.assistant-back-oneulink': '返回 OneULink',
  ZYztv7: '在您自己国家的家庭状态如何？',
  Zw4Exn: 'OneULink - 个人信息收集表',
  Z2qrwmG: '英语水平测试成绩（如果您持有）',
  'apply-form:entrolment-status.option.intermission': '休学',
  'apply-form:health-status.option.mobility': '行动障碍',
  tvc6E: '列出上一年的三项宣传活动。',
  '1lNy7X': '曾经有过胸部x光片显示异常吗?',
  Zprc0q: '申请人以前是否在澳大利亚学习过？',
  Z1rHnkc: '听/说/读/写/总均分',
  '2wMJ7K': '点击该按钮，前去公司协助',
  '1BkNVd': '可以点击上传或者拖拽文件到此处',
  TxmWb: '申请人护照信息/额外信息',
  '1eNQEI': '输入学校关键字搜索或者自行输入',
  EVgYh: '该家庭成员是否有其他现行护照？',
  Z2aNzJz: '你是否需要校方提供的英语测试？',
  ZQSd7T: '当前页面已失效，请重新登入！',
  iED4m: '申请人是否有海外学生医疗保险?',
  Z9nx0c: '{name} 你好，你的主申请人是 {main}',
  focxp: '宣传页面是否使用公司邮箱',
  Z1z9x6a: '计算半工半课程费用和风险',
  Z3lEvX: '修改后台数据编辑年份成功',
  Z2HvMy: '副申请与主申请人关系',
  '1stXp2': '姓名与当前客户姓名不一致',
  Z1EmnSu: '可以点击上传或者拖拽文件到此处',
  Z1rsTtu: '这是申请人目前的就业情况吗？',
  Z28rmcP: '在客户 [{name}] 的案件完成时发送',
  '40NNE': '申请人之前是否持有澳大利亚签证？',
  '1nT8H6': '任何监禁或其他拘留期间的日期',
  '1NBdSS': '澳洲境外已完成的最高教育水平',
  Z1o0jXt: '确定删除该定时通知吗？',
  Z2bJane: '合同已签署，请查看合同~',
  V82O8: '将评论区文本内容保存为 PDF 文件',
  fng48: '宣传页面是否使用公司电话',
  '1LWjGr': '切换公司请先关闭其它所有标签页',
  '6wS4A': '邮政地址是否与住址相同？',
  '1YYXlB': '该家庭成员是否为其他国家的公民？',
  '2iWWVD': '选择符合条件的签证延期资格课程',
  Z12TPIt: '选择移民局官方文件',
  Z1NgIQk: '专业机构的成员资格或注册：',
  Z2rBA4w: '该家庭成员是否有其他身份证件？',
  Z23RjMp: '该家庭成员是否为18岁以下的儿童？',
  '1LQS2': '请选择申请人打算申请的签证。',
  '2ponaj': '详细说明申请人正在进行的课程',
  Z1VxOSG: '请再次检查相关文件是否是正确的',
  Z2kwyms: '当前文件， 将绑定到 {name} 步骤',
  '1i7CBH': '当前公司数据或者所有数据已经标注完',
  '2bvo5F': '提供家庭成员目前所在位置的详细信息。',
  ZcW1TF: '详细说明申请人的澳大利亚资格',
  '25jWHr': '十年旅行历史 / 澳大利亚联邦警察局核查',
  '20Oyhp': '是否有学生医疗保险?',
  '2fa6IO': '申请人是否有其他的医疗保险?',
  bf1zI: '内政事务部交易参考号码',
  '2ih2Y0': '这个孩子的旅行是否有任何法律障碍？',
  PytKh: '申请人是否有澳大利亚签证批准号？',
  '14UbXw': '提供申请人学历的详细信息。',
  '2sWVSW': '如有关事项涉及刑事定罪，请提供:',
  Z1QtaMH: '申请人计划在澳大利亚停留多长时间?',
  Z2nLHzI: '您是否计算过每周的生活成本？',
  '1Re65s': '您明白学生签证中工作权利的限制吗？',
  eGdLX: '如果要申请新的课程，请联系专员',
  '2txr8E': '请配置触发提交到 OneuLink 步骤',
  Z1TPyhB: '公司名、ABN、电话、邮箱、地址',
  Z2sPhrl: '是否申请过澳洲签证',
  '2iijEP': '提供组织内联系人的详细信息。',
  '10JIre': '该客户姓第一个字母是大写 I',
  ZYHA7F: '请提供该公司内联系人的详细信息',
  ZRCe7l: '申请人在过去 5 年中是否有工作经历？',
  '1e2nqV': '提供申请人最近持有的签证的详细信息。',
  Z1rETzL: '是否有工作签证医疗保险?',
  Z1a50Nc: '申请人是否已申请澳大利亚父母签证？',
  HnL8K: '申请人是否会在澳大利亚进行学习',
  '1vv49m': '确定将该专员设为市场专员？',
  '1jV9yp': '最近上传的资料要求是 28 天以内的',
  Z11Ymdc: '与护照一致，请输入英文输入法',
  Z1IPU8A: '生日与当前客户生日不一致',
  Z2fksQJ: '该申请人是否有澳大利亚签证批签号？',
  Z23BKoe: '若有信息不实将会影响签证',
  Z20r8hQ: '您在澳大利亚学习后向留下来吗？为什么？',
  Gfv63: '未设置学校佣金，暂无可得佣金',
  YEPUJ: '恭喜您！您的合同已提交成功！',
  ZNjWvM: '选择使用哪一个手机号接受短信',
  ZSkneo: '确定向 {email} 发送测试邮件吗？',
  ZUAt3k: '没找到副申请人？立马添加到系统中',
  '1L3VfH': '在澳大利亚期间，与提名学生住在一起；以及',
  '1O1yMb': '输入课程名称、Code搜索打包课程',
  Z1FRj20: '作业提醒（完成和打分），上课提醒',
  Z1spc6X: '选择后，GTE 内容会放在电调文件里',
  Z1AYq9b: '该步骤相关通知是否不抄送',
  ZIYK1: '快捷批量填写入学日期 例如 * * 1',
  '13xy2g': '客户生日以 {timezone} 时区为基准',
  Z23o0dW: '是否在过去十二个月内参加过英语水平测试？',
  Z21pD7J: '过去10年居住地址 / 技能评估',
  ZrSPR4: '选择申请人主修课程的教育类型',
  Z1oUwRq: '您可以在这个课程中学到什么？',
  Z12hqGz: '最后一步不能设置触发步骤，请检查',
  Z1yI2Uq: '请注意：合并后推荐人也将变更。',
  wcsF8: '恭喜您！您的资料已提交成功！',
  Z5uQLb: '有没有申请人被登记在性犯罪者名单上?',
  '1VCaJW': '谁将支付您在澳期间学费和生活费？',
  ZUvXNn: '不允许出现 / \\ : * " < > | ？; 符号',
  Zn1Xsr: '批量转移案件申请提交成功',
  Z1lRq4s: '提供虚假或误导性信息是严重的违法行为。',
  Z1Yndcx: '离申请人目前所在地最近的办事处',
  ZvHRhb: '是否曾有申请人被法院认定不适合辩护?',
  Z2gwajt: '需要提供过往十年旅行跨境旅行经历',
  '2wrvqQ': '是否有其它家庭成员',
  '1vGtXT': '申请人是否要多次入境',
  '175afw': '为客户创建案件，可以按照以下流程走哦',
  Z1aV03S: '有组织的宣传材料和广告样本。',
  rXtMx: '申请人在澳大利亚停留的资金来源',
  Z21lquL: '该申请人是否为所选护照国家的公民？',
  X28B3: '是否有曾用名',
  ZFeUjI: '点击选中后，可以批量发起提现',
  '1A8gyk': '本申请表上的所有申请人都有医疗保险吗？',
  '1Df1Vp': '申请人目前是否持有 485 学习后工作签证？',
  '2v9AMz': '{name} 邀请您加入{company}公司，并担任{job}一职',
  Z3SQ0T: '什么类型的残疾、障碍或长期健康状况',
  Z1HdcFb: '选择当前生效的 OSHC，续买下一个 OSHC',
  '25dPU6': '选择申请人申请的过渡签证类型',
  ZLiogo: '输入金额超过原总价，请选择原因',
  '15BKla': '离境有值得同情或令人信服的理由',
  '1guzmp': '是否有残疾、障碍或长期健康状况?',
  ElphD: '可以向里面添加分类好的文件，方便律师查看下载',
  Z1IIqlr: '确定移除该专员客户查看权限吗？',
  Z2o7Sma: '本次邮件共分为 {count} 封邮件分批发送',
  ZkHjBv: '资金是否由申请人以外的个人提供？',
  Z15jfCV: '申请人是否想申请更长的签证有效期？',
  '2jszOk': '短信验证码发送成功，请注意查收',
  '1Aaa1O': '申请人是否有其他护照或旅行证件？',
  ZwDc7c: '该家庭成员是否为所选护照国家的公民？',
  Z1XRqqU: '什么类型的残疾、障碍或长期健康状况?',
  '4vrlw': '说明完成该课程将如何使申请人受益。',
  '16c7Qf': '是否有过往十年旅行跨境旅行经历？',
  Z10mPmo: '同意不进行超过三个月的学习或培训。',
  '2s75an': '请上传对应{name}分类的文件',
  ZwtkiA: '查看临近户开学、毕业的客户',
  '2lvW8y': '这个孩子是否在主要申请人的照顾和法律监护之下？',
  Z14rhhu: '邮件内容不确定，需要人工判断',
  ZFXE2g: '请检查邮箱为 {email} 的邮箱验证码',
  Z1wroyD: '邮箱验证码发送成功，请注意查收',
  ZW2FT6: '与能处理该类型案件的上级渠道签约',
  '1qxUwQ': '申请人是否打算使用联合国通行证旅行？',
  '1EYmXu': '是否所有申请人都有海外访客医疗保险（OVHC）？',
  '2bwjVm': '课程的开始日期、结束日期存在重复，请检查',
  ZNdIjJ: '提供申请人当前 485 学习后工作签证的详细信息。',
  '19PhXN': '需要住院或吸氧治疗的呼吸系统疾病',
  ZeNO7m: '申请人在完成课程后是否会得到一份工作？',
  '28ip4S': '是否有其他人员与申请人一起前往澳大利亚',
  '27O5zD': '你期待的薪水是多少？请详细阐释预估期待的薪水。',
  ZAS4q2: '客户信息和表单上的信息有出入',
  Z2dkamE: '以下信息缺失，需要客户填写',
  Zhy3tY: '案件关闭后将无法进行操作，请谨慎操作',
  Z1oRTb: '很抱歉，页面不小心迷路了~请刷新后，重新提交！',
  ZHO9l7: '与患有活动性肺结核的家庭成员有密切接触?',
  Z2wjrPe: '家庭单位的副申请人与主申请人的关系',
  '1sTQ2W': '申请人是否有相关学生签证申请的详细信息？',
  '21CtoB': '详细说明申请人的澳大利亚高等教育学历。',
  ZhAzXu: '温馨提示:发送附件大小总计不得超过 10 MB',
  fijoa: '请仔细查看下面的内容，确保信息无误',
  Z1geyuH: '申请人在过去 36 个月内是否参加过英语语言测试？',
  Z2uTeB2: '选择后，相应的文件会放在电调文件里',
  Z2apWHO: '暂无搜索结果，请尝试更换关键字搜索',
  Z1YGncm: '有董事、负责人或主要员工因刑事犯罪被定罪。',
  ngRuJ: '已阅读隐私声明（表格 1442i）中包含的信息。',
  nq7ne: '尚未设置公司时区，请联系公司管理人员',
  '1ky1Nq': '了解访客签证不允许他们在澳大利亚工作。',
  '1aSjt2': '您缴纳了多少钱的学费和学生保险费用？',
  ZPIFLY: '学生是否有残疾、障碍或长期健康状况',
  CuHS9: '可接受案件任何类型，包括后面新增的案件类型',
  ZpAm2N: '在已签约的上级渠道，更改能处理的案件类型',
  Z2wya1x: '* * 1 代表每周星期一\n1 * * 代表每月 1 号',
  ZR8KTL: '已阅读并理解本申请表中提供的信息。',
  '2fzc5j': '没有跟具体的案件绑定，无法进行 CoE 审核结算',
  Z1pPec4: '您和您的家人在中国或其他国家有地产吗？',
  Z9dMs0: '请上传企业微信、微信、Whatsapp 联系二维码',
  Z1dSRBN: '已阅读隐私声明（表格 1442i）中包含的信息。',
  Z1LjFwM: '副申请人客户资料已创建好，请添加为副申请人',
  '2tvBmz': '暂无相关打包课程, 建议尝试更换搜索关键字',
  '29mTro': '{inviteMember} 邀请你加入 {company}，担任 {job} 一职',
  DNPB5: '请检查手机号为 {phone} 的手机短信验证码',
  '1BTvlQ': '正在考虑取消或已取消签证的签证持有人',
  HXfML: '请给出过去24个月内英语考试最高成绩的详细信息。',
  Z1lyn1n: '如果有心仪的公司，可以让公司邀请你加入',
  Z27N8GO: '已完成的最高教育水平',
  Z1twWQr: '过去24个月是否参加英语语言测试?',
  ZdL4Xx: '在已签约的上级渠道，添加当前案件申请的学校',
  Z2tm9lB: '这个家庭成员曾经有任何其他名字吗？',
  ZzIVkh: '对于合并申请，应说明该声明适用于哪一位申请人。',
  '1LbfQE': '支持 CoE、Offer、OSHC、Visa、Passport 以及其它文件',
  Z22K4xS: '待验证订单超时未验证被系统关闭 60 分钟',
  ZPfHe4: '本申请中是否有家庭单位的陪同成员？',
  '1wFBBJ': '申请人是否曾被拘捕令或国际刑警组织通知?',
  ZO8cX1: '申请人在澳大利亚逗留期间是否有医疗保险安排?',
  '1WEAaf': '请详细说明失业时间和最后担任的工作职位',
  Zc1ILg:
    '所有课程的申请表均位于以下页面，为了成功提交申请，您需要按照以下步骤进行操作：',
  '1GNkgE': '在提交申请之前，请确保您能够提供并附上以下文件',
  SEuSb: '如果需要，同意收集他们的指纹和面部图像。',
  '268if3': '申请人在澳洲期间是否会探望亲戚、朋友或联系人',
  eVJiS: '该客户异常，请及时修改客户的邮箱',
  cS14H: '尝试使用过生物指纹登录，现在需要绑定吗？',
  ZoGQ6g: '该文件已经上传过了，是否继续上传到系统？',
  '2iwW5u': '申请人是否曾在任何国家(包括澳大利亚)逾期居留?',
  Z2nYr9O: '暂无相关搜索结果，请输入更准确的邮箱或者手机号',
  '1BzGCU': '详细说明申请人希望提供的任何其他相关信息。',
  Z1zRoFX: '同意在签证停留期届满或之前离开澳大利亚。',
  '1jbNu': '暂无搜素结果，你可以尝试更换关键字再此搜索',
  '8dPQq':
    '隐私邮箱为 OneULink 内部专有邮箱，私密性、安全性强，可用于接收和发送电子邮件，若开启隐私邮箱，您的学校申请表格上将会使用该邮箱，且邮箱中所收到的全部邮件也将会同步转发至您的私人邮箱中。反之，则直接使用个人邮箱接收学校邮件。',
  ZtkJC: '本申请表中的所有申请人是否都有医疗保险或互惠医疗保险？',
  '22q3Qy': '申请人有什么资金可以支持他们留在澳大利亚？详细说明',
  '1C3VUP': '申请人以前是否曾前往澳大利亚或申请过签证？',
  ZQzSLx: '申请人是否曾涉及偷渡或贩卖人口罪行?',
  '4qyJs': '为什么您选择这所大学或学院，而不是在您自己国家就读？',
  Z2gYqwF: '还有步骤尚未上传文件，确认完成案件吗？',
  Z1USQEC: '下面卡片可以点击排序，自定义学习、工作历史',
  '1stBUK': '请从一下两种自动申请提现的方式中选择一种',
  iSnol: '是否有任何其他人对这个孩子有监护权、探视权或监督权？',
  '2e9mxi': '申请人以前是否有用于前往澳大利亚的护照？',
  Zuo7G6: '申请人是否因健康状况需要协助行动或照顾?',
  fNRCv: '{name} 未开启佣金结算，暂无佣金可得',
  Zq85Mk: '收不到验证码？可以尝试切换手机短信验证',
  '2qPPH': '失业包括离开学校/学院/大学后的所有就业空白期。',
  Z200pvl: '长时间 (30天) 未操作，系统自动关闭',
  Z1R9Mvs: '下面是专员是上传过的 CoE ，你可以点击使用来选中',
  ZiidN9: '此表格将由律师审核，请学生确保真实性后签字',
  ZfTcIR: '提供申请人需要在澳大利亚居住的任何重要日期的详细信息',
  ZgHrcv: '过去 3 年按教育部门每年派往澳大利亚的学生人数',
  GNu1z: '过去 3 年按教育部门每年派往澳大利亚的学生人数。',
  KkMxr: '客户系统邮箱指系统内部给客户生成的邮箱',
  '1yQeLl': '该课程是否为申请人在澳大利亚境外学习或培训的延续？',
  Z1E0S8T: '请提供申请人在澳大利亚境外完成的最高学历的详细信息',
  ZFF70F: '学生医疗保险是否由申请人的教育机构购买？',
  ZDi10V: '收不到验证码？可以尝试切换邮箱验证',
  '20PVs6': '申请人是否持有其他除CoE以外的在澳大利亚学习的证明？',
  '2ht7n2': '申请人是否知道首次入境澳洲之后每次入境的日期',
  jrM7r: '申请人是否曾被拒签或取消澳大利亚或其他国家的签证?',
  '1mqQbe': '输入员工手机号、邮箱或者发送邀请链接来查找',
  Z2rJXzb: '提供家庭单位中所有 6 岁以下随行家庭成员的详细信息。',
  ZTJq1Y: '请确保您已阅读每条声明内容，并符合实际情况。',
  Z13B3xX: '注意：确保教育背景页面的详细信息中包含此合格资格。',
  '2uSnV9': '请人是否曾被控任何罪行，目前正在等待法律行动?',
  '2vb6Oi': '签证相关的提名学生在澳大利亚期间居住在澳大利亚；',
  ZqcQUi: '刚才你尝试使用生物指纹登录，现在需要绑定该设备吗？',
  '24VB1p': '当客户录入学习历史、工作经历后，可以为客户生成一份简历',
  ZrKz5P: '获取地理位置信息失败，请手动填写签字地点',
  '1qsUWJ': '输入客户名字、手机号搜索，不得少于2个字',
  XA9JW: '请注意该信息填写仅供参考，需要客户自己再确认一遍的',
  '9ETtK': '申请人是否持有可获得延长两年居留期的特定学位？',
  '1P675t': '客户系统邮箱收到的重要邮件会自动转发给客户',
  Z1qMYvF: '提供虚假或误导性信息是严重的违法行为。 申请人声明：',
  Zih3HO: '客户信息距离上一次提交已经更新过了，请注意检查',
  '24AiwB': '如果您已持有学习后工作替代签证，请提供您当前签证的详细信息。',
  Z2g3SRH: '当客户的偏爱语言是选中的语言时，才会执行该消息任务',
  '1TKsyO': '温馨提示：若您还有疑问，还可添加微信咨询相关事宜！',
  Z18wbyB: '检测到已经上传过签证拒签文件，可以关闭案件了',
  Z2geLR2: '该申请人在过去12个月内是否进行过澳大利亚签证体检？',
  IFq4G: '申请人是否因为其澳大利亚教育机构的关闭而申请此签证？',
  ZKNVeg: '请详细说明申请人完成课程后将寻求的工作类型',
  '20zI62': '确定将消息模板和全局消息任务配置同步到其它公司吗？',
  '1BwCvH': '原文件大小为 {originSize}，压缩后文件大小为 {currentSize}',
  Z1D26oJ: '本申请中是否包括 6 岁以下的随行家庭成员？',
  ZzIGEG: '将与提名学生同住，并为其提供适当的照顾和福利安排。',
  '1jSliO': 'GTE 正在生成中，时间可能会很长，请耐心等待改',
  '2gnnJf': '客户系统邮箱收到的重要邮件不会自动转发给客户',
  Z13uWun: '当前文件柜文件没有任何改变，你可以添加文件或者删除文件',
  Z1S5Aks: '在这里可以一次性上传多个客户材料，点击上传或者拖拽文件到此处',
  '1sXhTx': '申请人是否曾被任何国家(包括澳大利亚)驱逐出境或驱逐出境?',
  Yytu: '当前模式下，会创建新的版本，请填写相应的版本说明',
  '2okaTO': '{name} 佣金结算方式为固定金额，在同一个案件中，只结算一次',
  Z1ykm7I: '该申请人在过去12个月内是否进行过澳大利亚签证的健康检查?',
  Z1DXJpl: '为提名学生提供适当的住宿、一般福利和支持安排。',
  oijJS: '签证过期日期、护照过期日期以 Australia/Melbourne 时区为基准',
  Z2jBIxF: '选择申请人打算在澳大利亚工作期间从事的主要行业类别。',
  ZsFk7L: '申请人有否因精神不健全或精神错乱而被判无罪释放?',
  '7iUwx': '承认如果获得学生签证，他们必须了解并遵守其条件。',
  ZGNKa7: '申请人是澳大利亚公民或澳大利亚永久居民的父母或继父母吗？',
  Z110eUi: '申请人是否持有或目前持有澳大利亚或其他国家的签证?',
  WMQm3: '当前或过去有针对组织、其董事、负责人或关键员工的法律诉讼。',
  Zuh0C9: '在有使用上的疑问可以在此查看，也可以查看更新日志',
  '2rjIov': '已在本表格及其附件中提供了完整和正确的信息。',
  uFw6d: '申请人是否持有任何澳大利亚的入学确认书（CoE）？',
  '9YFbg': '创建企业/团队/组织，作为管理员，邀请团队成员共同使用',
  ZOMy7l: '在该案件中，{name} 可得佣金为 {value}，结算方式为固定金额',
  Z2vEOHV: '检测到当前系统最新版本为 {version}，需要升级后使用。',
  '1XLSb7': '暂无打包课程，输入课程名字、Code 搜索打包课程',
  Z1hPIYL: '您可以继续编辑客户信息，编辑后的信息将被合并至客户',
  ZenDSV: '请注意，需要一个街道地址。邮局地址不能作为公司地址',
  Z134XFq: '请详细说明申请人在上述国家获得的最高学历',
  Z2xlrR: '请注意，必须提供街道地址。邮局地址不能作为组织地址。',
  Zz1a1g: '如果申请人对任何字符声明回答“是”，他们必须提供所有相关细节。',
  '5VCSj': '续买的保险的结束日期晚于 CoE 的结束日期，不需要续买',
  Z1EHRLh: '在十年旅行历史表单里，需要提供过往十年旅行跨境旅行经历',
  '14vLcf': '这是参与中介公司协助，办理但是没有提交到 OneULink 的案件',
  ZYK0YB:
    '您是否在申请流程、取消流程或特定事项方面提供帮助？(请在以下选项中选取)',
  '2nO6iR': '您是否遵守海外学生教育服务 (ESOS) 立法规定的主要义务？',
  amzpu: '申请人是否曾欠澳大利亚政府或任何公共机构债务?',
  '2rfI1a': '申请人以前是否持有受COVID-19相关旅行限制影响的访客签证？',
  Z2iyX5l: '每天介绍 3 个实用功能指南哦 ^^，助你更好、更高效使用系统。',
  Z23fbo8: '当前 Pdf 文件大小为 {size}, 已超出最大文件大小 {max} 的限制。',
  '1EN2RF': '请详细说明申请人自离开学校/学院/大学以来的就业和失业历史。',
  '28STla': '在提交申请前的 6 个月内，是否达到了澳大利亚的学习要求？',
  Z23Ndml: '申请人是否已在澳大利亚境外完成或目前正在接受任何其他学习或培训？',
  ZyQaFL: '简历至少需要一个学习、工作历史哦，请上传学生相关历史',
  '264DH': '请详细说明没有请签发国/地区签发的身份证的原因',
  '26QWGJ': '超出最大文件大小 {max} 的限制，你可以继续压缩或者自行压缩文件',
  '29rSIi': '确认如果获得学生监护人签证，则必须理解并遵守签证条件。',
  '262Flo': '理解并承认该签证附带第 8537 条条件，这意味着申请人必须',
  '2ceUaJ': '为了方便 {company} 更好地为您服务，请点击链接完善您的个人信息吧~',
  c1DNk: '提供详细信息，说明申请人为何在其当前所在地，包括其当前签证的结束日期',
  BrW8S: '提供详细信息，说明申请人为何在其当前所在地，包括其当前签证的结束日期',
  '1oosl':
    '提供详细信息，说明申请人为何在其当前所在地，包括其当前签证的结束日期',
  Z1ebtDO: '申请人是否因相关学生的澳大利亚教育机构关闭而申请此签证？',
  '2ipVdH':
    '提供详细信息，说明申请人为何在其当前所在地，包括其当前签证的结束日期',
  XwQL6: '提供所有申请人自年满 16 周岁起过去 10 年的所有住址详情。',
  Z1BPTaO: '需要向专员提供过往十年旅行跨境旅行经历相关资料',
  ZeooWc:
    '忘记密码了？不用担心，只需在下面输入您的电子邮件，我们就会给您发送给一个临时密码',
  Sq6nx:
    '感谢您对我们工作的支持！具体相关事宜， 我们的工作人员将会在48小时之内尽快与您联系！',
  '1gFIyg': '海外学生医疗保险（OSHC）不被视为适用于本签证的适当医疗保险。',
  HJQyo: '提供支持申请人满足标准的能力的答复，并确保附上证明文件。',
  Z1QOaIH:
    '感谢您对我们工作的支持！具体相关事宜，我们的工作人员将会在48小时之内尽快与您联系！',
  Z9YBzK: '申请人在澳大利亚期间是否打算入住医院或医疗机构(包括养老院)?',
  Z1867c9: '申请人是否正在申请多次居留签证，每次访问最多可停留 12 个月？',
  '12Fnly': '申请人是作为外国政府代表旅行，还是持有联合国通行证旅行?',
  Z2wFY20: '注意：确保申请人在申请摘要页面上提供合格资格的详细信息。',
  Z1XBy99: '提供两名来自与您有代理协议的澳大利亚学院/学院/大学的推荐人。',
  '5jfA6': '您就其事项提供移民协助的其他客户的姓名（例如：受抚养的申请人）',
  '1jhrEY': '申请人在澳洲期间是否打算工作或学习成为医生、牙医、护士或护理人员?',
  Zgly1H:
    'CoE {coeNumber} [{date}] 与 CoE {otherCoENumber} [{otherDate}] 存在重合问题',
  ZGhmJL: '申请人是否曾与曾经或正在从事犯罪行为的个人、团体或组织有关联?',
  Z1cGw4e:
    '我正在申请的学校是墨尔本商业科技学院，英文名是Melbourne College of Business and Technology。',
  '29AaJ5':
    '该客户的生日、邮箱在系统里已经存在，需要选择已经存在的客户进行合并',
  '2qxv3T': '澳洲联系地址、电话和国内联系地址、电话需填写其中一个',
  '1ci146': '客户澳洲联系电话、澳洲联系地址或者国内联系地址、国内联系电话',
  '1C1BB2': '该中介 {name} 与上级渠道签了约，但是上级渠道不能处理该类型案件',
  Z1gmSFL:
    '这里是很长时间没有更新的案件，可能是忘记更新流程或者忘记关闭案件了。',
  Z26tqfz: '当前批次数据已打标完，你可以点击确定按钮，会自动刷新当前页面',
  Z2aDECg: '该中介 {name} 暂无于任何上级渠道签约，请与相应的上级渠道签约',
  '1ssg3X': '申请人是否已向相关评估机构申请评估其指定技术职业的技能？',
  Z1poP5A: '申请人是否在澳大利亚联邦政府批准的培训计划下接受部分或全部资助？',
  Z1yxJAQ: '提供申请人自首次抵达澳大利亚之日起在澳大利亚学习的详细历史记录。',
  Z2cIbxt: '详细介绍申请人目前的情况，包括与家庭、社区、就业和经济状况的联系。',
  '1RgU7w': '申请人有否因涉及儿童的性罪行而被判有罪(包括没有定罪记录的情况)?',
  Z2mL8ws:
    '当客户未填写签证表单时，可以通过上传移民局签证表单文件，快速填写表单',
  '2kNsbO': '可以查看其他同事往里面添加分类好的文件，也可以打包下载所有文件',
  Z1a9Kl6:
    '申请人或本申请中包含的任何人员是否持有或目前持有澳大利亚或任何其他国家的签证？',
  '1IvVYy': '申请人是否曾在任何国家被定罪(包括现已从官方记录中删除的定罪)?',
  '1zx3E4':
    '请理解，如果他们无法获得进一步的签证，他们必须在学生签证终止日期或之前离开澳大利亚。',
  '6o6ke': '文件下载次数已超过限制，需要输入邮箱验证码验证方可继续下载文件',
  Z133l6I:
    'Websocket 连接失败，会影响到你正常使用系统，请通知管理员或者刷新浏览器',
  Zx7oYa: '提供以前可能用于前往澳大利亚的任何其他非联合国护照或旅行证件的详情',
  '1HLcUL': '签证电调文件请求生成成功，请耐心等待几分钟后到案件评论区查看',
  Z2peIja: '文件下载次数已超过限制，需要输入手机验证码验证方可继续下载文件',
  qM1k7: '详细说明给予 6 岁以下家庭成员学生监护人签证的令人同情和信服的理由',
  '10bPPH':
    '紧急联系人姓名、与紧急联系人关系、紧急联系人所在地、紧急联系人电话，必须填写',
  ojWdz: '积分余额达到此数值后自动申请提现，请输入不低于 500 的积分数',
  ZTwWRg:
    '所有申请人在澳大利亚逗留期间是否全部或部分享有互惠医疗保健协议（RHCA）的保障？',
  tropT: '如果提名学生未满 18 周岁，替代安排须经提名学生的教育机构批准。',
  Z2rirqs:
    '感谢您对我们工作的支持！具体相关事宜，\n我们的工作人员将会在48小时之内尽快与您联系！',
  Z1XtgVj:
    '在计划访问澳大利亚期间，申请人是否预计会产生医疗费用，或需要治疗或医疗后续治疗:',
  Z16al1T:
    '相关学生申请人是否在澳大利亚联邦政府批准的培训计划下接受部分或全部资助？',
  Z2612FV:
    '在过去 10 年中，申请人中是否有人在年满 16 岁后去过常住国以外的任何国家？',
  z39x3: '主申请人的家庭单位中是否有除了副申请人以外的家庭成员？',
  '2k3vMM':
    '在过去 12 个月中，所有 16 岁或以上的申请人是否都向澳大利亚联邦警察局申请过犯罪记录检查？',
  '2dgPqA':
    '申请人是否曾在军队、警察部队、国家/私人民兵或情报机构(包括秘密警察)服役?',
  '17eCPK':
    '申请人是否有非澳大利亚公民或澳大利亚永久居民的家庭成员不前往澳大利亚？',
  '3tg8t':
    '请理解，如果此申请获得批准，则此申请中未包括的任何人都不会自动获得进入澳大利亚的权利。',
  '1PRFrG':
    '尊敬的客户你好，本系统将于 {start } 更新系统，预计 {end} 结束，给你造成的不便尽请联谅解',
  Z1j2w1L:
    '请理解，如果此申请获得批准，任何未包含在此申请中的人员将不会自动获得进入澳大利亚的权利。',
  '2oLx2G':
    '申请人是否曾在澳大利亚或任何其他国家未遵守签证条件或在许可的逗留期限外离开?',
  '23lt7a':
    '请注意，如果在授予签证后发现文件有欺诈性或信息不正确，签证随后可能会被取消。',
  '23TpIt':
    '请理解，如果在获得签证后发现文件是欺诈性的或信息不正确，签证可能随后被取消。',
  Z1gy5Na: '在提交申请前的 24 个月内，申请人是否已在指定教育机构获得认可学历？',
  Z2qgdRb:
    '所有学生签证申请人都必须提供申请支持信息，以证明他们符合 真正的学生标准。',
  ZtVp2Q:
    '就业包括所有有偿就业、自营职业、家庭企业就业、工作经验、实习、无偿就业和志愿者工作。',
  Z2pS97h: '申请人是否曾接受军事/准军事训练、武器/炸药或化学/生物制品制造训练?',
  '1AWPf9':
    '客户填写签证信息表单后，可以利用该工具事先写一篇 GTE，注意该文章仅供参考',
  '2oN3M3':
    '已阅读并理解网站 (www.studyinaustralia.gov.au) 上提供的有关在澳大利亚生活和学习的信息。',
  ZuQWpb:
    '说明申请表中的每位申请人如何在澳大利亚维持生计，以支付生活费、学费和学校费用。请选择适用的选项。',
  '1DYDO9':
    '为什么你考虑在澳洲完成学习后回国工作？换种说法，为什么澳大利亚学位会对你回国后工作有帮助？',
  '1R8BlH':
    '当前客户的保险有效日期覆盖了课程的保险有效日期，不需要续买 OSHC，或者可以取消购买 OSHC',
  Z2etql1:
    '当案件是进行中时，因为某些原因，可以挂起案件，等待后面处理，挂起后也可以恢复为办理中',
  Z1DJGBg:
    '已为提名学生的住宿、一般福利和其他支持做出替代安排，直至持有人返回澳大利亚；以及',
  ZDsTJ5:
    '识别到你上传了 {label} 文件，可能是上传错了，你可以选择到客户详情上传对应的文件',
  '2u55Q8':
    '在过去五年中，申请人是否连续三个月以上在护照所在国以外访问或居住?不包括在澳大利亚的时间?',
  rP8QQ:
    '申请人是否曾直接或间接参与或与可能对澳大利亚或其他国家的国家安全构成威胁的活动有关联?',
  Z1opxcp:
    '请理解，如果对该签证施加不再停留 8503 条件，将限制在签证授权停留期后留在澳大利亚的能力。',
  Z1TPCtj:
    '当客户填写完学生签证信息表单后，可以生成一份整理后的客户资料文件，帮助客户更好地应对移民局签证电调',
  Z1URSev:
    '再次压缩后仍然超出最大文件大小 {max} 的限制，请自行压缩文件，建议拆分 Pdf 文件后上传',
  iKOlt:
    '申请人或本申请中包含的任何人员是否曾被拒绝入境或继续在澳大利亚或任何其他国家停留，或被取消签证？',
  Z2t8Nus:
    '最重要的是要了解您的签证申请中包含了什么内容。您的面试答案必须与您在签证申请中所提供的材料内容一致。',
  Z17lJm4:
    '该客户与系统中已存在的客户重复，请选择重复客户，选择后将把该客户信息合并至选中的客户。',
  ZIkvVa:
    '如果客户提供了移民局官方生成的签证表格文件，可以在此上传该文件，一键帮客户填写好信息',
  '1t2OmC':
    '同意该部门使用为 1958 年移民法或 2007 年公民法而获得的申请人的生物识别、传记和犯罪记录信息。',
  '2kwUSQ':
    '在申请学生签证之前的两年内，申请人是否成功完成了高中教育证书（在澳大利亚用英语授课）的要求？',
  ZuJ49q:
    '申请人是否曾被指控或起诉:种族灭绝罪、战争罪、反人类罪、酷刑、奴役或其他引起严重国际关注的罪行?',
  '1VIpyY':
    '太平洋-澳大利亚卡是通过邀请程序发给太平洋地区领导人的。如果申请人没有持有太平洋-澳大利亚卡，请在这个问题上选择 "否"。',
  qISyW:
    '申请人或本申请中包含的任何人员是否曾经在澳大利亚或任何其他国家，但不符合签证的条件或在其授权的停留期之外离开?',
  '9MGe6':
    '申请人是否曾受到审裁处、法院或其他类似当局为保护他人而发出的家庭暴力或家庭暴力令，或任何其他令?',
  '13k715':
    '在联邦或外国政府（视情况需要）不反对他们获得进一步签证以留在澳大利亚的情况下，获得进一步的学生签证（500 类）。',
  Z2tfUDY:
    '申请人是否曾在海外或澳洲参与暴力组织或暴力行为(包括战争、叛乱、争取自由、恐怖主义、抗议)?',
  Z1mKeTa:
    '尊敬的客户你好，为了确保你的个人信息安全，需要验证你的相关个人信息，请点击下一步以进行相关验证。',
  Z6mRvT:
    '祝贺您将赴澳大利亚开始一段令人激动的学习之旅。对于签证申请以及在签证过程中遇到的问题，请与您所指定的注册招生代理进行沟通。',
  Z1FDGQX:
    '已为他们在澳大利亚逗留期间做出充分的健康保险安排，并承认他们在澳大利亚作为学生签证持有人时必须维持这些安排。',
  Z2tjO0h:
    '申请人是否有足够的资金支持他们在澳洲的学习,并了解可能会被要求提供进一步的资金证明？',
  ZHJaD7:
    '每位年满 18 周岁或以上的申请人都已阅读或听取过澳大利亚政府提供的有关澳大利亚社会和价值观的信息，并同意澳大利亚的价值观声明。',
  KtiDY:
    '每位年满 18 岁或以上的申请人都已阅读或已向他们解释过澳大利亚政府提供的有关澳大利亚社会和价值观的信息，并同意澳大利亚价值观声明。',
  kkWk0:
    '或者，如果您正在申请毕业生工作替代签证或学习后工作替代签证，请提供您之前持有的受 COVID-19 旅行限制影响的 485 签证的详细信息。',
  '1NjEfF':
    '了解该部门可能会按照隐私声明（表格 1442i）中的规定收集、使用和披露申请人的个人信息（包括生物识别信息和其他敏感信息）。',
  ZOxmBt:
    '解释申请人希望在澳大利亚通过该教育机构学习该课程的原因，包括对所选课程要求的理解，以及在澳大利亚学习和生活的情况。',
  ZrVo4I:
    '了解学生签证是临时签证，获得学生签证并不能保证他们有资格获得进一步的签证留在澳大利亚，包括技术移民签证。',
  '2qFJW2':
    '在 2020 年 2 月 1 日至 2021 年 12 月 15 日期间，申请人在澳大利亚境外持有学生签证时，是否在线完成了符合资格的学习?',
  Z2rUtPM:
    '已为其在澳大利亚逗留期间的医疗保险做好充分安排，并承认作为学生监护人签证持有者，在澳大利亚逗留期间必须保持这些安排。',
  Z1DrU2p:
    '请注意：您对此调查问卷的答复以及随后与我们达成的任何协议都是您的组织与巴顿学院之间的协议。该合作者以及任何其他合作者必须以书面形式注明。',
  Z1Wrsx4:
    '申请人是否在澳大利亚、加拿大、新西兰、南非共和国、爱尔兰共和国、英国和/或美国成功完成了至少5年的学习（英语语言）？',
  ZGdcXO:
    '了解 8534 签证条件的影响是无法申请在学生签证授权的日期之后留在澳大利亚，并且他们将被要求在该日期或之前离开澳大利亚。',
  ZqTyiA:
    '请您在正式签名之前仔细阅读以下承诺，并确保您已充分理解和同意其中的内容，如果您对以下说明有任何疑问或意见，请随时与我们联系。',
  uTr72:
    '理解并承认该签证附带第 8538 条条件，这意味着申请人不得在没有该签证相关提名学生的情况下离开澳大利亚，除非他们已向教育部门提供以下证据',
  rB063:
    '在考虑申请期间，如果申请人发现情况发生变化（包括地址变更）或与在本申请中提供的信息有关的任何变化，将立即以书面形式通知部门。',
  Z1NpR1g:
    '注意：已经包含在上述入学确认书部分的课程不需要添加录取通知书。同时为同一门课程添加CoE和录取通知书是不必要的，可能会导致处理申请的延迟。',
  ZJc4Kk:
    '同意澳大利亚执法机构向本部门披露申请人的生物识别、传记和犯罪记录信息，以帮助识别申请人、确定是否有资格获得签证并用于执法目的。',
  zBJN7:
    '当他们意识到情况发生变化（包括地址变更），或者如果他们在本申请中或随本申请提供的信息有任何变化时，将立即以书面形式通知该部门，同时正在考虑该申请。',
  '1Td1ES':
    '根据之前的经验，我们建议专员协助客户填写完成前两页信息的，点击保存后让客户继续填写的。因为前两页是问题最多的页面。',
  Zom0Mr:
    '根据系统签证表格信息填写好移民局官方表格后，可以用该工具校验移民局生成的表格文件，是否与客户的姓名、生日、护照号是否一致',
  Z5c0t0:
    '客户联系邮箱改版，为了给学校提供的邮箱可以选择使用系统邮箱，让客户不再错过重要邮箱，如缴费单通知，欠费提醒、作业提醒（完成和打分）',
  '18Ossr':
    '温馨提示：为了防止与您失联，建议您至少填写一种社交账号联系方式，以便专员能够更快速地与您取得联系，我们将会对您的个人信息严格保密。',
  '1LUwYv':
    '对上诉问题的回答表明，该家庭成员没有公民身份，也不是无国籍。如果不正确，请修改以上答案。如果这是正确的，请详细说明为什么该家庭成员不是任何国家的公民或无国籍',
  Z12ypEU:
    '距离您上次更新个人信息已经超过5个月了。为了确保您的案件能够得到更有效的处理，请尽快更新您的个人信息。若您近期已更新，请忽略此提醒。感谢您的配合！',
  '2rNyHc':
    '注意：如果进一步停留的请求将导致某些持有访客、工作假期和过桥签证的申请人在澳大利亚逗留超过12个月，则他们必须证明他们有继续逗留的特殊原因。提供所有详细信息',
  VI2Qw:
    '在申请学生签证前的两年内，申请人是否以学生签证持有人的身份，成功完成了澳大利亚学历框架中四级或更高学历课程的主要部分（在澳大利亚用英语授课）？',
  Z2rW22R:
    '了解 8534 签证条件的效果是，除了进一步获得学生监护人签证外，申请人将无法申请在此签证授权日期之后继续留在澳大利亚，申请人必须在该日期或之前离开澳大利亚。',
  '1KFFhW':
    '我明白，如果我的签证失效并且我当时没有持有其他签证留在澳大利亚，根据 1958 年移民法，我将成为非法非公民。因此，我将被要求离开澳大利亚 澳大利亚，并根据 1958 年移民法被驱逐出境。',
  lYxY2:
    '注：在大多数情况下，申请人的家庭成员不能作为该签证的一部分随同来澳。他们的年龄必须在 6 岁以下，而且申请人需要提供令人同情和信服的理由，才能为其家庭成员中的子女签发学生监护人签证。',
  '2cs621':
    '了解 8534 签证条件将适用于本签证，并将以条件代码 8534 和 "不得继续逗留 "的简短描述来表示。申请人确认，这意味着在澳大利亚期间，他们只能获得有限的签证，包括进一步的学生监护人签证。',
  kz9b:
    '请理解，如果在本申请中提供了任何欺诈性文件或虚假或误导性信息，或者如果任何申请人未能使部长满意其身份，则申请可能会被拒绝，申请人及其家庭成员可能无法在指定的时间内获得签证。',
  Z24SrRg:
    '理解，如果需要提供他们的指纹和面部图像，本部门持有的申请人的指纹和面部图像以及个人信息可能会提供给澳大利亚执法机构，以帮助识别申请人并确定是否有资格获得所申请的签证 ，以及用于执法目的。',
  mjQSf:
    '了解如果 8534 签证条件强加于学生签证，条件代码 8534 和简短描述 No Further Stay 将表明。他们承认这意味着 8534 条件已强加于他们的签证上并且在澳大利亚期间 他们将只能获得有限的签证，包括：',
  '2d2U7f':
    '了解如果 8535 签证条件施加在学生签证上，将由条件代码 8535 和简短描述 No Further Stay 表明。 他们承认，这意味着 8535 条件已加在他们的签证上，而在澳大利亚期间，他们将仅有权获得有限的签证，包括：',
  ZagYJG:
    '，同意我在本教育代理简介中向巴顿学院提供的信息以及提交时间均真实、正确。我了解巴顿学院将根据我提供的信息对该组织作为教育代理的能力进行评估。我声明我以前没有任何不道德的营销行为。',
  '1WwKxp':
    '了解 8535 签证条件的影响是不可能在学生签证授权的日期之后留在澳大利亚，除非他们在联邦或外国政府（作为 情况需要）不反对他们进行相关的学习课程，并且他们将被要求在授权日期或之前离开澳大利亚。',
  Z1eto3G:
    '您的签证申请程序将要求您提供一些文件，您也可能被要求参加电话面试。面试由签证官进行。最好的方式是在电话面试之前，做好相关的准备工作。签证官会要求您阐述赴澳大利亚留学的学习计划，以及在澳大利亚您如何安排生活，您的学习经历对未来的职业规划有什么影响等相关问题。',
};
