import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { createPortal } from 'react-dom';
import styles from '../index.less';

export enum EmailEditorModeEnum {
  FIXED,
  FULLSCREEN,
}

const EmailEditorModal: React.FC<{
  visible?: boolean;
  onClose?: () => void;
  title?: string;
  initialMode?: EmailEditorModeEnum;
}> = ({ visible, onClose, title, children }) => {
  const modalContent = (
    <div className={styles.container}>
      <div className={styles.header}>
        <div title={title} className={styles.title}>
          {title}
        </div>
        <div className={styles.extra}>
          <Button onClick={onClose} icon={<CloseOutlined />} type="text" />
        </div>
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );

  return (
    <>
      {visible &&
        createPortal(
          <div className={styles.fixed}>{modalContent}</div>,
          document.body,
        )}
    </>
  );
};

export default EmailEditorModal;
