import { Modal } from 'antd';
import { modalLayout } from '@/config';
import TodoDetail from './TodoDetail';
import styles from './TodoViewer.less';

const TodoViewer: React.FC<
  {
    id?: number;
    visible: boolean;
    onCancel?: () => void;
    onRefresh?: () => void;
  } & Pick<React.ComponentProps<typeof TodoDetail>, 'scope' | 'scopeId'>
> = ({ visible, id, onCancel, onRefresh, scope, scopeId }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      {...modalLayout}
      width={1400}
      className={styles.modal}
      destroyOnClose
    >
      {id ? (
        <TodoDetail
          scope={scope}
          scopeId={scopeId}
          id={id}
          onRefresh={onRefresh}
        />
      ) : (
        <div style={{ minHeight: 300 }} />
      )}
    </Modal>
  );
};

export default TodoViewer;
