import {
  DatabaseOutlined,
  FileProtectOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Menu, Space, Spin } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { history, useIntl, useModel } from 'umi';
import { logout, removeToken } from '@/auth';
import { CustomAvatar } from '@/common';
import { AccountType } from '@/data';
import { ENV } from '@/env';
import {
  ExtraPermissionEnum,
  useAccessable,
  useExtraPermissions,
} from '../Accessable';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';

const AvatarDropdown = () => {
  const { initialState } = useModel('@@initialState');
  const { formatMessage } = useIntl();
  const { currentUser } = initialState ?? {};
  const loading = !currentUser;
  const hasLogAccessable = useAccessable('front.member.log.list');
  const fileSignExtraAccessable = useExtraPermissions(
    ExtraPermissionEnum.FileSign,
  );

  if (loading) {
    return (
      <span className={`${styles.action} ${styles.account}`}>
        <Spin
          size="small"
          style={{
            marginLeft: 8,
            marginRight: 8,
          }}
        />
      </span>
    );
  }

  const extraMenus: ItemType[] = [];

  extraMenus.push({
    icon: <SettingOutlined />,
    label: formatMessage({ id: 'app.right-content.user-settings' }),
    key: 'settings',
  });

  if (hasLogAccessable) {
    extraMenus.push({
      icon: <DatabaseOutlined />,
      label: formatMessage({ id: 'menu.account.log' }),
      key: 'log',
    });
  }

  if (fileSignExtraAccessable) {
    extraMenus.push({
      key: 'file-sign',
      icon: <FileProtectOutlined />,
      label: formatMessage({ id: 'menu.account.file-sign' }),
    });
  }

  const menuHeaderDropdown = (
    <Menu
      className={styles.menu}
      selectedKeys={[]}
      onClick={(event) => {
        const { key } = event;
        if (key === 'logout') {
          if (initialState?.accountType === AccountType.ASSISTANT) {
            removeToken();
            window.location.href = ENV.origin;
          } else {
            logout(currentUser.companys.length > 0);
          }
          return;
        }
        history.push(`/account/${key}`);
      }}
      items={[
        {
          icon: <LogoutOutlined />,
          key: 'logout',
          label:
            initialState?.accountType === AccountType.ASSISTANT
              ? formatMessage({
                  id: 'app.right-content-.assistant-back-oneulink',
                })
              : formatMessage({ id: 'app.right-content.logout' }),
        },
        ...extraMenus,
      ]}
    />
  );

  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <Space size={4} align="center" className={styles.account}>
          {currentUser?.information.avatar && (
            <CustomAvatar size="small" src={currentUser?.information.avatar} />
          )}
          <span className={styles.name}>
            {currentUser?.information.nickname}
          </span>
        </Space>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
