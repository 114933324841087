import { useMemo } from 'react';
import useFormatMessage from '@/hooks/useFormatMessage';

export enum CoeStatus {
  PROCESSING = 1,
  CANCELLED = 2,
  TRANSFERRED = 3,
  NO_TARTED = 4,
  COMPLETED = 5,
}

export const useCoeStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum = {
      [CoeStatus.PROCESSING]: {
        status: 'processing',
        text: /* @message.en-US In Effect */ formatMessage('生效中'),
      },
      [CoeStatus.CANCELLED]: {
        status: 'error',
        text: /* @message.en-US Cancelled */ formatMessage('被取消'),
      },
      [CoeStatus.TRANSFERRED]: {
        status: 'warning',
        text: /* @message.en-US  Transferred  */ formatMessage('中途转学'),
      },
      [CoeStatus.NO_TARTED]: {
        status: 'default',
        text: /* @message.en-US No started  */ formatMessage('未开始'),
      },
      [CoeStatus.COMPLETED]: {
        status: 'success',
        text: /* @message.en-US Completed  */ formatMessage('已结束'),
      },
    } as const;

    return new Map(
      Object.entries(statusEnum).map((item) => [
        Number(item[0]) as CoeStatus,
        item[1],
      ]),
    );
  }, [formatMessage]);
};
