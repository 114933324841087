import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum CoePaymentStatus {
  WAITING = 1,
  PASSED = 2,
  REJECTED = 3,
  REVOKED = 5,
}

export const useCoePaymentStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () =>
      new Map([
        [
          CoePaymentStatus.WAITING,
          {
            text: formatMessage('待审核'),
            status: 'processing',
          },
        ],
        [
          CoePaymentStatus.PASSED,
          {
            text: formatMessage('审核通过'),
            status: 'success',
          },
        ],
        [
          CoePaymentStatus.REJECTED,
          {
            text: formatMessage('审核不通过'),
            status: 'error',
          },
        ],
        [
          CoePaymentStatus.REVOKED,
          {
            text: formatMessage('已撤销'),
            status: 'warning',
          },
        ],
      ] as const),
    [formatMessage],
  );
};
