import { useUpdateEffect } from 'ahooks';
import { Button, Input, Space } from 'antd';
import type { TextAreaRef } from 'antd/lib/input/TextArea';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useFormatMessage } from '@/hooks';
import styles from './TodoInputEdit.less';

const TodoInputEdit: React.FC<{
  content?: string;
  onConfirm?: (value: string) => void;
  className?: string;
  style?: React.CSSProperties;
  wrap?: boolean;
  emptyText?: string;
  required?: boolean;
}> = ({
  className,
  onConfirm,
  style,
  wrap,
  emptyText,
  required,
  content = '',
}) => {
  const [value, setValue] = useState(content);
  const ref = useRef<TextAreaRef>(null);
  const { formatMessage } = useFormatMessage();
  const [edit, setEdit] = useState(false);

  const handleConfirm = () => {
    const target = value?.trim();

    if (!target && required) {
      setValue(content);

      return;
    }

    onConfirm?.(target);
    setEdit(false);
  };

  useUpdateEffect(() => {
    setValue(content);
  }, [content]);

  return (
    <div className={classNames(styles.container, className)} style={style}>
      <Input.TextArea
        ref={ref}
        onPressEnter={(evt) => {
          if (!wrap) {
            evt.preventDefault();
            handleConfirm();
            ref.current?.blur();
          }
        }}
        onFocus={() => {
          if (wrap) {
            setEdit(true);
          }
        }}
        value={value || ''}
        placeholder={emptyText}
        onChange={(evt) => setValue(evt.target.value)}
        onBlur={() => {
          if (!wrap) {
            handleConfirm();
          }
        }}
        className={styles.input}
        bordered={false}
        autoSize
      />

      {wrap && edit && (
        <div className={styles.actions}>
          <Space>
            <Button
              onClick={() => {
                setEdit(false);
                setValue(content);
              }}
              type="text"
            >
              {formatMessage('取消')}
            </Button>
            <Button onClick={handleConfirm} type="primary">
              {formatMessage('保存')}
            </Button>
          </Space>
        </div>
      )}
    </div>
  );
};

export default TodoInputEdit;
