import { BellOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { MessageTabs } from '../Message';
import styles from './index.less';

const MessageDropdown = () => {
  return (
    <MessageTabs>
      {(unread) => (
        <span className={styles.action}>
          <Badge count={unread} offset={[-10, 0]}>
            <BellOutlined
              style={{
                paddingLeft: 4,
                paddingRight: 4,
              }}
            />
          </Badge>
        </span>
      )}
    </MessageTabs>
  );
};

export default MessageDropdown;
