import { DownOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Tag } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { services } from '@/api';
import { TodoStatus } from '@/data';
import { useFormatMessage } from '@/hooks';
import TodoStatusDropdown from './TodoStatusDropdown';
import styles from './TodoStatusTag.less';
import useTodoStatusTagConfigs from './useTodoStatusTagConfigs';

export const TodoSimpleStatusTag: React.FC<{ status: TodoStatus }> = ({
  status,
}) => {
  const tagConfigs = useTodoStatusTagConfigs();
  const targetConfig = tagConfigs[status];
  const { formatMessage } = useFormatMessage();

  const deprecatedStatusEnum = {
    open: formatMessage('打开'),
    reopen: formatMessage('重新打开'),
  };

  return targetConfig ? (
    <Tag
      color={targetConfig.color}
      style={{
        borderColor: targetConfig.color,
        color: targetConfig.plain ? targetConfig.color : undefined,
      }}
      className={classNames(
        styles.simpleTag,
        targetConfig.plain && styles.plain,
      )}
    >
      {targetConfig.text}
    </Tag>
  ) : status in deprecatedStatusEnum ? (
    <Tag>{deprecatedStatusEnum[status]}</Tag>
  ) : (
    <span>-</span>
  );
};

const TodoStatusTag: React.FC<{
  status: TodoStatus;
  id: number;
  onSuccess?: () => void;
  className?: string;
  renderTag?: (status: TodoStatus, visible: boolean) => React.ReactElement;
}> = ({ status, id, onSuccess, className }) => {
  const tagConfigs = useTodoStatusTagConfigs();
  const { run: update } = useRequest(services.todo.updateTodo, {
    manual: true,
    onSuccess,
  });

  const targetConfig = tagConfigs[status];

  return targetConfig ? (
    <TodoStatusDropdown
      value={status}
      onChange={(next) => {
        update({ id, state: next.status, deadline: next.deadline });
      }}
    >
      {(visible) => (
        <span
          className={classNames(styles.container, className)}
          tabIndex={0}
          role="button"
        >
          <Tag
            color={targetConfig.color}
            style={{
              borderColor: targetConfig.color,
              color: targetConfig.plain ? targetConfig.color : undefined,
            }}
            className={classNames(
              styles.tag,
              targetConfig.plain && styles.plain,
            )}
          >
            {targetConfig.text}
          </Tag>
          <DownOutlined
            className={classNames(styles.icon, visible && styles.opened)}
          />
        </span>
      )}
    </TodoStatusDropdown>
  ) : null;
};

export default TodoStatusTag;
