import { usePersistFn } from 'ahooks';
import { Reducer, useCallback, useReducer } from 'react';
import { noop } from '@/types';

interface ModalFormActionState<E, K> {
  visible: boolean;
  id: K;
  extraParams?: E;
}

enum Action {
  OPEN,
  CLOSE,
}

interface ReducerAction<E, K> {
  type: Action;
  payload?: {
    id: K;
    extraParams?: E;
  };
}

const reducers = <E extends object, K extends string | number>(
  state: ModalFormActionState<E, K>,
  action: ReducerAction<E, K>,
) => {
  switch (action.type) {
    case Action.CLOSE:
      return {
        ...state,
        visible: false,
        extraParams: undefined,
        id: 0 as K,
      };
    default:
      return {
        ...state,
        ...action.payload,
        visible: true,
      };
  }
};

const useModalFormActions = <
  E extends object = AnyObject,
  K extends string | number = number
>(
  params?: {
    onOk?: (...args: any) => void;
    onCancel?: () => void;
  },
  initialState?: {
    visible?: boolean;
    id?: K;
    extraParams?: E;
  },
): [
  {
    open: (id?: K, extraParams?: E) => void;
    onCancel: () => void;
    onOk: () => void;
  } & Omit<ModalFormActionState<E, K>, 'extraParams'>,
  E | undefined,
] => {
  const [state, dispatch] = useReducer<
    Reducer<ModalFormActionState<E, K>, ReducerAction<E, K>>
  >(reducers, {
    visible: !!initialState?.visible,
    id: initialState?.id || (0 as K),
    extraParams: initialState?.extraParams,
  });

  const onOk = usePersistFn(params?.onOk || noop);
  const onCancel = usePersistFn(params?.onCancel || noop);

  const handleShow = useCallback(
    (id?: K, extraParams?: E) => {
      dispatch({
        type: Action.OPEN,
        payload: {
          id: id || (0 as K),
          extraParams,
        },
      });
    },
    [dispatch],
  );

  const handleOk = useCallback(
    (...args: any) => {
      onOk(...args);
      dispatch({
        type: Action.CLOSE,
      });
    },
    [dispatch, onOk],
  );

  const handleCancel = useCallback(() => {
    dispatch({
      type: Action.CLOSE,
    });
    onCancel();
  }, [dispatch, onCancel]);

  const { extraParams } = state;

  return [
    {
      visible: state.visible,
      id: state.id,
      open: handleShow,
      onCancel: handleCancel,
      onOk: handleOk,
    },
    extraParams,
  ];
};

export default useModalFormActions;
