import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { history } from 'umi';

const env = APP_ENV;

export default function initSentry() {
  if (env === 'development' || env === 'test') {
    return;
  }

  Sentry.init({
    dsn:
      'https://de60a03239554ba9bd2fb1c932f8aecb@o1325506.ingest.sentry.io/6586893',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: env,
    release: SENTRY_RELEASE,
  });
}
