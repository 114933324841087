import { useControllableValue } from 'ahooks';
import { Button, DatePicker } from 'antd';
import { omit } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { DATE_FORMAT } from '@/config';
import { useFormatMessage } from '@/hooks';

const getNow = (precision: 'hour' | 'minute' | 'day') => {
  const m = moment();

  if (precision === 'hour') {
    m.set('hour', m.hour() + 1).set('minute', 0);
  } else if (precision === 'minute') {
    m.set('minute', m.minute() + 10);
  } else {
    m.day(m.day() + 1)
      .set('hour', 0)
      .set('minute', 0);
  }

  m.set('second', 0);

  return m;
};

const AbsoluteDate: React.FC<
  React.ComponentProps<typeof DatePicker> & {
    renderToFooter?: boolean;
    precision?: 'hour' | 'minute' | 'day';
  }
> = ({ renderToFooter, format, precision = 'hour', ...rest }) => {
  const now = getNow(precision);
  const [value, setValue] = useControllableValue<Moment | null | undefined>(
    rest,
  );

  const { formatMessage } = useFormatMessage();

  const defaultList = (['work', 'default'] as const)
    .map((type) =>
      [3, 7, 28].map((day) => ({
        day,
        type,
        label:
          type === 'work'
            ? formatMessage('{day} 个工作日后', { day })
            : formatMessage('{day} 个自然日后', { day }),
      })),
    )
    .flat();

  const handleSelect = (type: 'work' | 'default', day: number) => {
    const next = now.clone();

    if (type === 'default') {
      setValue(next.add(day, 'day'));
      return;
    }

    for (let i = 1; i <= day; i++) {
      const currentDay = next.get('days');

      if (currentDay >= 5) {
        next.add(7 - currentDay, 'day');
      }

      next.add(1, 'day');
    }

    setValue(next);
  };

  const extra = () => (
    <>
      <div
        style={{
          marginBottom: 16,
          marginTop: 16,
          display: 'flex',
          gap: 16,
          flexWrap: 'wrap',
        }}
      >
        {defaultList.map((item) => (
          <Button
            size="middle"
            type="dashed"
            key={`${item.type}_${item.day}`}
            onClick={() => handleSelect(item.type, item.day)}
          >
            {item.label}
          </Button>
        ))}
      </div>
    </>
  );
  const dateFormat = `${DATE_FORMAT} HH:mm:ss`;
  const currentDisabledHours = Array.from({ length: now.hour() }, (_, i) => i);
  const currentDisabledMinutes = Array.from(
    { length: now.minute() },
    (_, i) => i,
  );
  const currentDate = now.format(DATE_FORMAT);

  return (
    <>
      {!renderToFooter && extra()}
      <DatePicker
        style={{ width: '100%' }}
        // @ts-ignore
        showTime={
          precision === 'day'
            ? undefined
            : {
                showHour: true,
                showMinute: precision === 'minute',
                showSecond: false,
                showNow: false,
              }
        }
        showNow={false}
        showToday={false}
        // @ts-ignore
        disabledTime={(v) => {
          if (!v || v.format(DATE_FORMAT) !== currentDate) {
            return {};
          }

          return {
            disabledHours: () => currentDisabledHours,
            disabledMinutes: (hour: number) =>
              hour === now.hour() ? currentDisabledMinutes : [],
          };
        }}
        disabledDate={(v) => {
          const diff = v.diff(now, 'day', true);

          return precision === 'day' ? diff <= 0 : diff < 0;
        }}
        renderExtraFooter={renderToFooter ? extra : undefined}
        value={value}
        onChange={(val) => {
          const next = val?.clone();

          if (precision === 'hour') {
            next?.set('minute', 0);
          } else if (precision === 'day') {
            next?.set('hour', 0).set('minute', 0);
          }

          next?.set('second', 0);

          setValue(next);
        }}
        placeholder={formatMessage('请选择时间')}
        {...omit(rest, ['value', 'onChange'])}
        format={format ?? dateFormat}
      />
    </>
  );
};

export default AbsoluteDate;
