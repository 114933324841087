import React from 'react';

export const tableRender = <T extends AnyObject = AnyObject>(
  render: (
    record: T & AnyObject,
    defaultDom: React.ReactNode,
  ) => React.ReactNode,
) => (_: any, record: T) => {
  return render(record, _);
};
