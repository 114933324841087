import { Empty, Select, Spin } from 'antd';
import { RefSelectProps } from 'antd/es/select';
import { SelectProps, SelectValue } from 'antd/lib/select/index.d';
import { isString } from 'lodash';
import React from 'react';
import { useCommonMessages } from '@/hooks';

export const { Option } = Select;
export const { OptGroup } = Select;

const InternalCommonSelect = <T extends SelectValue = SelectValue>(
  { style, loading, showSearch = true, ...rest }: SelectProps<T>,
  ref: React.Ref<RefSelectProps>,
) => {
  const { normalMessages } = useCommonMessages();

  return (
    <>
      <input type="text" autoComplete="nope" style={{ display: 'none' }} />
      <Select<T>
        style={{ width: '100%', ...style }}
        allowClear
        showArrow
        showSearch={showSearch}
        ref={ref}
        placeholder={normalMessages.select.placeholder}
        loading={loading}
        notFoundContent={loading ? <Spin size="small" /> : <Empty />}
        filterOption={
          rest?.onSearch || !showSearch
            ? false
            : (input, option) => {
                if (!input || !option) {
                  return true;
                }

                return [isString(option.label) && option.label, option.title]
                  .filter(Boolean)
                  .some((item) =>
                    item.toLowerCase().includes(input.toLowerCase()),
                  );
              }
        }
        {...rest}
      />
    </>
  );
};

export const CommonSelect = React.forwardRef(InternalCommonSelect) as <
  T extends SelectValue = SelectValue
>(
  props: SelectProps<T> & { ref?: React.Ref<RefSelectProps> },
) => React.ReactElement;
