import Distribution from './zh-CN/Distribution';
import app from './zh-CN/app';
import form from './zh-CN/form';
import login from './zh-CN/login';
import menu from './zh-CN/menu';
import pwa from './zh-CN/pwa';
import settingDrawer from './zh-CN/settingDrawer';

export default {
  ...app,
  ...form,
  ...login,
  ...menu,
  ...pwa,
  ...settingDrawer,
  ...Distribution,
};
