import { IRoute } from 'umi';
import { EXTRA_PERMISSIONS } from '../config/extraPermissions';
import { ENV } from './env';
import { GlobalState } from './types/API';

const getRouteAccessable = (
  route: IRoute,
  authorityList: string[],
  extraPermissions: AnyObject,
  companyId: number,
): boolean => {
  if (!route.path) {
    return true;
  }

  const hasAccess = authorityList.includes(route.path!);

  let extraAccess = route.extraPermission
    ? extraPermissions[route.extraPermission] === 1
    : true;

  if (route.extraPermission === EXTRA_PERMISSIONS.ONLY_ONEU_EDUCATION) {
    extraAccess = companyId === ENV.oneuEducationCompanyId;
  }

  if (hasAccess && extraAccess) {
    return true;
  }

  if (route.children && extraAccess) {
    // hideChildrenInMenu ? 暂不考虑
    // 暂时没有三级菜单 暂不考虑 有 children  的就是顶级菜单栏
    const children = route.children as IRoute[];

    // 使用 EmptyAccess 特殊处理
    if (route.useEmptyAccess) {
      const noParameterRoutes = children.filter(
        (item: IRoute) => item.path && !item.path.includes(':'),
      );

      // 如果有待跳转的
      //  EmptyAccess 取的是第一个没有动态参数的路由 如果第一个动态参数没有权限且自己本身没有权限那么不展示
      if (noParameterRoutes.length) {
        return noParameterRoutes.some((item) =>
          getRouteAccessable(item, authorityList, extraPermissions, companyId),
        );
      }
    }

    // 不是使用 EmptyAccess 的则直接判断是有权限或者子级至少有一个权限
    return children.some((child) =>
      getRouteAccessable(child, authorityList, extraPermissions, companyId),
    );
  }

  return false;
};

// src/access.ts
export default function access(initialState: GlobalState) {
  const {
    routerAuthorityList = [],
    apiAuthorityList = [],
    extraPermissions = {},
    companyId,
  } = initialState || {};

  return {
    normalRouteAccessFilter: (route: IRoute) => {
      return getRouteAccessable(
        route,
        routerAuthorityList,
        extraPermissions,
        companyId!,
      );
    },

    apiAccessFilter: (authority: string) =>
      apiAuthorityList.includes(authority),
  };
}
