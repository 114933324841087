import { Skeleton } from 'antd';
import React, { memo } from 'react';

const MessageSkeleton: React.FC<{ rows?: number }> = ({ rows = 2 }) => {
  return (
    <div
      style={{
        paddingLeft: 24,
        paddingRight: 24,
      }}
    >
      {new Array(rows).fill(0).map((_, i) => (
        <Skeleton
          avatar
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        />
      ))}
    </div>
  );
};

export default memo(MessageSkeleton);
