import { useAccess, useModel } from 'umi';
import { GlobalState } from '@/types/API';

export const useAccessable = (authority: string | string[]): boolean => {
  const { apiAccessFilter } = useAccess();
  const authorityList = Array.isArray(authority) ? authority : [authority];

  return authorityList.every((item) => apiAccessFilter(item));
};

export const useExtraPermissions = (
  permission?: keyof NonNullable<GlobalState['extraPermissions']>,
) => {
  const { initialState } = useModel('@@initialState');

  return permission
    ? initialState?.extraPermissions?.[permission] === 1
    : false;
};
