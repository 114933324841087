import { RequestData } from '@ant-design/pro-table';
import { upperFirst } from 'lodash';
import moment, { Moment } from 'moment';
import { nanoid } from 'nanoid';
import numeral from 'numeral';
import { compile } from 'path-to-regexp';
import { RequestParams } from 'zyouh-request';
import { Fetcher, ResponseSchema } from '@/api';
import { AUSTRALIA_NAME } from '@/data';

export const safetyGetJSON = <T extends any = any>(
  str: string | null,
  fallback: T,
): T => {
  if (!str) {
    return fallback;
  }

  let target = fallback;

  try {
    target = JSON.parse(str);
  } catch {
    target = fallback;
  }

  return target;
};

export const sleep = (interval = 1000) =>
  new Promise((resolve) => setTimeout(resolve, interval));

export const formatNumber = (
  num: number | string,
  decimal?: boolean,
  fixedDecimal = 2,
) => {
  return numeral(num).format(
    `0,0${decimal ? `.${'0'.repeat(fixedDecimal)}` : ''}`,
  );
};

export const getId = (size = 8) => nanoid(size);

export const isMobile = () =>
  /(?:iphone|ipad|android)/i.test(window.navigator.userAgent);

export const isIOS = () => navigator.userAgent.match(/iphone|ipad|ios/i);

export const getTableKey = (name: string) => {
  const map = new Map<string, number>();

  return (record: Record<string, any>) => {
    const key = String(record[name]);
    if (!map.has(key)) {
      map.set(key, 0);

      return key;
    }
    const current = map.get(key)! + 1;
    map.set(key, current);

    return `${key}:${current}`;
  };
};

export const nextTick = (cb: (...args: any[]) => any, wait = 0) =>
  window.setTimeout(cb, wait);

export const getPaginationTableData = async <T extends AnyObject = AnyObject>(
  params: AnyObject,
  action: (params: AnyObject) => Promise<ResponseSchema<T[]>>,
): Promise<Partial<RequestData<T>>> => {
  try {
    const result = await action({
      ...params,
      current: undefined,
      page: params?.current,
    });
    const { data, code } = result || {};

    return {
      ...('total' in data ? data : { data }),
      success: code === 0,
    } as RequestData<T>;
  } catch {
    return {
      data: undefined,
      success: false,
    };
  }
};

export const formatAlphaInput = (
  formatter: (value?: string) => string | undefined = upperFirst,
  pattern = /[^a-z\s_-]/gi,
) => (evt: React.FormEvent<HTMLInputElement>) => {
  const target = evt.target as HTMLInputElement | null;

  if (target) {
    target.value =
      formatter(target.value?.trimStart()?.replace(pattern, '')) ?? '';
  }
};

export const familyNameInputFormatter = formatAlphaInput((x) =>
  x?.toUpperCase(),
);

const capitalize = (text: string) =>
  !text
    ? text
    : text[0].toUpperCase() + (text[1] ? text.slice(1).toLowerCase() : '');

export const givenNameInputFormatter = (
  evt: React.FormEvent<HTMLInputElement>,
) => {
  const target = evt.target as HTMLInputElement | null;

  if (!target) {
    return;
  }

  const val = target.value;

  let text = val.trimStart().replace(/[-\s]$/g, '');

  if (text.match(/[-\s]/)) {
    text = text.replace(/([^-\s]*)(?:[-\s]+|$)/g, (matched, word) => {
      if (!word) {
        return matched;
      }

      return capitalize(word) + matched.replace(word, '');
    });
  } else {
    text = capitalize(text);
  }

  target.value = text + (val.match(/[-\s]$/)?.[0] || '');
};

export const formatPersonName = (
  info?: AnyObject,
  givenNameKey = 'givenName',
  familyNameKey = 'familyName',
) => [info?.[givenNameKey], info?.[familyNameKey]].filter(Boolean).join(' ');

export const isAustralia = (area?: string) => area === AUSTRALIA_NAME;

export const compileUrl = (url: string, params?: AnyObject) => {
  const urlObj = new URL(url);
  const restUrl = urlObj.pathname;
  const searchUrl = urlObj.search;

  const fn1 = compile(restUrl, { encode: encodeURIComponent });
  const fn2 = compile(searchUrl.replace('?', ''), {
    encode: encodeURIComponent,
  });

  urlObj.pathname = fn1(params);

  if (searchUrl) {
    urlObj.search = `?${fn2(params)}`;
  }

  return urlObj.href;
};
export const getOshcDates = (
  minDate: Moment,
  maxDate: Moment,
  totalDuration: number,
) => {
  const month = totalDuration / (52 / 12);
  const endMonth = maxDate.month() + 1;
  let end = maxDate.clone();

  if (month <= 10) {
    end = end.add(1, 'months');
  } else if (endMonth >= 1 && endMonth <= 10) {
    end = end.add(2, 'months');
  } else {
    end = moment(`${end.year() + 1}-03-15`);
  }

  return {
    startDateTime: minDate,
    endDateTime: end,
  };
};

export const normalizeAuAddress = ({ meta }: { meta: AnyObject }) => {
  if (!meta) {
    return undefined;
  }
  return {
    address: [
      meta.address_line_1 || meta.addressLine1 || meta.address_line1,
      meta.address_line_2 || meta.addressLine2 || meta.address_line2,
    ]
      .filter(Boolean)
      .join(', '),
    town: meta.locality_name || meta.localityName,
    province: meta.state_territory || meta.stateTerritory,
    postcode: meta.postcode,
    country: AUSTRALIA_NAME,
  };
};

export const isValidEmail = (email?: string) => {
  if (!email) {
    return false;
  }

  return /^(?:\w+(?:-\w+)?\.)*\w+(?:-\w+)?@(?:\w+(?:-\w+)?\.)+[a-z]{2,}$/i.test(
    email,
  );
};

export const bindFetcher = (
  fetcher: Fetcher,
  params?: RequestParams,
  onArgs?: (args: any) => any,
) => {
  const fn = (args?: any) =>
    fetcher({ ...params, ...(onArgs ? onArgs(args) : args) });
  fn.key = fetcher.key;

  return fn as Fetcher;
};
