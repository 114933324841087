import { TagProps } from 'antd';
import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum RemoteAssistanceStatus {
  ACTIVE = 'in-progress',
  CHECKING = 'checking',
  CLOSE = 'close',
}

export enum RemoteAssistanceActionType {
  /**
   * 创建协助订单
   */
  CREATE_ASSISTANCE_ORDER = 'create assistance order',

  /**
   * 获取协助token
   */
  GET_ASSISTANCE_TOKEN = 'get assistance token',

  /**
   * 协助订单验证通过
   */
  ORDER_VERIFY_SUCCESS = 'order verify success',

  /**
   * 协助订单接单
   */
  TAKE_ASSISTANCE_ORDER = 'take assist order',

  /**
   * 关闭订单
   */
  CLOSE_ORDER = 'close order',
  /**
   * 待验证订单超时未验证被系统关闭 60 分钟
   */
  AUTO_CLOSE_BY_VERIFY_TIMEOUT = 'order verify timeout system auto close',

  /**
   * 长时间 (30天) 未操作 系统自动关闭
   */
  AUTO_CLOSE_BY_SYSTEM_TIMEOUT = 'long time non working system auto close',
}

export const useRemoteAssistanceEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum = {
      [RemoteAssistanceStatus.ACTIVE]: {
        text: formatMessage('进行中'),
        status: 'processing',
      },
      [RemoteAssistanceStatus.CHECKING]: {
        text: formatMessage('待验证'),
        status: 'default',
      },
      [RemoteAssistanceStatus.CLOSE]: {
        text: formatMessage('已关闭'),
        status: 'error',
      },
    } as const;
    return new Map(
      Object.entries(statusEnum).map((item) => [item[0], item[1]]),
    );
  }, [formatMessage]);
};

export const useRemoteAssistanceActionEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum: Record<
      RemoteAssistanceActionType,
      {
        text: string;
        color?: TagProps['color'];
      }
    > = {
      [RemoteAssistanceActionType.CLOSE_ORDER]: {
        text: formatMessage('关闭协助'),
        color: 'red',
      },
      [RemoteAssistanceActionType.CREATE_ASSISTANCE_ORDER]: {
        text: formatMessage('发起协助'),
        color: 'blue',
      },
      [RemoteAssistanceActionType.TAKE_ASSISTANCE_ORDER]: {
        text: formatMessage('OneULink 接受协助请求'),
        color: 'gold',
      },
      [RemoteAssistanceActionType.GET_ASSISTANCE_TOKEN]: {
        text: formatMessage('开始协助'),
        color: 'pink',
      },
      [RemoteAssistanceActionType.ORDER_VERIFY_SUCCESS]: {
        text: formatMessage('协助验证成功'),
        color: 'green',
      },
      [RemoteAssistanceActionType.AUTO_CLOSE_BY_SYSTEM_TIMEOUT]: {
        text: formatMessage('长时间 (30天) 未操作，系统自动关闭'),
        color: 'red',
      },
      [RemoteAssistanceActionType.AUTO_CLOSE_BY_VERIFY_TIMEOUT]: {
        text: formatMessage('待验证订单超时未验证被系统关闭 60 分钟'),
        color: 'red',
      },
    } as const;
    return new Map(
      Object.entries(statusEnum).map((item) => [item[0], item[1]]),
    );
  }, [formatMessage]);
};
