import { Checkbox } from 'antd';
import type { CheckboxGroupProps } from 'antd/lib/checkbox';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export const useGetRelativeDateOptions = () => {
  const { formatMessage } = useFormatMessage();

  return (targetDate: Moment, now: Moment) => {
    const date = targetDate.clone().minutes(0).seconds(0);
    const hour = date.diff(now, 'hours');

    return [7, 3, 1, 0]
      .filter((item) => Math.abs(item) * 24 <= hour)
      .map((day) => ({
        value: (day === 0
          ? date
          : date.clone().add(-day * 24, 'hours')
        ).toISOString(),
        label:
          day === 0
            ? formatMessage('快要到期时')
            : formatMessage('提前 {day} 天', { day }),
      }));
  };
};

const RelativeDate: React.FC<
  {
    targetDate: Moment | string;
    now: Moment;
  } & CheckboxGroupProps
> = ({ targetDate, onChange, value, now, ...props }) => {
  const getDateOptions = useGetRelativeDateOptions();
  const options = useMemo(() => getDateOptions(moment(targetDate), now), [
    getDateOptions,
    now,
    targetDate,
  ]);

  return (
    <Checkbox.Group
      {...props}
      value={value}
      onChange={(values) => {
        onChange?.(
          values.filter(
            (item) => moment(item as string).diff(moment(), 'hour') > 0,
          ),
        );
      }}
    >
      {options.map((item) => (
        <div key={item.label}>
          <Checkbox value={item.value}>{item.label}</Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  );
};

export default RelativeDate;
