export enum EXTRA_PERMISSIONS {
  ACCOUNT_OPEN = 'open',
  ARCHIVE_FILE_OPEN = 'archiveFileOpen',
  BILL_OPEN = 'billOpen',
  CUSTOMER_LABEL_OPEN = 'customerLabelOpen',
  FILE_SIGN_OPEN = 'fileSignOpen',
  POINT_OPEN = 'pointOpen',
  PUBLICITY_OPEN = 'publicityOpen',
  ONLY_ONEU_EDUCATION = 'onlyOneUEducation',

  MY_MASTER_AGENT = 'myMasterAgent',
}
