import { useMemo } from 'react';
import { useFormatMessage, useIsEn } from '@/hooks';

export const VISA_TYPE_OPTIONS = [
  {
    cnLabel: '访客签证 (Visitor visas)',
    label: 'Visitor visas',
    options: [
      {
        label: 'Electronic Travel Authority (subclass 601)',
        cnLabel: '电子旅行授权签证（601类别）',
        value: 'Electronic Travel Authority (subclass 601)',
      },
      {
        label: 'eVisitor (subclass 651)',
        cnLabel: '电子游客签证（651类别）',
        value: 'eVisitor (subclass 651)',
      },
      {
        label: 'Transit visa (subclass 771)',
        cnLabel: '过境签证（771类别）',
        value: 'Transit visa (subclass 771)',
      },
      {
        label: 'Visitor (subclass 600)',
        cnLabel: '访客签证（600类别）',
        value: 'Visitor (subclass 600)',
      },
      {
        label: 'Work and Holiday visa (subclass 462)',
        cnLabel: '工作和假期签证（462类别）',
        value: 'Work and Holiday visa (subclass 462)',
      },
      {
        label: 'Working Holiday visa (subclass 417)',
        cnLabel: '度假工作签证（417类别）',
        value: 'Working Holiday visa (subclass 417)',
      },
    ],
  },

  {
    cnLabel: '留学和培训签证 (Studying and training visas)',
    label: 'Studying and training visas',
    options: [
      {
        label: 'Student visa (subclass 500)',
        cnLabel: '学生签证（500类别）',
        value: 'Student visa (subclass 500)',
      },
      {
        label: 'Student visa Subsequent Entrant (subclass 500)',
        cnLabel: '学生签证附加申请人（500类别）',
        value: 'Student visa Subsequent Entrant (subclass 500)',
      },
      {
        label: 'Student Guardian visa (subclass 590)',
        cnLabel: '学生监护人签证（590类别）',
        value: 'Student Guardian visa (subclass 590)',
      },
      {
        label: 'Training visa (subclass 407)',
        cnLabel: '培训签证（407类别）',
        value: 'Training visa (subclass 407)',
      },
    ],
  },
  {
    label: 'Family and partner visas',
    cnLabel: '家庭和伴侣签证 (Family and partner visas)',
    options: [
      {
        label: 'Adoption visa (subclass 102)',
        cnLabel: '领养签证（102类别）',
        value: 'Adoption visa (subclass 102)',
      },
      {
        label: 'Aged Dependent Relative visa (subclass 114)',
        cnLabel: '年龄相关亲属签证（114类别）',
        value: 'Aged Dependent Relative visa (subclass 114)',
      },
      {
        label: 'Aged Dependent Relative visa (subclass 838)',
        cnLabel: '年龄相关亲属签证（838类别）',
        value: 'Aged Dependent Relative visa (subclass 838)',
      },
      {
        label: 'Aged Parent visa (subclass 804)',
        cnLabel: '年龄父母签证（804类别）',
        value: 'Aged Parent visa (subclass 804)',
      },
      {
        label: 'Carer visa (subclass 836)',
        cnLabel: '照护签证（836类别）',
        value: 'Carer visa (subclass 836)',
      },
      {
        label: 'Carer visa (subclass 116)',
        cnLabel: '照护签证（116类别）',
        value: 'Carer visa (subclass 116)',
      },
      {
        label: 'Child visa (subclass 101)',
        cnLabel: '子女签证（101类别）',
        value: 'Child visa (subclass 101)',
      },
      {
        label: 'Child visa (subclass 802)',
        cnLabel: '子女签证（802类别）',
        value: 'Child visa (subclass 802)',
      },
      {
        label: 'Contributory Aged Parent (Temporary) visa (subclass 884)',
        cnLabel: '快速通道年龄父母签证（暂时性）（884类别）',
        value: 'Contributory Aged Parent (Temporary) visa (subclass 884)',
      },
      {
        label: 'Contributory Aged Parent visa (subclass 864)',
        cnLabel: '快速通道年龄父母签证（864类别）',
        value: 'Contributory Aged Parent visa (subclass 864)',
      },
      {
        label: 'Contributory Parent (Temporary) visa (subclass 173)',
        cnLabel: '快速通道父母签证（暂时性）（173类别）',
        value: 'Contributory Parent (Temporary) visa (subclass 173)',
      },
      {
        label: 'Contributory Parent visa (subclass 143)',
        cnLabel: '快速通道父母签证（143类别）',
        value: 'Contributory Parent visa (subclass 143)',
      },
      {
        label: 'Dependent Child visa (subclass 445)',
        cnLabel: '依赖子女签证（445类别）',
        value: 'Dependent Child visa (subclass 445)',
      },
      {
        label:
          'New Zealand Citizen Family Relationship (temporary) visa (subclass 461)',
        cnLabel: '新西兰公民家庭关系（暂时性）签证（461类别）',
        value:
          'New Zealand Citizen Family Relationship (temporary) visa (subclass 461)',
      },
      {
        label: 'Orphan Relative (subclass 117)',
        cnLabel: '孤儿亲属签证（117类别）',
        value: 'Orphan Relative (subclass 117)',
      },
      {
        label: 'Orphan Relative (subclass 837)',
        cnLabel: '孤儿亲属签证（837类别）',
        value: 'Orphan Relative (subclass 837)',
      },
      {
        label: 'Parent visa (subclass 103)',
        cnLabel: '父母签证（103类别）',
        value: 'Parent visa (subclass 103)',
      },
      {
        label: 'Partner (Provisional and Migrant) visa (subclass 309 100)',
        cnLabel: '伴侣（临时和移民）签证（309 100类别）',
        value: 'Partner (Provisional and Migrant) visa (subclass 309 100)',
      },
      {
        label: 'Partner visa (subclass 820 801)',
        cnLabel: '伴侣签证（820 801类别）',
        value: 'Partner visa (subclass 820 801)',
      },
      {
        label: 'Prospective Marriage visa (subclass 300)',
        cnLabel: '未来婚姻签证（300类别）',
        value: 'Prospective Marriage visa (subclass 300)',
      },
      {
        label: 'Remaining Relative visa (subclass 115)',
        cnLabel: '剩余亲属签证（115类别）',
        value: 'Remaining Relative visa (subclass 115)',
      },
      {
        label: 'Remaining Relative visa (subclass 835)',
        cnLabel: '剩余亲属签证（835类别）',
        value: 'Remaining Relative visa (subclass 835)',
      },
      {
        label: 'Sponsored Parent (Temporary) visa (subclass 870)',
        cnLabel: '赞助父母签证（暂时性）（870类别）',
        value: 'Sponsored Parent (Temporary) visa (subclass 870)',
      },
    ],
  },
  {
    cnLabel: '工作和技术类签证 (Working and skilled visas)',
    label: 'Working and skilled visas',
    options: [
      {
        label:
          'Business Innovation and Investment (permanent) visa (subclass 888)',
        cnLabel: '商业创新和投资（永久性）签证（888类别）',
        value:
          'Business Innovation and Investment (permanent) visa (subclass 888)',
      },
      {
        label:
          'Business Innovation and Investment (provisional) visa (subclass 188)',
        cnLabel: '商业创新和投资（暂时性）签证（188类别）',
        value:
          'Business Innovation and Investment (provisional) visa (subclass 188)',
      },
      {
        label: 'Business Owner (subclass 890)',
        cnLabel: '企业所有者签证（890类别）',
        value: 'Business Owner (subclass 890)',
      },
      {
        label: 'Business Talent (Permanent) visa (subclass 132)',
        cnLabel: '商业人才（永久性）签证（132类别）',
        value: 'Business Talent (Permanent) visa (subclass 132)',
      },
      {
        label: 'Distinguished Talent visa (subclass 124)',
        cnLabel: '杰出人才签证（124类别）',
        value: 'Distinguished Talent visa (subclass 124)',
      },
      {
        label: 'Global Talent visa (subclass 858)',
        cnLabel: '全球人才签证（858类别）',
        value: 'Global Talent visa (subclass 858)',
      },
      {
        label: 'Employer Nomination Scheme (subclass 186)',
        cnLabel: '雇主提名计划签证（186类别）',
        value: 'Employer Nomination Scheme (subclass 186)',
      },
      {
        label: 'Investor visa (subclass 891)',
        cnLabel: '投资者签证（891类别）',
        value: 'Investor visa (subclass 891)',
      },
      {
        label: 'Permanent Residence (Skilled Regional) visa (subclass 191)',
        cnLabel: '永久居住（技术类地区）签证（191类别）',
        value: 'Permanent Residence (Skilled Regional) visa (subclass 191)',
      },
      {
        label: 'Regional Sponsored Migration Scheme (subclass 187)',
        cnLabel: '区域赞助移民计划签证（187类别）',
        value: 'Regional Sponsored Migration Scheme (subclass 187)',
      },
      {
        label:
          'Skilled Employer Sponsored Regional (provisional) visa (subclass 494)',
        cnLabel: '技术雇主赞助区域（暂时性）签证（494类别）',
        value:
          'Skilled Employer Sponsored Regional (provisional) visa (subclass 494)',
      },
      {
        label: 'Skilled Independent visa (subclass 189)',
        cnLabel: '独立技术类签证（189类别）',
        value: 'Skilled Independent visa (subclass 189)',
      },
      {
        label: 'Skilled Nominated visa (subclass 190)',
        cnLabel: '技术类提名签证（190类别）',
        value: 'Skilled Nominated visa (subclass 190)',
      },
      {
        label: 'Skilled-Recognised Graduate visa (subclass 476)',
        cnLabel: '技术认可研究生签证（476类别）',
        value: 'Skilled-Recognised Graduate visa (subclass 476)',
      },
      {
        label: 'Skilled Regional (provisional) visa (subclass 489)',
        cnLabel: '技术类地区（暂时性）签证（489类别）',
        value: 'Skilled Regional (provisional) visa (subclass 489)',
      },
      {
        label: 'Skilled Regional visa (subclass 887)',
        cnLabel: '技术类地区签证（887类别）',
        value: 'Skilled Regional visa (subclass 887)',
      },
      {
        label: 'Skilled Work Regional (Provisional) visa (subclass 491)',
        cnLabel: '技术工作地区（暂时性）签证（491类别）',
        value: 'Skilled Work Regional (Provisional) visa (subclass 491)',
      },
      {
        label:
          'State or Territory Sponsored Business Owner visa (subclass 892)',
        cnLabel: '州或地区赞助企业所有者签证（892类别）',
        value:
          'State or Territory Sponsored Business Owner visa (subclass 892)',
      },
      {
        label: 'State or Territory Sponsored Investor visa (subclass 893)',
        cnLabel: '州或地区赞助投资者签证（893类别）',
        value: 'State or Territory Sponsored Investor visa (subclass 893)',
      },
      {
        label: 'Temporary Activity visa (subclass 408)',
        cnLabel: '临时活动签证（408类别）',
        value: 'Temporary Activity visa (subclass 408)',
      },
      {
        label: 'Temporary Graduate visa (subclass 485)',
        cnLabel: '临时毕业生签证（485类别）',
        value: 'Temporary Graduate visa (subclass 485)',
      },
      {
        label: 'Temporary Work (International Relations) visa (subclass 403)',
        cnLabel: '临时工作（国际关系）签证（403类别）',
        value: 'Temporary Work (International Relations) visa (subclass 403)',
      },
      {
        label: 'Temporary Work (Short Stay Specialist) visa (subclass 400)',
        cnLabel: '临时工作（短期专业人员）签证（400类别）',
        value: 'Temporary Work (Short Stay Specialist) visa (subclass 400)',
      },
      {
        label: 'Temporary Skill Shortage visa (subclass 482)',
        cnLabel: '临时技能短缺签证（482类别）',
        value: 'Temporary Skill Shortage visa (subclass 482)',
      },
    ],
  },
  {
    label: 'Refugee and humanitarian visas',
    cnLabel: '难民和人道主义类签证 (Refugee and humanitarian visas)',
    options: [
      {
        label: 'Global Special Humanitarian (subclass 202)',
        cnLabel: '全球特殊人道主义签证（202类别）',
        value: 'Global Special Humanitarian (subclass 202)',
      },
      {
        label: 'Protection visa (subclass 866)',
        cnLabel: '庇护签证（866类别）',
        value: 'Protection visa (subclass 866)',
      },
      {
        label: 'Refugee visas (subclass 200, 201, 203 and 204)',
        cnLabel: '难民签证（200、201、203和204类别）',
        value: 'Refugee visas (subclass 200, 201, 203 and 204)',
      },
      {
        label: 'Temporary Protection visa (subclass 785)',
        cnLabel: '临时庇护签证（785类别）',
        value: 'Temporary Protection visa (subclass 785)',
      },
      {
        label: 'Safe Haven Enterprise visa (subclass 790)',
        cnLabel: '安全港企业签证（790类别）',
        value: 'Safe Haven Enterprise visa (subclass 790)',
      },
    ],
  },
  {
    label: 'Other visas',
    cnLabel: '其他类签证 (Other visas)',
    options: [
      {
        label: 'Bridging visa A  BVA - (subclass 010)',
        cnLabel: '过渡签证A  BVA - (010类别)',
        value: 'Bridging visa A  BVA - (subclass 010)',
      },
      {
        label: 'Bridging visa B  BVB  (subclass 020)',
        cnLabel: '过渡签证B  BVB  (020类别)',
        value: 'Bridging visa B  BVB  (subclass 020)',
      },
      {
        label: 'Bridging visa C  BVC  (subclass 030)',
        cnLabel: '过渡签证C  BVC  (030类别)',
        value: 'Bridging visa C  BVC  (subclass 030)',
      },
      {
        label: 'Bridging visa E  BVE  (subclass 050 and 051)',
        cnLabel: '过渡签证E  BVE  (050和051类别)',
        value: 'Bridging visa E  BVE  (subclass 050 and 051)',
      },
      {
        label: 'Crew Travel Authority visa (subclass 942)',
        cnLabel: '船员旅行许可签证（942类别）',
        value: 'Crew Travel Authority visa (subclass 942)',
      },
      {
        label: 'Former Resident visa (subclass 151)',
        cnLabel: '前居民签证（151类别）',
        value: 'Former Resident visa (subclass 151)',
      },
      {
        label: 'Maritime Crew visa (subclass 988)',
        cnLabel: '海员签证（988类别）',
        value: 'Maritime Crew visa (subclass 988)',
      },
      {
        label: 'Medical Treatment visa (subclass 602)',
        cnLabel: '医疗治疗签证（602类别）',
        value: 'Medical Treatment visa (subclass 602)',
      },
      {
        label: 'Resident Return visa (subclass 155 157)',
        cnLabel: '居民返程签证（155 157类别）',
        value: 'Resident Return visa (subclass 155 157)',
      },
      {
        label: 'Special Category visa (subclass 444)',
        cnLabel: '特殊类别签证（444类别）',
        value: 'Special Category visa (subclass 444)',
      },
      {
        label: 'Special Purpose visa',
        cnLabel: '特殊目的签证',
        value: 'Special Purpose visa',
      },
      {
        label: 'Investor Retirement visa (subclass 405)',
        cnLabel: '投资者退休签证（405类别）',
        value: 'Investor Retirement visa (subclass 405)',
      },
      {
        label: 'Confirmatory (Residence) visa (subclass 808)',
        cnLabel: '确认（居住）签证（808类别）',
        value: 'Confirmatory (Residence) visa (subclass 808)',
      },
    ],
  },
  {
    cnLabel: '废止的签证类别 (Repealed visas)',
    label: 'Repealed visas',
    options: [
      {
        label: 'Business (Short Stay) visa (subclass 456)',
        cnLabel: '商务（短期访问）签证（456类别）',
        value: 'Business (Short Stay) visa (subclass 456)',
      },
      {
        label: 'Business Skills (Provisional) visa (subclass 160 and 165)',
        cnLabel: '商业技能（临时）签证（160和165类别）',
        value: 'Business Skills (Provisional) visa (subclass 160 and 165)',
      },
      {
        label:
          'Domestic Worker (Temporary) Diplomatic and Consular visa (subclass 426)',
        cnLabel: '临时外交和领事馆家政工签证（426类别）',
        value:
          'Domestic Worker (Temporary) Diplomatic and Consular visa (subclass 426)',
      },
      {
        label: 'Domestic Worker (Temporary) Executive visa (subclass 427)',
        cnLabel: '临时执行家政工签证（427类别）',
        value: 'Domestic Worker (Temporary) Executive visa (subclass 427)',
      },
      {
        label:
          'Electronic Travel Authority (Business Entrant) visa (subclass 956 and 977)',
        cnLabel: '电子旅行许可（商业入境）签证（956和977类别）',
        value:
          'Electronic Travel Authority (Business Entrant) visa (subclass 956 and 977)',
      },
      {
        label: 'Electronic Travel Authority (Visitor) visa (subclass 976)',
        cnLabel: '电子旅行许可（访客）签证（976类别）',
        value: 'Electronic Travel Authority (Visitor) visa (subclass 976)',
      },
      {
        label: 'Employer Nomination Scheme (subclass 121 and 856)',
        cnLabel: '雇主提名计划签证（121和856类别）',
        value: 'Employer Nomination Scheme (subclass 121 and 856)',
      },
      {
        label: 'Established Business in Australia visa (subclass 845)',
        cnLabel: '在澳大利亚建立的业务签证（845类别）',
        value: 'Established Business in Australia visa (subclass 845)',
      },
      {
        label: 'Exchange visa (subclass 411)',
        cnLabel: '交流签证（411类别）',
        value: 'Exchange visa (subclass 411)',
      },
      {
        label: 'Foreign Government Agency (subclass 415)',
        cnLabel: '外国政府机构签证（415类别）',
        value: 'Foreign Government Agency (subclass 415)',
      },
      {
        label: 'Government Agreement visa (subclass 406)',
        cnLabel: '政府协议签证（406类别）',
        value: 'Government Agreement visa (subclass 406)',
      },
      {
        label: 'Labour Agreement visa (subclass 120)',
        cnLabel: '劳工协议签证（120类别）',
        value: 'Labour Agreement visa (subclass 120)',
      },
      {
        label: 'Labour Agreement visa (subclass 855)',
        cnLabel: '劳工协议签证（855类别）',
        value: 'Labour Agreement visa (subclass 855)',
      },
      {
        label: 'Media and Film Staff visa (subclass 423)',
        cnLabel: '媒体和电影工作人员签证（423类别）',
        value: 'Media and Film Staff visa (subclass 423)',
      },
      {
        label: 'Medical Practitioner visa (subclass 422)',
        cnLabel: '医疗从业者签证（422类别）',
        value: 'Medical Practitioner visa (subclass 422)',
      },
      {
        label: 'Medical Treatment (Short Stay) visa (subclass 675)',
        cnLabel: '医疗治疗（短期停留）签证（675类别）',
        value: 'Medical Treatment (Short Stay) visa (subclass 675)',
      },
      {
        label: 'Medical Treatment Long Stay visa (subclass 685)',
        cnLabel: '医疗治疗（长期停留）签证（685类别）',
        value: 'Medical Treatment Long Stay visa (subclass 685)',
      },
      {
        label: 'Regional Sponsor Migration Scheme (subclass 119 and 857)',
        cnLabel: '区域担保移民计划签证（119和857类别）',
        value: 'Regional Sponsor Migration Scheme (subclass 119 and 857)',
      },
      {
        label: 'Religious Worker visa (subclass 428)',
        cnLabel: '宗教工作者签证（428类别）',
        value: 'Religious Worker visa (subclass 428)',
      },
      {
        label: 'Retirement visa (subclass 410)',
        cnLabel: '退休签证（410类别）',
        value: 'Retirement visa (subclass 410)',
      },
      {
        label: 'Skilled Designated Area Sponsored visa (subclass 496)',
        cnLabel: '技术指定地区赞助签证（496类别）',
        value: 'Skilled Designated Area Sponsored visa (subclass 496)',
      },
      {
        label: 'Skilled Independent Regional (Provisional) visa (subclass 495)',

        cnLabel: '独立技术移民区域（临时）签证（495类别）',
        value: 'Skilled Independent Regional (Provisional) visa (subclass 495)',
      },
      {
        label: 'Skilled Independent visa (subclass 175)',
        cnLabel: '独立技术移民签证（175类别）',
        value: 'Skilled Independent visa (subclass 175)',
      },
      {
        label: 'Skilled Independent visa (subclass 885)',
        cnLabel: '独立技术移民签证（885类别）',
        value: 'Skilled Independent visa (subclass 885)',
      },
      {
        label: 'Skilled Regional Sponsored visa (subclass 475)',
        cnLabel: '技术区域赞助签证（475类别）',
        value: 'Skilled Regional Sponsored visa (subclass 475)',
      },
      {
        label: 'Skilled Regional Sponsored (subclass 487)',
        cnLabel: '技术区域赞助签证（487类别）',
        value: 'Skilled Regional Sponsored (subclass 487)',
      },
      {
        label: 'Skilled Sponsored visa (subclass 176)',
        cnLabel: '技术赞助签证（176类别）',
        value: 'Skilled Sponsored visa (subclass 176)',
      },
      {
        label: 'Special Program visa (subclass 416)',
        cnLabel: '特别计划签证（416类别）',
        value: 'Special Program visa (subclass 416)',
      },
      {
        label: 'Sponsored visa (subclass 886)',
        cnLabel: '赞助签证（886类别）',
        value: 'Sponsored visa (subclass 886)',
      },
      {
        label: 'Sport visa (subclass 421)',
        cnLabel: '体育签证（421类别）',
        value: 'Sport visa (subclass 421)',
      },
      {
        label: 'Superyacht Crew visa (subclass 488)',
        cnLabel: '超级游艇机组签证（488类别）',
        value: 'Superyacht Crew visa (subclass 488)',
      },
      {
        label:
          'State or Territory Sponsored Regional Established Business in Australia visa (subclass 846)',
        cnLabel: '澳大利亚州或领地赞助的区域建立业务签证（846类别）',
        value:
          'State or Territory Sponsored Regional Established Business in Australia visa (subclass 846)',
      },
      {
        label: 'Temporary Work (Entertainment) visa (subclass 420)',
        cnLabel: '临时工作（娱乐）签证（420类别）',
        value: 'Temporary Work (Entertainment) visa (subclass 420)',
      },
      {
        label: 'Temporary Work (Skilled) visa (subclass 457)',
        cnLabel: '临时工作（技术）签证（457类别）',
        value: 'Temporary Work (Skilled) visa (subclass 457)',
      },
      {
        label: 'Tourist visa (subclass 676)',
        cnLabel: '旅游签证（676类别）',
        value: 'Tourist visa (subclass 676)',
      },
      {
        label: 'Temporary Work (long Stay Activity) visa (subclass 401)',
        cnLabel: '临时工作（长期停留活动）签证（401类别）',
        value: 'Temporary Work (long Stay Activity) visa (subclass 401)',
      },
      {
        label: 'Training and Research visa (subclass 402)',
        cnLabel: '培训和研究签证（402类别）',
        value: 'Training and Research visa (subclass 402)',
      },
      {
        label: 'Visiting Academic visa (subclass 419)',
        cnLabel: '访问学者签证（419类别）',
        value: 'Visiting Academic visa (subclass 419)',
      },
      {
        label: 'Foreign Affairs or Defence sector visa (subclass 576)',
        cnLabel: '外交或国防领域签证（576类别）',
        value: 'Foreign Affairs or Defence sector visa (subclass 576)',
      },
      {
        label: 'Higher Education Sector visa (subclass 573)',
        cnLabel: '高等教育领域签证（573类别）',
        value: 'Higher Education Sector visa (subclass 573)',
      },
      {
        label: 'Independent ELICOS Sector visa (subclass 570)',
        cnLabel: '独立ELICOS领域签证（570类别）',
        value: 'Independent ELICOS Sector visa (subclass 570)',
      },
      {
        label: 'Non Award Sector visa (subclass 575)',
        cnLabel: '非学位领域签证（575类别）',
        value: 'Non Award Sector visa (subclass 575)',
      },
      {
        label: 'Postgraduate Research Sector visa (subclass 574)',
        cnLabel: '研究生研究领域签证（574类别）',
        value: 'Postgraduate Research Sector visa (subclass 574)',
      },
      {
        label: 'School Sector visa (subclass 571)',
        cnLabel: '学校领域签证（571类别）',
        value: 'School Sector visa (subclass 571)',
      },
      {
        label: 'Student Guardian visa (subclass 580)',
        cnLabel: '学生监护人签证（580类别）',
        value: 'Student Guardian visa (subclass 580)',
      },
      {
        label: 'Vocational Education and Training Sector visa (Subclass 572)',
        cnLabel: '职业教育和培训领域签证（572类别）',
        value: 'Vocational Education and Training Sector visa (Subclass 572)',
      },
    ],
  },
];

const VISA_CN_NAME_MAP = new Map(
  VISA_TYPE_OPTIONS.map((item) =>
    item.options.map((v) => [v.value, v.cnLabel] as [string, string]),
  ).flat(),
);

export const getVisaTypeCnName = (value: string) => VISA_CN_NAME_MAP.get(value);

export enum BuiltinVisaType {
  STUDENT_VISA = 'subclass 500',
  BRIDGING_VISA_B = 'Bridging visa B  BVB  (subclass 020)',
  VISITOR_ONSHORE_VISA = 'subclass 600 (onshore)',
  VISITOR_OFFSHORE_VISA = 'subclass 600 (offshore)',
  STUDENT_SUBSEQUENT_ENTRANT_VISA = 'student visa subsequent entrant (subclass 500)',
  STUDENT_GUARDIAN_VISA = 'student guardian visa (subclass 590)',
  TEMPORARY_GRADUATE_VISA = 'subclass 485',
}

export const BuiltinVisaFormLinks: Record<BuiltinVisaType, string> = {
  [BuiltinVisaType.STUDENT_VISA]: '/client-user/student-visa',
  [BuiltinVisaType.VISITOR_OFFSHORE_VISA]: '/client-user/visitor-visa',
  [BuiltinVisaType.VISITOR_ONSHORE_VISA]: '/client-user/visitor-visa',
  [BuiltinVisaType.TEMPORARY_GRADUATE_VISA]: '/client-user/485-visa',
  [BuiltinVisaType.BRIDGING_VISA_B]: '/client-user/bridging-visa-b',
  [BuiltinVisaType.STUDENT_SUBSEQUENT_ENTRANT_VISA]:
    '/client-user/subsequent-student-visa',
  [BuiltinVisaType.STUDENT_GUARDIAN_VISA]: '/client-user/guardian-student-visa',
};

export const isBuiltinStudentVisa = (
  type?: string,
  includeChannel: boolean = true,
) => {
  if (!type) {
    return false;
  }

  const internalType = includeChannel ? type.replace('_channel', '') : type;

  return internalType === BuiltinVisaType.STUDENT_VISA;
};

export const getBuiltinVisaType = (type: string) =>
  type.split('_')[0] as BuiltinVisaType;

export const isAccompanyingMembersVisa = (type?: string) =>
  type
    ? [
        BuiltinVisaType.STUDENT_VISA,
        BuiltinVisaType.TEMPORARY_GRADUATE_VISA,
        BuiltinVisaType.STUDENT_SUBSEQUENT_ENTRANT_VISA,
        BuiltinVisaType.VISITOR_OFFSHORE_VISA,
        BuiltinVisaType.VISITOR_ONSHORE_VISA,
      ].includes(getBuiltinVisaType(type))
    : false;

export const useBuiltinVisaTypeEnum = () => {
  const isEn = useIsEn();

  return useMemo(() => {
    const options: {
      type: BuiltinVisaType;
      enName: string;
      cnName: string;
    }[] = [
      {
        type: BuiltinVisaType.STUDENT_VISA,
        enName: 'Student Visa',
        cnName: '学生签证',
      },
      {
        type: BuiltinVisaType.VISITOR_OFFSHORE_VISA,
        enName: 'Visitor Visa (Offshore)',
        cnName: '境外旅游签证',
      },
      {
        type: BuiltinVisaType.VISITOR_ONSHORE_VISA,
        enName: 'Visitor Visa (Onshore)',
        cnName: '境内旅游签证',
      },
      {
        type: BuiltinVisaType.STUDENT_SUBSEQUENT_ENTRANT_VISA,
        enName: '(Subclass 500) Student visa Subsequent Entrant',
        cnName: '(Subclass 500) 学生陪读签证（主申请人18岁以上）',
      },
      {
        type: BuiltinVisaType.STUDENT_GUARDIAN_VISA,
        enName: '(Subclass 590) Student Guardian visa',
        cnName: '(Subclass 590) 学生陪读签证（主申请人18岁以下）',
      },
      {
        type: BuiltinVisaType.TEMPORARY_GRADUATE_VISA,
        enName: '(Subclass 485) Temporary Graduate visa',
        cnName: '(Subclass 485) 临时毕业生签证',
      },
      {
        type: BuiltinVisaType.BRIDGING_VISA_B,
        enName: 'Bridging visa B',
        cnName: '过桥签证 B',
      },
    ];

    return new Map(
      options.map((item) => [
        item.type,
        { text: isEn ? item.enName : item.cnName },
      ]),
    );
  }, [isEn]);
};

export const useVisaStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return {
    0: {
      text: formatMessage('已失效'),
      status: 'error',
    },
    1: {
      text: formatMessage('生效中'),
      status: 'success',
    },
    2: {
      text: formatMessage('未生效'),
      status: 'default',
    },
  };
};

const splitVisa = (type: string) =>
  type
    .split(/\s+/)
    .filter(Boolean)
    .map((item) => item.toLowerCase());
const calculateMatched = (source: string[], target: string[]) =>
  source.reduce(
    (sum, item) => sum + (target.find((word) => word.includes(item)) ? 1 : 0),
    0,
  );

export const searchVisaType = (type: string | null) => {
  if (!type) {
    return null;
  }

  const source = splitVisa(type);

  let maxLength = 0;
  let target: string | null = null;

  VISA_TYPE_OPTIONS.forEach(({ options }) => {
    options.forEach((item) => {
      const list = splitVisa(item.value);
      const currentMatched = calculateMatched(source, list);

      if (currentMatched > maxLength) {
        maxLength = currentMatched;
        target = item.value;
      }
    });
  });

  return target;
};

export const useBuiltinVisaSortTypeEnum = () => {
  const isEn = useIsEn();
  const builtinVisaEnum = useBuiltinVisaTypeEnum();

  return useMemo(() => {
    const visaSortOptions = [
      {
        cnName: '学生签证相关',
        enName: 'Student Visa Related',
        value: 'student',
        children: [
          BuiltinVisaType.STUDENT_VISA,
          BuiltinVisaType.STUDENT_SUBSEQUENT_ENTRANT_VISA,
          BuiltinVisaType.STUDENT_GUARDIAN_VISA,
        ],
      },
      {
        cnName: '旅游签证相关',
        enName: 'Visitor Visa Related',
        value: 'visitor',
        children: [
          BuiltinVisaType.VISITOR_OFFSHORE_VISA,
          BuiltinVisaType.VISITOR_ONSHORE_VISA,
        ],
      },
      {
        cnName: '其他签证类型',
        enName: 'Other Visa Type',
        value: 'other',
        children: [
          BuiltinVisaType.TEMPORARY_GRADUATE_VISA,
          BuiltinVisaType.BRIDGING_VISA_B,
        ],
      },
    ];

    return visaSortOptions.map((option) => ({
      label: isEn ? option.enName : option.cnName,
      value: option.value,
      children: option.children.map((child) => ({
        label: builtinVisaEnum.get(child)?.text,
        value: child,
      })),
    }));
  }, [builtinVisaEnum, isEn]);
};
