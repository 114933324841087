import Distribution from './en-US/Distribution';
import app from './en-US/app';
import form from './en-US/form';
import login from './en-US/login';
import menu from './en-US/menu';
import pwa from './en-US/pwa';
import settingDrawer from './en-US/settingDrawer';

export default {
  ...app,
  ...form,
  ...login,
  ...menu,
  ...pwa,
  ...settingDrawer,
  ...Distribution,
};
