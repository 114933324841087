import { usePersistFn } from 'ahooks';
import EventEmitter from 'eventemitter3';
import React, { createContext, useContext, useEffect } from 'react';

const EventEmitterContext = createContext<{
  eventEmitter: EventEmitter;
}>({
  eventEmitter: new EventEmitter(),
});

export const EventEmitterProvider: React.FC<{
  eventEmitter?: EventEmitter;
}> = ({ eventEmitter, children }) => {
  const persisted = useEventEmitter();
  return (
    <EventEmitterContext.Provider
      value={{
        eventEmitter: eventEmitter || persisted,
      }}
    >
      {children}
    </EventEmitterContext.Provider>
  );
};

export const useEventEmitter = () =>
  useContext(EventEmitterContext).eventEmitter;

export const useEventEmitterListen = (
  event: string,
  fn: (...args: any) => void,
) => {
  const emitter = useEventEmitter();
  const callback = usePersistFn(fn);

  useEffect(() => {
    emitter.on(event, callback);

    return () => {
      emitter.off(event, callback);
    };
  }, [callback, emitter, event]);
};
