/* eslint-disable no-restricted-globals */
import { isString } from 'lodash';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '@/config';

export const toDate = (date: string | Moment) => {
  if (moment.isMoment(date)) {
    return date;
  }
  if (!date || !isString(date)) {
    return date;
  }

  if (date.match(/^[\d-]+T[\d:+.]+Z?$/)) {
    return moment(date);
  }

  if (/^\d{4}-\d{2}(?:-\d{1,2})$/.test(date)) {
    return moment(date);
  }

  const parser = /^(?:(\d{2})[/_-])?(\d{2})[/_-](\d{4})(?:\s|$)|^(\d{4})[/_-](\d{2})(?:[/_-](\d{2}))?(?:\s|$)/;
  const matched = date.match(parser);

  if (matched) {
    const list = matched.slice(1).map(Number);
    const arr = date.split(/[/_-]/);
    let d: Date;

    if (/\d{2}[/_-]\d{4}/.test(date)) {
      d =
        arr.length > 2
          ? new Date(list[2], list[1] - 1, list[0])
          : new Date(list[2], list[1] - 1);
    } else {
      d =
        arr.length > 2
          ? new Date(list[3], list[4] - 1, list[5])
          : new Date(list[3], list[4] - 1);
    }

    if (/\s\d+(?::\d+)(?::\d+)$/.test(date)) {
      const [hour, minute = 0, sec = 0] = date.split(' ')[1].split(':');
      return moment(d)
        .hours(Number(hour))
        .minutes(Number(minute))
        .seconds(Number(sec));
    }

    return moment(d);
  }

  return date;
};

const getLocalizedFormats = (format: string) => {
  const isDate =
    format.includes('DD') && format.includes('YYYY') && format.includes('MM');

  const isMinute = /hh.mm(?:.ss)?/i.test(format);

  if (isMinute && isDate) {
    return 'lll';
  }

  if (/hh|ss/i.test(format) && isDate) {
    return `${format
      .replace('MM', 'MMM')
      .replace(/\//g, ' ')
      .replace('HH', 'h')} A`;
  }

  if (isDate && !isMinute) {
    return 'll';
  }

  if (['MM/YYYY', 'YYYY/MM'].includes(format)) {
    return format.replace('MM', 'MMM').toUpperCase().replace(/\//g, ' ');
  }

  return format;
};

export const formatDate = (
  date: string | number | Date | Moment,
  minute?: boolean,
  format?: string,
): null | undefined | string => {
  if (date === null || date === undefined || date === '') {
    return (date as unknown) as null | undefined;
  }

  const locale = moment.locale();
  const internalFormat = format || `${DATE_FORMAT}${minute ? ' HH:mm:ss' : ''}`;

  if (locale !== 'en-au') {
    moment.locale('en-au');
  }

  const d = moment(isString(date) ? toDate(date) : date);
  const result = d.format(getLocalizedFormats(internalFormat));

  if (locale !== 'en-au') {
    moment.locale(locale);
  }

  return result;
};

export const dateToFormValue = (
  date: string | Moment | number | Date,
  month?: boolean,
) =>
  date
    ? moment(isString(date) ? toDate(date) : date).format(
        month ? 'YYYY-MM' : 'YYYY-MM-DD',
      )
    : null;
