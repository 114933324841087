import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Alert, Button, Result, Space } from 'antd';
import React, { useState } from 'react';
import { ResponseSchema, services } from '@/api';
import { useFormatMessage } from '@/hooks';
import { formatFileSize } from '@/utils';
import { PdfView } from '../PdfViewer';
import Popup from '../Popup';
import styles from './PdfFileCompressionPopup.less';

const PdfFileCompressionPopup: React.FC<{
  fileUrl: string;
  fileSize: number;
  onCompressSuccess?: (
    compressedFileUrl: string,
    compressedFileSize: number,
  ) => void;
  onIgnoreCompress?: () => void;
  maxFileSize: number;
}> = ({
  onCompressSuccess,
  onIgnoreCompress,
  fileUrl: _fileUrl,
  fileSize: _fileSize,
  maxFileSize,
}) => {
  const { formatMessage } = useFormatMessage();
  const [status, setStatus] = useState<'retry' | 'none'>('none');
  const [fileUrl, setFileUrl] = useState(_fileUrl);
  const [fileSize, setFileSize] = useState(_fileSize);

  const { isLoading, data: compressedResp } = useQuery<
    ResponseSchema<{ fileUrl: string; fileSize: number }>
  >([services.file.compressPdf.key, fileUrl, status], ({ signal }) =>
    services.file.compressPdf({ signal, file: fileUrl }),
  );

  const compressedFileUrl = compressedResp?.data.fileUrl;
  const compressedFileSize = compressedResp?.data.fileSize ?? 0;

  const stillNeedCompress =
    compressedFileSize >= maxFileSize && status === 'none';

  return (
    <Popup
      width={900}
      className={styles.popupContainer}
      bodyClassName={styles.popup}
      zIndex={2000}
      maskClosable={false}
      immediate
      open
    >
      {isLoading ? (
        <div className={styles.loading}>
          <Alert
            type="info"
            message={formatMessage(
              '当前 Pdf 文件大小为 {size}, 已超出最大文件大小 {max} 的限制。',
              {
                size: formatFileSize(fileSize),
                max: formatFileSize(maxFileSize),
              },
            )}
            description={formatMessage('取消压缩文件将放弃上传文件')}
            showIcon
          />

          <Space>
            <Space>
              {status === 'retry'
                ? formatMessage('文件再次压缩中')
                : formatMessage('文件压缩中')}
              <LoadingOutlined spin />
            </Space>
            <Button onClick={() => onIgnoreCompress?.()} danger>
              {formatMessage('取消压缩文件')}
            </Button>
          </Space>
        </div>
      ) : (
        <>
          {compressedFileUrl && (
            <div className={styles.file}>
              <PdfView url={compressedFileUrl} />
            </div>
          )}

          {!compressedFileUrl ? (
            <Result
              status="error"
              title={formatMessage('文件压缩失败')}
              extra={
                <Button onClick={() => onIgnoreCompress?.()} danger>
                  {formatMessage('放弃压缩')}
                </Button>
              }
            />
          ) : (
            <Result
              status="success"
              title={formatMessage('文件压缩成功')}
              subTitle={
                <>
                  <div>
                    {formatMessage(
                      '原文件大小为 {originSize}，压缩后文件大小为 {currentSize}',
                      {
                        originSize: formatFileSize(fileSize),
                        currentSize: formatFileSize(compressedFileSize ?? 0),
                      },
                    )}
                  </div>
                  {stillNeedCompress &&
                    formatMessage(
                      '超出最大文件大小 {max} 的限制，你可以继续压缩或者自行压缩文件',
                      {
                        max: formatFileSize(maxFileSize),
                      },
                    )}
                  {compressedFileSize >= maxFileSize &&
                    status === 'retry' &&
                    formatMessage(
                      '再次压缩后仍然超出最大文件大小 {max} 的限制，请自行压缩文件，建议拆分 Pdf 文件后上传',
                      {
                        max: formatFileSize(maxFileSize),
                      },
                    )}
                </>
              }
              extra={
                <Space size={16}>
                  <Button onClick={() => onIgnoreCompress?.()} danger>
                    {formatMessage('放弃压缩')}
                  </Button>

                  {compressedFileUrl && (
                    <>
                      {stillNeedCompress && (
                        <Button
                          type="dashed"
                          onClick={() => {
                            setStatus('retry');
                            setFileUrl(compressedFileUrl);
                            setFileSize(compressedFileSize);
                          }}
                        >
                          {formatMessage('继续压缩')}
                        </Button>
                      )}
                      {compressedFileSize < maxFileSize && (
                        <Button
                          onClick={() => {
                            onCompressSuccess?.(
                              compressedFileUrl,
                              compressedFileSize,
                            );
                          }}
                          type="primary"
                        >
                          {formatMessage('使用该压缩文件')}
                        </Button>
                      )}
                    </>
                  )}
                </Space>
              }
            />
          )}
        </>
      )}
    </Popup>
  );
};

export default PdfFileCompressionPopup;
