import { useRequest } from 'ahooks';
import { Divider, Empty, Popover, Skeleton, Typography } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { ResponseSchema, services } from '@/api';
import { LinkOptionButton } from '@/common';
import { useFormatMessage } from '@/hooks';
import { EmailHTMLBody } from '../EmailDetail';

const { Title, Paragraph } = Typography;

const MessageContent: React.FC<{ id: number }> = ({ id }) => {
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useFormatMessage();
  const { run, loading, data: resp } = useRequest<
    ResponseSchema<{
      type: 'email';
      subject: string;
      content: string;
    }>
  >(() => services.messageTask.getMessageContent({ id }), {
    manual: true,
  });

  useEffect(() => {
    if (visible) {
      run();
    }
  }, [run, visible]);

  const data = resp?.data;

  return (
    <Popover
      overlayStyle={{
        width: Math.min(window.innerWidth - 30, 350),
      }}
      trigger="click"
      content={
        <Skeleton loading={loading && !data}>
          {data?.content ? (
            <Typography>
              <Title level={5}>
                <EmailHTMLBody html={data?.subject} />
              </Title>
              <Divider />
              <Paragraph style={{ marginTop: '2em' }}>
                <EmailHTMLBody html={data?.content} />
              </Paragraph>
            </Typography>
          ) : (
            <Empty description={formatMessage('暂无消息内容')} />
          )}
        </Skeleton>
      }
      visible={visible}
      onVisibleChange={setVisible}
    >
      <LinkOptionButton>{formatMessage('查看消息')}</LinkOptionButton>
    </Popover>
  );
};

export default memo(MessageContent);
