import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Button, Checkbox, InputNumber, Radio, Space, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormatMessage } from '@/hooks';

type DateFlag = 'before' | 'after';

const DynamicDate: React.FC<{
  value?: number[];
  onChange?: (value: number[]) => void;
}> = (props) => {
  const { formatMessage } = useFormatMessage();
  const [value, setValue] = useControllableValue<number[]>(props);
  const [customList, setCustomList] = useState<number[]>([]);
  const [visible, setVisible] = useState(false);
  const [flag, setFlag] = useState<DateFlag>('before');
  const [hour, setHour] = useState<number | null>(1);

  const quickList = useMemo(
    () => [
      {
        label: formatMessage('1 天前'),
        value: -1 * 24,
      },
      {
        label: formatMessage('1 周前'),
        value: -7 * 24,
      },
      {
        label: formatMessage('28 天前'),
        value: -28 * 24,
      },
      {
        label: formatMessage('1 天后'),
        value: 1 * 24,
      },
      {
        label: formatMessage('1 周后'),
        value: 7 * 24,
      },
      {
        label: formatMessage('28 天后'),
        value: 28 * 24,
      },
    ],
    [formatMessage],
  );

  useEffect(() => {
    // 需要把传过来的 没有在内置里面的 初始化到 customList 中
    if (value) {
      const extra = value.filter(
        (item) => !quickList.find((v) => v.value === item),
      );

      setCustomList((prev) => {
        const diff = prev
          ? extra.filter((item) => !prev.includes(item))
          : extra;

        return diff.length ? (prev ?? []).concat(diff) : (prev as number[]);
      });
    }
  }, [value, quickList]);

  return (
    <div
      style={{
        paddingTop: 4,
      }}
    >
      <Checkbox.Group
        value={value}
        onChange={(current) => {
          setValue(current as number[]);
        }}
      >
        {quickList.map((item) => (
          <div
            style={{
              paddingBottom: 8,
            }}
            key={item.value}
          >
            <Checkbox value={item.value}>{item.label}</Checkbox>
          </div>
        ))}
        {customList?.map((item, index) => (
          <div
            style={{
              paddingBottom: 8,
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}_custom`}
          >
            <Checkbox value={item}>
              {item === 0
                ? '当前'
                : item < 0
                ? `${-item} 小时前`
                : `${item} 小时后`}
            </Checkbox>
          </div>
        ))}
      </Checkbox.Group>

      <div style={{ marginTop: 16 }}>
        {visible && (
          <div
            style={{
              padding: 16,
              backgroundColor: '#f4f4f4',
              marginBottom: 16,
            }}
          >
            <h4
              style={{
                marginBottom: 12,
              }}
            >
              {formatMessage('设置自定义时间')}
            </h4>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: 4,
              }}
            >
              <Space>
                <Radio.Group
                  onChange={(evt) => setFlag(evt.target.value as DateFlag)}
                  value={flag}
                  optionType="button"
                  buttonStyle="solid"
                  options={[
                    { value: 'before', label: 'Before' },
                    { value: 'after', label: 'After' },
                  ]}
                />
                <InputNumber min={0} value={hour} onChange={setHour} />
                <span>Hour</span>
              </Space>
              <Space size={4}>
                <Button
                  onClick={() => {
                    const currentValue =
                      (hour ?? 0) * (flag === 'after' ? 1 : -1);

                    if (
                      quickList.find((item) => item.value === currentValue) ||
                      customList?.includes(currentValue)
                    ) {
                      message.warn(formatMessage('不能重复添加'));
                      return;
                    }

                    setCustomList(customList?.concat(currentValue));
                    setVisible(false);
                  }}
                  icon={<CheckOutlined style={{ color: 'rgb(82, 196, 26)' }} />}
                  type="text"
                />
                <Button
                  onClick={() => {
                    setVisible(false);
                  }}
                  icon={
                    <CloseOutlined style={{ color: 'rgba(0, 0, 0, 0.65)' }} />
                  }
                  type="text"
                />
              </Space>
            </div>
          </div>
        )}
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            setVisible(true);
          }}
          type="dashed"
        >
          {formatMessage('自定义时间')}
        </Button>
      </div>
    </div>
  );
};

export default DynamicDate;
