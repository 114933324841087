import ahm from '@/assets/oshc/ahm.png';
import allianz from '@/assets/oshc/allianz.png';
import bupa from '@/assets/oshc/bupa.png';
import cbhs from '@/assets/oshc/cbhs.jpg';
import medibank from '@/assets/oshc/medibank.jpg';
import nib from '@/assets/oshc/nib.png';

export const getOSHCCompanyOptions = () => [
  { label: 'Bupa', value: 'Bupa', logo: bupa },
  { label: 'CBHS', value: 'CBHS', logo: cbhs },
  { label: 'Medibank', value: 'Medibank', logo: medibank },
  { label: 'Allianz', value: 'Allianz', logo: allianz },
  { label: 'NIB', value: 'NIB', logo: nib },
  {
    label: 'AHM',
    value: 'AHM',
    logo: ahm,
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
