const FACULTY = [
  {
    enName: 'Information Technology',
    value: '02',
    cnName: '信息技术',
  },
  {
    enName: 'Natural and Physical Sciences',
    value: '01',
    cnName: '自然和物理科学',
  },
  {
    enName: 'Engineering and Related Technologies',
    value: '03',
    cnName: '工程和相关技术',
  },
  {
    enName: 'Architecture and Building',
    value: '04',
    cnName: '工程和建筑',
  },
  {
    enName: 'Agriculture,Environmental and Related Studies',
    value: '05',
    cnName: '农业，环境和相关研究',
  },
  {
    enName: 'Health',
    value: '06',
    cnName: '健康',
  },
  {
    enName: 'Education',
    value: '07',
    cnName: '教育',
  },
  {
    enName: 'Management and Commerce',
    value: '08',
    cnName: '管理和商务',
  },
  {
    enName: 'Society and Culture',
    value: '09',
    cnName: '社会与文化',
  },
  {
    enName: 'Creative Arts',
    value: '10',
    cnName: '创意艺术',
  },
  {
    enName: 'Food,Hospitality and Personal Services',
    value: '11',
    cnName: '食品，酒店和私人服务',
  },
  {
    enName: 'Mixed Field Programmes',
    value: '12',
    cnName: '混合课程(如双学位)',
  },
];

export const getFacultyOptions = (
  lang: string,
): { label: string; value: string }[] => {
  const key = lang === 'zh-CN' ? 'cnName' : 'enName';

  return FACULTY.map((item) => ({
    value: item.value,
    label: item[key],
  }));
};

export const getFacultyName = (value: string, lang: string): string => {
  const target = FACULTY.find((item) => item.value === value)!;
  const key = lang === 'zh-CN' ? 'cnName' : 'enName';

  return target?.[key];
};
