import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum ExtraPointReviewStatus {
  PROCESSING = 1,
  SUCCESS = 2,
  FAILED = 3,
  REVOKED = 4,
}

export const useExtraPointReviewStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () =>
      new Map([
        [
          ExtraPointReviewStatus.PROCESSING,
          {
            text: formatMessage('审核中'),
            status: 'processing',
          },
        ],
        [
          ExtraPointReviewStatus.SUCCESS,
          {
            text: formatMessage('审核通过'),
            status: 'success',
          },
        ],
        [
          ExtraPointReviewStatus.FAILED,
          {
            text: formatMessage('已拒绝'),
            status: 'error',
          },
        ],
        [
          ExtraPointReviewStatus.REVOKED,
          {
            text: formatMessage('已撤销'),
            status: 'warning',
          },
        ],
      ]),
    [formatMessage],
  );
};
