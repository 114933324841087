import createExtraPermission1, {
  ExtraPermissionEnum as ExtraPermissionEnum1,
} from './createExtraPermission';

export * from './hooks';

export {
  ExtraPermissionEnum,
  default as createExtraPermission,
} from './createExtraPermission';
export { default as createAccessable } from './createAccessable';
export { default as IAccessable } from './IAccessable';

export const BillFeatureAccessable = createExtraPermission1(
  ExtraPermissionEnum1.Bill,
);
export const FileAssistantFeatureAccessable = createExtraPermission1(
  ExtraPermissionEnum1.FileSign,
);
export const FileSignFeatureAccessable = createExtraPermission1(
  ExtraPermissionEnum1.FileAssistant,
);
export const PointFeatureAccessable = createExtraPermission1(
  ExtraPermissionEnum1.Point,
);
