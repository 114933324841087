import { ProColumnType, ProTableProps } from '@ant-design/pro-table';
import { isObject } from 'lodash';

const useCachedColumns = <T extends AnyObject>(
  cacheKey: string,
  columns: (ProColumnType<T> | undefined | null | boolean)[],
) => {
  const internalColumns = columns.filter(
    (item) => !!item && isObject(item),
  ) as ProColumnType<T>[];

  return {
    columns: internalColumns,
    columnsState: {
      persistenceKey: cacheKey,
    },
    tableLayout: 'fixed' as const,
  } as Pick<
    ProTableProps<any, any>,
    'columns' | 'columnsState' | 'tableLayout'
  >;
};

export default useCachedColumns;
