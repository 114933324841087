export enum MessageType {
  CLIENT = 'client',
  CASE = 'case',
  SYSTEM = 'system',
  POINT = 'point',
  NOTICE = 'notice',
}

export interface IMessageItem {
  cnContent: string;
  cnTitle: string;
  createdAt: string;
  enContent: string;
  enTitle: string;
  extra: null | AnyObject;
  hasRead: 0 | 1;
  id: number;
  type: MessageType;
}

export interface MessageTab {
  type: MessageType;
  title: string;
  unreaded: number;
  list: IMessageItem[];
}
