// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/runner/work/oneu-link-front/oneu-link-front/node_modules/.pnpm/@umijs+runtime@3.5.20_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/home/runner/work/oneu-link-front/oneu-link-front/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "name": "login",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Login' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/User/Login'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/layouts/wrappers/OuterClient'), loading: LoadingComponent})],
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "admin-login",
            "path": "/user/admin-login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__AdminLogin' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/User/AdminLogin'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/layouts/wrappers/OuterClient'), loading: LoadingComponent})],
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "register",
            "path": "/user/register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Register' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/User/Register'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/layouts/wrappers/OuterClient'), loading: LoadingComponent})],
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "forgot",
            "path": "/user/forgot",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__ForgotPassword' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/User/ForgotPassword'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/layouts/wrappers/OuterClient'), loading: LoadingComponent})],
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "invite",
            "path": "/user/invite",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Invite' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/User/Invite'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/layouts/wrappers/OuterClient'), loading: LoadingComponent})],
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "layout": false,
        "name": "system-update-prompt",
        "path": "/system-update-prompt",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SystemUpdate__Prompt' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/SystemUpdate/Prompt'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard",
        "name": "dashboard",
        "icon": "DashboardOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "access": "normalRouteAccessFilter",
        "useEmptyAccess": true,
        "order": 99,
        "shortcut": true,
        "routes": [
          {
            "exact": true,
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Workplace' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Dashboard/Workplace'), loading: LoadingComponent}),
            "name": "workplace",
            "path": "/dashboard/workplace",
            "hideInMenu": true
          }
        ]
      },
      {
        "path": "/account/settings",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__Sidebar' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Account/Settings/Sidebar'), loading: LoadingComponent}),
        "name": "account.settings",
        "hideInMenu": true,
        "extraPermission": "open",
        "routes": [
          {
            "path": "/account/settings",
            "redirect": "/account/settings/base",
            "exact": true
          },
          {
            "name": "base",
            "path": "/account/settings/base",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__BaseView' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Account/Settings/BaseView'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "webauthn",
            "path": "/account/settings/webauthn",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__WebAuthn' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Account/Settings/WebAuthn'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "password",
            "path": "/account/settings/password",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__Password' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Account/Settings/Password'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "payment-info",
            "path": "/account/settings/payment-info",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__PaymentInfo' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Account/Settings/PaymentInfo'), loading: LoadingComponent}),
            "extraPermission": "pointOpen",
            "exact": true
          },
          {
            "name": "association",
            "path": "/account/settings/association",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__Association' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Account/Settings/Association'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/account/log",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Log__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Account/Log/List'), loading: LoadingComponent}),
        "name": "account.log",
        "access": "normalRouteAccessFilter",
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/account/file-sign",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FileSign__Personal' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/FileSign/Personal'), loading: LoadingComponent}),
        "name": "account.file-sign",
        "hideInMenu": true,
        "access": "normalRouteAccessFilter",
        "extraPermission": "fileSignOpen",
        "exact": true
      },
      {
        "path": "/releases",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__ReleaseLog__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Admin/ReleaseLog/List'), loading: LoadingComponent}),
        "name": "releases",
        "access": "normalRouteAccessFilter",
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/releases/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__ReleaseLog__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Admin/ReleaseLog/Detail'), loading: LoadingComponent}),
        "name": "releases.detail",
        "access": "normalRouteAccessFilter",
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/welcome",
        "name": "welcome",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome__Index' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Welcome/Index'), loading: LoadingComponent}),
        "menuRender": false,
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/create-company",
        "name": "create-company",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome__CreateCompany' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Welcome/CreateCompany'), loading: LoadingComponent}),
        "menuRender": false,
        "hideInMenu": true,
        "exact": true
      },
      {
        "name": "system-tips",
        "path": "/system-tips",
        "access": "normalRouteAccessFilter",
        "icon": "ToolOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Tips' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Admin/Tips'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "system-accounts",
        "path": "/system-accounts",
        "access": "normalRouteAccessFilter",
        "icon": "ToolOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Accounts' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Admin/Accounts'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "system-companys",
        "path": "/system-companys",
        "access": "normalRouteAccessFilter",
        "icon": "ToolOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Companys' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Admin/Companys'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "system-update",
        "path": "/system-update",
        "icon": "AlertOutlined",
        "access": "normalRouteAccessFilter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "useEmptyAccess": true,
        "routes": [
          {
            "path": "/system-update/list",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SystemUpdate__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/SystemUpdate/List'), loading: LoadingComponent}),
            "name": "list",
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "detail",
            "path": "/system-update/detail",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SystemUpdate__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/SystemUpdate/Detail'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          }
        ]
      },
      {
        "name": "system",
        "path": "/system",
        "icon": "SettingOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "access": "normalRouteAccessFilter",
        "useEmptyAccess": true,
        "routes": [
          {
            "path": "/system/router-rule",
            "name": "router-rule",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__RouterRuleList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/System/RouterRuleList'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/system/api-rule",
            "name": "api-rule",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__APIRuleList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/System/APIRuleList'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/system/role-group",
            "name": "role-group",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__RoleGroupList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/System/RoleGroupList'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          }
        ]
      },
      {
        "path": "/channel-case",
        "name": "channel-case",
        "icon": "AppstoreOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "access": "normalRouteAccessFilter",
        "useEmptyAccess": true,
        "order": 92,
        "shortcut": true,
        "routes": [
          {
            "path": "/channel-case/list",
            "access": "normalRouteAccessFilter",
            "name": "list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelCase__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ChannelCase/List'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/channel-case/detail/:id",
            "access": "normalRouteAccessFilter",
            "name": "detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelCase__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ChannelCase/Detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/channel-client",
        "name": "channel-client",
        "icon": "TeamOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "access": "normalRouteAccessFilter",
        "useEmptyAccess": true,
        "order": 90,
        "shortcut": true,
        "routes": [
          {
            "path": "/channel-client/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelClient__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ChannelClient/List'), loading: LoadingComponent}),
            "name": "list",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/channel-client/detail/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelClient__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ChannelClient/Detail'), loading: LoadingComponent}),
            "name": "detail",
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "access": "normalRouteAccessFilter",
        "path": "/my-master-agent",
        "name": "my-master-agent",
        "icon": "TeamOutlined",
        "extraPermission": "myMasterAgent",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MasterAgent__MyAgent' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/MasterAgent/MyAgent'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/oneulink-assistance",
        "name": "oneulink-assistance",
        "icon": "ContactsOutlined",
        "access": "normalRouteAccessFilter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "useEmptyAccess": true,
        "routes": [
          {
            "path": "/oneulink-assistance/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OneULinkAssistance__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/OneULinkAssistance/List'), loading: LoadingComponent}),
            "name": "list",
            "access": "normalRouteAccessFilter",
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "access": "normalRouteAccessFilter",
        "path": "/case-tag",
        "name": "case-tag",
        "icon": "TagOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CaseTag__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CaseTag/List'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/file-remark",
        "access": "normalRouteAccessFilter",
        "name": "file-remark",
        "icon": "TagOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "useEmptyAccess": true,
        "routes": [
          {
            "path": "/file-remark/coe",
            "name": "coe-attachment",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FileRemark__CoE' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/FileRemark/CoE'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/file-remark/offer",
            "name": "offer",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FileRemark__Offer' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/FileRemark/Offer'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/file-remark/cate",
            "name": "cate",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FileRemark__Category' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/FileRemark/Category'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/file-remark/student-visa",
            "name": "student-visa",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FileRemark__StudentVisa' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/FileRemark/StudentVisa'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          }
        ]
      },
      {
        "path": "/master-agent",
        "icon": "ClusterOutlined",
        "access": "normalRouteAccessFilter",
        "name": "master-agent",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "useEmptyAccess": true,
        "routes": [
          {
            "path": "/master-agent/list",
            "name": "list",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MasterAgent__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/MasterAgent/List'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/master-agent/:id",
            "name": "detail",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MasterAgent__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/MasterAgent/Detail'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/master-agent/:id/:schoolId",
            "name": "detail.school",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MasterAgent__SchoolDetail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/MasterAgent/SchoolDetail'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "parentKeys": [
              "/master-agent/:id"
            ],
            "exact": true
          }
        ]
      },
      {
        "path": "/analysis",
        "name": "analysis",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "access": "normalRouteAccessFilter",
        "icon": "AppstoreOutlined",
        "useEmptyAccess": true,
        "order": -90,
        "routes": [
          {
            "path": "/analysis/asqa",
            "name": "asqa",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Analysis__ASQA' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Analysis/ASQA'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/analysis/school",
            "name": "school",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Analysis__School' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Analysis/School'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/analysis/course",
            "name": "course",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Analysis__Course' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Analysis/Course'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/analysis/trend",
            "name": "trend",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Analysis__Trend' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Analysis/Trend'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/analysis/agent",
            "name": "agent",
            "routes": [
              {
                "path": "/analysis/agent/sumary",
                "name": "summary",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Analysis__Agent__Summary' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Analysis/Agent/Summary'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/analysis/agent/compare",
                "name": "compare",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Analysis__Agent__Compare' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Analysis/Agent/Compare'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/analysis/agent/detail",
                "name": "detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Analysis__Agent__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Analysis/Agent/Detail'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/data-config",
        "name": "data-config",
        "icon": "SettingOutlined",
        "access": "normalRouteAccessFilter",
        "routes": [
          {
            "name": "review-payment-info",
            "path": "/data-config/review-payment-info",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OneULinkAdmin__ReviewPaymentInfo' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/OneULinkAdmin/ReviewPaymentInfo'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/data-config/course-checklist",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataConfig__CourseChecklist' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/DataConfig/CourseChecklist'), loading: LoadingComponent}),
            "name": "course-checklist",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/data-config/skill-assess",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataConfig__SkillAssess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/DataConfig/SkillAssess'), loading: LoadingComponent}),
            "name": "skill-assess",
            "exact": true
          },
          {
            "path": "/data-config/occupation",
            "name": "occupation",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataConfig__Occupation' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/DataConfig/Occupation'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/data-config/rpl-discount",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataConfig__RplDiscount' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/DataConfig/RplDiscount'), loading: LoadingComponent}),
            "name": "rpl-discount",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "name": "assist-promotion",
            "path": "/data-config/assist-promotion",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataConfig__AIPromotion' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/DataConfig/AIPromotion'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "publicity-access",
            "path": "/data-config/publicity-access",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OneULinkAdmin__Publicity' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/OneULinkAdmin/Publicity'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/data-config/file-category",
            "name": "file-category",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FileCabinetsConfig__Category' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/FileCabinetsConfig/Category'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/data-config/auto-submit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OneULinkAdmin__AutoSubmit' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/OneULinkAdmin/AutoSubmit'), loading: LoadingComponent}),
            "name": "auto-submit",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/data-config/email-blacklist",
            "name": "email-blacklist",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OneULinkAdmin__EmailBlacklist' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/OneULinkAdmin/EmailBlacklist'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          }
        ]
      },
      {
        "path": "/agent-case",
        "name": "agent-case",
        "icon": "AppstoreOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "access": "normalRouteAccessFilter",
        "useEmptyAccess": true,
        "order": 92,
        "shortcut": true,
        "routes": [
          {
            "path": "/agent-case/list",
            "access": "normalRouteAccessFilter",
            "name": "list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentCase__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/AgentCase/List'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/agent-case/detail/:id",
            "access": "normalRouteAccessFilter",
            "name": "detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentCase__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/AgentCase/Detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/agent-client",
        "name": "agent-client",
        "icon": "TeamOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "access": "normalRouteAccessFilter",
        "useEmptyAccess": true,
        "order": 90,
        "shortcut": true,
        "routes": [
          {
            "path": "/agent-client/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentClient__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/AgentClient/List'), loading: LoadingComponent}),
            "name": "list",
            "access": "normalRouteAccessFilter",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/agent-client/detail/:id",
            "access": "normalRouteAccessFilter",
            "name": "detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentClient__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/AgentClient/Detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/agent-client/detail/:id/email/:emailId",
            "access": "normalRouteAccessFilter",
            "name": "detail-email",
            "parentKeys": [
              "/agent-client/detail/:id"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentClient__EmailDetail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/AgentClient/EmailDetail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/point",
        "name": "point",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyPoint' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/MyPoint'), loading: LoadingComponent}),
        "access": "normalRouteAccessFilter",
        "order": 89,
        "shortcut": true,
        "extraPermission": "pointOpen",
        "icon": "DollarCircleOutlined",
        "exact": true
      },
      {
        "name": "financial",
        "access": "normalRouteAccessFilter",
        "icon": "PoundCircleOutlined",
        "order": 91,
        "useEmptyAccess": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "path": "/financial",
        "routes": [
          {
            "access": "normalRouteAccessFilter",
            "path": "/financial/company-bill",
            "name": "company-bill",
            "icon": "AccountBookOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyBill__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyBill/List'), loading: LoadingComponent}),
            "extraPermission": "billOpen",
            "exact": true
          },
          {
            "access": "normalRouteAccessFilter",
            "path": "/financial/company-coe-payment",
            "name": "company-coe-payment",
            "icon": "PayCircleOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyCoePayment__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyCoePayment/List'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "access": "normalRouteAccessFilter",
            "path": "/financial/channel-bill",
            "name": "channel-bill",
            "icon": "AccountBookOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelBill__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ChannelBill/List'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "access": "normalRouteAccessFilter",
            "path": "/financial/channel-coe-payment",
            "name": "channel-coe-payment",
            "icon": "PayCircleOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelCoePayment__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ChannelCoePayment/List'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/financial/c2c-point-agent/coe",
            "name": "c2c-point-agent.coe",
            "access": "normalRouteAccessFilter",
            "icon": "DollarCircleOutlined",
            "extraPermission": "pointOpen",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__C2CCoE__Agent' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/C2CCoE/Agent'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/financial/company-point",
            "name": "company-point",
            "icon": "WalletOutlined",
            "access": "normalRouteAccessFilter",
            "extraPermission": "pointOpen",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/financial/company-point/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyPoint__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyPoint/List'), loading: LoadingComponent}),
                "name": "list",
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/financial/company-point/extra",
                "name": "extra",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyPoint__Extra' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyPoint/Extra'), loading: LoadingComponent}),
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/financial/company-point/withdraw",
                "name": "withdraw",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyPoint__Withdraw' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyPoint/Withdraw'), loading: LoadingComponent}),
                "access": "normalRouteAccessFilter",
                "exact": true
              }
            ]
          },
          {
            "path": "/financial/c2c-point-channel",
            "name": "c2c-point-channel",
            "icon": "WalletOutlined",
            "access": "normalRouteAccessFilter",
            "extraPermission": "pointOpen",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/financial/c2c-point-channel/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__C2CPoint__Channel__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/C2CPoint/Channel/List'), loading: LoadingComponent}),
                "name": "list",
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/financial/c2c-point-channel/extra",
                "name": "extra",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__C2CPoint__Channel__Extra' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/C2CPoint/Channel/Extra'), loading: LoadingComponent}),
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/financial/c2c-point-channel/withdraw",
                "name": "withdraw",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__C2CPoint__Channel__Withdraw' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/C2CPoint/Channel/Withdraw'), loading: LoadingComponent}),
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/financial/c2c-point-channel/coe",
                "name": "coe",
                "access": "normalRouteAccessFilter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__C2CCoE__Channel' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/C2CCoE/Channel'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/company-admin",
        "name": "company-admin",
        "icon": "SettingOutlined",
        "access": "normalRouteAccessFilter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "useEmptyAccess": true,
        "routes": [
          {
            "path": "/company-admin/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyAdmin__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyAdmin/Detail'), loading: LoadingComponent}),
            "name": "detail",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/company-admin/bill",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyAdmin__BillSettings' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyAdmin/BillSettings'), loading: LoadingComponent}),
            "name": "bill-settings",
            "extraPermission": "billOpen",
            "exact": true
          },
          {
            "path": "/company-admin/settings",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyAdmin__Settings' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyAdmin/Settings'), loading: LoadingComponent}),
            "name": "settings",
            "exact": true
          },
          {
            "path": "/company-admin/case-point-settings",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyAdmin__CasePointSettings' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyAdmin/CasePointSettings'), loading: LoadingComponent}),
            "name": "case-point-settings",
            "extraPermission": "pointOpen",
            "exact": true
          },
          {
            "access": "normalRouteAccessFilter",
            "path": "/company-admin/material-recycle",
            "name": "material-recycle",
            "icon": "DeleteOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyAdmin__MaterialRecycle' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyAdmin/MaterialRecycle'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/company-admin/message-task",
            "name": "message-task",
            "icon": "ScheduleOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "extraPermission": "onlyOneUEducation",
            "useEmptyAccess": true,
            "routes": [
              {
                "path": "/company-admin/message-task/template",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyAdmin__MessageTask__Template' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyAdmin/MessageTask/Template'), loading: LoadingComponent}),
                "name": "template",
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/company-admin/message-task/log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyAdmin__MessageTask__Log' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyAdmin/MessageTask/Log'), loading: LoadingComponent}),
                "name": "log",
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/company-admin/message-task/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyAdmin__MessageTask__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyAdmin/MessageTask/List'), loading: LoadingComponent}),
                "name": "list",
                "access": "normalRouteAccessFilter",
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/company-staff",
        "name": "company-staff",
        "icon": "ApartmentOutlined",
        "access": "normalRouteAccessFilter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "useEmptyAccess": true,
        "routes": [
          {
            "path": "/company-staff/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyStaff__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyStaff/List'), loading: LoadingComponent}),
            "name": "list",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/company-staff/invite-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyStaff__InviteList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyStaff/InviteList'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "name": "invite-list",
            "exact": true
          },
          {
            "path": "/company-staff/job-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyStaff__JobList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyStaff/JobList'), loading: LoadingComponent}),
            "name": "job-list",
            "access": "normalRouteAccessFilter",
            "exact": true
          }
        ]
      },
      {
        "path": "/custom-case",
        "name": "custom-case",
        "icon": "CodeOutlined",
        "access": "normalRouteAccessFilter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/custom-case/custom-detail",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomCase__CustomDetail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CustomCase/CustomDetail'), loading: LoadingComponent}),
            "name": "custom-detail",
            "parentKey": [
              "/custom-case/custom-list"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/custom-case/builtin-detail",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomCase__BuiltinDetail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CustomCase/BuiltinDetail'), loading: LoadingComponent}),
            "name": "builtin-detail",
            "parentKey": [
              "/custom-case/builtin-list"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/custom-case/builtin-list",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomCase__BuiltinList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CustomCase/BuiltinList'), loading: LoadingComponent}),
            "name": "builtin-list",
            "exact": true
          },
          {
            "path": "/custom-case/custom-list",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomCase__CustomList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CustomCase/CustomList'), loading: LoadingComponent}),
            "name": "custom-list",
            "exact": true
          },
          {
            "path": "/custom-case/steps-config",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomCase__StepsConfig' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CustomCase/StepsConfig'), loading: LoadingComponent}),
            "name": "steps-config",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "access": "normalRouteAccessFilter",
            "path": "/custom-case/custom-category",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomCase__CategoryList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CustomCase/CategoryList'), loading: LoadingComponent}),
            "name": "custom-category",
            "exact": true
          },
          {
            "path": "/custom-case/custom-form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomCase__CustomFormList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CustomCase/CustomFormList'), loading: LoadingComponent}),
            "name": "custom-form",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/custom-case/steps-category",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomCase__StepsCategory' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CustomCase/StepsCategory'), loading: LoadingComponent}),
            "name": "steps-category",
            "access": "normalRouteAccessFilter",
            "exact": true
          }
        ]
      },
      {
        "path": "/company-logs",
        "name": "company-logs",
        "icon": "SafetyOutlined",
        "access": "normalRouteAccessFilter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "useEmptyAccess": true,
        "routes": [
          {
            "path": "/company-logs/operation-list",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyLogs__OperationList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyLogs/OperationList'), loading: LoadingComponent}),
            "name": "operation-list",
            "exact": true
          },
          {
            "access": "normalRouteAccessFilter",
            "path": "/company-logs/log",
            "name": "log",
            "icon": "DatabaseOutlined",
            "routes": [
              {
                "path": "/company-logs/log/staff",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CompanyLogs__Log__Staff' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/CompanyLogs/Log/Staff'), loading: LoadingComponent}),
                "name": "staff",
                "access": "normalRouteAccessFilter",
                "hideInMenu": true,
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/client-user/create",
        "name": "client-user.create",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__Create' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/Create'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/visitor-visa",
        "name": "client-user.visitor-visa",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__VisitorVisa' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/VisitorVisa'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/student-visa",
        "name": "client-user.student-visa",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__StudentVisa' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/StudentVisa'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/bridging-visa-b",
        "name": "client-user.bridging-visa-b",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__BridgingVisaB' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/BridgingVisaB'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/subsequent-student-visa",
        "name": "client-user.subsequent-student-visa",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__SubsequentStudentVisa' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/SubsequentStudentVisa'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/guardian-student-visa",
        "name": "client-user.guardian-student-visa",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__GuardianStudentVisa' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/GuardianStudentVisa'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/485-visa",
        "name": "client-user.485-visa",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__PSWVisa' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/PSWVisa'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/gte",
        "name": "client-user.gte",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__GTE' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/GTE'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/956-form",
        "name": "client-user.956-form",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__956Form' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/956Form'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/contact-info-update/:number",
        "name": "client-user.gte",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__ContactInfoUpdate' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/ContactInfoUpdate'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/view-offer/:id",
        "name": "client-user.view-offer",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__ViewOffer' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/ViewOffer'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/offer/:id",
        "name": "client-user.offer",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__Offer' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/Offer'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/contract/:number",
        "name": "client-user.contract",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__Contract' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/Contract'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/contract-view/:number",
        "name": "client-user.contract-view",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__ContractView' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/ContractView'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/sa/:id/:applicationId",
        "name": "client-user.school-apply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__SchoolApply' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/SchoolApply'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/sa/:id",
        "name": "client-user.school-apply-nav",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__SchoolApplyNav' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/SchoolApplyNav'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/aa/:id",
        "name": "client-user.agent-apply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__AgentApply' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/AgentApply'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/client-user/prp",
        "name": "client-user.prp",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientUser__PrPlanner' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/ClientUser/PrPlanner'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/offer-form/:id",
        "name": "offer-form",
        "access": "normalRouteAccessFilter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OfferForm' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/OfferForm'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/visa-like-me",
        "name": "visa-like-me",
        "icon": "SearchOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaLikeMe__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/VisaLikeMe/List'), loading: LoadingComponent}),
        "access": "normalRouteAccessFilter",
        "exact": true
      },
      {
        "path": "/school",
        "name": "school",
        "icon": "ReadOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "useEmptyAccess": true,
        "access": "normalRouteAccessFilter",
        "order": 85,
        "shortcut": true,
        "routes": [
          {
            "path": "/school/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__School__SchoolList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/School/SchoolList'), loading: LoadingComponent}),
            "name": "list",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/school/course-alternative-titles",
            "name": "course-alternative-titles",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__School__CourseAlternativeTitles' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/School/CourseAlternativeTitles'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/school/course-tutoring-company",
            "name": "course-tutoring-company",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__School__CourseTutoringCompany' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/School/CourseTutoringCompany'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/school/rpl-course-list",
            "name": "rpl-course-list",
            "access": "normalRouteAccessFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__School__RplCourseList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/School/RplCourseList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/school/detail/:code",
            "access": "normalRouteAccessFilter",
            "name": "detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__School__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/School/Detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/publicity",
        "name": "publicity",
        "icon": "ToolOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "useEmptyAccess": true,
        "access": "normalRouteAccessFilter",
        "routes": [
          {
            "path": "/publicity/course-checklist",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentPublicity__CourseChecklist' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/AgentPublicity/CourseChecklist'), loading: LoadingComponent}),
            "name": "course-checklist",
            "access": "normalRouteAccessFilter",
            "extraPermission": "publicityOpen",
            "exact": true
          },
          {
            "path": "/publicity/visa-fees",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaFees__List' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/VisaFees/List'), loading: LoadingComponent}),
            "name": "visa-fees-list",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/publicity/visa-fees/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaFees__Detail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/VisaFees/Detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "name": "visa-fees-detail",
            "parentKeys": [
              "/publicity/visa-fees"
            ],
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/publicity/offer",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__School__Offer' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/School/Offer'), loading: LoadingComponent}),
            "name": "offer",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/publicity/part-study",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentPublicity__PartStudy' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/AgentPublicity/PartStudy'), loading: LoadingComponent}),
            "name": "part-study",
            "exact": true
          },
          {
            "path": "/publicity/prp",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentPublicity__PrPlanners' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/AgentPublicity/PrPlanners'), loading: LoadingComponent}),
            "name": "prp",
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/publicity/prp/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentPublicity__PrPlannerDetail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/AgentPublicity/PrPlannerDetail'), loading: LoadingComponent}),
            "name": "prp-detail",
            "parentKeys": [
              "publicity.prp"
            ],
            "layout": false,
            "hideInMenu": true,
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/publicity/pr-planner",
            "name": "pr-planner",
            "icon": "StockOutlined",
            "access": "normalRouteAccessFilter",
            "routes": [
              {
                "path": "/publicity/pr-planner/diff",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PrPlanner__DiffList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/PrPlanner/DiffList'), loading: LoadingComponent}),
                "name": "diff",
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/publicity/pr-planner/channel",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PrPlanner__ChannelList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/PrPlanner/ChannelList'), loading: LoadingComponent}),
                "name": "channel",
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/publicity/pr-planner/course",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PrPlanner__Course' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/PrPlanner/Course'), loading: LoadingComponent}),
                "name": "course",
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/publicity/pr-planner/channel/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PrPlanner__ChannelDetail' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/PrPlanner/ChannelDetail'), loading: LoadingComponent}),
                "name": "channel-detail",
                "hideInMenu": true,
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/publicity/pr-planner/assess-condition",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PrPlanner__AssessConditionList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/PrPlanner/AssessConditionList'), loading: LoadingComponent}),
                "name": "assess-condition",
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/publicity/pr-planner/cert-condition",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PrPlanner__CertConditionList' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/PrPlanner/CertConditionList'), loading: LoadingComponent}),
                "name": "cert-condition",
                "access": "normalRouteAccessFilter",
                "exact": true
              },
              {
                "path": "/publicity/pr-planner/version",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PrPlanner__Version' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/PrPlanner/Version'), loading: LoadingComponent}),
                "name": "version",
                "access": "normalRouteAccessFilter",
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/students",
        "name": "student",
        "access": "normalRouteAccessFilter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmptyAccess' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/EmptyAccess'), loading: LoadingComponent}),
        "icon": "TeamOutlined",
        "routes": [
          {
            "path": "/students/accounts",
            "name": "accounts",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Student__Accounts' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Student/Accounts'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/students/point-details",
            "name": "point-details",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Student__PointDetails' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Student/PointDetails'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/students/case-point-bonus",
            "name": "case-point-bonus",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Student__CasePointBonus' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Student/CasePointBonus'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          },
          {
            "path": "/students/use-points-record",
            "name": "use-points-record",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Student__UsePointsRecord' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Student/UsePointsRecord'), loading: LoadingComponent}),
            "access": "normalRouteAccessFilter",
            "exact": true
          }
        ]
      },
      {
        "path": "/exception403",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception403' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Exception403'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/exception500",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception500' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/Exception500'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/runner/work/oneu-link-front/oneu-link-front/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
