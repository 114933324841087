export enum RoleType {
  BACKEND = 'backend',
  SCHOOL = 'school',
  AGENT = 'agent',
  GUEST = 'guest',
  CHANNEL = 'channel',
}

export const RoleOptions: {
  label: RoleType;
  value: RoleType;
}[] = [
  RoleType.BACKEND,
  RoleType.SCHOOL,
  RoleType.AGENT,
  RoleType.GUEST,
  RoleType.CHANNEL,
].map((item) => ({
  value: item,
  label: item,
}));

export enum AccountType {
  NORMAL = 'normal',
  ASSISTANT = 'assistant',
}
