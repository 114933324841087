import raf from 'raf';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

const rafDebounce = <T extends (...args: any[]) => any>(
  fn: T,
): (() => void) => {
  let id = 0;

  return () => {
    if (id) {
      raf.cancel(id);
      id = 0;
    }

    id = raf(fn);
  };
};

const useForceUpdate = () => {
  const [, set] = useState(0);
  const unmountedRef = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const update = useCallback(
    rafDebounce(() => {
      if (!unmountedRef.current) {
        set((p) => p + 1);
      }
    }),
    [],
  );

  useLayoutEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return [update];
};

export default useForceUpdate;
