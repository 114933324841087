import { BadgeProps } from 'antd';
import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum PaymentInfoLocationType {
  CHINA = 'china',
  AUSTRALIA = 'australia',
}

export enum PaymentInfoReviewStatusType {
  PASS = 'pass',
  REFUSE = 'refuse',
  WAITING = 'waiting',
}

export const usePaymentInfoLocationEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () =>
      new Map([
        [
          PaymentInfoLocationType.CHINA,
          {
            text: formatMessage('中国'),
            color: 'blue',
          },
        ],
        [
          PaymentInfoLocationType.AUSTRALIA,
          {
            text: formatMessage('澳大利亚'),
            color: 'orange',
          },
        ],
      ]),
    [formatMessage],
  );
};

export const usePaymentInfoReviewStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () =>
      new Map([
        [
          PaymentInfoReviewStatusType.PASS,
          {
            text: formatMessage('审核通过'),
            status: 'success',
          },
        ],
        [
          PaymentInfoReviewStatusType.REFUSE,
          {
            text: formatMessage('审核失败'),
            status: 'error',
          },
        ],
        [
          PaymentInfoReviewStatusType.WAITING,
          {
            text: formatMessage('审核中'),
            status: 'processing',
          },
        ],
      ] as [PaymentInfoReviewStatusType, { text: string; status: BadgeProps['status'] }][]),
    [formatMessage],
  );
};
