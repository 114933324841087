const map: Record<
  typeof APP_ENV,
  {
    apiBaseUrl: string;
    websocketApiBaseUrl: string;
    analysisApiBaseUrl: string;
    HASH_KEY: string;
    schoolApplyUrl: string;
    schoolApplyNavUrl: string;
    fileSignUrl: string;
    origin: string;
    webAuthnBaseUrl: string;
    oneuEducationCompanyId: number;
    mcbtMasterAgentId?: number;
    officialWebsiteUrl: string;
    offshoreWechatApiBaseUrl: string;
    prpShareUrl: string;
    aiAssistDomain: string;
    assistDomain: string;
  }
> = {
  test: {
    apiBaseUrl: 'https://api-test.oneulink.com',
    websocketApiBaseUrl: 'wss://test-go.oneulink.com',
    analysisApiBaseUrl: 'https://test-wechat-front.oneuedu.com',
    HASH_KEY: '123456',
    schoolApplyUrl: `${window.location.origin}/client-user/sa/:id/:applicationId`,
    schoolApplyNavUrl: `${window.location.origin}/client-user/sa/:id`,
    fileSignUrl: 'https://docs-test.oneulink.com/sign/:code',
    origin: 'https://oneulinkapp-test.oneuedu.com',
    webAuthnBaseUrl: 'https://test-webauthn.oneulink.com',
    oneuEducationCompanyId: 1,
    mcbtMasterAgentId: 9,
    officialWebsiteUrl: 'https://test.oneuedu.com',
    prpShareUrl: `https://oneulinkapp-test.oneuedu.com/client-user/prp?n=:number`,
    offshoreWechatApiBaseUrl: 'https://test-offshore-wechat-front.oneuedu.com',
    aiAssistDomain: 'https://assist-test.oneuedu.com',
    assistDomain: 'assist-test.oneulink.com',
  },
  development: {
    apiBaseUrl: 'https://api-test.oneulink.com',
    websocketApiBaseUrl: 'wss://test-go.oneulink.com',
    analysisApiBaseUrl: 'https://test-wechat-front.oneuedu.com',
    HASH_KEY: '123456',
    schoolApplyUrl: `${window.location.origin}/client-user/sa/:id/:applicationId`,
    schoolApplyNavUrl: `${window.location.origin}/client-user/sa/:id`,
    fileSignUrl: 'https://docs-test.oneulink.com/sign/:code',
    origin: 'https://oneulinkapp-test.oneuedu.com',
    webAuthnBaseUrl: 'https://test-webauthn.oneulink.com',
    oneuEducationCompanyId: 1,
    mcbtMasterAgentId: 9,
    officialWebsiteUrl: 'https://test.oneuedu.com',
    prpShareUrl: `https://oneulinkapp-test.oneuedu.com/client-user/prp?n=:number`,
    offshoreWechatApiBaseUrl: 'https://test-offshore-wechat-front.oneuedu.com',
    aiAssistDomain: 'https://assist-test.oneuedu.com',
    assistDomain: 'assist-test.oneulink.com',
  },
  production: {
    apiBaseUrl: 'https://apis-main.oneulink.com',
    websocketApiBaseUrl: 'wss://gos-main.oneulink.com',
    analysisApiBaseUrl: 'https://wechat-front.oneuedu.com',
    HASH_KEY: 'U9x0z1hJ',
    schoolApplyUrl: `${window.location.origin}/client-user/sa/:id/:applicationId`,
    schoolApplyNavUrl: `${window.location.origin}/client-user/sa/:id`,
    fileSignUrl: 'https://docs.oneulink.com/sign/:code',
    origin: 'https://app.oneulink.com',
    webAuthnBaseUrl: 'https://webauthn-main.oneulink.com',
    oneuEducationCompanyId: 1,
    mcbtMasterAgentId: 8,
    officialWebsiteUrl: 'https://www.oneuedu.com',
    offshoreWechatApiBaseUrl: 'https://offshore-wechat-front.oneuedu.com',
    prpShareUrl: `https://app.oneulink.com/client-user/prp?n=:number`,
    aiAssistDomain: 'https://assist.oneuedu.com',
    assistDomain: 'assist.oneulink.com',
  },
};

export const ENV = map[APP_ENV];

export const IS_DEV = APP_ENV === 'development';
