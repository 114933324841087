import type { TagProps } from 'antd';
import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum HealthInsuranceType {
  OSHC = 'oshc',
  OVHC = 'ovhc',
}

export const useHealthInsuranceTypeEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () =>
      ({
        [HealthInsuranceType.OSHC]: {
          text: formatMessage('学生医疗保险'),
          color: 'blue',
        },
        [HealthInsuranceType.OVHC]: {
          text: formatMessage('工作签证医疗保险'),
          color: 'cyan',
        },
      } as Record<
        HealthInsuranceType,
        {
          text: string;
          color?: TagProps['color'];
        }
      >),
    [formatMessage],
  );
};
