import { QuestionCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Badge } from 'antd';
import { ResponseSchema, services } from '@/api';
import { HelpCenter } from '../HelpCenter';
import {
  UsedWebsocketChannel,
  UsedWebsocketEvent,
  useWebsocketSubscribe,
} from '../Websocket';
import styles from './index.less';

export default () => {
  const { refresh, data } = useRequest<
    ResponseSchema<{
      notViewedNumber: number;
    }>
  >(services.systemUpdate.front.getReleaseLogsNotViewNum);

  useWebsocketSubscribe(
    UsedWebsocketEvent.RELEASE_LOG_VISA_STATUS_CHANGE,
    refresh,
    {
      channel: UsedWebsocketChannel.MEMBER,
    },
  );

  return (
    <HelpCenter
      trigger={
        <span className={styles.action}>
          <Badge dot={!!data?.data.notViewedNumber}>
            <QuestionCircleOutlined />
          </Badge>
        </span>
      }
    />
  );
};
