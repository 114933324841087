import { TagProps } from 'antd';
import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum PointTypeEnum {
  WITHDRAW = 'withdraw',
  ADDITIONAL = 'additional',
  CASE = 'case',
  USE_POINT = 'usePoint',

  CHANNEL_CASE = 'channelCase',
  ASSISTANT_CASE = 'assistantCase',
}

export const usePointTypeEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum: Record<
      PointTypeEnum,
      {
        text?: string;
        color?: TagProps['color'];
      }
    > = {
      [PointTypeEnum.WITHDRAW]: {
        text: formatMessage('提现'),
        color: 'gold',
      },
      [PointTypeEnum.ADDITIONAL]: {
        text: formatMessage('额外申请'),
        color: 'green',
      },
      [PointTypeEnum.CASE]: {
        text: formatMessage('案件奖励'),
        color: 'blue',
      },
      [PointTypeEnum.USE_POINT]: {
        text: formatMessage('使用积分'),
        color: 'red',
      },
      [PointTypeEnum.CHANNEL_CASE]: {
        text: formatMessage('案件奖励'),
        color: 'blue',
      },
      [PointTypeEnum.ASSISTANT_CASE]: {
        text: formatMessage('案件协助'),
        color: 'pink',
      },
    };

    return new Map(
      Object.entries(statusEnum).map((item) => [item[0], item[1]]),
    );
  }, [formatMessage]);
};
