import { CloseOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Divider, Space, Tabs } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { clearBodyLocks, lock } from 'tua-body-scroll-lock';
import { services } from '@/api';
import { useFormatMessage } from '@/hooks';
import HistoryMessageList, {
  HistoryMessageListRef,
} from './HistoryMessageList';
import styles from './index.less';
import { MessageType } from './type';
import useMessageMap from './useMessageMap';

const HistoryMessage: React.FC<{
  visible: boolean;
  onVisibilityChange: (visible: boolean) => void;
}> = ({ visible, onVisibilityChange }) => {
  const { tabs } = useMessageMap();
  const { formatMessage } = useFormatMessage();
  const [activeType, setActiveType] = useState<MessageType>(MessageType.CLIENT);
  const refs = useRef<Record<string, HistoryMessageListRef | null>>({});
  const { run: markAll, loading: markAllLoading } = useRequest(
    services.message.markAllAsRead,
    {
      manual: true,
      onSuccess() {
        Object.values(refs.current).forEach((item) => item?.refresh());
      },
    },
  );

  useLayoutEffect(() => {
    if (visible) {
      lock();
    }

    return clearBodyLocks;
  }, [visible]);

  return createPortal(
    <div>
      <AnimatePresence>
        {visible && (
          <motion.div className={styles.history}>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                ease: 'linear',
              }}
              onClick={() => onVisibilityChange(false)}
              className={styles.mask}
            />
            <motion.div
              initial={{
                y: '-120%',
              }}
              animate={{
                y: '-0%',
              }}
              exit={{
                y: '-120%',
              }}
              transition={{
                delay: 0.1,
                stiffness: 600,
                damping: 20,
                velocity: visible ? 2000 : -100,
              }}
              className={styles.historyContent}
            >
              <Tabs
                size="large"
                activeKey={activeType}
                onChange={(c) => {
                  if (c === activeType) {
                    refs.current[activeType]?.refresh();
                  } else {
                    setActiveType(c as MessageType);
                  }
                }}
                tabBarExtraContent={
                  <Space>
                    <Button
                      onClick={() => markAll()}
                      loading={markAllLoading}
                      type="text"
                    >
                      {formatMessage('标记为全部已读')}
                    </Button>
                    <Divider orientation="center" />
                    <CloseOutlined onClick={() => onVisibilityChange(false)} />
                  </Space>
                }
              >
                {tabs.map((item) => (
                  <Tabs.TabPane key={item.type} tab={item.title}>
                    <HistoryMessageList
                      ref={(inst) => {
                        refs.current[item.type] = inst;
                      }}
                      onClose={() => onVisibilityChange(false)}
                      visible={item.type === activeType}
                      type={item.type}
                    />
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>,
    document.body,
  );
};

export default HistoryMessage;
