import React, { useCallback, useImperativeHandle, useState } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  IndexRange,
  InfiniteLoader,
  List,
  ListProps,
  ListRowRenderer,
} from 'react-virtualized';
import { sleep } from '@/utils';

export interface CustomVirtualListRef {
  clearLoadedRowsMap: () => void;
}

const CustomVirtualList = <D extends AnyObject = AnyObject>(
  {
    infinite,
    renderItem,
    data,
    loadMore,
    onRowsRendered,
    ...rest
  }: {
    renderItem: (data: D) => React.ReactNode;
    data?: D[];
    infinite?: boolean;
    loading?: boolean;
    loadMore?: () => void;
  } & Pick<ListProps, 'noRowsRenderer' | 'estimatedRowSize' | 'onRowsRendered'>,
  ref: React.Ref<CustomVirtualListRef>,
) => {
  const cache = useState(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
      }),
  )[0];
  const loadedRowsMap = useState(() => new Map<number, boolean>())[0];

  const render: ListRowRenderer = ({
    key,
    style,
    index,
    parent,
    columnIndex,
  }) => {
    return (
      <CellMeasurer
        cache={cache}
        key={key}
        rowIndex={index}
        columnIndex={columnIndex}
        parent={parent}
      >
        <div key={data?.[index].id} style={style}>
          {data?.[index] && renderItem(data[index])}
        </div>
      </CellMeasurer>
    );
  };

  const handleLoadMore = useCallback(
    async ({ startIndex, stopIndex }: IndexRange) => {
      for (let i = startIndex; i <= stopIndex; i++) {
        loadedRowsMap.set(i, true);
      }

      loadMore?.();

      await sleep(500);
    },
    [loadMore, loadedRowsMap],
  );

  const handleRowsRendered: ListProps['onRowsRendered'] = (info) => {
    onRowsRendered?.(info);
  };

  useImperativeHandle(ref, () => ({
    clearLoadedRowsMap: () => {
      loadedRowsMap.clear();
    },
  }));

  const props = ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }): ListProps => ({
    width,
    height,
    overscanRowCount: 2,
    rowCount: data?.length ?? 0,
    deferredMeasurementCache: cache,
    rowHeight: cache.rowHeight,
    rowRenderer: render,
    ...rest,
  });

  if (!infinite) {
    return (
      <AutoSizer>
        {({ width, height }) => <List {...props({ width, height })}> </List>}
      </AutoSizer>
    );
  }

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => !!loadedRowsMap.get(index)}
      loadMoreRows={handleLoadMore}
      rowCount={data?.length ?? 0}
      minimumBatchSize={15}
    >
      {({
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onRowsRendered,
        registerChild,
      }) => (
        <AutoSizer>
          {({ width, height }) => (
            <List
              ref={registerChild}
              deferredMeasurementCache={cache}
              {...props({ width, height })}
              onRowsRendered={(info) => {
                handleRowsRendered(info);
                onRowsRendered(info);
              }}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

export default React.forwardRef(CustomVirtualList);
