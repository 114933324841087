import React, { memo } from 'react';
import ReactShadowRoot from 'react-shadow-root';
import SetInnerHTML from '@/components/SetInnerHTML';
import css from './reset.txt';

const EmailHTMLBody: React.FC<{
  html?: string;
  css?: string;
  container?: keyof JSX.IntrinsicElements;
  className?: string;
}> = ({ html = '<div />', className, css: customCSS, container = 'div' }) => {
  const style = `
  ${css}
  img {
    max-width: 100%;
    height: auto;
  }
  ${customCSS}
  `;

  return React.createElement(
    container,
    { className },
    <ReactShadowRoot>
      <style>{style}</style>
      <SetInnerHTML html={html?.replace(/(?:\r\n&nbsp;)+$/g, '')} />
    </ReactShadowRoot>,
  );
};

export default memo(EmailHTMLBody);
