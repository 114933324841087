import { Typography, notification } from 'antd';
import axios from 'axios';
import { debounce, isObject } from 'lodash';
import { getLocale, history } from 'umi';
import RequestClient, {
  GetObjectAllFields,
  RequestPlugin,
  ResponseData,
} from 'zyouh-request';
import authPlugin from 'zyouh-request/dist/plugins/auth';
import extractResponseDataPlugin from 'zyouh-request/dist/plugins/extractResponseData';
import responseErrorNotify from 'zyouh-request/dist/plugins/responseErrorNotify';
import {
  getToken,
  isBackendAdminPage,
  isNonLoginStatusPage,
  logout,
  removeToken,
} from '@/auth';
import { ENV } from '@/env';
import { getFormAuthToken } from '@/formAuth';
import { nextTick, safetyGetJSON } from '@/utils';
import { requestListenerPlugin } from './RequestListener';
import apiConfig from './api';
import {
  FORM_NOT_AUTHENTICATED_CODE,
  NOT_AUTHENTICATED_CODE,
  SUCCESS_CODE,
} from './config';
import { getEncryptParams } from './util';

declare module 'zyouh-request' {
  interface ResponseData<T> {
    code: number;
    msg: string;
    data: T;
  }
}

const debounceNotifyWarn = debounce(notification.warn, 500);
const debounceNotifyError = debounce(notification.error, 500);

const handleLogout = () => {
  const { pathname } = window.location;

  if (isNonLoginStatusPage(pathname)) {
    removeToken();
  } else {
    debounceNotifyWarn({
      message: 'Please log in again',
      style: {
        zIndex: 10000,
      },
    });
    logout(!isBackendAdminPage(pathname));
  }
};

const resolveErrorStatus = (status: number) => {
  if (status === 403) {
    nextTick(() => {
      history.push('/exception403');
    });
  } else if (status >= 404 && status <= 422) {
    if (APP_ENV !== 'development') {
      nextTick(() => {
        history.push('/exception404');
      });
    }
  } else if (status >= 500 && status <= 504) {
    /*   nextTick(() => {
      history.push('/exception500');
    }); */
  }
};

const userFormAuthPlugin = <T extends object>({
  scopes,
  onNotAuthenticated,
}: {
  scopes: GetObjectAllFields<T>;
  onNotAuthenticated?: () => void;
}): RequestPlugin => ({
  name: 'userFormAuth',
  managers: {
    request: [
      (config) => {
        const { urlFieldPath } = config;

        if (!urlFieldPath) {
          return config;
        }

        const words = urlFieldPath.split('.');
        const included = words.some((_, index) =>
          scopes.includes(
            (words
              .slice(0, index + 1)
              .join('.') as unknown) as GetObjectAllFields<T>[number],
          ),
        );

        const token = getFormAuthToken();

        if (included) {
          if (!token) {
            onNotAuthenticated?.();
            return Promise.reject(config);
          }

          config.headers = {
            ...config.headers,
            token,
          };
        }

        return config;
      },
    ],
    response: [
      (resp) => {
        if (
          resp &&
          isObject(resp) &&
          resp.data.code === FORM_NOT_AUTHENTICATED_CODE
        ) {
          onNotAuthenticated?.();
          return Promise.reject(resp);
        }

        return resp;
      },
    ],
  },
});

const digestPlugin: RequestPlugin = {
  name: 'digest',
  managers: {
    request: [
      (config) => {
        const { url, method, originalUrl } = config;

        if (!url || !method) {
          return config;
        }

        if (originalUrl && /https?:\/{2}/.test(originalUrl)) {
          return config;
        }

        const now = new Date();
        const timestamp = now.getTime();

        config.headers = {
          ...config.headers,
          locale: getLocale(),
          env: APP_ENV,
          version: APP_VERSION ?? window.APP_VERSION,
          timezoneOffset: now.getTimezoneOffset() * -1,
          ...getEncryptParams(timestamp),
        };

        return config;
      },
    ],
  },
};

export const request = axios.create({ timeout: 1000 * 110 });

const client = new RequestClient(apiConfig, {
  baseUrl: {
    __root: ENV.apiBaseUrl,
    analysis: ENV.analysisApiBaseUrl,
    webAuthn: ENV.webAuthnBaseUrl,
  },
  getAxiosInstance: () => request,
  plugins: [
    requestListenerPlugin,
    digestPlugin,
    authPlugin<typeof apiConfig>({
      authScopes: [
        'systemAuthority',
        'account',
        'file',
        'filesign',
        'company',
        'agentClient',
        'common',
        'agentOffer',
        'customCase',
        'dashboard',
        'staffPoint',
        'companyPoint',
        'message',
        'school',
        'channelClient',
        'channelBill',
        'channelCase',
        'channelMaster',
        'channelCoePayment',
        'agentOneULinkPoint',
        'messageTask',
        'log',
        'fileAssistant',
        'autoSubmit',
        'caseTag',
        'fileRemark',
        'ai',
        'systemUpdate',
        'prPlanner',
        'assist',
        'studentForm.admin',
        'agentCase',
        'todo',
        'offerGeneration.agent',
        'offerGeneration.oneulink',
        'agentClientTag',
        'fileCabinets',
        'visaTools',
        'officialPublicity',
        'coursePlan',
        'agentContract',
        'functionGuide',
        'c2cPoint',
        'tips',
        'admin',
        'c2cCoE',
        'courseTutoring',
        'rpl',
        'studentApp',
        'reviewPaymentInfo',
        'dataConfig',
        'courseChecklist',
        'frontAssist',
      ],
      getToken,
      onNotAuthenticated: () => {
        if (APP_ENV !== 'development') {
          removeToken();
        }
        handleLogout();
      },
      checkServiceAuthenticated: (resp) =>
        resp.data?.code !== NOT_AUTHENTICATED_CODE,
    }),
    userFormAuthPlugin<typeof apiConfig>({
      scopes: [
        'clientUserForm.common',
        'clientUserForm.visa',
        'clientUserForm.contactInfoUpdate',
        'clientUserForm.contract',
      ],
    }),
    responseErrorNotify({
      checkServiceStatus: (resp) => {
        if (resp.data instanceof ArrayBuffer) {
          return true;
        }

        let data = resp?.data;

        if (typeof data === 'string') {
          data = safetyGetJSON(data, data);
        }

        const { code } = resp.data;

        return !(
          code !== SUCCESS_CODE &&
          code !== NOT_AUTHENTICATED_CODE &&
          code !== FORM_NOT_AUTHENTICATED_CODE
        );
      },

      getServiceErrorMessage: (resp) => {
        // @ts-ignore
        const msg = resp.data?.msg || resp.data?.message;

        const traceId = resp.headers['trace-id'];

        return [msg, traceId].filter(Boolean).join('$$$');
      },
      getLocale,
      notify: (message, status) => {
        if (!message && !status) {
          return;
        }

        const [msg, traceId] = message.split('$$$');

        const content = (
          <>
            {msg}
            {traceId && (
              <div>
                Error Trace Id:
                <Typography.Text copyable>{traceId}</Typography.Text>
              </div>
            )}
          </>
        );

        if (status >= 200 && status <= 204) {
          debounceNotifyError({
            message: content,
          });
        } else {
          debounceNotifyError({
            message: `Request Error ${status}`,
            description: content,
          });
        }
      },
      onError(err, status, config) {
        if (config && status) {
          if ((status >= 200 && status <= 204) || status === 304) {
            if (config.method === 'get') {
              resolveErrorStatus(status);
            }
          } else {
            resolveErrorStatus(status);
          }
        }

        return Promise.reject(err);
      },
    }),
    extractResponseDataPlugin(),
  ],
});

export const { services } = client;

export type ResponseSchema<T = AnyObject> = ResponseData<T>;
