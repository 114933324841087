import { useRequest } from 'ahooks';
import { Popconfirm } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { isFunction } from 'lodash';
import React from 'react';
import { useIntl } from 'umi';
import { Fetcher } from '@/api';
import { LinkOptionButton } from '@/common';
import { useCommonMessages } from '@/hooks';

const ActionButton: React.FC<{
  action: Fetcher;
  params?: AnyObject | (() => AnyObject);
  confirmContent?: React.ReactNode;
  onOk?: (data: any) => void;
  text?: React.ReactNode;
  children?: React.ReactNode | ((loading: boolean) => React.ReactNode);
  confirm?: boolean;
  id?: string;
  placement?: TooltipPlacement;
}> = ({
  action,
  params,
  confirmContent,
  onOk,
  children,
  text,
  id,
  placement,
  confirm = true,
}) => {
  const { loading, run } = useRequest(action, {
    manual: true,
    onSuccess: onOk,
  });
  const { actions } = useCommonMessages();
  const { formatMessage } = useIntl();

  const content = isFunction(children) ? (
    children(loading)
  ) : React.isValidElement(children) ? (
    React.cloneElement(children, { loading, id })
  ) : (
    <LinkOptionButton id={id} loading={loading}>
      {text ?? actions.deleteBtnText}
    </LinkOptionButton>
  );

  if (confirm === false) {
    return (
      <>
        {React.isValidElement(content)
          ? React.cloneElement(content, {
              onClick(...args: any) {
                content.props.onClick?.(...args);
                run(isFunction(params) ? params() : params);
              },
            })
          : content}
      </>
    );
  }

  return (
    <Popconfirm
      title={confirmContent || formatMessage({ id: 'app.confirm.content' })}
      onConfirm={() => {
        run(isFunction(params) ? params() : params);
      }}
      overlayInnerStyle={{
        width: 250,
      }}
      placement={placement}
    >
      {content}
    </Popconfirm>
  );
};

export default ActionButton;
