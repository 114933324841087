import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { TodoStatus, useTodoStatusEnum } from '@/data';

const useTodoStatusTagConfigs = () => {
  const statusEnum = useTodoStatusEnum();
  const tagConfigs: Record<
    TodoStatus,
    {
      color: string;
      plain?: boolean;
      icon: typeof MinusCircleOutlined;
      order: number;
    }
  > = {
    [TodoStatus.CLOSED]: {
      color: '#C2C2C2',
      icon: MinusCircleOutlined,
      order: 4,
    },
    [TodoStatus.COMPLETED]: {
      color: '#50CD5D',
      icon: CheckCircleOutlined,
      order: 3,
    },
    [TodoStatus.IN_PROGRESS]: {
      color: '#40A9FF',
      icon: CheckCircleOutlined,
      order: 2,
    },

    [TodoStatus.OVERDUE]: {
      color: '#FF7875',
      icon: MinusCircleOutlined,
      order: 6,
    },
  };

  return Object.fromEntries(
    Object.entries(tagConfigs).map((item) => [
      item[0],
      {
        ...item[1],
        text: statusEnum.get(item[0])?.text,
      },
    ]),
  );
};

export default useTodoStatusTagConfigs;
