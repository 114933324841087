import {
  CheckOutlined,
  DownOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Divider, Menu, Space, notification } from 'antd';
import React, { useState } from 'react';
import { history, useModel } from 'umi';
import { ResponseSchema, services } from '@/api';
import logo from '@/assets/logo.png';
import { removeToken } from '@/auth';
import { CustomAvatar, LinkOptionButton } from '@/common';
import { AccountType, RoleType } from '@/data';
import { ENV } from '@/env';
import { useFormatMessage, useModalConfirm } from '@/hooks';
import { useSwitchCompany } from '@/layouts/components';
import HeaderDropdown from '../HeaderDropdown';
import AssistantSwitchCompanyItem from './AssistantSwitchCompanyItem';
import styles from './index.less';

const ToggleCompany: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  const {
    companys,
    information: { previousCompany, previousCompanyName },
  } = initialState!.currentUser!;
  const { formatMessage } = useFormatMessage();
  const [visible, setVisible] = useState(false);
  const { data: companyResp } = useQuery<
    ResponseSchema<
      {
        companyId: number;
        tradingName: string;
      }[]
    >
  >([services.account.getCompanys.key], services.account.getCompanys, {
    enabled: visible,
  });

  const { switchCompany, switching } = useSwitchCompany(() => {
    notification.success({
      message: formatMessage('切换公司成功'),
    });
    history.replace('/');
  });
  const { modalConfirm } = useModalConfirm();

  const isOneULink = initialState?.role === RoleType.CHANNEL;
  const isAssistant = initialState?.accountType === AccountType.ASSISTANT;

  const { data } = useQuery<
    ResponseSchema<
      {
        companyName: string;
        companyId: number;
        assistId: string;
      }[]
    >
  >(
    [services.assist.getAvailableCompanyOptions.key],
    services.assist.getAvailableCompanyOptions,
    {
      enabled: isOneULink || isAssistant,
      refetchOnWindowFocus: true,
    },
  );

  const moveOneuLinkToFirst = (
    options: {
      companyId: number;
      tradingName: string;
    }[],
  ) => {
    const index = options.findIndex(
      (item) => item.tradingName.toLowerCase() === 'oneulink',
    );
    const oneulinkItem = options.find(
      (item) => item.tradingName.toLowerCase() === 'oneulink',
    );

    if (index !== -1 && oneulinkItem) {
      options.splice(index, 1);
      options.unshift(oneulinkItem);
    }

    return options;
  };

  const companyOptions = moveOneuLinkToFirst(companyResp?.data || companys);
  const createAction = (
    <Space>
      <PlusOutlined />
      {formatMessage('创建公司')}
    </Space>
  );
  const assistantCompanyOptions = data?.data;
  const hasAssistantCompany =
    assistantCompanyOptions && assistantCompanyOptions.length > 0;

  const overlay = (
    <Menu className={styles.menu} selectedKeys={[]}>
      {isAssistant && (
        <Menu.Item
          key="logout"
          onClick={() => {
            removeToken();
            window.location.href = ENV.origin;
          }}
        >
          {formatMessage(
            '返回 OneULink',
            'app.right-content-.assistant-back-oneulink',
          )}
        </Menu.Item>
      )}
      {!isAssistant &&
        companyOptions?.map((item) => (
          <Menu.Item
            key={`toggle-${item.companyId}`}
            onClick={async () => {
              if (switching) {
                return;
              }

              await modalConfirm({
                content: formatMessage('切换公司请先关闭其它所有标签页'),
              });
              switchCompany(Number(item.companyId));
            }}
          >
            <div className={styles.companyItem}>
              <Space style={{ overflow: 'hidden', flex: 1 }}>
                {item.tradingName.toLowerCase() === 'oneulink' && (
                  <CustomAvatar size="small" src={logo} />
                )}
                <h4 className={styles.companyName}>{item.tradingName}</h4>
              </Space>
              {item.companyId === previousCompany && (
                <LinkOptionButton className={styles.companyAction}>
                  <CheckOutlined />
                </LinkOptionButton>
              )}
            </div>
          </Menu.Item>
        ))}
      {isOneULink && hasAssistantCompany && (
        <>
          <Divider style={{ margin: 0, fontSize: 14 }}>
            {isOneULink && <span>{formatMessage('协助公司')}</span>}
          </Divider>
        </>
      )}
      {(isOneULink || isAssistant) &&
        hasAssistantCompany &&
        assistantCompanyOptions?.map((item) => (
          <AssistantSwitchCompanyItem
            id={item.assistId}
            name={item.companyName}
            key={item.assistId}
            active={isAssistant && item.companyId === previousCompany}
          />
        ))}

      {!isAssistant && (
        <Menu.Item
          onClick={() => {
            if (history.location.pathname !== '/create-company') {
              history.push('/create-company');
            }
          }}
          key="create"
        >
          {createAction}
        </Menu.Item>
      )}
    </Menu>
  );

  const trigger = (
    <Space align="center" className={styles.company}>
      <b>{previousCompanyName ?? formatMessage('选择公司')}</b>
      {switching ? (
        <LoadingOutlined style={{ fontSize: 12 }} spin />
      ) : (
        <DownOutlined style={{ fontSize: 12 }} />
      )}
    </Space>
  );

  return (
    <HeaderDropdown
      trigger={['click']}
      visible={visible}
      onVisibleChange={setVisible}
      overlay={companys?.length > 0 ? overlay : <div />}
    >
      <span className={styles.action}>
        {isAssistant ? (
          trigger
        ) : companys?.length > 0 ? (
          trigger
        ) : (
          <LinkOptionButton onClick={() => history.push('/create-company')}>
            {createAction}
          </LinkOptionButton>
        )}
      </span>
    </HeaderDropdown>
  );
};

export default ToggleCompany;
