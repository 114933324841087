import ProCard from '@ant-design/pro-card';
import { useRequest } from 'ahooks';
import { Badge, BadgeProps, Popover, Space, Switch, Tag } from 'antd';
import React from 'react';
import { services } from '@/api';
import { LinkOptionButton } from '@/common';
import { IAccessable } from '@/components/Accessable';
import {
  MessageTaskEventType,
  MessageTaskGroup,
  MessageTaskScope,
  MessageTaskStatus,
  useMessageTaskEventTypeOptions,
} from '@/data';
import {
  useCommonMessages,
  useFormatMessage,
  useIsEn,
  useModalConfirm,
} from '@/hooks';
import { formatDate } from '@/utils';
import { EmailHTMLBody } from '../EmailDetail';
import styles from './MessageTaskCard.less';
import MessageTaskCountDown from './MessageTaskCountDown';
import {
  MessageTaskRecipientType,
  TriggerDateType,
  useGroupEnum,
  useMessageTaskTriggerDescriptors,
  usePersonnelNameEnum,
  useRecipientTypeEnum,
} from './data';

export type ITaskItem = {
  templateName: string;
  triggerEvent: MessageTaskEventType;
  id: number;
  extra: AnyObject | null;
  status: MessageTaskStatus;
  templateContent: {
    emailCnContent: string;
    emailCnTitle: string;
    emailEnContent: string;
    emailEnTitle: string;
  };
  recipient: {
    Id: string;
    type: MessageTaskRecipientType;
    value: string;
    familyName: string | null;
    givenName: string | null;
    nickname: string;
  }[];
  grouping: MessageTaskGroup;
  triggerDate:
    | (
        | {
            Id: string;
            expectDatetime: string;
          }
        | {
            Id: string;
            offset: number;
            type: TriggerDateType.DYNAMIC;
          }
        | {
            Id: string;
            date: string;
            type: TriggerDateType.NORMAL;
          }
      )[]
    | null;
};

const MessageTaskCard: React.FC<{
  data: ITaskItem;
  scope: MessageTaskScope;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
  onRefresh: () => void;
}> = ({ data, onEdit, onDelete, onRefresh, scope }) => {
  const { formatMessage } = useFormatMessage();
  const { actions } = useCommonMessages();
  const { data: personnelNameEnum } = usePersonnelNameEnum();
  const recipientTypeEnum = useRecipientTypeEnum();
  const groupEnum = useGroupEnum();
  const isEn = useIsEn();

  const eventTypeOptions = useMessageTaskEventTypeOptions();
  const {
    dateDescriptors,
    normalDescriptor,
  } = useMessageTaskTriggerDescriptors();

  const {
    triggerEvent,
    templateContent,
    templateName,
    recipient,
    id,
    triggerDate,
    status,
    grouping,
  } = data;

  const { modalConfirm } = useModalConfirm();
  const { loading, run: deleteTask } = useRequest(
    services.messageTask.deleteTask,
    {
      manual: true,
      onSuccess: () => {
        onDelete(id);
      },
    },
  );
  const { loading: submitting, run: setTaskStatus } = useRequest(
    services.messageTask.setTaskStatus,
    {
      manual: true,
      onSuccess: onRefresh,
    },
  );

  const handleDelete = async () => {
    await modalConfirm();
    deleteTask({ id });
  };

  const triggerName = eventTypeOptions.find(
    (item) => item.value === triggerEvent,
  )?.label;
  const emailSubject = templateContent[isEn ? 'emailEnTitle' : 'emailCnTitle'];
  const emailContent =
    templateContent[isEn ? 'emailEnContent' : 'emailCnContent'];

  const renderRecipient = () => {
    return (
      <div className={styles.recipientContainer}>
        <div className={styles.scroll}>
          {recipient.map((item, index) => (
            <span key={item.Id}>
              <span style={{ marginRight: 2 }}>{index + 1}.</span>
              <Tag color={recipientTypeEnum[item.type]?.color}>
                {item.type === MessageTaskRecipientType.PRONOUN
                  ? personnelNameEnum[item.value]
                  : recipientTypeEnum[item.type].text}
              </Tag>
              {item.nickname}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const renderDate = () => {
    if (!triggerDate) {
      return null;
    }

    return (
      <div>
        {triggerDate.map((item) => {
          let content: React.ReactNode = null;

          if ('expectDatetime' in item) {
            content = (
              <>
                <span>{formatDate(item.expectDatetime, true)}</span>
                <MessageTaskCountDown expectDatetime={item.expectDatetime} />
              </>
            );
          } else if (item.type === TriggerDateType.NORMAL) {
            content = normalDescriptor(item.date);
          } else {
            content = dateDescriptors[triggerEvent!]?.(item.offset);
          }

          return (
            <div className={styles.timeItem} key={item.Id}>
              {content}
            </div>
          );
        })}
      </div>
    );
  };

  const groupStatusEnum: Record<MessageTaskGroup, BadgeProps> = {
    [MessageTaskGroup.OVERALL]: {
      status: 'error',
    },
    [MessageTaskGroup.CASE]: {
      status: 'success',
    },
    [MessageTaskGroup.CLIENT]: {
      status: 'warning',
    },
    [MessageTaskGroup.TODO]: { status: 'processing' },
  };
  const toggleStatusList = [
    MessageTaskStatus.RUNNING,
    MessageTaskStatus.SUSPEND,
  ];
  const templateTitle =
    triggerEvent === MessageTaskEventType.NORMAL
      ? templateContent.emailCnTitle
      : templateName;

  const scopeMap: Record<MessageTaskScope, MessageTaskGroup[]> = {
    case: [MessageTaskGroup.CASE],
    client: [MessageTaskGroup.CLIENT, MessageTaskGroup.CASE],
    all: [
      MessageTaskGroup.OVERALL,
      MessageTaskGroup.CASE,
      MessageTaskGroup.CLIENT,
    ],
    todo: [MessageTaskGroup.TODO],
  };

  return (
    <div className="ant-pro-list-row-card">
      <ProCard
        extra={
          scopeMap[scope].includes(grouping) && (
            <Space size={16}>
              <IAccessable
                authority={[
                  'front.messageTask.job.update',
                  'front.messageTask.job.info',
                ]}
              >
                <LinkOptionButton onClick={() => onEdit(id)}>
                  {actions.editBtnText}
                </LinkOptionButton>
              </IAccessable>
              <IAccessable authority="front.messageTask.job.del">
                <LinkOptionButton
                  loading={loading}
                  onClick={() => handleDelete()}
                >
                  {actions.deleteBtnText}
                </LinkOptionButton>
              </IAccessable>
              {toggleStatusList.includes(status) && (
                <IAccessable authority="front.messageTask.job.setJobStatus">
                  <Switch
                    unCheckedChildren={formatMessage('暂停')}
                    checkedChildren={formatMessage('运行')}
                    checked={status === MessageTaskStatus.RUNNING}
                    loading={submitting}
                    onChange={(current) =>
                      setTaskStatus({
                        id,
                        status: current
                          ? MessageTaskStatus.RUNNING
                          : MessageTaskStatus.SUSPEND,
                      })
                    }
                    defaultChecked
                  />
                </IAccessable>
              )}
            </Space>
          )
        }
        title={
          <Space>
            {triggerName}
            <Badge
              status={groupStatusEnum[grouping].status}
              text={groupEnum[grouping]}
            />
          </Space>
        }
        headerBordered
        hoverable
        bordered
      >
        {!!triggerDate?.length && <div>At, {renderDate()}</div>}
        <h4 className={triggerDate && styles.send}>
          {formatMessage('发送邮件')}
        </h4>
        <Popover
          title={emailSubject}
          content={<EmailHTMLBody html={emailContent} />}
          trigger={['click']}
          overlayStyle={{
            width: 320,
            maxHeight: '60vh',
            overflow: 'auto',
          }}
        >
          <h3
            style={{
              padding: '4px 8px',
              backgroundColor: '#F0F2F5',
              margin: '8px 0',
            }}
            className="text-primary"
          >
            {triggerEvent !== MessageTaskEventType.NORMAL &&
              formatMessage('模板：')}
            {templateTitle}
          </h3>
        </Popover>
        <div>{formatMessage('接收人')}:</div>
        {renderRecipient()}
      </ProCard>
    </div>
  );
};

export default MessageTaskCard;
