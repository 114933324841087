import ProForm from '@ant-design/pro-form';
import { useRequest } from 'ahooks';
import React, { useImperativeHandle } from 'react';
import { ResponseSchema, services } from '@/api';
import { CommonSelect } from '@/common';
import { useCommonRules, useFormatMessage } from '@/hooks';
import { formatPersonName } from '@/utils';
import type { StaffOrClientSelectRef } from './type';

const FormItem = ProForm.Item;

const ClientSelect = React.forwardRef<
  StaffOrClientSelectRef,
  React.ComponentProps<typeof FormItem> & {
    selectProps?: React.ComponentProps<typeof CommonSelect>;
    selectedValues?: number[];
  }
>(({ selectProps, selectedValues, ...props }, ref) => {
  const { formatMessage } = useFormatMessage();
  const { selectRules } = useCommonRules();
  const { loading, data, run, reset } = useRequest<ResponseSchema<AnyObject[]>>(
    services.messageTask.getClientSelectList,
    {
      manual: true,
      debounceInterval: 150,
    },
  );

  const map =
    selectedValues && Object.fromEntries(selectedValues.map((v) => [v, true]));
  const clientOptions = data?.data
    ?.filter((item) => (map ? !map[item.id] : true))
    .map((item) => ({
      label: formatPersonName(item),
      value: item.id,
    }));

  useImperativeHandle(ref, () => ({
    getNames: (values) =>
      values.map((item) => ({
        value: item,
        name: clientOptions?.find((v) => v.value === item)?.label,
      })),
  }));

  return (
    <FormItem rules={selectRules} {...props}>
      <CommonSelect
        onSearch={(value) => {
          const keyword = value?.trim();
          if (!keyword || keyword.length < 2) {
            reset();
          } else {
            run({ keyword });
          }
        }}
        loading={loading}
        options={clientOptions}
        placeholder={formatMessage('输入客户名称关键字搜索')}
        {...selectProps}
      />
    </FormItem>
  );
});

export default ClientSelect;
