import { DefaultFooter } from '@ant-design/pro-layout';
import { Space } from 'antd';
import { SelectLang } from 'umi';
import config from '../../../config/defaultSettings';

export default ({ className }: { className?: string }) => {
  const now = new Date().getFullYear();
  const data = [
    {
      lang: 'en-US',
      label: 'English',
      icon: ' ',
      title: 'Language',
    },
    {
      lang: 'zh-CN',
      label: '简体中文',
      icon: ' ',
      title: '语言',
    },
  ];

  return (
    <DefaultFooter
      // @ts-ignore
      copyright={
        <Space>
          <span>
            {`${now} ${config.title} `}
            {APP_VERSION}
          </span>
          <SelectLang reload={false} postLocalesData={() => data} />
        </Space>
      }
      links={false}
      className={className}
    />
  );
};
