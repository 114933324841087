import { useClickAway, useRequest } from 'ahooks';
import { Drawer, Skeleton } from 'antd';
import { range } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { clearBodyLocks, lock } from 'tua-body-scroll-lock';
import { ResponseSchema, services } from '@/api';
import { useFormatMessage } from '@/hooks';
import { FunctionGuide } from '../FunctionGuide';
import Header from './Header';
import styles from './HelpCenter.less';
import ReleaseLogItem, { ReleaseLogItemData } from './ReleaseLogItem';

export default ({ trigger }: { trigger: React.ReactElement }) => {
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useFormatMessage();
  const ref = useRef<HTMLElement>();
  const { data: logsResp, run: getLogs, refresh } = useRequest<
    ResponseSchema<{
      data: ReleaseLogItemData[];
    }>
  >(services.systemUpdate.front.getReleasedLogList, {
    manual: true,
    debounceInterval: 100,
  });

  useEffect(() => {
    if (visible) {
      lock();

      return clearBodyLocks;
    }

    return undefined;
  }, [visible]);

  useClickAway(
    (evt) => {
      if (
        visible &&
        (!ref.current ||
          (evt.target instanceof Element && !ref.current.contains(evt.target)))
      ) {
        setVisible(false);
      }
    },
    () => document.querySelector(`.${styles.drawer}`),
  );

  const logs = logsResp?.data.data;

  return (
    <>
      <FunctionGuide
        steps={[
          {
            key: 'help-center',
            content: formatMessage(
              '在有使用上的疑问可以在此查看，也可以查看更新日志',
            ),
          },
        ]}
        padding={0}
      >
        {React.cloneElement(trigger, {
          onClick() {
            setVisible(true);
            lock();
            getLogs();
          },
          id: 'help-center',
          ref,
        })}
      </FunctionGuide>
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        mask={false}
        footer={null}
        className={styles.drawer}
        title={formatMessage('帮助中心')}
        destroyOnClose
        maskClosable
      >
        <div
          role="button"
          tabIndex={0}
          onClick={(evt) => {
            if (evt.target instanceof Element && evt.target.closest('a')) {
              setVisible(false);
            }
          }}
        >
          <Header />
          {!logs ? (
            range(4).map((item) => (
              <Skeleton style={{ marginBottom: 24 }} key={item} />
            ))
          ) : (
            <div className={styles.list}>
              {logs.map((item) => (
                <ReleaseLogItem onRead={refresh} key={item.id} {...item} />
              ))}
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};
