import { ArrowRightOutlined } from '@ant-design/icons';
import ProList from '@ant-design/pro-list';
import { Space, Tabs, Tag, Timeline, Typography } from 'antd';
import React from 'react';
import { CustomAvatar } from '@/common';
import DynamicRelativeTime from '@/components/DynamicRelativeTime';
import { TodoPriority, TodoStatus, useTodoPriorityEnum } from '@/data';
import { useFormatMessage, useIsEn } from '@/hooks';
import { formatDate } from '@/utils';
import { tableRender } from '@/utils/tableRender';
import styles from './TodoActivity.less';
import { TodoSimpleStatusTag } from './TodoStatusTag';
import { TodoActivityData, TodoDetailMessageTaskData } from './type';

const StatusTimeline: React.FC<{
  data?: {
    time: string;
    staff: {
      avatar: string;
      nickname: string;
    };
    status: TodoStatus;
    id: string;
  }[];
}> = ({ data }) => {
  const { formatMessage } = useFormatMessage();

  return (
    <Timeline mode="left" className={styles.timeline}>
      {data?.map((item, index) => (
        <Timeline.Item
          key={item.id}
          label={
            <Space size={4}>
              {index === data.length - 1 && (
                <Tag style={{ borderRadius: '20px' }} color="geekblue">
                  {formatMessage('当前')}
                </Tag>
              )}
              <TodoSimpleStatusTag status={item.status} />
            </Space>
          }
        >
          <div className={styles.item}>
            <CustomAvatar
              size="small"
              src={item.staff.avatar}
              alt={item.staff.nickname}
            />
            <span className={styles.nickname}>{item.staff.nickname}</span>
            <span style={{ color: '#bfbfbf' }}>
              <DynamicRelativeTime time={item.time} />
            </span>
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

const getStatusActivityData = (data?: TodoActivityData[]) => {
  return data
    ?.filter(
      (item) =>
        item.action === 'todo-created' ||
        (item.action === 'data-changed' && 'state' in item.data.targetData),
    )
    .map((item) => {
      return {
        time: item.createdAt,
        staff: {
          avatar: item.chmAvatar,
          nickname: item.chmNickname,
        },
        status:
          item.action === 'todo-created'
            ? TodoStatus.IN_PROGRESS
            : // @ts-ignore
              item.data.targetData.state,
        id: item.Id,
      };
    })
    .reverse();
};

const TodoActivity: React.FC<{ data?: TodoActivityData[] }> = ({ data }) => {
  const { formatMessage } = useFormatMessage();
  const priorityEnum = useTodoPriorityEnum();
  const isEn = useIsEn();

  const tabs = [
    {
      key: 'all',
      tab: formatMessage('活动'),
    },
    {
      key: 'status',
      tab: formatMessage('流转'),
    },
  ];

  const actions = {
    create: {
      title: formatMessage('创建了待办事项'),
    },
    priorityLevel: {
      title: formatMessage('修改了优先级'),
      render: (currentValue: TodoPriority) => (
        <Tag
          style={{ marginRight: 0 }}
          color={priorityEnum.get(currentValue)?.color}
        >
          {priorityEnum.get(currentValue)?.text}
        </Tag>
      ),
    },
    title: {
      title: formatMessage('修改了标题'),
      render: (currentValue: string) =>
        currentValue ? (
          <Typography.Text ellipsis={{ tooltip: currentValue }}>
            {currentValue}
          </Typography.Text>
        ) : (
          '-'
        ),
    },
    state: {
      title: formatMessage('修改了状态'),
      render: (currentValue: TodoStatus) => (
        <TodoSimpleStatusTag status={currentValue} />
      ),
    },
    deadline: {
      title: formatMessage('修改了截止时间'),
      render: (currentValue: string) => formatDate(currentValue, true),
    },
    content: {
      title: formatMessage('修改了详情'),
      render: (currentValue: string) =>
        currentValue ? (
          <Typography.Text ellipsis={{ tooltip: currentValue }}>
            {currentValue}
          </Typography.Text>
        ) : (
          '-'
        ),
    },
    chargeChmId: {
      title: formatMessage('修改了负责人'),
      render: (_currentValue: number, record: AnyObject) => (
        <Space size={8} align="center">
          <CustomAvatar
            size="small"
            src={record.chargeChmAvatar}
            alt={record.chargeChmNickname}
          />
          {record.chargeChmNickname}
        </Space>
      ),
    },

    addMessageTask: {
      title: formatMessage('添加了消息任务'),
      render: (currentValue: TodoDetailMessageTaskData) => (
        <div className={styles.diffContent}>
          {currentValue.templateContent[isEn ? 'emailEnTitle' : 'emailCnTitle']}
        </div>
      ),
    },

    deleteMessageTask: {
      title: formatMessage('删除了消息任务'),
      render: (currentValue: TodoDetailMessageTaskData) => (
        <div
          style={{ textDecoration: 'line-through' }}
          className={styles.diffContent}
        >
          {currentValue.templateContent[isEn ? 'emailEnTitle' : 'emailCnTitle']}
        </div>
      ),
    },
  };

  const renderValueDiff = (
    key: string,
    oldValue: AnyObject,
    newValue: AnyObject,
  ) => {
    return (
      <div key={key} style={{ marginBottom: 8 }}>
        <span className={styles.actionTitle}>{actions[key].title}</span>
        <div className={styles.diffContent}>
          {actions[key]?.render(oldValue[key], oldValue)}
          <ArrowRightOutlined />
          {actions[key]?.render(newValue[key], newValue)}
        </div>
      </div>
    );
  };

  const renderAllActivity = () => {
    return (
      <ProList
        dataSource={data}
        rowKey="Id"
        pagination={false}
        cardProps={{
          bodyStyle: { padding: 0 },
        }}
        split
        metas={{
          avatar: {
            dataIndex: 'chmAvatar',
          },
          title: {
            dataIndex: 'chmNickname',
          },
          subTitle: {
            render: tableRender((record) => (
              <DynamicRelativeTime time={record.createdAt} />
            )),
          },
          description: {
            // @ts-ignore
            render: tableRender((record: TodoActivityData) => {
              if (record.action === 'todo-created') {
                return (
                  <span className={styles.actionTitle}>
                    {actions.create.title}
                  </span>
                );
              }

              if (record.action === 'message-task-add') {
                return (
                  <div>
                    <span className={styles.actionTitle}>
                      {actions.addMessageTask.title}
                    </span>
                    {actions.addMessageTask.render(record.data)}
                  </div>
                );
              }

              if (record.action === 'message-task-del') {
                return (
                  <div>
                    <span className={styles.actionTitle}>
                      {actions.deleteMessageTask.title}
                    </span>
                    {actions.deleteMessageTask.render(record.data)}
                  </div>
                );
              }

              const { sourceData, targetData } = record.data;

              return Object.keys(actions)
                .filter(
                  (key) =>
                    key in sourceData &&
                    key in targetData &&
                    actions[key]?.render,
                )
                .map((key) => {
                  return renderValueDiff(key, sourceData, targetData);
                });
            }),
          },
        }}
      />
    );
  };

  return (
    <Tabs className={styles.container}>
      {tabs.map((item) => {
        return (
          <Tabs.TabPane key={item.key} tab={item.tab}>
            {item.key === 'all' ? (
              renderAllActivity()
            ) : (
              <StatusTimeline data={getStatusActivityData(data)} />
            )}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
};

export default TodoActivity;
