import { isEqual } from 'lodash';
import { DependencyList, useRef } from 'react';

const useDeepMemorized = (deps: DependencyList) => {
  const last = useRef<DependencyList>([]);

  if (!isEqual(last.current, deps)) {
    last.current = deps;
  }

  return last.current;
};

export default useDeepMemorized;
