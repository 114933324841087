import { useMemo } from 'react';
import useFormatMessage from '@/hooks/useFormatMessage';

export const useBillStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum = {
      0: {
        text: formatMessage('待付款'),
        status: 'processing',
      },
      1: {
        text: formatMessage('待审核'),
        status: 'warning',
      },
      2: {
        text: formatMessage('审核通过'),
        status: 'success',
      },
      3: {
        text: formatMessage('审核不通过'),
        status: 'error',
      },
    };
    return new Map(
      Object.entries(statusEnum).map((item) => [Number(item[0]), item[1]]),
    );
  }, [formatMessage]);
};
