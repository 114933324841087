import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';
import { MessageTab, MessageType } from './type';

const useMessageMap = () => {
  const { formatMessage } = useFormatMessage();

  const map = useMemo(
    () => ({
      [MessageType.CLIENT]: /* @message.scope message-tabs @message.en-US Client  */ formatMessage(
        '客户',
      ),
      [MessageType.CASE]: /* @message.scope message-tabs  @message.en-US Case  */ formatMessage(
        '案件',
      ),
      [MessageType.SYSTEM]: /* @message.scope message-tabs  @message.en-US System */ formatMessage(
        '系统',
      ),
      [MessageType.NOTICE]: /* @message.scope message-tabs @message.en-US  Notification   */ formatMessage(
        '通知',
      ),
      [MessageType.POINT]: /* @message.scope message-tabs  @message.en-US Credits   */ formatMessage(
        '积分',
      ),
    }),
    [formatMessage],
  );

  const tabs: MessageTab[] = useMemo(() => {
    const defaultTabs = [
      {
        type: MessageType.CLIENT,
        title: map[MessageType.CLIENT],
        list: [],
        unreaded: 0,
      },
      {
        type: MessageType.CASE,
        title: map[MessageType.CASE],
        list: [],
        unreaded: 0,
      },
      {
        type: MessageType.POINT,
        title: map[MessageType.POINT],
        list: [],
        unreaded: 0,
      },
      {
        type: MessageType.SYSTEM,
        title: map[MessageType.SYSTEM],
        list: [],
        unreaded: 0,
      },
      {
        type: MessageType.NOTICE,
        title: map[MessageType.NOTICE],
        list: [],
        unreaded: 0,
      },
    ];

    return defaultTabs;
  }, [map]);

  return { map, tabs } as const;
};

export default useMessageMap;
