import { TodoPriority, TodoStatus } from '@/data';
import { ITaskItem } from '../MessageTask';

export type TodoFormValues = {
  title: string;
  content?: string;
  deadline: string;
  chmId: number;
  chargeChmId: number;
  priorityLevel: TodoPriority;
};

export type TodoDetailMessageTaskData = Omit<ITaskItem, 'triggerDate'> & {
  triggerDate: {
    Id: string;
    expectDatetime: string;
  }[];
};

export type TodoDetailData = {
  caseId?: number;
  caseName?: string;
  customerName?: string;
  customerId?: string;
  chargeChmAvatar?: string;
  chargeChmId: number;
  chargeChmMemberId: number;
  chargeChmNickname: string;
  chmAvatar?: string;
  chmId: number;
  chmNickname: string;
  companyId: number;
  content?: string;
  createdAt: string;
  deadline: string;
  id: number;
  priorityLevel: TodoPriority;
  state: TodoStatus;
  title: string;
  updatedAt: string;
  messageTaskList: TodoDetailMessageTaskData[];
  changeChmAvatar?: string;
  changeChmId: number;
  changeChmNickname: string;
};

type TodoActivityItem<T> = {
  Id: string;
  chmAvatar: string;
  chmNickname: string;
  createdAt: string;
} & T;

export type TodoActivityData =
  | TodoActivityItem<{ action: 'todo-created' }>
  | TodoActivityItem<{
      action: 'data-changed';
      data: {
        sourceData: AnyObject;
        targetData: AnyObject;
      };
    }>
  | TodoActivityItem<{
      action: 'message-task-add';
      data: TodoDetailMessageTaskData;
    }>
  | TodoActivityItem<{
      action: 'message-task-del';
      data: TodoDetailMessageTaskData;
    }>;

export enum TodoTaskScope {
  CASE = 'case',
  CLIENT = 'client',
}

export type TodoFormRef = {
  open: () => void;
  close: () => void;
};
