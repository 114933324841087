import { ProviderProps, StepType, TourProvider, useTour } from '@reactour/tour';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'antd';
import { cloneDeep, isFunction } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useModel } from 'umi';
import { services } from '@/api';
import { useDeepMemorizedMemo, useIsChannel } from '@/hooks';
import useFormatMessage from '@/hooks/useFormatMessage';
import { nextTick } from '@/utils';
import { useModalManagerContext } from '../ModalManager/ModalManagerProvider';
import styles from './FunctionGuide.less';

export enum FunctionGuideScope {
  ONEULINK = 'oneulink',
  AGENT = 'agent',
}

const FunctionGuide: React.FC<{
  ready?: (() => boolean) | boolean;
  empty: boolean;
  functionKey: string;
  steps: StepType[];
}> = ({ children, ready, empty, functionKey, steps, ...props }) => {
  const { setIsOpen, setSteps, ...api } = useTour();
  const internalReady = isFunction(ready) ? ready() : !!ready;
  const { open } = useModalManagerContext();

  const isOpen = !empty && internalReady;

  useEffect(() => {
    setSteps?.(steps);
  }, [steps, setSteps]);

  useEffect(() => {
    if (!isOpen) {
      setIsOpen(false);
      return undefined;
    }

    return open(
      () => {
        nextTick(() => {
          setIsOpen(true);
        });
      },
      functionKey,
      'FUNCTION_GUIDE',
    );
  }, [open, functionKey, isOpen, setIsOpen]);

  const content = isFunction(children)
    ? children({ ...props, ...api, setIsOpen })
    : React.isValidElement(children)
    ? React.cloneElement(children, props)
    : children;

  return <>{content}</>;
};

const Wrapper: React.FC = ({ children }) =>
  createPortal(children, document.body);

export default ({
  padding,
  portal = true,
  steps = [],
  ready = true,
  ...props
}: Pick<ProviderProps, 'padding'> & {
  steps?: (Omit<StepType, 'selector'> & {
    key: string;
    scope?: boolean;
    selector?: string;
  })[];
  portal?: boolean;
  ready?: (() => boolean) | boolean;
  children?: React.ReactNode;
}) => {
  const isOneULink = useIsChannel();
  const { initialState, setInitialState } = useModel('@@initialState');
  const store = initialState?.functionGuideStatusStore;
  const currentScope = isOneULink
    ? FunctionGuideScope.ONEULINK
    : FunctionGuideScope.AGENT;

  const internalSteps = steps
    ?.map((item) => ({
      ...item,
      selector: item.selector || `#${item.key}`,
      guideKey: item.scope ? `${currentScope}-${item.key}` : item.key,
    }))
    .filter((item) => (store ? !store.get(item.guideKey) : true));

  const readStack = useRef(new Set<string>());
  const { formatMessage } = useFormatMessage();
  const { close } = useModalManagerContext();

  const { mutateAsync: mark } = useMutation(
    [services.functionGuide.markRead.key],
    services.functionGuide.markRead,
    {
      onSuccess(_, { key }: { key: string[] }) {
        if (!store) {
          return;
        }
        const newStore = cloneDeep(store);
        key.forEach((k) => newStore.set(k, true));

        setInitialState({
          ...initialState,
          functionGuideStatusStore: newStore,
        });
      },
    },
  );

  const guideSteps = useDeepMemorizedMemo(() => internalSteps, [internalSteps]);
  const functionKey = guideSteps.map((item) => item.guideKey).join('_$$_');

  const handleClose = () => {
    const keys = Array.from(readStack.current.values());
    readStack.current.clear();

    mark({ key: keys });

    close(functionKey);
  };

  return (
    <TourProvider
      steps={guideSteps}
      showBadge={false}
      padding={padding}
      className={styles.container}
      nextButton={({ currentStep, stepsLength, setCurrentStep, setIsOpen }) => {
        const key = guideSteps[currentStep]?.guideKey;

        if (currentStep === stepsLength - 1) {
          return (
            <Button
              size="small"
              type="dashed"
              onClick={() => {
                setIsOpen(false);
                readStack.current.add(key);

                handleClose();
              }}
            >
              {formatMessage('我知道了')}
            </Button>
          );
        }

        return (
          <Button
            size="small"
            type="dashed"
            onClick={() => {
              readStack.current.add(key);
              setCurrentStep(currentStep + 1);
            }}
          >
            {formatMessage('下一步')}
          </Button>
        );
      }}
      prevButton={({ currentStep, setCurrentStep }) =>
        currentStep === 0 ? (
          <span />
        ) : (
          <Button
            size="small"
            type="ghost"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            {formatMessage('上一步')}
          </Button>
        )
      }
      showCloseButton={false}
      onClickMask={() => {}}
      Wrapper={portal ? Wrapper : undefined}
      disableDotsNavigation
      disableInteraction
    >
      <FunctionGuide
        steps={guideSteps}
        functionKey={functionKey}
        empty={internalSteps.length === 0}
        ready={ready}
        {...props}
      />
    </TourProvider>
  );
};
