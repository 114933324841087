import { Space, Switch, Tooltip } from 'antd';
import React from 'react';
import { useModel } from 'umi';
import { useFormatMessage } from '@/hooks';

const MenuHeaderRender: React.FC<{
  title: React.ReactNode;
  logo: React.ReactNode;
}> = ({ title, logo }) => {
  const { formatMessage } = useFormatMessage();
  const { initialState, setInitialState } = useModel('@@initialState');
  const showAllMenu = !!initialState?.showAllMenu;
  const tip = showAllMenu
    ? formatMessage('查看全部菜单')
    : formatMessage('折叠菜单');

  return (
    <Space>
      <div className="text-primary">
        {logo}
        {title}
      </div>
      <Tooltip placement="bottom" title={tip}>
        <Switch
          size="small"
          onChange={(checked) => {
            setInitialState({
              ...initialState,
              showAllMenu: checked,
            });
            localStorage.setItem('SHOW_ALL_MENU', JSON.stringify(checked));
          }}
          checked={showAllMenu}
        />
      </Tooltip>
    </Space>
  );
};

export default MenuHeaderRender;
