import { TagProps } from 'antd';
import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum C2CPointTypeEnum {
  WITHDRAW = 'withdraw',

  WITHDRAWAL_REJECTED = 'withdrawalRejected',

  ADDITIONAL_ADD = 'additionalAdd',
  ADDITIONAL_USE = 'additionalUse',

  ADDITIONAL_USE_REJECTED = 'additionalUseRejected',
  ADDITIONAL_USE_REVOKE = 'additionalUseRevoke',

  COE_PAYMENT = 'CoEPayment',

  WITHDRAW_FROM_ONEULINK = 'withdrawFromOneULink',

  COE_BONUS = 'CoEBonus',
}

export const useC2CPointTypeEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum: Record<
      C2CPointTypeEnum,
      {
        text?: string;
        color?: TagProps['color'];
      }
    > = {
      [C2CPointTypeEnum.WITHDRAW]: {
        text: formatMessage('申请提现'),
        color: 'gold',
      },
      [C2CPointTypeEnum.WITHDRAWAL_REJECTED]: {
        text: formatMessage('提现拒绝'),
        color: 'red',
      },
      [C2CPointTypeEnum.ADDITIONAL_ADD]: {
        text: formatMessage('申请增加积分'),
        color: 'green',
      },
      [C2CPointTypeEnum.ADDITIONAL_USE]: {
        text: formatMessage('申请使用积分'),
        color: 'pink',
      },
      [C2CPointTypeEnum.ADDITIONAL_USE_REJECTED]: {
        text: formatMessage('申请额外积分被拒绝'),
        color: 'red',
      },
      [C2CPointTypeEnum.ADDITIONAL_USE_REVOKE]: {
        text: formatMessage('申请额外积分被撤销'),
        color: 'magenta',
      },
      [C2CPointTypeEnum.COE_PAYMENT]: {
        text: formatMessage('CoE 佣金'),
        color: 'blue',
      },
      [C2CPointTypeEnum.WITHDRAW_FROM_ONEULINK]: {
        text: formatMessage('OneULink 发起积分提现'),
        color: 'hsl(102, 53%, 61%)',
      },
      [C2CPointTypeEnum.COE_BONUS]: {
        text: formatMessage('CoE 奖金'),
        color: '#f50',
      },
    };

    return new Map(
      Object.entries(statusEnum).map((item) => [item[0], item[1]]),
    );
  }, [formatMessage]);
};
