import { useUnmountedRef } from 'ahooks';
import { AnimationPlaybackControls, animate } from 'framer-motion';
import { useCallback, useEffect, useRef } from 'react';
import { useMobile } from '@/hooks';

const getScrollDuration = (distance: number, speed = 600) => distance / speed;

export const useScrollbar = (container: HTMLElement | null, dir: -1 | 1) => {
  const isMobile = useMobile(820);
  const animatePlayControls = useRef<AnimationPlaybackControls | null>(null);
  const wholeAnimatePlayControls = useRef<AnimationPlaybackControls | null>(
    null,
  );
  const minScrollDistance = 100;
  const unmountedRef = useUnmountedRef();

  const clear = useCallback(() => {
    animatePlayControls.current?.stop();
    animatePlayControls.current = null;
    wholeAnimatePlayControls.current?.stop();
    wholeAnimatePlayControls.current = null;
  }, []);

  const handleMouseEnter = () => {
    if (isMobile || !container) {
      return;
    }

    const { scrollWidth, clientWidth } = container;
    const scrollDistance = scrollWidth - clientWidth;
    const pos = container.scrollLeft;

    clear();

    const target = dir === 1 ? scrollDistance : 0;

    wholeAnimatePlayControls.current = animate(pos, target, {
      onUpdate(val) {
        container.scrollTo(val, 0);
      },
      onComplete() {
        wholeAnimatePlayControls.current = null;
      },
      type: 'tween',
      duration: getScrollDuration(scrollDistance),
      easings: ['easeInOut'],
    });
  };

  const handleMouseLeave = () => {
    if (isMobile) {
      return;
    }

    clear();
  };

  const handleClick = () => {
    if (!container) {
      return;
    }

    clear();

    const { scrollWidth, clientWidth } = container;
    const scrollDistance = scrollWidth - clientWidth;
    const pos = container.scrollLeft;

    const nextPos = Math.min(
      scrollDistance,
      pos + Math.max(minScrollDistance, scrollDistance * 0.2) * dir,
    );

    animatePlayControls.current = animate(pos, nextPos, {
      onUpdate(val) {
        if (!unmountedRef.current) {
          container.scrollLeft = val;
        } else {
          clear();
        }
      },
      onComplete() {
        animatePlayControls.current = null;
      },
    });
  };

  useEffect(() => {
    return clear;
  }, [clear]);

  return {
    onClick: handleClick,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };
};
