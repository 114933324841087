import { CloseOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  AnimatePresence,
  AnimateSharedLayout,
  animate,
  motion,
  useMotionValue,
} from 'framer-motion';
import type { AnimationPlaybackControls } from 'framer-motion';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { TodoGlobalViewerProvider } from '@/PageComponents/Todo';
import { services } from '@/api';
import {
  UsedWebsocketChannel,
  UsedWebsocketEvent,
  useWebsocketSubscribe,
} from '../Websocket';
import MessageItem from './MessageItem';
import styles from './index.less';
import { rubberbandIfOutOfBounds } from './rubberBand';
import { IMessageItem } from './type';

const MessageDrawerItem: React.FC<{
  data: IMessageItem;
  onDelete: (id: number) => void;
  onRead: (id: number) => void;
}> = memo(({ data, onDelete, onRead }) => {
  const x = useMotionValue(0);
  const offsetX = useRef(0);
  const [dragging, setDragging] = useState(false);
  const ctrl = useRef<AnimationPlaybackControls | null>(null);
  const timer = useRef<number | null>(null);

  // todo 後面從父級先測量出
  const width = Math.min(window.innerWidth - 48, 354);
  const max = width + 30;

  const clear = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  const setup = useCallback(() => {
    clear();
    timer.current = window.setTimeout(() => {
      onDelete(data.id);
      onRead(data.id);
    }, 1000 * 6);
  }, [data.id, onDelete, clear, onRead]);

  useEffect(() => {
    return () => {
      if (ctrl.current) {
        ctrl.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    setup();
  }, [setup]);

  return (
    <motion.div
      layout
      key={data.id}
      style={{ x }}
      onMouseEnter={() => {
        clear();
      }}
      onMouseLeave={() => {
        // 动画中不需要
        if (!ctrl.current) {
          setup();
        }
      }}
      onPanStart={() => {
        if (ctrl.current) {
          ctrl.current.stop();
          ctrl.current = null;
        }

        offsetX.current = x.get();
        setDragging(true);
      }}
      onPan={(_, { offset }) => {
        x.set(rubberbandIfOutOfBounds(offset.x + offsetX.current, 0, max));
      }}
      onPanEnd={(_, { velocity }) => {
        const current = x.get();
        const cvx = velocity.x;

        const deleted = cvx > 1000 ? true : current > width / 2.5;
        const next = deleted ? max : 0;

        setDragging(false);

        ctrl.current = animate(x, next, {
          velocity: cvx,
          type: 'spring',
          damping: next === 0 ? 60 : 40,
          stiffness: 500,
          restDelta: 1,
          onComplete: () => {
            ctrl.current = null;

            if (!deleted) {
              setup();
            }
            onDelete(data.id);
          },
        });
      }}
      className={styles.drawerItem}
      initial={{
        x: max,
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: max,
      }}
      transition={{
        stiffness: 500,
        damping: 60,
        delay: dragging ? 60 : 0,
      }}
    >
      <MessageItem
        extra={[
          <CloseOutlined
            key="del"
            onClick={() => {
              onDelete(data.id);
              onRead(data.id);
            }}
          />,
        ]}
        onView={() => {
          onDelete(data.id);
        }}
        avatar={false}
        hoverable={false}
        data={data}
      />
    </motion.div>
  );
});

const MessageNotifyDrawer: React.FC = () => {
  const [list, set] = useState<IMessageItem[]>([]);
  const { run: mark } = useRequest(services.message.markAsRead, {
    manual: true,
  });

  useWebsocketSubscribe(
    UsedWebsocketEvent.MESSAGE_REFRESH,
    (socketData) => {
      set((prev) => prev.concat(socketData));
    },
    {
      channel: UsedWebsocketChannel.COMPANY_MEMBER,
    },
  );
  useWebsocketSubscribe(
    UsedWebsocketEvent.MESSAGE_REFRESH,
    (socketData) => {
      set((prev) => prev.concat(socketData));
    },
    {
      channel: UsedWebsocketChannel.MEMBER,
    },
  );

  const onDelete = useCallback(
    (id: number) => {
      set((prev) => prev.filter((item) => item.id !== id));
    },
    [set],
  );

  const onRead = useCallback(
    (id: number) => {
      mark({
        ids: [id],
      });
    },
    [mark],
  );

  return createPortal(
    <TodoGlobalViewerProvider>
      <AnimateSharedLayout>
        <motion.div
          style={{
            zIndex: list.length ? 10000 : 1,
          }}
          layout
          className={styles.drawer}
        >
          <AnimatePresence>
            {list.map((item) => (
              <MessageDrawerItem
                onRead={onRead}
                onDelete={onDelete}
                data={item}
                key={item.id}
              />
            ))}
          </AnimatePresence>
        </motion.div>
      </AnimateSharedLayout>
    </TodoGlobalViewerProvider>,
    document.body,
  );
};

export default memo(MessageNotifyDrawer);
