export const auTrans = {
  api: 'https://api.addressfinder.io/api/au/address',
  appKey: '68EMCYHT3UQN9WAV7GDJ',
  secKey: 'DC37F6BQLVX84TUHWYNE',
};

export const MAX_FILE_SIZE = 5 * 1024 * 1024;

export const MAX_COMPRESSED_FILE_SIZE = 30 * 1024 * 1024;

export const AUSTRALIA_COUNTRY_CODE = '61';

export const CLIENT_DOMAIN_PREFIX = 'agent.oneulink.com';

export enum FileUploadTypeEnum {
  COMMON = 'common',
  // 客户文件
  AGENT_CUSTOMER = 'agent-customer',
  // oneulink 案件
  CHANNEL_CASE = 'channel-case',
  // oneulink coe payment
  CHANNEL_COE_PAYMENT = 'channel-coe-payment',
  // 学校单一表格
  CLIENT_FORM = 'clientForm',
  // 富文本
  RICH_TEXT = 'rich-text',
}

export const BACKEND_PATHNAMES = [
  '/system/router-rule',
  '/system/api-rule',
  '/system/role-group',
  '/system-update',
  '/system-update/list',
  '/system-update/detail',
  '/system-tips',
  '/system-accounts',
  '/system-companys',
];

export const SYSTEM_UPDATE_PROMPT_PATHNAME = '/system-update-prompt';
export const ADMIN_LOGIN = '/user/admin-login';
export const COMPANY_PATHNAMES = ['/welcome', '/create-company'];

export const WECHAT_APP_ID = 'wx983fbf0ba236559e';
export const WECHAT_APP_SECRET = '5d3bbd429f4d2bd3d0268183ae51191c';
