import { GlobalOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useModel } from 'umi';
import { MessageTaskEventType, MessageTaskGroup } from '@/data';
import { useFormatMessage } from '@/hooks';
import { formatDate } from '@/utils';

export enum MessageTaskRecipientType {
  // 代称
  PRONOUN = 'pronoun',
  /**
   * 员工
   */
  STAFF = 'staff',
  /**
   * 客户
   */
  CUSTOMER = 'customer',
}

export enum TriggerDateType {
  NORMAL = 'normal',
  DYNAMIC = 'dynamic',
}

export enum MessageTaskPriorityType {
  IMPORTANT = 1,
  NORMAL = 2,
  UNIMPORTANT = 3,
}

export const useRecipientTypeEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () => ({
      [MessageTaskRecipientType.CUSTOMER]: {
        text: formatMessage('客户'),
        color: 'geekblue',
      },
      [MessageTaskRecipientType.STAFF]: {
        text: formatMessage('专员'),
        color: 'blue',
      },
      [MessageTaskRecipientType.PRONOUN]: {
        text: formatMessage('代称'),
        color: 'green',
      },
    }),
    [formatMessage],
  );
};

export const useGroupEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () => ({
      [MessageTaskGroup.OVERALL]: formatMessage('全局'),
      [MessageTaskGroup.CLIENT]: formatMessage('客户'),
      [MessageTaskGroup.CASE]: formatMessage('案件'),
    }),
    [formatMessage],
  );
};

export const usePersonnelNameEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const client = {
      'customer-self': formatMessage('客户'),
    };
    const caseGroup = {
      'case-referee': formatMessage('案件推荐人'),
      'case-consultant': formatMessage('案件专员'),
      'case-created': formatMessage('案件创建人'),
      ...client,
    };
    const companyGroup = {
      founder: formatMessage('公司创始人'),
    };

    const clientGroup = {
      ...client,
      'customer-referee': formatMessage('客户推荐人'),
      'customer-created': formatMessage('客户创建人'),
      'customer-last-handle-staff': formatMessage('客户最近案件处理专员'),
    };

    return {
      caseGroup,
      clientGroup,
      companyGroup,
      data: {
        ...caseGroup,
        ...clientGroup,
        ...companyGroup,
      },
    };
  }, [formatMessage]);
};

export const useMessageTaskTriggerDescriptors = () => {
  const { formatMessage } = useFormatMessage();
  const { companyTimezone } =
    useModel('@@initialState')?.initialState?.currentUser?.information ?? {};

  const getTimeDescriptor = useCallback(
    (hour: number) => {
      if (hour === 0) {
        return '当前时刻';
      }
      if (hour % 24 === 0) {
        const day = Math.abs(hour / 24);
        if (day % 7 === 0) {
          return hour < 0
            ? formatMessage('{week} 周之前', { week: day / 7 })
            : formatMessage('{week} 周之前', { week: day / 7 });
        }
        return hour < 0
          ? formatMessage('{day} 天之前', { day })
          : formatMessage('{day} 天之后', { day });
      }
      return hour < 0
        ? formatMessage('{hour} 小时之前', { hour: -hour })
        : formatMessage('{hour} 小时之后', { hour });
    },
    [formatMessage],
  );

  const eventDescriptors: Record<
    | MessageTaskEventType.ON_CLIENT_CREATED
    | MessageTaskEventType.ON_CASE_CREATED
    | MessageTaskEventType.ON_CASE_COMPLETED
    | MessageTaskEventType.ON_VISA_WILL_DUE
    | MessageTaskEventType.ON_VISA_EXPIRATION_URGENT,
    (name?: string) => string
  > = useMemo(
    () => ({
      [MessageTaskEventType.ON_CASE_COMPLETED]: (name, clientName?: string) =>
        clientName
          ? formatMessage('在客户 [{name}] 的案件完成时发送', {
              name: clientName,
            })
          : name
          ? formatMessage('在案件 [{name}] 完成时发送', { name })
          : formatMessage('在案件完成时发送'),
      [MessageTaskEventType.ON_CLIENT_CREATED]: () =>
        formatMessage('在客户创建时发送'),
      [MessageTaskEventType.ON_CASE_CREATED]: () =>
        formatMessage('在案件创建时发送'),
      [MessageTaskEventType.ON_VISA_WILL_DUE]: () =>
        formatMessage('签证到期临期提醒'),

      [MessageTaskEventType.ON_VISA_EXPIRATION_URGENT]: () =>
        formatMessage('签证到期紧急提醒'),
    }),
    [formatMessage],
  );
  const dateDescriptors: Record<
    | MessageTaskEventType.PASSPORT
    | MessageTaskEventType.VISA
    | MessageTaskEventType.CLIENT_BIRTHDAY
    | MessageTaskEventType.ON_COE_COURSE_START
    | MessageTaskEventType.ON_COE_COURSE_END,
    (date?: number) => string
  > = useMemo(
    () => ({
      [MessageTaskEventType.CLIENT_BIRTHDAY]: (hour) =>
        isNil(hour)
          ? formatMessage('客户生日')
          : formatMessage('在客户生日 {desc}', {
              desc: getTimeDescriptor(hour),
            }),
      [MessageTaskEventType.PASSPORT]: (hour) =>
        isNil(hour)
          ? formatMessage('护照到期日')
          : formatMessage('在护照到期日 {desc}', {
              desc: getTimeDescriptor(hour),
            }),
      [MessageTaskEventType.VISA]: (hour) =>
        isNil(hour)
          ? formatMessage('签证到期日')
          : formatMessage('在签证到期日 {desc}', {
              desc: getTimeDescriptor(hour),
            }),
      [MessageTaskEventType.ON_COE_COURSE_END]: (hour) =>
        isNil(hour)
          ? formatMessage('课程结束日')
          : formatMessage('在课程结束日 {desc}', {
              desc: getTimeDescriptor(hour),
            }),
      [MessageTaskEventType.ON_COE_COURSE_START]: (hour) =>
        isNil(hour)
          ? formatMessage('课程开始日')
          : formatMessage('在课程开始日 {desc}', {
              desc: getTimeDescriptor(hour),
            }),
    }),
    [formatMessage, getTimeDescriptor],
  );

  const normalDescriptor = (date: string) => (
    <Space size={4}>
      {formatMessage('在 {date}|{timezone}|触发', {
        date: formatDate(date, true),
        timezone: companyTimezone,
      })
        .split('|')
        .map((item, index) => (
          <span
            key={item}
            className={classNames(index === 1 && 'text-primary')}
          >
            {index === 1 && <GlobalOutlined />} {item}
          </span>
        ))}
    </Space>
  );

  return { dateDescriptors, eventDescriptors, normalDescriptor };
};
