import classnames from 'classnames';
import React from 'react';
import styles from './index.less';

export const FormText = React.forwardRef<
  HTMLDivElement,
  {
    value?: any;
    onChange?: (value: any) => void;
    getText?: (value: any) => React.ReactNode;
    className?: string;
  }
>(({ value, getText, className }, ref) => {
  return (
    <div className={classnames(styles.text, className)} ref={ref}>
      {getText ? getText(value) : value}
    </div>
  );
});
