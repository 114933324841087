import { CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'antd';
import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { history, useModel } from 'umi';
import { ResponseSchema, services } from '@/api';
import banner from '@/assets/system-version-banner.png';
import {
  isBackendAdminPage,
  redirectToUpdatePage,
  resolveUpdateCompletedDirect,
  shouldRedirectUpdatePage,
} from '@/auth';
import { ModalManager } from '@/components/ModalManager';
import Popup from '@/components/Popup';
import {
  UsedWebsocketEvent,
  useWebsocketSubscribe,
} from '@/components/Websocket';
import { SYSTEM_UPDATE_PROMPT_PATHNAME } from '@/constant';
import { RoleType } from '@/data';
import { useFormatMessage } from '@/hooks';
import { useAppGlobalStateContext } from '@/layouts/components';
import styles from './SystemVersionPrompt.less';

export default () => {
  const { initialState = {} } = useModel('@@initialState');
  const { formatMessage } = useFormatMessage();
  const { update } = useAppGlobalStateContext();
  const [open, setOpen] = useState(false);
  const [initialOpened, setInitialOpened] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(APP_VERSION);

  const status = initialState?.latestRelease?.status;
  const { token, role } = initialState;

  const { data } = useQuery<
    ResponseSchema<{
      data: {
        id: number;
        isRead: 0 | 1;
        summary: string;
        title: string;
        versionNumber: string;
      }[];
    }>
  >(
    [services.systemUpdate.front.getLatestReleasedLog.key, currentVersion],
    ({ signal }) =>
      services.systemUpdate.front.getReleasedLogList({ pageSize: 2, signal }),
    {
      enabled: !!token,
    },
  );
  const { mutateAsync: markAsRead, isLoading } = useMutation(
    [services.systemUpdate.front.markReleaseLogAsRead.key],
    services.systemUpdate.front.markReleaseLogAsRead,
  );

  const { pathname } = history.location;

  const disable =
    initialState.latestRelease?.status !== 'completed' ||
    isBackendAdminPage(pathname) ||
    initialState.role === RoleType.BACKEND ||
    (initialOpened &&
      (pathname.includes('/client-user') ||
        pathname === '/user/login' ||
        pathname === '/user/register')) ||
    (token ? !data : false);

  useWebsocketSubscribe<{ number: string }>(
    UsedWebsocketEvent.SYSTEM_VERSION_CHANGE,
    ({ number }) => {
      setCurrentVersion(number);

      if (!disable) {
        setOpen(number !== initialState.localeSystemVersion);

        if (number !== initialState.localeSystemVersion) {
          update();
        }
      }
    },
  );

  const latestNotReadRelease = !data?.data.data?.[0].isRead
    ? data?.data.data?.[0]
    : null;
  const nextOpen = latestNotReadRelease
    ? true
    : initialState?.latestRelease &&
      initialState.latestRelease.status === 'completed' &&
      initialState.systemVersion?.toLowerCase() !==
        initialState.localeSystemVersion?.toLowerCase();

  useEffect(() => {
    if (!status) {
      return;
    }

    if (isBackendAdminPage(pathname)) {
      return;
    }

    if (status === 'completed' && pathname === SYSTEM_UPDATE_PROMPT_PATHNAME) {
      resolveUpdateCompletedDirect(role === RoleType.BACKEND);
    } else if (status === 'updating' && shouldRedirectUpdatePage(pathname)) {
      redirectToUpdatePage();
    }
  }, [status, role, pathname]);

  useEffect(() => {
    if (nextOpen) {
      setInitialOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextOpen && !disable) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [nextOpen, disable]);

  const handleClose = () => {
    setOpen(false);

    if (latestNotReadRelease) {
      markAsRead({ id: latestNotReadRelease.id });
    }
  };

  return (
    <ModalManager
      queue="SYSTEM_VERSION_PROMPT"
      visibleProperty="open"
      visible={open}
    >
      <Popup
        open={open}
        onClose={handleClose}
        bodyClassName={styles.body}
        maskClosable={false}
        immediate
      >
        <div
          className={styles.header}
          style={{
            backgroundImage: `url(${banner})`,
          }}
        >
          <h3>{formatMessage('系统更新提示')}</h3>
          <Button
            type="text"
            onClick={handleClose}
            icon={<CloseOutlined />}
            className={styles.close}
          />
        </div>
        <div className={styles.content}>
          <p>{formatMessage('尊敬的用户您好，')}</p>
          <p>
            {formatMessage(
              '检测到当前系统最新版本为 {version}，需要升级后使用。',
              {
                version: currentVersion,
              },
            )}
          </p>

          {!!latestNotReadRelease && (
            <>
              <p>{formatMessage('以下是本次系统更新内容：')}</p>
              <div className={styles.release}>
                <h4>{latestNotReadRelease.title}</h4>
                <dl>
                  {latestNotReadRelease.summary
                    .split('\n')
                    .filter(Boolean)
                    .map((item, index) => (
                      <dd key={item}>
                        {index + 1}. {item}
                      </dd>
                    ))}
                </dl>
              </div>
            </>
          )}

          <div className={styles.footer}>
            {latestNotReadRelease && (
              <Button
                onClick={() => {
                  setOpen(false);
                  history.push(`/releases/${latestNotReadRelease.id}`);
                }}
                size="large"
                type="dashed"
              >
                {formatMessage('查看更新详情')}
              </Button>
            )}
            <Button
              onClick={async () => {
                const action = () => {
                  window.location.href = `${
                    window.location.pathname
                  }?${QueryString.stringify({
                    ...QueryString.parse(
                      window.location.search.replace('?', ''),
                    ),
                    rd: Date.now(),
                  })}`;
                };
                if (latestNotReadRelease) {
                  await markAsRead({ id: latestNotReadRelease.id });
                  action();
                } else {
                  action();
                }
              }}
              loading={isLoading}
              type="primary"
              icon={<ReloadOutlined />}
              size="large"
            >
              {formatMessage('升级系统')}
            </Button>
          </div>
        </div>
      </Popup>
    </ModalManager>
  );
};
