import Utf8 from 'crypto-js/enc-utf8';
import HMAC from 'crypto-js/hmac-sha256';
import MD5 from 'crypto-js/md5';
import { ENV } from '@/env';

const hashKey = Utf8.parse(ENV.HASH_KEY);

export const hash = (message: string) => HMAC(message, hashKey).toString();

export const md5 = MD5;
