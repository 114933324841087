import ProForm from '@ant-design/pro-form';
import { Modal } from 'antd';
import { Moment } from 'moment';
import { TodoStatus } from '@/data';
import { useCommonRules, useFormatMessage } from '@/hooks';
import { AbsoluteDate } from '../MessageTask';

const STATE_MACHINE: Record<TodoStatus, TodoStatus[]> = {
  [TodoStatus.CLOSED]: [TodoStatus.IN_PROGRESS],
  [TodoStatus.COMPLETED]: [TodoStatus.IN_PROGRESS],
  [TodoStatus.IN_PROGRESS]: [TodoStatus.CLOSED, TodoStatus.COMPLETED],
  [TodoStatus.OVERDUE]: [TodoStatus.IN_PROGRESS, TodoStatus.COMPLETED],
};

const ALL_STATUS = [
  TodoStatus.CLOSED,
  TodoStatus.COMPLETED,
  TodoStatus.IN_PROGRESS,
  TodoStatus.OVERDUE,
];

const NeedDeadline = [TodoStatus.IN_PROGRESS];

export type TodoStatusSelectedResult = {
  status: TodoStatus;
  deadline?: string;
};

const useTodoStatusStateMachine = (currentStatus?: TodoStatus) => {
  const { formatMessage } = useFormatMessage();
  const [ctrl, holder] = Modal.useModal();
  const [form] = ProForm.useForm();
  const { selectRules } = useCommonRules();

  const handleSelect = async (targetStatus: TodoStatus) => {
    if (!currentStatus) {
      return Promise.reject();
    }

    return new Promise<TodoStatusSelectedResult>((resolve, reject) => {
      if (!NeedDeadline.includes(targetStatus)) {
        resolve({
          status: targetStatus,
        });
      } else {
        const modal = ctrl.confirm({
          title: formatMessage('请重新选择截止时间'),
          content: (
            <ProForm submitter={false} form={form}>
              <ProForm.Item name="deadline" rules={selectRules}>
                <AbsoluteDate precision="minute" />
              </ProForm.Item>
            </ProForm>
          ),
          async onOk() {
            const { deadline } = await form.validateFields();
            resolve({
              status: targetStatus,
              deadline: (deadline as Moment).toISOString(),
            });
            modal.destroy();
          },
          onCancel() {
            modal.destroy();
            reject();
          },
          maskClosable: false,
          centered: true,
        });
      }
    });
  };

  const matchedStatusList = currentStatus
    ? STATE_MACHINE[currentStatus] ?? []
    : ALL_STATUS;

  return [{ matchedStatusList, handleSelect }, holder] as const;
};

export default useTodoStatusStateMachine;
