/* eslint-disable react/destructuring-assignment */
import { message } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import ReactDOM from 'react-dom';
import { MAX_FILE_SIZE } from '@/constant';
import { OSSUploaderUploadParams, ossUploader } from '@/core';
import { getId, normalizeUploadValue, sleep } from '@/utils';
import styles from './index.less';

const fileTypeFileValidator = (type: string, limit: string[]) => {
  const arr = limit.map((item) => {
    return item === 'image' ? '(?:jpeg?|png|bmp)' : item;
  });
  const reg = new RegExp(`(?:${arr.join('|')})$`, 'i');
  return reg.test(type);
};

const fileSizeValidator = (size: number, limit: number) => {
  if (size >= limit) {
    message.error(`File must smaller than ${limit / 1024 / 1024}MB!`);
    return false;
  }

  return true;
};

export interface CustomDragUploadProps extends OSSUploaderUploadParams {
  onChange: (fileList: UploadFile[]) => void;
  dragPopupTitle?: string;
  value?: UploadFile[];
  enableDragPopup?: boolean;
  fileSize?: number;
  fileType?: string[];
  fileNum?: number;
  normalize?: (resp: any) => string;
  component?: keyof JSX.IntrinsicElements;
  children?: React.ReactElement;
  className?: string;
}

interface CustomDragUploadState {
  popupVisible: boolean;
  fileList: UploadFile[];
}

export default class CustomDragUpload extends React.PureComponent<
  CustomDragUploadProps,
  CustomDragUploadState
> {
  static defaultProps: Partial<CustomDragUploadProps> = {
    onChange: () => undefined,
    component: 'div',
    // 文件大小
    fileSize: MAX_FILE_SIZE,
    fileNum: 9,
    normalize: (resp) => resp.data.fileUrl,
    // 是否开启拖拽 popup
    enableDragPopup: true,
    dragPopupTitle: '',
    fileType: ['image', 'pdf', 'doc', 'docx'],
  };

  constructor(props: Readonly<CustomDragUploadProps>) {
    super(props);

    this.state = {
      fileList: [],
      popupVisible: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: CustomDragUploadProps,
    prevState: CustomDragUploadState,
  ) {
    if ('value' in nextProps && nextProps.value !== prevState.fileList) {
      return {
        fileList: normalizeUploadValue(nextProps.value),
      };
    }

    return null;
  }

  componentDidMount() {
    const { value } = this.props;
    const { fileList } = this.state;
    // mount 更新 filelist
    if ('value' in this.props && value !== fileList) {
      this.notify(fileList);
    }
  }

  // 更新 fileList
  notify = (fileList: UploadFile[]) => {
    const { onChange } = this.props;

    if ('value' in this.props) {
      if (typeof onChange === 'function') {
        onChange(fileList);
      }

      return;
    }

    this.setState({
      fileList,
    });
  };

  onDragStart = (evt: React.DragEvent) => {
    evt.dataTransfer.effectAllowed = 'copy';
  };

  onDragEnd = () => {
    this.setState({
      popupVisible: false,
    });
  };

  onDragEnter = (evt: React.DragEvent) => {
    evt.preventDefault();
    const { enableDragPopup } = this.props;
    const { popupVisible } = this.state;

    if (enableDragPopup && !popupVisible) {
      this.setState({
        popupVisible: true,
      });
    }
  };

  onDragLeave = () => {
    this.setState({
      popupVisible: false,
    });
  };

  onDragOver = (evt: React.DragEvent) => {
    evt.preventDefault();
  };

  onDrop = async (evt: React.DragEvent) => {
    evt.preventDefault();
    const { fileNum } = this.props;

    const files = Array.from(evt.dataTransfer.files)
      .filter(Boolean)
      .slice(-fileNum!);

    if (!files.length) {
      this.setState({
        popupVisible: false,
      });
    } else {
      for (let i = 0; i < files.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        await this.createMultiUploadTask(files[i]);
      }
    }
  };

  createMultiUploadTask = async (file: File) => {
    this.createUploadTask(file);
    await sleep(32);
  };

  createUploadTask = (file: File) => {
    const { fileSize } = this.props;
    this.setState({
      popupVisible: false,
    });

    if (!file) {
      return;
    }

    if (!fileSizeValidator(file.size, fileSize!)) {
      return;
    }

    let { type } = file;
    if (!type) {
      const match = file.name.match(/\.(.+)$/);
      if (!match) {
        return;
      }
      // eslint-disable-next-line prefer-destructuring
      type = match[1];
    }

    if (type === 'application/msword') {
      type = 'doc';
    }

    if (
      type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      type = 'docx';
    }

    type = type.replace(/^\/+/, '');

    if (!fileTypeFileValidator(type, this.props.fileType!)) {
      return;
    }

    this.request(this.createMeta(file));
  };

  createMeta = (file: File) => {
    const uid = getId();
    const meta = {
      uid,
      originFileObj: file,
    };
    const keys = [
      'name',
      'lastModified',
      'lastModifiedDate',
      'size',
      'type',
      'webkitRelativePath',
    ];

    // file 不能浅复制 ?
    keys.forEach((key) => {
      meta[key] = file[key];
    });

    return meta;
  };

  request = (meta: AnyObject) => {
    const fileList = this.state.fileList?.slice() ?? [];
    const { uid, originFileObj: file } = meta;
    const { extraData, normalize, clientId } = this.props;

    meta.status = 'uploading';
    meta.percent = 0;
    fileList.push(meta as UploadFile);

    this.notify(fileList);

    ossUploader
      .upload(file, {
        clientId,
        extraData,
      })
      .then((resp) => {
        const url = normalize!(resp);
        this.setFileState(uid, {
          status: 'done',
          url,
          response: resp,
          percent: 100,
        });
      })
      .catch(() => {
        const list = this.state.fileList.slice();
        const index = list.findIndex((item) => item.uid === uid);
        // 删除
        if (index > -1) {
          list.splice(index, 1);
          this.notify(list);
        }
      });
  };

  setFileState = (uid: string, state: Partial<UploadFile>) => {
    const list = this.state.fileList.slice();
    const index = list.findIndex((item) => item.uid === uid);

    if (index > -1) {
      list[index] = {
        ...list[index],
        ...state,
      };
      this.notify(list);
    }
  };

  renderDragPopup = () => {
    const { popupVisible } = this.state;
    if (!popupVisible) {
      return null;
    }

    const popup = (
      <div
        className={styles.popup}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
      >
        <span>{this.props.dragPopupTitle}</span>
      </div>
    );

    return ReactDOM.createPortal(popup, document.body);
  };

  render() {
    const { children, component, className } = this.props;
    const el = React.createElement(
      component!,
      {
        tabIndex: -1,
        onDrop: this.onDrop,
        onDragStart: this.onDragStart,
        onDragEnd: this.onDragEnd,
        onDragEnter: this.onDragEnter,
        className,
      },

      children,
    );

    return (
      <>
        {el}
        {this.renderDragPopup()}
      </>
    );
  }
}
