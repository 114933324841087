import { useRequest } from 'ahooks';
import { useRef, useState } from 'react';
import { ResponseSchema, services } from '@/api';
import FileLimitPrompt from './FileLimitPrompt';

const useFileLimit = (action: (...args: any) => Promise<any>) => {
  const [visible, setVisible] = useState(false);
  const lastParams = useRef<any>();
  const { loading, run } = useRequest(
    async (...params: any) => {
      const { data } = await services.file.limiter.check<
        ResponseSchema<{ passed: boolean }>
      >();

      if (!data.passed) {
        lastParams.current = [...params];
        throw Error('File Download limited');
      }

      await action(...params);
    },
    {
      throwOnError: false,
      onError(err) {
        setVisible(true);
      },
      manual: true,
    },
  );

  const fileLimitHolder = visible && (
    <FileLimitPrompt
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        setVisible(false);
        action(...(lastParams.current ?? []));
      }}
    />
  );

  return {
    loading,
    run,
    fileLimitHolder,
  };
};

export default useFileLimit;
