import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum TodoPriority {
  NORMAL = 'normal',
  URGENCY = 'urgency',
}

export const useTodoPriorityEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum: Record<
      TodoPriority,
      {
        text: string;
        color: string;
      }
    > = {
      [TodoPriority.NORMAL]: {
        text: formatMessage('普通'),
        color: 'geekblue',
      },
      [TodoPriority.URGENCY]: {
        text: formatMessage('紧急'),
        color: 'red',
      },
    } as const;
    return new Map(
      Object.entries(statusEnum).map((item) => [item[0], item[1]]),
    );
  }, [formatMessage]);
};

export enum TodoStatus {
  /**
   * 进行中
   */
  IN_PROGRESS = 'in-progress',

  /**
   * 已完成
   */
  COMPLETED = 'completed',

  /**
   * 已关闭
   */
  CLOSED = 'closed',

  /**
   * 已逾期
   */
  OVERDUE = 'overdue',
}

export const useTodoStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum: Record<
      TodoStatus,
      {
        text: string;
      }
    > = {
      [TodoStatus.IN_PROGRESS]: {
        text: formatMessage('进行中'),
      },
      [TodoStatus.COMPLETED]: {
        text: formatMessage('已完成'),
      },
      [TodoStatus.CLOSED]: {
        text: formatMessage('已关闭'),
      },
      [TodoStatus.OVERDUE]: {
        text: formatMessage('已逾期'),
      },
    } as const;
    return new Map(
      Object.entries(statusEnum).map((item) => [item[0], item[1]]),
    );
  }, [formatMessage]);
};
