import { ENV } from '@/env';

const ANALYSIS_URL = ENV.analysisApiBaseUrl;

/**
 * 服务端请求 原始 api
 */
const api = {
  auTrans: 'GET https://api.addressfinder.io/api/au/address',
  auGetAddressMeta: 'GET https://api.addressfinder.io/api/au/address/info',
  getIpAddress: 'GET https://api.ipify.org/?format=json',

  // 检查当前时间是否合法
  checkTime: `POST ${ANALYSIS_URL}/normal/check-time`,

  searchChinaAddress: `GET ${ANALYSIS_URL}/map/search`,
  // 根据 ip 获取 ip地址信息
  getIpGeographicInfo: 'GET /common/IpAddress',

  // 澳大利亚汇买汇卖数据
  getAustraliaInOutExchange: `${ANALYSIS_URL}/normal/au-exchange-rate`,

  // 设置系统当前版本
  setSystemCurrentVersion: 'POST /api/version/current',

  // 获取学校签证基本等级
  getSchoolVisaBaseLevel: `${ANALYSIS_URL}/school-level/base/:cricosCode`,

  // 根据经纬度获取地理位置信息
  getGeogLocation: 'GET /common/geocoder',

  // 获取微信分享配置
  getWechatShareConfig: 'GET /common/wechat/share-config',

  // 订阅学校签证等级报告
  subscribeSchoolLevelReport: `POST ${ENV.offshoreWechatApiBaseUrl}/school-level/subscribe-report/:cricosCode`,

  // 外部客户上传、查看文件
  outerClientFile: {
    getSTSToken: 'GET /api/clientForm/oss/credentials',
    view: 'GET /api/clientForm/file/:id/view',
  },

  file: {
    upload: 'POST /common/file',
    acid: 'POST /common/acid',
    viewFile: 'GET /common/file/:id/view',
    downloadFile: 'GET /common/file/:id/download',
    uploadClientFile: 'POST /common/customer/:id/file',
    // 获取文件上传临时 token
    getSTSToken: 'GET /common/oss/credentials',
    // 批量获取文件名称
    getFileNames: 'POST /common/file/name-for-files',

    // 识别 oshc 的生日
    acidOSHCDob: 'POST /common/acid/oshc/dob',

    // 文件限流
    limiter: {
      // 检查当前文件下载是否超过限制
      check: 'GET /common/fileLimiter/check',
      // 发送短信验证码
      sendSmsCode: 'POST /common/fileLimiter/sentSms',
      // 返回将发送短信的相关信息 (国家手机编号 和脱敏手机号)
      getSmsInfo: 'GET /common/fileLimiter/sentSms',
      relieve: 'POST /common/fileLimiter/relieve',
    },

    // 公开查看文件
    overViewFile: 'GET /common/file/:id/overt-view',
    // 公开下载文件
    overDownloadFile: 'GET /common/file/:id/overt-download',

    // 压缩 PDF
    compressPdf: 'POST /common/file/compress-pdf',
  },

  common: {
    getSchoolList: 'GET /common/resource/school',
    getApplyCourseSelector: 'GET /common/resource/school/:code/category',
    // 获取课程校区
    getSchoolCampus: 'GET /common/resource/school/:schoolCode/campus',
    // 获取课程校区
    getCourseCampus: 'GET /common/resource/course/:courseCode/campus',
    getCourseList: 'GET /common/resource/school/:schoolCode/course',
    // 获取所有可用时区
    getTimezoneList: 'GET /common/timezone',
    // 身份证识别
    identifyIDCard: 'GET /common/idcard/detailed',

    // 护照识别 - 获取信息
    identifyPassport: 'POST /common/passport/scan',
    // 翻译
    translate: 'POST /common/translate',
    // 自动识别文件分类
    aiIdentifyFileType: 'GET /common/infer/file/type',
    // 获取课程精确学费
    getCourseExactTuition: 'GET /common/resource/course/tuitionFee',

    // 获取全部职业
    getAllOccupations: 'GET /common/occupation',

    // 文本转word
    textToWord: 'POST /common/tool/text2word',

    // 汉字转拼音
    chinese2pinyin: 'POST /common/pinyin',

    // 获取文件分类
    getFileCategories: 'GET /common/caseFileCabinet/categorys',
  },

  commonAuth: {
    getCaptcha: 'POST /common/captcha',
    getEmailVerifyCode: 'POST /common/captcha/email',
  },

  webAuthn: {
    // 绑定注册获取服务端签发数据
    bind: 'POST /bind',
    // 验证绑定
    validateBind: 'POST /validate-bind',

    // 验证登录-成功给出token
    login: 'POST /login',
    // 验证登陆
    validateLogin: 'POST /validate-login',

    // 添加尝试登录记录
    reportAttemptLoginError: `POST ${ENV.apiBaseUrl}/api/webauthn/attempt-log`,
  },

  frontAuth: {
    // 登录
    login: 'POST /api/member/login',
    // 注册
    register: 'POSt /api/member/register',

    accountLogin: 'POST /api/member/login/email',
    forgot: 'POST /api/member/forget',

    // 获取雇员邀请信息
    getInviteDetail: 'GET /api/invite/:k',
    // 邮件邀请-确认加入当前公司
    joinCompanyByInvite: 'POST /api/company/invite/:k/join',

    getEmailOTPCode: 'POST /api/member/login/sendCaptcha',
    emailOTPLogin: 'POST /api/member/login/emailCaptcha',
  },

  backendAuth: {
    login: 'POST /backend/login',
  },

  account: {
    // 获取用户信息
    getUserInfo: 'GET /common/member',

    // 是否具有webauthn登录尝试记录(10分钟)
    getWebAuthnAttemptLoginError: 'GET /api/webauthn/has-attempt-log',

    // 切换公司
    switchCompany: 'GET /api/member/company/:id/change',
    // 创建公司
    createCompany: 'POST /api/member/company',
    // 更新用户信息
    updateUserInfo: 'PUT /api/member',
    // 更新邮箱或者手机号
    updateEmailOrMobile: 'POST /api/member/changeEmailOrPhone',

    // 绑定邮箱
    bindUserEmail: 'POST /api/member/bindEmail',

    // 员工公司邀请记录
    getInviteMessages: 'GET /api/member/invite/record',

    // 获取账号所在公司列表
    getCompanys: 'GET /common/member/companys',

    // 获取个人付款信息
    getPaymentInfo: 'GET /api/member/payment-method/own',

    // 修改个人付款信息
    updatePaymentInfo: 'POST /api/member/payment-method/own',
    // 检查员工旧数据 有付款信息同时没有对新付款信息进行任何操作
    checkOldPaymentInfo: 'GET /api/member/payment-method/check-old',

    // 后台管理
    backend: {
      // 更新用户信息
      updateUserInfo: 'PUT /backend/manage',
      // 更新邮箱或者手机号
      updateEmailOrMobile: 'POST /backend/manage/changeEmailOrPhone',
      // 更新密码
      updateUserPassword: 'PUT /backend/manage/password',
    },
  },

  // 功能引导
  functionGuide: {
    // 专员是否查看过新功能步骤指导
    getIsRead: 'GET /api/member/instruction/view-status',
    // 专员标记为知道了
    markRead: 'POST /api/member/instruction/set-viewed',
  },

  officialPublicity: {
    // 保存专员宣传信息
    updateMemberInfo: 'PUT /api/sale/member',
    // 获取专员宣传信息
    getMemberInfo: 'GET /api/sale/member',
    // 校验牌照
    checkLicenseCode: 'POST /api/sale/member/checkLicense',

    oneulink: {
      // 获取公司列表
      getCompanyList: 'GET /api/company/publicity/permit',
      // 设置公司是否能宣传
      setCompanyPublicity: 'POST /api/company/:id/publicity/permit',
    },
  },

  filesign: {
    // 专员签名-添加新签名
    createPersonalSign: 'POST /api/member/autograph',
    // 个人签名-签名签名列表
    getPersonalSignList: 'GET /api/member/autograph',
    getSignList: 'GET /api/signature/file',
    /**
     * @deprecated
     * 获取客户文件对应的签名文件列表
     */
    getFileSignRecord: 'GET /api/agent/customer/:id/file/:fileId/signatureFile',
    /**
     * @deprecated
     * 取消用户的签名文件使其无效化
     */
    cancelFileSign:
      'PUT /api/agent/customer/:id/file/:fileId/signatureFile/:code/invalid',
  },

  dashboard: {
    // 中介端首页统计
    getAgentNormalStatistic: 'GET /api/dashboard',
    // channel首页统计
    getChannelNormalStatistic: 'GET /api/channel/dashboard',

    // 统计中介公司案件步骤完成情况
    getAgentCaseStepsStatistic: 'GET /api/data/report/complete-step',
    // 统计oneulink 提交的升学转学开学时间
    getChannelSchoolCaseStatistic:
      'GET /api/channel/data-report/school-admission-date',
    // 统计oneulink公司案件步骤完成情况
    getChannelCaseStepsStatistic: 'GET /api/channel/data-report/complete-step',

    // oneulink - 统计协助专员案件完成情况
    getChannelAssistStepsStats: 'GET /api/channel/data-report/assistant-member',

    // 学校渠道自动归类到案件的邮件附件绑定到案件步骤
    channelMasterAttachmentBind2CaseStep: {
      // 获取所有预绑定到步骤的附件
      getAttachments: 'GET /api/channel/master-agent-inbox-attachment-infer',
      // 忽略附件
      ignore:
        'POST /api/channel/master-agent-inbox-attachment-infer/:inferId/ignore',
      // 绑定到步骤
      bind:
        'POST /api/channel/master-agent-inbox-attachment-infer/:inferId/exec-operation',
      // 获取案件详情
      getCaseSteps:
        'GET /api/channel/master-agent-inbox-attachment-infer/:inferId/case-step',
      // 获取文件原始 url
      getFileRawUrl:
        'GET /api/channel/master-agent-inbox-attachment-infer/:inferId/raw-file-url',
      // 客户重复材料检查
      clientMaterialRepeatabilityCheck:
        'POST /api/channel/master-agent-inbox-attachment-infer/:inferId/material-repetition',
      // 案件绑定文件重复性检查
      stepFileRepeatabilityCheck:
        'POST /api/channel/master-agent-inbox-attachment-infer/:inferId/duplicate-check',
    },

    // 学校上级渠道邮件发布新闻到官网
    channelMasterEmailPost2News: {
      // 预览新闻
      previewNews:
        'GET /api/channel/masterAgent/:masterAgentId/inbox/:emailId/preview-news',
      // 选择学校院系
      getSchoolSubjects: 'GET /api/school-news/:schoolCode/select-subject',
      // 发布新闻
      postNews:
        'POST /api/channel/masterAgent/:masterAgentId/inbox/:emailId/publish-news',

      // 获取新闻或广告类型的邮件
      getEmails: 'GET /api/channel/master-agent-inbox/advertise',
    },
  },

  company: {
    // 获取员工列表
    getStaffList: '/api/company/staff',
    // 获取员工所有职位列表
    getStaffJobList: 'GET /api/company/staff/competent',
    // 修改员工职位
    updateStaff: 'PUT /api/company/staff/:id',
    // 编辑员工在职状态
    updateStaffStatus: 'PUT /api/company/staff/:id/status',
    // 获取邀请记录列表
    getInviteLogList: 'GET /api/company/invite/log',
    // 获取操作日志
    getOperationLogList: 'GET /api/company/invite/operationlog',
    //  获取职位可选择所有角色·
    getJobRoleList: 'GET /api/company/competent/roles',
    // 邀请员工加入公司（短信、邮件发送）
    inviteStaff: 'POST /api/company/invite',
    // 链接邀请
    inviteStaffByUrl: 'POST /api/company/invite/send',
    // 搜索专员
    searchInviteStaff: 'POST /api/company/invite/search',

    // 转移公司
    transferCompany: 'POST /api/company/transfer',
    // 获取转移时的员工选择列表
    getTransferStaffList: 'GET /api/company/transfer/staff',
    // 获取转移时的员工职位选择列表
    getTransferJobList: 'GET /api/company/transfer/competent',

    // 获取公司信息
    getCompanyInfo: 'GET /api/company',
    // 编辑公司信息
    updateCompanyInfo: 'PUT /api/company',
    // 检查公司子域名是否重复
    checkSubdomain: 'GET /api/company/checkSubDomain',

    // 获取公司额外信息
    getCompanyExtraInfo: 'GET /api/company/extraInfo',
    // 更新公司额外信息
    updateCompanyExtraInfo: 'PUT /api/company/extraInfo',

    // 获取账单模板列表
    getBillTpl: 'GET /api/billsetting/tpl',
    // 获取账单模板详情
    getBillTplDetail: 'GET /api/billsetting/tpl/:id',
    // 创建账单模板
    createBillTpl: 'POST /api/billsetting/tpl',
    // 编辑账单模板
    updateBillTpl: 'PUT /api/billsetting/tpl/:id',
    // 删除账单模板
    deleteBillTpl: 'DELETE /api/billsetting/tpl/:id',
    // 获取公司账单默认设置信息
    getBillDefaultInfo: 'GET /api/billsetting/basic',
    // 编辑公司账单默认设置信息
    updateBillDefaultInfo: 'PUT /api/billsetting/basic',
    // 获取案件账单列表
    getAllBillList: 'GET /api/company/bill',
    // 审核案件账单
    reviewBill: 'POST /api/company/bill/:id/review',

    // 获取 coe 付款列表
    getCoePaymentList: 'GET /api/company/coePayment',
    // 获取 coe 付款详情
    getCoePaymentDetail: 'GET /api/company/coePayment/:id',
    // 审核 coe 付款记录
    reviewCoePaymentVoucher:
      'PUT /api/company/coePayment/:paymentId/voucher/:id/review',
    // 设置 coe 付款佣金比率
    setCoePaymentSchoolScale: 'PUT /api/company/coePayment/:id',
    // 结算 coe 付款记录
    settleCoePayment:
      'PUT /api/company/coePayment/:paymentId/voucher/:id/settlement',
    // 添加奖金
    addCoePaymentBonus: 'POST /api/company/coePayment/:id/bonus',
    // 获取奖金列表
    getCoePaymentBonusList: 'GET /api/company/coePayment/:id/bonus',
    // 补充付款收据文件
    supplementaryPaymentReceiptFile:
      'PUT /api/company/coePayment/:paymentId/voucher/:id/supplementary',

    getJobList: 'GET /api/company/competent',
    getJobDetail: 'GET /api/company/competent/:id',
    createJob: 'POST /api/company/competent',
    updateJob: 'PUT /api/company/competent/:id',
    deleteJob: 'DELETE /api/company/competent/:id',

    // 获取步骤配置积分列表
    getCaseStepPointList: 'GET /api/caseStep/point',
    // 更新步骤配置积分
    updateCaseStepPoint: 'POST /api/caseStep/point',

    // 材料回收站
    getMaterialRecycleList: 'GET /api/agent/fileRecycleBin',

    // 获取公司通用功能配置
    getSettingsInfo: 'GET /api/company/getStatusConfig',
    // 更新公司通用功能配置
    updateSettingsInfo: 'PUT /api/company/updateStatusConfig',

    // 获取公司付款信息
    getPaymentInfo: 'GET /api/company/payment-method/own',
    // 修改公司付款信息
    updatePaymentInfo: 'POST /api/company/payment-method/own',
    // 检查公司旧数据 有付款信息同时没有对新付款信息进行任何操作
    checkOldPaymentInfo: 'GET /api/company/payment-method/check-old',
  },

  fileAssistant: {
    // 客户归档文件列表
    getClientArchivedFileList: 'GET /api/agent/customer/:id/archiveFile',
    // 根据type获取归档文件
    getClientArchivedFileForType:
      'GET /api/agent/customer/:id/archiveFileStore',
    /**
     * 获取专员的文件归档
     */
    getStaffArchivedFileList: 'GET /api/member/archiveFile',
    /**
     * 设置归档文件状态
     */
    updateArchiveStatus: 'PUT /api/member/archiveFile/:id/status',
    /**
     * 归档文件时 获取可选择的客户列表
     */
    getClientListOfArchive: 'GET /api/member/agent/customer',
    /**
     * 专员文件归档详情
     */
    getStaffAssistantInfo: 'GET /api/member/archiveFile/info',
    /**
     * 归档文件
     */
    archiveFile: 'PUT /api/member/archiveFile/:id',
    // 获取归档文件中的 文件 url
    getArchivedFileUrl: 'GET /api/member/archiveFile/:id/info',
  },

  messageTask: {
    // 获取消息模板
    getAllTemplateList: 'GET /api/messageTask/template',
    // 创建消息模板
    createTemplate: 'POST /api/messageTask/template',
    // 获取消息模板详情
    getTemplateDetail: 'GET /api/messageTask/template/:id',
    // 编辑消息模板
    updateTemplate: 'PUT /api/messageTask/template/:id',

    // 删除模板
    deleteTemplate: 'DELETE /api/messageTask/template/:id',

    // 获取所有任务实例
    getAllJobList: 'GET /api/messageTask/work',
    // 创建任务-获取消息模板列表
    getTemplateList: 'GET /api/messageTask/template/:type/select',

    // 获取所有任务列表
    getAllTaskList: 'GET /api/messageTask/job',
    // 可关联客户
    getClientSelectList: 'GET /api/messageTask/select/customer',
    // 创建任务时 - 获取专员列表
    getStaffList: 'GET /api/messageTask/select/staff',
    // 创建任务 获取关联案件的案件列表
    getCaseList: 'GET /api/messageTask/select/case',
    // 获取案件下面的task
    getCaseTaskList: 'GET /api/agent/case/:id/task',
    // 获取客户下面的 task
    getClientTaskList: 'GET /api/agent/customer/:id/task',
    // 消息任务概览
    getTaskSummary: 'GET /api/messageTask/job/:id',
    // 获取客户详情下面任务的详情
    getTaskDetailInClient: 'GET /api/agent/customer/:id/task/:taskId/work',
    // 获取案件详情下面任务的详情
    getTaskDetailInCase: 'GET /api/agent/case/:id/task/:taskId/work',
    // 获取首页下面任务的详情
    getTaskDetailInAll: 'GET /api/messageTask/job/:id/work',
    // 获取任务实例发送的消息内容
    getMessageContent: 'GET /api/messageTask/work/:id/sendContent',
    // 创建任务
    createTask: 'POST /api/messageTask/job',
    // 更新任务
    updateTask: 'PUT /api/messageTask/job/:id',
    // 删除任务
    deleteTask: 'DELETE /api/messageTask/job/:id',
    // 设置任务状态
    setTaskStatus: 'PUT /api/messageTask/job/:id/status',

    // 将万友教育的全局任务同步到所有中介公司
    syncTemplatesAndGlobalTasksToOtherCompanys:
      'POST /api/messageTask/jobs/sync-to-all',
  },

  agentUserClient: {
    // 预创建客户
    preFillClientForm: 'POST /api/customer/collect',
    // 客户创建填表，获取填表相关信息
    getPreFillClientInfo: 'GET /api/customer/collect/:code/info',

    //  客户创建填表，根据生日姓名获取系统邮箱
    generateClientSystemEmail:
      'POST /api/customer/collect/generate-system-email-preview',

    // 获取副申请人创建客户表单信息
    getAccompanyMembersClientFormInfo: 'GET /api/joint-applicant/meta/:id',

    // 提交 GET
    submitGTE: 'POST /api/agent/case/:number/gte',
    // 获取 GTE 表单信息
    getGTEFormInfo: 'GET /api/agent/case/:number/gte',

    // 提交 956 Form
    submit956Form: 'POST /api/agent/case/:number/visa956Form',
  },

  clientUserForm: {
    auth: {
      // 获取token
      generateToken: 'GET /api/permission/verification/:number/generateToken',
      // 校验 token 是否合法
      verifyToken: 'GET /api/permission/verification/:number/checkToken',
      // 获取公司信息
      getFormInfo: 'GET /api/permission/verification/:number/info',
    },
    common: {
      // 发送签名验证码
      sendSignatureCaptcha:
        'POST /api/permission/verification/:number/sign/captcha',
    },
    visa: {
      // 签证表单
      submit: 'POST /api/agent/case/:number/visa',
      // 获取签证预填信息
      getPreFillInfo: 'GET /api/agent/case/:number/visa',

      // 表单缓存-缓存信息
      updateFormValuesCache: 'PUT /api/cache/case/:number',
      // 表单缓存-获取缓存信息
      getCachedFormValues: 'GET /api/cache/case/:number',

      // 签证表单预览pdf
      preview: 'POST /api/agent/case/:number/visa/previewPDF',
      // 获取表单信息
      getFormInfo: 'GET /api/agent/case/:number/visa/info',
      // 获取签证主申请人的信息
      getAssociatedAccompanyMembersInfo:
        'GET /api/agent/case/:number/deputy-applicant',
      // 是否需要更新用户表单
      checkNeedUpdateClientInfo:
        'GET /api/cache/case/:number/check-info-update',

      // 获取评估职业列表
      getOccupationList: 'GET /api/agent/case/:number/visa/occupations',
      // 根据课程等级获取课程
      getCourseList: 'GET /api/agent/case/:number/visa/courses/:courseLevel',
    },

    contactInfoUpdate: {
      // 获取客户基本信息、紧急联系信息
      getContactInfo: 'GET /api/agent/customer/infoUpdate/:number',
      // 更新客户信息
      updateContactInfo: 'PUT /api/agent/customer/infoUpdate/:number',
    },

    contract: {
      // 客户签署合同
      createContract: 'POST /api/customer-contract/:number',
      // 客户签署合同时，返回基本信息
      getBaseInfo: 'GET /api/customer-contract/:number/info',
      // 查看合同
      viewContract: 'GET /api/customer-contract/:number/details',
      // 预览合同
      previewContract: 'POST /api/customer-contract/:number/preview',
      // 下载合同
      downloadContract: 'GET /api/customer-contract/:number/file-download',
    },
  },

  customCase: {
    // 获取自定义案件分类
    getCategoryList: 'GET /api/company/customCase/category',
    // 创建案件自定义分类
    createCategory: 'POST /api/company/customCase/category',
    // 编辑案件自定义分类
    updateCategory: 'PUT /api/company/customCase/category/:id',
    // 删除自动移分类
    deleteCategory: 'DElETE /api/company/customCase/category/:id',
    // 创建自定义案件时获取分类
    getCustomCaseCategory: 'GET /api/company/customCase/selectCategory',

    // 创建自定义案件
    createCustomCase: 'POST /api/company/customCase/case',
    // 获取所有自定义案件
    getCustomCaseList: 'GET /api/company/customCase/case',
    // 获取自定义案件详情
    getCustomCaseDetail: 'GET /api/company/customCase/case/:id',
    // 编辑自定义案件
    updateCustomCase: 'PUT /api/company/customCase/case/:id',
    // 自定义案件排序
    sortCustomCaseList: 'PUT /api/company/customCase/case/sort',

    // 获取自定义表单列表
    getCustomFormList: 'GET /api/caseStep/customForm',
    // 创建自定义表单
    createCustomForm: 'POST /api/caseStep/customForm',
    // 获取自定义表单详情
    getCustomFormDetail: 'GET /api/caseStep/customForm/:id',

    // 新增案件步骤项配置
    createCaseStep: 'POST /api/caseStep/item',
    // 获取步骤配置详情
    getCaseStepDetail: 'GET /api/caseStep/item/:id',
    // 获取案件步骤配置项列表
    getCaseStepList: 'GET /api/caseStep/item',
    // 更新步骤项配置基本信息
    updateCaseStep: 'PUT api/caseStep/item/:id',

    // 创建步骤项分类
    createStepCategory: 'POST /api/caseStep/category',
    // 步骤项分类列表
    getStepCategoryList: 'GET /api/caseStep/category',

    // 获取步骤布局配置列表
    getBuiltinCaseList: 'GET /api/caseStep/layout',
    // 创建案件布局配置
    createBuiltinCase: 'POST /api/caseStep/layout',
    // 获取内置步骤布局详情
    getBuiltinCaseDetail: 'GET /api/caseStep/layout/:id',

    // 假删除自定义案件
    makeCustomCaseInVisible: 'DELETE /api/company/customCase/case/:id',
    // 假删除自定义表单
    makeCustomFormInVisible: 'DELETE /api/caseStep/customForm/:id',
    // 假删除自定义步骤项
    makeStepInVisible: 'DELETE /api/caseStep/item/:id',
  },

  agentClient: {
    // 客户设为有效/无效
    updateClientInvalid: 'PUT /api/agent/customer/:id/invalid',
    // 获取所有客户列表
    getClientList: '/api/agent/customer',
    // 获取为创建客户数量
    getCanCreateClientNum: 'GET /api/agent/customer/canCreated',
    // 获取客户创建邀请信息
    getInviteInfo: '/api/agent/customer/invite',
    // 发送邀请客户创建 url
    sendInvite: 'POST /api/agent/customer/invite',
    // 获取客户编辑信息
    getClientEditInfo: '/api/agent/customer/:id',
    // 获取待创建客户在系统里重复的客户列表
    getRepeatedClientList: '/api/agent/customer/:id/repeat',
    // 将待创建客户的信息更新到选择的客户上
    mergeClientInfo: 'POST /api/agent/customer/:id/merge',
    // 发送查看客户信息 log
    reportClientInfoViewLog: 'POST /api/agent/customer/:id/addViewLog',
    // 获取客户联系信息
    getClientContactInfo: 'GET /api/agent/customer/:id/contactInfo',
    // 更新客户联系信息
    updateClientContactInfo: 'PUT /api/agent/customer/:id/contactInfo',

    // 获取客户的新文件记录
    getMaterialNewRecord: 'GET /api/agent/customer/:id/newRecord',
    // 设置客户新文件记录为已读
    markMaterialRecord: 'POST /api/agent/customer/:id/newRecord',
    // 查重校验coe/签证/护照/医疗保险
    checkMaterial: 'POST /api/agent/customer/:id/materialRepetition',

    // 创建客户
    createClient: 'POST /api/agent/customer/:id',
    // 编辑客户基本信息
    updateClientBaseInfo: 'PUT /api/agent/customer/:id',
    // 获取客户详情
    getClientDetail: 'GET /api/agent/customer/:id/info',
    // 获取客户关系
    getClientRelations: 'GET /api/agent/customer/:id/relationship',
    // 删除客户关系
    delClientRelations: 'DELETE /api/agent/customer/:clientId/relationship/:id',
    // 添加客户关系
    createClientRelations: 'POST /api/agent/customer/:id/relationship',
    // 获取可以选择的关系人
    getClientOptionsOfRelations:
      'GET /api/agent/customer/:id/relationship/select',
    // 获取关系人的信息
    getClientInfoOfRelations:
      'GET /api/agent/customer/:clientId/relationship/select/:id',

    // 获取授予了客户权限的专员
    getClientGrantedStaff: 'GET /api/agent/customer/:id/permissions',
    // 更新有客户权限的专员
    updateClientGrantedStaff:
      'PUT /api/agent/customer/:clientId/permissions/:id',

    // 设置客户信息备注
    updateClientNote: 'PUT /api/agent/customer/:id/remarks',
    // 获取可以设置客户推荐人的专员列表
    getClientRefereeList: 'GET /api/agent/customer/:id/referee',
    // 编辑客户推荐人
    updateClientReferee: 'PUT /api/agent/customer/:id/referee',

    // 获取客户下面的案件
    getCaseList: 'GET /api/agent/customer/:id/case',
    // 创建案件
    createCase: 'POST /api/agent/customer/:id/case',
    // 创建案件-获取自定义类型下的子类型
    getCustomCaseCategory: 'GET /api/agent/case/customCase/subtype',
    // 案件创建-选择员工
    getCaseStaffSelectionList: 'GET /api/agent/customer/:id/case/staff',
    // 获取所有案件
    getAllCaseList: 'GET /api/agent/case',
    // 中介-案件列表-获取筛选类型
    getCaseSubTyeOfCaseList: 'GET /api/agent/case/subType',
    // 案件筛选用的员工列表
    getStaffOfCaseFilters: 'GET /api/agent/case/filters/staff',
    // 获取案件下的账单列表
    getCaseBillList: 'GET /api/agent/case/:id/bill',
    // 获取账单语言列表
    getCaseBillLanguage: 'GET /api/agent/case/:id/bill/language',
    // 创建账单
    createCaseBill: 'POST /api/agent/case/:caseId/bill',
    // 上传付款凭证
    createPaymentVoucher: 'POST /api/agent/case/:caseId/bill/:id/proof',
    // 获取当前账单可用的付款类型
    getPaymentVoucherPayTypes: 'GET /api/agent/case/:caseId/bill/:id/payment',
    // 关闭案件
    closeCase: 'POST /api/agent/case/:id/close',
    // 发送签证案件的表单  url
    sendVisaCaseFormUrl: 'POST /api/agent/case/:id/visa/send',

    // 标记案件下所有信息已读
    markCaseMessageAsRead: 'POST /api/agent/case/:id/read',
    // 转移案件
    transformCase: 'POST /api/agent/case/:id/transfer',
    // 转移律师
    transformLawyer: 'POST /api/agent/case/:id/lawyerTransfer',
    // 获取账单步骤信息
    getCaseStep: 'GET /api/agent/case/:id/step',
    // 设置案件下一步
    operateCaseStep: 'POST /api/agent/case/:id/step/next',
    // 案件步骤里的 operator
    operateCase: 'POST /api/agent/case/:id/step/operation',
    // 完成案件
    completeCase: 'POST /api/agent/case/:id/step/complete',

    // 获取案件评论列表
    getCaseCommentList: 'GET /api/agent/case/:id/comment',
    // 发送案件评论
    postCaseComment: 'POST /api/agent/case/:id/comment',
    // 获取案件评论所有可以提交的列表
    getMentionList: 'GET /api/agent/case/:id/comment/canBeAtStaff',
    // 评论-打包下载附件
    packCommentFiles: 'GET /api/agent/case/:id/comment/:commentId/packDownload',

    // 案件是否通知
    updateCaseSilence: 'POST /api/agent/case/:id/silence',
    // 获取可以绑定的 visa 列表
    getVisaCanBindList: 'GET /api/agent/case/:id/canBeBindVisa',
    // 绑定 visa
    bindVisaInCaseStep: 'POST /api/agent/case/:id/bindVisa',
    // 评论重发邮件
    reSendEmailInComment:
      'POST /api/agent/case/:id/comment/:commentId/reSendEmail',

    // 文件柜-获取客户其他文件
    getClientOtherFiles:
      'GET /api/agent/case/:caseId/collectFile/select/otherFile',

    // 获取专员的案件评论邮箱
    getCaseCommentEmail: 'GET /api/agent/case/:id/memberExclusiveMailbox',
    // 手动生成专员的案件评论邮箱
    generateCaseCommentEmail: 'POST /api/agent/case/:id/memberExclusiveMailbox',

    getPassportList: 'GET /api/agent/customer/:id/passport',
    createPassport: 'POST /api/agent/customer/:clientId/passport',
    getPassportDetail: 'GET /api/agent/customer/:clientId/passport/:id',
    updatePassport: 'PUT /api/agent/customer/:clientId/passport/:id',

    getVisaList: 'GET /api/agent/customer/:id/visa',
    createVisa: 'POST /api/agent/customer/:clientId/visa',
    // 查询签证Vevo
    vevoQuery: 'POST /api/agent/customer/:id/visa/:visaId/vevo',
    // 获取签证Vevo查询记录列表
    getVEVOHistory: 'GET /api/agent/customer/:id/visa/:visaId/vevo',

    getVEVOParsedData: 'GET /api/agent/customer/:id/visa/:visaId/vevoParse',

    getEnglishTestList: 'GET /api/agent/customer/:id/english',
    createEnglishTest: 'POST /api/agent/customer/:id/english',

    getWorkList: 'GET /api/agent/customer/:id/work',
    createWork: 'POST /api/agent/customer/:id/work',
    createWorkAttachment:
      'POST /api/agent/customer/:clientId/work/:id/enclosure',

    getAebList: 'GET /api/agent/customer/:id/aeb',
    createAeb: 'POST /api/agent/customer/:id/aeb',
    createAebAttachment: 'POST /api/agent/customer/:clientId/aeb/:id/enclosure',

    getCoeList: 'GET /api/agent/customer/:id/coe',
    createCoe: 'POST /api/agent/customer/:clientId/coe',
    updateCoeStatus: 'PUT /api/agent/customer/:clientId/coe/:id/status',
    resetCoe: 'POST /api/agent/customer/:id/coeReset',
    createCoeAttachment: 'POST /api/agent/customer/:clientId/coe/:id/enclosure',
    packageCoE: 'POST /api/agent/customer/:id/coePack',
    resetCoEPackage: 'POST /api/agent/customer/:id/coePackReset',
    coeBindCase: 'POST /api/agent/customer/:id/coe/:coeId/bindCase',
    coeUnbindCase: 'PUT /api/agent/customer/:id/coe/:coeId/unbindCase',

    // 切换 coe 的可见性
    toggleCoEVisibility: 'POST /api/agent/customer/:id/coe/:coeId/visible',
    // 获取隐藏的 coe 列表
    getHiddenCoEList: 'GET /api/agent/customer/:id/visible/hidden',

    // 修改 coe 推荐人
    updateCoEReferrer:
      'PUT /api/agent/customer/:clientId/coe/:coeId/change-recommender',

    getOfferList: 'GET /api/agent/customer/:id/offer',
    createOffer: 'POST /api/agent/customer/:clientId/offer',

    getFileList: 'GET /api/agent/customer/:id/file',
    createFile: 'POST /api/agent/customer/:clientId/file',

    packFiles: 'GET /api/agent/customer/:id/file/pack',
    sendFiles: 'GET /api/agent/customer/:id/file/send',

    // 生成一个文件签名系统的code
    getFileSignCode: 'GET /api/agent/customer/:id/file/:fileId/signatureCode',

    getOSHCList: 'GET /api/agent/customer/:id/oshc',
    createOSHC: 'POST /api/agent/customer/:clientId/oshc',
    // 获取客户生效中的 OSHC
    getActiveOSHC: 'GET /api/agent/customer/:id/valid-oshc',

    // 获取客户邮件列表
    getEmailList: 'GET /api/agent/customer/:id/mailbox',
    // 发送邮件
    sendEmail: 'POST /api/agent/customer/:id/mailbox',
    // 获取邮件详情
    getEmailDetail: 'GET /api/agent/customer/:id/mailbox/:emailId',
    // 客户详情-为客户生成系统邮箱
    generateClientEmail: 'PUT /api/agent/customer/:id/makeSystemEmail',
    // 设置客户邮件转发至员工
    setForwardStaff: 'PUT /api/agent/customer/:id/forwardMail',
    getForwardedStaff: 'GET /api/agent/customer/:id/forwardMail',
    // 获取可转发的员工列表
    getCanForwardStaffList: 'GET /api/agent/customer/:id/forwardMail/staff',

    // 获取客户学校申请额外信息
    getClientSchoolExtraInfo: 'GET /api/agent/customer/:id/extra/school',
    // 编辑客户学校申请额外信息
    updateClientSchoolExtraInfo: 'PUT /api/agent/customer/:id/extra/school',
    // 获取客户签证申请额外信息
    getClientVisaExtraInfo: 'GET /api/agent/customer/:id/extra/visa',
    // 编辑客户签证额外信息
    updateClientVisaExtraInfo: 'PUT /api/agent/customer/:id/extra/visa',

    // 获取coe 付款
    getCoePaymentList: 'GET /api/agent/customer/:id/coePayment',
    // 切换 coe 付款 可见行
    toggleCoePaymentVisibility:
      'PUT /api/agent/customer/:clientId/coePayment/:id/display-status',
    // 创建coe 付款凭证
    createCoePaymentVoucher:
      'POST /api/agent/customer/:clientId/coePayment/:id/voucher',
    // 设置coe 付款备注
    updateCoePaymentNote:
      'PUT /api/agent/customer/:clientId/coePayment/:id/remarks',
    // 撤销coe付款记录
    revokeCoePayment:
      'PUT /api/agent/customer/:clientId/coePayment/:paymentId/voucher/:id/revoke',

    // 获取客户下的CoE付款的佣金
    getEstimatedCoeCommissions:
      'GET /api/agent/customer/:clientId/coePaymentCommission',

    // 获取coe付款审核详情
    getCoePaymentReviewDetail:
      'GET /api/agent/customer/:clientId/coePayment/:id',
    // 设置佣金比
    setCoePaymentSchoolScale:
      'PUT /api/agent/customer/:clientId/coePayment/:id',
    // 审核付款记录
    reviewCoePaymentVoucher:
      'PUT /api/agent/customer/:clientId/coePayment/:paymentId/voucher/:id/review',
    // coe付款结算
    settleCoePayment:
      'PUT /api/agent/customer/:clientId/coePayment/:paymentId/voucher/:id/settlement',

    // 迁移 coe 付款凭据
    transferCoEPayment:
      'POST /api/agent/customer/:clientId/coePayment/:paymentId/migrate',

    // 获取客户个人经历
    getPersonalTimeline: 'GET /api/agent/customer/:id/personalExperience',
    // 材料统一删除接口
    deleteMaterial: 'DELETE /api/agent/customer/:clientId/uniteDelete',
    // 获取佣金预估
    getEstimatedCaseCommission: 'GET /api/agent/case/CoECommission/estimate',

    // 获取案件抄送人
    getCCGroup: 'GET /api/agent/case/:id/ccGroup',
    // 获取案件可用抄送专员列表
    getCCGroupStaffOfSelect:
      'GET /api/agent/case/additional/ccGroup/staff/_select',
    // 更新案件抄送人
    updateCCGroup: 'PUT /api/agent/case/:id/ccGroup',

    // 获取可选择的渠道列表
    getCaseMasterAgentOptions: 'GET /api/agent/case/:id/masterAgent',
    // 设置递交的渠道
    setCaseBindingMasterAgent: 'PUT /api/agent/case/:id/masterAgent',

    // 获取所有副申请人列表
    getAccompanyMembers: 'GET /api/agent/customer/:clientId/deputy',
    // 获取可以添加为副申请人的客户列表
    searchAvailableJoinToAccompanyMembers:
      'GET /api/joint-applicant/customers/search',
    // 设置某个客户为主申请人的副申请人
    setAsAccompanyMembers:
      'POST /api/joint-applicant/customers/:clientId/as-deputy',
    // 创建副申请人填写客户表单
    createAccompanyMembersFormLink:
      'POST /api/joint-applicant/customers/:clientId/create-deputy',
    // 获取当前副申请相关案件关联的案件 (传入主申请案件 id 会获得 副申请的案件列表，传入副申请案件id 会获得与当前副申请案件关联的主申请案件)
    getAssociatedCases: 'GET /api/agent/case/:caseId/joint-case',

    // 获取主申请人对应的副申请人和案件列表
    getAccompanyMembersCases: 'GET /api/agent/customer/:id/joint-applicant',

    // 识别大类文件 (coe offer)
    identifyMaterialFileCategory:
      'POST /api/uni-material/:clientId/inference-file',
    // 识别其它文件分类
    identifyOtherFileCategory:
      'POST /api/uni-material/:clientId/inference-other-file',
    // 提交其它文件（可以携带附件）
    uploadOtherFileWitAttachment: 'POST /api/uni-material/:clientId/submission',

    // 户口本、房产证、海外教育背景、工作证明数据格式化
    formatMaterial: 'POST /api/agent/customer/:clientId/datamation',

    // 设置是否转发邮件给学生
    setWhetherAutoForwardEmail:
      'PUT /api/agent/customer/:clientId/whether-forward-email',

    // 发送客户信息更新提醒邮件
    sendClientInfoUpdateEmail:
      'POST /api/agent/customer/:clientId/send-update-remind',
  },

  agentCase: {
    // 获取案件详情
    getCaseDetail: 'GET /api/agent/case/:id',
    // 获取案件步骤信息
    getCaseStepList: 'GET /api/agent/case/:id/step',
    // 设置案件挂起状态
    updateCasePendingStatus: 'POST /api/agent/case/:id/pending-status',

    // 案件下一步
    handleNextStep: 'POST /api/agent/case/:id/step/action/next',
    // 完成案件
    completeCase: 'POST /api/agent/case/:id/step/action/done',
    // 案件步骤项操作项执行
    handleStepOperation:
      'POST /api/agent/case/:caseId/step/:stepId/operation/:id',
    // 获取分支触发步骤操作列表
    getCaseBranchOperationList: 'GET /api/agent/case/:id/operation',
    // 触发分支操作
    triggerCaseBranch:
      'POST /api/agent/case/:caseId/operation/:operationPreformId',
    // 获取步骤项日志
    getStepLogList: 'GET /api/agent/case/:id/stepItemLog',
    // 重新发送客户通知邮件
    renotifyClientInStepOperation:
      'POST /api/agent/case/:caseId/retryNotifyClient/:stepId/:id',

    // 检查用户是否需要更新用户信息
    checkClientInfoUpdate: 'GET /api/agent/customer/:clientId/updateCheck',
    // 创建客户信息更新表单 id
    generateClientInfoUpdateFormId:
      'POST /api/agent/customer/:clientId/info/updateRemind',

    // 案件详情-文件绑定步骤重复校验
    stepFileRepeatabilityCheck:
      'POST /api/agent/case/:caseId/step/:stepId/operation/:operationId/check',

    // 案件工具-识别移民局学生签证表单
    identifyOfficialStudentFormFile:
      'POST /api/agent/case/:caseId/fill-form-cache',
    // 案件工具 - 检查移民局学生签证表单是否正确
    checkOfficialStudentFormFile:
      'POST /api/agent/case/:caseId/fill-form-cache/check-customer-info',

    // 生成签证电调文件
    generateVisaCallFile:
      'POST /api/agent/case/:caseId/visa-survey/generate-pdf',
    // 将案件评论区对话生成到pdf
    caseCommentSnapshot2File:
      'POST /api/agent/case/:caseId/comment/:commentId/toPdf',
    // 生成简历
    generateResume: 'POST /api/agent/case/:caseId/generate-resume',
    // 重发评论中某个被at专员的通知
    caseCommentRenotifyStaff:
      'POST /api/agent/case/:caseId/comment/:commentId/resentNotifyEmailTo',

    // 获取案件里学校风险等级
    getCaseSchoolLevel: 'GET /api/agent/case/:caseId/school-level',

    // 追加副申请人
    appendAccompanyMembers: 'POST /api/agent/case/:caseId/append-deputy',
    // 获取案件创建的副申请人表单列表
    getAppendAccompanyMembersCreatedForms:
      'GET /api/agent/case/:caseId/deputy-forms',

    // 查看评论邮件原文
    gteOriginalEmailDetail:
      'GET /api/agent/case/:caseId/comment/:commentId/original-email',

    // 自动申请的时候，跳过发送邮件到学校
    skipAutoSubmitToSchool: 'POST /api/agent/case/:caseId/autoSubmit/skip',

    // 获取签证案件审理官统计信息
    getVisaCaseOfficerStats: 'GET /api/agent/case/:caseId/officer',
  },

  // 中介客户合同模块
  agentContract: {
    // 获取客户创建合同的基本信息
    getClientBaseInfo: 'GET /api/agent/customer/:clientId/contract/basic-info',
    // 创建合同
    createContract: 'POST /api/agent/customer/:clientId/contracts',
    // 生成预览合同的PDF
    previewContract: 'POST /api/agent/customer/:clientId/contract/preview',
    // 获取客户合同列表
    getContractList: 'GET /api/agent/customer/:clientId/contracts',
    // 让合同无效
    invalidate: 'PUT /api/agent/customer/:clientId/contracts/:id/invalidate',
  },

  agentClientTag: {
    // 获取公司可用 tags
    getCompanyAvailableTags: 'GET /api/agent/customer/label/all',
    // 删除客户标签
    deleteClientTag: 'DELETE /api/agent/customer/:clientId/label/:id',
    // 创建客户标签
    createClientTag: 'POST /api/agent/customer/:clientId/label',
    // 更新客户标签
    updateClientTag: 'PUT /api/agent/customer/:clientId/label/:id',
    // 获取当前使用的标签
    getUsingTags: 'GET /api/agent/customer/label/using',

    // 客户标签功能开关
    switchCompanyClientTag: 'PUT /api/agent/customer/label/switch',
    // 获取客户标签功能开关状态
    getCompanyClientTagOpenStatus:
      'GET /api/agent/customer/label/switch/status',
  },

  autoSubmit: {
    // 获取自动提交配置列表
    getConfigurationList: 'GET /api/autoSubmit',
    // 新增自动提交
    createConfiguration: 'POST /api/autoSubmit',
    // 修改自动提交
    updateConfiguration: 'PUT /api/autoSubmit/:id',
    getConfigurationDetail: 'GET /api/autoSubmit/:id',
    // 删除自动提交
    deleteConfiguration: 'DELETE /api/autoSubmit/:id',
    //  生成测试pdf
    generateMockPDF: 'POST /api/autoSubmit/testDataMockGeneratePdf',
    // 获取完整 schema
    getCompletedTemplateSchema:
      'GET /api/autoSubmit/testDataMockGeneratePdf/templateSchema',
    // 发送测试邮件
    sendTestEmail: 'POST /api/autoSubmit/sendTestEmail',
    // 获取发送的测试邮件状态
    getSendedEmailStatus: 'POST /api/autoSubmit/checkTestEmailStatus',

    // 获取预览信息
    getAutoSubmitPreview: 'GET /api/agent/case/:id/autoSubmit',
    // 提交到学校
    submitToSchool: 'POST /api/agent/case/:id/autoSubmit',
    // 获取模板结构
    getPDFTemplateSchema: 'GET /api/agent/case/:id/autoSubmitTemplateSchema',
    // 提交自动申请表单到学校
    oneULinkSubmitAutoSubmitForm: 'PUT /api/channel/case/:id/autoSubmitForm',
    // 获取案件自动签名的文件签名状态
    getFileSignStatus: 'GET /api/agent/case/:id/autoSubmit/signature',
    // 获取邮件发送分组情况
    getEmailGroupingInfo: 'GET /api/agent/case/:id/autoSubmit/mailGrouping',
    // 下载自动表单文件
    downloadApplicationPDF: 'GET /api/agent/case/:id/autoSubmit/download',
  },

  staffPoint: {
    // 获取积分详情列表
    getDetailList: 'GET /api/point/detailed',
    // 获取额外积分列表
    getExtraList: 'GET /api/point/additional',
    // 获取申请积分提现列表
    getWithdrawList: 'GET /api/point/withdraw',
    // 额外积分-撤销申请
    revokeExtraApply: 'PUT /api/point/additional/:id/revoke',
    // 申请积分提现
    withdraw: 'POST /api/point/withdraw',
    // 获取积分统计
    getPointStatistic: 'GET /api/point/statistic',
    // 申请额外积分
    applyExtraPoint: 'POST /api/point/additional',

    // 获取自动申请提现信息
    getAutoWithdrawConfig: 'GET /api/point/withdraw/automatic/conf',
    // 修改自动申请提现信息
    updateAutoWithdrawConfig: 'PUT /api/point/withdraw/automatic/conf',
  },

  companyPoint: {
    // 获取积分详情列表
    getDetailList: 'GET /api/company/point/detailed',
    // 获取额外积分列表
    getExtraList: 'GET /api/company/point/additional',
    // 获取申请积分提现列表
    getWithdrawList: 'GET /api/company/point/withdraw',
    // 获取积分列表中的专员列表
    getStaffListOfPoint: 'GET /api/company/point/staff',
    // 提现审核
    reviewWithdraw: 'POST /api/company/point/withdraw/:id/review',
    // 额外积分审核
    reviewApply: 'POST /api/company/point/additional/:id/review',
  },

  // oneulink 审核
  reviewPaymentInfo: {
    // 获取公司审核列表
    getCompanyRecords: 'GET /api/company/payment-method',
    // 审核公司付款信息
    reviewCompanyPaymentInfo: 'POST /api/company/payment-method/:id/review',
    // 获取员工审核列表
    getMemberRecords: 'GET /api/member/payment-method',
    // 审核员工付款信息
    reviewMemberPaymentInfo: 'POST /api/member/payment-method/:id/review',
  },

  // 公对公积分
  c2cPoint: {
    // 中介
    agent: {
      // 获取积分详情列表
      getDetailList: 'GET /api/b2b-point/detailed',
      // 获取额外积分列表
      getExtraList: 'GET /api/b2b-point/additional',
      // 获取申请积分提现列表
      getWithdrawList: 'GET /api/b2b-point/withdraw',
      // 额外积分-撤销申请
      revokeExtraApply: 'PUT /api/b2b-point/additional/:id/revoke',
      // 申请积分提现
      withdraw: 'POST /api/b2b-point/withdraw',
      // 获取积分统计
      getPointStatistic: 'GET /api/b2b-point/detailed-stat',
      // 申请额外积分
      applyExtraPoint: 'POST /api/b2b-point/additional',

      // 获取自动申请提现信息
      getAutoWithdrawConfig: 'GET /api/point/withdraw/automatic/conf',
      // 修改自动申请提现信息
      updateAutoWithdrawConfig: 'PUT /api/point/withdraw/automatic/conf',
    },

    // oneulink
    channel: {
      // 获取积分详情列表
      getDetailList: 'GET /api/b2b-point/detailed-all',
      // 获取额外积分列表
      getExtraList: 'GET /api/b2b-point/additional-all',
      // 获取申请积分提现列表
      getWithdrawList: 'GET /api/b2b-point/withdraw-all',

      // 提现审核
      reviewWithdraw: 'POST /api/b2b-point/withdraw/:id/review',
      // 额外积分审核
      reviewApply: 'POST /api/b2b-point/additional/:id/review',

      // 主动给中介结算 积分
      settlePoint: 'POST /api/b2b-point/withdraw-to',
      // 获取主动结算积分公司列表
      getSettlementAvailableCompanies:
        'GET /api/b2b-point/withdrawable-companies',

      // 获取公司筛选选项
      getCompanyFilterOptions: 'GET /api/b2b-point/sub-company',
    },
  },

  c2cCoE: {
    channel: {
      // 获取结算记录列表
      getSettlementList: 'GET /api/channel/b2b-coe-settle/records',
      // 审核结算记录
      review: 'POST /api/channel/b2b-coe-settle/review',

      // 生成公司预提现账单
      generateCompanyPreWithdrawBill: 'POST /api/agent/b2b-coe-settle/bill',
      // 打包公司预提现为zip
      downloadCompanyPreWithdrawBill:
        'POST /api/agent/b2b-coe-settle/bill-package',
    },

    agent: {
      // 获取结算记录列表
      getSettlementList: 'GET /api/agent/b2b-coe-settle/records',
      // 积分结算给员工
      settle: 'POST /api/agent/b2b-coe-settle/settle-to-staff',
      // 中介-CoE结算统计(待申请预提现,等待审核，已结算,拒绝提现的数据)
      getSettlementStats: 'GET /api/agent/b2b-coe-settle/stat',
      // 中介发起预提现
      applyPreWithdraw: 'POST /api/agent/b2b-coe-settle/apply-for-withdrawal',
      // 设置coe付款记录至隐藏状态
      hideRecords: 'POST /api/agent/b2b-coe-settle/records/:id/hidden',
    },
  },

  /**
   * 中介通过渠道获得的积分
   */
  agentOneULinkPoint: {
    // 积分明细
    getPointDetail: 'GET /api/agent/selfPoint/detailed',
    // 中介自己积分 的统计
    getStatistics: 'GET /api/agent/selfPoint/statistics',
  },

  agentOffer: {
    // 获取学校申请表单信息
    getOfferFormInfo: 'GET /api/agent/case/:id/school',
    // 编辑学校申请表单
    updateOfferForm: 'POST /api/agent/case/:id/school',
    // 获取推荐人列表
    getRefereeStaffList: 'GET /api/agent/case/:id/school/referee',
    // 查看学校申请表单详情
    getOfferCaseDetail: 'GET /api/agent/case/:id/school/view',
    // 检查客户材料是否满足表单条件
    checkMaterial: 'GET /api/agent/case/:id/school/materialCheck',
    // 提交到 oneulink 时生成 pdf
    generateSummaryPDF: 'POST /api/agent/case/:id/school/generatePdf',
    // 获取学校入学时间点
    getSchoolTeachingStartDate: 'GET /api/agent/case/:id/school/importantTime',
    // 打包下载案件学校表单的文件
    packageDownloadFiles: 'POST /api/agent/case/:id/school-form-pack-download',

    // 获取学校的额外要求
    getSchoolExtraRequirements:
      'GET /api/agent/case/:id/school/extra-requirement',

    // 搜索获取打包课程
    getPackagedCourses: 'GET /api/agent/case/:id/packaged-course',
  },

  caseTag: {
    // 获取标签配置列表
    getConfiguredTagList: 'GET /api/channel/company/stamp',
    // 创建一个新标签
    createTag: 'POST /api/channel/company/stamp',
    // 删除一个标签
    deleteTag: 'DELETE /api/channel/company/stamp/:id',
    // 修改一个标签
    updateTag: 'PUT /api/channel/company/stamp/:id',
    // 获取新增标签时可选择的案件分类和子分类
    getCaseTypeForTag: 'GET /api/channel/company/stamp/select/caseType',
  },

  message: {
    // 获取最近一周信息
    getMessagesOfWeek: 'GET /api/message/week',
    // 设置信息已读
    markAsRead: 'POST /api/message/read',
    // 设置所有信息已读
    markAllAsRead: 'POST /api/message/read/all',
    // 获取历史消息
    getHistoryMessage: 'GET /api/message/all',
    // 接收/拒绝 公司邀请
    handleInviteMessage: 'POST /api/message/:id/invite',
  },

  log: {
    // 专员日志
    getStaffLog: 'GET /api/log/staff',
    // 个人日志
    getMemberLog: 'GET /api/member/log',
    // 客户日志
    getCustomerLog: 'GET /api/agent/customer/:id/customerLog',
  },

  fileRemark: {
    getCompanyFilters: 'GET /api/channel/customer/file/mark/company',
    viewFile: 'GET /api/channel/customer/file/mark/view/:id',
    getCoEAttachmentList: 'GET /api/channel/customer/file/mark',
    markCoE: 'POST /api/channel/customer/file/mark/:id',
    markOffer: 'POST /api/channel/customer/offer/mark/:id',
    getOfferList: 'GET /api/channel/customer/offer/mark',

    // 创建数据集
    createDataSet: 'POST /api/channel/customer/file-mark/data-set',
    // 获取所有数据集
    getAllDataSets: 'GET /api/channel/customer/file-mark/data-set',
    // 获取数据集文件
    getDataSetFiles: 'GET /api/channel/customer/file-mark/data-set/:id',
    // 对数据集里的文件进行标注
    markDataSetFile:
      'POST /api/channel/customer/file-mark/data-set/:dataSetId/files/:id',
    // 获取数据集文件详情
    getDataSetFileDetail:
      'GET /api/channel/customer/file-mark/data-set/:dataSetId/files/:id',

    // 获取数据集公司基本信息
    getDataSetsMeta: 'GET /api/channel/customer/file-mark/info',

    // 学生签证
    studentVisa: {
      // 获取已标注案件
      getMarkedCases: 'GET /api/mark-system/visaCase/marked',
      // 获取表单原始数据
      getCaseRawDetail: 'GET /api/mark-system/visaCase/next/:agentCaseId',
      // 标注
      mark: 'POST /api/mark-system/visaCase/:agentCaseId/mark',
      getMarkDetail: 'GET /api/mark-system/visaCase/marked/:id',
      getOtherFiles:
        'GET /api/mark-system/visaCase/:agentCaseId/marked-other-file',
    },
  },

  channelCase: {
    // 获取代理商案件列表
    getCaseList: 'GET /api/channel/submission',
    // 获取案件有关的状态数据
    getCaseStateInfo: 'GET /api/channel/case/:id/statusField',
    // OneULink-案件列表-获取筛选类型
    getCaseSubTypeOfCaseList: 'GET /api/channel/submission/subType',
    // 代理商案件列表筛选 专员列表
    getStaffListForCase: 'GET /api/channel/staff',
    // 可绑定的MasterAgent列表
    getAgentListForClient: 'GET /api/channel/agent',
    // 设置案件挂起状态
    updateCasePendingStatus: 'POST /api/channel/case/:id/pending-status',
    // 代理商案件列表筛选 所属渠道列表
    getMasterAgentListForCase: 'GET /api/channel/case/masterAgent/_select',

    // 获取案件详情
    getCaseDetail: 'GET /api/channel/case/:id',
    // 获取案件步骤信息
    getCaseStepList: 'GET /api/channel/case/:id/step',
    // 案件下一步
    handleNextStep: 'POST /api/channel/case/:id/step/action/next',
    // 完成案件
    completeCase: 'POST /api/channel/case/:id/step/action/done',
    // 案件步骤项操作项执行
    handleStepOperation:
      'POST /api/channel/case/:caseId/step/:stepId/operation/:id',
    // 获取分支触发步骤操作列表
    getCaseBranchOperationList: 'GET /api/channel/case/:id/operation',
    // 触发分支操作
    triggerCaseBranch:
      'POST /api/channel/case/:caseId/operation/:operationPreformId',
    // 获取步骤项日志
    getStepLogList: 'GET /api/channel/case/:id/stepItemLog',

    // 代理案件文件上传接口
    uploadFileInCase: 'POST /api/channel/case/:id/upload',
    // 代理商创建账单
    createCaseBill: 'POST /api/channel/case/:caseId/bill',
    // 派单给专员
    dispatchCase: 'POST /api/channel/case/:id/dispatch',
    // 转移案件
    transformCase: 'POST /api/channel/case/:id/transferCase',
    // 转移渠道
    transformMasterAgent: 'POST /api/channel/case/:id/transfer/masterAgent',
    // 获取转移案件时可接收的员工列表
    getStaffListOfTransformCase:
      'GET /api/channel/case/:id/transferCaseSelectStaff',
    // 评论-打包下载附件
    packCommentFiles:
      'GET /api/channel/case/:id/comment/:commentId/packDownloadAttachments',
    // 获取佣金预估
    getEstimatedCaseCommission: 'GET /api/channel/case/CoECommission/estimate',

    // 获取案件抄送人
    getCCGroup: 'GET /api/channel/case/:id/ccGroup',
    // 获取案件可用抄送专员列表
    getCCGroupStaffOfSelect:
      'GET /api/channel/case/:caseId/ccGroup/staff/_select',
    // 更新案件抄送人
    updateCCGroup: 'PUT /api/channel/case/:id/ccGroup',
    // 获取案件上级渠道相关信息
    getCaseMasterAgentInfo: 'GET /api/channel/case/:id/masterAgent',
    // 重新发送邮件给专员
    renotifyDispatchEmail: 'POST /api/channel/case/:id/dispatch/sendEmail',

    // 案件详情-文件绑定步骤重复校验
    stepFileRepeatabilityCheck:
      'POST /api/channel/case/:caseId/step/:stepId/operation/:operationId/check',

    // 案件工具 - OneULink 检查移民局签证信息文件
    uploadOfficialVisaFormPdf:
      'POST /api/channel/case/:caseId/visa-document/check-info',

    // 检查签证案件是否上传过签证拒签文件
    checkVisaCaseRefusalFile:
      'GET /api/channel/case/:caseId/bindVisaRefusalFile',
  },

  // 上级渠道商相关
  channelMaster: {
    // 上级渠道 - master agent详情
    getAgentDetail: 'GET /api/channel/masterAgent/:id',
    // 上级渠道-master agent更新
    updateAgent: 'PUT /api/channel/masterAgent/:id',
    // 上级渠道-新增
    createAgent: 'POST /api/channel/masterAgent',

    // 渠道可递交的案件类型列表
    getCaseTypes: 'GET /api/channel/masterAgent/selectCaseType',
    // 获取所有上级渠道
    getMasterAgentList: 'GET /api/channel/masterAgent',

    // 设置上级渠道默认抄送人
    setMasterAgentCC: 'PUT /api/channel/masterAgent/:masterAgentId/staff/cc',
    // 设置上级渠道默认接单人
    setMasterAgentDefaultHandler:
      'PUT /api/channel/masterAgent/:masterAgentId/staff/handle',
    // 获取上级渠道默认抄送人、接单人相关信息
    getMasterAgentStaffConfig: 'GET /api/channel/masterAgent/:id/staff/define',

    // 获取批量转移记录详情
    getTransferRecorderDetail:
      'GET /api/channel/masterAgent/:masterAgentId/transferCase/:id',

    // 上级渠道-学校列表
    getAgentSchoolList: 'GET /api/channel/masterAgent/:id/school',
    // 上级渠道-添加学校
    createAgentSchool: 'POST /api/channel/masterAgent/:id/school',
    // 上级渠道-删除学校
    deleteAgentSchool: 'DELETE /api/channel/masterAgent/:id/school/:schoolId',
    // 上级渠道-获取学校基本信息
    getAgentSchoolDetail: 'GET /api/channel/masterAgent/:id/school/:schoolId',
    // 上级渠道-更新学校信息
    updateAgentSchool: 'PUT /api/channel/masterAgent/:id/school/:schoolId',

    // 获取上级渠道员工列表
    getStaffList: 'GET /api/channel/masterAgent/:id/staffs',
    // 可选择员工列表
    getManagerStaffOptions: 'GET /api/channel/masterAgent/:id/staff/select',
    // 设置负责人
    setMasterAgentRole:
      'PUT /api/channel/masterAgent/:masterAgentId/staffs/:staffId/role',
    // 添加员工
    addStaff: 'POST /api/channel/masterAgent/:id/staffs',
    // 员工离职
    staffResign: 'PUT /api/channel/masterAgent/:masterAgentId/staffQuit',
    // 获取员工离职记录
    getStaffResignRecord:
      'GET /api/channel/masterAgent/:masterAgentId/staffQuit/log',

    // 签约的渠道中介列表
    getSubAgentList: 'GET /api/channel/masterAgent/:id/subAgents',
    // 获取中介可接单员工
    getSubAgentMembersAvailableToTakeOrders:
      'GET /api/channel/masterAgent/:id/subAgents/:subAgentId/members',
    // 添加下级中介
    addSubAgent: 'POST /api/channel/masterAgent/:id/subAgents',
    // 下级中介 - 删除
    deleteSubAgent: 'DELETE /api/channel/masterAgent/:id/subAgents/:subAgentId',
    // 添加下级中介时的公司选择列表
    getCompanyOptions:
      'GET /api/channel/masterAgent/:id/subAgent/selectCompany',
    // 修改下级中介
    updateSubAgent: 'PUT /api/channel/masterAgent/:masterAgentId/subAgents/:id',

    // 获取学校素有的佣金规则
    getSchoolCommissionRuleList:
      'GET /api/channel/masterAgent/:masterAgentId/school/:schoolId/commission',
    // 创建学校佣金规则
    createSchoolCommissionRule:
      'POST /api/channel/masterAgent/:masterAgentId/school/:schoolId/commission',
    // 更新佣金规则
    updateSchoolCommissionRule:
      'PUT /api/channel/masterAgent/:masterAgentId/school/:schoolId/commission/:id',
    // 删除佣金规则
    deleteSchoolCommissionRule:
      'DELETE /api/channel/masterAgent/:masterAgentId/school/:schoolId/commission/:id',

    // 获取 masterAgent 的客户列表
    getMasterAgentClientList: 'GET /api/channel/masterAgent/:id/customer',
    // 获取 masterAgent 的案件列表
    getMasterAgentCaseList: 'GET /api/channel/masterAgent/:id/case',
    // 获取学校佣金文件列表
    getSchoolCommissionFileList:
      'GET /api/channel/masterAgent/:masterAgentId/school/:schoolId/commissionFile',
    // 更新学校佣金文件列表
    updateSchoolCommissionFile:
      'PUT /api/channel/masterAgent/:masterAgentId/school/:schoolId/commissionFile',

    // 获取递交到该渠道的 Offer
    getOfferList: 'GET /api/channel/masterAgent/:masterAgentId/offer',

    // 获取 coe 付款列表 （仅限上级渠道负责人查看）
    getCoEPayments: 'GET /api/channel/masterAgent/:masterAgentId/coe-payment',

    // 修改学校代理申请链接模板
    updateAgentApplicationTemplate:
      'PUT /api/channel/masterAgent/:masterAgentId/agent-application-template',

    certificate: {
      // 生成证书时，获取完成了的学校申请案件
      getSchoolCases:
        'GET /api/channel/MasterAgent/:masterAgentId/certificate/case',
      // 生成证书 PDF
      generatePDF:
        'POST /api/channel/MasterAgent/:masterAgentId/certificate/generate',

      // 获取生成记录
      getRecord:
        'GET /api/channel/MasterAgent/:masterAgentId/certificate/generatedRecord',
    },

    inbox: {
      // 上级渠道生成邮箱
      generateInboxEmail:
        'POST /api/channel/masterAgent/:masterAgentId/generate-email-inbox',
      // 获取到上级渠道所有邮箱
      getEmails: 'GET /api/channel/masterAgent/:masterAgentId/inbox',
      // 邮件绑定到案件
      emailBindToCase:
        'POST /api/channel/masterAgent/:masterAgentId/inbox/:emailId/inbox-bind-case',
      // 可绑定案件列表
      getBindableCases:
        'GET /api/channel/masterAgent/:masterAgentId/bindable-case',

      // 检查待绑定的邮箱是否重复
      checkBindingEmailRepeatability:
        'GET /api/channel/masterAgent/:masterAgentId/inbox/:emailId/check-bind-mail',

      // 设置邮件类型
      setEmailType:
        'POST /api/channel/masterAgent/:masterAgentId/inbox/:emailId/set-type',

      // 转移(复制)邮件到新渠道
      copyEmailToOtherMasterAgent:
        'POST /api/channel/masterAgent/:masterAgentId/inbox/:mailId/transfer-to',
      // 获取可转移的渠道列表
      getCopyMasterAgentOptions:
        'GET /api/channel/masterAgent/:masterAgentId/inbox/:mailId/transfer-to/master-agents',
    },
  },

  todo: {
    // 获取 todo 列表
    getTodoList: 'GET /api/todoTask',
    // 新增 todo
    createTodo: 'POST /api/todoTask',
    // 获取 todo 详情
    getTodoDetail: 'GET /api/todoTask/:id',
    // 可选的负责人列表
    getChargeStaffOptions: 'GET /api/todoTask/chargeStaff/_select',
    // 更新 todo
    updateTodo: 'PUT /api/todoTask/:id',
    // 删除任务中的消息任务
    deleteTodoMessageTask: 'DELETE /api/todoTask/:todoId/messageTask/:id',
    // 获取待办事项日志
    getTodoLogs: 'GET /api/todoTask/:id/log',
  },

  channelBill: {
    // 代理商账单审核
    getBillList: 'GET /api/channel/company/bill',
    // 代理商账单审核列表
    reviewBill: 'POST /api/channel/company/bill/:id/review',
  },

  channelClient: {
    // 代理商商获取客户列表
    getClientList: 'GET /api/channel/customer',
    // 代理商客户详情
    getClientDetail: 'GET /api/channel/customer/:id',

    // 根据最新案件获取渠道客户信息
    getClientLatestInfoFromAgentCase:
      'GET /api/channel/customer/:id/latest-info',
    // 代理商客户案件列表
    getCaseList: 'GET /api/channel/customer/:id/case',
    // 代理商获取 Offer 客户列表
    getOfferList: 'GET /api/channel/customer/:id/offer',

    // 获取代理商客户签证列表
    getVisaList: 'GET /api/channel/customer/:id/visa',
    // 获取代理商客户护照列表
    getPassportList: 'GET /api/channel/customer/:id/passport',

    // 获取客户工作经历
    getWorkList: 'GET /api/channel/customer/:id/work',
    // 获取客户教育经历
    getEducationList: 'GET /api/channel/customer/:id/coe',
    // 获取英语测试成绩
    getEnglishTests: 'GET /api/channel/customer/:id/english',

    // 代理商获取客户 coe 付款记录
    getCoePaymentList: 'GET /api/channel/customer/:id/coePayment',
    // 代理商客户 创建coe 付款
    createCoePaymentVoucher:
      'POST /api/channel/customer/:clientId/coePayment/:id/voucher',
    // 代理商上传 coe 付款凭证
    uploadCoePaymentVoucher:
      'POST /api/channel/customer/:clientId/coePayment/:id/upload',
    // 代理商设置客户 coe 付款备注
    updateCoePaymentNote:
      'PUT /api/channel/customer/:clientId/coePayment/:id/remarks',
    // CoE付款记录 撤销
    revokeCoePayment:
      'PUT /api/channel/customer/:clientId/coePayment/:paymentId/voucher/:id/revoke',

    // 获取coe付款审核详情
    getCoePaymentReviewDetail:
      'GET /api/channel/customer/:clientId/coePayment/:id',
    // 设置佣金比
    setCoePaymentSchoolScale:
      'PUT /api/channel/customer/:clientId/coePayment/:id',
    // 审核付款记录
    reviewCoePaymentVoucher:
      'PUT /api/channel/customer/:clientId/coePayment/:paymentId/voucher/:id/review',
    // 代理商coe付款结算
    settleCoePayment:
      'PUT /api/channel/customer/:clientId/coePayment/:paymentId/voucher/:id/settlement',

    // 获取客户关系
    getClientRelations: 'GET /api/channel/customer/:clientId/relationship',
  },

  ai: {
    /**
     * 评论文件预识别
     */
    identifyCommentFile: 'POST /api/agent/case/:id/discernmentFileByAi',
    // AI识别 付款凭证
    identifyPaymentReceipt: 'POST /api/ai/extract-entity/payment-receipt',
    // offer 文件识别
    identifyOffer: 'POST /api/ai/extract-entity/offer',
  },

  // 代理商 coe 付款模块
  channelCoePayment: {
    // 付款审核列表
    getCoePaymentList: 'GET /api/channel/coePayment',
    // 代理商审核-获取coe付款审核详情
    getCoePaymentDetail: 'GET /api/channel/coePayment/:id',
    // 代理商设置佣金比
    setCoePaymentSchoolScale: 'PUT /api/channel/coePayment/:id',
    // 审核付款记录
    reviewCoePaymentVoucher:
      'PUT /api/channel/coePayment/:paymentId/voucher/:id/review',
    // 代理商coe付款结算
    settleCoePayment:
      'PUT /api/channel/coePayment/:paymentId/voucher/:id/settlement',

    // 可选的上级渠道
    getMasterAgentList: 'GET /api/channel/coePayment-selectable-master-agent',

    // 补充学校佣金收据、付款收据文件
    supplementaryReceiptFiles:
      'PUT /api/channel/coePayment/:paymentId/voucher/:id/supplementary',

    // 转移 coe 付款
    transferCoEPayment:
      'POST /api/channel/customer/:clientId/migration-coePayment/:paymentId',
  },

  // 课程辅导模块
  courseTutoring: {
    // 新增辅导公司
    createCompany: 'POST /api/course-tutoring/company',
    // 更新辅导公司
    updateCompany: 'PUT /api/course-tutoring/company/:id',
    // 删除辅导公司
    deleteCompany: 'DELETE /api/course-tutoring/company/:id',
    // 获取辅导公司详情
    getCompanyDetail: 'GET /api/course-tutoring/company/:id',
    // 获取所有辅导公司
    getCompanys: 'GET /api/course-tutoring/company',

    // 选择课后辅导公司
    getCompanyOptions:
      'GET /api/school/:schoolCode/course-tutoring/company/select',

    // 新增辅导课程
    createCourseTutoring: 'POST /api/school/:schoolCode/course-tutoring',
    // 修改辅导课程
    updateCourseTutoring: 'PUT /api/school/:schoolCode/course-tutoring/:id',
    // 删除辅导课程
    deleteCourseTutoring: 'DELETE /api/school/:schoolCode/course-tutoring/:id',
    // 获取辅导课程详情
    getCourseTutoringDetail: 'GET /api/school/:schoolCode/course-tutoring/:id',
    // 获取学校的所有辅导课程列表
    getSchoolCourseTutoringList: 'GET /api/school/:schoolCode/course-tutoring',

    // 获取所有开通官网宣传的中介公司会专员
    getAgentAndStaffs: 'GET /api/channel/publicity-company/select',
  },

  rpl: {
    // 获取 RPL 课程认证所有的材料
    getRplMaterialList: 'GET /api/channel/school/rpl-material/_select',
    // 新增 RPL 课程认证材料
    createRplMaterial: 'POST /api/channel/school/rpl-material',
    // 获取 RPL 课程认证材料详情
    getRplMaterialDetail: 'GET /api/channel/school/rpl-material/:id',

    // 获取 RPL 认证课程所有 type
    getRplType: 'GET /api/channel/school/rpl-course/all-type',
    // 获取 RPL 认证课程所有 vetCode
    getRplVetCode: 'GET /api/channel/school/rpl-course/all-vet-code',

    // 新增 RPL 认证课程
    createRplCourse: 'POST /api/channel/school/rpl-course',
    // 修改 RPL 认证课程
    updateRplCourse: 'PUT /api/channel/school/rpl-course/:id',
    // 删除 RPL 认证课程
    deleteRplCourse: 'DELETE /api/channel/school/rpl-course/:id',
    // 获取 RPL 认证课程详情
    getRplCourseDetail: 'GET /api/channel/school/rpl-courses/:id',

    // 获取所有 RPL 认证课程列表
    getRplCourseList: 'GET /api/channel/school/rpl-course',

    // 获取所有rpl课程
    getAllRplCourses: 'GET /api/channel/school/rpl-course/_select',

    // rpl 打折
    discount: {
      // 删除折扣码
      delete: 'DELETE /api/channel/rpl/discount/:id',
      // 修改折扣
      update: 'PUT /api/channel/rpl/discount/:id',
      // 获取折扣码详情
      getDetails: 'GET /api/channel/rpl/discount/:id',
      // 创建 折扣码
      create: 'POST /api/channel/rpl/discount',
      // 获取折扣列表
      getDiscounts: 'GET /api/channel/rpl/discount',
    },
  },

  school: {
    getSchoolList: 'GET /api/school',
    getSchoolDetail: 'GET /api/school/:code',
    getCourseList: 'GET /api/school/:code/course',
    getCoeList: 'GET /api/school/:code/coe',
    getOfferList: 'GET /api/school/:code/offer',
    getCourseListOfCoe: 'GET /api/school/:code/coe/course',

    // 搜索课程
    getSearchCourseList: 'GET /api/school/search/course',

    // 获取学校护照等级数据
    getSchoolLevel: 'GET /api/school/:code/level',

    //  获取学校所有unit课程
    getSchoolUnits: 'GET /api/school/:code/unit',

    // 学校详情-设置是否支持分期付款
    switchSchoolPaymentInstallment: 'PUT /api/school/:code/installment',

    admin: {
      // 获取课程当前年份 范围
      getEffectiveYear: 'GET /common/resource/course/year-scope',
      // 修改当前年份范围
      updateEffectYear: 'PUT /common/resource/course/year-scope',
    },

    channel: {
      // 获取递交到oneulink 的 coe
      getCoeList: 'GET /api/channel/school/:code/coe',
      getAgentOptionsOfCoE: 'GET /api/channel/school/:code/coe/company/select',
      // 获取递交到oneulink 的 offer
      getOfferList: 'GET /api/channel/school/:code/offer',
      getAgentOptionsOfOffer:
        'GET /api/channel/school/:code/offer/company/select',
    },

    // 学校 offer 表单额外要求
    extraRequirement: {
      // 新增学校额外要求
      createExtraRequirement:
        'POST /api/school/:schoolCode/school-application/extra',
      // 获取学校额外要求
      getExtraRequirements:
        'GET /api/school/:schoolCode/school-application/extra',
      // 删除额外要求
      deleteExtraRequirement:
        'DELETE /api/school/:schoolCode/school-application/extra/:id',
      // 修改额外要求
      updateExtraRequirement:
        'PUT /api/school/:schoolCode/school-application/extra/:id',
    },

    // 打包课程管理
    packageCourses: {
      // 新增打包课程
      createPackageCourse:
        'POST /api/channel/school/:schoolCode/course-packaged',
      // 更新打包课程
      updatePackageCourse:
        'PUT /api/channel/school/:schoolCode/course-packaged/:id',
      // 获取打包课程列表
      getPackageCourses: 'GET /api/channel/school/:schoolCode/course-packaged',
      // 获取打包课程详情
      getPackageCourseDetail:
        'GET /api/channel/school/:schoolCode/course-packaged/:id',
      // 删除打包课程
      deletePackageCourse:
        'DELETE /api/channel/school/:schoolCode/course-packaged/:id',
    },

    // 课程别名管理
    courseAlternativeTitles: {
      // 获取所有别名
      getNames: 'GET /api/course-alias',
      // 更新别名
      updateAlternativeTitles: 'POST /api/course-alias',
    },
  },

  // 课程清单
  courseChecklist: {
    agent: {
      // 新增课程清单
      createChecklist: 'POST /api/agent/school/course-checklist',
      // 删除课程清单
      deleteChecklist: 'DELETE /api/agent/school/course-checklist/:id',
      // 获取所有清单
      getAllChecklist: 'GET /api/agent/school/course-checklist',
      // 获取课程清单详情
      getChecklistDetail: 'GET /api/agent/school/course-checklist/:id',

      // 修改课程清单
      updateChecklist: 'PUT /api/agent/school/course-checklist/:id',
    },
    oneulink: {
      // 获取所有清单
      getAllChecklist: 'GET /api/channel/school/course-checklist',
      // 获取课程清单详情
      getChecklistDetail: 'GET /api/channel/school/course-checklist/:id',
      // 审核课程中介端添加的清单
      review: 'PUT /api/channel/school/course-checklist/:id/review',
    },
  },

  /**
   *  系统权限管理
   */
  systemAuthority: {
    // 前端路由权限
    createRouterRule: 'POST /backend/router',
    deleteRouterRule: 'DELETE /backend/router/:id',
    updateRouterRule: 'PUT /backend/router/:id',
    getRouterRuleList: 'GET /backend/router',
    getRouterRuleDetail: 'GET /backend/router/:id',

    // API 权限
    createAPIRule: 'POST /backend/permissions',
    getAPIRuleList: 'GET /backend/permissions',
    updateAPIRule: 'PUT /backend/permissions/:id',
    deleteAPIRule: 'DELETE /backend/permissions/:id',
    getAPIRuleDetail: 'GET /backend/permissions/:id',

    // 角色组
    createRoleGroup: 'POST /backend/role',
    getRoleGroupDetail: 'GET /backend/role/:id',
    updateRoleGroup: 'PUT /backend/role/:id',
    getRoleGroupList: 'GET /backend/role',
    deleteRoleGroup: 'DELETE /backend/role/:id',
  },

  // 系统更新
  systemUpdate: {
    backend: {
      // 获取版本更新消息配置列表
      getSystemUpdateConfigList: 'GET /backend/versionUpdate/config',
      // 获取版本更新消息配置详情
      getSystemUpdateConfigDetail: 'GET /backend/versionUpdate/config/:id',
      // 删除更新
      deleteSystemUpdateConfig: 'DELETE /backend/versionUpdate/config/:id',
      // 修改更新
      updateSystemUpdateConfig: 'PUT /backend/versionUpdate/config/:id',
      // 新增更新
      createSystemUpdateConfig: 'POST /backend/versionUpdate/config',
      // 获取更新详情
      getSystemUpdateConfig: 'GET /backend/versionUpdate/config/:id',
      // 像前台发送更新消息
      sendUpdateNotify: 'POST /backend/versionUpdate/notice/send',

      // 版本更新消息配置缓存
      updateVersionCache: 'POST /backend/versionUpdate/config/cache',
    },

    front: {
      // 获取更新日志列表
      getReleasedLogList: 'GET /api/versionUpdate/log',
      // 获取当前最新完成的版本更新
      getLatestReleasedLog: 'GET /api/versionUpdate/new',
      // 发布日志设为已读

      // 标记为已读
      markReleaseLogAsRead: 'POST /api/versionUpdate/log/:id/read',
      // 获取日志详情
      getReleaseLogDetail: 'GET /api/versionUpdate/log/:id',

      // 获取未读数量
      getReleaseLogsNotViewNum: 'GET /api/versionUpdate/log/not-viewed-number',
    },
  },

  release: {
    // 获取当前更新状态
    getReleaseStatus: 'GET /api/versionUpdate/status',
    // 获取当前系统版本
    getCurrentVersion: 'GET /api/version/current',
  },

  offerGeneration: {
    oneulink: {
      // 更新学校 pdf 模板
      updateOfferTemplate:
        'POST /api/channel/masterAgent/:masterAgentId/preoffer/:schoolCode/template',
      // 修改下级代理权限
      updateSubAgentAccess:
        'PUT /api/channel/masterAgent/:masterAgentId/preoffer/:schoolCode/power/:subAgentId',
      // 获取下级代理权限列
      getAccessConfigList:
        'GET /api/channel/masterAgent/:masterAgentId/preoffer/:schoolCode/power',

      // OneULink 获取 生成记录
      getOfferRecorder:
        'GET /api/channel/masterAgent/:masterAgentId/preoffer/:schoolCode/generates',

      // 获取 学校模板 相关信息
      getOfferTemplateDetail:
        'GET /api/channel/masterAgent/:masterAgentId/preoffer/:schoolCode/template',

      // 获取签约渠道学校下所有的额外课程费用
      getCourseFees:
        'GET /api/channel/masterAgent/:masterAgentId/school/:schoolCode/courseExtraFees',
      // 设置课程额外费用
      updateCourseFees:
        'POST /api/channel/masterAgent/:masterAgentId/school/:schoolCode/courseExtraFees/:courseCode',

      // 获取学校费用配置列表
      getSchoolFees:
        'GET /api/channel/masterAgent/:masterAgentId/school/:schoolCode/schoolExtraFee',
      // 配置学校费用
      updateSchoolFees:
        'POST /api/channel/masterAgent/:masterAgentId/school/:schoolCode/schoolExtraFee',

      // 获取入学时间列表
      getCourseStartDates:
        'GET /api/channel/masterAgent/:masterAgentId/school/:schoolCode/intakeTimes',
      // 创建或更新课程入学时间
      createOrUpdateCourseStartDate:
        'POST /api/channel/masterAgent/:masterAgentId/school/:schoolCode/intakeTimes/:courseCode',
      // 删除课程入学时间
      deleteCourseStartDate:
        'DELETE /api/channel/masterAgent/:masterAgentId/school/:schoolCode/intakeTimes/:courseCode',

      // 删除打包课程
      deletePackagedCourse:
        'DELETE /api/channel/masterAgent/:masterAgentId/school/:schoolCode/coursePackage/:id',
      // 更新打包课程
      updatePackagedCourse:
        'PUT /api/channel/masterAgent/:masterAgentId/school/:schoolCode/coursePackage/:id',
      // 创建打包课程
      createPackagedCourse:
        'POST /api/channel/masterAgent/:masterAgentId/school/:schoolCode/coursePackage',
      // 获取课程打包列表
      getPackagedCourses:
        'GET /api/channel/masterAgent/:masterAgentId/school/:schoolCode/coursePackage',
    },

    agent: {
      // offer 生成预览
      previewOffer: 'POST /api/preoffer/generate/:schoolCode/preview',
      // 预览 offer
      generateOffer: 'POST /api/preoffer/generate/:schoolCode/toSave',
      // 获取学校开学日期
      getSchoolStartDate:
        'GET /api/preoffer/resource/school/:schoolCode/importantTime',
      // 获取配置好的学校费用
      getSchoolFees: 'GET /api/school/:schoolCode/schoolExtraFee',

      // 获取课程
      getCourses: 'GET /api/preoffer/resource/school/:schoolCode/courses',
      // 获取打包课程
      getPackagedCourses:
        'GET /api/preoffer/resource/school/:schoolCode/package',

      // 中介 获取学校生成记录 (有权限 普通专员只能看自己的)
      getOfferRecorder: 'GET /api/preoffer/generate/:schoolCode',
      // 中介 获取可生成offer的学校列表
      getOfferSchoolList: 'GET /api/preoffer/schools',
      // 获取分享 id
      getOfferShareId: 'GET /api/preoffer/share/:schoolCode',
    },

    client: {
      // 查看原始offer文件
      viewRawOffer: 'GET /api/preoffer/:id/sourceFile',
    },

    share: {
      // 获取分享页面详情
      getShareDetail: 'GET /api/preoffer-share/:id',
      // offer 生成预览
      previewOffer: 'POST /api/preoffer-share/:id/toPreview',
      // 预览 offer
      generateOffer: 'POST /api/preoffer-share/:id/toSave',
      // 获取学校开学日期
      getSchoolStartDate: 'GET /api/preoffer-share/:id/schoolImportantTime',
      // 获取配置好的学校费用
      getSchoolFees: 'GET /api/preoffer-share/:id/schoolFee',
      // 获取课程
      getCourses: 'GET /api/preoffer-share/:id/courses',
      // 获取打包课程
      getPackagedCourses: 'GET /api/preoffer-share/:id/coursePackages',
    },
  },

  analysis: {
    asqa: {
      getDates: `GET /asqa/dates`,
      compare: `GET /asqa/compare/:from/:to`,
      getTrend: `POST /asqa/trend`,
    },
    school: {
      getOpenAndClose: `GET /school/openAndClose`,
      getDates: `GET /school/dates`,
      getCourseOpenAndCloseList: `GET /school/totalCourseOpenCloseList`,

      getSchoolCourseList: `GET /school/courseList/:code`,
      getSchoolCampusList: `GET /school/campusList/:code`,
      getNumberOfLocation: `GET /school/numberOfLocation/:code`,
      getNumberOfLocationTrend: `GET /school/numberOfLocationTrend/:group`,
      getSchoolAsqa: `GET /asqa/school/:cricosCode`,

      // 获取国家的护照等级
      getCountryLevels: 'GET /school-level/country-all',
      // 获取学校对某个疼国家的护照等级
      getSchoolLevel: 'GET /school-level/:cricosCode',
    },
    agent: {
      getSummary: `GET /agency/summary`,
      getDates: `GET /agency/dates`,
      compare: `GET /agency/compare/:from/:to`,
      search: `GET /agency/search/:type`,
      detail: `GET /agency/detail/:type/:id`,
      allCompany: `GET /agency/allCompany/:qual/:date`,
      userAvatar: `GET /agency/user/:qual/avatar`,
    },
  },
  assist: {
    // 获取远程协助订单的Token
    getAssistanceToken: 'GET /api/channel/remoteAssists/:id/token',
    // oneulink--远程协作列表
    getAssistanceList: 'GET /api/channel/remoteAssists',
    // 查看当前协作的日志
    getAssistanceLogList: 'GET /api/channel/remoteAssists/:id/log',
    // oneulink-创建协助订单
    createAssistanceOrder: 'POST /api/channel/remoteAssists',
    // OneULink 可发起协助的中介公司列表
    getCompanyOptions: 'GET /api/channel/remoteAssist/company',
    // oneulink-订单验证验证码
    verifyAssistanceCode: 'POST /api/channel/remoteAssists/:id/verify',

    // 获取 oneulink 账单模板列表
    getOneULinkBillTemplates: 'GET /api/agent/case/:caseId/bill/assists-tpl',
    // 可用的切换公司列表
    getAvailableCompanyOptions: 'GET /api/channel/remoteAssist/selectable',

    // oneulink可用的远程协助专员列表
    getAssistantStaffOptions: 'GET /api/channel/remoteAssist/selectable-staff',
  },
  prPlannerCommon: {
    // 根据邮编获取位置信息
    getLocationInfoByPostcode: 'GET /prp/resource/postcode/addition/:postcode',
    // 获取学校的课程列表
    getCourseList: 'GET /prp/resource/school/:code/course',
    // 获取学校信息
    getSchoolList: 'GET /prp/resource/school',

    // 获取所有职业
    getOccupationList: 'GET /prp/overt/resource/occupation/addition/_select',
  },

  // tips 模块
  tips: {
    backend: {
      // 获取tips列表
      getTipsList: 'GET /backend/tips',
      // 获取所有已添加的分类
      getTipsCategories: 'GET /backend/tips/all-category',
      // 新增tips
      createTips: 'POST /backend/tip',
      // 删除tips
      deleteTips: 'DELETE /backend/tip/:id',
      // 获取 tips 详情
      getTipsDetail: 'GET /backend/tip/:id',
      // 修改tips
      updateTips: 'PUT /backend/tip/:id',
    },

    front: {
      // 获取3个未读的tips
      getTips: 'GET /api/tips/not-viewed',
      // 设为已读
      markRead: 'POST /api/tips/:id/set-viewed',
    },
  },
  prPlanner: {
    /**
     * 获取 条件集合列表(职业证书、职业评估)
     */
    getGroupConditionList: 'GET /prp/group-condition/:type',
    // 创建职业证书、职业评估
    createGroupCondition: 'POST /prp/group-condition/:type',
    // 更新职业评估、职业证书
    updateGroupCondition: 'PUT /prp/group-condition/:type/:id',
    // 删除职业证书、职业评估
    deleteGroupCondition: 'DELETE /prp/group-condition/:type/:id',

    // 渠道列表
    getChannelList: 'GET /prp/channel',
    // 创建新移民渠道
    createChannel: 'POST /prp/channel',
    // 删除移民渠道
    deleteChannel: 'DELETE /prp/channel/:id',
    // 更新移民渠道
    updateChannel: 'PUT /prp/channel/:id',
    // 获取移民渠道简要信息
    getChannelDetail: 'GET /prp/channel/:id',
    // 导入移民渠道的 职业列表数据
    updateChannelOccupationList: 'POST /prp/channel/:id/occupation',
    // 获取职业清单判定列表
    getOccupationList: 'GET /prp/channel/:channelId/occupation',
    // 职业清单删除
    deleteOccupationList: 'DELETE /prp/channel/:channelId/occupation/:id',
    // 职业清单导出
    exportOccupationList: 'GET prp/channel/:id/occupation/addition/_export',
    // 更新职业清单判定
    updateOccupationList: 'PUT /prp/channel/:channelId/occupation/:id',

    // 获取移民路线列表
    getImmiRouteList: 'GET /prp/channel/:channelId/immi-route',
    // 创建移民路线
    createImmiRoute: 'POST /prp/channel/:channelId/immi-route',
    // 删除移民路线
    deleteImmiRoute: 'DELETE /prp/channel/:channelId/immi-route/:id',
    // 更新移民路线
    updateImmiRoute: 'PUT /prp/channel/:channelId/immi-route/:id',

    // 获取客户基本信息列表
    getClientList: 'GET /prp/base-information',
    // 获取客户基本信息
    getClientDetail: 'GET /prp/base-information/:id',
    // 更新客户信息
    updateClient: 'PUT /prp/base-information/:id',
    // 创建客户
    createClient: 'POST /prp/base-information',
    // 生成 pdf 报告
    generatePDFReport: 'POST /prp/base-information/:id/planner-report',

    // 获取对应职业Code的渠道列表
    getMatchedChannelList: 'GET /prp/match-channels/:code',

    // 推荐课程列表
    getRecommendedCourseList: 'GET /prp/recommended-courses',
    // 删除推荐课程
    deleteRecommendedCourse: 'DELETE /prp/recommended-courses/:id',
    // 新增推荐课程
    createRecommendedCourse: 'POST /prp/recommended-courses',

    // 在当前渠道发布版本
    channelReleaseVersion: 'POST /prp/channel/:id/version',
    // 获取渠道版本列表
    getChannelVersionList: 'GET /prp/channel/:id/version',
    // 将当前渠道回滚为对应版本号内容 (当前渠道所有内容将被覆盖)
    rollbackChannelVersion: 'POST /prp/channel/:id/version/:version/rollback',

    // 获取整个渠道配置版本
    getVersionList: 'GET /prp/version-collect',
    // 发布版本
    releaseVersion: 'POST /prp/version-collect',
    // 修改版本状态为使用中
    useVersion: 'PUT /prp/version-collect/:id/in-use',
    // 获取当前最新的渠道版本列表
    getLatestChannelVersion: 'GET /prp/version-collect/version-details',

    agent: {
      // 专员创建规划
      createPlaner: 'POST /api/agent/prp',
      // 更新规划
      updatePlaner: 'PUT /api/agent/prp/:id',
      // 获取永居详情
      getPlannerDetail: 'GET /api/agent/prp/:id',
      // 打开规划
      openPlanner: 'GET /api/agent/prp/view-record',

      // 专员根据职业code 获取可申请的签证类型
      getOccupationVisaTypes:
        'GET /api/agent/prp/occupation/:occupationCode/visa-type',

      // 获取永居规划列表
      getPlanners: 'GET /api/agent/prp',

      // 删除规划
      deletePlanner: 'DELETE /api/agent/prp/:id',
    },
  },

  prClient: {
    // 客户打开编号
    openPlanner: 'POST /official/prp/info',
    // 编辑规划
    updatePlanner: 'PUT /official/prp/:id',
    // 客户根据职业code 获取可申请的签证类型
    getOccupationVisaTypes:
      'GET /official/prp/occupation/:occupationCode/visa-type',

    // 创建规划
    createPlanner: 'POST /official/prp',

    // 选择联系专员
    getStaffOptions: 'GET /official/prp/sale-member/_select',
  },

  studentForm: {
    client: {
      file: {
        acid: 'POST /api/clientForm/:formId/file/acid',
      },

      getCachedData: 'GET /api/clientForm/:formId/cache/:clientFormSubmitId',
      cacheFormData: 'PUT /api/clientForm/:formId/cache/:clientFormSubmitId',

      getSchoolList: 'GET /api/clientForm/:formId/school',
      getCourseList: 'GET /api/clientForm/:formId/school/:schoolCode/course',

      getPackagedCourses: 'GET /api/clientForm/:formId/packaged-course',

      getFormDetail: 'GET /api/clientForm/:formId/submit/:clientFormSubmitId',

      // 获取学校入学时间点
      getSchoolTeachingStartDate:
        'GET /api/clientForm/:formId/school/importantTime',

      submit: 'POST /api/clientForm/:formId/submit/:clientFormSubmitId',

      getApplicationId: 'GET /api/clientForm/:formId/submitId',

      // 获取中介选项
      getAgentOptions: `GET /api/clientForm/:formId/company/_select`,
    },
    admin: {
      // 获取渠道单一表格申请表单 Id
      getApplyFormLinkId:
        'GET /api/channel/masterAgent/:masterAgentId/school/:cricosCode/clientForm/apply',
      // 获取单一表单创建客户记录
      getClientCreationRecord:
        'GET /api/channel/masterAgent/:masterAgentId/school/:cricosCode/clientForm/customers',

      // 修改上级渠道学校代理申请链接
      updateAgentApplyUrl:
        'PUT /api/channel/masterAgent/:masterAgentId/school/:schoolId/agent-apply',
    },
  },

  admin: {
    // 获取 companys
    getCompanys: 'GET /backend/companys',
    // 获取账号列表
    getAccounts: 'GET /backend/members',
    // 修改账号信息
    updateAccount: 'PUT /backend/member/:id',
    // 创建账号
    createAccount: 'POST /backend/member',
    // 修改公司信息
    updateCompany: 'PUT /backend/company/:id',
  },

  //  学生端积分
  studentApp: {
    // 获取所有学生端账户
    getAccounts: 'GET /api/student/accounts',
    // 是否启用客户端账号的推荐功能
    configAccountInvitation: 'POST /api/student/accounts/:accountId/invitation',
    // 配置开启 "案件完成获得积分" 的案件列表 (已经注册过学生端账号的Oneulink类型的案件列表)
    getConfigurablePointBonusCases: 'GET /api/student/point-conf/cases',
    // 为案件配置 可得积分奖励
    configPointBonusCase: 'POST /api/student/point-conf/cases/:caseId',
    // 积分详情列表
    getPointDetails: 'GET /api/student/point-details',
    // 手动为某个客户增加积分
    addPointToAccount: 'POST api/student/accounts/:accountId/point',

    // 使用积分列表
    getUsePointsRecord: 'GET /api/student/use-points',
    // 审核使用积分申请
    reviewApplyOfUsePoint: 'POST /api/student/use-points/:id/review',

    // 完成积分使用
    completeUsePoint: 'POST /api/student/use-points/:id/complete',
  },

  fileCabinets: {
    admin: {
      // 创建一个新文件分类
      createFileCategory: 'POST /api/caseFileCabinet/categorys',
      // 删除一个文件分类
      deleteFileCategory: 'DELETE /api/caseFileCabinet/categorys/:id',
      // 更新文件分类
      updateFileCategory: 'PUT /api/caseFileCabinet/categorys/:id',
      // 获取文件分类列表
      getFileCategoryList: 'GET /api/caseFileCabinet/categorys',
      // 修改 文件柜分类排序
      sortCategory: 'PUT /api/caseFileCabinet/category/sort',
    },
    user: {
      // 获取文件柜保存的文件数据
      getStoreData: 'GET /api/caseFileCabinet/store/:caseId',
      // 文件柜提交预览
      preview: 'PUT /api/caseFileCabinet/store/:caseId/toPreview',
      // 文件柜提交
      save: 'PUT /api/caseFileCabinet/store/:caseId/toSave',

      // 打包下载文件
      packDownload: 'GET /api/caseFileCabinet/store/:caseId/baleFile',
      // 获取新文件记录id
      getNewFilesRecord: 'GET /api/caseFileCabinet/readHistory/:caseId/newFile',
      // 文件记录标注为已读
      markAsRead: 'POST /api/caseFileCabinet/readHistory/:caseId',

      // 文件柜打包下载签名文件
      packageSignAndDownload:
        'POST /api/caseFileCabinet/store/:caseId/baleFileWithSignature',
    },
  },

  schoolAgentApplication: {
    // 获取中介申请表单信息
    getFormInfo: 'GET /api/agent-application/:id',
    // 提交中介申请表单
    submit: 'POST /api/agent-application/:id',
  },

  coursePlan: {
    // 根据已选择的课程获取相关信息
    getSelectedCourseInfo:
      'POST /api/agent/customer/:clientId/course-plan/course/selected',
    // 生成报告
    generateReport:
      'POST /api/agent/customer/:clientId/course-plan/generateReport',
    // 预览报告
    previewReport:
      'POST /api/agent/customer/:clientId/course-plan/generateReport/preview',
    // 专员生成报告记录
    getReportRecords:
      'GET /api/agent/customer/:clientId/course-plan/reportRecords',
  },

  visaTools: {
    //  获取记录列表
    getVisaFeesRecordList: 'GET /api/visa/fee/estimate',
    // 获取预创建详情
    getVisaFeesRecordDetail: 'GET /api/visa/fee/estimate/:id',
    // 预创建记录
    preVisaFeesCreateRecord: 'GET /api/visa/fee/estimate/precreate',
    // 保存数据
    saveVisaFeesRecordData: 'PUT /api/visa/fee/estimate/:id',
    // 删除记录
    deleteVisaFeesRecord: 'DELETE /api/visa/fee/estimate/:id',

    // 获取课程费用
    getCourseFees: 'GET /api/visa/fee/estimate/courseFee',
    // 获取 OSHC 费用
    getOSHCFees: 'GET /api/visa/fee/estimate/oshcFee',
    // 获取课程开学日期
    getCourseTeachingStartDates:
      'GET /api/visa/fee/estimate/school/teachingStartDate',
    // 获取签证审理信息
    getVisaProcessingTime: 'GET /api/visa/check/case/:caseId/processing',

    // 签证案例查询 Visa Like Me
    visaLikeMe: 'POST /api/visaModule/likeMe/search',
  },

  dataConfig: {
    occupation: {
      // 删除评估职业
      deleteOccupation: 'DELETE /api/occupation/:id',
      // 获取评估职业详情
      getOccupationDetail: 'GET /api/occupation/:id',
      // 创建评估职业
      createOccupation: 'POST /api/occupation',
      // 修改评估职业
      updateOccupation: 'PUT /api/occupation/:id',
      // 获取所有评估职业
      getOccupations: 'GET /api/occupation',

      // 获取所有职业评估选项
      getSkillAssessOptions: `GET /api/occupation-assessment/options`,
    },

    // 职业评估
    skillAssess: {
      // 获取职业评估列表
      getAssessments: 'GET /api/occupation-assessments',
      // 删除职业评估
      deleteAssessment: 'DELETE /api/occupation-assessments/:id',
      // 添加职业评估
      createAssessment: 'POST /api/occupation-assessments',
      // 更新职业评估
      updateAssessment: 'PUT /api/occupation-assessments/:id',
      //  获取所有已配置评估的机构
      getOrganizationOptions: 'GET /api/occupation-assessment/organizations',
      // 搜索 conditions 和 step
      searchObject: 'POST /api/occupation-assessment/search-object',
    },

    // 邮箱黑名单
    emailBlackList: {
      // 邮件黑名单 - 所有公司的, 包含全局(被退回)黑名单
      getAllBlacklist: 'GET /api/mail-black-all',
      // 恢复白名单
      whitelist: 'DELETE /api/mail-black/:id',
    },
  },

  frontAssist: {
    // AI助手 获取分发配置
    getDistributions: 'GET /api/front-assistant/distributions',

    // 创建分发配置
    createDistribution: 'POST /api/front-assistant/distributions',
    // 获取可选专员列表
    getStaffOptions:
      'GET /api/front-assistant/distribution/selectable-employee',
    // 删除分发配置
    deleteDistribution: 'DELETE /api/front-assistant/distributions/:id',
  },
} as const;

export default api;
