import { useLayoutEffect, useRef } from 'react';

const usePersistState = <T extends any = any>(
  data: T,
): React.MutableRefObject<T> => {
  const ref = useRef<T>(data);

  useLayoutEffect(() => {
    ref.current = data;
  });

  return ref;
};

export default usePersistState;
