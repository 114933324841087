import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import type { ProColumnType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import { Space, Tag } from 'antd';
import React from 'react';
import ShortLine from '@/components/ShortLine';
import TableCell from '@/components/TableCell';
import TableField from '@/components/TableField';
import { useFormatMessage } from '@/hooks';
import { tableRender } from '@/utils/tableRender';
import MessageContent from './MessageContent';
import Recipient from './Recipient';

const MessageTaskLog: React.FC<
  Omit<React.ComponentProps<typeof ProTable>, 'columns'> & {
    formatColumns?: (
      columns: ProColumnType<AnyObject>[],
    ) => ProColumnType<AnyObject>[];
  }
> = ({ formatColumns, ...props }) => {
  const { formatMessage } = useFormatMessage();

  const statusEnum = new Map([
    [
      1,
      {
        text: formatMessage('待执行'),
        status: 'default',
        icon: <ClockCircleOutlined />,
      },
    ],
    [
      2,
      {
        text: formatMessage('进行中'),
        status: 'processing',
        icon: <SyncOutlined spin />,
      },
    ],
    [
      3,
      {
        text: formatMessage('执行成功'),
        status: 'success',
        icon: <CheckCircleOutlined />,
      },
    ],
    [
      4,
      {
        text: formatMessage('执行失败'),
        status: 'error',
        icon: <CloseCircleOutlined />,
      },
    ],
    [
      5,
      {
        text: formatMessage('暂停中'),
        status: 'warning',
      },
    ],
    [
      6,
      {
        text: formatMessage('已关闭'),
        status: 'warning',
      },
    ],
  ]);

  const childColumns: ProColumnType<AnyObject>[] = [
    {
      dataIndex: 'runningDatetime',
      key: 'expectDatetime',
      title: formatMessage('预期发送时间'),
      width: 160,
      render: tableRender((record) => (
        <TableField record={record} type="dateTime" field="expectDatetime" />
      )),
      search: false,
    },
    {
      dataIndex: 'runningDatetime',
      key: 'runningDatetime',
      title: formatMessage('完成时间'),
      width: 160,
      render: tableRender((record) => (
        <TableField record={record} type="dateTime" field="runningDatetime" />
      )),
      search: false,
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: formatMessage('状态'),
      valueEnum: statusEnum,
      width: 140,
      render: tableRender((record) => (
        <Tag
          color={statusEnum.get(record.status)?.status}
          icon={statusEnum.get(record.status)?.icon}
        >
          {statusEnum.get(record.status)?.text}
        </Tag>
      )),
    },
    {
      title: formatMessage('接收邮件'),
      dataIndex: 'notifyMode',
      key: 'notifyMode',
      width: 160,
      search: false,
      render: tableRender((record) =>
        !record.contact.email ? (
          <ShortLine />
        ) : (
          <Space size={4} direction="vertical">
            <TableCell text={record.contact.email} />
          </Space>
        ),
      ),
    },
    {
      title: formatMessage('接收人'),
      dataIndex: 'recipientType',
      key: 'recipientType',
      width: 150,
      search: false,
      render: tableRender(
        ({ recipientType, recipientName, recipientValue }) => (
          <Recipient
            name={recipientName}
            type={recipientType}
            personalName={recipientValue}
          />
        ),
      ),
    },
    {
      title: formatMessage('消息内容'),
      dataIndex: 'id',
      key: 'messageContent',
      width: 150,
      search: false,
      render: tableRender((record) => <MessageContent id={record.id} />),
    },
  ];

  return (
    // @ts-ignore
    <ProTable
      rowKey="id"
      options={false}
      rowSelection={false}
      headerTitle={formatMessage('任务日志')}
      columns={formatColumns ? formatColumns(childColumns) : childColumns}
      {...props}
    />
  );
};

export default MessageTaskLog;
