import { useCreation, usePersistFn, useRequest } from 'ahooks';
import { notification } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isObject, omit } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { ResponseSchema } from '@/api';
import useDeepMemorizedMemo from './useDeepMemorizedMemo';
import useFormatMessage from './useFormatMessage';
import usePersistState from './usePersistState';

type RequestType<T> = (...args: any[]) => Promise<ResponseSchema<T>>;

const empty = () => Promise.resolve();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultFormatSubmitParams = (
  formValues: AnyObject,
  ...rest: any
): AnyObject => ({
  ...formValues,
});

const defaultFormatFetchDetailParams = <D extends string | number>(
  id: D,
): AnyObject => ({
  id,
});

const useModalFormRequestActions = <
  D,
  T extends any = any,
  ID extends string | number = number
>(params: {
  visible: boolean;
  form: FormInstance;
  submitActions: {
    create?: RequestType<D>;
    update?: RequestType<D>;
  };
  id?: ID;
  // 请求详细数据
  fetchDetailAction?: RequestType<T>;
  // 提交前格式化参数
  formatSubmitParams?: (values: AnyObject) => AnyObject;
  // 获取数据前格式化参数
  formatFetchDetailParams?: typeof defaultFormatFetchDetailParams;
  // 获取到数据后自动设置表单值， false 关闭 数组则是将要排除的 key
  autoSetFormValueOmitKeys?: boolean | string[];
  // 格式化详情数据到表单值
  formatDetailData?: (values: T) => AnyObject;
  // 提交后成功
  onOk?: (result: ResponseSchema<D>, params?: AnyObject) => void;
  // 提交失败
  onError?: () => void;
  showSuccessTips?: boolean;
  successTips?: React.ReactNode;
  extraParams?: AnyObject;
}) => {
  const { formatMessage } = useFormatMessage();
  const {
    visible,
    id,
    form,
    submitActions: { create, update },
    fetchDetailAction,
    formatFetchDetailParams = defaultFormatFetchDetailParams,
    formatSubmitParams = defaultFormatSubmitParams,
    autoSetFormValueOmitKeys = ['id'],
    onOk,
    onError,
    formatDetailData,
    extraParams,
    successTips,
    showSuccessTips = true,
  } = params;
  const { run: submit, loading: submitting } = useRequest<ResponseSchema<D>>(
    (id ? update : create) || empty,
    {
      manual: true,
      onError,
      onSuccess: (result, requestParams) => {
        if (showSuccessTips) {
          notification.success({
            message: successTips || formatMessage('操作成功'),
          });
        }
        if (onOk) {
          onOk(result, requestParams);
        }
      },
    },
  );
  const { loading, run: fetchDetail, data: detailResp } = useRequest<
    ResponseSchema<T>
  >(fetchDetailAction || empty, {
    manual: true,
  });

  const persistedFormatFetchDetailParams = usePersistFn(
    formatFetchDetailParams,
  );
  const persistedFormatSubmitParams = usePersistFn(formatSubmitParams);
  const persistedFormatDetailData = usePersistState(formatDetailData);
  const persistedExtraParams = useDeepMemorizedMemo(() => extraParams, [
    extraParams,
  ]);

  const valueKeys = useCreation(() => autoSetFormValueOmitKeys, [
    Array.isArray(autoSetFormValueOmitKeys)
      ? autoSetFormValueOmitKeys.join('')
      : autoSetFormValueOmitKeys,
  ]);

  useEffect(() => {
    if (visible && id) {
      fetchDetail({
        ...persistedExtraParams,
        ...persistedFormatFetchDetailParams(id),
      });
    }
  }, [
    visible,
    id,
    fetchDetail,
    persistedFormatFetchDetailParams,
    persistedExtraParams,
  ]);

  const handleSubmit = useCallback(
    async (formValues: AnyObject) => {
      try {
        if (id) {
          formValues.id = id;
        }

        const values = persistedFormatSubmitParams(
          {
            ...formValues,
            ...persistedExtraParams,
          },
          id!,
        );
        const result = await submit(values);

        return result;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    [id, submit, persistedFormatSubmitParams, persistedExtraParams],
  );

  const detailData = detailResp?.data;

  useEffect(() => {
    if (detailData && id && visible) {
      let values: AnyObject | null = null;

      if (persistedFormatDetailData.current) {
        values = persistedFormatDetailData.current(detailData);
      } else if (Array.isArray(valueKeys) && isObject(detailData)) {
        values = omit(detailData, valueKeys);
      }

      if (values) {
        form.setFieldsValue(values);
      }
    }
  }, [detailData, id, visible, form, valueKeys, persistedFormatDetailData]);

  return {
    handleSubmit,
    loading,
    submitting,
    detailData,
  };
};

export default useModalFormRequestActions;
