import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum FileLanguageTag {
  ENGLISH = 'english',
  NON_ENGLISH = 'non-english',
  COLLECTION = 'collection',
}

export const useFileLanguageTagEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    return new Map([
      [
        FileLanguageTag.ENGLISH,
        {
          text: formatMessage('英文'),
          color: 'blue',
        },
      ],
      [
        FileLanguageTag.NON_ENGLISH,
        {
          text: /* @message.en-US Non-english  @message.scope file-cabinets-group */ formatMessage(
            '中文',
          ),
          color: 'orange',
        },
      ],
      [
        FileLanguageTag.COLLECTION,
        {
          text: /* @message.en-US Both  @message.scope file-cabinets-group */ formatMessage(
            '双语',
          ),
          color: 'red',
        },
      ],
    ]);
  }, [formatMessage]);
};
