// @ts-nocheck

import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import ToolOutlined from '@ant-design/icons/ToolOutlined';
import AlertOutlined from '@ant-design/icons/AlertOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import ContactsOutlined from '@ant-design/icons/ContactsOutlined';
import TagOutlined from '@ant-design/icons/TagOutlined';
import ClusterOutlined from '@ant-design/icons/ClusterOutlined';
import DollarCircleOutlined from '@ant-design/icons/DollarCircleOutlined';
import PoundCircleOutlined from '@ant-design/icons/PoundCircleOutlined';
import AccountBookOutlined from '@ant-design/icons/AccountBookOutlined';
import PayCircleOutlined from '@ant-design/icons/PayCircleOutlined';
import WalletOutlined from '@ant-design/icons/WalletOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import ScheduleOutlined from '@ant-design/icons/ScheduleOutlined';
import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined';
import CodeOutlined from '@ant-design/icons/CodeOutlined';
import SafetyOutlined from '@ant-design/icons/SafetyOutlined';
import DatabaseOutlined from '@ant-design/icons/DatabaseOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import ReadOutlined from '@ant-design/icons/ReadOutlined';
import StockOutlined from '@ant-design/icons/StockOutlined'

export default {
  DashboardOutlined,
ToolOutlined,
AlertOutlined,
SettingOutlined,
AppstoreOutlined,
TeamOutlined,
ContactsOutlined,
TagOutlined,
ClusterOutlined,
DollarCircleOutlined,
PoundCircleOutlined,
AccountBookOutlined,
PayCircleOutlined,
WalletOutlined,
DeleteOutlined,
ScheduleOutlined,
ApartmentOutlined,
CodeOutlined,
SafetyOutlined,
DatabaseOutlined,
SearchOutlined,
ReadOutlined,
StockOutlined
}
    