export const DEGREE = [
  {
    name: '研究生',
    enName: 'Master',
    value: 'master',
  },

  {
    name: '本科',
    value: 'bachelor',
    enName: 'Bachelor',
  },
  {
    name: '专科',
    value: 'diploma',
    enName: 'Diploma',
  },
  {
    name: '中小学',
    value: 'vec',
    enName: 'Year 12 and below',
  },
  {
    name: '其它',
    value: 'other',
    enName: 'Other',
  },
];
