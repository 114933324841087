import { formatMessage } from 'umi';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (..._args: any[]) => undefined;

export interface ModalFormPublicProps<D extends string | number = number> {
  id: D;
  onOk: (...args: any[]) => any;
  onCancel: (...args: any[]) => any;
  visible: boolean;
}

export const modalFormPublicDefaultProps = {
  id: 0,
  onOk: noop,
  onCancel: noop,
  visible: false,
};

export type Options = { label: string; value: string; disabled?: boolean }[];

export type FormatMessage = typeof formatMessage;

export type LiteralUnion<T extends U, U = string> = T | (U & {});
