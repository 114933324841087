import {
  IDomEditor,
  IEditorConfig,
  IToolbarConfig,
  i18nChangeLanguage,
} from '@wangeditor/editor';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import '@wangeditor/editor/dist/css/style.css';
import { useControllableValue } from 'ahooks';
import { message, notification } from 'antd';
import clsx from 'classnames';
import React, { useEffect, useState } from 'react';
import { ResponseSchema } from '@/api';
import { FileUploadTypeEnum } from '@/constant';
import { ossUploader } from '@/core';
import { useDeepMemorizedMemo, useIsEn, useMeasure } from '@/hooks';
import styles from './index.less';

type InsertFnType = (url: string, alt: string, href: string) => void;
type ToolbarKey =
  | 'bold'
  | 'underline'
  | 'italic'
  | 'through'
  | 'code'
  | 'sub'
  | 'sup'
  | 'clearStyle'
  | 'color'
  | 'bgColor'
  | 'fontSize'
  | 'fontFamily'
  | 'indent'
  | 'delIndent'
  | 'justifyLeft'
  | 'justifyRight'
  | 'justifyCenter'
  | 'justifyJustify'
  | 'lineHeight'
  | 'insertImage'
  | 'deleteImage'
  | 'editImage'
  | 'viewImageLink'
  | 'imageWidth30'
  | 'imageWidth50'
  | 'imageWidth100'
  | 'divider'
  | 'emotion'
  | 'insertLink'
  | 'editLink'
  | 'unLink'
  | 'viewLink'
  | 'codeBlock'
  | 'blockquote'
  | 'headerSelect'
  | 'header1'
  | 'header2'
  | 'header3'
  | 'header4'
  | 'header5'
  | 'todo'
  | 'redo'
  | 'undo'
  | 'fullScreen'
  | 'enter'
  | 'bulletedList'
  | 'numberedList'
  | 'insertTable'
  | 'deleteTable'
  | 'insertTableRow'
  | 'deleteTableRow'
  | 'insertTableCol'
  | 'deleteTableCol'
  | 'tableHeader'
  | 'tableFullWidth'
  | 'insertVideo'
  | 'uploadVideo'
  | 'editVideoSize'
  | 'uploadImage'
  | 'codeSelectLang';

const DefaultToolbarKeys: ToolbarKey[] = [
  'bold',
  'italic',
  'underline',
  'numberedList',
  'undo',
  'redo',
  'headerSelect',
];

interface WandEditorProps {
  value?: string;
  renderExtraToolbar?: () => React.ReactNode;
  className?: string;
  onChange?: (string?: string) => void;
  toolbarPosition?: 'top' | 'bottom';
  toolbarKeys?: ToolbarKey[];
  excludeToolbarKeys?: ToolbarKey[];
  simple?: boolean;
  maxHeight?: string | number;
  classNames?: Partial<Record<'bar' | 'content' | 'root', string>>;
  border?: boolean;
  image?: boolean;
  autoFocus?: boolean;
  footer?: React.ReactNode;
}

const WangEditor = React.forwardRef<any, WandEditorProps>(
  (
    {
      renderExtraToolbar,
      className,
      toolbarPosition,
      toolbarKeys,
      excludeToolbarKeys,
      simple,
      maxHeight,
      classNames,
      image,
      footer,
      autoFocus = true,
      border = true,
      ...props
    },
    ref,
  ) => {
    const [editor, setEditor] = useState<IDomEditor | null>(null);
    const isEn = useIsEn();
    const [value, setValue] = useControllableValue(props);
    const {
      setRef,
      data: { height },
    } = useMeasure({
      format(target) {
        return { height: target?.scrollHeight ?? 0 };
      },
    });

    useEffect(() => {
      i18nChangeLanguage(isEn ? 'en' : 'zh-CN');
    }, [isEn]);

    useEffect(() => {
      return () => {
        if (editor) {
          editor.destroy();
          setEditor(null);
        }
      };
    }, [editor]);

    const configs = useDeepMemorizedMemo(() => {
      const toolbarConfig: Partial<IToolbarConfig> = {
        excludeKeys: [
          'imageWidth30',
          'imageWidth50',
          'imageWidth100',
          'viewImageLink',
          'insertVideo',
          'uploadVideo',
          'editVideoSize',
          'insertLink',
          'editLink',
          'unLink',
          'viewLink',
          'todo',
          'codeSelectLang',
          'italic',
          'codeBlock',
          'group-video',
          ...(excludeToolbarKeys ?? []),
        ],
      };

      const editorConfig: Partial<IEditorConfig> = {
        customAlert(info, type) {
          return message[type] ? message[type](info) : message.info(info);
        },
        MENU_CONF: {},
        autoFocus,
      };

      if (!image) {
        toolbarConfig.excludeKeys!.push(
          ...'uploadImage',
          'insertImage',
          'deleteImage',
          'editImage',
          'group-image',
        );
      } else {
        editorConfig.MENU_CONF!.uploadImage = {
          async customUpload(file: File, insertFn: InsertFnType) {
            try {
              const { data } = await ossUploader.upload<
                ResponseSchema<{ fileUrl: string }>
              >(file, {
                extraData: {
                  type: FileUploadTypeEnum.RICH_TEXT,
                },
              });

              insertFn(data.fileUrl, file.name, data.fileUrl);
            } catch (err) {
              notification.error({ message: 'Upload Image failed' });
            }
          },
        };
      }

      if (toolbarKeys || simple) {
        toolbarConfig.toolbarKeys = toolbarKeys || DefaultToolbarKeys;
      }

      return {
        toolbarConfig,
        editorConfig,
      };
    }, [toolbarKeys, excludeToolbarKeys, simple, image, autoFocus]);

    console.log(value);

    return (
      <div
        className={clsx(
          styles.container,
          className,
          toolbarPosition === 'bottom' && styles.positionBottom,
          classNames?.root,
          border && styles.border,
        )}
        ref={ref}
      >
        <div ref={setRef} className={clsx(styles.barWrapper, classNames?.bar)}>
          <div className={styles.barBody}>
            {renderExtraToolbar && (
              <div className={styles.extra}>{renderExtraToolbar()}</div>
            )}
            <Toolbar
              defaultConfig={configs.toolbarConfig}
              editor={editor}
              mode="default"
              className={styles.bar}
            />
          </div>
          {!!footer && <div className={styles.barFooter}>{footer}</div>}
        </div>
        <div
          style={{
            // @ts-ignore
            '--bar-height': `${height}px`,
          }}
          className={clsx(classNames?.content, styles.wrapper)}
        >
          <Editor
            defaultConfig={configs.editorConfig}
            value={value}
            onCreated={setEditor}
            onChange={(_editor) => {
              if (_editor.isEmpty()) {
                setValue(undefined);
              } else {
                console.log(_editor?.getHtml());
                setValue(_editor.getHtml());
              }
            }}
            style={{
              maxHeight,
              overflowY: 'hidden',
            }}
            mode="default"
          />
        </div>
      </div>
    );
  },
);

export default WangEditor;
