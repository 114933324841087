import * as Sentry from '@sentry/react';
import { Button, Result, Typography } from 'antd';
import { history } from 'umi';
import { useFormatMessage } from '@/hooks';
import { nextTick } from '@/utils';

const { Paragraph, Title } = Typography;

const SentryErrorBoundary: React.FC = ({ children }) => {
  const { formatMessage } = useFormatMessage();
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError, componentStack }) => {
        return (
          <Result
            title={formatMessage('发生了一点错误')}
            status="error"
            subTitle={
              <div style={{ textAlign: 'left' }}>
                <Typography>
                  <Title level={4}>{error.message}</Title>
                  {APP_ENV !== 'production' && (
                    <Paragraph>{componentStack}</Paragraph>
                  )}
                </Typography>
              </div>
            }
            extra={
              <Button
                type="primary"
                onClick={() => {
                  resetError();
                  nextTick(() => {
                    history.replace('/');
                  }, 100);
                }}
              >
                {formatMessage('返回首页')}
              </Button>
            }
          />
        );
      }}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
