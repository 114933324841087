import { FormProps } from 'antd/lib/form';

const useFormItemDefaultLayout = () => {
  const formItemLayout: {
    labelCol: FormProps['labelCol'];
    wrapperCol: FormProps['wrapperCol'];
  } = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };

  const submitFormItemLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 17, offset: 7 },
    },
  };

  return {
    formItemLayout,
    submitFormItemLayout,
  };
};

export default useFormItemDefaultLayout;
