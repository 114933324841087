import ProForm, {
  ProFormRadio,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-form';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { services } from '@/api';
import { EmptyField } from '@/common';
import ProModalForm from '@/components/ProModalForm';
import { DATE_FORMAT, getTextareaSizeProps } from '@/config';
import { MessageTaskEventType } from '@/data';
import {
  useCommonRules,
  useFormatMessage,
  useModalFormRequestActions,
} from '@/hooks';
import {
  MessageTaskFormValues,
  MessageTaskPriorityType,
  StaffSelect,
  TriggerDateType,
  formatMessageTaskFormGrouping,
  formatTaskFormValues,
} from '../MessageTask';
import RelativeDate from './RelativeDate';

export type NormalMessageTaskFormProps = {
  onSubmit?: (...args: any) => Promise<any>;
  initialValues?: Partial<
    Pick<MessageTaskFormValues, 'title' | 'content' | 'priority'> & {
      staffList?: (string | number)[];
    }
  >;
  trigger: React.ReactElement;
  onOk?: () => void;
  hiddenItems?: boolean;
  deadline: Moment | string;
};

const TRIGGER_EVENT = MessageTaskEventType.NORMAL;

export const useNormalMessageTaskUtils = () => {
  const scope = 'todo';

  const getFormValues = (
    dates: Moment[],
    memberId: number,
    values: Pick<MessageTaskFormValues, 'content' | 'title' | 'priority'>,
  ) => {
    return formatTaskFormValues(
      {
        ...values,
        staffList: [memberId],
        triggerDate: dates.map((date) => ({
          type: TriggerDateType.NORMAL,
          date,
        })),
        notifyMode: ['email'],
        triggerEvent: MessageTaskEventType.NORMAL,
      },
      undefined,
      scope,
    );
  };

  return {
    getCurrentGrouping: () => formatMessageTaskFormGrouping(scope),
    getFormValues,
  };
};

const NormalMessageTaskForm: React.FC<NormalMessageTaskFormProps> = ({
  onSubmit,
  initialValues,
  onOk,
  trigger,
  deadline,
  hiddenItems = true,
}) => {
  const [form] = ProForm.useForm();
  const { formatMessage } = useFormatMessage();
  const [visible, setVisible] = useState(false);
  const scope = 'todo';

  const { selectRules, textLengthRule, inputRules } = useCommonRules();
  const { getCurrentGrouping } = useNormalMessageTaskUtils();

  const { handleSubmit } = useModalFormRequestActions({
    visible,
    form,
    submitActions: {
      create: onSubmit || services.messageTask.createTask,
    },
    formatSubmitParams: ({ triggerDate, ...params }) => {
      return triggerDate.map((date: string) =>
        formatTaskFormValues(
          {
            ...params,
            grouping: getCurrentGrouping(),
            triggerDate: [
              {
                date: moment(date),
                type: TriggerDateType.NORMAL,
              },
            ],
          },
          undefined,
          scope,
        ),
      );
    },
    extraParams: {
      notifyMode: ['email'],
    },
    onOk,
  });

  useEffect(() => {
    if (visible && initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, visible, scope]);
  const now = moment();

  const priorityOptions = [
    {
      label: formatMessage('重要'),
      value: MessageTaskPriorityType.IMPORTANT,
    },
    {
      label: formatMessage('一般'),
      value: MessageTaskPriorityType.NORMAL,
    },
    {
      label: formatMessage('不重要'),
      value: MessageTaskPriorityType.UNIMPORTANT,
    },
  ];

  const renderFormItem = (
    key: 'title' | 'priority' | 'content' | 'staffList',
    content: React.ReactNode,
  ) => (
    <div
      style={{
        display: initialValues?.[key] && hiddenItems ? 'none' : '',
        marginBottom: 24,
      }}
    >
      {content}
    </div>
  );

  return (
    <ProModalForm
      title={formatMessage('创建任务')}
      onFinish={handleSubmit}
      onCancel={() => setVisible(false)}
      trigger={trigger}
      visible={visible}
      form={form}
      onVisibleChange={setVisible}
    >
      <EmptyField name="triggerEvent" initialValue={TRIGGER_EVENT} />
      {renderFormItem(
        'title',
        <ProFormText
          name="title"
          rules={[...inputRules, textLengthRule(2, 200)]}
          fieldProps={{
            minLength: 2,
            maxLength: 200,
          }}
          label={formatMessage('消息标题')}
        />,
      )}
      {renderFormItem(
        'priority',
        <ProFormRadio.Group
          options={priorityOptions}
          rules={selectRules}
          name="priority"
          label={formatMessage('优先级')}
        />,
      )}
      {renderFormItem(
        'content',
        <ProFormTextArea
          rules={[...inputRules, textLengthRule(2, 250)]}
          name="content"
          label={formatMessage('消息内容')}
          fieldProps={getTextareaSizeProps({ min: 2, max: 250 })}
        />,
      )}

      {renderFormItem(
        'staffList',
        <StaffSelect
          name="staffList"
          label={formatMessage('参与专员')}
          fieldProps={{
            mode: 'multiple',
          }}
        />,
      )}

      {deadline && (
        <ProForm.Item
          name="triggerDate"
          label={formatMessage('设置时间')}
          rules={selectRules}
          dataFormat={`${DATE_FORMAT} HH:mm:ss`}
        >
          <RelativeDate now={now} targetDate={deadline} />
        </ProForm.Item>
      )}
    </ProModalForm>
  );
};

export default NormalMessageTaskForm;
