export enum CaseBuiltinFormType {
  SCHOOL_FORM = 'SchoolForm',
  VISA_FORM = 'VisaForm',
  GTE_FORM = 'GTEForm',
  VISA_956FORM = 'Visa956Form',
  VISA_FILE = 'VisaFile',
  OFFER_FILE = 'OfferFile',
  COE_FILE = 'CoEFile',
  OSHC_FILE = 'OSHCFile',
  CUSTOM_FILE = 'CustomFile',
  CUSTOM_FORM = 'CustomForm',
  SCHOOL_AUTO_SUBMIT_FORM = 'SchoolAutoForm',
  ONEULINK_AUTO_SUBMIT_FORM = 'OneULinkAutoSubmitForm',
  REPLACE_AUTO_SUBMIT_FORM_PDF_FILE = 'ReplaceAutoSubmitFormPdfFile',
}

export enum CaseStepExtraActionType {
  FILL_COLLECT = 'FileCollect',
  DOWNLOAD_AUTO_SUBMIT_PDF = 'DownloadAutoSubmitPDF',
}

export enum CaseStepState {
  /**
   * 步骤可用的
   */
  ACTIVE = 'active',

  /**
   * 完成的步骤项
   */
  COMPLETED = 'completed',
  /**
   * 步骤不可用,执行时应当跳过当前步骤
   */
  UNAVAILABLE = 'unavailable',
  // 步骤部分完成
  PARTIAL_COMPLETED = 'part-completed',
  HIDE = 'hide',
}

export enum CaseStepConditionType {
  VISA_REFUSAL = 'VisaRefusal',
  DEFERRED_ENROLLMENT = 'Postpone',
}

export enum CaseStepTriggerFun {
  FREE_STEP = 'triggerFreeStep',
  SUBMIT_TO_ONEULINK = 'submitACase',
}

export enum CaseStepActionType {
  CUSTOM_FORM = 'custom-form',
  FILE = 'file',
  OTHER = 'other',
  BUILTIN_FORM = 'builtin-form',
  REPLACE_AUTO_SUBMIT_FORM_PDF_FILE = 'ReplaceAutoSubmitFormPdfFile',
}

export const AIFileTags = [
  { value: 'coe', enLabel: 'CoE File', cnLabel: 'CoE 文件', tag: 'coe' },
  {
    enLabel: 'Visa File',
    cnLabel: '签证文件',
    value: 'visa',
    tag: 'aus visa grant letter',
  },
  {
    enLabel: 'Offer File',
    value: 'offer',
    cnLabel: 'Offer 文件',
    tag: 'aus offer',
  },
  { enLabel: 'OSHC File', value: 'oshc', cnLabel: '医疗保险', tag: 'oshc' },
  {
    enLabel: 'OVHC File',
    value: 'ovhc',
    cnLabel: '工作签证医疗保险',
    tag: 'ovhc',
  },
  {
    enLabel: 'Passport File',
    value: 'passport',
    cnLabel: '护照文件',
    tag: 'passport',
  },
  {
    enLabel: 'English Test',
    value: 'englishTest',
    cnLabel: '英语测试',
    tag: 'english test',
  },
  {
    enLabel: 'Educational Background',
    value: 'educationalBackground',
    cnLabel: '海外教育背景',
    tag: 'educational background',
  },
  {
    enLabel: 'AUS Transcript and Certificate',
    value: 'ausTranscriptAndCertificate',
    cnLabel: '澳洲学校的成绩单和毕业证',
    tag: 'aus transcript and certificate',
  },
  {
    enLabel: 'AUS Transcript',
    value: 'ausTranscript',
    cnLabel: '澳洲学校的成绩单',
    tag: 'aus transcript',
  },
  {
    enLabel: 'AUS Certificate',
    value: 'ausCertificate',
    cnLabel: '澳洲学校的毕业证',
    tag: 'aus certificate',
  },
  {
    enLabel: 'AUS Enrollment Letter',
    value: 'ausEnrollmentLetter',
    cnLabel: '澳洲学校的在读证明',
    tag: 'aus enrollment letter',
  },
  {
    enLabel: 'AUS Visa Refused Letter',
    value: 'ausVisaRefusedLetter',
    cnLabel: '澳洲签证拒签信',
    tag: 'aus visa refused letter',
  },
  {
    enLabel: 'AUS Release Letter',
    value: 'ausReleaseLetter',
    cnLabel: '澳洲学校的释放信',
    tag: 'aus release letter',
  },
  {
    enLabel: 'Visa Payment Receipt',
    cnLabel: '签证付款证明',
    value: 'visaPaymentReceipt',
    tag: 'visa payment receipt',
  },
  {
    enLabel: 'Immi Acknowledgement of Application Received',
    cnLabel: '签证提交回执',
    value: 'immiAcknowledgementOfApplicationReceived',
    tag: 'immi acknowledgement of application received',
  },
  {
    enLabel: 'Visa Request Checklist and Details',
    cnLabel: '签证要求的补料信',
    value: 'visaRequestChecklistAndDetails',
    tag: 'visa request checklist and details',
  },
  { enLabel: 'GTE', cnLabel: '澳洲签证GTE表格', value: 'gte', tag: 'gte' },
  {
    enLabel: 'Hap ID Letter',
    cnLabel: '澳洲体检信',
    value: 'hapIdLetter',
    tag: 'hap id letter',
  },
  {
    enLabel: 'School Acknowledgement Letter',
    cnLabel: '澳洲学校申请确认信',
    value: 'schoolAcknowledgementLetter',
    tag: 'school acknowledgement letter',
  },
  {
    enLabel: 'School Payment Receipt',
    cnLabel: '澳洲学校收据',
    value: 'paymentCertificate',
    tag: 'school payment receipt',
  },
  {
    enLabel: 'School SOP',
    cnLabel: '澳洲学校解释信',
    value: 'schoolSop',
    tag: 'school sop',
  },
  {
    enLabel: 'AUS Bridging Visa',
    cnLabel: '过桥签证批签信(过桥签证)',
    value: 'ausVisaGrantLetterBridgingVisa',
    tag: 'aus visa grant letter bridging visa',
  },
  {
    enLabel: 'Visa Student Form',
    cnLabel: '签证学生资料收集表',
    value: 'visaStudentForm',
    tag: 'visa student form',
  },
  {
    enLabel: 'Health Examation Booking Info',
    cnLabel: '体检预约证明',
    value: 'ausHealthExamationBookingInfo',
    tag: 'aus health examation booking info',
  },
  {
    enLabel: 'School Student Form',
    cnLabel: '学校申请表单',
    value: 'schoolStudentForm',
    tag: 'school student form',
  },
  {
    enLabel: 'Modified School Application Form PDF',
    cnLabel: '修改好的学校申请表 PDF',
    value: CaseBuiltinFormType.REPLACE_AUTO_SUBMIT_FORM_PDF_FILE,
    tag: CaseBuiltinFormType.REPLACE_AUTO_SUBMIT_FORM_PDF_FILE,
  },
];

export const getAIFileOptions = (locale: string) => {
  const isEn = locale === 'en-US';

  return AIFileTags.map((item) => ({
    ...item,
    label: isEn ? item.enLabel : item.cnLabel,
  }));
};
