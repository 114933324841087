import nationalities from '@/assets/nationality.json';

const allNationality = nationalities.map((item) => ({
  label: item,
  value: item,
}));

export const getAllNationality = (excludeAustralia?: boolean) =>
  excludeAustralia
    ? allNationality.filter((item) => item.value !== AUSTRALIA_NAME)
    : allNationality;

export const AUSTRALIA_NAME = 'AUSTRALIA - AUS';
