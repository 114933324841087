import { CaretDownFilled } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Button, Dropdown, Menu, Space } from 'antd';
import React, { memo } from 'react';
import { useFormatMessage } from '@/hooks';
import { EmailForwardIcon, EmailReplyIcon } from './icons';

const EmailActionType: React.FC<{
  value?: string;
  onChange?: (current: string) => void;
}> = (props) => {
  const [value, setValue] = useControllableValue<string>(props);
  const { formatMessage } = useFormatMessage();

  const options = [
    {
      key: 'forward',
      label: formatMessage('转发'),
      icon: EmailForwardIcon,
    },
    {
      key: 'reply',
      label: formatMessage('回复'),
      icon: EmailReplyIcon,
    },
    {
      key: 'editSubject',
      label: formatMessage('编辑主题'),
      icon: null,
    },
  ];

  const selectIcon = options.find((item) => item.key === value)?.icon;

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu
          activeKey={value}
          onClick={(evt) => {
            setValue(evt.key);
          }}
        >
          {options.map((item) => (
            <Menu.Item key={item.key}>
              <Space size={4}>
                <div
                  style={{
                    width: 32,
                  }}
                >
                  {item.icon &&
                    React.createElement(item.icon, {
                      style: {
                        fontSize: 20,
                        color: 'rgba(0, 0, 0, 0.55)',
                      },
                    })}
                </div>
                {item.label}
              </Space>
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button block type="text">
        <Space size={4} align="center">
          {selectIcon &&
            React.createElement(selectIcon, {
              style: {
                fontSize: 24,
                color: 'rgba(0, 0, 0, 0.55)',
              },
            })}
          <CaretDownFilled
            style={{
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)',
            }}
          />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default memo(EmailActionType);
