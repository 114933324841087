import {
  BellOutlined,
  BookOutlined,
  CarryOutOutlined,
  MenuOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Col, ColProps, Row } from 'antd';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import { Link } from 'umi';
import { ResponseSchema, services } from '@/api';
import { CustomAvatar } from '@/common';
import {
  UsedWebsocketChannel,
  UsedWebsocketEvent,
  useWebsocketSubscribe,
} from '@/components/Websocket';
import { MessageTaskEventType, TodoPriority } from '@/data';
import { useFormatMessage, useIsChannel } from '@/hooks';
import { formatDate } from '@/utils';
import {
  MessageTaskFormInitialValues,
  MessageTaskPriorityType,
} from '../MessageTask';
import NormalMessageTaskForm from './NormalMessageTaskForm';
import TodoActivity from './TodoActivity';
import TodoChargeStaffSelect from './TodoChargeStaffSelect';
import TodoDeadlineDate from './TodoDeadlineDate';
import styles from './TodoDetail.less';
import {
  TodoDetailBadge,
  TodoDetailBadgePopup,
  TodoPrioritySelect,
} from './TodoDetailBadge';
import TodoDetailEditItem, {
  TodoDetailEditItemProvider,
} from './TodoDetailEditItem';
import TodoInputEdit from './TodoInputEdit';
import TodoMessageTaskCard from './TodoMessageTaskCard';
import TodoStatusDropdown from './TodoStatusDropdown';
import { TodoActivityData, TodoDetailData, TodoTaskScope } from './type';
import { TodoStatusSelectedResult } from './useTodoStatusStateMachine';
import useTodoStatusTagConfigs from './useTodoStatusTagConfigs';

const TodoDetail: React.FC<{
  id: number;
  onRefresh?: () => void;
  scope?: TodoTaskScope;
  scopeId?: number;
}> = ({ id, scope, scopeId }) => {
  const { data: detailResp, refresh } = useRequest<
    ResponseSchema<TodoDetailData>
  >(() => services.todo.getTodoDetail({ id }), {
    refreshDeps: [id],
  });
  const tagConfigs = useTodoStatusTagConfigs();
  const { formatMessage } = useFormatMessage();

  const { run: updateTasks } = useRequest(services.todo.updateTodo, {
    manual: true,
    onSuccess: refresh,
  });
  const { data: activityResp, refresh: refreshActivity } = useRequest<
    ResponseSchema<TodoActivityData[]>
  >(() => services.todo.getTodoLogs({ id }), {
    refreshDeps: [id],
  });
  const { data: staffResp } = useRequest<
    ResponseSchema<
      {
        familyName: string;
        givenName: string;
        id: number;
        nickname: string;
      }[]
    >
  >(services.messageTask.getStaffList, {
    cacheKey: 'TASK_FORM_STAFF_SELECT',
  });

  const isChannel = useIsChannel();

  useWebsocketSubscribe<{ todoTaskId: number }>(
    UsedWebsocketEvent.TODO_TASK_UPDATED,
    ({ todoTaskId }) => {
      if (todoTaskId === id) {
        refresh();
        refreshActivity();
      }
    },
    {
      channel:
        scope === TodoTaskScope.CASE
          ? UsedWebsocketChannel.CASE
          : scope === TodoTaskScope.CLIENT
          ? UsedWebsocketChannel.CLIENT
          : UsedWebsocketChannel.COMPANY,
      scope: scopeId,
    },
  );

  const detail = detailResp?.data;
  const targetConfig = detail && tagConfigs[detail.state];

  const colProps: ColProps = {
    md: 12,
    lg: 6,
    sm: 12,
    xs: 24,
  };

  const handleSubmit = async (values: AnyObject[]) => {
    await updateTasks({
      messageTask: [
        ...detail!.messageTaskList.map((item) => ({ id: item.id })),
        ...values,
      ],
      id,
    });
  };

  const initialValues: MessageTaskFormInitialValues = useMemo(
    () => ({
      triggerEvent: MessageTaskEventType.NORMAL,
      title: detail?.title,
      content: detail?.content || detail?.title,
      priority:
        detail?.priorityLevel === TodoPriority.URGENCY
          ? MessageTaskPriorityType.IMPORTANT
          : MessageTaskPriorityType.NORMAL,
      staffList:
        detail?.chargeChmMemberId &&
        staffResp?.data.find((item) => item.id === detail.chargeChmMemberId)
          ? [detail.chargeChmMemberId]
          : undefined,
    }),
    [detail, staffResp],
  );

  const memorizedDeadline = useMemo(
    () => (detail?.deadline && moment(detail.deadline)) || undefined,
    [detail?.deadline],
  );

  return (
    <TodoDetailEditItemProvider id={id}>
      <section className={styles.container}>
        <div className={styles.left}>
          <header className={styles.header}>
            <TodoDetailEditItem field="title">
              {({ onChange }) => (
                <TodoInputEdit
                  content={detail?.title}
                  className={styles.title}
                  onConfirm={onChange}
                  required
                />
              )}
            </TodoDetailEditItem>
            <Row gutter={16}>
              <Col {...colProps}>
                <TodoDetailEditItem<TodoStatusSelectedResult>
                  field="state"
                  transform={(params) => ({
                    state: params?.status,
                    deadline: params?.deadline,
                  })}
                >
                  {({ onChange }) => (
                    <TodoStatusDropdown
                      value={detail?.state}
                      onChange={onChange}
                    >
                      <TodoDetailBadge
                        icon={
                          targetConfig?.icon &&
                          React.createElement(targetConfig.icon, {
                            style: { color: targetConfig.color },
                          })
                        }
                        title={targetConfig?.text}
                        subTitle={formatMessage('当前状态')}
                      />
                    </TodoStatusDropdown>
                  )}
                </TodoDetailEditItem>
              </Col>
              <Col {...colProps}>
                <TodoDetailEditItem field="chargeChmId">
                  {({ onChange }) => (
                    <TodoDetailBadgePopup
                      content={({ onConfirm }) => (
                        <TodoChargeStaffSelect
                          onChange={(next) => {
                            onChange(next);
                            onConfirm();
                          }}
                          value={detail?.chargeChmId}
                          popup={false}
                        />
                      )}
                      density
                    >
                      <TodoDetailBadge
                        icon={
                          <CustomAvatar
                            src={detail?.chargeChmAvatar}
                            alt={detail?.chargeChmNickname}
                          />
                        }
                        title={detail?.chargeChmNickname}
                        subTitle={formatMessage('负责人')}
                      />
                    </TodoDetailBadgePopup>
                  )}
                </TodoDetailEditItem>
              </Col>
              <Col {...colProps}>
                <TodoDetailEditItem<Moment>
                  field="deadline"
                  transform={(val) => ({
                    deadline: val?.toISOString(),
                  })}
                >
                  {({ onChange }) => (
                    <TodoDetailBadgePopup
                      content={({ onConfirm }) => (
                        <TodoDeadlineDate
                          value={memorizedDeadline}
                          onChange={(next) => {
                            onChange(next);
                            onConfirm();
                          }}
                        />
                      )}
                    >
                      <TodoDetailBadge
                        icon={<CarryOutOutlined className={styles.icon} />}
                        title={
                          detail?.deadline && formatDate(detail?.deadline, true)
                        }
                        subTitle={formatMessage('截止时间')}
                      />
                    </TodoDetailBadgePopup>
                  )}
                </TodoDetailEditItem>
              </Col>
              <Col {...colProps}>
                <TodoDetailEditItem field="priorityLevel">
                  {({ onChange }) => (
                    <TodoPrioritySelect
                      value={detail?.priorityLevel}
                      onChange={onChange}
                    />
                  )}
                </TodoDetailEditItem>
              </Col>
            </Row>
          </header>
          <main className={styles.body}>
            {detail?.customerName && (
              <div>
                <div className={styles.section}>
                  <div className={styles.cell}>
                    <UserOutlined />
                    <h3>{formatMessage('关联客户')}</h3>
                  </div>
                </div>
                <Link
                  to={
                    isChannel
                      ? `/channel-client/detail/${detail.customerId}`
                      : `/agent-client/detail/${detail.customerId}`
                  }
                >
                  {detail.customerName}
                </Link>
              </div>
            )}
            {detail?.caseName && (
              <div>
                <div className={styles.section}>
                  <div className={styles.cell}>
                    <BookOutlined />
                    <h3>{formatMessage('关联案件')}</h3>
                  </div>
                </div>
                <Link
                  to={
                    isChannel
                      ? `/channel-case/detail/${detail.caseId}`
                      : `/agent-case/detail/${detail.caseId}`
                  }
                >
                  {detail.caseName}
                </Link>
              </div>
            )}
            <div className={styles.detail}>
              <div className={styles.section}>
                <div className={styles.cell}>
                  <MenuOutlined />
                  <h3>{formatMessage('详情')}</h3>
                </div>
              </div>
              <TodoDetailEditItem field="content">
                {({ onChange }) => (
                  <TodoInputEdit
                    content={detail?.content}
                    className={styles.content}
                    onConfirm={onChange}
                    emptyText={formatMessage('编辑详情')}
                    wrap
                  />
                )}
              </TodoDetailEditItem>
            </div>

            <div className={styles.message}>
              <div className={styles.section}>
                <div className={styles.cell}>
                  <BellOutlined />
                  <h3>{formatMessage('定时消息通知')}</h3>
                </div>
                {memorizedDeadline && (
                  <NormalMessageTaskForm
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    deadline={memorizedDeadline}
                    trigger={
                      <Button type="dashed" icon={<PlusOutlined />}>
                        {formatMessage('添加消息任务')}
                      </Button>
                    }
                  />
                )}
              </div>
              <div className={styles.taskList}>
                {detail?.messageTaskList.map((item) => (
                  <div key={item.id} className={styles.taskItem}>
                    <TodoMessageTaskCard
                      onRefresh={refresh}
                      data={item}
                      todoId={id}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.date}>
              <div className={styles.dateDesc}>
                <CustomAvatar
                  size="small"
                  src={detail?.chmAvatar}
                  alt={detail?.chmNickname}
                />
                <span style={{ marginLeft: 4 }}>{detail?.chmNickname} </span>
                <time>
                  {formatMessage('创建于 {time}', {
                    time: formatDate(detail?.createdAt || '', true),
                  })}
                </time>
              </div>
              <div className={styles.dateDesc}>
                <span className={styles.split} />
                <CustomAvatar
                  size="small"
                  src={detail?.changeChmAvatar}
                  alt={detail?.changeChmNickname}
                />
                <span style={{ marginLeft: 4 }}>
                  {detail?.changeChmNickname}{' '}
                </span>
                <time>
                  {formatMessage('更新于 {time}', {
                    time: formatDate(detail?.createdAt || '', true),
                  })}
                </time>
              </div>
            </div>
          </main>
        </div>
        <div className={styles.right}>
          <TodoActivity data={activityResp?.data} />
        </div>
      </section>
    </TodoDetailEditItemProvider>
  );
};

export default TodoDetail;
