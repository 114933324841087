import { UseRequestProvider } from 'ahooks';
import moment from 'moment';
import React, { useEffect } from 'react';
import { AliveScope } from 'react-activation';
import { useIntl, useModel } from 'umi';
import { FunctionGuideProvider } from '@/components/FunctionGuide';
import { ModalManagerProvider } from '@/components/ModalManager';
import { UseWebsocketContainer } from '@/components/Websocket';
import { SentryApp } from '@/sentry';
import { AppGlobalStateProvider } from './AppGlobalState';
import CommonWebsocket from './CommonWebsocket';
import InnerLayout from './InnerLayout';
import QueryLayout from './QueryLayout';
import SystemVersionPrompt from './SystemVersionPrompt';
import { VersionUpdateNotifyProvider } from './VersionUpdateNotify';

const AppContainer = ({
  children,
  routes,
}: {
  children: React.ReactElement;
  routes: AnyObject[];
}) => {
  const { locale } = useIntl();
  const { initialState } = useModel('@@initialState');

  useEffect(() => {
    moment.locale('en-au');
  }, [locale]);

  return (
    <SentryApp>
      <UseWebsocketContainer>
        <UseRequestProvider
          value={{
            throwOnError: true,
          }}
        >
          <QueryLayout>
            <AppGlobalStateProvider>
              <ModalManagerProvider>
                <VersionUpdateNotifyProvider>
                  {initialState?.token && <InnerLayout />}
                  <SystemVersionPrompt />
                  <CommonWebsocket />
                  <AliveScope>
                    <FunctionGuideProvider>
                      {React.cloneElement(children, {
                        routes,
                      })}
                    </FunctionGuideProvider>
                  </AliveScope>
                </VersionUpdateNotifyProvider>
              </ModalManagerProvider>
            </AppGlobalStateProvider>
          </QueryLayout>
        </UseRequestProvider>
      </UseWebsocketContainer>
    </SentryApp>
  );
};

export default AppContainer;
