import { isBoolean } from 'lodash';
import React from 'react';
import { Access, AccessProps, useAccess } from 'umi';

const IAccessable: React.FC<
  {
    authority: undefined | string | string[] | boolean;
    fallback?: React.ReactNode;
  } & Omit<AccessProps, 'accessible'>
> = ({ authority, fallback, children, ...rest }) => {
  const { apiAccessFilter } = useAccess();
  const authorityList = Array.isArray(authority) ? authority : [authority];

  const Child = children;

  return (
    <Access
      fallback={fallback}
      accessible={authorityList.every((item) =>
        isBoolean(item) || item === undefined ? item : apiAccessFilter(item),
      )}
    >
      {React.isValidElement(Child) ? React.cloneElement(Child, rest) : Child}
    </Access>
  );
};

export default IAccessable;
