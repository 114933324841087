import { useMemo } from 'react';
import useFormatMessage from '@/hooks/useFormatMessage';

export enum ClientStatus {
  ACTIVE = 1,
  ERROR = 2,
  INACTIVE = 3,
  // 潜在客户
  POTENTIAL = 4,
  INVALID = 0,
}

export const useClientStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    return new Map([
      [
        ClientStatus.ACTIVE,
        { text: formatMessage('活跃客户'), status: 'processing' },
      ],
      [
        ClientStatus.ERROR,
        { text: formatMessage('异常客户'), status: 'warning' },
      ],
      [
        ClientStatus.INACTIVE,
        { text: formatMessage('冷客户'), status: 'default' },
      ],
      [
        ClientStatus.POTENTIAL,
        { text: formatMessage('潜在客户'), status: 'success' },
      ],
      [
        ClientStatus.INVALID,
        {
          text: formatMessage('无效客户'),
          status: 'error',
        },
      ],
    ] as const);
  }, [formatMessage]);
};
