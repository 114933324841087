export default {
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.register': 'Sign up',
  'menu.login': 'Login',
  'menu.forgot': 'Forgot password',
  'menu.admin-login': 'Login',
  'menu.welcome': 'Welcome',
  'menu.invite': 'Invite',
  'menu.create-company': 'Create Company',

  'menu.company-admin': 'Company Management',
  'menu.company-admin.detail': 'Company Detail',
  'menu.company-admin.bill-settings': 'Invoice Template',
  'menu.company-admin.settings': 'Function Configuration',
  'menu.company-admin.case-point-settings': 'Case Point Configuration',
  'menu.company-admin.material-recycling': 'Recycling Station',
  'menu.company-admin.message-task': 'Task Management',
  'menu.company-admin.message-task.template': 'Message Template',
  'menu.company-admin.message-task.log': 'Task Log',
  'menu.company-admin.message-task.list': 'Global Task',

  'menu.company-logs': 'System Safety',
  'menu.company-logs.operation-list': 'Operation Record',
  'menu.company-logs.log': 'System Log',
  'menu.company-logs.log.staff': 'Staff Log',

  'menu.company-staff': 'Staff Management',
  'menu.company-staff.list': 'Staff List',
  'menu.company-staff.invite-list': 'Invitation Record',
  'menu.company-staff.job-list': 'Company Roles',
  'menu.financial.company-bill': 'Invoice Payment Review',
  'menu.financial.company-coe-payment': 'CoE Payment Review',

  'menu.financial.company-point': 'Credits Management',
  'menu.financial.company-point.list': 'Credits Details',
  'menu.financial.company-point.extra': 'Extra Credits',
  'menu.financial.company-point.withdraw': 'Withdraw Management',
  'menu.financial.c2c-point-agent': 'Company Point',
  'menu.financial.c2c-point-agent.list': 'Company Point List',
  'menu.financial.c2c-point-agent.coe': 'CoE Credits',
  'menu.financial.c2c-point-channel': 'Company Point Management',
  'menu.financial.c2c-point-channel.list': 'Credits Details',
  'menu.financial.c2c-point-channel.withdraw': 'Withdraw Management',
  'menu.financial.c2c-point-channel.extra': 'Extra Credits',
  'menu.financial.c2c-point-channel.coe': 'CoE Credits',

  'menu.case-tag': 'Case Tag',

  'menu.point': 'My Credits',
  'menu.point.list': 'Credits Details',
  'menu.point.withdraw': 'Withdraw',
  'menu.point.extra': 'Extra Credits',

  'menu.my-master-agent': 'My Master Agent',
  'menu.master-agent': 'Master Agent',
  'menu.master-agent.list': 'Master Agent',
  'menu.master-agent.detail': 'Master Agent Detail',
  'menu.master-agent.detail.school': 'School Detail',

  'menu.client-user.create': 'Client Form',
  'menu.client-user.visitor-visa': 'Visitor Visa',
  'menu.client-user.student-visa': 'Student Visa',
  'menu.client-user.bridging-visa-b': 'Bridging Visa B',
  'menu.client-user.subsequent-student-visa': 'Student visa Subsequent Entrant',
  'menu.client-user.guardian-student-visa': 'Student Guardian visa',
  'menu.client-user.485-visa': 'Temporary Graduate visa',
  'menu.client-user.gte': 'Visa Questions',
  'menu.client-user.view-offer': 'View Offer',
  'menu.client-user.offer': 'Instant Offer',
  'menu.client-user.956-form': '956 Form',
  'menu.client-user.contract-view': 'View Contract',
  'menu.client-user.school-apply-nav': 'School Application',
  'menu.client-user.school-apply': 'School Application',
  'menu.client-user.agent-apply': 'Agent Application',
  'menu.client-user.prp': '永居规划',

  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',

  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',

  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.settings.base': 'Basic Settings',
  'menu.account.settings.password': 'Change Password',
  'menu.account.settings.association': 'Account Association',
  'menu.account.settings.payment-info': 'Payment Info',
  'menu.account.settings.webauthn': 'Biometric fingerprint login binding',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.file-sign': 'File Signature',
  'menu.account.logout': 'Logout',
  'menu.account.log': 'Operation Log',

  // 系统管理
  'menu.system': 'System Management',
  'menu.system.api-rule': 'API Rule',
  'menu.system.router-rule': 'Router Rule',
  'menu.system.role-group': 'Role Group',

  // 中介管理
  'menu.agent-client': 'Client Management',
  'menu.agent-client.list': 'Client List',
  'menu.agent-client.detail': 'Client Detail',
  'menu.agent-client.detail-email': 'Email Detail',

  'menu.agent-case': 'Case Management',
  'menu.agent-case.list': 'Case List',
  'menu.agent-case.detail': 'Case Detail',
  'menu.offer-form': 'Offer Form',

  'menu.custom-case': 'Case Configuration',
  'menu.custom-case.custom-list': 'Custom Case',
  'menu.custom-case.custom-detail': 'Custom Case',
  'menu.custom-case.builtin-list': 'Builtin Case',
  'menu.custom-case.builtin-detail': 'Builtin Case',
  'menu.custom-case.custom-category': 'Custom Case Category',
  'menu.custom-case.custom-form': 'Custom Form',
  'menu.custom-case.steps-config': 'Custom Steps',
  'menu.custom-case.steps-category': 'Step Category',

  'menu.file-sign': 'Document Certify',
  'menu.file-sign.personal': 'Personal Signatures',
  'menu.file-sign.list': 'Certified Documents',

  'menu.channel-case': 'Case Management',
  'menu.channel-case.list': 'Case List',
  'menu.channel-case.detail': 'Case Detail',

  'menu.channel-client': 'Client Management',
  'menu.channel-client.list': 'Case List',
  'menu.channel-client.detail': 'Case Detail',

  'menu.financial.channel-coe-payment': 'CoE Payment Review',
  'menu.financial.channel-bill': 'Invoice Payment Review',

  'menu.school': 'Schools',
  'menu.school.detail': 'School Detail',
  'menu.school.list': 'School List',
  'menu.school.course-tutoring-company': 'Course Tutoring Company',
  'menu.school.rpl-course-list': 'RPL Course List',
  'menu.school.course-alternative-titles': 'Course Alternative Tiles',

  'menu.analysis': 'Data Analysis',
  'menu.analysis.asqa': 'ASQA',
  'menu.analysis.student': 'Number of International Students',
  'menu.analysis.school': 'School Analysis',
  'menu.analysis.course': 'Course Analysis',
  'menu.analysis.agent': 'Agency Analysis',
  'menu.analysis.trend': '学校趋势分析',
  'menu.analysis.agent.summary': 'Data Summary',
  'menu.analysis.agent.compare': 'Data Compare',
  'menu.analysis.agent.detail': 'Detail',

  'menu.file-assistant': 'File Assistant',
  'menu.file-remark': 'File Remark',
  'menu.file-remark.coe-attachment-': 'CoE Attachment Remark',
  'menu.file-remark.offer': 'Offer Remark',
  'menu.file-remark.cate': 'File Classification Remark',
  'menu.file-remark.student-visa': '学生签证标注',
  'menu.system-update': 'System Update Management',
  'menu.system-update.list': 'System Update',
  'menu.system-update-prompt': 'System is updating',
  'menu.system-update.detail': 'Release a new version',
  'menu.releases': 'Release Logs',
  'menu.releases.detail': 'Release Log Detail',

  'menu.publicity.prp': '永居规划',
  'menu.publicity.prp-detail': '永居规划',
  'menu.publicity.pr-planner': '永居规划管理',
  'menu.publicity.pr-planner.cert-condition': '职业证书',
  'menu.publicity.pr-planner.assess-condition': '职业评估',
  'menu.publicity.pr-planner.channel': '移民渠道',

  'menu.publicity.pr-planner.diff': '数据对比',
  'menu.publicity.pr-planner.channel-detail': '移民渠道详情',
  'menu.publicity.pr-planner.course': '课程录入',
  'menu.publicity.pr-planner.version': '版本发布',

  'menu.oneulink-assistance': 'Company Assistance',
  'menu.oneulink-assistance.list': 'Company Assistance',
  'menu.oneulink-assistance.log': 'Assistance Log',

  'menu.financial': 'Financial',

  'menu.visa-like-me': 'Visa Like Me',

  'menu.publicity': 'Agent Media',
  'menu.publicity.course-plan-list': 'Course Plan',
  'menu.publicity.course-plan-report': 'Course Plan',
  'menu.publicity.visa-fees-list': 'Visa Fees calculation',
  'menu.publicity.visa-fees-detail': 'Visa Fees calculation',
  'menu.publicity.offer': 'Instant Offer',

  'menu.publicity.part-study': 'Part Study',
  'menu.publicity.course-checklist': 'Course List',

  'menu.data-config': '数据/权限配置',
  'menu.data-config.file-category': '文件分类配置',
  'menu.data-config.auto-submit': '自动提交',
  'menu.data-config.publicity-access': '专员宣传权限',
  'menu.data-config.review-payment-info': '审核付款信息',
  'menu.data-config.occupation': '评估职业',
  'menu.data-config.email-blacklist': 'Email Blacklist',
  'menu.data-config.course-checklist': 'Course List',
  'menu.data-config.skill-assess': 'Skill Assessment',
  'menu.data-config.rpl-discount': 'RPL Discount',
  'menu.data-config.assist-promotion': 'AI 助手推广码',

  'menu.system-tips': 'Tips Management',
  'menu.system-members': 'Account Management',
  'menu.system-companys': 'Company Management',

  'menu.student': 'Student Management',
  'menu.student.accounts': 'Account List',
  'menu.student.point-details': 'Point Details',
  'menu.student.use-points-record': 'Use Points Record',
  'menu.student.case-point-bonus': 'Case Point Bonus',
};
