import {
  CloseOutlined,
  RedoOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { AnimatePresence, motion } from 'framer-motion';
import React, { memo, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import 'react-image-lightbox/style.css';
import { lock, unlock } from 'tua-body-scroll-lock';
import PanPinch from '../PanPinch';
import styles from './index.less';

interface ImageViewerProps {
  onClose?: () => void;
  visible?: boolean;
  title?: React.ReactNode;
  url: string;
  toolbars?: React.ReactElement[];
}

const ToolbarItem: React.FC = ({ children }) => {
  return (
    <motion.div
      initial={{
        opacity: 0.6,
      }}
      whileHover={{
        scale: 1.1,
        opacity: 1,
      }}
      className={styles.navItem}
    >
      {children}
    </motion.div>
  );
};

const ImageViewer: React.FC<ImageViewerProps> = ({
  onClose,
  visible,
  title,
  url,
  toolbars,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const elem = ref.current;

    if (visible) {
      lock(elem);
    }

    return () => unlock(elem);
  }, [visible]);

  return createPortal(
    <AnimatePresence>
      {visible && (
        <motion.div
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          initial={{
            opacity: 0,
          }}
          transition={{
            duration: 0.3,
          }}
          key="mask"
          className={styles.mask}
        />
      )}
      {visible && (
        <motion.div
          key="body"
          className={styles.body}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          initial={{
            opacity: 0,
          }}
          transition={{
            duration: 0.3,
          }}
          ref={ref}
        >
          <PanPinch url={url}>
            {(elem, { rotate, zoomIn, zoomOut }) => (
              <>
                <div className={styles.header}>
                  <div className={styles.title}>{title}</div>
                  <div className={styles.navs}>
                    {toolbars?.map((item) => (
                      <ToolbarItem key={item.key}>{item}</ToolbarItem>
                    ))}
                    <ToolbarItem>
                      <UndoOutlined onClick={() => rotate(-90)} />
                    </ToolbarItem>
                    <ToolbarItem>
                      <RedoOutlined onClick={() => rotate(90)} />
                    </ToolbarItem>
                    <ToolbarItem>
                      <ZoomOutOutlined onClick={zoomOut} />
                    </ToolbarItem>
                    <ToolbarItem>
                      <ZoomInOutlined onClick={zoomIn} />
                    </ToolbarItem>
                    <ToolbarItem>
                      <CloseOutlined onClick={onClose} />
                    </ToolbarItem>
                  </div>
                </div>
                <div className={styles.container}>{elem}</div>
              </>
            )}
          </PanPinch>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body,
  );
};

export default memo(ImageViewer);
