import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum C2CCoEStatus {
  WAITING = 'waiting',
  PASS = 'pass',
  FAILED = 'fail',
  SETTLED = 'settle',
  REVIEWING = 'reviewing',
}

export enum C2CCoEType {
  BONUS = 'bonus',
  COMMISSION = 'commission',
}

export const useC2CCoETypeEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () =>
      new Map([
        [
          C2CCoEType.BONUS,
          {
            text: formatMessage('奖金'),
            status: 'success',
          },
        ],
        [
          C2CCoEType.COMMISSION,
          {
            text: formatMessage('CoE 付款'),
            status: 'processing',
          },
        ],
      ]),
    [formatMessage],
  );
};

export const useC2CCoEStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () =>
      new Map([
        [
          C2CCoEStatus.WAITING,
          {
            text: formatMessage('等待公司预提现'),
            status: 'default',
          },
        ],
        [
          C2CCoEStatus.REVIEWING,
          {
            text: formatMessage('预提现审核中'),
            status: 'processing',
          },
        ],
        [
          C2CCoEStatus.PASS,
          {
            text: formatMessage('已结算到公司'),
            status: 'processing',
          },
        ],
        [
          C2CCoEStatus.SETTLED,
          {
            text: formatMessage('已结算到公司员工'),
            status: 'success',
          },
        ],
        [
          C2CCoEStatus.FAILED,
          {
            text: formatMessage('预提现审核失败'),
            status: 'error',
          },
        ],
      ] as const),
    [formatMessage],
  );
};
