import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { usePersistFn, useRequest } from 'ahooks';
import { Empty, Input, Menu, Skeleton, Space } from 'antd';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import { ResponseSchema, services } from '@/api';
import { CommonSelect, CustomAvatar, Option } from '@/common';
import { useFormatMessage } from '@/hooks';
import { formatPersonName } from '@/utils';
import styles from './TodoChargeStaffSelect.less';

const TodoChargeStaffSelect: React.FC<
  { refreshDeps?: any[] } & {
    value?: number;
    onChange?: (value?: number, memberId?: number) => void;
    popup?: boolean;
    memberId?: number;
  }
> = ({ memberId, refreshDeps = [], popup = true, value, onChange = noop }) => {
  const [searchText, setSearchText] = useState('');
  const { run: getStaffOptions, data: staffResp, loading } = useRequest<
    ResponseSchema<
      {
        avatar: string;
        familyName: string;
        givenName: string | null;
        id: number;
        nickname: string;
        memberId: number;
      }[]
    >
  >(services.todo.getChargeStaffOptions, {
    manual: true,
  });
  const { formatMessage } = useFormatMessage();

  useEffect(() => {
    getStaffOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...refreshDeps, getStaffOptions]);

  const keyword = searchText.toLowerCase();
  const staffList = staffResp?.data;
  const list = staffList?.filter((item) => {
    if (!searchText) {
      return true;
    }

    return (
      item.nickname.toLowerCase().includes(keyword) ||
      [item.familyName, item.givenName].some(
        (text) => text && text.toLowerCase().includes(keyword),
      )
    );
  });

  const persistedOnChange = usePersistFn(onChange);

  useEffect(() => {
    if (memberId && !value && staffList) {
      const target = staffList.find((item) => item.memberId === memberId);
      persistedOnChange(target?.id, target?.memberId);
    }
  }, [memberId, value, persistedOnChange, staffList]);

  return popup ? (
    <CommonSelect
      value={value}
      // @ts-ignore
      onChange={(val, option) => onChange(val, option?.memberId)}
      optionLabelProp="title"
    >
      {list?.map((item) => (
        <Option
          key={item.id}
          value={item.id}
          title={item.nickname}
          label={formatPersonName(item)}
          memberId={item.memberId}
        >
          <Space align="center">
            <CustomAvatar size="small" src={item.avatar} alt={item.nickname} />
            <span>{item.nickname}</span>
          </Space>
        </Option>
      ))}
    </CommonSelect>
  ) : (
    <div>
      <div className="ant-table-filter-dropdown-search">
        <Input
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(evt) => setSearchText(evt.target.value)}
          placeholder={formatMessage('搜索')}
          className="ant-table-filter-dropdown-search-input"
        />
      </div>
      <Skeleton
        paragraph={{ rows: 6 }}
        style={{ padding: loading ? 16 : 0 }}
        loading={loading}
      >
        {list && list.length > 0 ? (
          <Menu
            className={styles.menu}
            selectedKeys={value ? [String(value)] : undefined}
            items={list?.map((item) => ({
              icon: (
                <CustomAvatar
                  size="small"
                  src={item.avatar}
                  alt={item.nickname}
                />
              ),
              key: String(item.id),
              onClick: () => onChange?.(item.id, item.memberId),
              label: item.nickname,
              itemIcon: item.id === value && <CheckOutlined />,
            }))}
          />
        ) : (
          <div
            style={{
              height: 250,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </Skeleton>
    </div>
  );
};

export default TodoChargeStaffSelect;
