import { DependencyList, useEffect } from 'react';
import useDeepMemorized from './useDeepMemorized';

const useDeepMemorizedEffect = <T extends (...args: any) => any>(
  fn: T,
  deps: DependencyList,
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fn, useDeepMemorized(deps));
};

export default useDeepMemorizedEffect;
