import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum ClientRelationshipType {
  Parents = 'Parents',
  Marriage = 'Marriage',
  Devoice = 'Devoice',
  Defacto = 'De-facto',
  Child = 'Child',
  InRelationship = 'In-Relationship',
  BrotherSister = 'Brother/Sister',
}

export const useRelationshipTypeEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum = {
      [ClientRelationshipType.Parents]: {
        text: /* @message.en-US Parents @message.scope client-relations */ formatMessage(
          '父母关系',
        ),
      },
      [ClientRelationshipType.Marriage]: {
        text: /* @message.en-US Marriage @message.scope client-relation */ formatMessage(
          '夫妻关系',
        ),
      },
      [ClientRelationshipType.Devoice]: {
        text: /* @message.en-US Devoice @message.scope client-relation */ formatMessage(
          '离异关系',
        ),
      },
      [ClientRelationshipType.Defacto]: {
        text: /* @message.en-US De facto @message.scope client-relation */ formatMessage(
          '同居关系',
        ),
      },
      [ClientRelationshipType.Child]: {
        text: /* @message.en-US Child @message.scope client-relation */ formatMessage(
          '子女关系',
        ),
      },
      [ClientRelationshipType.BrotherSister]: {
        text: /* @message.en-US Brother/Sister @message.scope client-relation */ formatMessage(
          '兄弟姐妹关系',
        ),
      },
      [ClientRelationshipType.InRelationship]: {
        text: /* @message.en-US In Relationship @message.scope client-relation */ formatMessage(
          '恋爱关系',
        ),
      },
    } as const;

    return new Map(
      Object.entries(statusEnum).map((item) => [
        item[0] as ClientRelationshipType,
        item[1],
      ]),
    );
  }, [formatMessage]);
};
