const TOKEN_KEY = 'FORM_AUTH_TOKEN';

// eslint-disable-next-line no-underscore-dangle
let _token: string | null = null;

export const getFormAuthToken = () => {
  try {
    return localStorage.getItem(TOKEN_KEY) || null;
  } catch {
    return _token;
  }
};

export const setFormAuthToken = (token: string) => {
  try {
    localStorage.setItem(TOKEN_KEY, token);
  } catch {
    _token = token;
  }
};

export const clearFormAuthToken = () => {
  try {
    _token = null;
    localStorage.removeItem(TOKEN_KEY);
    // eslint-disable-next-line no-empty
  } catch {}
};
