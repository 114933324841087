import { omit, pick } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from '@/config';
import {
  MessageTaskEventType,
  MessageTaskGroup,
  MessageTaskScope,
} from '@/data';
import { MessageTaskRecipientType, TriggerDateType } from './data';
import type { IMessageTaskRecipientValue, MessageTaskFormValues } from './type';

export const formatMessageTaskFormGrouping = (
  scope?: MessageTaskScope,
  correlationCaseId?: number,
  correlationClientId?: number,
) =>
  scope === 'todo'
    ? MessageTaskGroup.TODO
    : correlationCaseId
    ? MessageTaskGroup.CASE
    : correlationClientId
    ? MessageTaskGroup.CLIENT
    : MessageTaskGroup.OVERALL;

export const formatTaskFormValues = (
  values: AnyObject,
  data?: MessageTaskFormValues,
  scope?: MessageTaskScope,
) => {
  const {
    personnelName,
    staffList,
    recipient,
    triggerDate,
    correlationClientId,
    correlationCaseId,
    ...formValues
  } = values;

  let result: { type: MessageTaskRecipientType; value: string | number }[];

  formValues.grouping = formatMessageTaskFormGrouping(
    scope,
    correlationCaseId,
    correlationClientId,
  );
  formValues.correlationId = correlationCaseId ?? correlationClientId;

  if (staffList) {
    result = (staffList as number[]).map((id) => ({
      type: MessageTaskRecipientType.STAFF,
      value: id,
      Id: data?.recipient.find(
        (item) =>
          item.type === MessageTaskRecipientType.STAFF && item.value === id,
      )?.Id,
    }));
  } else if (personnelName) {
    result = (personnelName as string[]).map((name) => ({
      type: MessageTaskRecipientType.PRONOUN,
      value: name,
      Id: data?.recipient.find(
        (item) =>
          item.type === MessageTaskRecipientType.PRONOUN && item.value === name,
      )?.Id,
    }));
  } else {
    result = (recipient as IMessageTaskRecipientValue[]).map((item) =>
      pick(item, ['type', 'value', 'Id']),
    );
  }

  if (Array.isArray(triggerDate)) {
    formValues.triggerDate = triggerDate.map((item) => {
      if (item.type === TriggerDateType.DYNAMIC) {
        return {
          ...item,
          offset: item.offset,
          Id:
            item.Id ||
            data?.triggerDate?.find(
              (v) =>
                v.type === TriggerDateType.DYNAMIC && v.offset === item.offset,
            )?.Id,
        };
      }
      const formattedDate = moment.isMoment(item.date)
        ? item.date.toISOString()
        : moment(item.date, `${DATE_FORMAT} HH:mm:ss`).toISOString();

      return {
        ...item,
        date: formattedDate,
        Id:
          item.Id ||
          data?.triggerDate?.find(
            (v) =>
              v.type !== TriggerDateType.DYNAMIC && v.date === formattedDate,
          )?.Id,
      };
    });
  }

  formValues.recipient = result;

  return omit(formValues, ['correlationName']);
};

export const convertToTaskFormValues = (
  data: MessageTaskFormValues & {
    currentGrouping: string | undefined;
  },
) => {
  const {
    triggerEvent,
    grouping,
    recipient,
    triggerDate,
    correlationId,
    currentGrouping,
    ...rest
  } = data;
  const formValues: AnyObject = omit(rest, ['id', 'status', 'correlationName']);

  if (triggerEvent === MessageTaskEventType.NORMAL) {
    formValues.staffList = recipient.map((item) => item.value);
  } else if (
    currentGrouping === MessageTaskGroup.OVERALL ||
    triggerEvent === MessageTaskEventType.ON_CLIENT_CREATED
  ) {
    formValues.personnelName = recipient.map((item) => item.value);
  } else {
    formValues.recipient = recipient.map((item) => ({
      ...item,
      name:
        item.type === MessageTaskRecipientType.PRONOUN ? item.value : item.name,
    }));
  }

  if (grouping === MessageTaskGroup.CASE) {
    formValues.correlationClientId = correlationId;
  } else if (grouping === MessageTaskGroup.CLIENT) {
    formValues.correlationCaseId = correlationId;
  }

  return {
    ...formValues,
    grouping,
    triggerEvent,
    triggerDate: triggerDate?.map((item) =>
      item.type === TriggerDateType.DYNAMIC
        ? item
        : {
            ...item,
            date: moment(item.date).format(`${DATE_FORMAT} HH:mm:ss`),
          },
    ),
  };
};
