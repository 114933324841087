import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum ClientContractStatusEnum {
  PROCESSING = 'wait-customer-sign',
  COMPLETED = 'completion',
  INVALID = 'invalid',
}

export const useClientContractStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(
    () =>
      new Map([
        [
          ClientContractStatusEnum.PROCESSING,
          {
            text: formatMessage('待签署'),
            status: 'processing',
          },
        ],
        [
          ClientContractStatusEnum.COMPLETED,
          {
            text: formatMessage('已签署'),
            status: 'success',
          },
        ],
        [
          ClientContractStatusEnum.INVALID,
          {
            text: formatMessage('已失效'),
            status: 'error',
          },
        ],
      ] as const),
    [formatMessage],
  );
};
