import ProForm, { ProFormItemProps } from '@ant-design/pro-form';
import React from 'react';

const EmptyField = React.forwardRef<unknown, ProFormItemProps>((props, ref) => {
  return (
    <ProForm.Item noStyle {...props}>
      <span style={{ display: 'none' }} />
    </ProForm.Item>
  );
});

export default EmptyField;
