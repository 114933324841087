export default {
  'form:email.wrong-format': '请输入正确的邮箱',
  'form:phone.wrong-format': '请输入正确的手机号',
  'form:text.length': '请输入至少 {min} 个字符至多 {max} 个字符',
  'form:input.placeholder': '请输入',
  'form:input.required-tips': '请输入',
  'form:select.placeholder': '请选择',
  'form:select.required-tips': '请选择',
  'form:file.placeholder': '请上传',
  'form:file.required-tips': '请上传',
  'form:country-code': '地区',
  'form:add.placeholder': '请添加',
  'form:add.required-tips': '请添加',

  'form:sex.option.man': '男',
  'form:sex.option.woman': '女',
  'form:sex.option.x': 'X',

  'form:sure-btn-text': '确定',
  'form:add-btn-text': '添加',
  'form:submit-btn-text': '提交',
  'form:cancel-btn-text': '取消',
  'form:delete-btn-text': '刪除',
  'form:edit-btn-text': '编辑',
  'form:preview-btn-text': '预览',
  'form:action-text': '操作',

  'form:yesno.option.yes': '是',
  'form:yesno.option.no': '否',

  'form:view-detail-text': '查看详情',
  'form:keyword-text': '关键字',
};
