import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ProForm from '@ant-design/pro-form';
import { useControllableValue } from 'ahooks';
import { Badge, Button, Space, Tooltip } from 'antd';
import { Rule } from 'antd/lib/form';
import { AnimatePresence, motion } from 'framer-motion';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { EmptyField } from '@/common';
import ProModalForm from '@/components/ProModalForm';
import { DATE_FORMAT } from '@/config';
import { useCommonMessages, useCommonRules, useFormatMessage } from '@/hooks';
import { formatDate } from '@/utils';
import AbsoluteDate from './AbsoluteDate';
import DynamicDate from './DynamicDate';
import { TriggerDateType } from './data';
import { TriggerDateFormValue } from './type';

const FormItem = ProForm.Item;

const TriggerDateForm: React.FC<{
  value?: TriggerDateFormValue[];
  onChange?: (value: TriggerDateFormValue[]) => void;
  // 是否只能是绝对时间 一般是 normal
  onlyAbsoluteDate?: boolean;
  // 对相对时间的描述 如生日前
  description?: (hour?: number) => string;
}> = (props) => {
  const { description, onlyAbsoluteDate } = props;
  const [values, setValues] = useControllableValue<TriggerDateFormValue[]>(
    props,
  );

  const { formatMessage } = useFormatMessage();
  const [form] = ProForm.useForm();
  const { actions } = useCommonMessages();
  const { selectRules } = useCommonRules();
  const [visible, setVisible] = useState(false);

  const dateFormat = `${DATE_FORMAT} HH:mm:ss`;
  const absoluteDates = (values
    ?.map((item) => (item.type === TriggerDateType.NORMAL ? item.date : null))
    .filter(Boolean) ?? []) as string[];
  const dynamicDates = (values
    ?.map((item) =>
      item.type === TriggerDateType.DYNAMIC ? item.offset : null,
    )
    .filter(Boolean) ?? []) as number[];

  const absoluteDateRules: Rule[] = [
    ...selectRules,
    () => ({
      validator: async (_, dateValue) => {
        if (dateValue) {
          const formattedDateValue = (dateValue as Moment).format(dateFormat);

          if (absoluteDates.includes(formattedDateValue)) {
            throw new Error(formatMessage('不能添加重复时间'));
          }
        }
      },
    }),
  ];

  return (
    <div>
      <div
        style={{
          marginBottom: 8,
        }}
      >
        <AnimatePresence>
          {values?.map((item, index) => (
            <motion.div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              initial={{
                height: 0,
              }}
              animate={{
                height: 'auto',
              }}
              transition={{
                stiffness: 50,
                damping: 600,
              }}
              exit={{ height: 0, overflow: 'hidden' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Space>
                  <Badge
                    status={
                      item.type === TriggerDateType.NORMAL
                        ? 'processing'
                        : 'success'
                    }
                  />
                  {item.type === TriggerDateType.NORMAL ? (
                    <span>
                      {formatDate(moment(item.date, dateFormat), true)}
                    </span>
                  ) : (
                    description?.(item.offset)
                  )}
                </Space>
                <Tooltip title={actions.deleteBtnText}>
                  <Button
                    onClick={() => {
                      values.splice(index, 1);
                      setValues(values.slice());
                    }}
                    type="text"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <ProModalForm
        form={form}
        onFinish={async (formValues: TriggerDateFormValue) => {
          if (formValues.type === TriggerDateType.NORMAL) {
            setValues((values ?? []).concat(formValues));
          } else {
            // @ts-ignore
            const nextDynamicDates = (formValues.offset as number[]).sort(
              (a, b) => a - b,
            );

            setValues([
              ...absoluteDates.map((item) => ({
                type: TriggerDateType.NORMAL,
                date: item,
              })),
              ...nextDynamicDates.map((item) => ({
                type: TriggerDateType.DYNAMIC,
                offset: item,
              })),
            ] as TriggerDateFormValue[]);
          }
          return true;
        }}
        visible={visible}
        onVisibleChange={setVisible}
        title={formatMessage('添加时间')}
        trigger={
          <Button icon={<PlusOutlined />} block type="dashed">
            {formatMessage('添加时间')}
          </Button>
        }
        modalProps={{
          bodyStyle: {
            minHeight: 300,
          },
        }}
        onValuesChange={(changed) => {
          const { type } = changed;

          if ('type' in changed && type === TriggerDateType.DYNAMIC) {
            // 初始化已有的相对时间
            form.setFieldsValue({
              offset: dynamicDates,
            });
          }
        }}
      >
        {onlyAbsoluteDate ? (
          <>
            <EmptyField
              name="type"
              style={{ display: 'none' }}
              initialValue={TriggerDateType.NORMAL}
            />
            <ProForm.Item
              dataFormat={dateFormat}
              name="date"
              rules={absoluteDateRules}
            >
              <AbsoluteDate format={dateFormat} />
            </ProForm.Item>
          </>
        ) : (
          <>
            <EmptyField name="type" initialValue={TriggerDateType.DYNAMIC} />
            <FormItem rules={selectRules} name="offset" label={description?.()}>
              <DynamicDate />
            </FormItem>
          </>
        )}
      </ProModalForm>
    </div>
  );
};

export default TriggerDateForm;
