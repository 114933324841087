import { useRequest, useSize, useUpdateLayoutEffect } from 'ahooks';
import { Button, ButtonProps, Typography } from 'antd';
import classNames from 'classnames';
import { isString } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'umi';
import { ResponseSchema, services } from '@/api';
import StaticFileViewer from '@/components/StaticFileViewer';
import { useMeasure } from '@/hooks';
import { formatFilename } from '@/utils';

const { Text } = Typography;

let taskId: 0 | 1 = 0;

const FileViewer: React.FC<
  {
    id?: number;
    url?: string;
    filename?: string | null;
    children?: React.ReactNode;
    maxWidth?: number | string;
    measureFlexWidth?: boolean;
    service?: (...args: any[]) => Promise<any>;
  } & Omit<ButtonProps, 'id' | 'url' | 'filename'>
> = ({
  id,
  children,
  url,
  filename,
  style,
  measureFlexWidth,
  className,
  service = services.file.viewFile,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const { width: bodyWidth } = useSize(document.body);
  const [flexWidth, setFlexWidth] = useState(0);

  const {
    data: { max },
    measure,
  } = useMeasure({
    ref,
    format: (node) => {
      if (!node || !node.parentElement || measureFlexWidth) {
        return { max: 0 };
      }

      if (node.parentElement.tagName === 'TD') {
        return {
          max: node.parentElement.clientWidth - 14,
        };
      }

      return { max: 0 };
    },
  });

  const { loading, run, data } = useRequest<
    ResponseSchema<{ fileUrl: string; fileName?: string }>
  >(service, {
    manual: true,
    onSuccess() {
      taskId = 0;
      setVisible(true);
    },
    onError() {
      taskId = 0;
    },
  });

  const handleView = () => {
    if (url) {
      setVisible(true);
    } else if (taskId === 0 && id) {
      taskId = 1;
      run({ id });
    }
  };

  useEffect(() => {
    return () => {
      taskId = 0;
    };
  }, []);

  useEffect(() => {
    const btn = ref.current;

    if (!btn || !measureFlexWidth) {
      return;
    }

    const child = btn.firstElementChild! as HTMLSpanElement;
    const {
      display,
      flexGrow,
      paddingLeft,
      paddingRight,
      flex,
    } = getComputedStyle(btn);

    if (display.includes('flex')) {
      child.style.display = 'none';

      if (flexGrow !== '1') {
        btn.style.flex = '1 1 auto';
      }

      if (display.includes('inline')) {
        btn.style.display = 'flex';
      }

      const w =
        btn.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);

      btn.style.display = display;
      btn.style.flex = flex;

      child.style.display = 'inline-block';

      setFlexWidth(w > 0 ? w : 0);
    }
  }, [measureFlexWidth, bodyWidth]);

  useUpdateLayoutEffect(() => {
    if (ref.current) {
      measure(ref.current);
    }
  }, [bodyWidth, measure]);

  const internalUrl = data?.data?.fileUrl;
  const internalFilename = data?.data?.fileName;
  const targetUrl = url ?? internalUrl;

  const child =
    (isString(children) ? formatFilename(children) : children) ||
    formatMessage({ id: 'app.file.view' });

  const w = measureFlexWidth ? flexWidth : max;
  const maxWidth = w > 0 ? w : undefined;

  return (
    <>
      <Button
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          display: 'inline-flex',
          alignItems: 'center',
          maxWidth: '100%',
          height: 'auto',
          transition: 'none',
          ...style,
        }}
        onClick={handleView}
        loading={loading}
        className={classNames('file-viewer-btn', className)}
        type="link"
        ref={ref}
        {...rest}
      >
        <Text
          style={{
            color: 'inherit',
            maxWidth,
          }}
          ellipsis={{
            tooltip: isString(child) ? child : undefined,
          }}
        >
          {child}
        </Text>
      </Button>
      <StaticFileViewer
        filename={filename ?? internalFilename ?? targetUrl}
        id={id}
        visible={visible}
        onVisibleChange={setVisible}
        url={targetUrl}
      />
    </>
  );
};

export default memo(FileViewer);
