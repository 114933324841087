import { PageLoading } from '@ant-design/pro-layout';
import { dynamic } from 'umi';

export function Dynamic<D extends object = AnyObject>(
  loader: () => Promise<
    React.ComponentType<D> | { default: React.ComponentType<D> }
  >,
  params?: {
    loading?: React.ComponentType<any>;
  },
): React.ComponentType<D> {
  // @ts-ignore
  return dynamic({
    loader,
    loading: PageLoading,
    ...params,
  });
}
