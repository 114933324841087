import OSS from 'ali-oss';
import { MD5, SHA1, enc, lib } from 'crypto-js';
import { fromBuffer } from 'file-type/browser';
import { isNil, isString, mapValues, pickBy } from 'lodash';
import mime from 'mime-types';
import pLimit from 'p-limit';
import { ResponseSchema, services } from '@/api';
import { SUCCESS_CODE } from '@/api/config';
import { getToken } from '@/auth';
import { FileUploadTypeEnum } from '@/constant';
import { ENV } from '@/env';
import { getId } from '@/utils';

const convertStringToBlob = (file: string) => {
  if (file.includes('image') && file.includes('base64')) {
    // eslint-disable-next-line compat/compat
    return fetch(file).then((res) => res.blob());
  }

  const blob = new Blob([file], { type: 'text/plain' });

  return Promise.resolve(blob);
};

const getFileHash = async (data: ArrayBuffer) => {
  // @ts-ignore
  return MD5(lib.WordArray.create(data)).toString(enc.Hex);
};

const getFileExt = async (file: File | Blob, type?: string, name?: string) => {
  const buffer = await file.arrayBuffer();
  const result = await fromBuffer(buffer);

  if (result) {
    if (result.ext === 'cfb' && name) {
      const internalName = name.split('?')[0].trim();
      return internalName.match(/\.([^.]+)$/)?.[1] || 'docx';
    }

    return result.ext === 'cfb' ? 'docx' : result.ext;
  }

  return type ? mime.lookup(type) || '.pdf' : '.pdf';
};

const getParams = (params?: OSSUploaderUploadParams) => {
  const { clientId, extraData } = params ?? {};
  const result: AnyObject = {};

  if (clientId && !extraData) {
    result.customerId = clientId;
    result.type = FileUploadTypeEnum.AGENT_CUSTOMER;
  } else if (extraData?.type) {
    Object.assign(result, extraData);
  }

  if (!result.type) {
    result.type = FileUploadTypeEnum.COMMON;
  }

  return result;
};

type IToken = {
  region: string;
  bucket: string;
  endpoint: string;
  accessKeySecret: string;
  accessKeyId: string;
  stsToken: string;
  callbackPath: string;
  dir: string;
};

export type OSSUploadExtraData =
  | {
      type: FileUploadTypeEnum.CHANNEL_CASE;
      caseId: number;
    }
  | {
      type: FileUploadTypeEnum.CHANNEL_COE_PAYMENT;
      clientId: number;
      paymentId: number;
    }
  | {
      type: FileUploadTypeEnum.COMMON | FileUploadTypeEnum.RICH_TEXT;
    }
  | { type: FileUploadTypeEnum.CLIENT_FORM; formType: string };
export type OSSUploaderUploadParams = {
  clientId?: number;
  extraData?: OSSUploadExtraData;
};

export class OSSUploader {
  static uploaderMap = new Map<string, OSSUploader>();

  static getUploader = (
    namespace: 'default' | (string & {}) = 'default',
    getTokenService: () => Promise<ResponseSchema<IToken>> = services.file
      .getSTSToken,
  ) => {
    if (this.uploaderMap.get(namespace)) {
      return this.uploaderMap.get(namespace)!;
    }

    const uploader = new OSSUploader(getTokenService);

    this.uploaderMap.set(namespace, uploader);

    return uploader;
  };

  private ossClient: OSS | null = null;

  private getClientPromise?: Promise<OSS>;

  private dir: string | null = null;

  private callbackPath: string | null = null;

  private getTokenService: () => Promise<ResponseSchema<IToken>>;

  constructor(
    getTokenService: () => Promise<ResponseSchema<IToken>> = services.file
      .getSTSToken,
  ) {
    this.getTokenService = getTokenService;
  }

  public async upload<T extends ResponseSchema>(
    file: string | File | Blob,
    params?: OSSUploaderUploadParams,
  ): Promise<T> {
    let target: File | Blob;

    if (isString(file)) {
      target = await convertStringToBlob(file);
    } else {
      target = file;
    }

    const [client, ext, fileData] = await Promise.all([
      this.getClient(),
      // @ts-ignore
      getFileExt(target, file.type, file.name),
      target.arrayBuffer(),
    ]);

    if (!this.dir || !this.callbackUrl) {
      console.warn('没有拿到参数，刷新参数');
      await this.refreshUploadData();
      if (!this.dir || !this.callbackUrl) {
        throw Error('Upload Failed');
      }
    }

    const md5Data = await getFileHash(fileData);

    const salt = 'client';
    const name = `/${this.dir}${SHA1(
      `${md5Data}${getId()}${salt}`,
    ).toString()}.${ext}`;
    const callbackParams = this.getCallbackParams({
      fileHash: md5Data,
      // @ts-ignore
      fileName: encodeURIComponent(target.name || `${md5Data}.${ext}`),
      ...getParams(params),
    });

    if (target.size < 1024 * 1024 * 3) {
      const { data } = await client.put(name, target, {
        callback: callbackParams,
      });

      // @ts-ignore
      if (data.code !== SUCCESS_CODE) {
        // @ts-ignore
        throw Error(data.msg);
      }

      return data as T;
    }

    const multipartUploadData = await this.multipartUpload(
      name,
      target,
      callbackParams,
    );

    return multipartUploadData as T;
  }

  private async multipartUpload(
    name: string,
    file: File | Blob,
    callbackParams: OSS.ObjectCallback,
  ) {
    const client = await this.getClient();
    const initMultipartResult = await client.initMultipartUpload(name);
    const { uploadId } = initMultipartResult;

    const done: {
      number: number;
      etag: string;
    }[] = [];
    const partFileSize = 1024 * 1024 * 1.5;
    const fileSize = file.size;
    const parts = Math.ceil(fileSize / partFileSize);

    const limit = pLimit(6);
    const queue: Promise<void>[] = [];

    for (let i = 0; i < parts; i++) {
      const start = i * partFileSize;
      const end = Math.min(fileSize, start + partFileSize);

      queue.push(
        limit(async () => {
          const part = await client.uploadPart(
            name,
            uploadId,
            i + 1,
            file,
            start,
            end,
          );
          done.push({
            number: i + 1,
            etag: part.etag,
          });
        }),
      );
    }

    await Promise.all(queue);

    const { data } = await client.completeMultipartUpload(
      name,
      uploadId,
      done,
      {
        callback: callbackParams,
      },
    );

    // @ts-ignore
    if (data.code !== SUCCESS_CODE) {
      // @ts-ignore
      throw Error(data.msg);
    }

    return data;
  }

  private getCallbackParams(extraParams?: AnyObject) {
    const base =
      'mimeType=${mimeType}&size=${size}&bucket=${bucket}&object=${object}&';
    const requestData = {
      ...extraParams,
      token: getToken(),
    };
    const data = pickBy(requestData, (val) => !isNil(val));

    const callBackBody = `${base}${Object.keys(data)
      .map((key) => `${key}=\${x:${key}}`)
      .join('&')}`;

    return {
      url: this.callbackUrl,
      body: callBackBody,
      contentType: 'application/x-www-form-urlencoded',
      customValue: mapValues(data, (item) => JSON.stringify(item)),
    };
  }

  private async getClient(): Promise<OSS> {
    if (this.ossClient) {
      return this.ossClient;
    }

    if (this.getClientPromise) {
      const client = await this.getClientPromise;

      return client;
    }

    // eslint-disable-next-line no-async-promise-executor
    this.getClientPromise = new Promise<OSS>(async (resolve, reject) => {
      try {
        const token = await this.getToken();
        const client = new OSS({
          ...token,
          // 刷新临时访问凭证。
          refreshSTSToken: async () => {
            const refreshToken = await this.getToken();
            this.dir = refreshToken.dir;
            this.callbackPath = refreshToken.callbackPath;

            return {
              accessKeyId: refreshToken.accessKeyId,
              accessKeySecret: refreshToken.accessKeySecret,
              stsToken: refreshToken.stsToken,
            };
          },
        });
        this.ossClient = client;
        this.getClientPromise = undefined;
        resolve(client);
      } catch (err) {
        reject(err);
      }
    });

    // eslint-disable-next-line no-return-await
    return await this.getClientPromise;
  }

  private async refreshUploadData() {
    await this.getToken();
  }

  private async getToken() {
    const {
      data: { dir, callbackPath, ...data },
    } = await this.getTokenService();

    this.dir = dir;
    this.callbackPath = callbackPath;

    return data as IToken;
  }

  private get callbackUrl() {
    return `${ENV.apiBaseUrl}${this.callbackPath!}`;
  }
}

const ossUploader = OSSUploader.getUploader();

export default ossUploader;
