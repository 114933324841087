import { useRequest } from 'ahooks';
import { Badge, Button, Empty, Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TodoGlobalViewerProvider } from '@/PageComponents/Todo';
import { ResponseSchema, services } from '@/api';
import emptySVG from '@/assets/message-empty.svg';
import { useFormatMessage, useMobile } from '@/hooks';
import HeaderDropdown from '../HeaderDropdown';
import {
  UsedWebsocketChannel,
  UsedWebsocketEvent,
  useWebsocketSubscribe,
} from '../Websocket';
import CustomVirtualList from './CustomVirtualList';
import HistoryMessage from './HistoryMessage';
import MessageItem from './MessageItem';
import MessageSkeleton from './MessageSkeleton';
import styles from './index.less';
import { IMessageItem, MessageTab } from './type';
import useMessageTitleMap from './useMessageMap';

const MessageTabs: React.FC<{
  children: (unread: number) => React.ReactNode;
}> = ({ children }) => {
  const { formatMessage } = useFormatMessage();
  const { tabs } = useMessageTitleMap();
  const isMobile = useMobile();
  const [unread, setUnread] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);

  const { run: markAll, loading: markAllLoading } = useRequest(
    services.message.markAllAsRead,
    {
      manual: true,
    },
  );
  const { loading, data: resp, run: fetch } = useRequest<
    ResponseSchema<AnyObject<string, any>>
  >(services.message.getMessagesOfWeek, {
    manual: true,
  });

  const data = resp?.data;
  const internalUnread = data?.count ?? 0;
  const list: MessageTab[] = useMemo(
    () =>
      data
        ? tabs.map((item) => ({
            ...item,
            list: data[item.type],
            unreaded: data[item.type].filter(
              (v: IMessageItem) => v.hasRead === 0,
            ).length,
          }))
        : tabs,
    [data, tabs],
  );

  useWebsocketSubscribe<{ count: number }>(
    UsedWebsocketEvent.WAIT_READ,
    (socketData) => {
      setUnread(socketData.count);
      fetch();
    },
    { channel: UsedWebsocketChannel.COMPANY_MEMBER },
  );

  useWebsocketSubscribe<{ count: number }>(
    UsedWebsocketEvent.WAIT_READ,
    (socketData) => {
      setUnread(socketData.count);
      fetch();
    },
    { channel: UsedWebsocketChannel.MEMBER },
  );

  useEffect(() => {
    if (visible) {
      fetch();
    }
  }, [visible, fetch]);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  const overlay = (
    <div className={styles.tabs}>
      <Tabs centered>
        {list.map((item) => (
          <Tabs.TabPane
            key={item.type}
            tab={
              <Badge dot count={item.unreaded}>
                {item.title}
              </Badge>
            }
          >
            <div className={styles.list}>
              <CustomVirtualList
                estimatedRowSize={80}
                noRowsRenderer={() =>
                  loading ? (
                    <MessageSkeleton />
                  ) : (
                    <Empty
                      className={styles.empty}
                      description={formatMessage('暂未收到消息')}
                      image={<img src={emptySVG} alt="" />}
                    />
                  )
                }
                data={item.list}
                renderItem={(message) => (
                  <MessageItem
                    onView={close}
                    refresh={fetch}
                    data={message as IMessageItem}
                  />
                )}
              />
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>

      <ul className={styles.tabsFooter}>
        <li>
          <Button
            type="text"
            onClick={() => markAll()}
            loading={markAllLoading}
          >
            {formatMessage('标记为全部已读')}
          </Button>
        </li>
        <li>
          <Button
            onClick={() => {
              setHistoryVisible(true);
              setVisible(false);
            }}
            type="text"
          >
            {formatMessage('查看历史通知')}
          </Button>
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <TodoGlobalViewerProvider>
        <HeaderDropdown
          visible={visible}
          onVisibleChange={setVisible}
          overlay={overlay}
          trigger={isMobile ? ['click'] : ['hover']}
        >
          {children(unread ?? internalUnread)}
        </HeaderDropdown>
        <HistoryMessage
          visible={historyVisible}
          onVisibilityChange={setHistoryVisible}
        />
      </TodoGlobalViewerProvider>
    </>
  );
};

export default MessageTabs;
