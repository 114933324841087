import { useMemo } from 'react';

export enum StudentPointType {
  /**
   * 自己的案件 累计积分
   */
  OwnCase = 'own-case',

  /**
   * 邀请的客户赠送积分
   */
  InviteeCase = 'invitee-case',

  /**
   * 手工添加
   */
  ManuallyAdded = 'manually-added',

  ApplyUseDeduct = 'apply-use-deduct',

  ApplyUseReturn = 'apply-use-return',
}

export const useStudentPointTypeEnum = () => {
  return useMemo(
    () =>
      new Map([
        [StudentPointType.OwnCase, { text: '案件奖励', color: 'blue' }],
        [
          StudentPointType.InviteeCase,
          { text: '邀请客户完成案件', color: 'gold' },
        ],
        [StudentPointType.ManuallyAdded, { text: '手动添加', color: 'green' }],
        [
          StudentPointType.ApplyUseDeduct,
          { text: '积分使用扣除', color: 'red' },
        ],
        [
          StudentPointType.ApplyUseReturn,
          { text: '积分使用退回', color: 'magenta' },
        ],
      ]),
    [],
  );
};
