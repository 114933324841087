import { CloseOutlined } from '@ant-design/icons';
import React, { memo, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { lock, unlock } from 'tua-body-scroll-lock';

const STYLE_INNER: React.CSSProperties = {
  zIndex: 2000,
  left: 0,
  top: 0,
  position: 'fixed',
  minHeight: '100vh',
  width: '100%',
  background: '#fff',
  overflow: 'auto',
};

const STYLE_CLOSE: React.CSSProperties = {
  position: 'absolute',
  fontSize: 24,
  right: 20,
  top: 20,
  cursor: 'pointer',
};

export interface UnknownFileViewerProps {
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  maskStyle?: React.CSSProperties;
  mask?: boolean;
  visible: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const UnknownFileViewer: React.FC<UnknownFileViewerProps> = ({
  style,
  onClose,
  visible,
  children,
  innerStyle,
}) => {
  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (visible) {
      lock(document.body);
    }

    return () => unlock(document.body);
  }, [visible]);

  return visible
    ? ReactDOM.createPortal(
        <div
          key="file-viewer-custom-inner"
          style={{ ...STYLE_INNER, ...innerStyle }}
        >
          <CloseOutlined style={STYLE_CLOSE} onClick={handleClose} />
          <div style={style}>{children}</div>
        </div>,
        document.body,
      )
    : null;
};

UnknownFileViewer.defaultProps = {
  style: {},
  innerStyle: {},
};

export default memo(UnknownFileViewer);
