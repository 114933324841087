import SentryErrorBoundary from './SentryErrorBoundary';

export { default as initSentry } from './init';

const InternalSentryApp: React.FC = ({ children }) => {
  if (APP_ENV === 'development') {
    return <>{children}</>;
  }

  return <SentryErrorBoundary>{children}</SentryErrorBoundary>;
};

export const SentryApp = InternalSentryApp;
