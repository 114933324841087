import ProForm, { ProFormText } from '@ant-design/pro-form';
import { useUnmountedRef } from 'ahooks';
import { Button, Space, notification } from 'antd';
import classnames from 'classnames';
import { isArray } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import CustomDragUpload from '@/components/CustomDragUpload';
import WangEditor from '@/components/WangEditor';
import { useCommonRules, useFormatMessage } from '@/hooks';
import { normalizeUploadValue } from '@/utils';
import styles from '../index.less';
import EmailActionType from './EmailActionType';
import EmailAddressInput from './EmailAddressInput';
import EmailEditorModal from './EmailEditorModal';
import useUploadAttachments from './useUploadAttachments';
import { getEmailContent, getEmailSubject } from './utils';

const FormItem = ProForm.Item;

export interface EmailInfo {
  subject?: string;
  content: string;
  toFull: { email: string }[];
  from: string;
  time: string;
  name?: string;

  attachments?: { name?: string; url: string }[];
}

const EmailEditor: React.FC<{
  visible?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  onSubmit?: (values: AnyObject) => Promise<unknown>;
  emailInfo?: EmailInfo;
  type?: string;
}> = ({ visible, onCancel, emailInfo, onSubmit, onOk, type }) => {
  const { formatMessage } = useFormatMessage();
  const { textLengthRule, inputRules } = useCommonRules();
  const [toFocused, setFocused] = useState(false);
  const [form] = ProForm.useForm();
  const [submitting, setSubmitting] = useState(false);
  const unmountedRef = useUnmountedRef();
  const [
    attachments,
    { trigger, setAttachments, renderAttachments, attachmentsValidators },
  ] = useUploadAttachments();
  const submitToken = useRef(0);

  const createModeTitle = formatMessage('新邮件');
  const [subjectVisible, setSubjectVisible] = useState(true);
  const [title, setTitle] = useState(createModeTitle);

  const createMode = !emailInfo;
  const toLabel = formatMessage('收件人');
  const subjectBuiltin = !subjectVisible && !createMode;

  useEffect(() => {
    if (!createMode && type) {
      form.setFieldsValue({
        type,
      });
      setTitle(getEmailSubject(type, emailInfo.subject));
      setSubjectVisible(false);

      if (type === 'reply') {
        form.setFieldsValue({
          toEmail: [emailInfo?.from],
        });
      }
    } else {
      setSubjectVisible(true);
      setTitle(createModeTitle);
    }
  }, [form, createMode, emailInfo, createModeTitle, type]);

  useEffect(() => {
    if (emailInfo) {
      form.setFieldsValue({
        htmlBody: getEmailContent(
          form.getFieldValue('type'),
          emailInfo.content,
          emailInfo,
        ),
      });

      setAttachments(normalizeUploadValue(emailInfo.attachments));
    }
  }, [emailInfo, form, setAttachments]);

  const handleSubmit = async (values: AnyObject) => {
    if (!submitToken.current) {
      return;
    }

    submitToken.current--;

    try {
      setSubmitting(true);

      await onSubmit?.({
        ...values,
        subject: values?.subject || title,
        attachments: attachments?.map((item) => item.url),
      });
      setAttachments(undefined);

      onOk?.();
      notification.success({
        message: formatMessage('发送成功'),
      });
    } finally {
      if (!unmountedRef.current) {
        setSubmitting(false);
      }
    }
  };

  return (
    <EmailEditorModal visible={visible} onClose={onCancel} title={title}>
      <ProForm
        preserve={false}
        onFinish={handleSubmit}
        onValuesChange={(changed) => {
          if ('type' in changed) {
            const { type: currentType } = changed;

            if (currentType === 'editSubject') {
              setSubjectVisible(true);
              form.setFieldsValue({
                subject: title,
              });
            } else {
              /**
               * 这时候 content 一定存在
               * 这时候要改变标题和内容
               */
              setTitle(getEmailSubject(currentType, emailInfo!.subject));
            }
          }
        }}
        form={form}
        submitter={false}
        layout="horizontal"
        requiredMark={false}
        colon={false}
      >
        <div className={styles.bodyPadding}>
          <div
            className={classnames({
              [styles.flex]: subjectBuiltin,
            })}
          >
            {subjectBuiltin && (
              <FormItem
                name="type"
                style={{
                  marginLeft: -16,
                }}
              >
                <EmailActionType />
              </FormItem>
            )}

            <FormItem
              name="toEmail"
              style={{
                flex: 1,
              }}
              rules={[
                {
                  required: true,
                  type: 'array',
                  validator: async (_, input) => {
                    if (!(isArray(input) && input[0])) {
                      throw new Error(inputRules[0].message as string);
                    }
                  },
                  message: inputRules[0].message,
                },
              ]}
              label={toFocused ? toLabel : null}
            >
              <EmailAddressInput
                bordered={false}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                placeholder={toFocused ? undefined : toLabel}
              />
            </FormItem>
          </div>
          {subjectVisible && (
            <ProFormText
              name="subject"
              rules={[...inputRules, textLengthRule(4, 250)]}
              placeholder={formatMessage('主题')}
              fieldProps={{
                bordered: false,
              }}
            />
          )}
        </div>
        <CustomDragUpload
          value={attachments}
          fileSize={10 * 1024 * 1024}
          onChange={setAttachments}
          dragPopupTitle={formatMessage('拖拽要上传的附件文件')}
        >
          <FormItem
            rules={[...attachmentsValidators, ...inputRules]}
            className={styles.editorFormItem}
            name="htmlBody"
          >
            <WangEditor
              toolbarPosition="bottom"
              renderExtraToolbar={() => (
                <Space size={4}>
                  <Button
                    style={{
                      padding: '4px 15px',
                      marginRight: 4,
                    }}
                    type="primary"
                    onClick={() => {
                      submitToken.current++;
                      form.submit();
                    }}
                    loading={submitting}
                    htmlType="button"
                  >
                    {formatMessage('发送')}
                  </Button>
                  {trigger}
                </Space>
              )}
              classNames={{
                content: styles.editorBody,
              }}
              footer={renderAttachments()}
              border={false}
              image
              simple
            />
          </FormItem>
        </CustomDragUpload>
      </ProForm>
    </EmailEditorModal>
  );
};

export default EmailEditor;
