import { useMemo } from 'react';
import useFormatMessage from '@/hooks/useFormatMessage';

export enum EmailSendStatus {
  FAILED = 0,
  SEND_SUCCESS = 1,
  DELIVERED = 2,
  BOUNCED = 3,
  READ = 4,
}

export const useEmailStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum = {
      [EmailSendStatus.FAILED]: {
        status: 'error',
        text: formatMessage('发送失败'),
      },
      [EmailSendStatus.SEND_SUCCESS]: {
        status: 'processing',
        text: formatMessage('邮件投递中'),
      },
      [EmailSendStatus.DELIVERED]: {
        status: 'success',
        text: formatMessage('邮件送达'),
      },
      [EmailSendStatus.BOUNCED]: {
        status: 'warning',
        text: formatMessage('邮件退回'),
      },
      [EmailSendStatus.READ]: {
        status: 'success',
        text: formatMessage('邮件已读'),
      },
    } as const;

    return new Map(
      Object.entries(statusEnum).map((item) => [Number(item[0]), item[1]]),
    );
  }, [formatMessage]);
};
