import { useEffect } from 'react';
import { useWebsocket } from '@/components/Websocket';
import { useInterval } from '@/hooks';

const CommonWebsocket = () => {
  const websocket = useWebsocket();
  const { start, cancel } = useInterval(
    () => {
      websocket?.sendJsonMessage({
        event: 'ping',
      });
    },
    {
      loop: true,
      interval: 1000 * 5,
    },
  );

  useEffect(() => {
    start();

    return cancel;
  }, [start, cancel]);

  return null;
};

export default CommonWebsocket;
