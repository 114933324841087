import { FormatMessage, Options } from '@/types';

export const getProofOfAbroadOptions = (
  formatMessage: FormatMessage,
): Options => [
  {
    value: 'transcript',
    label: formatMessage({
      id: 'app.proof-of-abroad.transcript',
    }),
  },
  {
    value: 'graduation',
    label: formatMessage({
      id: 'app.proof-of-abroad.graduation',
    }),
  },
  {
    value: 'degree',
    label: formatMessage({
      id: 'app.proof-of-abroad.degree',
    }),
  },
  {
    value: 'completionLetter',
    label: formatMessage({
      id: 'app.proof-of-abroad.completion-letter',
    }),
  },
  {
    value: 'enrollmentLetter',
    label: formatMessage({
      id: 'app.proof-of-abroad.enrollment-letter',
    }),
  },
  {
    value: 'other',
    label: formatMessage({
      id: 'app.proof-of-abroad.other',
    }),
  },
];

export const getProofOfAbroadName = (
  value: string,
  formatMessage: FormatMessage,
) =>
  getProofOfAbroadOptions(formatMessage).find((item) => item.value === value)
    ?.label;
