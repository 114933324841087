import { BadgeProps } from 'antd';
import { useMemo } from 'react';
import useFormatMessage from '@/hooks/useFormatMessage';

export enum SystemUpdateStatus {
  DRAFT = 0,
  PREPARE = 1,
  RELEASING = 2,
  RELEASED = 3,
}

export const useSystemUpdateStatusEnum = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    return new Map(([
      [
        SystemUpdateStatus.DRAFT,
        {
          text: formatMessage('草稿'),
        },
      ],
      [
        SystemUpdateStatus.PREPARE,
        {
          text: formatMessage('预发布'),
          status: 'warning',
        },
      ],
      [
        SystemUpdateStatus.RELEASING,
        {
          text: formatMessage('发布中'),
          status: 'processing',
        },
      ],

      [
        SystemUpdateStatus.RELEASED,
        {
          text: formatMessage('发布完成'),
          status: 'success',
        },
      ],
    ] as unknown) as [
      [
        SystemUpdateStatus,
        {
          text: string;
          status?: BadgeProps['status'];
        },
      ],
    ]);
  }, [formatMessage]);
};
