import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ProForm, {
  ProFormDependency,
  ProFormRadio,
  ProFormSelect,
} from '@ant-design/pro-form';
import { useControllableValue } from 'ahooks';
import { Button, Popover, Tooltip } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { isArray } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useCommonMessages, useCommonRules, useFormatMessage } from '@/hooks';
import ClientSelect from './ClientSelect';
import Recipient from './Recipient';
import StaffSelect from './StaffSelect';
import {
  MessageTaskRecipientType,
  usePersonnelNameEnum,
  useRecipientTypeEnum,
} from './data';
import type {
  IMessageTaskRecipientValue,
  StaffOrClientSelectRef,
} from './type';

const StaffOrClientSelect: React.FC<{
  value?: IMessageTaskRecipientValue[];
  onChange?: (value: IMessageTaskRecipientValue[]) => void;
  includeCase?: boolean;
}> = (props) => {
  const { includeCase } = props;
  const [values, setValues] = useControllableValue<
    IMessageTaskRecipientValue[] | undefined
  >(props);
  const { formatMessage } = useFormatMessage();
  const [form] = ProForm.useForm();
  const { actions } = useCommonMessages();
  const { selectRules } = useCommonRules();
  const [visible, setVisible] = useState(false);
  const { caseGroup, clientGroup, companyGroup } = usePersonnelNameEnum();
  const recipientTypEnum = useRecipientTypeEnum();
  const clientSelectRef = useRef<StaffOrClientSelectRef | null>(null);
  const staffSelectRef = useRef<StaffOrClientSelectRef | null>(null);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const currentClientList =
    (values
      ?.filter((item) => item.type === MessageTaskRecipientType.CUSTOMER)
      .map((item) => item.value) as number[]) || [];
  const currentStaffList =
    (values
      ?.filter((item) => item.type === MessageTaskRecipientType.STAFF)
      .map((item) => item.value) as number[]) || [];
  const currentPronounList =
    (values
      ?.filter((item) => item.type === MessageTaskRecipientType.PRONOUN)
      .map((item) => item.value) as string[]) || [];

  const rawPersonnelNamesEnum = {
    ...clientGroup,
    ...companyGroup,
    ...(includeCase ? caseGroup : null),
  };
  const personnelNamesEnum = Object.fromEntries(
    Object.entries(rawPersonnelNamesEnum).filter(
      (item) => !currentPronounList.includes(item[0]),
    ),
  );

  const addPersonnel = (personnelNames: string[]) => {
    const nextValues = [...(values || [])];

    nextValues.push(
      ...personnelNames
        .filter((item) => !currentPronounList.includes(item))
        .map((item) => ({
          type: MessageTaskRecipientType.PRONOUN,
          value: item,
          name: personnelNamesEnum[item],
        })),
    );

    setValues(nextValues);
  };

  const addStaffOrClient = (
    type: MessageTaskRecipientType,
    formValues: number[] | number,
  ) => {
    const nextValues = [...(values || [])];
    const ref =
      type === MessageTaskRecipientType.CUSTOMER
        ? clientSelectRef
        : staffSelectRef;

    if (!ref.current) {
      return;
    }

    const list = isArray(formValues) ? formValues : [formValues];
    const checkedList =
      type === MessageTaskRecipientType.CUSTOMER
        ? currentClientList
        : currentStaffList;
    const data = ref.current.getNames(
      list.filter((item) => !checkedList.includes(item)),
    );

    nextValues.push(
      ...(data.map((item) => ({
        type,
        ...item,
      })) as {
        type: MessageTaskRecipientType;
        name: string;
        value: number;
      }[]),
    );
    setValues(nextValues);
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 8,
        }}
      >
        <AnimatePresence>
          {values?.map((item, index) => (
            <motion.div
              key={item.value}
              initial={{
                height: 0,
              }}
              animate={{
                height: 'auto',
              }}
              transition={{
                stiffness: 50,
                damping: 600,
              }}
              exit={{ height: 0, overflow: 'hidden' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: 44,
                }}
              >
                <Recipient type={item.type} name={item.name} />
                <Tooltip title={actions.deleteBtnText}>
                  <Button
                    onClick={() => {
                      values.splice(index, 1);
                      setValues(values.slice());
                    }}
                    type="text"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <Popover
        title={formatMessage('添加参与人员')}
        onVisibleChange={setVisible}
        visible={visible}
        overlayStyle={{
          width: Math.min(375, window.innerWidth - 30),
        }}
        trigger="click"
        destroyTooltipOnHide={false}
        content={
          <ProForm
            form={form}
            onFinish={async (formValues) => {
              if (formValues.type === MessageTaskRecipientType.PRONOUN) {
                addPersonnel(formValues.personnelNames);
              } else {
                addStaffOrClient(formValues.type, formValues.values);
              }
              setVisible(false);
            }}
            onValuesChange={(changed) => {
              if ('type' in changed) {
                form.resetFields(['personnelNames', 'value', 'name']);
              }
            }}
          >
            <ProFormRadio.Group
              name="type"
              valueEnum={recipientTypEnum}
              rules={selectRules}
              label={formatMessage('选择类型')}
            />
            <ProFormDependency name={['type']}>
              {({ type }) => {
                if (!type) {
                  return null;
                }

                if (type === MessageTaskRecipientType.PRONOUN) {
                  return (
                    <ProFormSelect
                      name="personnelNames"
                      rules={selectRules}
                      label={formatMessage('参与人员')}
                      valueEnum={personnelNamesEnum}
                      fieldProps={{
                        mode: 'multiple',
                      }}
                    />
                  );
                }

                if (type === MessageTaskRecipientType.CUSTOMER) {
                  return (
                    <>
                      <ClientSelect
                        name="values"
                        label={formatMessage('添加客户')}
                        selectedValues={currentClientList}
                        ref={clientSelectRef}
                      />
                    </>
                  );
                }

                return (
                  <>
                    <StaffSelect
                      name="values"
                      label={formatMessage('添加专员')}
                      selectedValues={currentStaffList}
                      ref={staffSelectRef}
                      fieldProps={{
                        mode: 'multiple',
                      }}
                    />
                  </>
                );
              }}
            </ProFormDependency>
          </ProForm>
        }
      >
        <Button icon={<PlusOutlined />} block type="dashed">
          {formatMessage('添加参与人员')}
        </Button>
      </Popover>
    </div>
  );
};

export default StaffOrClientSelect;
