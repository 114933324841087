import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import { useCallback } from 'react';
import { useIntl } from 'umi';
import useCommonMessages from './useCommonMessages';

const useModalConfirm = () => {
  const { actions } = useCommonMessages();
  const { formatMessage } = useIntl();

  const confirm = useCallback(
    (props?: Omit<ModalFuncProps, 'onOk' | 'onCancel'>): Promise<void> =>
      new Promise((resolve, reject) => {
        const modal = Modal.confirm({
          title: props?.title || formatMessage({ id: 'app.confirm.title' }),
          content:
            props?.content || formatMessage({ id: 'app.confirm.content' }),
          okText: actions.sureBtnText,
          cancelText: actions.cancelBtnText,
          icon: <ExclamationCircleOutlined />,
          zIndex: 5000,
          ...props,
          onCancel: () => {
            modal.destroy();
            reject();
          },
          onOk: async () => {
            modal.destroy();
            resolve();
          },
        });
      }),
    [formatMessage, actions],
  );

  return { modalConfirm: confirm };
};

export default useModalConfirm;
