import {
  DownloadOutlined,
  FileUnknownOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useControllableValue, useUpdateEffect } from 'ahooks';
import { Button, Spin } from 'antd';
import React, { Suspense, memo, useCallback, useState } from 'react';
import { useIntl } from 'umi';
import Downloader from '@/PageComponents/Downloader';
import ImageViewer from '@/components/ImageViewer';
import { formatFilename } from '@/utils';
import { getFileType } from '@/utils/file';
import { PdfViewerProps } from '../PdfViewer';
import UnknownFileViewer from './UnknownFileViewer';
import styles from './index.less';

const SuspensePdfViewer = React.lazy(() => import('../PdfViewer'));
const PdfViewer: React.FC<PdfViewerProps> = (props) => (
  <Suspense
    fallback={
      <div className={styles.loading}>
        <Spin tip="loading..." />
      </div>
    }
  >
    <SuspensePdfViewer {...props} />
  </Suspense>
);

const getFilenameFromUrl = (url?: string) => {
  if (!url) {
    return '';
  }

  if (!url.includes('http')) {
    return url;
  }

  const urlObject = new URL(url);

  return urlObject.pathname.split('/').pop() ?? urlObject.pathname;
};

export interface StaticFileViewerProps {
  filename?: string | null;
  url: string | undefined;
  initialVisible?: boolean;
  children?: React.ReactNode;
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  id?: number;
}

const StaticFileViewer: React.FC<StaticFileViewerProps> = (props) => {
  const { url, filename, id } = props;
  const [visible, updateVisible] = useControllableValue<boolean>(props, {
    valuePropName: 'visible',
    defaultValuePropName: 'initialVisible',
    trigger: 'onVisibleChange',
  });
  const [pdfRotation, setPdfRotation] = useState(0);

  const name = filename || getFilenameFromUrl(url);
  const { formatMessage } = useIntl();

  useUpdateEffect(() => {
    setPdfRotation(0);
  }, [url]);
  const handleClose = useCallback(() => {
    updateVisible(false);
  }, [updateVisible]);

  const renderUnknownFileViewer = () => {
    const tip = formatMessage({ id: 'app.file.other-tip' });

    return (
      <UnknownFileViewer
        mask={false}
        innerStyle={{
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.8), #fff 60%)',
        }}
        onClose={handleClose}
        visible={!!visible}
        style={{ height: '100vh' }}
      >
        <div className={styles.customPreview}>
          <FileUnknownOutlined style={{ fontSize: 60 }} />
          <div className={styles.customPreviewTips}>
            {tip.split('\n').map((item) => (
              <div style={{ marginBottom: 10 }} key={item}>
                {item}
              </div>
            ))}
          </div>

          <Button
            href={url}
            target="_blank"
            style={{ minWidth: 100 }}
            type="primary"
          >
            {formatMessage({ id: 'app.file.download' })}
          </Button>
        </div>
      </UnknownFileViewer>
    );
  };

  const renderImageViewer = () => {
    return (
      <ImageViewer
        toolbars={
          id
            ? [
                <Downloader
                  key="download"
                  renderTrigger={({ download, downloading }) =>
                    downloading ? (
                      <LoadingOutlined />
                    ) : (
                      <DownloadOutlined onClick={() => download()} />
                    )
                  }
                  onDownload={() => ({ id })}
                />,
              ]
            : undefined
        }
        title={formatFilename(name)}
        onClose={handleClose}
        visible={visible}
        url={url!}
      />
    );
  };

  const renderPDFPrev = () => {
    const extra = [
      <h2
        key="title"
        style={{
          color: '#fff',
          marginBottom: 12,
          maxWidth: 'min(100%, 768px)',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textAlign: 'left',
        }}
      >
        {formatFilename(name)}
      </h2>,
    ];

    if (id) {
      extra.push(
        <Downloader
          key="download"
          onDownload={() => ({ id, pdfRotation })}
          trigger={
            <Button type="primary">
              {formatMessage({ id: 'app.file.download' })}
            </Button>
          }
        />,
      );
    }

    return (
      <PdfViewer
        visible={!!visible}
        onClose={handleClose}
        extra={extra}
        url={url!}
        rotation={pdfRotation}
        onRotationChange={setPdfRotation}
      />
    );
  };

  const renderViewer = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow

    if (!url) {
      return null;
    }

    const type = getFileType(url);

    if (type === 'image') {
      return renderImageViewer();
    }

    if (type === 'pdf') {
      return renderPDFPrev();
    }

    return renderUnknownFileViewer();
  };

  return renderViewer();
};

export default memo(StaticFileViewer);
