import { useUpdateEffect } from 'ahooks';
import { Avatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import React, { memo, useCallback } from 'react';

const CustomAvatar = memo(
  React.forwardRef<HTMLDivElement, Omit<AvatarProps, 'onError'>>(
    ({ children, alt, src, ...rest }, ref) => {
      const [fallback, setFallback] = React.useState(false);

      useUpdateEffect(() => {
        setFallback(false);
      }, [src]);

      const onError = useCallback(() => {
        setFallback(true);

        return false;
      }, []);

      if (!src || fallback) {
        let text = '';

        if (children) {
          if (typeof children === 'string') {
            text = children.charAt(0);
          } else if (React.isValidElement(children)) {
            text = children.props.children?.charAt?.() ?? '';
          }
        } else if (alt) {
          text = alt.charAt(0);
        }

        return (
          <Avatar ref={ref} {...rest} alt={alt}>
            {text.toUpperCase()}
          </Avatar>
        );
      }

      return (
        <Avatar ref={ref} onError={onError} alt={alt} src={src} {...rest}>
          {children}
        </Avatar>
      );
    },
  ),
);

export default CustomAvatar;
