import { useUpdateEffect } from 'ahooks';
import { Alert, Statistic } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useFormatMessage } from '@/hooks';
import styles from './MessageTaskCard.less';

const { Countdown } = Statistic;

const isShow = (time: string) => {
  const value = moment(time).diff(moment(), 'days', true);

  return value <= 2 && value > 0;
};

const MessageTaskCountDown: React.FC<{
  expectDatetime: string;
  onFinish?: () => void;
}> = ({ expectDatetime, onFinish }) => {
  const [visible, setVisible] = useState(() => isShow(expectDatetime));
  const { formatMessage } = useFormatMessage();

  useUpdateEffect(() => {
    setVisible(isShow(expectDatetime));
  }, [expectDatetime]);

  const expired = moment(expectDatetime).diff(moment(), 'hour') <= -1;

  return (
    <>
      {visible && (
        <Countdown
          className={styles.countDown}
          onFinish={onFinish}
          format="HH:mm:ss"
          value={moment(expectDatetime).valueOf()}
        />
      )}
      {expired && (
        <Alert
          type="warning"
          style={{
            padding: '4px 7px',
          }}
          message={formatMessage('发送时间已过期')}
          showIcon
        />
      )}
    </>
  );
};

export default MessageTaskCountDown;
