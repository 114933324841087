import ProForm from '@ant-design/pro-form';
import { useRequest } from 'ahooks';
import React from 'react';
import { ResponseSchema, services } from '@/api';
import { CommonSelect } from '@/common';
import { useCommonRules, useFormatMessage } from '@/hooks';

const FormItem = ProForm.Item;

const CaseSelect = React.forwardRef<
  unknown,
  React.ComponentProps<typeof FormItem> & {
    selectProps?: React.ComponentProps<typeof CommonSelect>;
    clientId?: number;
  }
>(({ selectProps, clientId, ...props }, ref) => {
  const { formatMessage } = useFormatMessage();
  const { selectRules } = useCommonRules();
  const { loading, data, run, reset } = useRequest<ResponseSchema<AnyObject[]>>(
    services.messageTask.getCaseList,
    {
      manual: true,
      debounceInterval: 150,
    },
  );

  const caseOptions = data?.data?.map((item) => ({
    label: item.caseName,
    value: item.id,
  }));

  return (
    <FormItem rules={selectRules} {...props}>
      <CommonSelect
        onSearch={(value) => {
          const keyword = value?.trim();
          if (!keyword || keyword.length < 2) {
            reset();
          } else {
            run({ keyword, customerId: clientId });
          }
        }}
        loading={loading}
        options={caseOptions}
        placeholder={formatMessage('输入案件关键字搜索')}
        {...selectProps}
      />
    </FormItem>
  );
});

export default CaseSelect;
