import { RightOutlined } from '@ant-design/icons';
import ProList from '@ant-design/pro-list';
import { ActionType } from '@ant-design/pro-table';
import { useSize, useUpdateEffect } from 'ahooks';
import { Button, Space } from 'antd';
import classNames from 'classnames';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useModel } from 'umi';
import { ResponseSchema, services } from '@/api';
import { IAccessable } from '@/components/Accessable';
import { MessageTaskScope } from '@/data';
import {
  useFormatMessage,
  useModalConfirm,
  useModalFormActions,
} from '@/hooks';
import { getPaginationTableData } from '@/utils';
import MessageTaskCard, { ITaskItem } from './MessageTaskCard';
import MessageTaskForm, { MessageTaskFormProps } from './MessageTaskForm';
import styles from './MessageTaskList.less';

type TaskFormDataParams = Pick<
  MessageTaskFormProps,
  'currentCase' | 'currentClient'
>;
/**
 * 获取不同场景下的参数
 * 1.  Dashboard (all)
 * 2.  Client
 * 3.  Case
 */
const getActionAndParams = (
  formData: TaskFormDataParams | undefined,
  scope: MessageTaskScope,
): {
  requestParams?: AnyObject;
  action: () => Promise<ResponseSchema<ITaskItem[]>>;
} => {
  if (scope === 'case' && formData?.currentCase) {
    return {
      requestParams: { id: formData?.currentCase?.id },
      action: services.messageTask.getCaseTaskList,
    };
  }

  if (scope === 'client' && formData?.currentClient) {
    return {
      requestParams: { id: formData?.currentClient?.id },
      action: services.messageTask.getClientTaskList,
    };
  }

  return {
    action: services.messageTask.getAllTaskList,
  };
};

const getLayouts = (width: number, cardMinWidth = 440) => {
  const gutter = 24;
  const column = Math.floor((width + gutter) / (cardMinWidth - gutter));

  if (column === 0) {
    return {
      gutter,
      column: 0,
    };
  }

  return {
    gutter,
    column: Math.min(4, column),
  };
};

const MessageTaskList = React.forwardRef<
  ActionType | undefined,
  {
    formData?: TaskFormDataParams;
    scope?: MessageTaskScope;
    collapsible?: boolean;
    className?: string;
    extra?: React.ReactNode;
  }
>(({ formData, collapsible, className, extra, scope = 'all' }, ref) => {
  const actionRef = useRef<ActionType>();
  const { formatMessage } = useFormatMessage();
  const [{ open, ...modalFormParams }] = useModalFormActions({
    onOk() {
      actionRef.current?.reload();
    },
  });
  const [collapsed, setCollapsed] = useState(!!collapsible);
  const listRef = useRef<HTMLDivElement>(null);
  const { width = 0 } = useSize(listRef);
  const { modalConfirm } = useModalConfirm();
  const { companyTimezone } =
    useModel('@@initialState')?.initialState?.currentUser?.information ?? {};

  const { requestParams, action } = getActionAndParams(formData, scope);
  const toolBarRender = () => [
    <IAccessable key="create" authority="front.messageTask.job.create">
      <Button
        onClick={async () => {
          if (!companyTimezone) {
            modalConfirm({
              content: formatMessage('尚未设置公司时区，请联系公司管理人员'),
              type: 'warn',
              cancelButtonProps: { style: { display: 'none' } },
            });
          } else {
            open(undefined);
          }
        }}
        type="primary"
      >
        {formatMessage('创建任务')}
      </Button>
    </IAccessable>,
    extra,
  ];

  const renderTitle = () => {
    const text = formatMessage('消息任务');

    if (!collapsible) {
      return text;
    }

    return (
      <Space
        align="center"
        role="button"
        tabIndex={0}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        style={{ cursor: 'pointer' }}
      >
        <RightOutlined className={styles.icon} />
        {text}
      </Space>
    );
  };

  useUpdateEffect(() => {
    if (!collapsible) {
      setCollapsed(false);
    }
  }, [collapsible]);

  useImperativeHandle(ref, () => actionRef.current);

  return (
    <>
      <div
        className={className}
        ref={listRef}
        style={{ opacity: width === 0 ? 0 : 1 }}
      >
        <ProList<ITaskItem>
          rowKey="id"
          className={classNames(styles.card, collapsed && styles.collapsed)}
          key="message-list"
          actionRef={actionRef}
          headerTitle={renderTitle()}
          toolBarRender={toolBarRender}
          rowSelection={false}
          // @ts-ignore
          request={(params) => getPaginationTableData(params, action)}
          params={requestParams}
          search={false}
          pagination={{}}
          grid={getLayouts(width)}
          onLoad={(current) => {
            if (collapsible && collapsed) {
              setCollapsed(current.length === 0);
            }
          }}
          renderItem={(item) => (
            <MessageTaskCard
              onRefresh={() => actionRef.current?.reload()}
              onEdit={open}
              onDelete={() => actionRef.current?.reload()}
              scope={scope}
              data={item as ITaskItem}
              key={item.id}
            />
          )}
        />
      </div>
      <MessageTaskForm scope={scope} {...formData} {...modalFormParams} />
    </>
  );
});

export default MessageTaskList;
