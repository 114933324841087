import {
  DeleteOutlined,
  EyeOutlined,
  FileOutlined,
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Upload, message, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/es/modal/style';
import 'antd/es/slider/style';
import type {
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/lib/upload/interface';
import { escapeRegExp, omit, set } from 'lodash';
import React from 'react';
import { ResponseSchema, SUCCESS_CODE } from '@/api';
import { MAX_COMPRESSED_FILE_SIZE, MAX_FILE_SIZE } from '@/constant';
import { ossUploader } from '@/core';
import type { OSSUploaderUploadParams } from '@/core';
import { getFileAccepts, getFileType, normalizeUploadValue } from '@/utils';
import FileViewer from '../StaticFileViewer';
import PdfFileCompressionPopup from './PdfFileCompressionPopup';
import styles from './Uploader.less';

const { Dragger } = Upload;

type UploaderResp = ResponseSchema<{
  fileUrl: string;
}>;

type ImgCropProps = Omit<React.ComponentProps<typeof ImgCrop>, 'children'>;

const defaultNormalize = (resp: UploaderResp): string => resp.data.fileUrl;

export interface UploaderProps
  extends OSSUploaderUploadParams,
    Pick<
      UploadProps,
      | 'beforeUpload'
      | 'itemRender'
      | 'accept'
      | 'style'
      | 'className'
      | 'listType'
      | 'iconRender'
    > {
  enablePreview?: boolean;
  enableDrag?: boolean;
  singleMode?: boolean;
  fileNum?: number;
  onUploadSuccess?: (resp: UploaderResp) => void;
  successTips?: React.ReactNode;
  help?: React.ReactNode;
  normalize?: (resp: UploaderResp) => string;
  showUploadList?: boolean;
  value?: UploadFile[];
  initialValue?: UploadFile[];
  onChange?: (file?: UploadFile[]) => void;
  // 触发校验
  onCompleted?: (value: UploadFile[]) => void;
  inline?: boolean;
  multiple?: boolean;
  crop?: boolean;
  children?: (loading: boolean) => React.ReactNode;
  cropProps?: ImgCropProps;
  acceptDocx?: boolean;
  acceptImage?: boolean;
  readonly?: boolean;
  enablePdfCompress?: boolean;
  pdfMaxFileSize?: number;
}

interface UploaderState {
  showPreviewLayer: boolean;
  previewVisible: boolean;
  loading: boolean;
  previewImage: string;
  fileList: UploadFile[];
  pdfCompressionPopupVisible: boolean;
}

export default class Uploader extends React.PureComponent<
  UploaderProps,
  UploaderState
> {
  static defaultProps: Partial<UploaderProps> = {
    enablePreview: true,
    singleMode: false,
    enableDrag: true,
    listType: 'picture',
    showUploadList: true,
    onUploadSuccess: () => undefined,
    successTips: 'Upload success!',
    normalize: defaultNormalize,
    help: (
      <>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </>
    ),
  };

  constructor(props: UploaderProps) {
    super(props);

    this.state = {
      showPreviewLayer: false,
      loading: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      pdfCompressionPopupVisible: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: UploaderProps,
    prevState: UploaderState,
  ) {
    const { value, readonly, enablePreview, fileNum = 1 } = nextProps;

    if ('value' in nextProps && value !== prevState.fileList) {
      const files = normalizeUploadValue(value);
      const [currentFile] = files ?? [];

      const isAutoShowPdfCompressPopup =
        fileNum === 1 &&
        enablePreview &&
        readonly &&
        currentFile &&
        getFileType(currentFile.name) === 'pdf';

      return {
        fileList: files,
        pdfCompressionPopupVisible: isAutoShowPdfCompressPopup
          ? !!currentFile.size && currentFile.size >= MAX_FILE_SIZE
          : prevState.pdfCompressionPopupVisible,
      };
    }

    return null;
  }

  componentDidUpdate() {
    const { fileList } = this.state;
    const { value, onChange } = this.props;

    if (value !== fileList) {
      if (onChange) {
        onChange(fileList);
      }
    }
  }

  handlePrevView = (file: string | AnyObject, e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({
      previewImage: typeof file === 'string' ? file : file.url || file.thumburl,
      previewVisible: true,
    });
  };

  handlePreviewCancel = () => {
    this.setState({
      previewVisible: false,
    });
  };

  handleMouseEnter = () => {
    const { enablePreview } = this.props;

    if (!enablePreview) {
      return;
    }

    this.setState({
      showPreviewLayer: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      showPreviewLayer: false,
    });
  };

  handleDelete = (image: string, evt?: React.MouseEvent) => {
    const { onChange } = this.props;
    const { fileList } = this.state;
    const nextFileList = fileList.filter((item) => item.url !== image);

    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    onChange?.(nextFileList);

    if (!('value' in this.props)) {
      this.setState({
        fileList: nextFileList,
      });
    }
  };

  renderSingleImage = (image: string) => {
    const { showPreviewLayer, fileList } = this.state;
    const { readonly } = this.props;
    const isImage = /\.(?:png|jpe?g|svg)/.test(image);
    const rendered = !!(showPreviewLayer && fileList.length);

    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className={styles.uploaderSingle}
      >
        {isImage ? (
          <div
            className={styles.uploaderImg}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        ) : (
          <div className={styles.uploaderImg}>
            <div style={{ display: 'flex' }}>
              <FileOutlined style={{ fontSize: 20 }} />
              <span>{image}</span>
            </div>
          </div>
        )}
        {rendered && (
          <div className={styles.uploaderPreview}>
            <EyeOutlined onClick={(e) => this.handlePrevView(image, e)} />
            {!readonly && (
              <DeleteOutlined
                onClick={(evt) => this.handleDelete(image, evt)}
                style={{ marginLeft: 10 }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  // eslint-disable-next-line react/sort-comp
  renderPreviewModal() {
    const { enablePreview } = this.props;
    const { previewImage, previewVisible } = this.state;

    if (!enablePreview || !previewVisible) {
      return null;
    }

    return (
      <FileViewer
        onVisibleChange={this.handlePreviewCancel}
        url={previewImage}
        visible
      />
    );
  }

  onChange = (info: UploadChangeParam<UploadFile<UploaderResp>>) => {
    const { status } = info.file;
    const {
      successTips,
      onUploadSuccess,
      normalize,
      onCompleted,
      enablePdfCompress,
      fileNum = 1,
    } = this.props;
    const removedStatus = ['removed', 'error'];

    let failedMsg = 'Upload failed';
    let isFailed;

    const fileList = info.fileList

      .map((file) => {
        const resp = file.response;

        if (resp && resp.code === SUCCESS_CODE) {
          file.url = normalize!(resp);
          file.status = 'done';

          if (onUploadSuccess) {
            onUploadSuccess(resp);
          }

          return file;
        }
        if ((resp && resp.code !== SUCCESS_CODE) || file.status === 'error') {
          isFailed = true;

          if (resp) {
            failedMsg = resp.msg || 'Upload failed!';
          }
        }

        if (
          (file.status && removedStatus.includes(file.status)) ||
          (resp && resp.code !== SUCCESS_CODE)
        ) {
          return null;
        }

        return file;
      })
      .filter(Boolean) as UploadFile[];

    const completedFileList = fileList.filter(
      (item) => item?.status === 'done',
    );

    // 上传完成触发校验
    if (
      completedFileList.length === info.fileList.length &&
      completedFileList.length > 0
    ) {
      onCompleted?.(fileList);

      const [file] = completedFileList;

      if (
        fileNum === 1 &&
        enablePdfCompress &&
        completedFileList.length === 1 &&
        file.name &&
        getFileType(file.name) === 'pdf' &&
        (file.size ?? 0) >= MAX_FILE_SIZE
      ) {
        this.setState((prev) => ({
          ...prev,
          pdfCompressionPopupVisible: true,
        }));
      }
    }

    this.setState((prev) => ({
      ...prev,
      loading: status === 'uploading',
    }));

    this.notifyChange(fileList);

    if (status === 'done' && !isFailed) {
      notification.success({ message: successTips });
    } else if (status === 'error' || isFailed) {
      notification.error({ message: failedMsg });
    }
  };

  notifyChange = (nextFileList: UploadFile[]) => {
    const { onChange } = this.props;

    onChange?.(nextFileList);

    if (!('value' in this.props)) {
      this.setState((prev) => ({
        ...prev,
        fileList: nextFileList,
      }));
    }
  };

  customRequest = async ({
    onError,
    onSuccess,
    file,
  }: Parameters<NonNullable<UploadProps['customRequest']>>['0']) => {
    const { extraData, clientId } = this.props;

    try {
      const data = await ossUploader.upload<
        ResponseSchema<{ fileUrl: string }>
      >(file, { clientId, extraData });

      onSuccess?.(data);
    } catch (err: any) {
      onError?.(err);
    }
  };

  /**
   * check file
   */
  beforeUpload: UploadProps['beforeUpload'] = async (file, list) => {
    const { beforeUpload, enablePdfCompress, pdfMaxFileSize } = this.props;

    if (
      ['.pic', '.svg'].some((item) =>
        file.name.match(new RegExp(`${escapeRegExp(item)}$`)),
      )
    ) {
      return Upload.LIST_IGNORE;
    }

    const fileType = getFileType(file.name);

    if (file.size) {
      const currentMaxFileSize =
        pdfMaxFileSize && fileType === 'pdf'
          ? pdfMaxFileSize
          : fileType === 'pdf' && enablePdfCompress
          ? MAX_COMPRESSED_FILE_SIZE
          : MAX_FILE_SIZE;
      const isLimit = file.size >= currentMaxFileSize;

      if (isLimit) {
        message.error(
          `File must smaller than ${currentMaxFileSize / 1024 / 1024}MB!`,
        );

        return Upload.LIST_IGNORE;
      }
    }

    if (beforeUpload) {
      const result = await beforeUpload(file, list);

      if (result === false) {
        return Upload.LIST_IGNORE;
      }

      return result;
    }

    return true;
  };

  handleIgnoreCompressPdf = () => {
    // 开启 pdf 压缩，必定文件数量为 1

    this.notifyChange([]);
    this.setState((prev) => ({
      ...prev,
      pdfCompressionPopupVisible: false,
    }));
  };

  handleCompressSuccess = (
    compressedFileUrl: string,
    compressedFileSize: number,
  ) => {
    const { fileList } = this.state;
    const [currentFile] = fileList;

    // 开启 pdf 压缩，必定文件数量为 1

    currentFile.url = compressedFileUrl;
    currentFile.response =
      currentFile.response &&
      set(currentFile.response, 'data.fileUrl', compressedFileUrl);
    currentFile.size = compressedFileSize;

    this.notifyChange([currentFile]);

    this.setState((prev) => ({
      ...prev,
      pdfCompressionPopupVisible: false,
    }));
  };

  render() {
    const {
      children,
      className,
      enableDrag,
      singleMode,
      inline,
      listType,
      showUploadList,
      help,
      crop,
      cropProps,
      readonly,
      accept,
      acceptDocx,
      acceptImage = true,
      fileNum = 1,
      ...rest
    } = this.props;

    const { fileList, loading, pdfCompressionPopupVisible } = this.state;
    const Container = !singleMode && enableDrag ? Dragger : Upload;

    const uploaderProps: UploadProps = {
      onPreview: this.handlePrevView,
      beforeUpload: this.beforeUpload,
      onChange: this.onChange,
      customRequest: this.customRequest,
      showUploadList: singleMode ? false : !!showUploadList,
      listType: !singleMode ? listType : 'picture-card',
      fileList,
      accept: getFileAccepts(acceptImage, !!acceptDocx, accept),
      maxCount: fileNum,
      disabled: readonly,
    };

    // 获得当前图片
    const [currentFile] = fileList ?? [];
    const currentFileUrl = currentFile && currentFile?.url;

    const uploadButton = (
      <div className={styles.uploaderBtn}>
        {React.createElement(
          loading && !inline ? LoadingOutlined : PlusOutlined,
          {
            style: {
              fontSize: 32,
            },
          },
        )}
        <div className="ant-upload-text">
          Upload{!inline && loading ? '...' : ''}
        </div>
      </div>
    );
    const defaults = (
      <>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        {typeof help === 'function' ? help() : help}
      </>
    );
    const uploadChildren = inline
      ? uploadButton
      : singleMode
      ? currentFileUrl
        ? this.renderSingleImage(currentFileUrl)
        : uploadButton
      : (!!children && children(loading)) || defaults;

    const uploader = (
      // @ts-ignore
      <Container
        {...omit(rest, [
          'onChange',
          'enablePreview',
          'fileList',
          'normalize',
          'id',
          'extraData',
          'beforeUpload',
          'enablePdfCompress',
          'pdfMaxFileSize',
        ])}
        {...uploaderProps}
        className={className}
      >
        {uploadChildren}
      </Container>
    );

    return (
      <>
        {crop || cropProps ? (
          <ImgCrop {...cropProps}>{uploader}</ImgCrop>
        ) : (
          uploader
        )}
        {this.renderPreviewModal()}
        {pdfCompressionPopupVisible && !!currentFileUrl && (
          <PdfFileCompressionPopup
            fileUrl={currentFileUrl}
            fileSize={currentFile.size ?? 0}
            onCompressSuccess={this.handleCompressSuccess}
            onIgnoreCompress={this.handleIgnoreCompressPdf}
            maxFileSize={MAX_FILE_SIZE}
          />
        )}
      </>
    );
  }
}
