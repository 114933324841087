export default {
  '4235': 'Click',
  '84543': 'Please check the visa expired date',
  '262591':
    "The applicant may be required to attend an Australian Government Office for an interview. Which is the closest office to the applicant's current location?",
  '6wG': 'On',
  '5M9': 'No',
  '5AO': 'Off',
  '5EW': 'sub',
  '71P': 'Yes',
  '6uo': 'year',
  '75p': 'Have',
  '3TKQ': 'No',
  '3tpD': 'Age',
  '5b5L': 'Run',
  '5Ne': 'Weeks',
  '3DXh': 'Add',
  '30zW': 'All',
  if4: 'CoE',
  '4sYD': 'Visa',
  '57CK': 'Skip',
  '3mM1': 'Paid',
  '5cdL': 'Pass',
  '3xev': 'Open',
  '5jBO': 'Link',
  '32xc': 'Name',
  '2SBq': 'Save',
  '4AUb': 'Edit',
  '4rv6': 'Type',
  '3KL6': 'View',
  '51KT': 'Bill',
  '3poD': 'Open',
  '5pju': 'Hide',
  '34DB': 'Name',
  '3tw6': 'Open',
  '2XNX': 'Join',
  '3Hoi': 'Show',
  '54fv': 'Cost',
  bCPg: '单元',
  '35EI': 'Send',
  '3qYA': 'Read',
  '3GGX': 'More',
  '54hb': 'Path',
  '3JlQ': 'Find',
  '3Nmb': 'Title',
  '32UG': 'Agree',
  '4Ip1': 'Draft',
  '3jbr': 'Guest',
  '5kTG': 'Annex',
  '5c4G': 'Email',
  '3jA9': 'Audit',
  '3IPc': 'Cases',
  '58YX': 'Email',
  '1bLdYv': 'Use',
  '3Y4m': 'Empty',
  vv0Ao: 'Name',
  '4ffc': 'Login',
  '3Lm3': 'Label',
  '2Tl3': 'Theme',
  '38o0': 'Reply',
  '4pGI': 'Title',
  '3wIT': 'Total',
  '5fzh': 'Retry',
  '9Cs': 'Reading',
  '5Br': 'Writing',
  Mqgjz: 'Weeks',
  '5eIe': 'Reset',
  Z19kFqJ: 'I,',
  xyMXr: 'HE',
  '3KDV': 'Unread',
  '2cde85': 'Case',
  '3iYD': 'Finish',
  '1qqAv': 'Next',
  '3krV': 'Unfold',
  '3AtL': 'Reject',
  '2W1Y': 'Others',
  '3JmG': 'Normal',
  '4twB': 'Urgent',
  '3FSu': 'Revoke',
  '47fB': 'Status',
  '3rI6': 'Gender',
  '585I': 'Bounce',
  '3zht': 'Submit',
  '2VWX': 'Global',
  '3INh': 'Campus',
  '4gov': 'cancer',
  '3D7d': 'Search',
  '3TDA': 'Active',
  '33kA': 'Cancel',
  '51WV': 'Course',
  '2XDn': 'Create',
  '2NRO': 'Upload',
  bA5s: 'VEVO',
  '2XAG': 'Global',
  '3Qvf': 'Normal',
  '5pXl': 'Korean',
  '9Cl': 'Speaking',
  '1qozP': 'Next',
  '3QIW': 'Weekly',
  '32ut': 'Delete',
  '30md': 'Assist',
  ZONq9e: 'Type',
  '5cNB': 'Invite',
  ZW3e9l: 'Years',
  '3hyp': 'Bonuses',
  '2askYi': 'Login',
  MLysb: 'Fail',
  '4Gfl': 'English',
  Z1zMDuK: 'Both',
  '3emm': 'Remarks',
  '5tKg': 'Preview',
  '3FeO': 'Invalid',
  '1R07K': 'Pause',
  '2RiX': 'General',
  '501F': 'Details',
  '2VIt': 'Balance',
  '3F9l': 'Suspend',
  '3DGE': 'Receipt',
  '5ok4': 'Faculty',
  '3j5M': 'Schools',
  '3g0t': 'Surname',
  '38OU': 'Country',
  '38W7': 'Address',
  '3pWU': 'Current',
  '3XRE': 'Tansfer',
  '2Wau': 'Content',
  '2Vqf': 'Company',
  '30IM': 'Cloning',
  '1uA9k5': 'Fail',
  '1IgIa': 'Filed',
  '3ETw': 'Journal',
  '46OM': 'Version',
  '4j98': 'Confirm',
  NP4x2: 'Spam',
  '1PAwt': 'Inbox',
  '5cFC': 'Options',
  '1Jm16': 'Bound',
  '4wL2': 'Binding',
  '3Dsb': 'Give up',
  '3zD5': 'Mention',
  '2PHC': 'Chinese',
  '35tJ': 'Visible',
  '5Mf': 'Listening',
  '3S17': 'Monthly',
  '3DBj': 'Support',
  '3BB8': 'Deposit',
  '1sC096': 'VET',
  ZHjoM5: 'Client',
  ZRg28F: 'System',
  '3GA4': 'Put away',
  '1Jho3': 'Signed',
  '1Iv8y': 'Undone',
  '3zaT': 'Passport',
  '1HPgF': 'Closed',
  NBWfs: 'Child',
  '3jbs': 'Customer',
  '4zns': 'Continue',
  '1I1Or': 'Copied',
  '52Ug': 'Language',
  '5c0U': 'Delivery',
  '30E7': 'Grouping',
  '4lGA': 'Integral',
  '1JeoV': 'Signed',
  ZjJWFl: 'using',
  '4z51': 'Position',
  '2uK0Md': 'Writing',
  '4ziY': 'Position',
  ZVoIQt: 'Nature',
  ZaYcJd: 'Active',
  '2Sjr': 'Download',
  '1wGnL': 'Sender',
  '1wJtt': 'Outbox',
  '4ofW': 'Tax rate',
  '3j1y': 'Semester',
  '3HmH': 'Nickname',
  '54Hm': 'Identity',
  Z1xwyiX: 'Email',
  '4okR': 'Equal to',
  '2d8C0': 'Points',
  '3kYc': 'Password',
  XDLff: 'company',
  ZAEWNP: 'number',
  '4cwN': 'Generate',
  ZgPgaA: 'Credits',
  '2gssDk': 'Binding',
  '1JXXv': 'Overdue',
  '1PGiu': 'Referee',
  '6aX': 'Family name',
  '5LJ': 'Given names',
  '1JfJF': 'Removed',
  '1Ir8v': 'Pending',
  '311B': 'Shut down',
  '3HHK': 'Effective',
  '33l2': 'Employees',
  '3Do6': 'New value',
  '3DQ3': 'Old value',
  '5fpv': 'Important',
  Z1u8xAP: 'Reading',
  LFW6t: 'Reason',
  '3s1J': 'pregnancy',
  '1voq5': 'Creater',
  '1wAv4': 'Keyword',
  '1HMSr': 'Created',
  Iv1z3: 'Agency',
  '1G2LX': 'Auditor',
  '3o8l': 'Cope with',
  TNAyp: 'Client',
  '3CWU': 'Documents',
  '1vdmH': 'Founder',
  '4orm': 'Dimission',
  '2DACc': 'Inviter',
  '3rt6': 'Archiving',
  '4p9R': 'Signature',
  ZfeRUt: 'Expired',
  '16v773': 'Address',
  ZxpDVq: 'Country',
  '1TlvL': 'Un-link',
  '1z1CI': 'Send to',
  '3Lz0': 'Authority',
  '2SN6': 'Unlimited',
  '23AT8o': 'Mobile',
  '3iJl': 'less than',
  '3czn': 'Duplicate',
  PRYgI: 'Prefix',
  '1IpIP': 'Rejected',
  '3BOW': 'Withdrawal',
  '1I8eH': 'Complete',
  '5ydN': 'Validation',
  '1P7aq': 'Operator',
  MLNlG: 'Success',
  '1OCk4': 'Receiver',
  '1rHo4': 'Priority',
  Z1uB1VE: 'Speaking',
  '1laC4y': 'Reopen',
  '4yYY': 'Occupation',
  TNdNc: 'Company',
  '3BFK': 'Change tie',
  '1I8Pd': 'Approved',
  '1hGu7h': 'Unbind',
  '23ql91': 'Position',
  '56DH': 'Forwarding',
  Z28uRvW: 'Email',
  Z15XAjp: 'Website',
  '1Sm7E': 'New Mail',
  '1qok5': 'Previous',
  Z2jv2kp: 'Update',
  '4yYi': 'Settlement',
  QoQGy: 'Approved',
  '28yTJ': 'In Effect',
  '2tbde': 'Cancelled',
  '1JmPv': 'Completed',
  '1j6tqf': 'Parents',
  '3elI': 'Client Name',
  '1aJXwr': 'Date to',
  Z2oL9xX: 'Position',
  '2RadX': 'Blacklist',
  '1PxqL': 'Recipient',
  '9LnA': '1 Day ago',
  '2SCL': 'Designation',
  J1VVw: 'Task log',
  ZFwSnO: 'Courses',
  Z1QtPJY: 'Listening',
  L9XIO: 'Due Date',
  '1SRMP': 'Case name',
  '2a0BN': 'Applicant',
  '1vHh8x': 'Questions',
  '3bHj': 'Be employed',
  ZCchHS: 'Success',
  '2sUMm': 'Form item',
  '1SgdP': 'Not filed',
  '1PSIc': 'File Name',
  VKx1k: 'New step',
  '1wKfj': 'To assist',
  '2CIiX': 'Operating',
  '1wIwt': 'To create',
  '3b4G': 'Nationality',
  ZoIoE7: 'ABN',
  '1JdGq': 'Separated',
  XnQzt: 'View All',
  '1qFQn': 'Invisible',
  '1qqzo': 'Next time',
  '1hrmgX': 'Received',
  '3qDE': 'Attribution',
  '3lDs': 'Tuition fee',
  '1xyki': 'Change to',
  JUC9s: 'Address',
  '4CyO': 'Translation',
  Z1wafpY: 'Library',
  WQDFr: 'Reference',
  XoxGu: 'View More',
  '1JoQt': 'To be paid',
  '1SewU': 'No started',
  '2CL9g': 'In Process',
  '1XFkVU': 'Marriage',
  Z1jxgok: 'Devoice',
  '1jwcFs': 'De facto',
  WStcE: 'Case Name',
  '3dGU': 'Declarations',
  WTQ49: 'Case Type',
  '1aK1SC': 'End time',
  RY1Ny: 'Date from',
  '1MmzI': 'Total cost',
  RXXrn: 'Date from',
  '537A': 'Subrcription',
  '9Bf7': '1 Week ago',
  '2Oji': 'Commissioner',
  '1VV3m': 'Templates:',
  Z1KrW1I: 'other?',
  OAuWa: 'Copy Link',
  Z2d53vU: 'Signature',
  '29pws': 'Percentage',
  L17WK: 'Join Time',
  Z5g7Tm: 'Pending',
  WUgd0: 'Case form',
  '117qTw': 'Add Role',
  VojHk: 'File type',
  Vn5F9: 'File size',
  '3SRK': 'Registration',
  J2Ix6: 'Task type',
  '2xD68': 'Please add',
  Jhjkp: 'Sub agent',
  '2evhg': 'Separating',
  '2SKY': 'Introduction',
  '2WLO': 'Whole School',
  '1k9ro1': 'Mail log',
  '117kO9': 'Add Visa',
  '116Dby': 'Add tags',
  WSq1g: 'Case List',
  XozOI: 'View case',
  TUWbc: 'Open Link',
  JF2MJ: 'Save data',
  '12vB9S': 'Offshore',
  '3cxf': 'Greater than',
  '1qgK2H': 'Completed',
  Zo1RqE: 'Creator',
  '3xIX': 'Mobile phone',
  VDrI1: 'New cases',
  Z22o9jO: 'Mobile',
  '1I3qL': 'Has expired',
  '2KkRV': 'Pre-release',
  WSCUp: 'Case notes',
  LzT96: 'Close Case',
  '4cMS': 'Date of Birth',
  '3AwY': 'Fail a course',
  '1ihGLa': 'Set bonus',
  '3GCD': 'Not available',
  QgkfQ: 'Home phone',
  '3UwV': 'Main language',
  Rcscx: 'Employment',
  '4Q3L': 'Industry type',
  '9LuW': '1 Day later',
  '1bAvci': 'Edit task',
  J16XG: 'Task event',
  MNqw9: 'Send email',
  OcBqk: 'Tution fee',
  '1nzbYI': 'Education',
  Zs5qa0: 'Visa type',
  Z1D4TGP: 'Occupation',
  '1k6yWY': 'Education',
  '11Kdw1': 'Test Type',
  UIxB8: 'Case Agent',
  S7Sqm: 'To do list',
  '1RMo7': 'Not created',
  KExzS: 'Share link',
  '4wO3': 'Serial Number',
  '1TnJ2': 'Not claimed',
  WTcUe: 'Case Title',
  WTkeS: 'Case Steps',
  XhWXn: 'Label file',
  '1wk5R': 'Facilitator',
  '1Q0KHn': 'Get code',
  LaBYr: 'Start Date',
  XLaAT: 'Query time',
  '19xMLC': 'Visa List',
  '1fFXSP': 'Form Type',
  VnDyh: 'File label',
  '3iqb': 'Academic Year',
  '24Fngz': 'Get Code',
  ZS3w0g: 'Association',
  QgWqD: 'System Log',
  '19zCXm': 'Visa form',
  '1bU7CU': 'Role Edit',
  Trohj: 'Quick Tool',
  '1jNGb2': 'Case List',
  MaHNI: 'Staff list',
  KEIwM: 'Share page',
  VGNV2: 'Data Right',
  heJKy: 'Type here',
  Wz4VI: 'Update Log',
  '2Rwv': 'Add a Payment',
  S0HAj: 'Start date',
  '1kwvbr': 'Last Name',
  Zo2yEp: 'PrintName',
  Z73XXi: 'Multimedia',
  RnPsy: 'Help Center',
  '1KYsW': 'To be signed',
  JGWFz: 'Transferred',
  '1G2JE': 'Under Review',
  WSBLx: 'Case Reward',
  JVnkP: 'Use Credits',
  '1ilw3q': 'Course end',
  Qj9hh: 'Timing task',
  Z2dGhf7: 'Non-english',
  PSVkq: 'Degree Type',
  QeSNN: 'School Name',
  KvCqe: 'Intake Date',
  QeQHo: 'School Name',
  '3hHa': 'Student number',
  '3Uiu': 'Price Increase',
  Wz8Ns: 'Update Time',
  KPWpH: 'Citizenship',
  '30uw': 'Classification',
  IrtPC: 'Upload file',
  '160Xbj': 'Login time',
  '16xafW': 'SMS status',
  '9Bmt': '1 Week later',
  Qnof3: 'Review Time',
  '2eRo1': 'In signature',
  '132K1T': 'Employment',
  '117SMr': 'Add Course',
  '2oa7eV': 'CoE code',
  U2oLF: 'Finish Date',
  '38Fe': 'Region/Country',
  '2yNEs': 'Please enter',
  WTvNE: 'Case status',
  IVTVC: 'Last Update',
  QgmaI: 'Client Name',
  Qh3Sa: 'Client tags',
  '1MyOa': 'Total Amount',
  '3hO8': 'Actual payment',
  '1bcqdu': 'Create New',
  '51Ny': 'Account number',
  OlJ9Y: 'Basic steps',
  '10W6J4': 'Step Title',
  '3Mtn': 'Identification',
  '1uh7m': 'Company name',
  '1FvgzA': 'Register now',
  KeaB5: 'Finish Date',
  IrzDX: 'Upload Date',
  Z1xQlpF: 'Fax Number',
  '4PSGI': 'Agency List',
  '1rmW3': 'Chinese name',
  '2nyzp': 'English name',
  VmG53: 'File upload',
  '2uD4bn': 'GTE Form',
  '1YA8Yy': 'Form 956',
  QgSpH: 'School Fees',
  ZJhB4: 'Default Cc',
  '10OD9P': 'Cost Title',
  '1O86S': 'Handling fee',
  TUBIe: 'Open device',
  '116uMq': 'Add a file',
  '1PU5k': 'File Cabinet',
  '1k9xVY': 'Relocation',
  '1k8MVH': 'Email list',
  WAHVU: 'Latest Case',
  '19yv9B': 'Visa cases',
  JUpvV: 'Year of use',
  OELfM: 'Basic level',
  '1hiyc8': 'Role level',
  KYXXa: 'Create link',
  KxY5r: 'Other cases',
  '1bAypl': 'Edit Theme',
  '1kwvzF': 'First Name',
  '1YE6BA': '956 Form',
  '1IQOiQ': 'partnership',
  Z1EY6IN: 'Are you a',
  rPSyN: 'Seminar room',
  M7ofB: 'Loading more',
  Z1Mle5Y: 'Notification',
  '1qqkK': 'Previous Step',
  '2o8Ov3': 'CoE Bonus',
  '2052Oj': 'Case failed',
  '1uRNr': 'Cold customer',
  WSpzE: 'Case closure',
  TRkp8: 'Folding menu',
  LzT5a: 'Closing Time',
  '27UO12': 'Date of Birth',
  '1Skk9': 'Validity Date',
  V6Gwy: 'Pending Case',
  '1ik36A': 'Course Name',
  Jo2xs: 'Payment Date',
  '1iW1l3': 'Fee Details',
  '1k5jOz': 'Expiry Date',
  uyT78: 'Town / City',
  Vn19l: 'File Address',
  '3v80E': '28 Days ago',
  '1sPM9': 'Not important',
  LcucG: 'Related case',
  LyFRf: 'Participants',
  '116AAL': 'Adding time',
  '1kabUy': 'Select type',
  '1LE1N': 'Total Credits',
  '17EJz8': 'Use Credits',
  W8j3L: 'Revoke audit',
  '2yPPV': 'Please select',
  '1ilvh2': 'Course code',
  '1wmci': 'Select all No',
  '1ijQWD': 'Course code',
  '1J7Hjw': 'Qualification',
  '1LqGh': 'heart disease',
  '1kyvaU': 'Postal code',
  '9DGWS': 'Loading...',
  Z1Q5k09: 'Queue date',
  '1hW1b3': 'Give reason',
  '117SD4': 'Add Account',
  '3cNj': 'Profile picture',
  '3THW': 'Dispatch orders',
  NBVoV: 'Fixed Amount',
  ITMSg: 'Chinese name',
  '1Se9n': 'Not available',
  ITKLQ: 'Chinese name',
  VQJqM: 'Pass or Fail',
  LSP2u: 'Release time',
  ZyOa9w: 'Email login',
  '1T1v3': 'Not effective',
  '1k9nnn': 'Choose file',
  '19ySz1': 'Visa status',
  Z2i25U8: 'PDF file',
  S2xbR: 'Current visa',
  XLTOh: 'Visa enquiry',
  MM1ir: 'Sending time',
  hfJWD: 'Company Name',
  '16pFGR': 'Target step',
  Lvug2: 'Cloning step',
  QsVGw: 'Student name',
  '1t3w1l': 'Default fee',
  WMrU3: 'Material Fee',
  VOC7m: 'Tax Included',
  '1k5nbK': 'Expiry Date',
  MaHL9: 'Cancel reply',
  TSLvu: 'Open adderss',
  TGhqt: 'Package file',
  QUt9d: 'Tuition type',
  Zwmyis: 'Add OSHC',
  Z1K0dWl: 'End Date',
  KnN1v: 'Company name',
  WxNih: 'Save Changes',
  '1sH1l': 'No forwarding',
  '1Kr75': 'Overall Score',
  '16p4cu': 'Client Name',
  WUozI: 'Case Details',
  Koujv: 'Company Case',
  XnMpL: 'View Company',
  '1T7SR': 'Not Confirmed',
  '116aUi': 'Add schools',
  '1k9tfE': 'Select case',
  QgiYk: 'Student List',
  '1bB2VY': 'Year Edited',
  '7YCJb': 'Template ID',
  '1reXW': 'Not Supported',
  '12wCw1': 'Academic Year',
  '1im2Hu': 'Tuition Fee',
  Ir0qz: 'Some Courses',
  JUxSe: 'User Manuals',
  '1jNCJU': 'Date lodged',
  Z2ttbaO: 'Please sign',
  '2iJpqa': 'sole trader',
  '10aRbm': 'TV & video',
  '1DV0Qo': 'Add a staff',
  '1k98i9': 'Select year',
  '2al68G': 'Visa Refusal',
  '1kaTzd': 'Email return',
  '1k9dt5': 'Message Read',
  '1M5xL': 'To be verified',
  '1xdrx': 'In publication',
  '1ikofa': 'Course Start',
  JXx5f: 'Email address',
  '1inWz7': 'Give details',
  KXo7B: 'Creation Time',
  '1fGwpp': 'Form details',
  SIFYG: 'Reinstatement',
  '19ymz2': 'Visa refusal',
  Z1FF8nc: 'Checklist',
  '1dFebi': 'English Test',
  '2oa4KG': 'CoE Number',
  '1ax0MH': 'Binding case',
  '19Tbj0': 'Exactly fees',
  Li0DX: 'Date of Brith',
  LX14M: 'Delete a file',
  KWXwN: 'Create Client',
  KXobx: 'Create a Case',
  SqbwV: 'Regular email',
  XoMe4: 'View Messages',
  '1BOFAm': 'Custom time',
  KWvtl: 'Create a task',
  '115AcS': 'Message task',
  '1K7yW': 'To be executed',
  UKRhN: 'Receive email',
  '1ii8Y1': 'Setting time',
  '116b0X': 'Add Customer',
  QnLIo: 'Review Status',
  '1k9Dup': 'Message body',
  '1ikcOl': 'Course notes',
  '1wnqY': 'Select all Yes',
  '16K5sC': 'Qualifications',
  '2o7MpR': 'Name of test',
  ZzPVTc: 'Date of test',
  Z3c6hF: 'Offense type',
  '14uS24': 'Offence type',
  '11Jrq0': 'Date of test',
  '1jsXLr': 'Funding type',
  '19xMdX': 'Visa history',
  TJtTK: 'Industry type',
  '1bCE5X': 'Edit details',
  wuKdU: '{day} Days',
  KQGhR: 'Remining time',
  jGwog: 'Further stay',
  U1yJN: 'Mobile Number',
  '1b8pOi': 'Organization',
  WUwRC: 'Case Progress',
  Qabbm: 'Import Client',
  '25yzL': 'Version number',
  QoEus: 'Audit details',
  UYyix: 'Operation log',
  WL1Cs: 'Material file',
  WSp3z: 'Case Category',
  LX6S8: 'Deletion time',
  '1l7TPV': 'Invite Staff',
  '1cXHnr': 'English name',
  '1BPNt3': 'Custom form',
  '115Rk2': 'Add Category',
  '1cXFh2': 'English name',
  '2lxrM': 'Contact person',
  '3l6v': 'Official Website',
  '170EF0': 'Confirm Join',
  '1w2HF': 'Place of Birth',
  PSm1G: 'Employer',
  '1wzRk': 'Write an email',
  XKrJc: 'Query history',
  UoecG: 'Passport List',
  CNaSQ: 'Company Stamp',
  '19kiBF': 'Signing time',
  XnRYz: 'View Contract',
  Yl9hu: 'Template name',
  '1k9F6i': 'Select steps',
  QgvRP: 'Customer name',
  VDDyE: 'Add New Steps',
  '1fGJ4m': 'Form Options',
  Lf0IM: 'Built-in form',
  '1gGBJj': 'Tutoring fee',
  '2xYBk': 'Billing number',
  '1igSlG': 'Invoice list',
  UR1ub: 'Cc Recipients',
  XotWr: 'View Document',
  '1ikfPz': 'Course Level',
  '1iVyME': 'Missing Fees',
  TPuHC: 'Size of House',
  TNdpm: 'House Address',
  '116r0p': 'Add Passport',
  '2y4iP': 'Please confirm',
  pBJHz: 'Tips Title',
  LXQ6v: 'Delete a visa',
  '11JuMb': 'Date of Test',
  '1b6Rns': 'Claim Method',
  LqWwJ: 'Category Name',
  Ymw8Y: 'Template type',
  PipcM: 'Ongoing Case',
  Xpuwn: 'View yourself',
  WSEg2: 'Case Category',
  K0pHD: 'Bond to case',
  WSg8f: 'Case Handover',
  '115Uax': 'Add Employee',
  '1jKXWb': 'Tutoring Fee',
  Z29J8ny: 'Type here',
  '115Kx6': 'Message list',
  '11IQFD': 'Test sending',
  LoHGi: 'Assist action',
  '16lkvH': 'Open Diretly',
  M1k8c: 'Merge Success',
  WTjh0: 'Case Dispatch',
  MaISH: 'Employee name',
  '1laObT': 'Search Again',
  RcPxr: 'Proof of Work',
  ZXeiLM: 'Loading more',
  KWALA: 'Create company',
  '1bsD88': 'Not approved',
  '2o8pt9': 'CoE Payment',
  Z1EiSOH: 'Login failed',
  WSUny: 'Case successed',
  ZzkVsz: 'Case Pending',
  ZzxYdj: 'Case Created',
  '1Bfzkd': 'Tuition paid',
  WSoUg: 'Case Assistant',
  ZzxYb0: 'Case Creator',
  Zv7Nj: 'Dear User,',
  MbRJD: 'Upgrade System',
  WBoHL: 'Effective date',
  ZRCb05: 'Expiry Date',
  '12Cveo': 'Click options',
  '1FOMJ': 'Non-tuition Fee',
  JoxDE: 'Payment Reason',
  JplBz: 'Payment Record',
  KlzqH: 'Other expenses',
  QmSP9: 'Audit comments',
  '2o8SA6': 'CoE tuition',
  '18yOnN': 'Date of Issue',
  LhuVT: 'Place of birth',
  Zcg4cP: 'Give details',
  Rc63N: 'Business phone',
  MbVAH: 'Employee login',
  '1bYrhS': 'Email Address',
  '1ii6sB': 'Account phone',
  '1k8FOx': 'Email Subject',
  '1kaLva': 'Email details',
  '3v881': '28 days later',
  '1oHF9u': 'Extra Credits',
  LpsM0: 'Assisting Case',
  '1kVoNM': 'Email Address',
  '1kbgeK': 'Email Preview',
  THFqu: 'Package course',
  '1ikHgg': 'Course Campus',
  X3OoB: 'General Course',
  '17NeRE': 'Industry type',
  '1b98wQ': 'Position type',
  '1ntVtE': 'Employer name',
  RjCQD: 'Current School',
  ZScNIK: 'Main language',
  Zglgz2: 'Country/area',
  '1hQK3m': 'Evidence type',
  POkLf: 'Field of study',
  Z1OPSar: 'Student Visa',
  ABEdW: 'Suburb / Town',
  MuXzP: 'Business phone',
  S2brP: 'Current Status',
  ZnbMgh: 'Create Todo',
  WymcG: 'Update content',
  '12CHRg': 'Click to Hide',
  '12B0W4': 'Click to Show',
  '162k9j': 'Related Cases',
  JotnY: 'Payment Status',
  VQ7cj: 'Settled or not',
  '1W5Q99': 'Link expired',
  UYCah: 'Operation Time',
  OlAzV: 'Current Status',
  '2Xoa': 'Division of Labor',
  Sc9UC: 'Owned customer',
  '1hpMOS': 'Set to active',
  U4wEH: 'Marking Status',
  '1ug6x': 'Commission Rate',
  LTdvP: 'Publish status',
  MMdKI: 'Send a message',
  Z1D0ggb: 'Mobile login',
  '1NXjk': 'Passport Number',
  '19yphk': 'Visa Document',
  '1jFFdd': 'Transfer Date',
  '1jFIzo': 'Transfer Date',
  Xpg9o: 'View Signature',
  UJs46: 'Receiving time',
  ZrUdnl: 'Contact Name',
  Z1bDMrV: 'Phone Number',
  '1bBzK8': 'Editing steps',
  '116tgz': 'Add operation',
  V0kT7: 'Operation type',
  '167Cyd': 'Enrolment Fee',
  '15jfyd': 'Generate Time',
  Kvz43: 'Admission date',
  '4WMl': 'Cancel a Contract',
  '1bW4mp': 'Agent Contact',
  KYG7f: 'Create invoice',
  JoCVv: 'Payment Target',
  QhdKE: 'Client message',
  '2uB91c': 'GTE Writing',
  VpMzx: 'Search members',
  VnFyp: 'document title',
  '1jzdN5': 'Input content',
  Z22Le2p: 'Start Date',
  '1qC8w': 'Agency Terminal',
  Z1eGbCB: 'Edit Tips',
  Qh467: 'Access Control',
  ZVa2c3: 'Locating...',
  LQoN6: 'Function Guide',
  '1WFhy': 'Every two weeks',
  QgdY1: 'School Profile',
  '1ilpX5': 'Qualification',
  '2AIYu': 'Credit Transfer',
  '1aymIE': 'Route Binding',
  UpEoc: 'Passport Level',
  '11JAts': 'Test template',
  '1jNBOI': 'Submit Method',
  '1PJ8I': 'Invitation code',
  Tj6ij: 'Dear Customer',
  '1DILor': 'Closed Cases',
  VqOff: 'Search results',
  WAiib: 'Latest clients',
  '12SBBs': 'Add Category',
  YVaNc: 'Weekly tuition',
  rBa7n: 'Lost the client',
  Z1uKHMC: 'Other reasons',
  '1kaXup': 'Email delivery',
  RXiov: 'Begin to assist',
  c7w3G: 'In Relationship',
  KlPPW: 'Common template',
  WSHxG: 'Case completion',
  '1kmN1x': 'Postal address',
  QdX5F: 'Completion time',
  UpJyw: 'Passport Number',
  RllIM: 'Enrolled Course',
  '2o9aTy': 'CoE Document',
  KzjuK: 'Non-Tuition Fee',
  Jo0P2: 'Payment receipt',
  PRXzN: 'Amount of bonus',
  KnjgB: 'Commission Rate',
  KWGlo: 'Create contract',
  '1MCIR': 'Current Location',
  IAYuz: 'Legal status',
  '1EREi': 'State / Province',
  KxOq9: 'Other passports',
  '19q0Vj': 'Language ability',
  WwahO: 'Country visited',
  '16w0aP': 'Content of SMS',
  KxSda: 'Other Documents',
  '116xr4': 'Message header',
  Loy61: 'Assisting agent',
  '17GOYO': 'Points Details',
  '17ELGC': 'Credit Balance',
  V75rD: 'Withdrawal bill',
  '1k9zPF': 'Message header',
  '1ikk6r': 'Course Tuition',
  WxFzn: 'Country visited',
  '1g46nn': 'blood disorder',
  '19V5QP': 'mental illness',
  '1bWMWa': 'Contact Number',
  Kplsw: 'Company Website',
  KqOPL: 'Funding details',
  pCVn4: 'Tips Details',
  Kl9Oz: 'Prefer Language',
  '12B08P': 'Click to close',
  UXVJ3: 'Operating staff',
  '17ER3l': 'Point function',
  VO8zA: 'Copy Specialist',
  '1fECeA': 'Table Variable',
  KnYkN: 'Commission Rate',
  UrlNX: 'Submission type',
  '1hpKlW': 'Set to invalid',
  '18z8fe': 'Signature time',
  P8ElP: 'Processing area',
  '15mJmA': 'Effective time',
  thkh8: 'Estimate Time',
  JqLEF: 'You have joined',
  Tk4a2: 'Forgot password',
  '1u1OC3': 'Email login',
  '1iVYPY': 'Fee evaluation',
  Olrr4: 'Basic Documents',
  ZCVwMa: 'Send to Email',
  XKWE9: 'Query succeeded',
  XLYr5: 'Inquiry results',
  V5ngS: 'Withdraw Review',
  LQ3Aj: 'Contract number',
  '1lbkpR': 'Re-application',
  '1abwio': 'System default',
  KmqNI: 'Commission list',
  '1ikuRd': 'Package Course',
  WUr1u: 'Case discussion',
  '1a9K9A': 'System message',
  Z1srSB9: 'OSHC Cost',
  QdX9B: 'Complete a case',
  JB9r2: 'Download resume',
  Vp1c4: 'Select document',
  '1ikA47': 'Course deposit',
  ZOTDwN: 'Rights Holder',
  UqirU: 'Holding license',
  '1ihxgB': 'Linked Account',
  pASvS: 'Tips Content',
  TSpRt: 'Report Document',
  VPX3H: 'Document number',
  N2ICF: 'All the Results',
  LPD1R: 'Contract Status',
  '19mK94': 'Signature Link',
  SgpFv: 'Study and Work',
  Qgjwa: 'Client contract',
  LyK3F: 'Sub-application',
  '1KeFf': 'Total commission',
  Jo1Cg: 'Payment Options',
  ZOMoRM: 'Offer List',
  '19jRVc': 'School Setting',
  '2bF80': 'Look at yourself',
  '15o2yg': 'Active program',
  Jpma3: 'Payment Details',
  M17u9: 'Merge custumors',
  UYO4Q: 'Operating steps',
  '1R99gF': 'Visa Status',
  Tx9xz: 'Successful case',
  '1NfyLT': 'Pending cases',
  gp2JX: 'Visa applicant',
  '1ij8nq': 'Billing amount',
  Z1MSuSr: 'Add Signature',
  '19iUaw': 'Contact Number',
  XpQ1c: 'View the details',
  Utff7: 'Insert Varaiable',
  '11J4FR': 'Active customer',
  VX3Ea: 'Invalid customer',
  LSpfR: 'Publish complete',
  Uo6Ji: 'Passport details',
  WSixR: 'Case Information',
  '1bWTH5': 'Contact details',
  JimXA: 'Superior channel',
  PN1G2: 'Enrolment Status',
  '1qGhaj': 'Verify identity',
  Z1WWJHh: 'CRICOS CODE',
  Z1dvXaC: 'Course Code',
  '1KDXF': 'Total tuition fee',
  KnQQB: 'Commission types',
  QoGeG: 'View the Invoice',
  Zav6hN: 'List countries',
  UqcL9: 'Passport details',
  '1NeCm': 'Mobile/Cell phone',
  WJDY7: 'Institution Name',
  Z1wLicl: 'Login browser',
  Z1gMQdN: 'SMS Recipient',
  UFhYf: 'Execution failed',
  '115Gfn': 'Message content',
  '1ikHck': 'Course Duration',
  '12wSfq': 'Australian Visa',
  '139dPL': 'Add New Course',
  oRMxf: 'Packaged Courses',
  S0Y5U: 'Current Location',
  Whhmh: 'Current location',
  Z23yhNM: 'Application ID',
  '1bCG2e': 'Edit the Course',
  Z1RiFb1: 'Reason for visit',
  '166eHM': 'Date of request',
  WbbDa: 'Education sector',
  Zf4H4S: 'Country/Region',
  '14u6V3': 'Date of Offence',
  '1Iise': 'State or Province',
  '2k1cTB': 'Company Logo',
  '1bWoaj': 'Contact Address',
  '137v4i': 'Add New Client',
  McImY: 'Cancel selection',
  '18y5oX': 'Issuing company',
  Zkk22O: 'Upload Date',
  JpurW: 'Payment Progress',
  '1l8Aha': 'Invitation Time',
  '1b9DVo': 'Role Permission',
  '1xgBT': 'Original document',
  '1neTOL': 'Attachment type',
  '19kzDb': 'Signing channel',
  '12RJrm': 'Ask me for help',
  '1d2ncn': 'Self Submission',
  '1O8Iz': 'Visa Grant Number',
  '118cJc': 'Add Attachments',
  f5Mr: 'Reset all CoE',
  '116gh3': 'Add an existing',
  '1gyAMq': 'VEVO enquiry',
  KRjdc: 'Remaining points',
  '1oIIHT': 'Extra questions',
  oD7os: 'Upload documents',
  Yn4Ey: 'Template details',
  '1387yF': 'Add a new step',
  VFVlQ: 'Data information',
  '1jbheX': 'Edit form item',
  Z1cyCKP: 'Add form item',
  '1jMLu3': 'Submit to agent',
  KngMA: 'Commission ratio',
  '1kRQh1': 'Resend an email',
  '10W69K': 'Steps Available',
  '10Wl56': 'Step completion',
  '1jzb53': 'Suppoting tools',
  LXUSI: 'Health Insurance',
  TN6g3: 'Their profession',
  ZrCYTH: 'Use this CoE',
  Iw1xF: 'Personal profile',
  OmPha: 'General settings',
  pAVPU: 'Tips Category',
  '1k8JTY': 'Select category',
  '1omcg5': 'Hide the Client',
  VnKJM: 'Document Summary',
  '1ilRUu': 'Course planning',
  Vz3X5: 'Receipt document',
  QhHbz: 'Client signature',
  Yl1Nn: 'Template content',
  '1jOd9g': 'Exit assistance',
  UrzHc: 'Submission scope',
  VeqLn: 'Incoming mailbox',
  '1k8MKu': 'Select Employee',
  '2Owgw': 'Verification code',
  '1U4sU': 'Contact Agency',
  '15jfTp': 'Generate source',
  '2a2b6': 'Look at the whole',
  '1im5RF': 'Course Tutoring',
  Zt8wfh: 'Upload failed!',
  S3aUY: 'Visa Application',
  KYG9T: 'Account Creation',
  '1hrNv5': 'Document Number',
  '1b6VEP': 'Settlement time',
  TO1d9: 'Subordinate case',
  '1oTawC': 'Add a referees',
  '1ob19a': 'Privacy mailbox',
  Kpkyk: 'Company position',
  QiKYw: 'There is no more',
  '1k8FPH': 'Choose a company',
  '1JPU7': 'Waiting for review',
  '2o8thF': 'CoE Commission',
  '1wyUKn': 'Change of mind',
  V9up7: 'Bind successfully',
  ZHk9kN: 'Offer Letter',
  '1jQ75e': 'Brother/Sister',
  '19xFje': 'Visa information',
  '19zdOv': 'Subclass of visa',
  KnP7U: 'Organisation name',
  Z1CvLE: 'View all menus',
  Z148FqQ: 'Date of Issue',
  '1ke9xJ': 'Transfer channel',
  '1k8yIK': 'Choose the agent',
  '10UG88': 'Overseas address',
  '1ilq6H': 'Curriculum grade',
  '2Idrg': 'View bonus list',
  '1JgDvy': 'Current location',
  '1hrIc8': 'Type of document',
  QelRM: 'Employment status',
  '1b9G3q': 'Position details',
  '1l8aGm': 'Invite customers',
  UoPHo: 'Passport Document',
  WSdRl: 'Case Commissioner',
  ZlDJMD: 'Course End Date',
  '116yxq': 'Message template',
  '1gweGx': 'Trigger language',
  '17Fxlk': 'Points breakdown',
  '166i4X': 'Application Date',
  '166U0i': 'Apply for points',
  '18yFoX': 'Signature failed',
  '1kb7ob': 'Email attachment',
  '1kaLno': 'Course Selection',
  '1im7rO': 'Course selection',
  ZmbTfq: 'Course Duration',
  OkHsX: 'Basic Information',
  ORABt: 'Instant Offer',
  '15j2Bs': 'Build Successful',
  '1lizDK': 'Health insurance',
  Wbgkb: 'Education history',
  Z2fzr4A: 'Legal status',
  UClwm: 'Skills assessment',
  '10RXoi': 'Language ability',
  '1Lsixm': 'Reference number',
  '1rnYXX': 'Start-End Date',
  Z1TdGnX: 'Date of Offense',
  PLIjw: 'Category of study',
  W9K2C: 'Education history',
  '1jtwlo': 'Funding for stay',
  '1RdC2V': 'Genuine Student',
  WHEdO: 'Future employment',
  '2zXIm': 'Responsible person',
  '1kvQj1': 'Purpose  of stay',
  KopEx: 'Company time zone',
  VOvQo: 'Whether to create',
  '2izifr': 'Base Information',
  '1kd5bd': 'Transfer Company',
  '1igSXF': 'Billing function',
  '1jPrjV': 'Additional steps',
  '1hpsBs': 'Set as marketing',
  LP9Jb: 'Contract document',
  Lqinj: 'Assisting manager',
  '160Key': 'Login successful',
  Z1tT5wp: 'Applied Course',
  '163VdN': 'Relevant annexes',
  VoU7u: 'Document language',
  Z2bY9dR: 'Package course',
  Z1LpwU8: 'OSHC Document',
  UvOwo: 'I want to inquire',
  '1k8yyF': 'Select mediation',
  JzLip: 'Download contract',
  '19kswi': 'Contract signing',
  X1PLH: 'Ordinary employee',
  '19jnYC': 'Agent Management',
  '1iiToF': 'Invoice currency',
  WjDnb: 'Payment of Charge',
  VYSsx: 'Invalid selection',
  TGai4: 'Packaging success',
  JAngh: 'Download documrnt',
  KHlfz: 'Insurance Company',
  '1t9q9Y': 'Contact QR code',
  LWWhL: 'Delete a passport',
  '166i8T': 'Application case',
  KoFPs: 'Commission Amount',
  WiHvH: 'Scope of services',
  WgSuB: 'Service advantage',
  '1k93AU': 'Client selection',
  S0KWu: 'Course Start Date',
  VnCy4: 'Document labeling',
  Z6uWfT: 'Marked as valid',
  Z6uYIP: 'Mark as invalid',
  '1BOFEi': 'Customized Case',
  '1bAXa5': 'Editorial school',
  '180lfB': 'Handlable area',
  J8lP5: 'Handover employee',
  QgQ88: 'School curriculum',
  '12vAD8': 'Within Australia',
  UrWPV: 'Interface request',
  '1lNw4z': 'Important notice',
  LJ0Wd: 'Original language',
  '19z1Te': 'Visa application',
  JS3Do: 'Modification Time',
  '1ihGm1': 'Invoice Template',
  '12UWqt': 'Add Subcategory',
  Spvnr: 'Archived document',
  '1lknHd': 'Result Transcript',
  Z2i1Tpv: 'PDF Templates',
  Z15pV1W: 'No new message',
  LoFn3: 'Assist the company',
  '1f5j7A': 'Cancel Zip file',
  Z25evwz: 'Login successful',
  '1shSP': 'payment in progress',
  QgbvV: 'Client Information',
  ZzdGiA: 'Case recommender',
  Z1lzMKq: 'Passport details',
  Zq1w5t: 'Purpose of travel',
  '10YcF0': 'Step notification',
  Sbn3n: 'Deferred admission',
  ZM18gy: 'Australian visa',
  '162eh2': 'Related Documents',
  Q2q1W: 'Actual amount paid',
  JpCCk: 'Tuition Fee Amount',
  R9mAC: 'Contract Created',
  '1BcfmU': 'Country/ Region of Birth',
  Z29cehy: 'Date of marriage',
  K1ESu: 'Health examination',
  Uw5YB: 'Nominating student',
  QeLNi: 'Employment history',
  bQ3RI: 'Set custom time',
  '18gzDX': 'Customer creator',
  '115G8N': 'Add Commissioners',
  '17FrN6': 'Points withdrawal',
  '18z4HM': 'Invalid signature',
  kUw1c: 'Emergency Contact',
  RcmEv: 'Working Experience',
  ZOLdNc: 'Instant Offer',
  '1zcidT': 'Total Tuition Fees',
  JETr7: 'Saved successfully',
  WU6DK: 'Postcode of campus',
  Z1nPIL1: 'Overall band score',
  Kjuu7: 'Other relationship',
  Z2o6Fom: 'About to expire',
  Z26Wod7: 'Modified status',
  S2d0V: 'ApplicationContext',
  '1iAoRz': 'Financial support',
  '1npPXA': 'Type of support',
  '1hg9ng': 'Retirement Date',
  '3Dur': 'Travelling companions',
  Kopnb: 'Commission details',
  '165MF4': 'Application notes',
  ZOKqjk: 'Offer Details',
  Vohm6: 'Document signature',
  '1xwiF': 'Issue a new edition',
  '1WheGW': 'Add basic steps',
  '1l8XKv': 'Invitation Status',
  '15nFYE': 'Scope of validity',
  LoMhP: 'assisting employee',
  Lqj1O: 'assisting position',
  L0U10: 'Successful joining',
  '1l9PY6': 'invitation record',
  SwdKB: 'Con-Current Course',
  '14XUb2': 'Employment Status',
  UlAtS: 'Grade Notification',
  '17GoWr': 'Points settlement',
  '9c9mF': 'Online Apply Form',
  KkLTT: 'Specific operation',
  '257OQh': 'Add new options',
  Z1WOVgM: 'Visa Case Form',
  Kmj85: 'Commission ceiling',
  ZKbmr: 'Default recipient',
  '1gFhe9': 'Tutoring duration',
  ZCb0VM: 'Send to customer',
  '1omsrE': 'Illegal operation',
  '18zi9I': 'Resume generation',
  fNfaL: 'Visa and Passport',
  ItbMo: 'Upload attachments',
  SIERf: 'Recovery effective',
  LYI1y: 'Delete attachments',
  Qg4K1: 'School Application',
  Z1OVb8D: 'Number of cases',
  KnKPw: 'Company assistance',
  ZrxPJD: 'Incorrect format',
  '165nrU': 'Application Agent',
  '18p9vM': 'Separation record',
  '18ohic': 'Separation status',
  '1krTVt': 'Remote assistance',
  '1jJDr1': 'Tutoring Duration',
  Z1TWEee: 'Waiting to Accept',
  '1a8OJJ': 'Inside the system',
  '1ijTN1': 'Course commission',
  WngBr: 'Not configured yet',
  JiCns: 'Previous Directory',
  ZOMNC0: 'Course end time',
  Z2dxKAa: 'Save as Picture',
  g4aQJ: 'Visa Grant Number',
  UFTWQ: 'Rejected Visa Case',
  V5Hno: 'Withdrawal Receipt',
  '1WIu36': 'Choose Position',
  LzgNe: 'School Application',
  '21O2Qj': 'Current Location',
  '1URGhq': 'Degree Certificate',
  Z1kdFYX: 'Initial Number',
  VuJRK: 'Abandon compression',
  LzaF7: 'Turn off assistance',
  MIx1e: 'Initiate assistance',
  Qhuk0: "Customer's Birthday",
  KnEt8: 'Company information',
  '1ijRrN': 'Course information',
  '19xKGD': 'Expiration of Visa',
  Uoc7H: 'Passport expiration',
  QhktQ: 'Residential address',
  K172A: 'Health declarations',
  P8t7I: 'Handling specialist',
  VgFUi: 'Recommended company',
  '1kdRAa': 'Transfer Ownership',
  L9eiP: 'Relationship status',
  Pqqkp: 'Relationship Status',
  Z1GSK7G: 'Australian Visa',
  '197iEr': 'As an applicant:',
  '1jN3oU': 'Retirement details',
  '12vhoR': 'Australian Address',
  '18ARwn': 'Customer referral',
  ZmdHOL: 'Course Start Date',
  Lc4xW: 'Associated customer',
  '1sHbq': 'Two-dimensional code',
  '17Gq9Q': 'Credits Statistics',
  '3hFj': 'Educational background',
  '12vycT': 'Australian Address',
  Jng8c: 'Payment Information',
  '165DGV': 'Institution Name',
  '11ITMM': 'Overall test score',
  ZxaOxg: 'Created a to-do',
  Z26Vvr3: 'Modified details',
  '2915JQ': 'Add message task',
  '1zokGq': 'Show Client Tags',
  IUn0E: 'Chinese description',
  Jnnti: 'Payment Certificate',
  '10YfJD': 'Step configuration',
  '1hpJOj': 'Set as copywriting',
  '18z2qS': 'Certified document',
  '1qGFkn': 'Verification code',
  '1ilue5': 'Qualification Type',
  '1hPVvb': 'Evidence Documents',
  PjON0: 'Trigger free step',
  ZBE7UV: 'Custom form item',
  Kn3jq: 'Commission document',
  '1jIQ7U': 'counseling company',
  '1bW4wu': 'Specialist contact',
  XcQiy: 'Step Not Available',
  cPbSb: 'View the step log',
  '1bcgrV': 'Continue uploading',
  Zmchlj: 'Total Course Fees',
  MatY8: 'Cancellation of use',
  '2vj8OK': 'Country of birth',
  '10Vebh': 'Overseas education',
  VnjFk: 'Filing of documents',
  '2lAIIg': 'Add English test',
  '1hQ1Rq': 'Evidence Documents',
  QggP2: 'Client Relationship',
  '1YbLn0': 'Add Relationship',
  '13SHGt': "Parent's signature",
  '18Gt68': 'Signature location',
  '1kdc60': 'Transfer Employees',
  '18ndnC': 'Departing employee',
  '18nSNQ': 'Date of separation',
  Z2qEcq: 'Email or Mobile',
  Ue5hf: 'I have acknowledged',
  '1kXO5t': 'Email Verification',
  '1kvaCP': 'Spouse Information',
  ZdE4Az: 'Edit case labels',
  '2jlFjy': 'Name and Position',
  Z1mxmIG: 'Set course fees',
  '18nFR6': 'Separation success',
  Mc5MR: 'Employee Separation',
  ZYKW59: 'Mark all as read',
  kBY4c: 'Use this Zip file',
  V5Eav: 'Withdrawal Rejection',
  S3EPT: 'Exceptional customer',
  '11VpLO': 'Potential customers',
  Z2cmJzv: 'Health Insurance',
  Z1oKbeg: "Recommende's Name",
  xTVvd: 'Email is being sent',
  '1TufAD': 'Add Sub-Applicant',
  Z1W80yb: 'Applicant details',
  Z1vPp7z: 'Reason for Closure',
  UR06j: 'Copy to Commissioner',
  Zm4AYP: 'Course Expiry Date',
  n3eSl: 'Case not yet bound',
  UYpdw: 'Operation successful',
  LXG8e: 'Available commission',
  '166dLk': 'Application Context',
  '1BfmqV': 'Accompanying member',
  KnTqq: 'Organisation address',
  '2S6s': 'Insurance policy number',
  V4RRn: 'Change email address',
  ZOLMn5: 'Offer Documents',
  UFwRK: 'Successful execution',
  '1Tubpd': 'Add a participant',
  '1b6VIL': 'Settlement of cases',
  IuvIF: 'Personal Information',
  '2nLWN': "Client's own email",
  Z9NFmD: 'Course Tuition Fees',
  ZLtuTh: 'Course Start Date',
  T36E4: 'Total Course Duration',
  '1MQRP2': 'Total Material Fees',
  '2y70X': 'Please sign your name',
  Iw1SI: 'Personal Declaration',
  Z19vyMJ: 'Employment history',
  ZzHIgl: 'Application context',
  '2j7DUf': 'Countries visited',
  WblRd: 'Education background',
  ZdeJSb: 'Date from-Date to',
  Qt7Qf: 'Student declarations',
  Z25drzd: 'Requested end date',
  OFTIB: 'Entry to Australia',
  '2sbBME': 'Health declarations',
  '1b6oeL': 'Occupation grouping',
  '2kfNB8': 'Hide Client Label',
  '1o8I4i': 'Privacy information',
  ZaPG4T: 'Withdrawal denied',
  '1cYhuP': 'English description',
  '13QDBa': 'Version description',
  '28Rvwb': 'Add a step branch',
  Rn06n: 'Marketing Specialist',
  '139bFJ': 'Add custom cases',
  z28Oz: 'Nationality handled',
  IxXQq: 'Specialist promotion',
  KnEfi: 'Company introduction',
  Z123e0P: 'Verification Code',
  Z12wHuV: 'Membership Number',
  '1ky26l': 'Notify the customer',
  '13Ox61': 'Version information',
  '13PhRc': 'Version description',
  '9KK1r': 'View Case Handover',
  '1kyPau': 'Notification status',
  syW0L: 'Submit Form 956',
  Z12FRTt: 'Deleted documents',
  '1XynVI': 'House {index}',
  '1Yb699': 'Add Family Member',
  Wyq7X: 'Changed successfully',
  '165uIW': 'Application company',
  Z25FsQ9: 'Additional steps',
  MfRgw: 'Synchronized success',
  '18YAeJ': 'Client domain name',
  '10W2zY': 'Step classification',
  ZF2nly: 'Last Updated Time',
  LOpL5: 'Contract information',
  Z1Q6HYw: 'Access succeeded!',
  '1442U1': 'Search in filter',
  Qh1eS: 'School email address',
  '166vHD': 'Application process',
  MbwM5: 'Cancellation process',
  '1SfjTC': 'Add Other Clients',
  '1igKSi': 'Billing information',
  Z1z3C6K: 'Set brush colour',
  '1jQgTG': 'Return to Home Page',
  '22ut7A': 'Proof of Employment',
  XtrI6: 'Graduacte Certificate',
  '2e386b': 'Add Enrolment Fee',
  '1bcvuz': 'Continue compression',
  '166b8E': 'Apply for withdrawal',
  uodQL: 'Error creating case',
  '9sSW4': 'View Update Details',
  '1Sk7lK': 'Sub-Applicant Case',
  '2bqcdm': 'Close Case Notices',
  '2zxDG': 'National identity card',
  Z1QCFvw: 'Country of issue',
  Z1WtU9F: 'Previous passports',
  '1ab0YJ': 'System email address',
  Sr4ud: 'Archive email address',
  ZkrY7X: 'Login IP address',
  Z1wutKX: '{day} days ago',
  Z10pJmR: 'Email {index}',
  '1Xy3Sa': 'Other family members',
  '1b7Pun': 'Organisation address',
  '2dGT5b': 'Australian values：',
  Z26WH6x: 'Modified the title',
  '2kMqJg': 'Modified deadline',
  '163qc': 'Contact in Australia',
  '1Gom2p': "Contact's details",
  S1eYo: 'Landline phone number',
  '2aRkba': 'New Addition Tips',
  ZjWe93: 'Alipay Overseas',
  '1T9ns0': 'Add built-in cases',
  '2rKVQT': 'Add an append step',
  ZxH5Hq: 'Have a great day！',
  SoRU3: 'Filing classification',
  '2eE6Cc': 'Last classification',
  '39j54': 'View School Details',
  oBRu: 'Login with Account',
  '1bAy47': 'Edit the information',
  Z21VKxe: 'Correlation Case',
  '19jo9H': 'Signing commissioner',
  '2sUp7R': 'Parents please sign',
  '13ajuu': 'Add a custom form',
  VOYxx: 'Is it required or not',
  Z1vG1Wl: 'Instant Offer',
  '1hqism': 'Certificate document',
  '15kueI': 'Generate certificate',
  ZBnj1c: 'Submit a visa form',
  '2f07TY': 'Mail status change',
  Z5gtoo: 'Step status change',
  Z12o2bh: 'File step binding',
  jehp4: 'Transfer credit file',
  Z17x7Uj: 'Course Start Date',
  '12w6g3': 'Australian education',
  '1c6L5k': 'Automatic forwarding',
  Zphiys: 'Step Chinese title',
  '1c5eI6': 'Automatic submission',
  Z25Sx5M: 'Modify role groups',
  Z17yNH4: 'Course Start Date',
  QaFGH: 'Less than or Equal to',
  Z1RcGfp: 'Primary Applicant',
  Z16pMIK: 'Whether to lodged?',
  Z1Mbn5Y: 'Date visa granted',
  '1DeFnB': 'Computing resources',
  Uqq8n: 'Submitted Successfully',
  Z2rVeNd: 'Settled to company',
  Z1oIdvi: "Recommender's Email",
  Zk1oGi: 'School English Name',
  ZMzaeA: 'Course English Name',
  Z11MA0H: 'Has any applicant:',
  Z2bRpey: 'Destination country',
  '1ixIkB': 'Comment email address',
  '1j4bVI': 'Identification number',
  '1SO28': 'Other names / spellings',
  '1hql1l': 'Identification number',
  KI86m: 'Name of health insurer',
  '1ijJNk': 'Account email address',
  ZwVmdk: 'OSHC Documentation',
  '1Ir51': 'Cash has been withdrawn',
  TEOem: 'Manual cash withdrawal',
  V5IaC: 'Cash withdrawal method',
  Z24APYh: "Student's location",
  fHjD: 'Client system mailbox',
  '1UY0VJ': 'English Test Record',
  Z8jUqr: 'Apply Extra Credits',
  OyH9Q: 'Successful replication',
  '1cem78': 'Description of duties',
  Z1gR7L4: 'Date order raised',
  '1lM21R': 'Financial institution',
  '25pONj': 'Added message task',
  '1pwRq4': 'Using archive files',
  KpWy0: 'Company Business Email',
  '1Vwymq': 'View CoE Details',
  gu8EM: 'Enter a customer name',
  Z1vuwYa: 'Confirm to delete?',
  Z1jNNHa: 'Add a custom case',
  S14GK: 'Current classification',
  M29QO: 'Do you want to help?',
  Z1x0dM5: 'Log in successfully',
  '1p3fbJ': 'Do you need credits',
  '29Wdsg': 'Intended start date',
  MDz1X: 'Successful publication',
  '1K08t': 'MARA Lawyer Service Fee',
  '1bVNrG': 'GTE Prefill Form',
  XBRcA: 'Submit a GTE form',
  '19z1Ur': 'visa telephone survey',
  '22IAh5': 'Select Work History',
  Z2kHh3S: 'Bind to step file',
  ZtxvYw: 'Please add documents',
  Z1oA8vm: 'Add Rights Holder',
  b3Nxj: 'Click to expand more',
  '1czf3t': 'Open Signature Link',
  JTvVO: 'Modify a email address',
  '2qdbSY': 'Add a bill template',
  ZJdzXe: 'Unarchived documents',
  LriaB: 'Assist in verification',
  ZOLj5o: 'Acceptance Status',
  Z1xb8fb: 'Create a role group',
  ViaxX: 'Reason for name change',
  ZauxAe: 'Please select a time',
  OdqpR: 'Related application',
  Z1tOiQX: 'Type of application',
  Z2jEzc6: 'If yes ,explain:',
  Z1POATq: 'Invalid expression',
  ZgPz5b: 'Edit package course',
  '2gwvHx': 'Case Creation Failed',
  '1oHz2I': 'Additional Application',
  '2fqEa5': 'System Update Notice',
  '15MlLG': 'Failed to send email',
  '15kHQD': 'Generate email address',
  '2fSPP6': 'Date of net payment',
  MkyM2: 'Beneficial Commissioner',
  hPifN: 'Reference number type',
  ZzCW0z: 'Next step in the case',
  Z1hT4BM: 'Country of passport',
  Og2MP: 'Overseas Contact Info',
  '8hm76': 'View Document Details',
  Zxenlx: '{week} weeks ago',
  Z1wutDB: '{day} days later',
  yfPC5: '{hour} hours ago',
  sdJ5C: 'Expected sending time',
  '22DaXs': 'Add a package course',
  sVgpu: 'Estimated intake date',
  '1oGdrI': 'Additional Information',
  Z1dCWxo: 'Course Material Fees',
  Z1flO5T: 'Download Offer',
  Z1GqVP3: 'Page no longer valid',
  Z1LnW28: 'Nominated occupation',
  Z2aVv8K: 'English test scores',
  '1YEq0V': 'Add your own courses',
  Z1sYB5M: 'No Packaged Courses',
  '1KF4Ro': 'English test details',
  '1mAn2I': 'Test reference number',
  '1591TV': 'Other family members',
  '2cBF9Q': 'Mobile / Cell phone',
  EcS6z: 'Reference number type',
  Z9NF5s: 'Planned arrival date',
  '2slJKj': 'Organisation Address',
  '1ttWAU': 'Contact Mobile Number',
  Z1CNGjR: 'Commission Operator',
  Z1y31n: 'View Version History',
  '1RdRvy': 'Add Superior Channel',
  UrTNP: 'Submission instructions',
  Z2fKyht: 'Other ways to login',
  Z11AsB6: 'Register successfully',
  '1oIfag': 'Additional requirement',
  '18wSKh': 'School Warning Letter',
  '2wYKJk': 'Chinese name of form',
  ZaAGAH: 'Edit step successful',
  Z1f2grz: 'Course Intake Dates',
  '1Xody5': 'Instant Offer',
  Zl5pBc: 'Default Material Fee',
  '12tb5O': 'Fees for other areas',
  '1hqR2f': 'Certificate generation',
  '2rGrcz': 'Add Tutoring Program',
  '1W5XFf': 'Select Sub-Applicant',
  '139n3s': 'Add customized fees',
  ZQviSc: 'Fill out Form 956',
  Oc9LG: 'Filling out information',
  '21j8Wy': 'Select Study History',
  Z1AQOaF: 'Initialization Error',
  VmRGK: 'Document classification',
  '1YcyD5': 'Add Client Privilege',
  Z1IxbyX: 'Delete English test',
  '25co2z': 'Add Service Benefits',
  ZYGOt1: 'Point Withdraw Amount',
  '2etnjs': 'Chinese name of case',
  '1FDNuz': 'OneULink Submit',
  Qlmz7: 'Customer service center',
  ZNFJl: 'Add new client again',
  U43Di: 'Cell phone verification',
  Z2pgfku: 'Create a case label',
  EbXeG: 'Add a group of number',
  OfrGj: 'OverseasContract Info',
  OI54l: 'Passport Scan document',
  '1hnCBe': 'Major Updated Content',
  '8m0jL': 'Choosing to view CoE',
  '2bq5OF': 'Close Case Successful',
  '1fxVyP': 'Contract Signing Link',
  '1ta33k': 'Contact person details',
  V3ewE: 'Change your mobile phone',
  ZTh0Jh: 'Social Media Account 1',
  ZTh0Jg: 'Social Media Account 2',
  fKOVT: 'Expiration date of visa',
  '248BM': "Client's Contact Email",
  NphiT: 'Overseas Contact Address',
  UFcrd: 'Reasons for visa refusal',
  SRnoi: 'Course commencement date',
  '1immM3': 'Courses and departments',
  Zauxyd: 'Please select a campus',
  '1yvWbX': 'Test reference number',
  ZweOuI: 'Created at {time}',
  Z1qBSOR: 'Credits Use Review',
  TN6wa: 'Subordinate intermediary',
  Z1uT20u: 'Dear {name}',
  ujt1K: 'Estimated release time',
  '13AzAf': 'Invitation has expired',
  '2us9cB': 'Don’t have a account?',
  Rop5K: 'Submit by OneULink',
  '22s4V0': 'Estimated intake date',
  '38buv': 'Application Form PDF',
  XNqtS: 'Email verification code',
  ZBEhsy: 'Send Verification Code',
  Z248szq: 'English name of form',
  Z159cS5: 'Release a new version',
  '2eu722': 'Chinese title of case',
  Zq9UGx: 'Edit Commission Rules',
  '1gI4RA': 'requirement description',
  '1KOL5L': 'Add as Sub-Applicant',
  Z1bVFaJ: 'Health Examation Fee',
  FhuJc: 'Visa Application Fee',
  Z1lbucq: 'Document Cabinet View',
  Z24a0KQ: 'Visa Processing Time',
  '22It02': 'Add House information',
  Z1f666S: 'Passport Expiry Date',
  Z1dguI: 'Client Signature Time',
  '1icmo2': 'Buy an insurance policy',
  '25ed3F': 'Add range of services',
  '3LAUI': 'English title of steps',
  LAjVV: 'Work and part-time study',
  '180vLS': 'Handlable nationality',
  Z2fp8GL: 'Case Transfer Failed',
  ZnbcrW: 'Binding mail to case',
  Z1kvM2m: 'CoE Payment Review',
  '2eH9Qf': 'Cases handover failed',
  '22TRcW': 'Uploaded successfully!',
  Oz26b: 'Greater than or Equal to',
  '1TxaoJ': 'Contact Phone Number',
  '26YNE8': 'Current point balance',
  dVMh: 'Uploading, please wait',
  MnpiF: 'Periodic cash withdrawal',
  Z1mj7hX: 'My main office has',
  Z1yLLRX: 'Update Enrolment Fee',
  ZcKeX2: 'Default Enrolment Fee',
  '1lmR6C': 'PDF Footer Template',
  ZM6df6: 'Apply for using points',
  ZOMQYb: 'Course completion date',
  y5YGK: 'Email has been delivered',
  ZUyQQC: 'Electronic communication',
  '10R4yR': 'Primary Applicant Case',
  '17v2bX': 'School commission ratio',
  Z1nkGx0: 'Reason for name change',
  '1vhQiT': 'Child custody details',
  Z1Pve1X: 'Unemployment details',
  '2hwGNq': 'Send time has expired',
  Z2gJbWH: 'Cannot add repeatedly',
  yfPJr: '{hour} Hours later',
  '1T2Ho0': 'Current Packag Courses',
  hCOkl: 'Enter vertification code',
  ZeOIGT: 'Reference number type',
  '22QhbH': 'Select School District',
  ZSLQJu: 'Character declarations2',
  ZSLQJv: 'Character declarations1',
  Z1hCNgD: 'Government scholarship',
  Z1P5xJe: 'Contact person details',
  Z2tkiNn: 'Removed message task',
  Z1qypDw: 'Updated at {time}',
  Z1sT1Gu: 'Name of health insure',
  TNhRt: 'Affiliated Classification',
  Z1ku6AP: 'CoE Payment Details',
  Z984WW: 'Overseas Bank Transfer',
  ZfbmNU: 'Edit Document Category',
  Z2pYtnT: 'Create tag: {tag}',
  '207Rct': 'Add Working experience',
  '2dTFtH': 'Show Cancelled CoEs',
  '2lVsbE': 'Career Recommendations',
  '19kWlK': 'Contract signing success',
  Z1fMWhl: 'Text Verification Code',
  '1TYiJU': 'Chinese title of email',
  '2o3df6': 'English title of email',
  '1PDOEJ': 'Preferred Name',
  ZngQap: 'Open for the first time',
  '2wpBi3': 'AAT application fee',
  Z1OCLw8: 'Visa application form',
  ZAM9ak: '{name} Course fee',
  Z1V5t1o: 'File binding to step',
  ZwIcCX: 'GTE Assisted Writing',
  Z1mDC5l: 'Newly added Documents',
  '1mBBtQ': 'Password does not match',
  ZWLGl4: 'Merge target customers',
  Z2d39KP: 'Operation Log Details',
  KYcAO: 'share commission employee',
  '29245o': 'Add a message template',
  '1sEW9P': 'Click the next button',
  ZDix9y: 'Acceptable case types',
  '2uGyT4': 'Generate Contact Email',
  '1k93vf': 'Please choose the school',
  ZyrzE4: 'Cases being transferred',
  Z2nL1IH: 'Bind to Case Success',
  '2eHoJK': 'Cases handover success',
  '2hfnta': 'Add contracted schools',
  '19ksrW': 'Modify Tutoring Course',
  Z1FobWJ: 'National identity card',
  '1upQN8': 'Reference number type',
  '13SEXz': 'Duplicate COE number',
  Z1N97B3: 'Duplicate batch number',
  ZGoQBh: 'Total balance of points',
  '2dmotw': 'Account password login',
  jrQJ1: 'Set Course Entry Date',
  OeV9x: 'Overseas contact address',
  Z1Pavhw: 'Passport Place of Issue',
  ZLBKGI: 'School commission ratio',
  Z1FYGYz: 'Student course details',
  FP2FO: 'Modify customer comments',
  '12wEQc': 'Australian Contact Number',
  LyEtn: 'Participating Commissioner',
  '1c5hgz': 'Automatic cash withdrawal',
  ZQnHYX: 'Emergency Contact Name',
  UFTSU: 'When was the visa refused？',
  Z19jUM8: 'Course completion date',
  '1ikg8i': 'Curriculum qualifications',
  Z16P2PX: 'English test details',
  '1j5w2G': 'Education provider name',
  '165sxY': 'Education provider code',
  vk0m3: 'Scholarship/Award name',
  '1GmcKq': 'Contact person details',
  '2YiU': 'Relationship to the applicant',
  '1bXuNB': 'Contact telephone numbers',
  Zsmcvh: 'Proposed period of stay',
  QfABb: 'Members of the family unit',
  Z1I4qG9: 'Reviewed on {time}',
  Z12vCpw: 'Extra Credits Review',
  Z2qhYFq: 'Get temporary password',
  '1G1u3W': 'Login to OneULink',
  ZqSLOq: 'School application case',
  '2sq2nt': 'Create Commission Rules',
  '1ijXWv': 'Curriculum classification',
  '3loRO': 'Edit Offer Template',
  Z1QVtld: 'Billing Receipt Object',
  Z1IG7gP: 'Re-notify Commissioner',
  ZqRE0F: 'School application form',
  Lc9O2: 'Associate with an existing',
  '18z9xd': "Client's passport number",
  Z1lcr5H: 'Document Cabinet Upload',
  ZKY9Cq: 'GTE Generation Failed',
  '2mgKpo': 'Relationship with Client',
  Z21mEaS: 'Whose work certificate',
  '1jzBBM': 'Please enter the password',
  Z25135K: 'Delete work experience',
  '1nVvwe': 'Use the company LOGO',
  Z2aricK: 'Upload Company Logo',
  pdaGi: 'Entitled to Commission',
  '28Rx7W': 'Add step classification',
  Z1xNjfc: 'Number of cases created',
  Z11PmB9: 'Confirmed and Refreshed',
  '1Dh61G': 'Name of channel provider',
  '1DgWI7': 'Channel agent commission',
  Z23otzY: 'Create Tutoring Course',
  '1W4BNn': 'Type Email or Mobile',
  Z13HNpz: 'Place of Birth',
  Z187QBJ: 'Locations of offices:',
  Zn4XX6: 'Maximum course duration',
  '1Sr4B1': 'Add a date of admission',
  ZOMPDV: 'Withdrawal Audit Failed',
  yaVSl: 'Email delivery in progress',
  '2gwKB3': 'Successful case creation',
  Z29Ah79: 'Expiration date of visa',
  '2tFdUU': 'Intended overseas travel',
  Z2lKYtC: 'Date relationship began',
  '1wWBTt': 'Other identity documents',
  gfOWa: 'Contact telephone numbers',
  '28EKtB': 'Automatic Submit Preview',
  '2tX1v9': 'Copy preview file link',
  '26rdmj': 'Name of health insurer',
  '14vqxD': 'Description of the offence',
  ygAqQ: 'Priority has been changed',
  KsrcL: 'Dates of entry to Australia',
  RJ8tg: 'Study while in Australia',
  '1QN1sr': 'Customer name or email',
  '1SXamy': 'Self-submission of cases',
  Zy1ATb: 'Australian Bank Transfer',
  Z12evtu: 'Create labeling details',
  Zb4paa: 'Please enter the password',
  Z1byohS: 'Record Creation Success',
  Z1kWGCW: 'Resend in {s} seconds',
  '1TXrye': 'Chinese content of email',
  '2o2m3p': 'English content of email',
  ZNwzO: 'Default total tuition fees',
  '1sF4xS': 'Add course entry dates',
  '1NKitK': 'Submit to OneULink',
  ZKXTIU: 'GTE Generation Success',
  Z1lafVQ: 'Document Cabinet Details',
  '1bnCVW': 'Add Australian Offer',
  ZqaA2x: 'Edit Company Information',
  RkXws: 'The OneULink Terminal',
  '1EEyfB': 'Employee Billing Methods',
  Z1qAvJC: 'Company Bonus Available',
  '1k7P59': 'No order has been sent yet',
  OlG2n: 'Increase professional title',
  '176rKP': 'Number of cases completed',
  Z1fEylt: 'Company Case Statistics',
  ZzBzkT: 'Case transfer in progress',
  '2k0Jg4': 'Add automatic submission',
  Z1GvuLM: 'The page is not working!',
  ZShSuK: '{day} Calendar days',
  Z1rNIuj: 'Duplicate policy number',
  Z1yrB2f: 'Upload Client Materials',
  '1xjDBK': 'What is privacy mailbox',
  '2eGHex': 'Case Handover Specialist',
  Z1qxzs1: 'Passport expiration date',
  Z17yR4f: 'Course commencement date',
  Zs2oPF: 'Interface call successful',
  Z1UB2sA: 'Set up superior channels',
  '1yiBdK': 'Country/Area of School',
  ZLC0Ua: 'School Commission Receipt',
  t3Oz6: 'Other identity documents',
  Z2uqlev: 'Signature initialization',
  ZJB2Jh: 'Australian Contact Number',
  ZQmGKh: 'Emergency Contact Number',
  Z2wjdVu: 'Form Information Preview',
  '2dqyX9': 'Date of skills assessment',
  zvE2p: 'English language proficiency',
  Z1LArhN: 'Confirmation of enrolment',
  Z2hihNf: 'Description of the offense',
  '2u6pUj': '{day} Days in advance',
  CCsdR: 'Timed message notification',
  cKlWf: 'Special category of entry',
  Z1xi1ad: 'Length of further stay',
  '1VtV8q': 'Last employment position',
  VvaAb: 'Travel companion information',
  Z1krMA9: 'Invoice Default Settings',
  XJ3yp: 'Is there a credit transfer',
  Z1jk1lY: 'Company Contact QR Code',
  gcLwx: 'Welcome to OneULink',
  '1STCzn': 'Entrance time/end time',
  Z1lVUN: 'Agent Registration Number',
  Z2mcRkC: 'Successful creation step',
  '17R5X8': 'Please confirm the password',
  Z2mDQ0i: 'English name of the case',
  '1DisSb': 'Types of channel providers',
  Z2foSNg: 'Case Transfer Successful',
  '1RcNRn': 'Add Subordinate Mediation',
  PTadE: 'Academic qualification range',
  '1vFhNp': 'Create Client Form Link',
  Z1yEOES: 'Continue merging clients',
  '1TwA4B': 'Pacific-Australia Card',
  Z1PPbkm: 'Duplicate passport number',
  '1lbWyj': 'PDF Letterhead Template',
  Z1wYgC: 'View notifications history',
  ZQnNoz: 'Emergency Contact Address',
  '2nHPTw': 'Set amount {amount}',
  Z2x3Czv: 'Date of intended marriage',
  UlaTY: 'Modify customer references',
  ZciT7A: 'Valid and invalid customer',
  ZnF51K: 'Confirm full withdrawal?',
  '23Sogn': '{year} Admission time',
  ZyfeIE: 'Is there a package course',
  Zz1tJ8: 'Visa history and passport',
  Z18Di26: 'Total Course Tuition Fees',
  t7vNG: 'Education history details',
  Zs4uPp: 'Current or previous visa',
  f5Obw: 'Name of assessing authority',
  '2oKd6z': 'Use the packages courses',
  Z2lC3Vf: 'Study in English language',
  ZB8k5O: 'Country/Area of Company',
  '1PzhAm': 'Current employment details',
  '17PXvN': 'Modify Account Information',
  Z1PBK40: 'Country/Region of Passport',
  '13MA8x': 'Effective date of contract',
  Z1LoImW: 'Type of medical insurance',
  '2orlh2': 'Reset Packaged Course',
  Z2m3QHa: 'Create a message template',
  Z15PbUq: 'Number of files displayed',
  TtsPF: 'Chinese display information',
  Z1Co46t: 'Input step classification',
  Z2mD7hI: 'English title of the case',
  '1bbzQ2': 'Visa Renewal Application Fee',
  '2rgJ3B': 'Generate Resume Successful',
  Z15RCyV: 'GTE Writing Description',
  Z7UeRe: 'Save as word document',
  '21N96B': 'Selecting Client Documents',
  HX89S: 'Family member {index}',
  SfRzK: 'Enable or Disable Promotion',
  '1MLjat': 'Tips Affiliated Company',
  ZQo5cB: 'Emergency Contact Address',
  '2naxep': 'Automatic email forwarding',
  Z1O7XQV: 'Invoice Template Settings',
  Z2uEJP8: 'Tuition to be re-labeled',
  sA50: 'Affiliation tutoring company',
  '2fscwq': 'System Update Notification',
  ZQJip9: 'Select another case type',
  ZJBCPN: 'Australian Contact Details',
  ZHGaQU: 'Apply for additional points',
  ZbDmGE: 'Pre-withdrawal under review',
  Z2qXHkl: 'Case waiting to be created',
  Z2g6QbI: 'Australian Admission CoE',
  Z1QwEiK: 'Percentage{percent}',
  Z1PSzY8: 'Expiration date of passport',
  '7L6QL': 'Extra points have been used',
  Z1Uw9iY: 'Set up payment information',
  Z2qLTvz: 'Health insurance details',
  '1Yvsj0': 'Usual country of residence',
  '1SnIw5': 'Reference / Receipt number',
  '1pDOFQ': 'Usual country of residence',
  zoQL9: 'Changed the person in charge',
  Z1Ps76g: 'Company Registration Number',
  '1bWFbw': 'Contact Landline Phone Number',
  Z15MCcT: 'Study history in Australia',
  Z1ev3xp: 'Relationship to applicant',
  '5VBbr': 'OneULink submits case',
  Z2qMxxP: 'Create document categories',
  Z1Flepe: 'Upload payment certificate',
  '1vkkgI': 'Download the signature file',
  '21Oypu': 'Select customer information',
  '2wDJHe': 'Add Additional Requirements',
  Z1BBuFe: 'Edit case copy personnel',
  Z2lC6Fl: 'Signature Package Download',
  ZzqQ19: 'Rights owner {index}',
  Z1Eve2r: 'Please enter Chinese name',
  '1gwIdK': 'Bonus available to employee',
  '3Lrkx': 'English description of steps',
  ZBkSo1: 'Completed cases this month',
  '2cPnE2': 'Please select a cycle type',
  '1kX7CY': 'Support installment payment',
  Z1OGT7B: 'Company Promotion QR Code',
  '11DKvr': 'Modify automatic submission',
  Z1hwwwT: 'Domain name already exists',
  '7bXfk': 'Click to initiate withdrawal',
  Nqnwl: 'A little error has occurred',
  Z1K65pH: 'Add a promotional activity',
  ZiVGI3: 'Default weekly tuition fees',
  Z23SeB0: 'Company switch successfully',
  Z1g2tDE: 'Document Compression Failed',
  '2tO0FJ': 'Billed to Company Employee',
  Z29poW: 'Assist in validating success',
  Z2wqdQf: 'Region/country of issue',
  FOHjh: 'Modify the client information',
  '2wYNly': 'Enter case keyword search',
  ZsiSsl: 'Course Tuition Fees per week',
  Z1O8fgN: 'Education / Employment',
  Z1eVotv: 'Intended state of residence',
  '1SpL5o': 'Select a program to attend',
  '1c6DUU': 'Commonwealth funded student',
  Z8r9u2: 'Sponsoring organisation name',
  Z8pLCx: 'Sponsoring organisation type',
  arnN9: 'Country/area of organisation',
  kMwIz: 'Australian residency status',
  ZSyxye: 'After {count} seconds',
  '2spYRa': 'Company created successfully',
  Z2a0X0g: "Student's basic information",
  Zfpj8I: 'Personal Contact Information',
  ZJC9nz: 'Contact Address in Australia',
  ZI7yuE: 'Foreign Passport (current)',
  OfkVo: 'Domestic Contact Phone Number',
  '10sKcO': 'Please configure case steps',
  '1kVoCP': 'Whether to notify the client',
  JYhwx: 'Upload Immigration Documents',
  ZLqQd0: 'Work Certificate Information',
  tGnIO: "Commissioner's Signature Time",
  Zphs9D: 'Chinese description of steps',
  Zwsrai: 'Points withdrawn this month',
  Z1FrGCS: 'Enter a case classification',
  '1TYN7B': 'Add Router Privilege Rules',
  Z2mqYSY: 'Please select account type',
  ZrVG7C: 'How long have you operated?',
  ZypoUh: 'If yes , Attach documents',
  '29ppOT': 'Personal information category',
  Z1g2eK9: 'Document Compression Success',
  '19vhDX': 'Recommender Contact QR Code',
  ZC8m5t: 'Search for domestic addresses',
  '1w2OSE': 'Country/Region of Passport',
  ZzuEJr: 'Overseas Education Background',
  '1R3kMV': 'Supplementary Payment Receipt',
  Z1qAQX0: 'Company Commission Available',
  Z1EFIML: 'Employer / business details',
  qugqC: 'Previous travel to Australia',
  '24vucO': 'Country/region of passport',
  jLVhA: 'Australian study requirement',
  '1mO9BR': 'Skilled extension eligibility',
  Z1QdJ6j: 'Is this a current address?',
  Z1F6yyO: 'Employer / business details',
  Z1pERX0: 'About your future career',
  Z2mJxlb: 'English name of the position',
  '2enFXz': 'Position in your own language',
  Z1FIwwp: 'Already sent,please check',
  Z1BjhXY: 'Prefer Name or Native Name',
  Z1qAgjo: 'Form item English Label',
  Z12VnsA: 'Effective duration of course',
  '2XVhK': 'Certificate Generation Success',
  '1EbAic': 'Download Certificate Document',
  WkiTx: 'Data is not available at present',
  eVLgr: 'Insurance start and end date',
  Z2aqvxR: 'Generate Visa ESC document',
  '26T6jk': 'Selecting Document Categories',
  '25h0Bl': '{file} Marked Successful',
  Z1sXoCE: 'School Base Passport Level',
  lgl0B: 'Enter Email Verification Code',
  Z1x7H21: "Please enter client's email",
  '1BD1SQ': 'Australian visa grant number',
  '218Ulf': 'Commonwealth funded student',
  Z5ssjS: 'Or drag and drop files here',
  Z1rNgIN: 'Types of attributable cases',
  N9F0c: 'Licenses and permits held:',
  Z2cUUys: 'Use private mailbox or not',
  ZQo3lR: 'Emergency contact information',
  Z1XrTUn: 'Resend the notification email',
  RVR3N: "Search by commissioner's name",
  LRmtR: 'Set up school commission rate',
  '1vt6ih': 'Closure of education provider',
  Scws1: 'Nationality of passport holder',
  '2vXBad': 'Student guardian declarations',
  Z1TPCqh: 'Australia Offer Document',
  '2eRvl': 'Place of issue / issuing authority',
  Z203xRb: 'Australian visa grant number',
  hXqtp: 'Occupation name (ANZSCO)',
  Z2pri21: 'full details of the sentence',
  ZzCacl: 'Relationship to the applicant',
  '2wDP9O': 'Please enter the correct URL',
  ZFC2AR: 'Enter company name to search',
  ZJY3St: 'Application form is missing',
  Z1L5cT0: 'Position deleted successfully',
  Z2mPttv: 'Automatic submission of PDF',
  Z1yLAFq: 'The current form has expired',
  Z298KFQ: 'Are there packaged courses?',
  '179ICR': 'Display information in English',
  b7b8U: 'The step is partially completed',
  Z2eI1sl: 'Server Connecting in progress',
  Zol093: 'Other information of customers',
  Z2e3R7P: 'Household Register information',
  Gyr1c: 'Edit client basic information',
  Z26Bnsf: 'Modify the mobile phone number',
  ZNSkDv: 'Additional Company Information',
  Z2pshop: 'School Data Year Management',
  ZRHkKe: 'Passport class of the country',
  Z217RFP: 'Mobile phone verification code',
  ZwV4iS: 'Please enter the mobile number',
  Z18LOeG: 'Education related information',
  '1ILNdC': 'Waiting for Company Withdrawal',
  Z15L6Ul: 'Australian Educational History',
  '1Ptejs': 'Overseas Visitor Health Cover',
  '20U0Bt': 'Enter address keyword search',
  ZWaFXx: 'Waiting for email confirmation',
  '16iylh': 'Nationality of passport holder',
  '25Az9G': 'Determined to reinstate case?',
  ZRVTDG: 'Total current attachment size',
  Z19kPYy: 'Cannot Select Repeat Courses',
  Z1A5X0o: 'Do you need credit transfer?',
  ZYobFF: 'Australian Education Background',
  Z2gWK3h: 'Type of health insurance cover',
  Z1KU2tw: 'Recognised university study',
  '12j8G4': 'Occupation code (ANZSCO)',
  '2sbcB': 'Please elaborate on the reasons',
  Z1tUIhq: 'Closure of education provider',
  I46to: 'Indicate all countries that apply',
  ZaqjYv: 'Other organisation scholarship',
  ZMvEY1: 'Planned final departure date',
  Z1sRDP0: 'Type of health insurance cover',
  '1BhkkI': 'What do they do for living?',
  ZJBJAI: 'Australian Contact Phone Number',
  '2gmK7V': 'Form item in Chinese Label',
  eyvpC: 'Expected {point} points',
  Z2fKxer: 'Real estate license information',
  Z1iQLpk: "Company's Country of Residence",
  '182nCs': 'Languages that can be processed',
  Z17DalE: 'Pre-credit Application Review',
  ZnpWHp: 'Default submission instructions',
  Z2qVwGQ: 'Case waiting to be transferred',
  YF34y: 'Enter the course name keyword',
  '1V2sMp': 'Add Interface Privilege Rules',
  ZcU5XX: 'Application for a Bridging visa',
  Z1iJivB: 'Document Download Verification',
  Z1dIOBx: 'After {day} business days',
  Zb2IkN: 'Please enter your account number',
  Z1a3shf: 'Estimated commission available',
  '1mfz0A': 'Nationality of document holder',
  '2d6yVI': 'Do you need to create OSHC',
  Z28LXWk: 'Do you need to create a visa',
  Z2unGEm: 'Add a different regional fee',
  Z1lwJCb: 'Document compression in progress',
  Z13hDYS: 'Websocket reconnecting',
  '27Lmim': 'Request for permission to travel',
  uTKxU: 'Dispatch email has been resent',
  ZGExdm: 'Start and end date of the course',
  MaUkQ: 'Modification is valid and invalid',
  D2e4p: 'Sent when the case is completed',
  Z21RwVC: 'Send when the case is created',
  '20XHRx': 'On the expiry date {desc}',
  ZcopXH: 'Upload the appropriate document',
  ZQlKh0: 'Emergency Contact Email Address',
  '2nGlJ2': 'Total cost of course materials',
  Z1Cfm0B: 'Are you sure to select all NO?',
  '1CieHy': 'Country/Region of institution',
  Z2cvl4S: 'Length of stay in Australia',
  '29z5Yk': 'Number of clients to be created',
  '1q0Tfz': 'Waiting for Withdrawal Requests',
  Z1EQoDc: 'Please enter different options',
  '1l1oAH': 'Have applied for a visa before',
  '2rwdyC': 'Is it your second visa renewal',
  Z2m0OlG: 'Drag and drop files to upload',
  Rg0Y1: 'Submit information to the school',
  '18A4GI': 'Associated with an existing visa',
  '1IdiFp': 'Include GTE document or not',
  '65SCK': '{name} Apply {school}',
  Z1JruCl: 'Setting Submission Instructions',
  BikYa: 'Please enter keywords to search',
  iTV4z: 'Do you have a visa grant number',
  Z23IheU: 'Do you need to create a CoE',
  Z11B3hC: 'Company contact telephone number',
  Z2vSBSX: 'Creating a Customer Successfully',
  Z1m3jPd: 'Download Automatic Forms PDF',
  Z1fktxp: 'Name of other health insurer',
  Z1bWRfP: 'You cannot add repetition time',
  UFjMS: 'What country/area refused your visa？',
  Z1BUNAV: 'Are you sure to select all YES?',
  Z2sICX2: 'Other evidence of intended study',
  Z21GvB5: 'Other evidence of intended study',
  ZF9uCg: 'Relationship to the primary applicant',
  Z2hnjA3: 'Please re-select the deadline',
  '1TD9uX': 'Relationship to the applicant',
  ZRuTPr: 'What is your course duration?',
  Z1HW4Bh: 'Where is your campus location?',
  Z1J9Wv2: 'What is your career plan?',
  '1XHA2H': 'Release a new version of the case',
  J4K5f: 'Create a course tutoring company',
  Z1fQBrP: 'Please enter signature location',
  ZP7f6B: 'Client Resume Generation Failed',
  '1esgbL': 'Delete student health insurance',
  ZhFMnY: 'Acceptable forwarding specialist',
  '1gwn0n': 'Commissions available to employee',
  eEl70: 'Synchronize global message tasks',
  ZwYgyD: 'Click to switch assisting firms',
  Z2kAuyX: 'Modify Course Tutoring Company',
  paCIU: 'Material duplicate checking failed',
  Z1iUsIX: 'Sponsor or Sponsor applicant',
  K48Cc: 'View the latest created clients',
  Z1TGTT6: 'The applicants declare that they:',
  CAPk: 'Successful execution of {name}',
  '22K9Dl': 'National identity card (If have)',
  ZtSq9h: 'Intermediary Processing Specialist',
  Z1MLd0W: 'Is there a related CoE file',
  gORVm: 'CoE File Recognition in Progress',
  '1dK7NR': 'Relationship to primary applicant',
  Z1c7Lu6: 'Health insurance / Education',
  Z2p9Tai: 'Country where test was undertaken',
  Z1FG69l: 'About your personal circumstances',
  ZGmzuM: 'Create custom case successfully',
  WWPhw: 'Do you have a visa refusal history？',
  LII4B: 'Edit personal contact information',
  ZG26Hj: 'Your information has been updated',
  Z2aG9fm: 'Separation case handover status',
  Zy7uy3: 'Confirm completion of this case?',
  ZvTLEI: 'Please enter the verification code',
  '2tYlFI': 'Edit Custom Case Classification',
  '16GeD9': 'Ability to quickly create a case',
  Z2ru347: 'Modify routing permission rules',
  Z7ME0Q: 'Social Media Account {index}',
  Z2mzFAh: 'Confirm settlement to employee?',
  ZixzaL: 'Number of days until course start',
  '1PjpL7': 'Send when the customer is created',
  P05il: 'Place of issue / issuing authority',
  '17bsmu': 'HIV infection, including AIDS',
  '2nBmAH': 'Does it never cross the border?',
  Z1LIHY: 'Reason for further stay',
  '28cFg1': 'Add Overseas Education Background',
  '1cXvUX': 'Automatic School Application Form',
  '28wrXR': 'Enter document title (optional)',
  ZWJuGn: 'Please enter the verification code',
  Z27eohc: 'Submitted through OneULink',
  Z1tjaWH: 'Click to exit current assistance',
  ZSAGHM: 'High Risk Country Passport Level',
  Z2qrnoj: 'Modify Interface Privilege Rules',
  ZzUQ5W: "Primary applicant's date of birth",
  Z28MRL4: 'Do you need to create a passport',
  K00D9: 'Attach certification of registration',
  Z14W83h: 'Number of staff and position(s):',
  Mat4N: 'Documents are being compressed again',
  ZYJxs8: 'Client form has not been submitted',
  Z1Aj6zT: 'No information available at present',
  Z1huiNV: 'Document initialization in progress',
  ZxhvrF: 'Do you have a visa refusal history？',
  V8ojt: 'Period covered by health insurance.',
  ZTBQDS: 'Australian qualification details',
  ZljiSv: 'Australian Federal Police check',
  Z1bTibN: 'Country/area of Emergency Contact',
  '1WEbsQ': 'Would you like to purchase OSHC?',
  '2c69bI': 'Submit the school application form',
  Z1Lqanw: 'Classification of medical insurance',
  Z1RQBrh: 'Delete Australian Admission CoE',
  dfGyO: 'Edit additional company information',
  '16FNYp': 'Ability to quickly create a client',
  '5T7YS': 'Modify superior channel information',
  '2hDQXb': 'All cases transferred successfully',
  yV8gF: 'There are two ways to deal with it',
  '1P7C9M': 'Proposer or Proposer application',
  '1Ij9jM': 'Add Australian Education Background',
  '12HS9q': 'Commission Settlement is not enabled',
  wN1TP: 'On the course start date {desc}',
  '1AQFdA': 'Usual occupation of the applicant',
  '28PDIA': 'Course level of your qualification',
  Z1DQBtz: 'the date and nature of the offense',
  '2je2kz': 'kidney disease, including dialysis',
  '22QeFv': 'The size is limited to 200x200',
  '1wYatV': 'Enter case name keyword to search',
  ZP4EIg: 'About the program you applied for',
  '27zI9z': 'Form rows can be dragged and sorted',
  Z1MsJJf: 'Use emergency contact information',
  Z2ozrV8: 'Blackboard login information',
  TiVRR: 'Trigger submission to OneULink',
  Z2wyDnk: 'The reply content cannot be empty',
  '15MaKX': 'View the GTE Form Details file',
  fgKST: 'Signature Package Download Documents',
  ZyFzyt: 'Biometric Fingerprint Login Binding',
  Z13phtD: 'Delete Australian School Offer',
  Xu7Rg: 'Modify the case points configuration',
  '6nG40': 'Form items can be dragged and sorted',
  '21wg4o': 'Cases of further studies and transfer',
  Z1Rxd3M: 'Please upload the correct QR code',
  Z1mS6SF: 'Nominator or Nominator applicant',
  '11HfH1': 'Specific matter - give details',
  Z2woCVM: 'Do you need to create an Offer',
  Z27cv6t: 'Confirm hanging the current case?',
  kPr4T: 'Student passport (must be uploaded)',
  Z1stjSJ: 'Student medical insurance documents',
  Zkw77R: 'Generate Client System email address',
  Gbfv3: "On the customer's birthday {desc}",
  qYuNP: 'Country/area where test was undertaken',
  '1kbuSw': '{total} items have been selected',
  ZP4arK: 'When is the course start date?',
  ZibjNJ: 'Copywriting or other advertisements',
  Z1iEpgK: 'Personal Information Collection Form',
  yfLhj: 'Set Submit Free Step Trigger Step',
  Z1my8vq: '{name} Course Fees by Region',
  ZVoQlL: 'Check the 956 form details file',
  Z99S2l: 'Notify the customer of the operation',
  '20M1N9': 'Country/Region of Permanent Residence',
  Z4yXA6: 'Verification code sent successfully!',
  Z1RWVQp: 'Add customized case classification',
  '1JX1A1': 'Failed to transfer part of the cases',
  Z1U4vNK: 'Progress of current transfer case',
  '4oMmM': 'Support for a single or bulk upload.',
  'apply-form:relationship-to-you.option.other': 'Other',
  'apply-form:employment-status.option.resign': 'Resign',
  Z1Sl2KC: 'Enter text message verification code',
  '1KKwCG': 'Choose the language of the file name',
  '265Bs4': 'printed on the front of your card',
  '1AHoIC': 'One click upload of client materials',
  '14jmE3': 'Biometric fingerprint binding failure',
  '2e6xLN': 'Cc personnel have not been set up yet',
  '1gLk48': 'Accompanying members of the family unit',
  ZXec7a: 'View private information of customers',
  Z1mBAgT: 'There is no message content at present',
  '1SXOh7': 'Financial support from an individual',
  '2vh3aR': 'Select the special category of entry',
  '1Svb0P': 'What course are you going to study?',
  Z11mlOV: 'Sign up for a OneULink account',
  '240P4y': 'The current form item already exists',
  Z1NnGNw: 'Enter case name keyword to search',
  '16bnCt': 'The following information is incorrect',
  '1NOmue': 'Australian Immigration Document Import',
  '1NNRAr': 'Australian Immigration Document Upload',
  ZrzuEr: 'Select the documents to be included',
  Z2dQMM6: 'Delete overseas education background',
  ZqEySG: 'Edit Company Information Successfully',
  '1FLygL': 'Application for extra credit rejected',
  ZRi0T: 'Proposed date of return to Australia',
  Z2eHgkI: 'Supplement School Commission Receipts',
  Z23EpXz: 'Enter customer name keyword search',
  KaaF3: 'Total current message attachment size',
  Z1ttWR9: 'Country/area of Emergency Contact',
  Zn4p3Y: 'Have you applied an Australian visa?',
  '1263e': 'Temporary password  sent successfully!',
  Z1gboB0: 'Define to make this contract invalid',
  '1vNz7s': "Enter client's name and email address",
  'apply-form:entrolment-status.option.current': 'Current',
  'apply-form:relationship-to-you.option.parent': 'Parent',
  'apply-form:employment-status.option.retired': 'Retired',
  '1FLDFu': 'Application for extra credit withdrawn',
  '1tKWlV': 'Bind to other documents of the client',
  ZBg3Co: 'Generate customer archive email address',
  Z20a339: 'Transaction Reference Number (TRN)',
  '9fekR': 'List all reasons for visiting Australia',
  MGiqu: 'Does the application have a queue date?',
  '2jixy8': 'Automatic application for cash withdrawal',
  Z1hICep: 'Emails from teachers and classmates',
  eyQmx: 'Enter email subject keyword to search',
  Z2or7Kk: 'Complete all the parts in the form',
  xNBfP: 'Australian Immigration Forms Quick Fill',
  ZIpN6Y: 'Offer AI is recognizing in progress',
  '1QPd4i': 'Confirm to hide this CoE payment?',
  Z17MlEE: "Participate in the company's assistance",
  ZepG6N: 'Keywords must be no less than 2 words',
  'apply-form:relationship-to-you.option.frirend': 'Friend',
  Z2an7bW: 'Add a new client as a sub-applicant',
  ZBsx1b: 'Do the student come with a student visa?',
  Z2cTvCP: 'Other passport or document for travel',
  Z1oXVSK: 'Built-in case configuration successful',
  sv0hI: 'Please Enter The Correct Course Code',
  '2lTzwT': 'Whether the school commission is claimed',
  ZhFvQU: 'Accompanying members of the family unit',
  ZKigcy: 'Australian visa grant number (if known)',
  Z1Vyaww: 'On passport expiration date {desc}',
  Kp0Xu: 'Apply for school client creation record',
  ZgOhOB: 'Please priority set the billing template',
  Z1mUFnR: 'Check the details of school application',
  '2jbExk': 'Please enter the correct place of issue',
  '3CGsw': 'Have not updated the case for a long time',
  'apply-form:relationship-to-you.option.partner': 'Partner',
  'apply-form:employment-status.option.on-job': 'In-service',
  '1QsyCa': 'Sent SMS verification code successfully',
  XWfMQ: 'Have you previously travelled to Australia?',
  Z2bdA08: 'Enter name and email address to search',
  Z1TS7cb: 'Please sign in the centre if possible',
  ZLz5H: 'Number of days until the end of the course',
  Z2lzFA0: 'At the end of the course day {desc}',
  '2hPCxD': 'Education Background and Working Experience',
  '3qKwD': "Emergency Contact's Relationship To You",
  UbMCe: 'Residential address for the last 10 years',
  hsvlP: 'COVID-19 related travel restrictions',
  '2ftBks': 'Enter Tips title keyword to search',
  Z1Qjge4: 'To the form that needs to be triggered',
  rLuUw: 'Emergency Contact’s Relationship To You',
  Z13d8uE: 'Update personal information successfully',
  '1cs06A': 'Confirm to package all selected course?',
  ZaoiHx: 'Case steps are repeated, please check',
  '25CKrx': 'View visa application details documents',
  Z1Dz4j3: 'To set up the program without a deposit',
  '250GMj': "Founder's position after company transfer",
  ZepFQ3: 'Keywords should be no less than 3 words',
  'apply-form:health-status.option.visual': 'vision defect',
  Z1cB5ly: 'Pacific-Australia Card serial number',
  Z1ED1n9: 'Please select, enter a keyword search',
  '1wYGAm': 'Residential address for the past 10 years',
  Iv10I: 'Provide individual and overall band scores.',
  '16t5cH': 'Do you have any relatives in Australia?',
  LqmPF: 'For details, please contact the specialist',
  Z1pcMAO: 'Current Transcript/ Graduate Certificate',
  '2wiwBB': 'Download the school automatic form PDF',
  '1XuIkz': 'Contains original GTE content or not',
  'apply-form:relationship-to-you.option.relative': 'Relative',
  'apply-form:employment-status.option.suspended': 'Suspended',
  Z9U9if: 'Sent successfully, pay attention to check',
  ZjQ5dF: 'Person requesting ministerial intervention',
  Z2s3gWF: 'Duplicate countries cannot be selected',
  Z1Y6fe8: 'OneULink Launches Points Withdrawal',
  '1Ussur': 'OneULink accepts assistance requests',
  '1Dhd8L': 'hepatitis B or C and/or liver disease',
  Ms3qy: 'Application ID/Transaction Reference Number(TRN)',
  Z11vTNJ: 'Sorry! Your contract is no longer valid',
  '22yc3V': 'Maximum number of files allowed to upload',
  SmUbV: 'Course and Commission Description Documents',
  Z2mfF7M: "Enter client's name and email to search",
  ZzUa0c: 'Are you sure to proceed to the next step?',
  Zvsl82: 'Visa Document AI Recognizing in progress',
  'apply-form:entrolment-status.option.graduated': 'Graduated',
  Z23Tbot: 'Email verification code sent successfully',
  '1XkFMS': 'The person receiving immigration assistance',
  '1rz2nB': 'Proposed date of departure from Australia',
  ZOtTT5: 'Triggered on {date} {timezone}',
  Z1SibH8: 'Additional Requirements for Applying School',
  Z2uphqb: 'Intended state of residence in Australia',
  ZbY6F2: 'Australian Federal Police check details',
  '166JNY': 'Select the stream the applicant is applying for:',
  Z1InXo8: 'Sent successfully, please check your email',
  vfHkh: 'Click to view the details of folding steps',
  '2v3T4W': 'Student Commencement Graduation Management',
  Z1ImOnf: 'There are duplicate courses, please check',
  'apply-form:entrolment-status.option.discontinued': 'Drop out',
  Z1obQMX: 'Relationship with emergency contact person',
  ZbqekM: 'Is this applicant currently stateless?',
  '1S5yaY': 'If yes, please attach explanatory letter.',
  Z1Pk31z: 'Give details of the nominating student(s)',
  '1cuPit': 'Non-accompanying members of the family unit',
  ZrAQGQ: 'Sent when case [{name}] is complete',
  kAWid: 'Please enter the correct mobile phone number',
  '23SDGi': 'Non-accompanying members of the family unit',
  Z1EqSP9: 'Please enter the correct {type} code',
  Z2lsGiF: 'Notification emails from the Student Center',
  Z2w6Dql: 'Set up submission to OneULink steps',
  ZliAuy: 'Drag and drop the attachment file to upload',
  '1LwnoX': 'Select a country/region outside Australia',
  X3tJX: 'Enter English letters, numbers or Chinese',
  ZXfSJp: 'Automatically create {job} jobs or not',
  Z1BTksR: 'Is the family member over 5 years of age?',
  '13rkMo': 'Australian visa grant number (if known)',
  w4exN: 'Please fill in at least one contact number',
  Z1AoSTK: 'Are you sure you want to unbind the CoE?',
  Z21wJPm: 'Configure at least 2 steps for case steps',
  '1WBza3': 'OSHC document AI recognition in progress',
  Z1qfocv: 'Not yet bound to the quick login, you can',
  'apply-form:health-status.option.intellectual': 'Intellectual',
  Z2l1of4: 'System Identification Document Classification',
  '1GO0yC': 'Relationship to the primary applicant',
  '2bfwNO': 'E-mail verification code obtained successfully',
  '123Deq': 'Add documents under [{name}] category',
  '1DOPV0': 'Passport Document AI Recognizing in progress',
  '2sjsAO': 'Accessories must not exceed 15 MB in size',
  '1ww092': 'Confirm to go to the company for assistance?',
  'apply-form:health-status.option.hearing-deaf': 'Hearing/deaf',
  '264gHJ': 'If there is a independent contact information',
  ZH8POg: 'Does this applicant have a national identity card?',
  Z2gPJzv: 'ever had, or currently have, tuberculosis?',
  Z1RBB6R: 'Non-accompanying members of the family unit',
  Ozdvb: 'System is updating, Please stay tuned.',
  Zb9jI5: 'Bill Payment Notification, Unpaid Fee Reminder',
  '2UiD7': 'Will send a verification code to {email}',
  ZkPvy7: 'View cases with latest follow-up information',
  '1kHfLw': 'a legal representative of (OrganizationName)',
  '2k0H56': 'Below are the contents of this system update:',
  '1skpRl': 'Is this family member currently stateless?',
  ZIQHn7: 'Which educational background is the attachment?',
  tflL7: 'Value in Australian dollars (whole dollars only)',
  ZBFlP3: 'Relatives, friends or contacts in Australia',
  Z2eatOf: "Visa applicant's current overseas employment",
  Z17m3XC: 'How much is your tuition fee for each course?',
  '1FRVTj': 'Before that, you need to log in or register',
  '2bQfIQ': 'Australia Visa Copy (if your holding any)',
  '1XR8t2': 'Edit school application additional requirements',
  'apply-form:health-status.option.learning': 'Learning Disability',
  Z2380V5: 'Please setup school commission rate first',
  Z2pDoiz: 'Provide at least one of the following numbers',
  '1Fqfm7': 'Department of Home Affairs Request ID number (RID)',
  WKqc1: 'Please determine the classification of the document',
  Z24mgVv: 'Please add steps for branch triggering append',
  '1ieOJv': 'Please enter the correct OSHC membership number',
  '1Q7Pf3': 'Modify school data effective year successfully',
  'apply-form:health-status.option.mental-illness': 'Mental illness',
  aILtP: 'Select the reason for applying for a Bridging visa',
  '1Keio2': 'Click to upload or drag and drop files here',
  ZQ1kVx: 'Have you ever traded under any other name?',
  '2hdBlu': 'I have read and agree to the above disclaimer.',
  '7qeka': "Give details of the applicant's current location.",
  UXrWV: "Nominating student's relationship to the guardian",
  Z2r0JBF: 'Confirm termination of contract with this agent?',
  '2oJXLE': 'Translation of GTE content into Chinese or not',
  Zg3TC4: 'Generate a course planning report for the client',
  E3apU: 'Add additional requirements for school application',
  Zeot1X: 'Number of documents to upload in the requirements',
  '1EjKx2': 'Please copy the URL and send to client to fill in',
  ZpIQU1: 'Set up reference number and payment options，etc.',
  H0d9D: 'Information entered incorrectly, please try again',
  '1VmP26': 'Does the family member have a current passport?',
  ZNv6CB: 'Are you sure you want to submit this visa form?',
  Zp9px1: 'Why would you choose this city in Australia?',
  '1VOfwV': 'No documents have been uploaded to the file cabinet',
  '246O8g': 'Are you sure to approve this payment certificate?',
  Z268NVj: 'Are you sure to cancel the additional application?',
  Z2f9kmr: "Give details of the applicant's current location.",
  '1A4rbR': 'Document categorization AI Recognizing in progress',
  '2n3BoA': 'Assisted but not submitted to OneULink case',
  Z2rnUuF: 'Does this applicant have any other valid passports?',
  Z1958nF: 'Are you sure to reject this payment certificate?',
  ZlDaVe: 'Set the signature to be used for document certify',
  ZE70uu: 'Cancel zip file will abandon the upload documents',
  Z15Irf3: 'Click here to add a sub-applicant to the case',
  '1UEPGs': 'Review selected pre-credit withdrawal applications',
  ZpCUEO: 'Which university or college are you applying for?',
  '22O0DI': 'This action does not notify the customer by default',
  ZiGxLg: 'Enter school name, CRICOS Code and search.',
  Z1n7urc: 'Give details of the most recent English test.',
  Z2Afbz: 'Have you thought about where you were going to live?',
  '1iTsW6': 'Enter course keyword search or enter it yourself',
  '2tv2vE': 'The recommender for this client is the original one',
  Zqn4G8: 'Are staff trained on the ESOS legislation?',
  pEQT1: 'Does this family member have a national identity card?',
  Z1iSKNX: 'Confirm setting this commissioner as a copywriter?',
  h4WX4: 'Please associate the form with the branch trigger step',
  fPluN: 'Fill in the relevant information at the time of creation',
  'apply-form:health-status.option.medical-condition': 'Medical condition',
  Z184x7J: 'The link to create a client form has been generated',
  Z24J8tv: 'Meanwhile, the recommender of this customer will be',
  '1DgXQS': 'Is this applicant a citizen of any other country?',
  pqYq3: 'Is the applicant a Pacific-Australia Card holder?',
  'app.right-content-.assistant-back-oneulink': 'Return to OneULink',
  ZYztv7: 'What is your family situation in your home-country?',
  Zw4Exn: 'OneULink - Personal Information Collection Form',
  Z2qrwmG: 'English Proficiency Test Result (if you hold one)',
  'apply-form:entrolment-status.option.intermission': 'Suspended from school',
  'apply-form:health-status.option.mobility': 'Mobmobility impairmentility',
  tvc6E: 'List three promotional activities over the last year.',
  '1lNy7X': 'ever had a chest x-ray which showed an abnormality?',
  Zprc0q: 'Has the applicant previously studied in Australia?',
  Z1rHnkc: 'Listening/speaking/reading/writing/total average score',
  '2wMJ7K': 'Click on the button to go to the office for assistance',
  '1BkNVd': 'You can click to upload or drag and drop files here',
  TxmWb: 'Applicant‘s passport information/additional information',
  '1eNQEI': 'Enter the school keyword search or enter it yourself',
  EVgYh: 'Does this family member have other current passports?',
  Z2aNzJz: 'Do you need an English test provided by the school?',
  ZQSd7T: 'The current page is not working, please log in again!',
  iED4m: 'Do the applicants have Overseas Student Health Cover?',
  Z9nx0c: '{name} Hello, your main applicant is {main}',
  focxp: 'Does the promotional page use the company email address?',
  Z1z9x6a: 'Calculate the cost and risk of half work-study program',
  Z3lEvX: 'Modify the editing year of background data successfully',
  Z2HvMy: 'Relationship of the sub-application to the main applicant',
  '1stXp2': 'The name is inconsistent with the current customer name',
  Z1EmnSu: 'You can click to upload or drag and drop files here',
  Z1rsTtu: "Is this the applicant's current employment situation?",
  Z28rmcP: "Sent when client [{name}] 's case is completed",
  '40NNE': 'Has the applicant previously held an Australian visa?',
  '1nT8H6': 'dates of any period of imprisonment or other detention',
  '1NBdSS': 'Highest level of schooling completed outside Australia',
  Z1o0jXt: 'Are you sure you want to delete this timed notification?',
  Z2bJane: 'The contract has been signed, please check the contract',
  V82O8: 'Save the text of the comments section as a PDF file',
  fng48: "Does the promotional page use the company's phone number?",
  '1LWjGr': 'To switch companies, please close all other tabs first',
  '6wS4A': 'Is the postal address the same as the residential address?',
  '1YYXlB': 'Is this family member a citizen of any other country?',
  '2iWWVD': 'Select the eligible qualification for a visa extension',
  Z12TPIt: 'Select the official documents of the Australian Immigration',
  Z1NgIQk: 'Membership in or registration with professional bodies:',
  Z2rBA4w: 'Does this family member have other identity documents?',
  Z23RjMp: 'Is this family member a child under 18 years of age?',
  '1LQS2': 'Select the visa for which the applicant intends to apply.',
  '2ponaj': 'Give details of the course the applicant is undertaking.',
  Z1VxOSG: 'Please check again if the relevant document is correct',
  Z2kwyms: 'The current file will be bound to the {name} step',
  '1i7CBH': 'The current company data or all data have been marked',
  '2bvo5F': "Give details of the family member's current location.",
  ZcW1TF: "Give details of the applicant's Australian qualifications",
  '25jWHr': 'Countries visited / Australian Federal Police check',
  '20Oyhp': 'Does the applicant have Overseas Student Health Cover (OSHC)?',
  '2fa6IO': 'Do the applicants have other acceptable health insurance?',
  bf1zI: 'Department of Home Affairs Transaction Reference Number (TRN)',
  '2ih2Y0': "Are there any legal impediments to this child's travel?",
  PytKh: 'Does this applicant have an Australian visa grant number?',
  '14UbXw': "Give details of the applicant's educational qualifications.",
  '2sWVSW': 'If the matter relates to a criminal conviction, provide:',
  Z1QtaMH: 'How long does the applicant plan to stay in Australia?',
  Z2nLHzI: 'Have you estimated how much is your living cost per week?',
  '1Re65s': 'Do you understand your work limitation on student visa?',
  eGdLX: 'To apply for a new course, please contact the commissioner',
  '2txr8E': 'Please configure the trigger submit to OneuLink step',
  Z1TPyhB: 'Company Name, ABN, Phone Number, Email Address, Address',
  Z2sPhrl: 'Has this applicant previously applied for a visa to Australia?',
  '2iijEP': 'Give details of the contact person within the organisation.',
  '10JIre': "The first letter of the client's last name is capitalized I",
  ZYHA7F: 'Give details of the contact person within the organisation.',
  ZRCe7l: 'Has the applicant been employed in the last five years?',
  '1e2nqV': "Give details of the applicant's most recently held visa.",
  Z1rETzL: 'Does the applicant have Overseas Visitor Health Cover (OVHC)?',
  Z1a50Nc: 'Has the applicant applied for an Australian parent visa?',
  HnL8K: 'Will the applicant undertake a course of study in Australia?',
  '1vv49m': 'Confirm setting this commissioner as a Marketing commissioner',
  '1jV9yp': 'Recent uploads are required to be less than 28 days old.',
  Z11Ymdc: 'Consistent with passport, please enter English input method',
  Z1IPU8A: "Birthday is inconsistent with the current customer's birthday",
  Z2fksQJ: 'Does this applicant have an Australian visa grant number?',
  Z23BKoe: 'If there is false information, it will affect visa application',
  Z20r8hQ: 'Do you have plan to stay in Australia after study? Why?',
  Gfv63: 'Have not set up school commission, no commission for this time',
  YEPUJ: 'Congratulations! Your contract has been submitted successfully!',
  ZNjWvM: 'Choose which cell phone number to use to receive text messages',
  ZSkneo: 'Are you sure you want to send a test message to {email}?',
  ZUAt3k: "Can't find a sub-applicant? Add it to the system immediately",
  '1L3VfH': 'While in Australia, stay with the nominating student; and',
  '1O1yMb': 'Type the course name and code to search for packaged courses',
  Z1FRj20: 'Assignment reminders (completed and graded), class reminders',
  Z1spc6X: 'After selection, the GTE content is placed in the ESC file',
  Z1AYq9b: 'Whether the notification related to this step will not be copied',
  ZIYK1: 'Quickly fill in the admission date in batches such as * * 1',
  '13xy2g': 'Customer birthdays are based on the {timezone} time zone',
  Z23o0dW: 'Have you taken an English Test within the last 12 months？',
  Z21pD7J: 'Residential address for the past 10 years / Skills assessment',
  ZrSPR4: "Select the education sector of the applicant's principal course.",
  Z1oUwRq: 'What would you learn from the course you are applying to study?',
  Z12hqGz: 'The trigger step cannot be set in the last step, please check',
  Z1yI2Uq: 'Pay attention: Recommenders will also change after the merger.',
  wcsF8: 'Congratulations! Your information has been submitted successfully!',
  Z5uQLb: 'Has any applicant ever been named on a sex offender register?',
  '1VCaJW': 'Who will be covering the cost of your tuition and living costs?',
  ZUvXNn: 'Symbols like / \\ : * " < > | ? ; are not allowed',
  Zn1Xsr: 'The application for batch transfer cases was successfully submitted',
  Z1lRq4s: 'Giving false or misleading information is a serious offence.',
  Z1Yndcx: "Which is the closest office to the applicant's current location?",
  ZvHRhb: 'Has any applicant ever been found by a court not fit to plead?',
  Z2gwajt: 'Previous ten years of cross-border travel experience is required',
  '2wrvqQ':
    'Does the applicant have any parents or siblings in or outside Australia?',
  '1vGtXT':
    'Does the applicant intend to enter Australia on more than one occasion?',
  '175afw': 'To Create a case for a client, please follow the process below.',
  Z1aV03S: "Sample of organisation's promotional materials and advertisements.",
  rXtMx: "Give details of how the applicant's stay in Australia will be funded",
  Z21lquL: 'Is this applicant a citizen of the selected country of passport?',
  X28B3:
    'Is this applicant currently, or have they ever been known by any other names?',
  ZFeUjI:
    'After cliking on the selected one, batch withdrawal can be initiated',
  '1A8gyk': 'Do all the applicants on this application have health insurance?',
  '1Df1Vp': 'Does the applicant currently hold a 485 Post-Study Work visa?',
  '2v9AMz': '{name} has invited you to join {company} as {job}',
  Z3SQ0T: 'What type of disability， impairment or long term medical condition',
  Z1HdcFb: 'Select the currently effective OSHC and renew the next OSHC',
  '25dPU6':
    'Select the type of Bridging visa for which the applicant is applying.',
  ZLiogo:
    'The amount entered exceeds the payable amount, please select a reason',
  '15BKla':
    'There are compassionate or compelling circumstances for the departure',
  '1guzmp':
    'Do you have a disability,impairment or long term medical condition？',
  ElphD: 'Added files can be categorized for lawyer to view and download.',
  Z1IIqlr:
    "Confirm removing the viewer's rights for the commissioner's clients?",
  Z2o7Sma: 'This email is divided into {count} emails and sent in batches',
  ZkHjBv:
    'Will the funds be provided by an individual other than the applicant?',
  Z15jfCV:
    'Does the applicant want to apply for a longer visa validity period?',
  '2jszOk':
    'SMS verification code sent successfully, please pay attention to check',
  '1Aaa1O':
    'Does this applicant have any other passports or documents for travel?',
  ZwDc7c:
    'Is this family member a citizen of the selected country of passport?',
  Z1XRqqU:
    'What type of disability， impairment or long term medical condition？',
  '4vrlw':
    'Explain how completing the course will be of benefit to the applicant.',
  '16c7Qf':
    'Have any of the applicants visited any countries in the past 10 years?',
  Z10mPmo:
    'Agree not to undertake study or training for more than three months.',
  '2s75an':
    'Please upload the documents that corresponding to the {name} category.',
  ZwtkiA:
    'Viewing clients who are approaching the commencement and graduation dates',
  '2lvW8y': "Is this child in the primary applicant's care and legal custody?",
  Z14rhhu:
    'The content of the email is uncertain and needs to be determined manually',
  ZFXE2g: 'Please check the email verification code with email address {email}',
  Z1wroyD:
    'E-mail verification code sent successfully, please pay attention to check',
  ZW2FT6:
    'Sign a contract with a superior channel that can handle this type of case',
  '1qxUwQ':
    'Does the applicant intend to travel on a United Nations Laissez-Passer?',
  '1EYmXu':
    'Does Do all applicants have Overseas Visitors Health Cover (OVHC)?',
  '2bwjVm':
    'The start date and end date of the course are duplicated, please check',
  ZNdIjJ: "Give details of the applicant's current 485 Post-Study Work visa.",
  '19PhXN':
    'respiratory disease that has required hospital admission or oxygen therapy',
  ZeNO7m:
    'Has the applicant been offered a job at the completion of their course?',
  '28ip4S':
    'Are there any other persons travelling with the applicant to Australia?',
  '27O5zD':
    'What salary would you expect? Please have an actual number in mind.',
  ZAS4q2:
    'The information of the client is different from the information on the form.',
  Z2dkamE:
    'The following information is missing and needs to be filled in by the client',
  Zhy3tY:
    'The case will not be unfrozen after freezing, please proceed with caution',
  Z1oRTb:
    'Sorry, the page was lost accidentally ~ please refresh and resubmit!',
  ZHO9l7:
    'been in close contact with a family member that has active tuberculosis?',
  Z2wjrPe:
    'Accompanying member of the family unit’s Relationship to primary applicant',
  '1sTQ2W':
    'Does the applicant have details of the related student visa application?',
  '21CtoB':
    "Give details of the applicant's Australian post-secondary qualifications.",
  ZhAzXu:
    'Reminder: The total size of sending attachments must not exceed 10 MB',
  fijoa:
    'Please review the following carefully to make sure the information is correct',
  Z1geyuH:
    'Has the applicant undertaken an English test in the last 36 months?',
  Z2uTeB2:
    'After selecting, the corresponding documents will be placed in the ESC file',
  Z2apWHO:
    'There are no results for your search, please try a different keyword search',
  Z1YGncm:
    'Convictions for criminal offences by Directors,Principals or Key Staff.',
  ngRuJ:
    'Have read the information contained in the Privacy Notice (Form 1442i).',
  nq7ne:
    'The company time zone has not been set, please contact the company management',
  '1ky1Nq':
    'Understand that the Visitor visa does not permit them to work in Australia.',
  '1aSjt2':
    'What was the amount of your first payable fees? e.g. Course Deposit and OSHC',
  ZPIFLY:
    'Whether the student has a disability, handicap, or long-term health condition',
  CuHS9:
    'Any type of case can be accepted, including the types of cases added later',
  ZpAm2N:
    'Change the type of case that can be handled in the signed superior channel',
  Z2wya1x: '"* * 1 means every Monday  1 * * means every 1st of the month"',
  ZR8KTL:
    'Have read and understood the information provided to them in this application.',
  '2fzc5j':
    'Not bound to a specific case and not available for CoE audit settlement',
  Z1pPec4:
    'What property do you or your family own in China or in any other countries?',
  Z9dMs0:
    'Please upload the enterprise WeChat, WeChat, Whatsapp, contact QR code',
  Z1dSRBN:
    'Have read the information contained in the Privacy Notice (Form 1442i).',
  Z1LjFwM:
    'Sub-applicant client profile has been created, please add as sub-applicant',
  '2tvBmz':
    'There are no packaged courses available, try changing your search keywords.',
  '29mTro': '{inviteMember} invites you to join {company} as {job}.',
  DNPB5:
    'Please check the SMS verification code for the cell phone number {phone}.',
  '1BTvlQ':
    'Visa holder whose visa being considered for cancellation or has been cancelled',
  HXfML:
    'Give details of the highest scoring English test over the 24 month period.',
  Z1lyn1n:
    'If you have a company you like, you can ask the company to invite you to join',
  Z27N8GO:
    "Give details of the applicant's highest level of schooling completed outside Australia.",
  Z1twWQr:
    'Has the applicant undertaken an English language test within the last 24 months?',
  ZdL4Xx:
    'Add the school applied for by the current case in the signed superior channel',
  Z2tm9lB:
    'Is this family member currently, or have they ever been known by any other names?',
  ZzIVkh:
    'For combined applications, state which applicant the declaration applies to.',
  '1LbfQE': 'Supports CoE, Offer, OSHC, Visa, Passport and other documents',
  Z22K4xS:
    'The order to be verified timed out and was closed by the system for 60 minutes',
  ZPfHe4:
    'Are there any accompanying members of the family unit included in this application?',
  '1wFBBJ':
    'Has any applicant ever been the subject of an arrest warrant or Interpol notice?',
  ZO8cX1:
    'Does the applicant have health insurance arranged for their stay in Australia?',
  '1WEAaf':
    'Give details of the length of unemployment and the last employment position held.',
  Zc1ILg: 'Application forms for all courses are on the following pages:',
  '1GNkgE':
    'Before you start，Please make sure you have the followings documents to attach',
  SEuSb:
    'Give consent to the collection of their fingerprints and facial image if required.',
  '268if3':
    'Will the applicant visit any relatives, friends or contacts while in Australia?',
  eVJiS:
    "This client is displayed abnormally, please change the client's email address in time.",
  cS14H:
    'You tried to use biometric fingerprint login just now, do you need to bind it now?',
  ZoGQ6g:
    'The file has already been uploaded, is it continuing to be uploaded to the system?',
  '2iwW5u':
    'Has any applicant ever overstayed a visa in any country (including Australia)?',
  Z2nYr9O:
    'No relevant search results, please enter a more accurate email or mobile number',
  '1BzGCU':
    'Give details of any other relevant information the applicant would like to include.',
  Z1zRoFX:
    'Agree to leave Australia on or before the expiry of the period of stay of the visa.',
  '1jbNu':
    'There are no search results yet. You can try changing the keywords and search again.',
  ZtkJC:
    'Do all applicants on this application have Medicare or reciprocal health cover?',
  '22q3Qy':
    'What funds will the applicant have available to support their stay in Australia?',
  '1C3VUP':
    'Has this applicant previously traveled to Australia or previously applied for a visa?',
  ZQzSLx:
    'Has any applicant ever been involved in people smuggling or people trafficking offenses?',
  '4qyJs':
    'Why would you choose this university or college, other than in your home-country?',
  Z2gYqwF:
    'Are there any steps that have not been uploaded to confirm the completion of the case?',
  Z1USQEC:
    'The following cards can be clicked and sorted to customize learning and work history',
  '1stBUK':
    'Please choose one of the following two ways to apply for cash withdrawal automatically',
  iSnol:
    'Does any other person have custodial, access or guardianship rights to this child?',
  '2e9mxi':
    'Does this applicant have previous passports that have been used to travel to Australia?',
  Zuo7G6:
    'Does any applicant require assistance with mobility or care due to a medical condition?',
  fNRCv:
    '{name} has not enabled commission settlement, and there is no commission available yet.',
  Zq85Mk:
    "Can't receive the verification code? Please try to switch to cell phone SMS verification",
  '2qPPH':
    'Unemployment includes all gaps in employment since leaving school/college/university.',
  Z200pvl:
    'If it is not operated for a long time (30 days), the system will shut down automatically',
  Z1R9Mvs:
    'The following is the CoE uploaded by the commissioner, you can click Use to select',
  ZiidN9:
    'This form will be reviewed by a lawyer, please sign the student to ensure the authenticity',
  ZfTcIR:
    'Give details of any significant dates on which the applicant needs to be in Australia',
  ZgHrcv:
    'Numbers of students sent to Australia for each of the past 3 years by education sector.',
  GNu1z:
    'Numbers of students sent to Australia for each of the past 3 years by education sector.',
  KkMxr:
    'Client system mailbox refers to the mailbox generated internally by the system for the client.',
  '1yQeLl':
    "Is this course a continuation of the applicant's studies or training outside Australia?",
  Z1E0S8T:
    "Give details of the applicant's highest level of schooling completed outside Australia",
  ZFF70F:
    "Was the Overseas Student Health Cover (OSHC) organised by the applicant's education provider?",
  ZDi10V:
    "Can't receive the verification code? Please try to switch your email address to verify the code.",
  '20PVs6':
    'Does the applicant hold evidence of their intended study in Australia other than a CoE?',
  '2ht7n2':
    'Does the applicant know the dates of entry for each occasion after first entry to Australia?',
  jrM7r:
    'Has the applicant ever had a visa for Australia or any other country refused or cancelled?',
  '1mqQbe':
    "Enter the employee's mobile phone number, email address or send an invitation link to find it",
  Z2rJXzb:
    'Give details of all accompanying family members of the family unit under 6 years of age.',
  ZTJq1Y:
    'Please make sure that you have read each declarations, and in matchs to your actual situation.',
  Z13B3xX:
    'Note: Ensure this eligible qualification is included in the details at the Education page.',
  '2uSnV9':
    'Has any applicant ever been charged with any offense that is currently awaiting legal action?',
  '2vb6Oi':
    'Reside in Australia while the nominating student, for whom the visa relates, is in Australia;',
  ZqcQUi:
    'You just tried to log in using a biometric fingerprint, do you need to bind that device now?',
  '24VB1p':
    'After the client enters their study history and work experience, a resume can be generated.',
  ZrKz5P:
    'Failed to obtain geographical location information, please manually fill in the signature location',
  '1qsUWJ':
    "Enter the customer's name and mobile phone number to search, which should not be less than 2 words",
  XA9JW:
    'Please note that this information is for reference only and needs to be confirmed by the client',
  '9ETtK':
    'Does the applicant hold a select degree that may be eligible for an extended two-year stay period?',
  '1P675t':
    "Important emails received by the client's system mailbox are automatically forwarded to the client.",
  Z1qMYvF:
    'Giving false or misleading information is a serious offense. The applicants declare that they:',
  Zih3HO:
    'The customer information has been updated since the last submission, please pay attention to check',
  '24AiwB':
    'Give details of your current visa if you already hold the Post-Study Work Replacement visa.',
  Z2g3SRH:
    "This message task is only executed when the client's preferred language is the selected language",
  '1TKsyO':
    'A warm reminder: if you still have questions, you can also add WeChat consulting related matters!',
  Z18wbyB:
    'Detected that a visa refusal document has already been uploaded and it is possible to close the case',
  Z2geLR2:
    'Has this applicant undertaken a health examination for an Australian visa in the last 12 months?',
  IFq4G:
    'Is the applicant applying for this visa due to the closure of their Australian education provider?',
  ZKNVeg:
    'Give details of the type of employment the applicant will be seeking at the completion of their course',
  '20zI62':
    'Made sure to sync the message templates and global message task configurations to other companies?',
  '1BwCvH':
    'Original document size is {originSize}, compressed document size is {currentSize}.',
  Z1D26oJ:
    'Are there any accompanying members of the family unit under 6 years old included in this application?',
  ZzIGEG:
    'HWill reside with and provide appropriate care and welfare arrangements for the nominating student.',
  '1jSliO':
    'GTE is in the generation process, it may take a long time, please be patient and wait for the change',
  '2gnnJf':
    "Important emails received by the client's system mailbox are not automatically forwarded to the client",
  Z13uWun:
    'There are no changes to the current filing cabinet documents, and you can add or delete documents',
  Z1S5Aks:
    'Here can upload multiple client materials at once, click to upload or drag and drop files here',
  '1sXhTx':
    'Has any applicant ever been removed, deported or excluded from any country (including Australia)?',
  Yytu:
    'In the current mode, a new version will be created, please fill in the corresponding version description',
  '2okaTO':
    '{name} The commission is settled in a fixed amount, only  to be settled once in the same case.',
  Z1ykm7I:
    'Has this applicant undertaken a health examination for an Australian visa in the last 12 months?',
  Z1DXJpl:
    'Provide appropriate accommodation, general welfare and support arrangements for the nominating student.',
  oijJS:
    'Visa expiration date, passport expiration date is based on Australia/Melbourne time zone',
  Z2jBIxF:
    'Select the main industry type in which the applicant intends to work during their time in Australia.',
  ZsFk7L:
    'Has any applicant ever been acquitted of any offense on the grounds of unsoundness of mind or insanity?',
  '7iUwx':
    'Acknowledge that if granted a student visa, they are required to understand and abide by its conditions.',
  ZGNKa7:
    'Is the applicant a parent or step-parent of an Australian citizen or Australian permanent resident?',
  Z110eUi:
    'Has the applicant held or does the applicant currently hold a visa to Australia or any other country?',
  WMQm3:
    'Current or past legal proceedings against the organization, its Directors, Principals or Key Staff.',
  Zuh0C9:
    'If you have any questions about how to use it, you can check here, and you can also check the update log',
  '2rjIov':
    'Have provided complete and correct information in every detail on this form, and on any attachments to it.',
  uFw6d:
    'Does the applicant hold a Confirmation of Enrolment (CoE) for any intended course of study in Australia?',
  '9YFbg':
    'Create a company/team/organization, act as an administrator, and invite team members to use it together',
  ZOMy7l:
    'In this case, {name} is entitled to a commission of {value} with a fixed amount of settlement',
  Z2vEOHV:
    'The latest version of the current system is detected as {version} and needs to be upgraded for use.',
  '1XLSb7':
    'There are no packaged courses at this time. Enter the course name and code to search for packaged courses.',
  Z1hPIYL:
    'You can continue to edit the client information, and the edited information will be merged into the Client',
  ZenDSV:
    'Note that a street address is required. A post office address cannot be accepted as an organisation address.',
  Z134XFq:
    'Give details of the highest academic qualification the applicant has obtained from the countries indicated above',
  Z2xlrR:
    'Note that a street address is required. A post office address cannot be accepted as a organisation address.',
  Zz1a1g:
    'If the applicant answers ‘Yes’ to any of the character declarations they must give all relevant details.',
  '5VCSj':
    'The end date of the renewed insurance is later than the end date of the CoE and does not need to be renewed.',
  Z1EHRLh:
    'In the 10-year travel history form, you need to provide the past 10 years of cross-border travel experience.',
  '14vLcf':
    'This is a case that was processed with the help of a participating agency but not submitted to OneULink',
  ZYK0YB:
    'Are you providing assistance with an application process, a cancellation process or specific matter?',
  '2nO6iR':
    'Do you comply with key obligations under the Education Services for Overseas Students (ESOS) legislation?',
  amzpu:
    'Has any applicant ever had any outstanding debts to the Australian Government or any public authority in Australia?',
  '2rfI1a':
    'Did the applicant hold a previous Visitor visa that was impacted by COVID-19 related travel restrictions?',
  Z2iyX5l:
    'Introducing 3 practical function guides every day to help you use the system better and more efficiently',
  Z23fbo8:
    'The current Pdf document size is {size}, which exceeds the maximum document size limit of {max}.',
  '1EN2RF':
    "Give details of the applicant's employment and unemployment history since leaving school/college/university.",
  '28STla':
    'Has the applicant met the Australian study requirement in the six months before the date of this application?',
  Z23Ndml:
    'Has the applicant completed, or is currently enrolled in any other studies or training outside Australia?',
  ZyQaFL:
    'At least one study and one work history is required for resume, please upload student-related history information',
  '264DH':
    'Give the reason the applicant cannot provide details of a national identity card issued by their country of passport.',
  '26QWGJ':
    'If the maximum file size {max} is exceeded, you can continue to compress the file or compress it yourself.',
  '29rSIi':
    'Acknowledge that if granted a Student Guardian visa, they are required to understand and abide by its conditions.',
  '262Flo':
    'Understand and acknowledge that condition 8537 is imposed on this visa, and this means that the applicant must:',
  '2ceUaJ':
    'In order for {company} to serve you better, please click the link to improve your personal information.',
  c1DNk:
    'Give details of why the applicant is at their current location, including the end date of their current visa.',
  BrW8S:
    'Give details of why the applicant is at their current location, including the end date of their current visa.',
  '1oosl':
    'Give details of why the applicant is at their current location, including the end date of their current visa.',
  Z1ebtDO:
    "Is the applicant applying for this visa due to the closure of the related student's Australian education provider?",
  '2ipVdH':
    'Give details of why the applicant is at their current location, including the end date of their current visa.',
  XwQL6:
    'Give details of all residential addresses for all applicants for the past 10 years since turning 16 years of age.',
  Z1BPTaO:
    'Required to provide the Commissioner with information on cross-border travel experience for the past 10 years of travel.',
  ZeooWc:
    "Forgot your password? Don't worry, just type in your email below and we'll send you a temporary password",
  Sq6nx:
    'Thank you for your support of our work! Our staff will contact you as soon as possible within 48 hours!',
  '1gFIyg':
    'Important: Overseas Student Health Cover (OSHC) is not deemed adequate health cover for the purposes of this visa.',
  HJQyo:
    "Provide responses to support the applicant's ability to meet the criteria and ensure supporting documents are attached.",
  Z1QOaIH:
    'Thank you for your support of our work! Our staff will contact you as soon as possible within 48 hours!',
  Z9YBzK:
    'Does any applicant intend to enter a hospital or a health care facility (including nursing homes) while in Australia?',
  Z1867c9:
    'Is the applicant applying for a multiple stay visa which may allow the applicant to stay up to 12 months per visit?',
  '12Fnly':
    'Is the applicant travelling as a representative of a foreign government, or travelling on a United Nations Laissez-Passer?',
  Z2wFY20:
    'Note: Ensure details are provided for any eligible qualification advised by the applicant at the Application context page.',
  Z1XBy99:
    'Please provide two referees from Australian Institutes / Colleges / Universities with which you have an Agency agreement.',
  '5jfA6':
    'Names of other clients you are providing immigration assistance to in relation to the same matter (eg. dependant applicants)',
  '1jhrEY':
    'Does any applicant intend to work as, or study to be a doctor, dentist, nurse or paramedic during their stay in Australia?',
  Zgly1H:
    'CoE {coeNumber} [{date}] has an overlap problem with CoE {otherCoENumber} [{otherDate}].',
  ZGhmJL:
    'Has any applicant ever been associated with a person, group or organization that has been or is involved in criminal conduct?',
  Z1cGw4e:
    'The school I am applying to is Melbourne College of Business and Technology in English.',
  '29AaJ5':
    "The customer's birthday and email address already exist in the system, and you need to select an existing customer to merge",
  '2qxv3T':
    'One of the following is required: Australian contact address and telephone number and domestic contact address and telephone number',
  '1ci146':
    "Client's Australian contact phone number, Australian contact address or domestic contact address, domestic contact phone number",
  '1C1BB2':
    'The Agent {name} has signed a contract with the superior channel, but the superior channel cannot handle this type of case',
  Z1gmSFL:
    "Here are cases that haven't been updated in a long time, probably forgot to update the process or forgot to close the case.",
  Z26tqfz:
    'The current batch of data has been labeled, you can click on the confirm button, it will automatically refresh the current page',
  Z2aDECg:
    'The Agent {name} has not signed a contract with any superior channel, please sign up with the corresponding superior channel',
  '1ssg3X':
    'Has the applicant applied to a relevant assessing authority for an assessment of their skills for their nominated skilled occupation?',
  Z1poP5A:
    'Is the applicant receiving partial or full funding under a training scheme approved by the Commonwealth government of Australia?',
  Z1yxJAQ:
    "Give details of the applicant's current circumstances, including ties to family, community, employment and economic circumstances.",
  Z2cIbxt:
    "Give details of the applicant's current circumstances, including ties to family, community, employment and economic circumstances.",
  '1RgU7w':
    'Has any applicant ever been found guilty of a sexually based offense involving a child (including where no conviction was recorded)?',
  Z2mL8ws:
    'When a client has not filled out the visa form, they can quickly fill out the form by uploading the immigration visa form document',
  '2kNsbO':
    'You can check the documents that other colleagues have added and categorized, and you can also download all the documents as a package.',
  Z1a9Kl6:
    'Has the applicant, or any person included in this application, held or currently hold a visa to Australia or any other country?',
  '1IvVYy':
    'Has any applicant ever been convicted of an offense in any country (including any conviction which is now removed from official records)?',
  '1zx3E4':
    'Understand that if they cannot obtain a further visa they must depart Australia on or before the cease date of the student visa.',
  '6o6ke':
    'The number of document downloads has exceeded the limit, you need to enter the e-mail verification code to continue to download the document',
  Z133l6I:
    'Websocket The connection failure will affect your normal use of the system, please notify the administrator or refresh the browser',
  Zx7oYa:
    'Give details of any other non United Nations passports or documents for travel that might have been previously used to travel to Australia',
  '1HLcUL':
    'Visa ESC document request was generated successfully, please be patient and wait for a few minutes before checking in the case comment section',
  Z2peIja:
    'The number of document downloads has exceeded the limit, please enter your cell phone verification code to continue to download the document.',
  qM1k7:
    'Give details of the compassionate and compelling reasons for granting a member of the family unit under 6 years of age a student guardian visa',
  '10bPPH':
    'Must be filled out: Emergency Contact Name, Relationship to Emergency Contact, Emergency Contact Location, Emergency Contact Phone Number',
  ojWdz:
    'After the balance of points reaches this value, you will automatically apply for withdrawal, please enter the number of points not less than 500',
  ZTwWRg:
    'Are all applicants fully or partially covered under a Reciprocal Health Care Agreement (RHCA) for the total period of stay in Australia?',
  tropT:
    "Where the nominating student is less than 18 years the alternative arrangements have been approved by the nominating student's education provider.",
  Z2rirqs:
    'Thank you for your support of our work! For specific related matters,Your staff will contact you as soon as possible within 48 hours!',
  Z1XtgVj:
    'During their proposed visit to Australia, does any applicant expect to incur medical costs, or require treatment or medical follow up for:',
  Z16al1T:
    'Is the related student applicant receiving partial or full funding under a training scheme approved by the Commonwealth government of Australia?',
  Z2612FV:
    'In the last 10 years have any of the applicants visited any countries outside of their usual country of residence since turning 16 years of age?',
  z39x3:
    "Does the applicant have any members of their family unit that have not already been included as an 'Accompanying member of the family unit' in this application?",
  '2k3vMM':
    'Have all applicants aged 16 years or over, applied in the last 12 months to the Australian Federal Police for a check of criminal records?',
  '2dgPqA':
    'Has any applicant ever served in a military force, police force, state sponsored / private militia or intelligence agency (including secret police)?',
  '17eCPK':
    'Does the applicant have any members of their family unit not travelling to Australia who are not Australian citizens or Australian permanent residents?',
  '3tg8t':
    'Understand that if this application is approved, any person not included in this application will not have automatic right of entry to Australia.',
  '1PRFrG':
    'Dear customer, the system will be {start } update system, is expected to {end} end, so please understand the inconvenience caused to you!',
  Z1j2w1L:
    'Understand that if this application is approved, any person not included in this application will not have automatic right of entry to Australia.',
  '2oLx2G':
    'Has the applicant ever been in Australia or any other country and not complied with visa conditions or departed outside their authorized period of stay?',
  '23lt7a':
    'Understand that if documents are found to be fraudulent or information to be incorrect after the grant of a visa, the visa may subsequently be cancelled.',
  '23TpIt':
    'Understand that if documents are found to be fraudulent or information to be incorrect after the grant of a visa, the visa may subsequently be cancelled.',
  Z1gy5Na:
    'Has the applicant completed a recognised qualification at a specified educational institution in the 24 months immediately before lodging this application?',
  Z2qgdRb:
    'All student visa applicants are required to provide information in support of their application to demonstrate that they meet the Genuine Student criteria.',
  ZtVp2Q:
    'Employment includes all paid employment, self-employment, family business employment, work experience, internships, unpaid employment and volunteer work.',
  Z2pS97h:
    'Has any applicant ever undergone any military/paramilitary training, been trained in weapons/ explosives or in the manufacture of chemical/biological products?',
  '1AWPf9':
    'Once the client has filled out the visa information form, the tool can be used to pre-write a GTE, noting that the article is for informational purposes only',
  '2oN3M3':
    'Have read and understood the information provided on the website (www.studyinaustralia.gov.au) regarding living and studying in Australia.',
  ZuQWpb:
    'Show how each applicant included in the application will support themselves in Australia to meet living, tuition and school costs. Select any that apply.',
  '1DYDO9':
    'Why would you consider to studying Australia and work in China later? In other words, why an Australian education degree would help you find a job in China?',
  '1R8BlH':
    "The current client's insurance effective date covers the course's insurance effective date and does not need to renew OSHC or can cancel the purchase of OSHC",
  Z2etql1:
    'When the case is in progress, for some reason, the case can be hung up and wait for later processing, and after hanging it up, it can also be restored as in progress.',
  Z1DJGBg:
    "Alternative arrangements for the nominating student's accommodation, general welfare and other support have been put in place until the holder returns to Australia; and",
  ZDsTJ5:
    'It recognizes that you uploaded the {label} file, it may have been uploaded incorrectly, you can choose to go to the customer details to upload the corresponding file',
  '2u55Q8':
    'In the last five years, has any applicant visited, or lived, outside their country of passport, for more than 3 consecutive months? Do not include time spent in Australia?',
  rP8QQ:
    'Has any applicant ever been directly or indirectly involved in, or associated with, activities which would represent a risk to national security in Australia or any other country?',
  Z1opxcp:
    'Understand that if a no further stay 8503 condition is imposed on this visa, it will limit the ability to remain in Australia beyond the authorized period of stay of the visa.',
  Z1TPCtj:
    'Once the client has completed the Student Visa Information Form, an organized client file can be generated to help the client better respond to the Immigration Visa Evaluation',
  Z1URSev:
    'If the maximum file size {max} is still exceeded after compressing again, please compress the document by yourself, and it is recommended to split the Pdf document and upload it',
  iKOlt:
    'Has the applicant, or any person included in this application, ever had an application for entry or further stay in Australia or any other country refused, or had a visa cancelled?',
  Z2t8Nus:
    'You will need to understand thoroughly what is involved in the visa application. Your responses must be consistent with the evidences you have provided in your visa application.',
  Z17lJm4:
    "This client is a duplicate of a client that already exists in the system, please select Duplicate Client and the selection will merge the client's information into the selected client.",
  ZIkvVa:
    'If the client has provided the official visa form document generated by the Immigration Department, you can upload the document here and fill in the information for the client with one click.',
  '1t2OmC':
    "Give consent to the Department using the applicant's biometric, biographical and criminal record information obtained for the purposes of the Migration Act 1958 or the Citizenship Act 2007.",
  '2kwUSQ':
    'In the two years before applying for the Student visa, has the applicant successfully completed the requirements for a Senior Secondary Certificate of Education (conducted in English in Australia)?',
  ZuJ49q:
    'Has any applicant ever been charged with, or indicted for: genocide, war crimes, crimes against humanity, torture, slavery, or any other crime that is otherwise of a serious international concern?',
  '1VIpyY':
    "The Pacific-Australia Card is issued through an invitation process to Pacific leaders. If the applicant does not hold a Pacific-Australia Card, please select 'No' for this question.",
  qISyW:
    'Has the applicant, or any person included in this application, ever been in Australia or any other country and not complied with visa conditions or departed outside their authorised period of stay?',
  '9MGe6':
    'Has any applicant ever been the subject of a domestic violence or family violence order, or any other order, of a tribunal or court or other similar authority, for the personal protection of another person?',
  '13k715':
    'A further student visa (subclass 500) in circumstance where the Commonwealth, or foreign government (as the case requires), does not oppose them from obtaining a further visa to stay in Australia.',
  Z2tfUDY:
    'Has any applicant ever been associated with an organization engaged in violence or engaged in acts of violence (including war, insurgency, freedom fighting, terrorism, protest) either overseas or in Australia?',
  Z1mKeTa:
    'Dear customer, in order to ensure the security of your personal information, it is necessary to verify your relevant personal information, please click on the next step to carry out the relevant verification.',
  Z6mRvT:
    'Congratulations! You are embarking on an exciting and new study opportunity in Australia. You may have a lot of questions about visa application process – please discuss this with your registered agent.',
  Z1FDGQX:
    'Have made adequate arrangements for health insurance for the period of their stay in Australia and acknowledge they are required to maintain these arrangements while in Australia as the holder of a student visa.',
  Z2tjO0h:
    'Do all applicants in this application confirm that they each have access to sufficient funds to support themselves for the total period of stay in Australia and understand that further evidence of funds may also be requested?',
  ZHJaD7:
    'Each applicant who is 18 years or over has read, or had explained to them, information provided by the Australian Government on Australian society and values, and agrees to the Australian values statement.',
  KtiDY:
    'Each applicant who is 18 years or over has read, or had explained to them, information provided by the Australian Government on Australian society and values, and agrees to the Australian values statement.',
  kkWk0:
    'Else, give details of the 485 visa you previously held that was impacted by COVID-19 travel restrictions if you are applying for either the Graduate Work Replacement or Post-Study Work Replacement visa.',
  '1NjEfF':
    "Understand that the department may collect, use and disclose the applicant's personal information (including biometric information and other sensitive information) as outlined in the Privacy Notice (Form 1442i).",
  ZOxmBt:
    'Explain why the applicant wishes to study this course in Australia with this particular education provider including their understanding of the requirements of the intended course and studying and living in Australia.',
  ZrVo4I:
    'Understand that a student visa is a temporary visa and that being granted a student visa will not guarantee that they will be eligible for the grant of a further visa to stay in Australia, including a skilled migration visa.',
  '2qFJW2':
    'Has any of the study undertaken towards the eligible qualification been done online while the applicant was outside Australia, but holding a student visa during the period 1 February 2020 to 15 December 2021?',
  Z2rUtPM:
    'Have made adequate arrangements for health insurance for the period of their stay in Australia and acknowledge they are required to maintain these arrangements while in Australia as the holder of a student guardian visa.',
  Z1DrU2p:
    'Please note: Your response to this questionnaire and any subsequent agreement with us is an agreement between your organization and Barton College. This, and any other collaborator must be identified in writing.',
  Z1Wrsx4:
    'Has the applicant successfully completed at least five years study (in the English language) in Australia, Canada, New Zealand, Republic of South Africa, Republic of Ireland, United Kingdom and/or United States of America?',
  ZGdcXO:
    'Understand that the effect of 8534 visa condition is that it will not be possible to apply to remain in Australia beyond the date authorized on the student visa and that they will be required to depart Australia on or before that date.',
  ZqTyiA:
    'Please read the following undertaking carefully and ensure that you have fully understood and agreed to its contents before you formally sign it, and feel free to contact us if you have any questions or comments about the following instructions.',
  uTr72:
    'Understand and acknowledge that condition 8538 is imposed on this visa, and this means that the applicant must not depart Australia without the nominating student to whom this visa relates unless they have given the department evidence that:',
  rB063:
    'Will inform the Department in writing immediately as they become aware of a change in circumstances (including change of address) or if there is any change relating to information they have provided in or with this application, while it is being considered.',
  Z1NpR1g:
    'Note: Do not add a Letter of offer for a course that has already been included in the Confirmation of enrolment section above. Adding a CoE and a Letter of offer for the same course is unnecessary and may lead to delays in processing the application.',
  ZJc4Kk:
    "Give consent to Australian law enforcement agencies disclosing the applicant's biometric, biographical and criminal record information to the Department to help identify the applicant, to determine eligibility for grant of a visa and for law enforcement purposes.",
  zBJN7:
    'Will inform the Department in writing immediately as they become aware of a change in circumstances (including change of address) or if there is any change relating to information they have provided in or with this application, while it is being considered.',
  '1Td1ES':
    'Based on previous experience, we recommend that the commissioner assist the customer to fill in the first two pages of information, and then click Save to let the customer continue to fill in the information. Because the first two pages are the pages with the most problems.',
  Zom0Mr:
    "According to the system visa form information to fill out the official form of the Department of Immigration, can use the tool to verify the form documents generated by the Department of Immigration, and whether the client's name, date of birth, passport number is the same",
  Z5c0t0:
    'Client contact mailboxes are revamped in order to provide schools with the option to use system mailboxes, so that clients no longer miss important mailboxes, such as bill payment notifications, reminders of arrears, and reminders of assignments (completion and marking)',
  '18Ossr':
    'Warm reminder: In order to prevent the loss of contact with you, we recommend that you fill in at least one social account contact information, so that the commissioner can more quickly get in touch with you, we will keep your personal information strictly confidential.',
  '1LUwYv':
    'The responses to the questions above indicate that this family member holds no citizenship and is not stateless. If this is not correct, then amend the answers above. If this is correct, give details as to why the family member is not a citizen of any country and is not stateless.',
  Z12ypEU:
    'It has been more than 5 months since you last updated your personal information. To ensure that your case is handled more efficiently, please update your personal information as soon as possible. If you have recently updated, please ignore this reminder. Thank you for your cooperation!',
  '2rNyHc':
    'Note: If the request for further stay will result in the applicant being authorised to stay in Australia for more than 12 months on certain visitor, working holiday and bridging visas, they must demonstrate that they have exceptional reasons for the further stay. Provide all details.',
  VI2Qw:
    'In the two years before applying for the Student visa, has the applicant successfully completed a substantial component of a course leading to a qualification from the Australian Qualifications Framework at Certificate IV or higher as a holder of a Student visa (conducted in English in Australia)?',
  Z2rW22R:
    'Understand that the effect of the 8534 visa condition is that, apart from a further Student Guardian visa, it will not be possible for the applicant to apply to remain in Australia beyond the date authorized by this visa and the applicant will be required to depart Australia on or before that date.',
  '1KFFhW':
    'I understand that if my visa ceases to be in effect and I do not hold another visa to remain in Australia at that time, I will be an unlawful non-citizen under the Migration Act 1958. As such, I will be expected to depart from Australia, and be subject to removal under the Migration Act 1958.',
  lYxY2:
    "Note: In most cases the members of the applicant's family unit cannot come with them to Australia as part of this visa. They must be under 6 years of age and the applicant needs to provide compassionate and compelling reasons for a student guardian visa to be granted to a child who is a member of their family unit.",
  '2cs621':
    'Understand that the 8534 visa condition will be imposed on this visa, and will be indicated by the condition code 8534 and by the short description No Further Stay. The applicant acknowledges that this means that while in Australia, they will only be entitled to the grant of limited visas including a further Student Guardian visa.',
  kz9b:
    'Understand that if any fraudulent documents or false or misleading information has been provided with this application, or if any of the applicants fail to satisfy the Minister of their identity, the application may be refused and the applicant(s), and any member of their family unit, may become unable to be granted a visa for a specified period of time.',
  Z24SrRg:
    "Understand that, if required to provide their fingerprints and facial image, the applicant's fingerprints and facial image and biographical information held by the Department may be given to Australian law enforcement agencies to help identify the applicant and determine eligibility for grant of the visa being applied for, and for law enforcement purposes.",
  mjQSf:
    'Understand that if the 8534 visa condition is imposed on the student visa, it will be indicated by the condition code 8534 and by the short description No Further Stay.They acknowledge that this means the 8534  condition has been imposed on their visa and while in Australia they  will only be entitled to the grant of limited visas including:',
  '2d2U7f':
    'Understand that if the 8535 visa condition is imposed on the student  visa, it will be indicated by the condition code 8535 and by the short description No Further Stay. They acknowledge that this means that the 8535 condition has been imposed on their visa and while in Australia they will only be entitled to the grant of limited visas including:',
  ZagYJG:
    ", agree that the information that I have provided to Barton College in this Education Agent Profile is true and correct and the time of submission. I understand that Barton College will make an assessment of this organization's ability to be engaged as an Education Agent based on the information that I have provided. I declare that I don’t have any previously unethical marketing behavior.",
  '1WwKxp':
    'Understand that the effect of the 8535 visa condition is that it will not be possible to remain in Australia beyond the date authorized on the student visa, unless they are applying for a further student visa in circumstances where the Commonwealth or a foreign government (as the case requires) does not oppose them undertaking a relevant course of study, and that they will be required to depart Australia on or before the authorized date.',
  Z1eto3G:
    'There are a number of documents required in your visa application. You may also be required to participate in a telephone interview. Interviews are conducted by visa officers. This document is to assist you to prepare well during the interview. The visa officer will be assessing your visa application based on your own explanation on: study plan, intension of study in Australia, knowledge of living arrangements, program familiarity and how study overseas will assist your career in the future, etc.',
};
