import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum SubmissionTypeEnum {
  AGENT = 1,
  ONEULINK = 2,
}

export const useSubmissionTypeEnum = () => {
  const { formatMessage } = useFormatMessage();

  const caseSubmissionTypeEnum = useMemo(
    () =>
      new Map([
        [
          SubmissionTypeEnum.ONEULINK,
          {
            text: /* @message.en-US Submit by OneULink  */ formatMessage(
              'OneULink提交',
            ),
          },
        ],
        [
          SubmissionTypeEnum.AGENT,
          {
            text: /* @message.en-US Self Submission */ formatMessage(
              '自行提交',
            ),
          },
        ],
      ]),
    [formatMessage],
  );

  return caseSubmissionTypeEnum;
};
