import React, { createContext, useContext } from 'react';
import { useModalFormActions } from '@/hooks';
import TodoViewer from './TodoViewer';

const TodoGlobalViewerContext = createContext<{
  onView?: (id: number) => void;
}>({});

export const useTodoGlobalViewerContext = () =>
  useContext(TodoGlobalViewerContext);

export const TodoGlobalViewerProvider: React.FC = ({ children }) => {
  const [{ open, ...viewerParams }] = useModalFormActions();

  return (
    <TodoGlobalViewerContext.Provider
      value={{
        onView: open,
      }}
    >
      {children}
      <TodoViewer {...viewerParams} />
    </TodoGlobalViewerContext.Provider>
  );
};
