import { ENV } from '@/env';
import { getId } from '@/utils';
import { hash, md5 } from './digest';

export const getEncryptParams = (now?: number) => {
  const timestamp = now || Date.now();
  const nonce = md5(getId()).toString();
  const requestId = hash(`${timestamp}@${nonce}`);

  return {
    nonce,
    timestamp,
    requestId,
  };
};

export const getUrl = (url: string) => {
  const arr = url.split(/\s+/);
  const t = arr.length === 2 ? arr[1] : arr[0];

  return `${ENV.apiBaseUrl}${t}`;
};
