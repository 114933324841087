import {
  ClockCircleOutlined,
  DeleteOutlined,
  ReadOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import ProCard from '@ant-design/pro-card';
import { Typography } from 'antd';
import classNames from 'classnames';
import { services } from '@/api';
import { CustomAvatar } from '@/common';
import { IAccessable } from '@/components/Accessable';
import { useFormatMessage, useIsEn } from '@/hooks';
import { formatDate } from '@/utils';
import ActionButton from '../ActionButton';
import styles from './TodoMessageTaskCard.less';
import { TodoDetailMessageTaskData } from './type';

const TodoMessageTaskCard: React.FC<{
  todoId: number;
  data: TodoDetailMessageTaskData;
  onRefresh?: () => void;
}> = ({ data, todoId, onRefresh }) => {
  const isEn = useIsEn();
  const { formatMessage } = useFormatMessage();

  const { templateContent, id, triggerDate, recipient } = data;

  const emailSubject = templateContent[isEn ? 'emailEnTitle' : 'emailCnTitle'];
  const emailContent =
    templateContent[isEn ? 'emailEnContent' : 'emailCnContent'];

  return (
    <ProCard
      title={emailSubject}
      extra={
        <IAccessable authority="front.todoTask.messageTask.del">
          <ActionButton
            action={services.todo.deleteTodoMessageTask}
            params={{ todoId, id }}
            confirmContent={formatMessage('确定删除该定时通知吗？')}
            onOk={onRefresh}
            text={<DeleteOutlined />}
          />
        </IAccessable>
      }
      className={styles.card}
      bordered
      headerBordered
    >
      <ul>
        <li className={styles.cell}>
          <div style={{ color: '#1890FF' }} className={styles.cellHeader}>
            <ReadOutlined />
            <span>{formatMessage('内容')}</span>
          </div>
          <div className={classNames(styles.cellBody, styles.content)}>
            <Typography.Paragraph ellipsis={{ rows: 1, expandable: true }}>
              {emailContent}
            </Typography.Paragraph>
          </div>
        </li>
        <li className={styles.cell}>
          <div style={{ color: '#FF8C39' }} className={styles.cellHeader}>
            <ClockCircleOutlined />
            <span>{formatMessage('到期时间')}</span>
          </div>
          <div className={styles.cellBody}>
            {triggerDate.map((date, index) => (
              <div key={date.Id}>
                {triggerDate.length > 1 ? `${index + 1}. ` : ''}
                {formatDate(date.expectDatetime, true)}
              </div>
            ))}
          </div>
        </li>
        <li className={styles.cell}>
          <div style={{ color: '#C466D3' }} className={styles.cellHeader}>
            <TeamOutlined />
            <span>{formatMessage('接收专员')}</span>
          </div>
          <div className={styles.cellBody}>
            <div className={styles.staffList}>
              {recipient.map((staff) => (
                <span key={staff.Id} className={styles.staff}>
                  <CustomAvatar alt={staff.nickname} />
                  {staff.nickname}
                </span>
              ))}
            </div>
          </div>
        </li>
      </ul>
    </ProCard>
  );
};

export default TodoMessageTaskCard;
