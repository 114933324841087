import { CheckOutlined } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Dropdown, Menu } from 'antd';
import { isFunction } from 'lodash';
import React, { useState } from 'react';
import { TodoStatus } from '@/data';
import useTodoStatusStateMachine, {
  TodoStatusSelectedResult,
} from './useTodoStatusStateMachine';
import useTodoStatusTagConfigs from './useTodoStatusTagConfigs';

const TodoStatusDropdown: React.FC<{
  value?: TodoStatus;
  onChange?: (data: TodoStatusSelectedResult) => void;
  children: React.ReactElement | ((visible: boolean) => React.ReactElement);
}> = ({ children, ...props }) => {
  const tagConfigs = useTodoStatusTagConfigs();
  const [visible, setVisible] = useState(false);
  const [
    selectedTag,
    setSelectedTag,
  ] = useControllableValue<TodoStatusSelectedResult>(props);
  const [
    { matchedStatusList, handleSelect },
    holder,
  ] = useTodoStatusStateMachine(props.value);

  const list = Object.entries(tagConfigs)
    .map(([value, item]) => ({
      value: value as TodoStatus,
      ...item,
    }))
    .filter((item) =>
      matchedStatusList.find((matched) => matched === item.value),
    )
    .sort((a, b) => a.order - b.order);

  const handleSelectTag = (current: TodoStatusSelectedResult) => {
    setSelectedTag(current);
    setVisible(false);
  };

  const content = isFunction(children) ? children(visible) : children;

  if (list.length === 0) {
    return content;
  }

  return (
    <>
      <Dropdown
        visible={visible}
        onVisibleChange={setVisible}
        overlay={
          <Menu
            selectedKeys={selectedTag ? [selectedTag.status] : undefined}
            style={{ width: 200, borderRight: 0 }}
            items={list.map((item) => ({
              icon: React.createElement(item.icon, {
                style: { color: item.color, fontSize: 16 },
              }),
              key: item.value,
              onClick: async () => {
                setVisible(false);
                const data = await handleSelect(item.value);

                handleSelectTag(data);
              },
              label: item.text,
              style: {
                paddingTop: 8,
                paddingBottom: 8,
              },
              itemIcon: item.value === selectedTag?.status && <CheckOutlined />,
            }))}
          />
        }
        trigger={['click']}
      >
        {content}
      </Dropdown>
      {holder}
    </>
  );
};

export default TodoStatusDropdown;
