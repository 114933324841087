import { CloseOutlined } from '@ant-design/icons';
import { useKeyPress } from 'ahooks';
import React, { Suspense, memo, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { lock, unlock } from 'tua-body-scroll-lock';
import { Dynamic } from '@/utils/fixedDynamic';
import type { PdfViewProps } from './PdfView';
import styles from './index.less';

const PdfView = Dynamic(() => import('./PdfView'));

export interface PdfViewerProps extends PdfViewProps {
  visible: boolean;
  onClose?: () => void;
  extra?: React.ReactNode;
}

const PDFLightbox: React.FC<PdfViewerProps> = ({
  visible,
  onClose,
  extra,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) {
      lock(document.body);
    }

    return () => unlock(document.body);
  }, [visible]);

  useKeyPress('esc', () => {
    if (visible) {
      onClose?.();
    }
  });

  const body = (
    <div ref={ref} className={styles.lightbox}>
      <span className={styles.closeBtn}>
        <CloseOutlined onClick={onClose} />
      </span>

      <div className={styles.wrapper}>
        <Suspense fallback={null}>
          <PdfView key={rest.url} {...rest} />
        </Suspense>
      </div>

      <div className={styles.extra}>{extra}</div>
    </div>
  );

  return visible
    ? createPortal(
        <>
          <div className={styles.mask} />
          {body}
        </>,
        document.body,
      )
    : null;
};

export default memo(PDFLightbox);
