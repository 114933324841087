import { Rule, RuleObject } from 'antd/es/form';
import { useIntl } from 'umi';
import { fileValidator } from '@/utils/validator';
import useCommonMessages from './useCommonMessages';

const useCommonRules = () => {
  const { normalMessages, textLengthMessage } = useCommonMessages();
  const { formatMessage } = useIntl();
  const inputRules: RuleObject[] = [
    {
      required: true,
      message: normalMessages.input.requiredTips,
    },
  ];

  const selectRules: RuleObject[] = [
    {
      required: true,
      message: normalMessages.select.requiredTips,
    },
  ];

  const fileRules: RuleObject[] = [
    {
      required: true,
      message: normalMessages.file.requiredTips,
      validateTrigger: 'onCompleted',
      validator: fileValidator,
    },
  ];

  const addRules: RuleObject[] = [
    {
      required: true,
      type: 'array',
      message: normalMessages.add.requiredTips,
    },
  ];

  const emailRule: Rule = {
    type: 'email',
    message: formatMessage({ id: 'form:email.wrong-format' }),
  };

  const textLengthRule: (min: number, max: number) => Rule = (min, max) => ({
    min,
    max,
    message: textLengthMessage(min, max),
  });

  return {
    inputRules,
    selectRules,
    fileRules,
    addRules,
    textLengthRule,
    emailRule,
  };
};

export default useCommonRules;
