import { Moment } from 'moment';
import React from 'react';
import { formatDate } from '@/utils';

const FormatDate: React.FC<{
  format?: string;
  time?: string | Moment;
  minute?: boolean;
}> = ({ time, minute, format }) => {
  const value = time ? formatDate(time, minute, format) : '-';

  return <>{value}</>;
};

export default FormatDate;
