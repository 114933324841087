import { useIntl } from 'umi';

type ValidationKey = 'file' | 'input' | 'select' | 'add';

const useCommonMessages = () => {
  const { formatMessage } = useIntl();
  const keys: ValidationKey[] = ['file', 'input', 'select', 'add'];

  const normalMessages = keys.reduce(
    (map, key) => ({
      ...map,
      [key]: {
        placeholder: formatMessage({ id: `form:${key}.placeholder` }),
        requiredTips: formatMessage({ id: `form:${key}.required-tips` }),
      },
    }),
    {} as Record<
      ValidationKey,
      {
        requiredTips: string;
        placeholder: string;
      }
    >,
  );

  const textLengthMessage: (min: number, max: number) => string = (min, max) =>
    formatMessage({ id: 'form:text.length' }, { min, max });

  const sureBtnText = formatMessage({ id: 'form:sure-btn-text' });
  const submitBtnText = formatMessage({ id: 'form:submit-btn-text' });
  const cancelBtnText = formatMessage({ id: 'form:cancel-btn-text' });
  const editBtnText = formatMessage({ id: 'form:edit-btn-text' });
  const deleteBtnText = formatMessage({ id: 'form:delete-btn-text' });
  const previewBtnText = formatMessage({ id: 'form:preview-btn-text' });
  const viewDetailText = formatMessage({ id: 'form:view-detail-text' });
  const addBtnText = formatMessage({ id: 'form:add-btn-text' });

  const actionText = formatMessage({ id: 'form:action-text' });
  const keywordText = formatMessage({ id: 'form:keyword-text' });

  return {
    normalMessages,
    textLengthMessage,
    actions: {
      sureBtnText,
      submitBtnText,
      cancelBtnText,
      editBtnText,
      deleteBtnText,
      actionText,
      viewDetailText,
      previewBtnText,
      addBtnText,
      keywordText,
    },
  };
};

export default useCommonMessages;
