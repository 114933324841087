import { CookieStorage } from 'cookie-storage';
import moment from 'moment';
import { MatchFunction, match } from 'path-to-regexp';
import qs from 'qs';
import { IRoute, getLocale, history } from 'umi';
import { ENV } from '@/env';
import routes from '../config/routes';
import {
  ADMIN_LOGIN,
  BACKEND_PATHNAMES,
  SYSTEM_UPDATE_PROMPT_PATHNAME,
} from './constant';

const isAssist = /^[\w-]+\.[\w-]+\.oneulink\.com$/.test(
  window.location.hostname,
);

export const TOKEN_KEY = isAssist
  ? 'ASSIST_ONEULINK_USER_TOKEN'
  : 'ONEULINK_USER_TOKEN';

const storage = new CookieStorage();

export const getToken = () => {
  return storage.getItem(TOKEN_KEY) || '';
};

export const setToken = (token: string, persist: boolean = true) => {
  removeToken();

  storage.setItem(TOKEN_KEY, token, {
    secure: APP_ENV !== 'development',
    path: '/',
    domain:
      isAssist || APP_ENV === 'development'
        ? undefined
        : window.location.hostname.replace(/^[\w-]+\./, '.'),
    expires: persist
      ? moment().add(1, 'years').toDate()
      : moment().add(1, 'days').toDate(),
  });
};

export const removeToken = () => {
  storage.removeItem(TOKEN_KEY);
};

export const loginRedirect = () => {
  const {
    location: { query },
  } = history;
  const { redirect } = query as { redirect: string };

  window.location.href = redirect || '/';
};

export const getLoginUrl = (redirect = false) => {
  const href = window.location.href.replace(window.location.origin, '');

  let url = isBackendAdminPage(window.location.pathname)
    ? '/user/admin-login/'
    : '/user/login';

  if (
    redirect &&
    !href.match(/\/user\/\S*?login/) &&
    ['exception403', 'exception500'].every((v) => !href.includes(v))
  ) {
    url = `${url}?redirect=${href}`;
  }

  return url;
};

export const logout = (redirect = true, remove = true) => {
  if (remove) {
    removeToken();
  }
  window.location.href = getLoginUrl(redirect);
};

export const toggleCompanyComplete = () => {
  window.location.reload();
};

const pathnames: MatchFunction<object>[] = [];

type Route = IRoute & {
  routes?: Route[];
};
const whiteList = ['/welcome', '/create-company'];

const getAllNotRequiredPermissionPages = (route: Route): void => {
  if (!route.path || route.redirect || route.access) {
    return;
  }

  const { path } = route;

  if (route.routes) {
    route.routes.forEach((item) => getAllNotRequiredPermissionPages(item));
  } else if (!path.includes('/account/settings') && !whiteList.includes(path)) {
    pathnames.push(match(path!));
  }
};

routes.forEach(getAllNotRequiredPermissionPages);

export const isNonLoginStatusPage = (path: string) => {
  return pathnames.some((item) => item(path));
};

export const resolveUpdateCompletedDirect = (backend: boolean) => {
  const query = new URL(window.location.href);

  const defaultUrl = backend ? BACKEND_PATHNAMES[0] : '/dashboard';
  const redirect =
    query.searchParams.get('redirect') === SYSTEM_UPDATE_PROMPT_PATHNAME
      ? defaultUrl
      : query.searchParams.get('redirect') || defaultUrl;
  const next = decodeURIComponent(redirect);

  history.replace(next);
};

export const shouldRedirectUpdatePage = (pathname: string) => {
  return (
    pathname !== SYSTEM_UPDATE_PROMPT_PATHNAME &&
    !pathname.startsWith('/client-user')
  );
};

export const redirectToUpdatePage = () => {
  const url = new URL(window.location.href);

  url.searchParams.delete('redirect');

  history.replace(
    `${SYSTEM_UPDATE_PROMPT_PATHNAME}?redirect=${encodeURIComponent(
      url.toString().replace(url.origin, ''),
    )}`,
  );
};

export const isBackendAdminPage = (pathname: string) => {
  const internalPathname =
    pathname !== '/' ? pathname.replace(/\/$/, '') : pathname;

  return (
    BACKEND_PATHNAMES.includes(internalPathname) ||
    internalPathname === ADMIN_LOGIN
  );
};

export const getAssistanceUrl = (name: string, token: string) => {
  const domain = `${
    APP_ENV === 'development' ? 'http://' : 'https://'
  }${name}.${ENV.assistDomain}`;

  return `${domain}?${qs.stringify({
    t: token,
    locale: getLocale(),
  })}`;
};
