import { useCountDown } from 'ahooks';
import React from 'react';
import { useFormatMessage } from '@/hooks';

const TodoRestTime: React.FC<{
  deadline: string;
  style?: React.CSSProperties;
}> = ({ deadline, style }) => {
  const [, , result] = useCountDown({
    targetDate: deadline,
  });
  const { formatMessage } = useFormatMessage();

  const { days, hours, minutes, seconds } = result;

  if (seconds < 0) {
    return <>-</>;
  }

  const colors = {
    error: '#ff4d4f',
    warning: '#faad14',
    normal: 'rgba(0, 0, 0, 0.65)',
  };

  const list = [hours, minutes, seconds].map((item) =>
    String(item).padStart(2, '0'),
  );

  return (
    <span
      style={{
        color:
          days <= 1 ? colors.error : days <= 3 ? colors.warning : colors.normal,
        ...style,
      }}
    >
      {days >= 0 && (
        <span style={{ marginRight: 6 }}>
          {formatMessage('{day} 天', { day: String(days).padStart(2, '0') })}
        </span>
      )}
      {list.join(':')}
    </span>
  );
};

export default TodoRestTime;
