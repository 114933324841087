import { BadgeProps } from 'antd';
import { useMemo } from 'react';
import { useFormatMessage } from '@/hooks';

export enum CoETuitionPaymentStatus {
  PROCESSING = 1,
  EXPIRED = 2,
  NONE = 3,
  COMPLETED = 4,
}

export const useCoETuitionPaymentStatus = () => {
  const { formatMessage } = useFormatMessage();

  return useMemo(() => {
    const statusEnum: [
      CoETuitionPaymentStatus,
      {
        status: BadgeProps['status'];
        text: string;
      },
    ][] = [
      [
        CoETuitionPaymentStatus.PROCESSING,
        {
          status: 'processing',
          text: formatMessage('付款中'),
        },
      ],
      [
        CoETuitionPaymentStatus.EXPIRED,
        {
          status: 'error',
          text: formatMessage('已付款已到期'),
        },
      ],
      [
        CoETuitionPaymentStatus.NONE,
        {
          status: 'warning',
          text: formatMessage('未付款已超期'),
        },
      ],
      [
        CoETuitionPaymentStatus.COMPLETED,
        {
          status: 'success',
          text: formatMessage('已付完学费'),
        },
      ],
    ];

    return new Map(statusEnum);
  }, [formatMessage]);
};
