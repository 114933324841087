import { RuleRender } from 'antd/es/form';
import { isNil } from 'lodash';
import type { FormatMessage } from '@/types';
import { phoneValidator } from './phone';

interface DependFun {
  (otherFieldName: string, f: FormatMessage, required?: boolean): RuleRender;
}

const isEmpty = (val: any) => val === '' || val === null || val === undefined;

const phoneNumberValidator: DependFun = (
  otherFieldName: string,
  f,
  required: boolean = true,
) => ({ getFieldValue }) => ({
  validator(_rule, value) {
    const other = getFieldValue(otherFieldName);

    if (required && isEmpty(value)) {
      return Promise.reject(Error(f({ id: 'form:input.required-tips' })));
    }

    if (other && !isEmpty(value)) {
      if (!phoneValidator(other, value)) {
        return Promise.reject(Error(f({ id: 'form:phone.wrong-format' })));
      }
    }

    return Promise.resolve();
  },
});

const phoneCodeValidator: DependFun = (
  otherFieldName: string,
  f,
  required: boolean = true,
) => ({ getFieldValue }) => ({
  validator(_rule, value) {
    const other = getFieldValue(otherFieldName);

    if (isEmpty(value) && (required || !isEmpty(other))) {
      return Promise.reject(Error(f({ id: 'form:input.required-tips' })));
    }

    return Promise.resolve();
  },
});

export const createPhoneValidator = {
  phoneCode: phoneCodeValidator,
  phoneNumber: phoneNumberValidator,
};

export const fileValidator = (rule: any, value: any) => {
  const field = 'url';

  if (
    (isNil(value) || (Array.isArray(value) && value.length === 0)) &&
    !rule.required
  ) {
    return Promise.resolve();
  }

  if ((!value || !value.length) && rule.required) {
    return Promise.reject(new Error(rule.message));
  }

  if (typeof value === 'string' && value) {
    return Promise.resolve();
  }

  const result = value.some((target: AnyObject) => {
    if (target[field] && target[field].match(/^https?/)) {
      return Promise.resolve();
    }

    const resp = target.response;

    if (resp) {
      if (resp.fileUrl || (resp.data && resp.data.fileUrl)) {
        return Promise.resolve();
      }
    }

    return undefined;
  });

  return result
    ? Promise.resolve()
    : Promise.reject(new Error(rule.message || 'Please upload file'));
};
