import { useUnmountedRef } from 'ahooks';
import { notification } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { Fetcher } from '@/api';
import { downloadFile2 } from '@/utils';

const useDownload = (fetcher: Fetcher) => {
  const unmounted = useUnmountedRef();
  const [downloading, setDownloading] = useState(false);
  const token = useRef(0);

  const download = useCallback(
    async (params?: AnyObject) => {
      const currentToken = ++token.current;
      try {
        setDownloading(true);
        await downloadFile2(fetcher, {
          ...params,
        });
      } catch (resp: any) {
        notification.error({ message: resp.msg });
      } finally {
        if (!unmounted.current && currentToken === token.current) {
          setDownloading(false);
        }
      }
    },
    [fetcher, unmounted],
  );

  return { downloading, download };
};

export default useDownload;
