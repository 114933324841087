import ProForm, {
  ProFormDependency,
  ProFormTextArea,
} from '@ant-design/pro-form';
import { Radio, Tag } from 'antd';
import moment from 'moment';
import React, { useImperativeHandle, useMemo, useState } from 'react';
import { services } from '@/api';
import ProModalForm from '@/components/ProModalForm';
import { getTextareaSizeProps } from '@/config';
import { TodoPriority, useTodoPriorityEnum } from '@/data';
import {
  useCommonRules,
  useDeepMemorizedEffect,
  useFormatMessage,
  useModalFormRequestActions,
} from '@/hooks';
import { AbsoluteDate, MessageTaskPriorityType } from '../MessageTask';
import { useNormalMessageTaskUtils } from './NormalMessageTaskForm';
import RelativeDate from './RelativeDate';
import TodoChargeStaffSelect from './TodoChargeStaffSelect';
import { TodoFormRef, TodoTaskScope } from './type';

const TodoForm = (
  {
    scope,
    scopeId,
    initialValues,
    trigger,
    onOk,
    defaultChargeStaffId,
    afterClose,
  }: {
    trigger?: React.ReactElement;
    scope?: TodoTaskScope;
    scopeId?: number;
    initialValues?: {
      title?: string;
      content?: string;
    };
    defaultChargeStaffId?: number;
    onOk?: () => void;
    afterClose?: () => void;
  },
  ref: React.Ref<TodoFormRef>,
) => {
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useFormatMessage();
  const { selectRules, inputRules, textLengthRule } = useCommonRules();
  const [form] = ProForm.useForm();

  const priorityEnum = useTodoPriorityEnum();
  const [memberId, setMemberId] = useState<number>();
  const { getFormValues } = useNormalMessageTaskUtils();

  const { handleSubmit } = useModalFormRequestActions({
    visible,
    form,
    id: 0,
    submitActions: {
      create: services.todo.createTodo,
    },
    onOk,
    extraParams: {
      caseId: scope === 'case' ? scopeId : undefined,
      customerId: scope === 'client' ? scopeId : undefined,
    },
  });

  useDeepMemorizedEffect(() => {
    if (initialValues && visible) {
      form.setFieldsValue({
        ...initialValues,
      });
    }
  }, [visible, form, initialValues]);

  useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false),
  }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const now = useMemo(() => moment(), [initialValues]);

  return (
    <ProModalForm
      title={formatMessage('新建待办事项')}
      onVisibleChange={setVisible}
      onCancel={() => setVisible(false)}
      visible={visible}
      trigger={trigger}
      form={form}
      onFinish={handleSubmit}
      modalProps={{ destroyOnClose: true, afterClose }}
    >
      <ProFormTextArea
        rules={inputRules}
        name="title"
        label={formatMessage('标题')}
        fieldProps={{
          autoSize: {
            minRows: 1,
          },
        }}
      />
      <ProForm.Item
        name="chargeChmId"
        label={formatMessage('负责人')}
        rules={selectRules}
      >
        <TodoChargeStaffSelect
          memberId={defaultChargeStaffId}
          refreshDeps={[visible]}
          onChange={(_, currentMemberId) => setMemberId(currentMemberId)}
        />
      </ProForm.Item>

      <ProForm.Item
        name="priorityLevel"
        label={formatMessage('优先级')}
        rules={selectRules}
      >
        <Radio.Group>
          {Array.from(priorityEnum.entries()).map(([priority, item]) => (
            <Radio key={priority} value={priority}>
              <Tag color={item.color}>{item.text}</Tag>
            </Radio>
          ))}
        </Radio.Group>
      </ProForm.Item>

      <ProForm.Item
        name="deadline"
        label={formatMessage('截止时间')}
        transform={(d) => ({
          deadline: moment(d).toISOString(),
        })}
        rules={selectRules}
      >
        <AbsoluteDate precision="day" renderToFooter />
      </ProForm.Item>

      <ProFormTextArea
        name="content"
        label={formatMessage('详情')}
        rules={[textLengthRule(4, 1000)]}
        fieldProps={getTextareaSizeProps({ min: 4, max: 1000 })}
        transform={(content) => ({
          content: content.replace(/\n{2,}/g, '\n'),
        })}
      />

      <ProFormDependency
        name={['title', 'content', 'priorityLevel', 'deadline']}
      >
        {({ title, content, priorityLevel, deadline }) =>
          memberId &&
          deadline && (
            <ProForm.Item
              label={formatMessage('定时消息通知')}
              transform={(dates: string[]) => {
                const nowWithHourPrecision = moment()
                  .hour(12)
                  .minutes(0)
                  .seconds(0);

                return {
                  messageTask: dates
                    .map((item) => moment(item))
                    .filter(
                      (item) => item.diff(nowWithHourPrecision, 'hours') > 0,
                    )
                    .map((date) =>
                      getFormValues([date], memberId, {
                        title,
                        content: content || title,
                        priority:
                          priorityLevel === TodoPriority.URGENCY
                            ? MessageTaskPriorityType.IMPORTANT
                            : MessageTaskPriorityType.NORMAL,
                      }),
                    ),
                };
              }}
              name="messageTask"
            >
              <RelativeDate targetDate={moment(deadline)} now={now} />
            </ProForm.Item>
          )
        }
      </ProFormDependency>
    </ProModalForm>
  );
};

export default React.forwardRef(TodoForm);
