import { CalendarOutlined, CarryOutOutlined } from '@ant-design/icons';
import { ActionType } from '@ant-design/pro-table';
import { useQuery } from '@tanstack/react-query';
import { Button, Space, Tag, Tooltip } from 'antd';
import type { SortOrder } from 'antd/lib/table/interface';
import React, { useImperativeHandle, useRef } from 'react';
import { Link } from 'umi';
import { ResponseSchema, services } from '@/api';
import { CustomAvatar, LinkOptionButton } from '@/common';
import { IAccessable, useAccessable } from '@/components/Accessable';
import EnhancedProTable from '@/components/EnhancedProTable';
import TableCell from '@/components/TableCell';
import {
  UsedWebsocketChannel,
  UsedWebsocketEvent,
  useWebsocketSubscribe,
} from '@/components/Websocket';
import { TodoStatus, useTodoPriorityEnum, useTodoStatusEnum } from '@/data';
import {
  useCachedColumns,
  useCachedTableFilters,
  useFormatMessage,
  useIsChannel,
  useModalFormActions,
} from '@/hooks';
import { formatPersonName, getPaginationTableData } from '@/utils';
import { tableRender } from '@/utils/tableRender';
import TodoForm from './TodoForm';
import TodoRestTime from './TodoRestTime';
import TodoStatusTag from './TodoStatusTag';
import TodoViewer from './TodoViewer';
import { TodoTaskScope } from './type';

const visibleStatus = [TodoStatus.IN_PROGRESS];

const getSortParams = (params: Record<string, SortOrder>) => {
  const fields = ['priorityLevel', 'deadline'];
  const orderConfig: Record<NonNullable<SortOrder>, string> = {
    ascend: 'asc',
    descend: 'desc',
  };

  return fields
    .filter((item) => params[item])
    .map((item) => `${item}-${orderConfig[params[item] as string]}`)
    .join(',');
};

const TodoList = (
  {
    creator,
    style,
    scope,
    scopeId,
    todoFormProps,
    className,
    collapsible,
    renderTitle = true,
  }: {
    todoFormProps?: Pick<
      React.ComponentProps<typeof TodoForm>,
      'initialValues' | 'defaultChargeStaffId'
    >;
    creator?: boolean;
    style?: React.CSSProperties;
    className?: string;
    collapsible?: boolean;
    renderTitle?: boolean;
  } & Pick<React.ComponentProps<typeof TodoForm>, 'scope' | 'scopeId'>,
  ref: React.Ref<ActionType | undefined | null>,
) => {
  const { formatMessage } = useFormatMessage();
  const actionRef = useRef<ActionType>();
  const tableKey = ['TODO', scope, scopeId, 'LIST'].filter(Boolean).join('_');

  const [{ open, ...modalFormParams }] = useModalFormActions({
    onOk() {
      actionRef.current?.reload();
    },
  });
  const canFilterChargeStaff = useAccessable(
    'front.todoTask.changeStaff.select',
  );
  const isChannel = useIsChannel();

  const { data: staffResp } = useQuery(
    ['todo', 'getChargeStaffOptions'],
    ({
      signal,
    }): Promise<
      ResponseSchema<
        {
          avatar: string;
          familyName: string;
          givenName: string | null;
          id: number;
          nickname: string;
        }[]
      >
    > => services.todo.getChargeStaffOptions({ signal }),
    {
      enabled: canFilterChargeStaff,
    },
  );

  const priorityEnum = useTodoPriorityEnum();
  const statusEnum = useTodoStatusEnum();
  const getColumnFilterProps = useCachedTableFilters(tableKey);

  const statusFilters = Array.from(statusEnum.entries()).map((item) => ({
    text: item[1].text,
    value: item[0],
  }));

  useWebsocketSubscribe<{ todoTaskId: number }>(
    UsedWebsocketEvent.TODO_TASK_CREATED,
    () => {
      actionRef.current?.reload();
    },
    {
      channel:
        scope === TodoTaskScope.CASE
          ? UsedWebsocketChannel.CASE
          : scope === TodoTaskScope.CLIENT
          ? UsedWebsocketChannel.CLIENT
          : UsedWebsocketChannel.COMPANY,
      scope: scopeId,
    },
  );

  useWebsocketSubscribe<{ todoTaskId: number }>(
    UsedWebsocketEvent.TODO_TASK_UPDATED,
    () => {
      if (actionRef.current?.pageInfo?.current === 1) {
        actionRef.current?.reload();
      }
    },
  );

  const staffOptions = staffResp?.data.map((item) => ({
    value: item.id,
    label: item.nickname,
    name: formatPersonName(item),
    avatar: item.avatar,
  }));

  const columnsState = useCachedColumns(tableKey, [
    {
      title: formatMessage('标题'),
      dataIndex: 'title',
      key: 'title',
      width: 250,
      render: tableRender(({ title, id }) => (
        <LinkOptionButton
          style={{
            overflow: 'hidden',
            maxWidth: '100%',
          }}
          onClick={() => open(id)}
        >
          <TableCell text={title} />
        </LinkOptionButton>
      )),
    },

    {
      title: formatMessage('状态'),
      key: 'state',
      dataIndex: 'state',
      width: 150,
      render: tableRender(({ state, id }) => (
        <TodoStatusTag
          id={id}
          onSuccess={() => {
            actionRef.current?.reload();
          }}
          status={state}
        />
      )),
      ...getColumnFilterProps('state', {
        filterSearch: true,
        filters: statusFilters,
        defaultValue: [TodoStatus.IN_PROGRESS, TodoStatus.OVERDUE],
        multiple: true,
      }),
    },
    {
      title: formatMessage('负责人'),
      width: 200,
      dataIndex: 'chargeChmId',
      key: 'filterChargeChmId',
      render: tableRender(({ chargeChmAvatar, chargeChmNickname }) => (
        <Space align="center">
          <CustomAvatar
            size="small"
            src={chargeChmAvatar}
            alt={chargeChmNickname}
          />
          <TableCell text={chargeChmNickname} />
        </Space>
      )),
      filterSearch: (input, record: AnyObject) => {
        return (
          record.label.toLowerCase().includes(input) ||
          record.name.toLowerCase().includes(input)
        );
      },
      filters: staffOptions?.map((item) => ({
        value: item.value,
        label: item.label,
        name: item.name,
        text: (
          <Space align="center">
            <CustomAvatar size="small" src={item.avatar} alt={item.label} />
            {item.label}
          </Space>
        ),
      })),
    },
    {
      title: formatMessage('剩余时间'),
      width: 150,
      dataIndex: 'deadline',
      key: 'rest',
      render: tableRender(({ state: status, deadline }) =>
        visibleStatus.includes(status) ? (
          <TodoRestTime deadline={deadline} />
        ) : (
          statusEnum.get(status)?.text
        ),
      ),
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: formatMessage('优先级'),
      dataIndex: 'priorityLevel',
      key: 'priorityLevel',
      width: 150,
      sorter: { multiple: 2 },
      render: tableRender(({ priorityLevel }) => (
        <Tag color={priorityEnum.get(priorityLevel)?.color}>
          {priorityEnum.get(priorityLevel)?.text}
        </Tag>
      )),
    },
    {
      title: formatMessage('创建人'),
      width: 200,
      dataIndex: 'chmId',
      key: 'chmId',
      render: tableRender(({ chmAvatar, chmNickname }) => (
        <Space align="center">
          <CustomAvatar size="small" src={chmAvatar} alt={chmNickname} />
          <TableCell text={chmNickname} />
        </Space>
      )),
    },
    !scope && {
      title: formatMessage('关联案件'),
      dataIndex: 'caseId',
      key: 'caseId',
      width: 200,
      render: tableRender(({ caseId, caseName }) =>
        caseName ? (
          <Link
            to={
              isChannel
                ? `/channel-case/detail/${caseId}`
                : `/agent-case/detail/${caseId}`
            }
          >
            <TableCell text={caseName} />
          </Link>
        ) : (
          '-'
        ),
      ),
    },
  ]);

  useImperativeHandle(ref, () => actionRef.current && actionRef.current);

  const title = collapsible ? (
    formatMessage('待办事项')
  ) : (
    <Space style={{ fontWeight: 'normal' }}>
      <CarryOutOutlined style={{ fontSize: 24, color: '#434343' }} />
      {formatMessage('待办事项')}
    </Space>
  );

  const renderExtra = () => [
    creator && (
      <IAccessable authority="front.todoTask.create">
        <TodoForm
          {...todoFormProps}
          onOk={() => actionRef.current?.reloadAndRest?.()}
          trigger={
            <Tooltip title={formatMessage('新建待办事项')}>
              <Button type="dashed" icon={<CalendarOutlined />} />
            </Tooltip>
          }
          scope={scope}
          scopeId={scopeId}
        />
      </IAccessable>
    ),
  ];

  return (
    <>
      <EnhancedProTable
        rowKey="id"
        headerTitle={renderTitle ? title : renderExtra()}
        toolBarRender={renderTitle ? renderExtra : undefined}
        request={(params, sort, filters) => {
          return getPaginationTableData(
            {
              ...params,
              sort: getSortParams(sort),
              [`filter${scope === 'case' ? 'Case' : 'Customer'}Id`]: scopeId,
              filterState: filters.state?.join(','),
              filterChargeChmId: filters.filterChargeChmId?.[0],
            },
            services.todo.getTodoList,
          );
        }}
        className={className}
        actionRef={actionRef}
        style={style}
        search={false}
        pagination={{
          pageSize: 6,
        }}
        key={tableKey}
        collapsible={collapsible}
        {...columnsState}
      />
      <TodoViewer {...modalFormParams} scope={scope} scopeId={scopeId} />
    </>
  );
};

export default React.forwardRef(TodoList);
