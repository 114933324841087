import {
  CustomerServiceOutlined,
  DeploymentUnitOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { Link } from 'umi';
import { useFormatMessage } from '@/hooks';
import styles from './Header.less';

export default () => {
  const { formatMessage } = useFormatMessage();

  const menus = [
    {
      label: formatMessage('客服中心'),
      icon: <CustomerServiceOutlined />,
      href:
        'https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97d27505ce70169fba4fe9e46be3494f540551274a5df7505592a8a93350db1a02e88a848804ce2ddfa19ab8f9553ef7',
    },
    {
      label: formatMessage('使用手册'),
      icon: <FileSearchOutlined />,
      href: 'https://j0f5e0fezy.feishu.cn/wiki/wikcnR8pyDPnXKWX6F1WtSH3lbd',
    },
    {
      label: formatMessage('更新日志'),
      icon: <DeploymentUnitOutlined />,
      href: '/releases',
    },
  ];

  return (
    <div className={styles.list}>
      {menus.map((item) => {
        const content = (
          <>
            <div className={styles.icon}>{item.icon}</div>
            <span>{item.label}</span>
          </>
        );

        if (item.href.includes('http')) {
          return (
            <a
              title={item.label}
              target="_blank"
              href={item.href}
              className={styles.item}
              key={item.label}
              rel="noreferrer"
            >
              {content}
            </a>
          );
        }

        return (
          <Link
            title={item.label}
            className={styles.item}
            key={item.label}
            to={item.href}
          >
            {content}
          </Link>
        );
      })}
    </div>
  );
};
