import { useRequest } from 'ahooks';
import { Button, Space } from 'antd';
import React, { useState } from 'react';
import { history } from 'umi';
import { ResponseSchema, services } from '@/api';
import { useFormatMessage } from '@/hooks';
import { useSwitchCompany } from '@/layouts/components';

const InviteOperator: React.FC<{
  id: number;
  markAsRead?: () => void;
  isSwitchCompany?: boolean;
}> = ({ id, markAsRead, isSwitchCompany = true }) => {
  const { formatMessage } = useFormatMessage();
  const [handled, set] = useState(false);
  const { switchCompany, switching } = useSwitchCompany(() => {
    history.replace('/');
  });

  const { fetches, run } = useRequest<ResponseSchema<AnyObject>>(
    services.message.handleInviteMessage,
    {
      manual: true,
      fetchKey: (d) => d?.operation,
      onSuccess({ data }) {
        set(true);
        markAsRead?.();

        if (isSwitchCompany && data) {
          switchCompany(data.id);
        }
      },
      onError() {
        set(true);
        markAsRead?.();
      },
    },
  );

  const actions = [
    {
      key: 'refuse',
      label: formatMessage('拒绝'),
    },
    {
      key: 'adopt',
      label: formatMessage('同意'),
    },
  ];

  return handled ? null : (
    <Space>
      {actions.map((item) => (
        <Button
          key={item.key}
          loading={fetches[item.key]?.loading || switching}
          onClick={() => {
            if (actions.some((v) => fetches[v.key]?.loading)) {
              return;
            }

            run({ id, operation: item.key });
          }}
          size="small"
          type={item.key === 'adopt' ? 'primary' : undefined}
        >
          {item.label}
        </Button>
      ))}
    </Space>
  );
};

export default InviteOperator;
