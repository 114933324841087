export default {
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.login': '登录',
  'menu.admin-login': '登录',
  'menu.register': '注册',
  'menu.forgot': '忘记密码',
  'menu.welcome': '欢迎',
  'menu.invite': '邀请',
  'menu.create-company': '创建公司',

  'menu.company-admin': '公司管理',
  'menu.company-admin.detail': '公司详情',
  'menu.company-admin.bill-settings': '账单配置',
  'menu.company-admin.settings': '功能配置',
  'menu.company-admin.case-point-settings': '案件积分配置',
  'menu.company-admin.material-recycling': '材料回收站',
  'menu.company-admin.message-task': '任务管理',
  'menu.company-admin.message-task.template': '消息模板',
  'menu.company-admin.message-task.log': '任务日志',
  'menu.company-admin.message-task.list': '全局任务',

  'menu.company-logs': '系统安全',
  'menu.company-logs.operation-list': '操作记录',
  'menu.company-logs.log': '系统日志',
  'menu.company-logs.log.staff': '员工日志',

  'menu.company-staff': '员工管理',
  'menu.company-staff.list': '员工列表',
  'menu.company-staff.invite-list': '邀请记录',
  'menu.company-staff.job-list': '职位列表',
  'menu.financial.company-bill': '付款凭证审核',
  'menu.financial.company-coe-payment': 'CoE 付款审核',

  'menu.financial.company-point': '积分管理',
  'menu.financial.company-point.list': '积分明细',
  'menu.financial.company-point.extra': '额外积分',
  'menu.financial.company-point.withdraw': '提现管理',

  'menu.financial.c2c-point-agent': '公司积分',
  'menu.financial.c2c-point-agent.list': '公司积分列表',
  'menu.financial.c2c-point-agent.coe': 'CoE 积分',
  'menu.financial.c2c-point-channel': '对中介积分管理',
  'menu.financial.c2c-point-channel.list': '积分明细',
  'menu.financial.c2c-point-channel.withdraw': '提现管理',
  'menu.financial.c2c-point-channel.extra': '额外积分',
  'menu.financial.c2c-point-channel.coe': 'CoE 积分',

  'menu.client-user.create': '客户表单',
  'menu.client-user.visitor-visa': '旅游签证',
  'menu.client-user.student-visa': '学生签证',
  'menu.client-user.bridging-visa-b': '过桥签证 B',
  'menu.client-user.subsequent-student-visa': '学生陪读签证',
  'menu.client-user.guardian-student-visa': '学生陪读签证（18岁以下）',
  'menu.client-user.485-visa': '485 PSW 毕业工作签',
  'menu.client-user.gte': '签证问题',
  'menu.client-user.view-offer': '查看 Offer',
  'menu.client-user.offer': 'Offer 生成',
  'menu.client-user.956-form': '956 Form',
  'menu.client-user.contract': '合同签署',
  'menu.client-user.contract-view': '查看合同',
  'menu.client-user.school-apply-nav': '学校申请',
  'menu.client-user.school-apply': '学校申请',
  'menu.client-user.agent-apply': '代理申请',
  'menu.client-user.prp': '永居规划',

  'menu.case-tag': '案件标签',

  'menu.point': '我的积分',
  'menu.point.list': '积分明细',
  'menu.point.withdraw': '积分提现',
  'menu.point.extra': '额外积分',

  // 中介
  'menu.agent-client': '客户管理',
  'menu.agent-client.list': '客户列表',
  'menu.agent-client.detail': '客户详情',
  'menu.agent-client.detail-email': '邮件详情',

  'menu.agent-case': '案件管理',
  'menu.agent-case.list': '案件列表',
  'menu.agent-case.detail': '案件详情',
  'menu.offer-form': '升学转学',

  'menu.my-master-agent': '我的渠道',
  'menu.master-agent': '渠道商管理',
  'menu.master-agent.list': '渠道商列表',
  'menu.master-agent.detail': '渠道商详情',
  'menu.master-agent.detail.school': '学校详情',

  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',

  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.settings.base': '基本设置',
  'menu.account.settings.password': '修改密码',
  'menu.account.settings.association': '账号关联',
  'menu.account.settings.webauthn': '生物指纹登录绑定',
  'menu.account.settings.payment-info': '付款信息',
  'menu.account.trigger': '触发报错',
  'menu.account.file-sign': '文件签名',
  'menu.account.logout': '退出登录',
  'menu.account.log': '操作日志',

  'menu.custom-case': '案件配置',
  'menu.custom-case.custom-list': '自定义案件',
  'menu.custom-case.custom-detail': '自定义案件',
  'menu.custom-case.builtin-list': '内置案件',
  'menu.custom-case.builtin-detail': '内置案件',
  'menu.custom-case.custom-category': '自定义案件分类',

  'menu.custom-case.custom-form': '自定义表单',
  'menu.custom-case.steps-config': '自定义步骤',
  'menu.custom-case.steps-category': '步骤分类',

  'menu.file-sign': '文件签名',
  'menu.file-sign.personal': '个人签名',
  'menu.file-sign.list': '签名文件',

  // 系统管理
  'menu.system': '系统管理',
  'menu.system.api-rule': '接口权限',
  'menu.system.router-rule': '路由权限',
  'menu.system.role-group': '角色组',

  'menu.channel-case': '案件管理',
  'menu.channel-case.list': '案件列表',
  'menu.channel-case.detail': '案件详情',

  'menu.channel-client': '客户管理',
  'menu.channel-client.list': '客户列表',
  'menu.channel-client.detail': '客户详情',

  'menu.financial.channel-coe-payment': 'CoE 付款审核',
  'menu.financial.channel-bill': '付款凭证审核',

  'menu.school': '学校相关',
  'menu.school.detail': '学校详情',
  'menu.school.list': '学校查询',
  'menu.school.course-tutoring-company': '课程辅导公司',
  'menu.school.rpl-course-list': 'RPL 课程列表',
  'menu.school.course-alternative-titles': '课程别名',

  'menu.analysis': '数据分析',
  'menu.analysis.asqa': 'ASQA',
  'menu.analysis.student': '留学人数',
  'menu.analysis.school': '学校分析',
  'menu.analysis.course': '课程分析',
  'menu.analysis.trend': '学校趋势分析',
  'menu.analysis.agent': '中介分析',
  'menu.analysis.agent.summary': '数据概览',
  'menu.analysis.agent.compare': '数据比较',
  'menu.analysis.agent.detail': '中介详情',

  'menu.file-assistant': '文件归档',

  'menu.file-remark': '文件标注',
  'menu.file-remark.coe-attachment': 'CoE 附件标注',
  'menu.file-remark.offer': 'Offer 标注',
  'menu.file-remark.cate': '文件分类标注',
  'menu.file-remark.student-visa': '学生签证标注',
  'menu.system-update': '系统更新管理',
  'menu.system-update.list': '系统更新',
  'menu.system-update.detail': '发布新版本',
  'menu.system-update-prompt': '系统正在更新',
  'menu.releases': '更新日志列表',
  'menu.releases.detail': '更新日志详情',

  'menu.publicity.prp': '永居规划',
  'menu.publicity.prp-detail': '永居规划',
  'menu.publicity.pr-planner': '永居规划管理',
  'menu.publicity.pr-planner.cert-condition': '职业证书',
  'menu.publicity.pr-planner.assess-condition': '职业评估',
  'menu.publicity.pr-planner.channel': '移民渠道',

  'menu.publicity.pr-planner.diff': '数据对比',
  'menu.publicity.pr-planner.channel-detail': '移民渠道详情',
  'menu.publicity.pr-planner.course': '课程录入',
  'menu.publicity.pr-planner.version': '版本发布',

  'menu.oneulink-assistance': '公司协助',
  'menu.oneulink-assistance.list': '公司协助',
  'menu.oneulink-assistance.log': '协助日志',

  'menu.data-config': '数据/权限配置',
  'menu.data-config.file-category': '文件分类配置',
  'menu.data-config.auto-submit': '自动提交',
  'menu.data-config.publicity-access': '专员宣传权限',
  'menu.data-config.review-payment-info': '审核付款信息',
  'menu.data-config.occupation': '评估职业',
  'menu.data-config.email-blacklist': '邮箱黑名单',
  'menu.data-config.course-checklist': '课程清单',
  'menu.data-config.skill-assess': '职业评估',
  'menu.data-config.rpl-discount': 'RPL 课程打折',
  'menu.data-config.assist-promotion': 'AI 助手推广码',

  'menu.publicity': '宣传工具',
  'menu.publicity.course-plan-list': '课程规划',
  'menu.publicity.course-plan-report': '课程规划',
  'menu.publicity.visa-fees-list': '签证费用计算',
  'menu.publicity.visa-fees-detail': '签证费用计算',
  'menu.publicity.offer': 'Offer 生成',
  'menu.publicity.part-study': '半工半读',
  'menu.publicity.course-checklist': '课程清单',

  'menu.system-tips': 'Tips 管理',
  'menu.system-accounts': '账号管理',
  'menu.system-companys': '公司管理',

  'menu.financial': '财务相关',
  'menu.visa-like-me': '签证案例查询',

  'menu.student': '学生端管理',
  'menu.student.accounts': '账号列表',
  'menu.student.point-details': '积分详情',
  'menu.student.use-points-record': '积分使用',
  'menu.student.case-point-bonus': '案件积分奖励',
};
