import { useRequest, useUpdateEffect, useUpdateLayoutEffect } from 'ahooks';
import { Divider, Empty, Space, Spin } from 'antd';
import React, {
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { services } from '@/api';
import emptySVG from '@/assets/message-empty.svg';
import { useFormatMessage } from '@/hooks';
import CustomVirtualList, { CustomVirtualListRef } from './CustomVirtualList';
import MessageItem from './MessageItem';
import MessageSkeleton from './MessageSkeleton';
import styles from './index.less';
import { IMessageItem, MessageType } from './type';

const getList = async (type: MessageType, page: number) => {
  const { data } = await services.message.getHistoryMessage({
    type,
    page: page ?? 1,
  });

  return {
    list: data.data,
    next: data.nextPageUrl ? data.currentPage + 1 : null,
  };
};

export interface HistoryMessageListRef {
  refresh: () => void;
}

const HistoryMessageList = React.forwardRef(
  (
    {
      type,
      visible,
      onClose,
    }: {
      type: MessageType;
      visible: boolean;
      onClose: () => void;
    },
    ref: React.Ref<HistoryMessageListRef>,
  ) => {
    const { formatMessage } = useFormatMessage();
    const refreshIndex = useRef(0);
    const listRef = useRef<CustomVirtualListRef | null>(null);

    const { loadMore, loading, data, reload, loadingMore, noMore } = useRequest(
      (d) => getList(type, d?.next),
      {
        cacheKey: `message-${type}`,
        loadMore: true,
        ready: visible,
        isNoMore: (d) => {
          return d?.next === null;
        },
      },
    );

    const handleReload = useCallback(() => {
      listRef.current?.clearLoadedRowsMap();
      reload();
    }, [reload, listRef]);

    const { list } = data as {
      list: IMessageItem[];
      next: number;
    };

    useUpdateLayoutEffect(() => {
      if (visible) {
        refreshIndex.current = 1;
      }
    }, [visible]);

    useUpdateEffect(() => {
      if (
        list.length === 0 &&
        visible &&
        !loading &&
        refreshIndex.current === 1
      ) {
        refreshIndex.current = 0;
        handleReload();
      }
    }, [visible, handleReload, loading, list.length]);

    useImperativeHandle(ref, () => ({
      refresh: handleReload,
    }));

    const messageList = useMemo(
      () =>
        list.length === 0
          ? list
          : // @ts-ignore
            list.concat({
              type: 'tip',
              loadingMore,
              noMore,
            }),
      [list, loadingMore, noMore],
    );

    return (
      <div
        style={{
          height: '70vh',
        }}
      >
        <CustomVirtualList
          loadMore={loadMore}
          estimatedRowSize={80}
          ref={listRef}
          noRowsRenderer={() =>
            loading && list.length === 0 ? (
              <MessageSkeleton />
            ) : (
              <Empty
                style={{
                  marginTop: 48,
                }}
                className={styles.empty}
                description={formatMessage('暂未收到消息')}
                image={<img src={emptySVG} alt="" />}
              />
            )
          }
          infinite
          data={messageList}
          renderItem={(message) => {
            // @ts-ignore
            if (message.type === 'tip') {
              const tip = message as AnyObject;
              return (
                <div>
                  <Divider>
                    {tip.noMore ? (
                      <Space>{formatMessage('没有更多了')}</Space>
                    ) : tip.loadingMore ? (
                      <Space>
                        <Spin />
                        {formatMessage('加载更多中')}
                      </Space>
                    ) : (
                      formatMessage('加载更多')
                    )}
                  </Divider>
                </div>
              );
            }

            return (
              <MessageItem
                onView={onClose}
                data={message as IMessageItem}
                compact
              />
            );
          }}
        />
      </div>
    );
  },
);

export default memo(HistoryMessageList);
